<section class="content">
  <div class="card">
    <div class="card-header">
    <strong>List Travel Cost</strong>
    <div class="float-right">
    <a type="button" class="btn btn-sm btn-warning pull-right" data-toggle="modal" (click)="setNullSearch();ModalLoad.show();">&nbsp;<b>Cari Data</b>&nbsp;&nbsp;<i class="fa fa-search"></i>&nbsp;</a> &nbsp;&nbsp;&nbsp;
    <a type="button" [hidden]="!btnCrud" id="inputdata" class="btn btn-sm btn-primary pull-right" data-toggle="modal" (click)="ModalTravelCost.show();clearFormCost();"><b>&nbsp;&nbsp;&nbsp;Input Biaya Perjalanan&nbsp;&nbsp;&nbsp;&nbsp;</b><i class="fa fa-pen"></i>&nbsp;&nbsp;&nbsp;</a>
    </div>
  </div>
    <div class="card-body">
  <table datatable  [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"  class="table-striped">
    <thead>
      <tr>
        <th>Action</th>
        <th>Port Transit</th>
        <th>Rute</th>
        <th>Kapasitas</th>
        <th>Material</th>
        <th>Jarak</th>
        <th>Ritase</th>
        <th>Premi/Trip</th>
        <th>Total</th>
        <th>Berlaku Sejak</th>
        <th>Berlaku Sampai</th>
        <th>Dibuat Pada</th>
        <th>Dibuat Oleh</th>
        <th>Diubah Pada</th>
        <th>Diubah Oleh</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let row of dataTravel">
        <td><button [hidden]="!btnCrud" title="Hapus Data" class="btn btn-sm btn-danger" triggers="mouseenter:mouseleave" (click)="deleteCost(row);"><i class="fa fa-trash"></i></button>&nbsp;
          <button title="Re-Assign" (click)="onSelectCost(row);ModalTravelCost.show()" class="btn btn-sm btn-primary"  data-toggle="modal"triggers="mouseenter:mouseleave"><i class="fa fa-pen"></i></button>&nbsp;      
          <button title="History"  (click)="onClickHistory(row);ModalHistory.show()" class="btn btn-sm btn-primary"  data-toggle="modal" triggers="mouseenter:mouseleave"><i class="fa fa-history"></i></button>
        </td>
        <td>{{row.transit_port_name}}</td>
        <td>{{row.route_name}}</td>
        <td>{{row.capacity_name}}</td>
        <td>{{row.material_name}}</td>
        <td>{{row.distance}}</td>
        <td>{{row.ritase}}</td>
        <td>{{row.trip_premi}}</td>
        <td>{{row.total_amount}}</td>
        <td>{{row.valid_from | slice:0:10}}</td>
        <td>{{row.valid_to | slice:0:10}}</td>
        <td>{{row.created_at | slice:0:10}}</td>
        <td>{{row.created_by_text}}</td>
        <td>{{row.updated_at | slice:0:10}}</td>
        <td>{{row.updated_by_text}}</td>
      </tr>
    </tbody>
    <!-- <tfoot>
      <tr>
        <th><input hidden class="searchInput"/></th>
        <th><input id="portsearch" type="text" class="searchInput" placeholder="Port"/></th>
        <th><input id="routesearch" type="text" class="searchInput" placeholder="Rute"/></th>
        <th><input id="capacitysearch" type="text" class="searchInput" placeholder="Kapasitas"/></th>
        <th><input id="materialsearch" type="text" class="searchInput" placeholder="Material"/></th>
        <th><input hidden class="searchInput"/></th>
      </tr>
    </tfoot> -->

  </table>
</div>
</div>
</section>

<div bsModal #ModalLoad="bs-modal"  [config]="{backdrop: 'static'}"  class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog" style="max-width: 80%;" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title"><strong>Cari Data</strong></div>
        <button type="button" class="close" (click)="ModalLoad.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-6"> 
                <div class="form-group row">
                  <label class="col-md-3 col-form-label" for="text-input">Port Transit</label>
                  <input hidden [(ngModel)]="costModel.transit_port" disabled type="text" >
                  <div class="col-md-7">
                    <input [(ngModel)]="portSelect" disabled type="text" class="form-control">
                  </div>
                  <div class="col-md-2">
                    <button class="btn btn-sm btn-primary" (click)="showPort();ModalPortLoad.show()"
                    data-toggle="modal" ><i class="fa fa-plus"></i></button>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-md-3 col-form-label" for="text-input">Material</label>
                  <input hidden [(ngModel)]="costModel.material_id"  disabled type="text" >
                  <div class="col-md-7">
                    <input [(ngModel)]="materialSelect" disabled type="text" class="form-control">
                  </div>
                  <div class="col-md-2">
                    <button class="btn btn-sm btn-primary" (click)="showMaterial();ModalMaterialLoad.show();"
                    data-toggle="modal" ><i class="fa fa-plus"></i></button>
                  </div>
                </div>  
              </div>
              <div class="col-md-6"> 
                <div class="form-group row">
                  <label class="col-md-3 col-form-label" for="text-input">Rute</label>
                  <input hidden [(ngModel)]="costModel.route_id" disabled type="text" class="form-control">
                  <div class="col-md-7">
                    <input [(ngModel)]="ruteSelect" disabled type="text" class="form-control">
                  </div>
                  <div class="col-md-2">
                    <button class="btn btn-sm btn-primary" (click)="showRoute();ModalRouteLoad.show();" 
                    data-toggle="modal" ><i class="fa fa-plus"></i></button>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-md-3 col-form-label" for="text-input">Kapasitas</label>
                  <input hidden [(ngModel)]="costModel.capacity_id" disabled type="text" class="form-control">
                  <div class="col-md-7">
                    <input [(ngModel)]="capacitySelect" disabled type="text" class="form-control">
                  </div>
                  <div class="col-md-2">
                    <button class="btn btn-sm btn-primary" (click)="showCapacity();ModalCapacityLoad.show();"
                    data-toggle="modal" ><i class="fa fa-plus"></i></button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
    <div class="modal-footer">
      <button type="button" (click)="searchData();ModalLoad.hide();" class="btn btn-primary"><i class="fa fa-search"></i>&nbsp;&nbsp;<b>Cari Data</b>&nbsp;&nbsp;</button>
    </div>
  </div>
</div>
</div>


<div  bsModal #ModalPortLoad="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog  modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title"><strong>Pilih Port</strong></div>
        <button type="button" class="close" (click)="ModalPortLoad.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
          <table datatable  [dtOptions]="dtOptionsPort" [dtTrigger]="dtTriggerPort"  class="table-striped">
            <thead>
              <tr>
                <th>Action</th>
                <th>Nama Port</th>
                <th>Biaya Bongkar</th>
                <th>Dibuat Oleh</th>
                <th>Dibuat Pada</th>
                <th>Diubah Oleh</th>
                <th>Diubah Pada</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let row of componentPort.dataPort">
                <td>
                  <button (click)="onSelectPort(row);ModalPortLoad.hide()" class="btn btn-sm btn-primary"  data-toggle="modal" triggers="mouseenter:mouseleave"><i class="fa fa-check"></i></button>
                </td> 
                <td>{{row.port_name}}</td>
                <td>{{row.biaya_bongkar}}</td>
                <td>{{row.createdby}}</td>
                <td>{{row.created_at | slice:0:10}}</td>  
                <td>{{row.updatedby}}</td>  
                <td>{{row.updated_at | slice:0:10}}</td>   
              </tr>
            </tbody>
              <tfoot>
                <tr>
                  <th><input hidden class="searchInputPort"/></th>
                  <th><input id="portsearchM2" type="text" class="searchInputPort" placeholder="Port" /></th>
                  <th><input hidden class="searchInputPort"/></th>
                  <th><input hidden class="searchInputPort"/></th>
                  <th><input hidden class="searchInputPort"/></th>
                  <th><input hidden class="searchInputPort"/></th>
                  <th><input hidden class="searchInputPort"/></th>
                </tr>
              </tfoot>
          </table>
      </div>
    </div>
  </div>
</div>

<div  bsModal #ModalRouteLoad="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog  modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title"><strong>Pilih Rute</strong></div>
        <button type="button" class="close" (click)="ModalRouteLoad.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
          <table datatable  [dtOptions]="dtOptionsRoute" [dtTrigger]="dtTriggerRoute"  class="table-striped">
              <thead>
                <tr>
                  <th>Rute</th>
                  <th>Lokasi Muat</th>
                  <th>Lokasi Bongkar</th>
                  <th>Tipe</th>
                  <th>Diubah Oleh</th>
                  <th>Diubah Pada</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let row of componentRoute.dataRoute">
                  <td>
                      <button (click)="onSelectRoute(row);ModalRouteLoad.hide()" class="btn btn-sm btn-primary"  data-toggle="modal" triggers="mouseenter:mouseleave"><i class="fa fa-check"></i></button>
                  </td>
                  <td>{{row.route_name}}</td>
                  <td>{{row.loadtext}}</td>
                  <td>{{row.unloadtext}}</td>
                  <td>{{row.route_type}}</td>
                  <td>{{row.updatedby}}</td>
                  <td>{{row.updated_at | slice:0:10}}</td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th><input hidden class="searchInputRoute"/></th>
                  <th><input id="namesearch2" type="text" class="searchInputRoute" placeholder="Rute" /></th>
                  <th><input id="loadsearch2" type="text" class="searchInputRoute" placeholder="Lokasi Muat" /></th>
                  <th><input id="unloadsearch2" type="text" class="searchInputRoute" placeholder="Lokasi Bongkar" /></th>
                  <th><input id="typesearch2" type="text" class="searchInputRoute" placeholder="Tipe" /></th>
                  <th><input hidden class="searchInputRoute"/></th>
                  <th><input hidden class="searchInputRoute"/></th>
                </tr>
              </tfoot>
          </table>
      </div>
    </div>
  </div>
</div>


<div  bsModal #ModalMaterialLoad="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog  modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title"><strong>Pilih Material</strong></div>
        <button type="button" class="close" (click)="ModalMaterialLoad.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
          <table datatable  [dtOptions]="dtOptionsMaterial" [dtTrigger]="dtTriggerMaterial"  class="table-striped">
            <thead>
              <tr>
                <th>Action</th>
                <th>Code</th>
                <th>Material</th>
                <th>Harga</th>
                <th>UOM</th>
                <th>Diubah Oleh</th>
                <th>Diubah Pada</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let row of componentMaterial.dataMaterial">
                <td>
                  <button (click)="onSelectMaterial(row);ModalMaterialLoad.hide()" class="btn btn-sm btn-primary"  data-toggle="modal" triggers="mouseenter:mouseleave"><i class="fa fa-check"></i></button>
                </td> 
                <td>{{row.material_code}}</td>
                <td>{{row.material_name}}</td>
                <td>{{row.material_price}}</td>
                <td>{{row.uom}}</td>
                <td>{{row.updatedby}}</td>  
                <td>{{row.updated_at | slice:0:10}}</td> 
              </tr>
            </tbody> 
            <tfoot>
              <tr>
                <th><input hidden class="searchInputMaterial"/></th>
                <th><input id="codematerial2" type="text" class="searchInputMaterial" placeholder="Kode" /></th>
                <th><input id="namematerial2" type="text" class="searchInputMaterial" placeholder="Nama" /></th>
                <th><input hidden class="searchInputMaterial"/></th>
                <th><input hidden class="searchInputMaterial"/></th>
                <th><input hidden class="searchInputMaterial"/></th>
                <th><input hidden class="searchInputMaterial"/></th>
              </tr>
            </tfoot>
          </table>
      </div>
    </div>
  </div>
</div>

<div  bsModal #ModalCapacityLoad="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog  modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title"><strong>Pilih Kapasitas</strong></div>
        <button type="button" class="close" (click)="ModalCapacityLoad.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
          <table datatable  [dtOptions]="dtOptionsCapacity" [dtTrigger]="dtTriggerCapacity"   class="table-striped">
            <thead>
              <tr>
                <th>Action</th>
                <th>Kode</th>
                <th>Keterangan</th>
                <th>Tipe Kendaraan</th>
                <th>Tonnase</th>
                <th>Diubah Oleh</th>
                <th>Diubah Pada</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let row of componentCapacity.dataCapacity">
                <td>
                  <button (click)="onSelectCapacity(row);ModalCapacityLoad.hide()" class="btn btn-sm btn-primary"  data-toggle="modal" triggers="mouseenter:mouseleave"><i class="fa fa-check"></i></button>
                </td> 
                <td>{{row.capacity_code}}</td>
                <td>{{row.capacity_note}}</td>
                <td>{{row.vehicleType}}</td>
                <td>{{row.tonCode}}</td>
                <td>{{row.updatedby}}</td>  
                <td>{{row.updated_at | slice:0:10}}</td>    
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th><input hidden class="searchInputCap"/></th>
                <th><input id="codecapacity2" type="text" class="searchInputCap" placeholder="Kode" /></th>
                <th><input id="namecapacity2" type="text" class="searchInputCap" placeholder="Nama" /></th>
                <th><input id="vehiclesearch2" type="text" class="searchInputCap" placeholder="Kendaraan"/></th>
                <th><input hidden class="searchInputCap"/></th>
                <th><input hidden class="searchInputCap"/></th>
                <th><input hidden class="searchInputCap"/></th>
              </tr>
            </tfoot>
          </table>
      </div>
    </div>
  </div>
</div>

<div  bsModal #ModalHistory="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title"><strong>Pilih Rute</strong></div>
        <button type="button" class="close" (click)="ModalHistory.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <table datatable  [dtOptions]="dtOptionsHistory" [dtTrigger]="dtTrigger"  class="table-striped">
          <thead>
            <tr>
              <th>Port Transit</th>
              <th>Rute</th>
              <th>Kapasitas</th>
              <th>Material</th>
              <th>Total</th>
              <th>Diubah Pada</th>
              <th>Diubah Oleh</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let row of dataHistory">
              <td>{{row.transit_port_name}}</td>
              <td>{{row.route_name}}</td>
              <td>{{row.capacity_name}}</td>
              <td>{{row.material_name}}</td>
              <td>{{row.total_amount}}</td>
              <td>{{row.updated_at | slice:0:10}}</td>
              <td>{{row.updated_by_text}}</td>
          </tbody>      
        </table>
      </div>
    </div>
  </div>
</div>


<div  bsModal #ModalTravelCost="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog" style="max-width: 80%;" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title"><strong>{{action}} Data Biaya Perjalanan</strong></div>
        <small><code style="color: black">&nbsp;&nbsp;*(semua kolom wajib di isi)</code></small>
        <button type="button" #modalSaveClose class="close" (click)="ModalTravelCost.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
          <div class="card">
              <div class="card-body">
                <div [formGroup]="costForm">
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group row">
                      <label class="col-md-3 col-form-label" for="text-input">Port Transit</label>
                      <input hidden [(ngModel)]="costModel.transit_port" [ngModelOptions]="{standalone: true}" disabled type="text" >
                      <div class="col-md-7">
                        <input [(ngModel)]="portSelect" disabled type="text" class="form-control" formControlName="transit_port">
                      </div>
                      <div class="col-md-2">
                        <button class="btn btn-sm btn-primary" (click)="showPort();ModalPort.show()"
                        data-toggle="modal" ><i class="fa fa-plus"></i></button>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-md-3 col-form-label" for="text-input">Material</label>
                      <input hidden [(ngModel)]="costModel.material_id" [ngModelOptions]="{standalone: true}"  disabled type="text" >
                      <div class="col-md-7">
                        <input [(ngModel)]="materialSelect" disabled type="text" class="form-control" formControlName="material_id">
                      </div>
                      <div class="col-md-2">
                        <button class="btn btn-sm btn-primary" (click)="ModalMaterial.show();showMaterial();"
                        data-toggle="modal" ><i class="fa fa-plus"></i></button>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-md-3 col-form-label" for="text-input">Jarak</label>
                      <div class="col-md-8">
                          <input [(ngModel)]="costModel.distance" type="text" class="form-control" formControlName="distance" currencyMask [options]="{ prefix: '', thousands: '.', decimal: ',' ,precision: '0',align: 'left' }">
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-md-3 col-form-label" for="text-input">Premi Trip</label>
                      <div class="col-md-8">
                          <input [(ngModel)]="costModel.trip_premi" type="text" class="form-control" formControlName="trip_premi" currencyMask [options]="{ prefix: '', thousands: '.', decimal: ',' ,precision: '0',align: 'left' }">
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-md-3 col-form-label" for="text-input">Berlaku Sejak</label>
                      <div class="col-md-8">
                          <input [(ngModel)]="costModel.valid_from" type="date" class="form-control" formControlName="valid_from">
                      </div>
                    </div> 
                  </div>

                  <div class="col-md-6">
                    <div class="form-group row">
                      <label class="col-md-3 col-form-label" for="text-input">Rute</label>
                      <input hidden [(ngModel)]="costModel.route_id" [ngModelOptions]="{standalone: true}" disabled type="text" class="form-control">
                      <div class="col-md-7">
                        <input [(ngModel)]="ruteSelect" disabled type="text" class="form-control" formControlName="route_id">
                      </div>
                      <div class="col-md-2">
                        <button class="btn btn-sm btn-primary" (click)="showRoute();ModalRoute.show()" 
                        data-toggle="modal" ><i class="fa fa-plus"></i></button>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-md-3 col-form-label" for="text-input">Kapasitas</label>
                      <input hidden [(ngModel)]="costModel.capacity_id" [ngModelOptions]="{standalone: true}" disabled type="text" class="form-control">
                      <div class="col-md-7">
                        <input [(ngModel)]="capacitySelect" disabled type="text" class="form-control" formControlName="capacity_id">
                      </div>
                      <div class="col-md-2">
                        <button class="btn btn-sm btn-primary" (click)="showCapacity();ModalCapacity.show()"
                        data-toggle="modal" ><i class="fa fa-plus"></i></button>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-md-3 col-form-label" for="text-input">Ritase</label>
                      <div class="col-md-8">
                          <input [(ngModel)]="costModel.ritase" type="text" class="form-control" formControlName="ritase" currencyMask [options]="{ prefix: '', thousands: '.', decimal: ',' ,precision: '0',align: 'left' }"> 
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-md-3 col-form-label" for="text-input">Tipe Trip</label>
                      <div class="col-md-8">
                          <select [(ngModel)]="costModel.trip_type" id="select" name="select" class="form-control" formControlName="trip_type">
                            <option *ngFor="let row of dataType" [value]='row.id'><b>{{row.text}}</b></option>
                          </select>
                      </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-md-3 col-form-label" for="text-input">Sampai</label>
                    <div class="col-md-8">
                        <input disabled type="text" value="31/12/9998" class="form-control">
                    </div>
                  </div>
                  </div>
                </div> 
              </div>
             <br>
             <div class="card">
                <div class="card-header">
                  <strong>Detail Biaya Perjalanan</strong>
                </div>
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group row">
                          <label class="col-md-3 col-form-label" for="text-input">Tipe Biaya</label>
                          <div class="col-md-7">
                            <input [(ngModel)]="tambahanModel.costType" disabled type="text" class="form-control">
                          </div>
                          <div class="col-md-2">
                            <button class="btn btn-sm btn-primary" (click)="ModalCostType.show()"
                            data-toggle="modal" ><i class="fa fa-plus"></i></button>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group row">
                          <label class="col-md-3 col-form-label" for="text-input">Jumlah</label>
                          <div class="col-md-8">
                              <input [(ngModel)]="tambahanModel.count" currencyMask [options]="{ prefix: '', thousands: '.', decimal: ',' ,precision: '0',align: 'left' }" type="text" class="form-control">
                              <input hidden [(ngModel)]="tambahanModel.id" type="text" class="form-control">
                          </div>
                        </div>
                      </div>
                    </div>

                      <br>
                      <div style="text-align: center">
                          <button type="button" (click)="AddDataTambahan();" class="btn btn-sm btn-primary" >&nbsp;Tambah&nbsp;</button>
                      </div>
                      <br>
                      <br>
                      <div class="card">
                          <div class="card-body">
                              <table class="table table-striped table-sm">
                                  <thead>
                                  <tr>
                                      <th hidden>Id Biaya</th>
                                      <th>Nama Biaya</th>
                                      <th>Jumlah</th>
                                      <th>Tipe</th>
                                      <th>Action</th>
                                  </tr>
                                  </thead>
                                  <tbody>
                                  <tr *ngFor="let row of dataTambahan; let i=index">
                                      <td hidden>{{row.id}}</td>
                                      <td>{{row.costType}}</td>
                                      <td>{{row.count}}</td>
                                      <td>{{row.type_text}}</td>
                                      <td><button class="btn btn-sm btn-danger" triggers="mouseenter:mouseleave" (click)="deleteDetail(i);"><i class="fa fa-trash"></i></button></td>
                                  </tr>
                                  </tbody>
                              </table>
                          </div>
                      </div>
                      <h2><b>Total Rp. {{labelTotal | number}}</b></h2>
                  </div>
              </div>

              </div>
          </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="ModalTravelCost.hide()"><b>Close</b></button>
        <button [hidden]="!btnCrud" type="button" class="btn btn-primary"  (click)="saveDataTravel()"><i class="fa fa-save"></i>&nbsp;&nbsp;<b>Save</b>&nbsp;&nbsp;</button>
      </div>
    </div>
  </div>
</div>

<div  bsModal #ModalRoute="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog  modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title"><strong>Pilih Rute</strong></div>
        <button type="button" class="close" (click)="ModalRoute.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
          <table datatable  [dtOptions]="dtOptions2" [dtTrigger]="dtTrigger2"  class="table-striped">
              <thead>
                <tr>
                  <th>Action</th>
                  <th>Rute</th>
                  <th>Lokasi Muat</th>
                  <th>Lokasi Bongkar</th>
                  <th>Tipe</th>
                  <th>Diubah Oleh</th>
                  <th>Diubah Pada</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let row of componentRoute.dataRoute">
                  <td>
                      <button (click)="onSelectRoute(row);ModalRoute.hide()" class="btn btn-sm btn-primary"  data-toggle="modal" triggers="mouseenter:mouseleave"><i class="fa fa-check"></i></button>
                  </td>
                  <td>{{row.route_name}}</td>
                  <td>{{row.loadtext}}</td>
                  <td>{{row.unloadtext}}</td>
                  <td>{{row.route_type}}</td>
                  <td>{{row.updatedby}}</td>
                  <td>{{row.updated_at | slice:0:10}}</td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th><input hidden class="searchInput2"/></th>
                  <th><input id="namesearch" type="text" class="searchInput2" placeholder="Rute" /></th>
                  <th><input id="loadsearch" type="text" class="searchInput2" placeholder="Lokasi Muat" /></th>
                  <th><input id="unloadsearch" type="text" class="searchInput2" placeholder="Lokasi Bongkar" /></th>
                  <th><input id="typesearch" type="text" class="searchInput2" placeholder="Tipe" /></th>
                  <th><input hidden class="searchInput2"/></th>
                  <th><input hidden class="searchInput2"/></th>
                </tr>
              </tfoot>
          </table>
      </div>
    </div>
  </div>
</div>

<div  bsModal #ModalPort="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog  modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title"><strong>Pilih Port</strong></div>
        <button type="button" class="close" (click)="ModalPort.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
          <table datatable  [dtOptions]="dtOptions3" [dtTrigger]="dtTrigger3"  class="table-striped">
            <thead>
              <tr>
                <th>Action</th>
                <th>Nama Port</th>
                <th>Biaya Bongkar</th>
                <th>Dibuat Oleh</th>
                <th>Dibuat Pada</th>
                <th>Diubah Oleh</th>
                <th>Diubah Pada</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let row of componentPort.dataPort">
                <td>
                  <button (click)="onSelectPort(row);ModalPort.hide()" class="btn btn-sm btn-primary"  data-toggle="modal" triggers="mouseenter:mouseleave"><i class="fa fa-check"></i></button>
                </td>  
                <td>{{row.port_name}}</td>
                <td>{{row.biaya_bongkar}}</td>
                <td>{{row.createdby}}</td>
                <td>{{row.created_at | slice:0:10}}</td>  
                <td>{{row.updatedby}}</td>  
                <td>{{row.updated_at | slice:0:10}}</td>  
              </tr>
            </tbody>
              <tfoot>
                <tr>
                  <th><input hidden class="searchInput3"/></th>
                  <th><input id="portsearchM" type="text" class="searchInput3" placeholder="Port" /></th>
                  <th><input hidden class="searchInput3"/></th>
                  <th><input hidden class="searchInput3"/></th>
                  <th><input hidden class="searchInput3"/></th>
                  <th><input hidden class="searchInput3"/></th>
                  <th><input hidden class="searchInput3"/></th>
                </tr>
              </tfoot>
          </table>
      </div>
    </div>
  </div>
</div>

<div  bsModal #ModalMaterial="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog  modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title"><strong>Pilih Material</strong></div>
        <button type="button" class="close" (click)="ModalMaterial.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
          <table datatable  [dtOptions]="dtOptions4" [dtTrigger]="dtTrigger4"  class="table-striped">
            <thead>
              <tr>
                <th>Code</th>
                <th>Material</th>
                <th>Harga</th>
                <th>UOM</th>
                <th>Diubah Oleh</th>
                <th>Diubah Pada</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let row of componentMaterial.dataMaterial">
                <td>
                  <button (click)="onSelectMaterial(row);ModalMaterial.hide()" class="btn btn-sm btn-primary"  data-toggle="modal" triggers="mouseenter:mouseleave"><i class="fa fa-check"></i></button>
                </td> 
                <td>{{row.material_code}}</td>
                <td>{{row.material_name}}</td>
                <td>{{row.material_price}}</td>
                <td>{{row.uom}}</td>
                <td>{{row.updatedby}}</td>  
                <td>{{row.updated_at | slice:0:10}}</td> 
              </tr>
            </tbody> 
            <tfoot>
              <tr>
                <th><input hidden class="searchInput4"/></th>
                <th><input id="codematerial" type="text" class="searchInput4" placeholder="Kode" /></th>
                <th><input id="namematerial" type="text" class="searchInput4" placeholder="Nama" /></th>
                <th><input hidden class="searchInput4"/></th>
                <th><input hidden class="searchInput4"/></th>
                <th><input hidden class="searchInput4"/></th>
                <th><input hidden class="searchInput4"/></th>
              </tr>
            </tfoot>
          </table>
      </div>
    </div>
  </div>
</div>


<div  bsModal #ModalCapacity="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog  modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title"><strong>Pilih Kapasitas</strong></div>
        <button type="button" class="close" (click)="ModalCapacity.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
          <table datatable  [dtOptions]="dtOptions5" [dtTrigger]="dtTrigger5"  class="table-striped">
            <thead>
              <tr>
                <th>Kode</th>
                <th>Keterangan</th>
                <th>Tipe Kendaraan</th>
                <th>Tonnase</th>
                <th>Diubah Oleh</th>
                <th>Diubah Pada</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let row of componentCapacity.dataCapacity">
                <td>
                  <button (click)="onSelectCapacity(row);ModalCapacity.hide()" class="btn btn-sm btn-primary"  data-toggle="modal" triggers="mouseenter:mouseleave"><i class="fa fa-check"></i></button>
                </td> 
                <td>{{row.capacity_code}}</td>
                <td>{{row.capacity_note}}</td>
                <td>{{row.vehicleType}}</td>
                <td>{{row.tonCode}}</td>
                <td>{{row.updatedby}}</td>  
                <td>{{row.updated_at | slice:0:10}}</td>    
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th><input hidden class="searchInput5"/></th>
                <th><input id="codecapacity" type="text" class="searchInput5" placeholder="Kode" /></th>
                <th><input id="namecapacity" type="text" class="searchInput5" placeholder="Nama" /></th>
                <th><input id="vehiclesearch" type="text" class="searchInput5" placeholder="Kendaraan"/></th>
                <th><input hidden class="searchInput5"/></th>
                <th><input hidden class="searchInput5"/></th>
                <th><input hidden class="searchInput5"/></th>
              </tr>
            </tfoot>
          </table>
      </div>
    </div>
  </div>
</div>

<div  bsModal #ModalCostType="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog  modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title"><strong>Pilih Kapasitas</strong></div>
        <button type="button" class="close" (click)="ModalCostType.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
          <table datatable  [dtOptions]="dtOptions6" [dtTrigger]="dtTrigger"  class="table-striped">
            <thead>
              <tr>
                <th>Action</th>
                <th>Kode</th>
                <th>Tipe</th>
                <th>Nama Biaya</th>
                <th>Screen</th>
                <th>GL Code</th>
                <th>UJ Pokok</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let row of componentCostType.dataCost">
                <td>
                  <button (click)="onSelectCostType(row);ModalCostType.hide()" class="btn btn-sm btn-primary"  data-toggle="modal" triggers="mouseenter:mouseleave"><i class="fa fa-check"></i></button>
                </td> 
                <td>{{row.code}}</td>
                <td>{{row.type_text}}</td>
                <td>{{row.name}}</td>
                <td>{{row.screen_text}}</td>
                <td>{{row.gl_code}}</td>
                <td><input disabled type="checkbox" [checked]="row.basic_cost"/></td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th><input hidden class="searchInput6"/></th>
                <th><input id="codecost" type="text" class="searchInput6" placeholder="Kode" /></th>
                <th><input id="namecost" type="text" class="searchInput6" placeholder="Type" /></th>
                <th><input hidden class="searchInput6"/></th>
                <th><input hidden class="searchInput6"/></th>
                <th><input hidden class="searchInput6"/></th>
                <th><input hidden class="searchInput6"/></th>
              </tr>
            </tfoot>
          </table>
      </div>
    </div>
  </div>
</div>



<ngx-spinner  bdColor = "rgba(248,248,248,0.95)" size = "large" color = "#262424" type = "ball-spin-fade" >
  <p style="font-size: 20px; color: white"></p>
</ngx-spinner>