import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { TransportService} from '../../../services/api.service';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { saveAs } from 'file-saver';
import { FormControl, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { Driver } from '@/model/driver';
import { Route } from '@/model/route';
import { Relation } from '@/model/relation';
import { Material } from '@/model/material';
import { MasterCode } from '@/model/masterCode';
import { FinalResultDto } from '@/model/reportDto';
@Component({
  selector: 'app-report-general',
  templateUrl: './report-general.component.html',
  styleUrls: ['./report-general.component.scss']
})
export class ReportGeneralComponent implements OnInit, AfterViewInit {
  public from : Date;
  public to : Date;
  public clean = true;
  driverName: string;
  routeName: string;
  customerName: string;
  sellerName: string;
  materialName: string;
  amountDebt: number;
  listCollumn= new Array();
  dtOptionsMaterial: any = {};
  dtTriggerMaterial = new Subject();
  dtOptionsSupplier: any = {};
  dtOptionsCustomer: any = {};
  dtTriggerCustomer = new Subject();
  dtTriggerSupplier = new Subject();
  dtOptionsRoute: any = {};
  dtTriggerRoute = new Subject();
  dtOptionsDriver: any = {};
  dtTriggerDriver = new Subject();
  dataSupplier: Relation[]=[];
  dataRoute: Route[] = [];
  dataDriver: Driver[] = [];
  dataRelation: Relation[]=[];
  dataMaterial: Material[] = [];
  doType: MasterCode[] = [];
  chargeType: MasterCode[] = [];
  statusType: MasterCode[] = [];  
  finalResult: FinalResultDto;
  public dt: any;
  public cb: any;
  @Input() genModel = { driver_active: null, driver_inactive: null, truck_no: null, muat_from: null, muat_to: null,
    bongkar_from: null, bongkar_to: null, realization_from: null, realization_to: null, depart_from: null, depart_to: null, 
    payment_from: null, payment_to: null, driver: null, main_do_no: null, spk_no: null, contract_no: null, 
    main_do_date_from: null, main_do_date_to: null, main_do_type: null, charge_type: null,  do_detail_status: null, route: null, 
    customer: null, seller: null, material: null, listOfVisibleColumns: new Array(), location: null, invoice_date_from: null,
    invoice_date_to: null}
    
  @Input() checkboxAll = { MainDoNo: false, SPKNo: false, ContractNo: false, MainDoDate: false, DoType: false, ChargeType: false, 
    Route: false, Customer: false, Seller: false, Material: false, Ekspedisi: false, RealDate: false, TruckNo: false, PaymentNo: false, 
    TravelCostBongkar: false, EstTon: false, DetailDoNo: false, DetailDoYear: false, DetailDoStatus: false, DetailDoDeliverDate: false,
    ReffRealization: false, WBReff: false, SendTonnage: false, ReceivedTonnage: false, Driver: false, TravelCostGaji: false, TravelCostBBM: false,
    MainSPONo: false, SPKKebun: false, EstPack: false, Oa: false, DepartDate: false, DriverLicense: false, AgamaSupir: false, PaymentDate: false, 
    SendDate: false, ReceiveDate: false, Remark: false, SLCode: false, SLCode2: false, SLCode3: false, TravelCostMakan: false, TravelCostJarak: false, 
    PaymentAmount: false, TravelCost: false, SIMValid: false, joinDate: false, contractDate: false, capacity: false, invoiceDate: false}

  constructor( public service: TransportService, private spinner: NgxSpinnerService) {
  }
  
  selectedItems: any = null;
  form: FormGroup = new FormGroup({
    items: new FormControl(null)
  });

    ngOnInit(): void {
      this.service.getListAllMasterCode().subscribe(resp =>{
        this.doType = resp.filter(x => x.category == "DoType");
        this.chargeType = resp.filter(x => x.category == "chargeType");
        this.statusType = resp.filter(x => x.category == "DoDetailStatus");
      })

      this.dtOptionsMaterial = {
        retrieve: true,
        pageLength: 5,
        scrollX: true,
        scrollCollapse: true,
        serverSide: true,
        processing: true,
        searching : false,
        ajax: (dataTablesParameters: any, callback) => {
          this.dt = dataTablesParameters; this.cb = callback;
          this.getDataMaterial(dataTablesParameters, callback,  $('#codeMaterial').val().toString(), $('#nameMaterial').val().toString(),true);
        },
        columnDefs: [
          { width: 100, targets: 0 },
          { width: 200, targets: 2 },
        ]
      };

      this.dtOptionsCustomer = {
        retrieve: true,
        pageLength: 5,
        scrollX: true,
        scrollCollapse: true,
        serverSide: true,
        processing: true,
        searching : false,
        ajax: (dataTablesParameters: any, callback) => {
         this.dt = dataTablesParameters; this.cb = callback;
         this.getDataRelation(dataTablesParameters, callback, $('#codecustomer').val().toString(), $('#namecustomer').val().toString(), 
         $('#addresscustomer').val().toString(), "CUSTOMER",
         $('#slcustomer').val().toString(),true);
        },
        columnDefs: [
          { width: 100, targets: [0,1,4,5,6,7,8,9,10] },
          { width: 200, targets: 2 },
          { width: 300, targets: 3 },
        ],
      };

      this.dtOptionsSupplier = {
        retrieve: true,
        pageLength: 5,
        scrollX: true,
        scrollCollapse: true,
        serverSide: true,
        processing: true,
        searching : false,
        ajax: (dataTablesParameters: any, callback) => {
         this.dt = dataTablesParameters; this.cb = callback;
         this.getSupplier(dataTablesParameters, callback, $('#codeSup').val().toString(), $('#nameSup').val().toString(), 
         $('#addressSup').val().toString(), "PENJUAL",
         $('#slSup').val().toString(),true);
        },
        columnDefs: [
          { width: 100, targets: [0,1,4,5,6,7,8,9,10] },
          { width: 200, targets: 2 },
          { width: 300, targets: 3 },
        ],
      };


      
      this.dtOptionsDriver = {
        retrieve: true,
        pageLength: 5,
        scrollX: true,
        scrollCollapse: true,
        serverSide: true,
        processing: true,
        searching : false,
        ajax: (dataTablesParameters: any, callback,) => {
         this.dt = dataTablesParameters; this.cb = callback;
         this.getDataDriver(dataTablesParameters, callback, "", "","","","", "","","","", "","","", "",  true);
         },
        columnDefs: [
          { width: 150, targets: [0,2,3,4,5,6,7,8,9,10,12,13,14,15] },
          { width: 200, targets: [1] },
          { width: 300, targets: [11] },
        ],
      };

      this.dtOptionsRoute = {
        retrieve: true,
        pageLength: 5,
        scrollX: true,
        scrollCollapse: true,
        serverSide: true,
        processing: true,
        searching : false,
        ajax: (dataTablesParameters: any, callback) => {
         this.dt = dataTablesParameters; this.cb = callback;
         this.getDataRoute(dataTablesParameters, callback, $('#nameRute').val().toString(),  $('#typeRute').val().toString()
         ,  $('#unloadRute').val().toString(),  $('#loadRute').val().toString(), null,true);
        },
        columnDefs: [
          { width: 100, targets: [0,1,4,5,6,7,8] },
          { width: 200, targets: 2 },
          { width: 200, targets: 3 },
         ],
      };

      const that = this;

      $('.searchRute').on("keyup", function(e) {
        var key = e.which;
        if (key == 13) // the enter key ascii code
        {
          that.getDataRoute(that.dt, that.cb,  $('#nameRute').val().toString(),  $('#typeRute').val().toString()
          ,  $('#unloadRute').val().toString(),  $('#loadRute').val().toString(), null,false);
        }
      }); 

      $('.searchInputD').on("keyup", function(e) {
        var key = e.which;
        if (key == 13) // the enter key ascii code
        {
          that.getDataDriver(that.dt, that.cb, $('#driver_name').val().toString(), $('#driver_license').val().toString(), 
          $('#driver_license_type').val().toString(),$('#agama').val().toString(),$('#birthplace').val().toString(),
          $('#ktp_no').val().toString(),$('#tanggal_lahir').val().toString(),$('#sl_code').val().toString(),
          $('#sl_code2').val().toString(),$('#sl_code3').val().toString(),$('#address').val().toString(),
          $('#valid_to').val().toString(),$('#valid_to_contract').val().toString(), false);
        }
      });

      $('.searchMaterial').on("keyup", function(e) {
        var key = e.which;
        if (key == 13) // the enter key ascii code
        {
          that.getDataMaterial(that.dt, that.cb, $('#codeMaterial').val().toString(), $('#nameMaterial').val().toString(),false);
        }
      }); 

      $('.searchSupplier').on("keyup", function(e) {
        var key = e.which;
        if (key == 13) // the enter key ascii code
        {
          that.getSupplier(that.dt, that.cb, $('#codeSup').val().toString(), $('#nameSup').val().toString(), 
          $('#addressSup').val().toString(), "SUPPLIER",
          $('#slSup').val().toString(),false);
        }
      });  

      $('.searchCustomer').on("keyup", function(e) {
        var key = e.which;
        if (key == 13) // the enter key ascii code
        {
          that.getDataRelation(that.dt, that.cb, $('#codecustomer').val().toString(), $('#namecustomer').val().toString(), 
          $('#addresscustomer').val().toString(), "CUSTOMER",
          $('#slcustomer').val().toString(),false);
        }
      }); 

    }   

    ngAfterViewInit(): void {
    }
  
    ngOnDestroy(): void {
    }

  messageWarning(text: string){
    Swal.fire({
      title: ""+text,
      type: 'warning',
      confirmButtonText: '      Ok!     '
    });
    return;
  }

  clear(){
    this.genModel = { driver_active: null, driver_inactive: null, truck_no: null, muat_from: null, muat_to: null,
      bongkar_from: null, bongkar_to: null, realization_from: null, realization_to: null, depart_from: null, depart_to: null, 
      payment_from: null, payment_to: null, driver: null, main_do_no: null, spk_no: null, contract_no: null, 
      main_do_date_from: null, main_do_date_to: null, main_do_type: null, charge_type: null,  do_detail_status: null, route: null, 
      customer: null, seller: null, material: null, listOfVisibleColumns: new Array(), location: null, invoice_date_from: null,
      invoice_date_to: null};
      
      this.checkboxAll = { MainDoNo: false, SPKNo: false, ContractNo: false, MainDoDate: false, DoType: false, ChargeType: false, 
        Route: false, Customer: false, Seller: false, Material: false, Ekspedisi: false, RealDate: false, TruckNo: false, PaymentNo: false, 
        TravelCostBongkar: false, EstTon: false, DetailDoNo: false, DetailDoYear: false, DetailDoStatus: false, DetailDoDeliverDate: false,
        ReffRealization: false, WBReff: false, SendTonnage: false, ReceivedTonnage: false, Driver: false, TravelCostGaji: false, TravelCostBBM: false,
        MainSPONo: false, SPKKebun: false, EstPack: false, Oa: false, DepartDate: false, DriverLicense: false, AgamaSupir: false, PaymentDate: false, 
        SendDate: false, ReceiveDate: false, Remark: false, SLCode: false, SLCode2: false, SLCode3: false, TravelCostMakan: false, TravelCostJarak: false,
        PaymentAmount: false, TravelCost: false, SIMValid: false, joinDate: false, contractDate: false, capacity: false, invoiceDate: false}
  }

  checkAll(){
    if (this.clean == false){
        this.checkboxAll = { MainDoNo: false, SPKNo: false, ContractNo: false, MainDoDate: false, DoType: false, ChargeType: false, 
        Route: false, Customer: false, Seller: false, Material: false, Ekspedisi: false, RealDate: false, TruckNo: false, PaymentNo: false, 
        TravelCostBongkar: false, EstTon: false, DetailDoNo: false, DetailDoYear: false, DetailDoStatus: false, DetailDoDeliverDate: false,
        ReffRealization: false, WBReff: false, SendTonnage: false, ReceivedTonnage: false, Driver: false, TravelCostGaji: false, TravelCostBBM: false,
        MainSPONo: false, SPKKebun: false, EstPack: false, Oa: false, DepartDate: false, DriverLicense: false, AgamaSupir: false, PaymentDate: false, 
        SendDate: false, ReceiveDate: false, Remark: false, SLCode: false, SLCode2: false, SLCode3: false, TravelCostMakan: false, TravelCostJarak: false,
        PaymentAmount: false, TravelCost: false, SIMValid: false, joinDate: false, contractDate: false, capacity: false, invoiceDate: false}
        this.genModel.listOfVisibleColumns = []
        this.clean = true;
    }
    else{
      this.checkboxAll = { MainDoNo: true, SPKNo: true, ContractNo: true, MainDoDate: true, DoType: true, ChargeType: true, 
        Route: true, Customer: true, Seller: true, Material: true, Ekspedisi: true, RealDate: true, TruckNo: true, PaymentNo: true, 
        TravelCostBongkar: true, EstTon: true, DetailDoNo: true, DetailDoYear: true, DetailDoStatus: true, DetailDoDeliverDate: true,
        ReffRealization: true, WBReff: true, SendTonnage: true, ReceivedTonnage: true, Driver: true, TravelCostGaji: true, TravelCostBBM: true,
        MainSPONo: true, SPKKebun: true, EstPack: true, Oa: true, DepartDate: true, DriverLicense: true, AgamaSupir: true, PaymentDate: true, 
        SendDate: true, ReceiveDate: true, Remark: true, SLCode: true, SLCode2: true, SLCode3: true, TravelCostMakan: true, TravelCostJarak: true, 
        PaymentAmount: false, TravelCost: true, SIMValid: true, joinDate: true, contractDate: true, capacity: true, invoiceDate: true}
        this.clean = false;
        this.genModel.listOfVisibleColumns = ['No_DO', 'No_SPK', 'No_Contract', 'Tanggal_DO', 'Tipe_DO', 'Tipe_Biaya', 
        'Route', 'Pelanggan', 'Penjual', 'Material', 'Ekspedisi', 'Tanggal_Realisasi', 'No_Truk', 'Kapasitas', 'No_Pembayaran', 
        'Biaya_Bongkar', 'Est_Berat', 'DO_Kecil', 'Tahun_DO', 'Status_DO', 'Tanggal_Selesai',
        'Reff', 'WB_Reff', 'Tonnase_Kirim', 'Tonnase_Terima', 'Supir', 'Biaya_Gaji', 'Biaya_BBM',
        'No_SPO', 'Kebun', 'Est_Kemasan', 'OA_Ekspedisi', 'Tanggal_Bagi_DO', 'SIM', 'Agama', 'Tanggal_Bayar', 
        'Tanggal_Muat', 'Tanggal_Bongkar', 'Keterangan', 'SLCode', 'SLCode2', 'SLCode3', 'Biaya_Makan', 'Jarak', 
         'Biaya_Perjalanan', 'SIM_Expired' , 'Tanggal_Bergabung', 'Kontrak_Dari', 'Tanggal_Invoice' ]
    }
  }
  
  onChange(column: any, event: any) {
    const { checked, name } = event.target;
    if (checked == true){
      this.genModel.listOfVisibleColumns.push(column)
    }
    else{
      this.genModel.listOfVisibleColumns.splice(this.genModel.listOfVisibleColumns.indexOf(column), 1);
    }
  }

  setDefault(){
    this.checkboxAll = { MainDoNo: true, SPKNo: true, ContractNo: true, MainDoDate: true, DoType: false, ChargeType: false, 
      Route: false, Customer: false, Seller: false, Material: true, Ekspedisi: true, RealDate: true, TruckNo: true, PaymentNo: false, 
      TravelCostBongkar: true, EstTon: true, DetailDoNo: true, DetailDoYear: false, DetailDoStatus: true, DetailDoDeliverDate: false,
      ReffRealization: true, WBReff: false, SendTonnage: true, ReceivedTonnage: true, Driver: true, TravelCostGaji: true, TravelCostBBM: true,
      MainSPONo: true, SPKKebun: true, EstPack: true, Oa: true, DepartDate: false, DriverLicense: true, AgamaSupir: true, PaymentDate: false, 
      SendDate: true, ReceiveDate: true, Remark: false, SLCode: false, SLCode2: false, SLCode3: false, TravelCostMakan: true, TravelCostJarak: true, 
      PaymentAmount: false, TravelCost: true, SIMValid: true, joinDate: false, contractDate: false, capacity: false, invoiceDate: false}
      this.genModel.listOfVisibleColumns = ['No_DO', 'No_SPK', 'Tanggal_DO', 'Material', 'Ekspedisi', 'Tanggal_Realisasi', 'No_Truk', 
      'Biaya_Bongkar', 'Est_Berat', 'DO_Kecil', 'Status_DO', 'Reff', 'Tonnase_Kirim', 'Tonnase_Terima', 'Supir', 'Biaya_Gaji',
       'Biaya_BBM', 'Biaya_Makan','Kebun', 'Est_Kemasan', 'OA_Ekspedisi', 'SIM', 'Agama', 'Tanggal_Muat', 'Tanggal_Bongkar', 
       'Biaya_Makan', 'Biaya_BBM', 'Biaya_Perjalanan', 'SIM_Expired']
  }
  printGeneralReport(){
    if(this.genModel.invoice_date_from != null || this.genModel.invoice_date_to != null){
      if(this.genModel.listOfVisibleColumns.includes("Tanggal_Invoice")){

      }
      else{
        Swal.fire({
          title: "Untuk Filter Tanggal Invoice, Mohon Centang Kolom Invoice Date!",
          type: 'warning',
          confirmButtonText: '      Ok!     '
        }); 
        return;
      }
    }
    if(this.genModel.listOfVisibleColumns.includes("Tanggal_Invoice")){
      this.genModel.listOfVisibleColumns.push("No_Invoice");
    }
    if(this.genModel.listOfVisibleColumns.includes("Route")){
      this.genModel.listOfVisibleColumns.splice(this.genModel.listOfVisibleColumns.indexOf("Route"), 1);
      this.genModel.listOfVisibleColumns.push("Dari");
      this.genModel.listOfVisibleColumns.push("Ke");
    }
    if(this.genModel.listOfVisibleColumns.includes("No_DO")){
      if (!this.genModel.listOfVisibleColumns.includes("OA_FGS")){
        this.genModel.listOfVisibleColumns.push("OA_FGS");
        this.genModel.listOfVisibleColumns.push("Amount_FGS");
      }
    }
    if(this.genModel.listOfVisibleColumns.includes("OA_Ekspedisi")){
      if (!this.genModel.listOfVisibleColumns.includes("Amount_Ekspedisi")){
        this.genModel.listOfVisibleColumns.push("Amount_Ekspedisi");
      }
    }
    else
    {
      if (!this.genModel.listOfVisibleColumns.includes("Amount_Ekspedisi")){
      }
      else{
        this.genModel.listOfVisibleColumns.splice(this.genModel.listOfVisibleColumns.indexOf("Amount_Ekspedisi"), 1);
      }
    }
    if(this.genModel.listOfVisibleColumns.includes("DO_Kecil")){
      if (!this.genModel.listOfVisibleColumns.includes("No_Tax")){
        this.genModel.listOfVisibleColumns.push("No_Tax");
      }
    }
    this.spinner.show();
    let currentDate = new Date().toLocaleDateString();;
    let name = "General_Report_"+currentDate+".xlsx";
    this.genModel.location = localStorage.getItem('locationname');
    this.service.printReportGeneral2(this.genModel).subscribe(resp =>{
      this.spinner.hide();
      const blob = new Blob([resp.body],
        { type: 'application/vnd.ms-excel' });
    const file = new File([blob], name,
        { type: 'application/vnd.ms-excel' }); 

      saveAs(file);
    })
  }

  getDataRoute(dataTablesParameters, callback, route_name: string, route_type: string, unloadtext: string, loadtext: string, material_id: string,padging : boolean){ 
      
    var page = 1;
    if (padging == true){
      page = parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length) + 1;
    }
    this.service.getListRoute(dataTablesParameters, page, dataTablesParameters.length, route_name, route_type, unloadtext, loadtext, material_id)
    .subscribe(resp => { 
        this.dataRoute = resp.data;
        callback({
          recordsTotal: resp.totalCount,
          recordsFiltered: resp.totalCount,
           data:  []
        });
    });
  }

  
  getDataDriver(dataTablesParameters, callback,  driver_name: string, driver_license: string, driver_license_type: string, 
    agama: string, birthplace: string, ktp_no: string, tanggal_lahir: string, sl_code: string, sl_code2: string,
    sl_code3: string, address: string, valid_to: string, valid_to_contract: string, padging : boolean){ 
    var page = 1;
    if (padging == true){
      page = parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length) + 1;
    }
    this.service.getListDriver(dataTablesParameters, page, dataTablesParameters.length, driver_name, driver_license, driver_license_type, agama,
      birthplace,ktp_no,tanggal_lahir, sl_code, sl_code2, sl_code3, address, valid_to, valid_to_contract, "true", "false")
    .subscribe(resp => { 
        this.dataDriver = resp.data;
        callback({
          recordsTotal: resp.totalCount,
          recordsFiltered: resp.totalCount,
          data:  []
        });
    });
  }

  getDataRelation(dataTablesParameters, callback, relation_code: string, relation_name: string, relation_address: string, 
    relation_type: string, relation_sl_code: string, padging : boolean){ 
    var page = 1;
    if (padging == true){
      page = parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length) + 1;
    }
    this.service.getListRelation(dataTablesParameters, page, dataTablesParameters.length, relation_code,
      relation_name, relation_address, relation_type, relation_sl_code)
      .subscribe(resp => { 
        this.dataRelation = resp.data;
        callback({
          recordsTotal: resp.totalCount,
          recordsFiltered: resp.totalCount,
           data:  []
        });
        this.dtTriggerCustomer.next(0);
    });
  }

  getSupplier(dataTablesParameters, callback, relation_code: string, relation_name: string, relation_address: string, 
    relation_type: string, relation_sl_code: string, padging : boolean){ 
    var page = 1;
    if (padging == true){
      page = parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length) + 1;
    }
    this.service.getListRelation(dataTablesParameters, page, dataTablesParameters.length, relation_code,
      relation_name, relation_address, relation_type, relation_sl_code)
      .subscribe(resp => { 
        this.dataSupplier = resp.data;
        callback({
          recordsTotal: resp.totalCount,
          recordsFiltered: resp.totalCount,
           data:  []
        });
        this.dtTriggerSupplier.next(0);
    });
  }

  getDataMaterial(dataTablesParameters, callback, code: string, name: string, padging : boolean){ 
    var page = 1;
    if (padging == true){
      page = parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length) + 1;
    }
    this.service.getDataMaterialTest(dataTablesParameters, page, dataTablesParameters.length, code, name)
    .subscribe(resp => { 
        this.dataMaterial = resp.data;
        callback({
          recordsTotal: resp.totalCount,
          recordsFiltered: resp.totalCount,
          data:  []
          //  data:  resp.data
        });
    });
  }

  
  onSelectDriver(data: Driver){
    this.genModel.driver = data.driver_id;
    this.driverName = data.driver_name;
  } 

  onSelectRoute(data: Route){
    this.genModel.route = data.route_id;
    this.routeName = data.route_name;
  } 

  onSelectCustomer(data: Relation){
    this.genModel.customer = data.relation_id;
    this.customerName = data.relation_name;    
  }
  
  onSelectSupplier(data: Relation){
    this.sellerName = data.relation_name;
    this.genModel.seller = data.relation_id;
  } 

  onSelectMaterial(data : Material){
    this.genModel.material = data.material_id;
    this.materialName = data.material_name;
  }

  showRoute(){
    this.dtTriggerRoute.next(0);
  }

  showDriver(){
    this.dtTriggerDriver.next(0);
  }

  showSupplier(){
    this.dtTriggerSupplier.next(0);
  }

  showCustomer(){
    this.dtTriggerCustomer.next(0);
  }

  showMaterial(){
    this.dtTriggerMaterial.next(0);
  }
}




