import { Guid } from "guid-typescript";

export class MasterSubMenu {
    subMenuId : Guid;
    menuId : Guid;
    subMenu : string;
    text : string;
    menu : string;
  }

  export class AuthMenu {
    menuAuthId : Guid;
    subMenuId : Guid;
    roleId : Guid;
    authId : Guid;
    createdAt : Date;
    roleName : string;
    menuName : string;
    subMenuName : string;
    authName : string;
    createdName : string;
    menu : string;
    subMenu: string;
  }
  
  

