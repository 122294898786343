<section class="content">
    <div class="card">
        <div class="card-header">
          <strong>List Debit Note</strong>
          <div class="float-right">
            <a type="button" (click)="ModalJournal.show();" class="btn btn-sm btn-primary pull-right" data-toggle="modal">&nbsp;<b>Journal</b>&nbsp;&nbsp;<i class="fa fa-book"></i>&nbsp;</a>&nbsp;&nbsp;
            <a type="button" class="btn btn-sm btn-primary pull-right" data-toggle="modal" (click)="ModalPrint.show()">&nbsp;<b>Rekap Data</b>&nbsp;&nbsp;<i class="fa fa-file-excel"></i>&nbsp;</a> &nbsp;&nbsp;&nbsp;
          <a type="button" (click)="ModalUploadFGS.show();" class="btn btn-sm btn-primary pull-right" data-toggle="modal">&nbsp;<b>Upload Debit Note</b>&nbsp;&nbsp;<i class="fa fa-upload"></i>&nbsp;</a>
          </div>
        </div>
        <div class="card-body">
            <table datatable [dtOptions]="dtOptionsAcc" [dtTrigger]="dtTriggerAcc" class="deeps table-striped">
                <thead>
                  <tr>
                    <th>Action</th>
                    <th>No Kwitansi</th>
                    <th>Tanggal</th>
                    <th>Customer</th>
                    <th>Deskripsi</th>
                    <th>Rute</th>
                    <th>SPK</th>
                    <th>DO</th>
                    <th>Amount</th>
                    <th>Dibuat Oleh</th>
                    <th>Dibuat Pada</th>
                    <th>Diubah Oleh</th>
                    <th>Diubah Pada</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of dataListDebit">
                    <td>
                       <button (click)="onSelectDetailDebit(row);ModalSimpan.show()" title="Detail" class="btn btn-sm btn-primary" triggers="mouseenter:mouseleave"><i class="fa fa-list"></i></button>&nbsp;
                       <button (click)="printDebit(row);" title="Print" class="btn btn-sm btn-primary" triggers="mouseenter:mouseleave"><i class="fa fa-print"></i></button>&nbsp;
                       <button (click)="delete(row);" title="Delete" class="btn btn-sm btn-danger" triggers="mouseenter:mouseleave"><i class="fa fa-trash"></i></button>&nbsp;
                    </td>
                    <td>{{row.receiptNo}}</td>
                    <td>{{row.receiptDate | slice:0:10}}</td>
                    <td>{{row.customer}}</td>
                    <td>{{row.description}}</td>
                    <td>{{row.route}}</td>
                    <td>{{row.spk}}</td>
                    <td>{{row.do}}</td>
                    <td>{{row.amount | number}}</td>
                    <td>{{row.createdName}}</td>
                    <td>{{row.createdDate | slice:0:10}}</td>
                    <td>{{row.updatedName}}</td>
                    <td>{{row.updatedDate | slice:0:10}}</td>
                </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <th><input hidden class="searchInput"/></th>
                    <th><input id="ReceiptNo" type="text" class="searchInput" placeholder="No Invoice"/></th>
                    <th><input hidden class="searchInput"/></th>
                    <th><input id="Customer" type="text" class="searchInput" placeholder="Customer"/></th>
                    <th><input id="Description" type="text" class="searchInput" placeholder="Deskripsi"/></th>
                    <th><input id="Route" type="text" class="searchInput" placeholder="Rute"/></th>
                    <th><input id="SPK" type="text" class="searchInput" placeholder="SPK"/></th>
                    <th><input id="DO" type="text" class="searchInput" placeholder="DO"/></th>
                    <th><input hidden class="searchInput"/></th>
                    <th><input hidden class="searchInput"/></th>
                    <th><input hidden class="searchInput"/></th>
                    <th><input hidden class="searchInput"/></th>
                    <th><input hidden class="searchInput"/></th>
                  </tr>
              </tfoot>
              </table>
        </div>
    </div>
  </section>

  <div  bsModal #ModalUploadFGS="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
     <div class="modal-dialog" style="max-width: 80%;" role="document">
        <div class="modal-content">
            <div class="modal-header">
            <div class="modal-title"><strong>Upload Debit Note</strong></div>
            <small><code style="color: black">&nbsp;&nbsp;*(semua kolom wajib di isi)</code></small>
            <button #ModalUploadFGSClose type="button" class="close" (click)="ModalUploadFGS.hide()" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
            </div>
            <div class="modal-body">
            <div class="card">
                <div class="card-body">
                        <input #uploadFile type="file" (change)="onFileSelected($event)" name="img[]" class="file">
                        <div class="input-group mb-3">
                        <div class="input-group-prepend">
                            <button (click)="browseFile()" class="browse input-group-text btn btn-warning">&nbsp;&nbsp;<i class="fa fa-folder-open" style="font-size:18px">&nbsp;&nbsp;</i></button>
                        </div>
                        <input type="text" [(ngModel)]="fileName" class="form-control" disabled aria-label="Upload File" aria-describedby="basic-addon1">
                        <div class="input-group-append">
                            <button (click)="uploadDebitNote()" id="upload" disabled class="btn btn-warning">&nbsp;&nbsp;<i class="fa fa-upload" style="font-size:18px">&nbsp;&nbsp;</i></button>
                        </div>
                        </div>
                </div>
            </div>
                <div class="card">
                    <div class="card-body">
                    <div class="scroll">
                    <div class="card-header">
                        <button (click)="selectAll();checkSelected();" class="btn btn-sm btn-warning" style="font-size:14px"><b>&nbsp;&nbsp;Check All&nbsp;&nbsp;</b><i class="fa fa-check">&nbsp;&nbsp;</i></button>&nbsp;&nbsp;
                        <button (click)="uploadClear()" class="btn btn-sm btn-danger" style="font-size:14px"><b>&nbsp;&nbsp;Clear Data&nbsp;&nbsp;</b><i class="fa fa-trash">&nbsp;&nbsp;</i></button>
                    </div>
                        <table class="table">
                            <thead>
                            <tr>
                                <th></th>
                                <th>Error Message</th>
                                <th>Tanggal</th>
                                <th>Customer</th>
                                <th>Deskripsi</th>
                                <th>Rute</th>
                                <th>SPK</th>
                                <th>DO</th>
                                <th>Qty</th>
                                <th>OA</th>
                                <th>Tipe</th>
                                <th>Tanggal Print</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let row of dataUpload; let i = index">
                                <td><input (change)="checkSelected()" [disabled]="row.error" type="checkbox" [(ngModel)]="row.selected"></td>
                                <td>
                                <span *ngIf="row.error_message"><b style="color: red;" > {{row.error_message}} </b></span>
                                <span *ngIf="!row.error_message">{{row.error_message}}</span>
                                </td>
                                <td>{{row.receiptDate | slice:0:10}}</td>
                                <td>{{row.customer}}</td>
                                <td>{{row.description}}</td>
                                <td>{{row.route}}</td>
                                <td>{{row.spk}}</td>
                                <td>{{row.do}}</td>
                                <td>{{row.qty}}</td>
                                <td>{{row.oa}}</td>
                                <td>{{row.type}}</td>
                                <td>{{row.printDate | slice:0:10}}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="row">
                        <div class="col-md-4"> 
                            <div class="form-group row group">
                                <label class="col-md-4 col-form-label" for="text-input">Data Terpilih</label>
                                <label class="col-md-3 col-form-label" for="text-input">{{select}}/{{totalData}}</label>
                            </div> 
                        </div>
                    </div>
                    </div>
                    <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" (click)="ModalUploadFGS.hide()"><b>Close</b></button>
                    <button type="button" class="btn btn-primary" (click)="saveUpload()"><i class="fa fa-save"></i>&nbsp;<b>Save</b>&nbsp;</button>
                    </div>
                </div>
            </div>
        </div>
        </div>
    </div>


  <div bsModal #ModalSimpan="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog" style="max-width: 80%;" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title">
            <div class="row">
              <strong>List Menu</strong>
            </div>
          </div>
          <button type="button" class="close" (click)="ModalSimpan.hide()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group row">
                          <label class="col-md-3 col-form-label" for="text-input">No Kwitansi</label>
                          <div class="col-md-8">
                              <input disabled [(ngModel)]="debitModel.receiptNo" type="text" class="form-control">
                          </div>
                        </div>
                        <div class="form-group row">
                          <label class="col-md-3 col-form-label" for="text-input">Tanggal Kwitansi</label>
                          <div class="col-md-8">
                              <input [ngModel]="debitModel.receiptDate | date:'yyyy-MM-dd'" (ngModelChange)="debitModel.receiptDate = $event" type="date" class="form-control">
                          </div>
                        </div>
                        <div class="form-group row">
                          <label class="col-md-3 col-form-label" for="text-input">SPK</label>
                          <div class="col-md-8">
                              <input [(ngModel)]="debitModel.spk" type="text" class="form-control">
                          </div>
                        </div>
                        <div class="form-group row">
                          <label class="col-md-3 col-form-label" for="text-input">DO</label>
                          <div class="col-md-8">
                              <input [(ngModel)]="debitModel.do" type="text" class="form-control">
                          </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group row">
                          <label class="col-md-3 col-form-label" for="text-input">Type</label>
                          <div class="col-md-8">
                              <input [(ngModel)]="debitModel.type" type="text" class="form-control">
                          </div>
                        </div>
                        <div class="form-group row">
                          <label class="col-md-3 col-form-label" for="text-input">Rute</label>
                          <div class="col-md-8">
                              <input [(ngModel)]="debitModel.route" type="text" class="form-control">
                          </div>
                        </div>
                        <div class="form-group row">
                          <label class="col-md-3 col-form-label" for="text-input">Deskripsi</label>
                          <div class="col-md-8">
                              <textarea [(ngModel)]="debitModel.description" cols="8" type="text" class="form-control"></textarea>
                          </div>
                        </div>
                    </div>

                </div>
            </div>
          </div>
            <br>
            <div class="card">
              <div class="card-body">
                  <table datatable [dtOptions]="dtOptionsDetail" [dtTrigger]="dtTriggerDetail" class="table-striped">
                      <thead>
                      <tr>
                          <!-- <th>Action</th> -->
                          <th>Nopol</th>
                          <th>Qty</th>
                          <th>OA</th>
                          <th>Amount</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr *ngFor="let row of listDebitNoteDetail; let i=index">    
                          <!-- <td>
                          <button (click)="deleteDataDetail(i, row)" title="Detail" class="btn btn-sm btn-danger" triggers="mouseenter:mouseleave"><i class="fa fa-trash"></i></button>&nbsp;
                          </td>        -->
                          <td>{{row.truckNumber}}</td>
                          <td>{{row.qty}}</td>
                          <td>{{row.oa}}</td>
                          <td>{{row.qty*row.amount}}</td>
                      </tr>
                      </tbody>
                  </table>
              </div>
          </div>
        </div>
        <div class="modal-footer">
          <button #modalSimpanClose type="button" class="btn btn-secondary" (click)="ModalSimpan.hide()">&nbsp;&nbsp;&nbsp;&nbsp;<b>Close</b>&nbsp;&nbsp;&nbsp;&nbsp;</button>
          <!-- <button [disabled]="show" [hidden]="update" type="button" (click)="createSPK()" class="btn btn-primary"><i class="fa fa-save"></i>&nbsp;&nbsp;&nbsp;&nbsp;<b>Simpan</b>&nbsp;&nbsp;&nbsp;&nbsp;</button>
          <button [disabled]="show"  [hidden]="!update" type="button" (click)="updateSPK()" class="btn btn-primary"><i class="fa fa-save"></i>&nbsp;&nbsp;&nbsp;&nbsp;<b>Update</b>&nbsp;&nbsp;&nbsp;&nbsp;</button> -->
        </div>
      </div>
    </div>
  </div>

  
  <div bsModal #ModalJournal="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog" style="max-width: 80%;" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title">
            <div class="row">
              <strong>Journal Debit Note</strong>
            </div>
          </div>
          <button #ModalJournalClose type="button" class="close" (click)="ModalJournal.hide()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-4">
                        <div class="form-group row">
                            <label class="col-md-3 col-form-label" for="text-input">Tanggal</label>
                            <div class="col-md-8">
                              <input  type="date" [ngModel]="inputModel.from | date:'yyyy-MM-dd'" (ngModelChange)="inputModel.from = $event"  class="form-control">
                            </div>
                        </div> 
                        <div class="form-group row">
                            <label class="col-md-3 col-form-label" for="text-input">Bulan</label>
                            <div class="col-md-8">
                                <input [(ngModel)]="inputModel.month" currencyMask [options]="{ prefix: '', thousands: '', decimal: ',' ,precision: '0',align: 'left' }" type="text"class="form-control">
                            </div>
                        </div> 
                    </div>
                    <div class="col-md-4">
                        <div class="form-group row">
                            <label class="col-md-3 col-form-label" for="text-input">Sampai Tanggal</label>
                            <div class="col-md-8">
                              <input  type="date" [ngModel]="inputModel.to | date:'yyyy-MM-dd'" (ngModelChange)="inputModel.to = $event"  class="form-control">
                            </div>
                        </div> 
                        <div class="form-group row">
                            <label class="col-md-3 col-form-label" for="text-input">Tahun</label>
                            <div class="col-md-8">
                                <input [(ngModel)]="inputModel.year" currencyMask [options]="{ prefix: '', thousands: '', decimal: ',' ,precision: '0',align: 'left' }" type="text"class="form-control">
                            </div>
                        </div> 
                    </div>
                    <div class="col-md-4">
                        <div class="form-group row">
                            <label class="col-md-3 col-form-label" for="text-input">Start Counter</label>
                            <div class="col-md-8">
                                <input [(ngModel)]="inputModel.start_counter" currencyMask [options]="{ prefix: '', thousands: '', decimal: ',' ,precision: '0',align: 'left' }" type="text"class="form-control">
                            </div>
                        </div> 
                    </div>
                </div> 
                <br>
                <br>
                <div style="text-align: center">
                    <button type="button" (click)="generateJournal()" class="btn btn-warning">&nbsp;<i class="fa fa-recycle"></i>&nbsp;&nbsp;&nbsp;&nbsp;<b>Generate</b>&nbsp;&nbsp;&nbsp;&nbsp;</button>&nbsp;&nbsp;
                    <button type="button" (click)="exportExcel()" class="btn btn-primary">&nbsp;<i class="fa fa-file-excel"></i>&nbsp;&nbsp;&nbsp;&nbsp;<b>Export Excel</b>&nbsp;&nbsp;&nbsp;&nbsp;</button>&nbsp;&nbsp;
                </div>
                <br>
                <br>
                <div class="card-body">
    
                  <div class="scroll">
                    <table datatable [dtOptions]="dtOptionsJournal" [dtTrigger]="dtTriggerJournal" class="table-striped">
                      <thead>
                        <tr>
                          <th>Journal Debit No</th>
                          <th>PRJ</th>
                          <th>Date</th>
                          <th>Remark</th>
                          <th>CCY</th>
                          <th>Rate</th>
                          <th>Credit</th>
                          <th>Debit</th>
                          <th>GL Code</th>
                          <th>IO</th>
                          <th>Cust/Supp</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let row of dataListJournal">     
                          <td>{{row.journalNo}}</td>
                          <td>{{row.prj}}</td>
                          <td>{{row.date | slice:0:10}}</td>
                          <td>{{row.remark}}</td>
                          <td>{{row.ccy}}</td>
                          <td>{{row.rate}}</td>
                          <td>Rp. {{row.credit | number}}</td>
                          <td>Rp. {{row.debit | number}}</td>
                          <td>{{row.glCode}}</td>
                          <td>{{row.io}}</td>
                          <td>{{row.custSup}}</td>
                        </tr>
                      </tbody>        
                    </table>
                  </div>
    
                </div>
            </div>
        </div>
      </div>
    </div>
  </div>

  <!-- 

  <div  bsModal #ModalDetail="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog" style="max-width: 50%;" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title">
            <div class="row">
              <strong>Tambah Item</strong>
            </div>
          </div>
          <button type="button" class="close" (click)="ModalDetail.hide()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group row">
                            <label class="col-md-3 col-form-label" for="text-input">Kode Item</label>
                            <input  [(ngModel)]="npbDetailModel.itemId"  hidden type="text" class="form-control">
                            <div class="col-md-7">
                              <input disabled [(ngModel)]="npbDetailModel.itemCode" disabled type="text" class="form-control">
                            </div>
                            <div class="col-md-2">
                                <button class="btn btn-sm btn-primary"
                                data-toggle="modal" (click)="ModalStock.show();showStock();"><i class="fa fa-plus"></i></button>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-3 col-form-label" for="text-input">Nama Item</label>
                            <div class="col-md-8">
                                <input disabled [(ngModel)]="npbDetailModel.itemName" type="text" class="form-control">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group row">
                            <label class="col-md-3 col-form-label" for="text-input">UOM</label>
                            <div class="col-md-8">
                                <input disabled [(ngModel)]="npbDetailModel.uom" type="text" class="form-control">
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-3 col-form-label" for="text-input">QTY</label>
                            <div class="col-md-8">
                                <input [(ngModel)]="npbDetailModel.qty" type="text" class="form-control">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" (click)="ModalDetail.hide()">&nbsp;&nbsp;&nbsp;&nbsp;<b>Close</b>&nbsp;&nbsp;&nbsp;&nbsp;</button>
          <button type="button" class="btn btn-primary" (click)="addNPBDetail();ModalDetail.hide()">&nbsp;&nbsp;&nbsp;&nbsp;<i class="fa fa-save"></i>&nbsp;&nbsp;<b>Simpan</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</button>
        </div>
      </div>
    </div>
  </div> 


 <div  bsModal #ModalSPK="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog  modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title"><strong>Pilih SPK</strong></div>
          <button type="button" class="close" (click)="ModalSPK.hide()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <table datatable [dtOptions]="dtOptionsSpk" [dtTrigger]="dtTriggerSpk" class="deeps table-striped">
            <thead>
              <tr>
                <th>Action</th>
                <th>No SPK</th>
                <th>Truk</th>
                <th>Supir</th>
                <th>Status</th>
                <th>Dibuat Oleh</th>
                <th>Dibuat Pada</th>
                <th>Diubah Oleh</th>
                <th>Diubah Pada</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let row of listSPK">
                <td>
                  <button (click)="onSelectSpk(row);ModalSPK.hide()" class="btn btn-sm btn-primary"  data-toggle="modal" triggers="mouseenter:mouseleave"><i class="fa fa-check"></i></button>
                </td>
                <td>{{row.spkNo}}</td>
                <td>{{row.truckNo}}</td>
                <td>{{row.driverName}}</td>
                <td>{{row.status}}</td>
                <td>{{row.createdName}}</td>
                <td>{{row.createdAt | slice:0:10}}</td>
                <td>{{row.updatedName}}</td>
                <td>{{row.createdAt | slice:0:10}}</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th><input hidden class="searchInputD"/></th>
                <th><input id="spkNo" type="text" class="searchInputD" placeholder="Nama"/></th>
                <th><input hidden class="searchInputD"/></th>
                <th><input hidden class="searchInputD"/></th>
                <th><input hidden class="searchInputD"/></th>
                <th><input hidden class="searchInputD"/></th>
                <th><input hidden class="searchInputD"/></th>
                <th><input hidden class="searchInputD"/></th>
                <th><input hidden class="searchInputD"/></th>
              </tr>
            </tfoot>

          </table>
        </div>
      </div>
    </div>
    </div>

    <div  bsModal #ModalStock="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
        <div class="modal-dialog  modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <div class="modal-title"><strong>Pilih Item</strong></div>
              <button type="button" class="close" (click)="ModalStock.hide()" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <table datatable [dtOptions]="dtOptionsStock" [dtTrigger]="dtTriggerStock" class="deeps table-striped">
                <thead>
                  <tr>
                    <th>Action</th>
                    <th>Kode Item</th>
                    <th>Item</th>
                    <th>Sub Kategori</th>
                    <th>Kategori</th>
                    <th>GL Account</th>
                    <th>UOM</th>
                    <th>Harga Unit</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of dataStock">
                    <td>
                      <button (click)="onSelectStock(row);ModalStock.hide()" class="btn btn-sm btn-primary"  data-toggle="modal" triggers="mouseenter:mouseleave"><i class="fa fa-check"></i></button>
                    </td>
                    <td>{{row.itemCode}}</td>
                    <td>{{row.itemName}}</td>
                    <td>{{row.itemSubKategori}}</td>
                    <td>{{row.itemKategori}}</td>
                    <td>{{row.gL_Account}}</td>
                    <td>{{row.uOM}}</td>
                    <td>{{row.unitPrice}}</td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <th><input hidden class="searchInputS"/></th>
                    <th><input id="ItemCode" type="text" class="searchInputS" placeholder="Kode Item"/></th>
                    <th><input id="ItemName" type="text" class="searchInputS" placeholder="Item"/></th>
                    <th><input id="ItemSubKategori" type="text" class="searchInputS" placeholder="Sub Kategori"/></th>
                    <th><input id="ItemKategori" type="text" class="searchInputS" placeholder="Kategori"/></th>
                    <th><input id="GL_Account" type="text" class="searchInputS" placeholder="GL Account"/></th>
                    <th><input hidden class="searchInputS"/></th>
                    <th><input hidden class="searchInputS"/></th>
                  </tr>
                </tfoot>
    
              </table>
            </div>
          </div>
        </div>
        </div> -->

        <div bsModal #ModalPrint="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
          <div class="modal-dialog" style="max-width: 50%;"  role="document">
            <div class="modal-content">
              <div class="modal-header">
                <div class="modal-title">
                </div>
                <button type="button" class="close" (click)="ModalPrint.hide()" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="card">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-12"> 
                          <div class="form-group row">
                              <label class="col-md-3 col-form-label" for="text-input">Dari Tanggal</label>
                              <div class="col-md-8">
                                  <input type="date" [ngModel]="printDateFrom | date:'yyyy-MM-dd'" (ngModelChange)="printDateFrom = $event"  class="form-control">
                              </div>
                          </div> 
                          <div class="form-group row">
                            <label class="col-md-3 col-form-label" for="text-input">Ke Tanggal</label>
                            <div class="col-md-8">
                                <input  type="date" [ngModel]="printDateTo | date:'yyyy-MM-dd'" (ngModelChange)="printDateTo = $event"  class="form-control">
                            </div>
                          </div> 
                      </div> 
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-primary" (click)="printOutStandExcel()"><i class="fa fa-file-excel"></i>&nbsp;<b>Export Excel</b>&nbsp;</button>&nbsp;&nbsp; 
                <button type="button" #modalPrint class="btn btn-secondary" (click)="ModalPrint.hide()">&nbsp;<b>Cancel</b>&nbsp;</button> 
            </div>
            </div>
          </div>
        </div>

  <ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>