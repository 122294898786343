import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {MainComponent} from '@modules/main/main.component';
import {BlankComponent} from '@pages/blank/blank.component';
import {LoginComponent} from '@modules/login/login.component';
import {ProfileComponent} from '@pages/profile/profile.component';
import {RegisterComponent} from '@modules/register/register.component';
import {DashboardComponent} from '@pages/dashboard/dashboard.component';
import {AuthGuard} from '@guards/auth.guard';
import {NonAuthGuard} from '@guards/non-auth.guard';
import {ForgotPasswordComponent} from '@modules/forgot-password/forgot-password.component';
import {RecoverPasswordComponent} from '@modules/recover-password/recover-password.component';
import {SubMenuComponent} from '@pages/main-menu/sub-menu/sub-menu.component';
import { MasterMaterialComponent } from '@pages/main-menu/master-material/master-material.component';
import { MasterVehiclesComponent } from '@pages/main-menu/master-vehicles/master-vehicles.component';
import { MasterTonnaseCapacityComponent } from '@pages/main-menu/master-tonnase-capacity/master-tonnase-capacity.component';
import { MasterCapacityComponent } from '@pages/main-menu/master-capacity/master-capacity.component';
import { MasterTruckComponent } from '@pages/main-menu/master-truck/master-truck.component';
import { MasterPortComponent } from '@pages/main-menu/master-port/master-port.component';
import { MasterRouteComponent } from '@pages/main-menu/master-route/master-route.component';
import { MasterRelationComponent } from '@pages/main-menu/master-relation/master-relation.component';
import { MasterCosttypeComponent } from '@pages/main-menu/master-costtype/master-costtype.component';
import { MasterDebtpriorityComponent } from '@pages/main-menu/master-debtpriority/master-debtpriority.component';
import { MasterTravelcostComponent } from '@pages/main-menu/master-travelcost/master-travelcost.component';
import { MasterDriverComponent } from '@pages/main-menu/master-driver/master-driver.component';
import { MasterToleranceComponent } from '@pages/main-menu/master-tolerance/master-tolerance.component';
import { MasterKebunComponent } from '@pages/main-menu/master-kebun/master-kebun.component';
import { SpkListComponent } from '@pages/spk/spk-list/spk-list.component';
import { TripRecordManualComponent } from '@pages/TripRecords/TripRecordManual/trip-record-manual/trip-record-manual.component';
import { InvoiceListComponent } from '@pages/invoice/invoice-list/invoice-list/invoice-list.component';
import { PaymentListComponent } from '@pages/payment/payment-list/payment-list.component';
import { DriverDebtComponent } from '@pages/driver-calculation/driver-debt/driver-debt.component';
import { ReportFormComponent } from '@pages/TripRecords/report-form/report-form.component';
import { AccClosingComponent } from '@pages/payment/acc-closing/acc-closing.component';
import { LoginPageComponent } from '@modules/login-page/login-page.component';
import { DriverBpjsComponent } from '@pages/driver-calculation/driver-bpjs/driver-bpjs.component';
import { ClaimSusutComponent } from '@pages/driver-calculation/claim-susut/claim-susut.component';
import { DebtListPay } from './model/debtListPay';
import { DebtPayComponent } from '@pages/driver-calculation/debt-pay/debt-pay.component';
import { DriverPremiComponent } from '@pages/driver-calculation/driver-premi/driver-premi.component';
import { SpsSusutComponent } from '@pages/general-report/sps-susut/sps-susut.component';
import { TravelCostReportComponent } from '@pages/general-report/travel-cost-report/travel-cost-report.component';
import { DriverPerformComponent } from '@pages/general-report/driver-perform/driver-perform.component';
import { ReportGeneralComponent } from '@pages/general-report/report-general/report-general.component';
import { TripDistributedComponent } from '@pages/TripRecords/trip-distributed/trip-distributed.component';
import { JournalInvoiceComponent } from '@pages/journal/journal-invoice/journal-invoice.component';
import { JournalTravelcostComponent } from '@pages/journal/journal-travelcost/journal-travelcost.component';
import { ReportCustomComponent } from '@pages/general-report/report-custom/report-custom.component';
import { ReportListComponent } from '@pages/general-report/report-list/report-list.component';
import { TravelcostBbmComponent } from '@pages/main-menu/travelcost-bbm/travelcost-bbm.component';
import { ListApprovalsDoComponent } from '@pages/approval/list-approvals-do/list-approvals-do.component';
import { DetailApprovalsDoComponent } from '@pages/approval/detail-approvals-do/detail-approvals-do.component';
import { SyncAisComponent } from '@pages/sync-ais/sync-ais.component';
import { TripOpslahComponent } from '@pages/TripRecords/trip-opslah/trip-opslah.component';
import { AccountComponent } from '@pages/settings/account/account.component';
import { RePassComponent } from '@pages/settings/re-pass/re-pass.component';
import { ListRoleComponent } from '@pages/settings/list-role/list-role.component';
import { SpkServiceComponent } from '@pages/services/spk-service/spk-service.component';
import { ListApprovalServiceComponent } from '@pages/services/list-approval-service/list-approval-service.component';
import { ListNpbComponent } from '@pages/services/list-npb/list-npb.component';
import { DebitNoteComponent } from '@pages/invoice/debit-note/debit-note.component';
import { InvoiceManualComponent } from '@pages/invoice/invoice-manual/invoice-manual.component';
import { ListSpbComponent } from '@pages/services/list-spb/list-spb.component';
import { ListBapbComponent } from '@pages/services/list-bapb/list-bapb.component';
import { DashboardServiceComponent } from '@pages/services/dashboard-service/dashboard-service.component';

const routes: Routes = [
    {
        path: '',
        component: MainComponent,
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        children: [
            {
                path: 'profile',
                component: ProfileComponent
            },
            {
                path: 'blank',
                component: BlankComponent
            },
            {
                path: 'sub-menu-1',
                component: SubMenuComponent
            },
            {
                path: 'sub-menu-2',
                component: BlankComponent
            },
            {
                path: '',
                component: DashboardComponent
            },
            {
                path: 'master-material',
                component: MasterMaterialComponent
            },
            {
                path: 'master-vehicles',
                component: MasterVehiclesComponent
            },
            {
                path: 'master-tonnase-capacity',
                component: MasterTonnaseCapacityComponent
            }
            ,
            {
                path: 'master-capacity',
                component: MasterCapacityComponent
            },
            {
                path: 'master-truck',
                component: MasterTruckComponent
            },
            {
                path: 'master-port',
                component: MasterPortComponent
            },
            {
                path: 'master-route',
                component: MasterRouteComponent
            },
            {
                path: 'master-relation',
                component: MasterRelationComponent
            },
            {
                path: 'master-customer',
                component: MasterRelationComponent
            },
            {
                path: 'master-vendor',
                component: MasterRelationComponent
            },
            {
                path: 'master-supplier',
                component: MasterRelationComponent
            },
            {
                path: 'master-costtype',
                component: MasterCosttypeComponent
            },
            {
                path: 'master-debtpriority',
                component: MasterDebtpriorityComponent
            },
            {
                path: 'master-travelcost',
                component: MasterTravelcostComponent
            },
            {
                path: 'travelcost-bbm',
                component: TravelcostBbmComponent
            },
            {
                path: 'master-driver',
                component: MasterDriverComponent
            },
            {
                path: 'master-tolerance',
                component: MasterToleranceComponent
            },
            {
                path: 'master-kebun',
                component: MasterKebunComponent
            },
            {
                path: 'spk-list',
                component: SpkListComponent
            },
            {
                path: 'trip-manual',
                component: TripRecordManualComponent
            }
            ,
            {
                path: 'realization-list',
                component: TripRecordManualComponent
            }
            ,
            {
                path: 'invoice-list',
                component: InvoiceListComponent
            },
            {
                path: 'payment-list',
                component: PaymentListComponent
            },
            {
                path: 'driver-debt',
                component: DriverDebtComponent
            },
            {
                path: 'report-travelcost',
                component: ReportFormComponent
            },
            {
                path: 'app-acc-closing',
                component: AccClosingComponent
            }
            ,
            {
                path: 'driver-bpjs',
                component: DriverBpjsComponent
            },
            {
                path: 'claim-susut',
                component: ClaimSusutComponent
            },
            {
                path: 'debt-pay',
                component: DebtPayComponent
            },
            {
                path: 'driver-premi',
                component: DriverPremiComponent
            }
            ,
            {
                path: 'sps-susut',
                component: SpsSusutComponent
            },
            {
                path: 'travelcost-report',
                component: TravelCostReportComponent
            },
            {
                path: 'driver-perform',
                component: DriverPerformComponent
            },
            {
                path: 'report-general',
                component: ReportGeneralComponent
            },
            {
                path: 'trip-distributed',
                component: TripDistributedComponent
            },
            {
                path: 'report-triprecord',
                component: ReportFormComponent
            },
            {
                path: 'journal-invoice',
                component: JournalInvoiceComponent
            },
            {
                path: 'journal-travelcost',
                component: JournalTravelcostComponent
            },
            {
                path: 'report-custom',
                component: ReportCustomComponent
            },
            {
                path: 'report-list',
                component: ReportListComponent
            }
            ,
            {
                path: 'list-approvaldo',
                component: ListApprovalsDoComponent
            }
            ,
            {
                path: 'detail-approvaldo/:id',
                component: DetailApprovalsDoComponent
            },
            {
                path: 'sync-ais',
                component: SyncAisComponent
            },
            {
                path: 'trip-opslah',
                component: TripOpslahComponent
            },
            {
                path: 'user-list',
                component: AccountComponent
            },
            {
                path: 're-pass',
                component: RePassComponent
            },
            {
                path: 'role-list',
                component: ListRoleComponent
            },
            {
                path: 'role-list',
                component: ListRoleComponent
            },
            {
                path: 'spk-service',
                component: SpkServiceComponent
            },
            {
                path: 'approval-service',
                component: ListApprovalServiceComponent
            },
            {
                path: 'list-npb',
                component: ListNpbComponent
            },
            {
                path: 'list-spb',
                component: ListSpbComponent
            },
            {
                path: 'list-debitnote',
                component: DebitNoteComponent
            },
            {
                path: 'invoice-manual',
                component: InvoiceManualComponent
            },
            {
                path: 'list-bapb',
                component: ListBapbComponent
            },
        ]
    },
    {
        path: 'dashboard-service',
        component: DashboardServiceComponent
    },
    {
        path: 'login',
        component: LoginComponent,
        canActivate: [NonAuthGuard]
    },
    {
        path: 'login-page',
        component: LoginPageComponent,
        canActivate: [NonAuthGuard]
    },
    {
        path: 'register',
        component: RegisterComponent,
        canActivate: [NonAuthGuard]
    },
    {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
        canActivate: [NonAuthGuard]
    },
    {
        path: 'recover-password',
        component: RecoverPasswordComponent,
        canActivate: [NonAuthGuard]
    },
    {path: '**', redirectTo: ''}
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true })],
    exports: [RouterModule]
})
export class AppRoutingModule {}
