
<!-- Main content -->
<section class="content">    
  <div class="card">
    <div class="card-header">
    <strong>List Truk</strong>
    <div class="float-right">
      <a type="button" class="btn btn-sm btn-warning pull-right" data-toggle="modal" (click)="exportAsXLSX()">&nbsp;&nbsp;&nbsp;<b>Export Data</b>&nbsp;&nbsp;&nbsp;&nbsp;<i class="fas fa-file-excel"></i>&nbsp;&nbsp;&nbsp;</a>&nbsp;&nbsp;&nbsp;&nbsp;
      <a type="button" [hidden]="!btnCrud" class="btn btn-sm btn-primary pull-right" data-toggle="modal" (click)="ModalTruck.show();clearFormTruck();"><b>&nbsp;&nbsp;&nbsp;Input Data Truck&nbsp;&nbsp;&nbsp;&nbsp;</b><i class="fa fa-pen"></i>&nbsp;&nbsp;&nbsp;</a>
    </div>
  </div>
 <div class="card-body">
  <table datatable  [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"  class="table-striped">
    <thead>
      <tr>
        <th>Action</th>
        <th>Nomor Polisi</th>
        <th>Kode Kapasitas</th>
        <th>Lokasi Truk</th>
        <th>Nama Ekspedisi</th>
        <th>Dibuat Oleh</th>
        <th>Dibuat Pada</th>
        <th>Diubah Oleh</th>
        <th>Diubah Pada</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let row of dataTruck">
        <td>
          <button [hidden]="!btnCrud" class="btn btn-sm btn-danger" triggers="mouseenter:mouseleave" (click)="deleteTruck(row);"><i class="fa fa-trash"></i></button>&nbsp;
          <button (click)="onSelectTruck(row);ModalTruck.show()" class="btn btn-sm btn-primary"  data-toggle="modal"triggers="mouseenter:mouseleave"><i class="fa fa-pen"></i></button>&nbsp;
          <button (click)="onSelectAsset(row);ModalAssets.show()" class="btn btn-sm btn-primary"  data-toggle="modal"triggers="mouseenter:mouseleave"><i class="fa fa-list"></i></button>
        </td>
        <td>{{row.truck_plate_number}}</td>
        <td>{{row.truck_capacity_code}}</td>
        <td>{{row.truck_location}}</td>
        <td>{{row.truck_vendor_name}}</td>
        <td>{{row.created_by_text}}</td>
        <td>{{row.created_at_text | slice:0:10}}</td>  
        <td>{{row.updated_by_text}}</td>  
        <td>{{row.updated_at_text | slice:0:10}}</td>       
      </tr>
    </tbody>
    <tfoot>
      <tr>
        <th><input hidden class="searchInput"/></th>
        <th><input id="platsearch" type="text" class="searchInput" placeholder="Nomor Polisi" name="search-code"/></th>
        <th><input id="codesearch" type="text" class="searchInput" placeholder="Kode Kapasitas" name="search-capacity"/></th>
        <th><input id="locationsearch" type="text" class="searchInput" placeholder="Lokasi Truk" name="search-location"/></th>
        <th><input hidden class="searchInput"/></th>
        <th><input hidden class="searchInput"/></th>
        <th><input hidden class="searchInput"/></th>
        <th><input hidden class="searchInput"/></th>
        <th><input hidden class="searchInput"/></th>
      </tr>
    </tfoot>

  </table>
</div>
</div>
</section>

<div  bsModal #ModalAssets="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog  modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title"><strong>{{action}} Data Truck</strong></div>
        <button type="button" class="close" (click)="ModalAssets.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
          <div class="card">
              <div class="card-body">
                <div class="form-group row">
                  <label class="col-md-3 col-form-label" for="text-input">Asset</label>
                  <div class="col-md-6">
                    <select [(ngModel)]="assetTruck.assetName" class="form-control">
                      <option value="STNK" ><b>STNK</b></option>
                      <option value="PAJAK STNK" ><b>PAJAK STNK</b></option>
                      <option value="KIR" ><b>KIR</b></option>
                      <option value="SIU" ><b>SIU</b></option>
                      <option value="TERRA" ><b>TERRA</b></option>
                      <option value="IZIN MIGAS" ><b>IZIN MIGAS</b></option>
                    </select>
                  </div>
                </div> 
                <div class="form-group row">
                  <label class="col-md-3 col-form-label" for="text-input">Valid Sampai Dengan</label>
                  <div class="col-md-6">
                      <input [(ngModel)]="assetTruck.validTo" type="date" class="form-control">
                  </div>
                </div> 
              </div>
          </div>
          <div style="text-align: center">
            <button [hidden]="!btnCrud" type="button" (click)="addAsset()" class="btn btn-primary"><i class="fas fa-floppy-disk"></i>&nbsp;<b>Simpan Data</b>&nbsp;</button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </div>
          <br>
          <div class="card">
            <div class="card-header">
            <strong>List Asset</strong>
          </div>
         <div class="card-body">
          <table datatable  [dtOptions]="dtOptionsAsset" [dtTrigger]="dtTriggerAsset"  class="table-striped">
            <thead>
              <tr>
                <th>Action</th>
                <th>Nama Asset</th>
                <th>Tanggal Valid Sampai</th>
                <th>Dibuat Oleh</th>
                <th>Dibuat Pada</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let row of assetTruckList">
                <td>
                  <button [hidden]="!btnCrud" class="btn btn-sm btn-danger" triggers="mouseenter:mouseleave" (click)="deleteTruckAsset(row);"><i class="fa fa-trash"></i></button>&nbsp;
                </td>
                <td>{{row.assetName}}</td>
                <td>{{row.validTo | slice:0:10}}</td> 
                <td>{{row.createdName}}</td>
                <td>{{row.createdAt | slice:0:10}}</td>  
              </tr>
            </tbody>
        
          </table>
        </div>
        </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="ModalAssets.hide()"><b>Close</b></button>
    </div>
  </div>
</div>
</div>


<div  bsModal #ModalTruck="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog  modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title"><strong>{{action}} Data Truck</strong></div>
        <button type="button" class="close" (click)="ModalTruck.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
          <div class="card">
              <div class="card-body">
                    <div class="form-group row">
                      <label class="col-md-3 col-form-label" for="text-input">Nomor Polisi</label>
                      <div class="col-md-9">
                        <input [(ngModel)]="truckModel.truck_plate_number" type="text" class="form-control">
                      </div>
                    </div> 
                    <div class="form-group row">
                      <label class="col-md-3 col-form-label" for="text-input">Kode Kapasitas</label>
                      <input hidden [(ngModel)]="truckModel.truck_capacity_code" disabled type="text" class="form-control" >
                      <div class="col-md-7">
                        <input [(ngModel)]="capacitySelect" disabled type="text" class="form-control" >
                      </div>
                      <div class="col-md-2">
                        <button style="position:absolute; right: 0; right: 75px; top: 4px;" class="btn btn-sm btn-primary" 
                        data-toggle="modal" (click)="ModalCapacity.show()"><i class="fa fa-plus"></i></button>
                      </div>
                    </div> 
                    <div class="form-group row">
                      <label class="col-md-3 col-form-label" for="text-input">Ekspedisi</label>
                      <input hidden [(ngModel)]="truckModel.truck_vendor_name" disabled type="text" class="form-control" >
                      <div class="col-md-7">
                        <input [(ngModel)]="ekspedisiSelect" disabled type="text" class="form-control" >
                      </div>
                      <div class="col-md-2">
                        <button style="position:absolute; right: 0; right: 75px; top: 4px;" class="btn btn-sm btn-primary" 
                        data-toggle="modal" (click)="ModalEkspedisi.show()"><i class="fa fa-plus"></i></button>
                      </div>
                    </div>
                  <div class="form-group row">
                      <label class="col-md-3 col-form-label" for="text-input">Lokasi</label>
                      <div class="col-md-9">                      
                        <select [(ngModel)]="truckModel.truck_location_id" id="select" name="select" class="form-control">
                          <option *ngFor="let row of dataLocation" [value]='row.id'><b>{{row.name}}</b></option>
                        </select>
                     </div>
                  </div> 
                  <div class="form-group row">
                    <label class="col-md-3 col-form-label" for="text-input">Nomor Rangka</label>
                    <div class="col-md-9">
                      <input [(ngModel)]="truckModel.frame_no" type="text" class="form-control">
                    </div>
                  </div> 
                  <div class="form-group row">
                    <label class="col-md-3 col-form-label" for="text-input">Nomor Mesin</label>
                    <div class="col-md-9">
                      <input [(ngModel)]="truckModel.machine_no" type="text" class="form-control">
                    </div>
                  </div> 
              </div>
          </div>

          <!-- <div class="card">
              <div class="card-header">
              <strong>Data Tambahan</strong>
             </div>
              <div class="card-body">
                  <div class="form-group row">
                      <label class="col-md-3 col-form-label" for="text-input">Nama Aset</label>
                      <div class="col-md-7">
                        <input [(ngModel)]="tambahanModel.assetName" type="text" class="form-control">
                     </div>
                  </div> 
                  <div class="form-group row">
                    <label class="col-md-3 col-form-label" for="text-input">Tanggal valid</label>
                    <div class="col-md-4">
                        <input [(ngModel)]="tambahanModel.validFrom" type="date" class="form-control">
                    </div>
                    <label class="col-md-1 col-form-label">s/d</label>
                    <div class="col-md-4">
                        <input [(ngModel)]="tambahanModel.validTo" type="date" class="form-control">
                    </div>
                  </div>
                  <div class="form-group row">
                      <div class="col-md-3"></div>
                      <div class="col-md-1">
                        <input [(ngModel)]="tambahanModel.check" type="checkbox" class="form-control">
                      </div>
                      <label class="col-md-1 col-form-label" for="text-input">Check</label>
                      <div class="col-md-7"></div>
                  </div>
                  <br>
                  <div style="text-align: center">
                      <button type="button" class="btn btn-sm btn-primary" (click)="AddDataTambahan();">&nbsp;Tambah&nbsp;</button>
                  </div>
                  <br>
                  <br>
                  <div class="card">
                      <div class="card-body">
                          <table class="table table-striped table-sm">
                              <thead>
                              <tr>
                                  <th>Nama Aset</th>
                                  <th>Valid Dari</th>
                                  <th>Valid Sampai</th>
                                  <th>Check</th>
                                  <th>Action</th>
                              </tr>
                              </thead>
                              <tbody>
                              <tr *ngFor="let row of dataTambahan; let i=index">
                                  <td>{{row.assetName}}</td>
                                  <td>{{row.validFrom | slice:0:10}}</td>
                                  <td>{{row.validTo | slice:0:10}}</td>
                                  
                                  <td><input type="checkbox" [checked]="row.check"/></td>
                                  <td><button class="btn btn-sm btn-danger" triggers="mouseenter:mouseleave" (click)="deleteAsset(i);"><i class="fa fa-trash"></i></button></td>
                              </tr>
                              </tbody>
                          </table>
                      </div>    
                  </div>
              </div>
          </div> -->
      <div class="modal-footer">
        <button #ModalTruckClose type="button" class="btn btn-secondary" (click)="ModalTruck.hide()"><b>Close</b></button>
        <button [hidden]="!btnCrud" type="button" class="btn btn-primary"  (click)="saveDataTruck()"><i class="fa fa-save"></i>&nbsp;&nbsp;<b>Save</b>&nbsp;&nbsp;</button>
      </div>
    </div>
  </div>
</div>

<div  bsModal #ModalCapacity="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog  modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title"><strong>Kapasitas</strong></div>
        <button type="button" class="close" (click)="ModalCapacity.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body"> 
          <!-- <app-master-capacity></app-master-capacity> -->
          <table datatable  [dtOptions]="dtOptions2" [dtTrigger]="dtTrigger2"  class="table-striped">
              <thead>
                <tr>
                  <th>Kode</th>
                  <th>Keterangan</th>
                  <th>Tipe Kendaraan</th>
                  <th>Tonnase</th>
                  <th>Diubah Oleh</th>
                  <th>Diubah Pada</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let row of componentCapacity.dataCapacity">
                  <td>{{row.capacity_code}}</td>
                  <td>{{row.capacity_note}}</td>
                  <td>{{row.vehicleType}}</td>
                  <td>{{row.tonCode}}</td>
                  <td>{{row.updatedby}}</td>  
                  <td>{{row.updated_at | slice:0:10}}</td>    
                  <td>
                      <button (click)="onSelectCapacity(row);ModalCapacity.hide()" class="btn btn-sm btn-primary"  data-toggle="modal" triggers="mouseenter:mouseleave"><i class="fa fa-check"></i></button>
                  </td> 
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th><input id="codesearchV" type="text" class="searchInputV" placeholder="Search Kode" name="search-code"/></th>
                  <th><input id="namesearchV" type="text" class="searchInputV" placeholder="Search Keterangan" name="search-keterangan"/></th>
                  <th><input id="vehiclesearchV" type="text" class="searchInputV" placeholder="Search Kendaraan" name="search-quantity"/></th>
                  <th><input hidden class="searchInputV"/></th>
                  <th><input hidden class="searchInputV"/></th>
                  <th><input hidden class="searchInputV"/></th>
                  <th><input hidden class="searchInputV"/></th>
                </tr>
              </tfoot>
          </table>
      </div>
    </div>
  </div>
</div>


<div  bsModal #ModalEkspedisi="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog  modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title"><strong>Data Ekspedisi</strong></div>
        <small><code style="color: black">&nbsp;&nbsp;*(semua kolom wajib di isi)</code></small>
        <button type="button" class="close" (click)="ModalEkspedisi.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body"> 
        <table datatable  [dtOptions]="dtOptions3" [dtTrigger]="dtTrigger3"  class="table-striped">
          <thead>
            <tr>
              <th>Kode</th>
              <th>Nama Relasi</th>
              <!-- <th>Alamat</th> -->
              <th>No Telepon</th>
              <th>Tipe Relasi</th>
              <th>Kode Sub ledger</th>
              <!-- <th>Dibuat Oleh</th>
              <th>Dibuat Pada</th> -->
              <th>Diubah Oleh</th>
              <th>Diubah Pada</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let row of dataRelation">
              <td>{{row.relation_code}}</td>
              <td>{{row.relation_name}}</td>
              <!-- <td>{{row.relation_address}}</td> -->
              <td>{{row.relation_phone}}</td>
              <td>{{row.relation_type}}</td>
              <td>{{row.relation_sl_code}}</td>
              <!-- <td>{{row.createdby}}</td>
              <td>{{row.created_at | slice:0:10}}</td>   -->
              <td>{{row.updatedby}}</td>  
              <td>{{row.updated_at | slice:0:10}}</td>    
              <td>
                <button (click)="onSelectEkspedisi(row);ModalEkspedisi.hide()" class="btn btn-sm btn-primary"  data-toggle="modal" triggers="mouseenter:mouseleave"><i class="fa fa-check"></i></button>
              </td> 
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <th><input id="codesearchT" type="text" class="searchInputT" placeholder="Cari Kode" name="search-code"/></th>
              <th><input id="namesearchT" type="text" class="searchInputT" placeholder="Cari Name\a" name="search-relation"/></th>
              <th><input hidden class="searchInputT" /></th>
              <th><input hidden class="searchInputT" /></th>
              <th><input hidden class="searchInputT" /></th>
              <th><input hidden class="searchInputT" /></th>
              <th><input hidden class="searchInputT" /></th>
              <th><input hidden class="searchInputT" /></th>
            </tr>
          </tfoot>        
        </table>
      </div>
    </div>
  </div>
</div>

<ngx-spinner  bdColor = "rgba(248,248,248,0.95)" size = "large" color = "#262424" type = "ball-spin-fade" >
  <p style="font-size: 20px; color: white"></p>
</ngx-spinner>


