
<!-- Main content -->
<section class="content">    
    <div class="card">
      <div class="card-header">
      <strong>List Toleransi</strong>
      <div class="float-right">
        <a type="button" class="btn btn-sm btn-primary pull-right" data-toggle="modal" (click)="ModalTolerance.show();"><b>&nbsp;&nbsp;&nbsp;Input Toleransi&nbsp;&nbsp;&nbsp;&nbsp;</b><i class="fa fa-pen"></i>&nbsp;&nbsp;&nbsp;</a>
      </div>
    </div>
      <div class="card-body">
    <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table-striped">
       <thead>
        <tr>
          <th>Action</th>
          <th>Toleransi</th>
          <th>Nama Material</th>
          <th>Nama Kapasitas</th>
          <th>UOM</th>
          <th>Dibuat Oleh</th>
          <th>Dibuat Pada</th>
          <th>Diubah Oleh</th>
          <th>Diubah Pada</th>
        </tr>
      </thead> 
      <tbody>
        <tr *ngFor="let row of dataTolerance">
        <td>
            <button title="Hapus" class="btn btn-sm btn-danger" (click)="deleteTolerance(row);" triggers="mouseenter:mouseleave"><i class="fa fa-trash"></i></button>&nbsp;
            <button title="Edit" class="btn btn-sm btn-primary" (click)="onSelectTolerance(row);ModalTolerance.show()"  data-toggle="modal"triggers="mouseenter:mouseleave"><i class="fa fa-pen"></i></button>
        </td>       
          <td>{{row.tolerance}}</td>
          <td>{{row.material_name}}</td>
          <td>{{row.capacity_code}}</td>
          <td>{{row.uom_name}}</td>
          <td>{{row.created_by_text}}</td>
          <td>{{row.created_at | slice:0:10}}</td>  
          <td>{{row.updated_by_text}}</td>  
          <td>{{row.updated_at | slice:0:10}}</td>    
        </tr>
      </tbody>
     <tfoot>
        <tr>
          <th><input hidden class="searchInput"/></th>
          <th><input id="tolerance" type="text" class="searchInput" placeholder="Tolerance"/></th>
          <th><input id="material_name" type="text" class="searchInput" placeholder="Material"/></th>
          <th><input id="capacity_code" type="text" class="searchInput" placeholder="Kapasitas"/></th>
          <th><input id="uom_name" type="text" class="searchInput" placeholder="UOM"/></th>
          <th><input hidden class="searchInput"/></th>
          <th><input hidden class="searchInput"/></th>
          <th><input hidden class="searchInput"/></th>
          <th><input hidden class="searchInput"/></th>
        </tr>
      </tfoot>

    </table>
  </div>
  </div>
</section>




<div  bsModal #ModalTolerance="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog  modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title"><strong>{{action}} Data Toleransi</strong></div>
          <small><code style="color: black">&nbsp;&nbsp;*(semua kolom wajib di isi)</code></small>
          <button type="button" class="close" #modalSaveClose (click)="ModalTolerance.hide()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="form-group row">
            <label class="col-md-3 col-form-label" for="text-input">Material</label>
            <input hidden [(ngModel)]="toleranceModel.id" disabled type="text" class="form-control">
            <input hidden [(ngModel)]="toleranceModel.material_id" disabled type="text" class="form-control">
            <div class="col-md-7">
              <input [(ngModel)]="materialSelect" [ngModelOptions]="{standalone: true}" disabled type="text" class="form-control">
            </div>
            <div class="col-md-2">
              <button style="position:absolute; right: 0; right: 75px; top: 4px;" class="btn btn-sm btn-primary" 
              data-toggle="modal" (click)="ModalMaterial.show()"><i class="fa fa-plus"></i></button>
            </div>
          </div> 
          <div class="form-group row">
            <label class="col-md-3 col-form-label" for="text-input">Kapasitas</label>
            <input hidden [(ngModel)]="toleranceModel.capacity_id" disabled type="text" class="form-control">
            <div class="col-md-7">
              <input [(ngModel)]="capacitySelect" [ngModelOptions]="{standalone: true}" disabled type="text" class="form-control" > 
            </div>
            <div class="col-md-2">
              <button style="position:absolute; right: 0; right: 75px; top: 4px;" class="btn btn-sm btn-primary" 
              data-toggle="modal" (click)="ModalCapacity.show()"><i class="fa fa-plus"></i></button>
            </div>
          </div> 
          <div class="form-group row">
            <label class="col-md-3 col-form-label" for="text-input">Toleransi</label>
            <div class="col-md-9">
              <input [(ngModel)]="toleranceModel.tolerance" type="text" class="form-control">
          </div>
          </div> 
          <div class="form-group row">
            <label class="col-md-3 col-form-label" for="text-input">UOM</label>
            <div class="col-md-9">
              <select [(ngModel)]="toleranceModel.uom" id="select" name="select" class="form-control">
                <option *ngFor="let row of dataType" [value]='row.id'><b>{{row.text}}</b></option>
              </select>
          </div>
          </div> 
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" (click)="ModalTolerance.hide()"><b>Close</b></button>
          <button type="button" class="btn btn-primary"  (click)="saveDataTolerance();"><i class="fa fa-save"></i>&nbsp;&nbsp;<b>Save</b>&nbsp;&nbsp;</button>
        </div>
      </div>
    </div>
  </div>

<div bsModal #ModalMaterial="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog  modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title"><strong>Pilih Material</strong></div>
        <button type="button" class="close" (click)="ModalMaterial.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body"> 
        <table datatable [dtOptions]="dtOptions2" [dtTrigger]="dtTrigger2" class="table-striped">
          <thead>
           <tr>
             <th>Action</th>
             <th>Kode Material</th>
             <th>Nama Material</th>
             <th>Harga</th>
             <th>UOM</th>
             <th>Tipe Cetakan DO</th>
             <th>Dibuat Oleh</th>
             <th>Dibuat Pada</th>
             <th>Diubah Oleh</th>
             <th>Diubah Pada</th>
           </tr>
         </thead> 
         <tbody>
           <tr *ngFor="let row of componentMaterial.dataMaterial">
           <td>
               <button class="btn btn-sm btn-primary" (click)="onSelectMaterial(row);ModalMaterial.hide()" data-toggle="modal" triggers="mouseenter:mouseleave"><i class="fa fa-pen"></i></button>
           </td>       
             <td>{{row.material_code}}</td>
             <td>{{row.material_name}}</td>
             <td>{{row.material_price}}</td>
             <td>{{row.uom}}</td>
             <td>{{row.do_print_type}}</td>
             <td>{{row.created}}</td>
             <td>{{row.created_at | slice:0:10}}</td>  
             <td>{{row.updated_by}}</td>  
             <td>{{row.updated_at | slice:0:10}}</td>    
           </tr>
         </tbody>
         <tfoot>
           <tr>
             <th><input hidden class="searchInputMaterial" /></th>
             <th><input id="codeMaterialsearch" type="text" class="searchInputMaterial" placeholder="Search Kode"/></th>
             <th><input id="nameMaterialasearch" type="text" class="searchInputMaterial" placeholder="Search Material"/></th>
             <th><input hidden type="text" class="searchInputMaterial"/></th>
             <th><input hidden type="text" class="searchInputMaterial"/></th>
             <th><input hidden type="text" class="searchInputMaterial"/></th>
             <th><input hidden type="text" class="searchInputMaterial"/></th>
             <th><input hidden type="text" class="searchInputMaterial"/></th>
             <th><input hidden type="text" class="searchInputMaterial"/></th>
             <th><input hidden type="text" class="searchInputMaterial"/></th>
           </tr>
         </tfoot>
   
       </table>
      </div>
    </div>
  </div>
</div>


<div bsModal #ModalCapacity="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog  modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title"><strong>Pilih Kapasitas</strong></div>
        <button type="button" class="close" (click)="ModalCapacity.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body"> 
        <table datatable  [dtOptions]="dtOptions3" [dtTrigger]="dtTrigger3"  class="table-striped">
          <thead>
            <tr>
              <th>Action</th>
              <th>Kode</th>
              <th>Keterangan</th>
              <th>Tipe Kendaraan</th>
              <th>Tonnase</th>
              <th>Diubah Oleh</th>
              <th>Diubah Pada</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let row of componentCapacity.dataCapacity">
              <td>
                <button (click)="onSelectCapacity(row);ModalCapacity.hide()" class="btn btn-sm btn-primary"  data-toggle="modal" triggers="mouseenter:mouseleave"><i class="fa fa-check"></i></button>
              </td> 
              <td>{{row.capacity_code}}</td>
              <td>{{row.capacity_note}}</td>
              <td>{{row.vehicleType}}</td>
              <td>{{row.tonCode}}</td>
              <td>{{row.updatedby}}</td>  
              <td>{{row.updated_at | slice:0:10}}</td>    
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <th><input id="codecapacity" type="text" class="searchInputCapacity" placeholder="Kode" /></th>
              <th><input id="namecapacity" type="text" class="searchInputCapacity" placeholder="Nama" /></th>
              <th><input id="vehiclesearch" type="text" class="searchInputCapacity" placeholder="Kendaraan"/></th>
              <th><input hidden class="searchInputCapacity"/></th>
              <th><input hidden class="searchInputCapacity"/></th>
              <th><input hidden class="searchInputCapacity"/></th>
              <th><input hidden class="searchInputCapacity"/></th>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </div>
</div>

  <ngx-spinner  bdColor = "rgba(248,248,248,0.95)" size = "large" color = "#262424" type = "ball-spin-fade" >
    <p style="font-size: 20px; color: white"></p>
  </ngx-spinner>

