import { AfterViewInit, Component, OnInit, ViewChild,Input, ChangeDetectorRef, ElementRef  } from '@angular/core';
import { TransportService} from '../../../services/api.service';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { AccClosing } from '@/model/accClosing';
import { MasterCode } from '@/model/masterCode';
import moment from 'moment';
import { User } from '@/model/user';
import { Role } from '@/model/role';
import { MasterAuth, MasterMenu } from '@/model/masterMenu';
import { AuthMenu, MasterSubMenu } from '@/model/masterSubMenu';
import { Guid } from 'guid-typescript';
import { SpkService, SpkServiceDetail } from '@/model/spkService';
import { ListTruck } from '@/model/listTruck';
import { Driver } from '@/model/driver';

@Component({
  selector: 'app-spk-service',
  templateUrl: './spk-service.component.html',
  styleUrls: ['./spk-service.component.scss']
})
export class SpkServiceComponent implements OnInit, AfterViewInit {
  @ViewChild('modalSimpanClose') modalSimpanClose: ElementRef;
  @ViewChild('modalProcessClose') modalProcessClose: ElementRef;
  dtOptionsAcc: any = {};
  dtOptionsDetail: any = {};
  dtTriggerDetail = new Subject();
  dtTriggerAcc = new Subject();
  dataListSPK: SpkService[] = [];
  dtOptionsTruck: any = {};
  dtTriggerTruck = new Subject();
  dtOptionsDriver: any = {};
  dtTriggerDriver = new Subject();
  listRole: Role[] = [];
  listMenu: MasterMenu[] = [];
  listSubMenu: MasterSubMenu[] = [];
  listMasterAuth: MasterAuth[] = [];
  listSpkDetail: SpkServiceDetail[] = [];
  listSpkDetailDelete: SpkServiceDetail[] = [];
  @Input() finishModel = { SpkId: null}
  dataTruck: ListTruck[] = [];
  dataDriver: Driver[] = [];
  public dt: any;
  public cb: any;
  public dtTruck: any;
  public cbTruck: any;
  public dtDriver: any;
  public cbDriver: any;
  public update : boolean;
  public show : boolean;
  locationName: string;
  menu: Guid;
  @Input() listApps = { roleName: null}
  @Input() spkNo = { startDate: null, code: null}
  @Input() spkDetailModel = {remark: null}
  @Input() proccesModel = { SpkId: null, SpkNo: null, TruckNo: null, DamageType: null, Workshop: null, RemarkProcess: null}
  @Input() spkModel = { SpkId: null, SpkNo: null, TruckId: null, DriverId: null, TruckNo: null, DriverName: null
                        , OilChange: null, StartDate: null, EndDate: null, spkDetail: new Array(), spkDetailDelete: new Array()}
  constructor( public service: TransportService, private router: Router, private spinner: NgxSpinnerService, private changeDetectorRef: ChangeDetectorRef) {
  }

    ngOnInit(): void {
      this.listApps.roleName = localStorage.getItem("rolename");
      this.locationName = localStorage.getItem('locationname');
      this.service.getListApprovals(this.listApps).subscribe(resp => {
        console.log(JSON.stringify(resp))
       });
      //this.userAccess();
      this.dtOptionsAcc = {  
        scrollX: true,
        scrollCollapse: true,
        pageLength: 10,
        ordering : false,
        serverSide: true,
        processing: true,
        searching : false,
        ajax: (dataTablesParameters: any, callback) => {
         this.dt = dataTablesParameters; this.cb = callback;
         this.getSPKServiceList(dataTablesParameters, callback, true, "","","","");

        },
        columnDefs: [
          { width: 200, targets: [0]},
          { width: 200, targets: [1,2,3,4,5,6,7]}
        ],
      };

      this.dtOptionsTruck = {
        retrieve: true,
        pageLength: 5,
        scrollX: true,
        scrollCollapse: true,
        serverSide: true,
        processing: true,
        searching : false,
        ajax: (dataTablesParameters: any, callback) => {
         this.dtTruck = dataTablesParameters; this.cbTruck = callback;
         this.getDataTruck(dataTablesParameters, callback, "","","",true);
        },
        columnDefs: [
          { width: 100, targets: 0 },
          { width: 150, targets: 1 },
          { width: 150, targets: 2 },
        ],
        
      };

      this.dtOptionsDetail = {
        retrieve: true,
        //pageLength: 100,
        ordering : false,
        // scrollX: true,
        // scrollCollapse: true,
        searching : false,
        columnDefs: [
          { width: 400, targets: [1]},
          { width: 80, targets: [0,2,3,4] }
        ],
      };

      this.dtOptionsDriver = {
        retrieve: true,
        pageLength: 5,
        scrollX: true,
        scrollCollapse: true,
        serverSide: true,
        processing: true,
        searching : false,
        ajax: (dataTablesParameters: any, callback) => {
         this.dtDriver = dataTablesParameters; this.cbDriver = callback;
         this.getDataDriver(dataTablesParameters, callback, "", "","","","", "","","","", "","","", "",  true);
         },
        columnDefs: [
          { width: 150, targets: [0,2,3,4,5,6,7,8,9,10,12,13,14,15] },
          { width: 200, targets: [1] },
          { width: 300, targets: [11] },
        ],
      };

      const that = this;
      $('.searchInputS').on("keyup", function(e) {
        var key = e.which;
        if (key == 13) // the enter key ascii code
        {
          that.getSPKServiceList(that.dt, that.cb, false, $('#spkNo').val().toString(), $('#truckNo').val().toString(),
          $('#driverName').val().toString(),$('#status').val().toString());
        }
      });
      
    }   

    userAccess(){
      const username = localStorage.getItem("username");
      if (!username.includes("admin")){
          return this.router.navigate(["/home"]);
      }
    }
    
    ngAfterViewInit(): void {
      this.dtTriggerAcc.next(this.dtOptionsAcc)
      this.dtTriggerDetail.next(this.dtOptionsDetail);
    }
  
    ngOnDestroy(): void {
      this.dtTriggerAcc.unsubscribe();
     this.dtTriggerDetail.unsubscribe();
    }

    // onSelectDataUser(data: Role){
    //   this.authModel.roleId = data.role_id;
    //   this.service.getAuthByRole(data.role_id.toString()).subscribe(resp => {
    //     this.listAuthMenu = resp;
    //     setTimeout(() => {
    //       this.dtTriggerAuth.next(0);
    //     }, 100);
    //   });
    // }

    showTruck(){
      this.dtTriggerTruck.next(this.dtOptionsTruck);
    }

    showDriver(){
      this.dtTriggerDriver.next(this.dtOptionsDriver);
    }

    getDataDriver(dataTablesParameters, callback,  driver_name: string, driver_license: string, driver_license_type: string, 
      agama: string, birthplace: string, ktp_no: string, tanggal_lahir: string, sl_code: string, sl_code2: string,
      sl_code3: string, address: string, valid_to: string, valid_to_contract: string, padging : boolean){ 
      var page = 1;
      if (padging == true){
        page = parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length) + 1;
      }
      this.service.getListDriver(dataTablesParameters, page, dataTablesParameters.length, driver_name, driver_license, driver_license_type, agama,
        birthplace,ktp_no,tanggal_lahir, sl_code, sl_code2, sl_code3, address, valid_to, valid_to_contract, "true", "false")
      .subscribe(resp => { 
          this.dataDriver = resp.data;
          callback({
            recordsTotal: resp.totalCount,
            recordsFiltered: resp.totalCount,
            data:  []
          });
      });
    }

    getDataTruck(dataTablesParameters, callback, platNumber: string, codeCapacity: string, location: string, padging : boolean){ 
      var page = 1;
      if (padging == true){
        page = parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length) + 1;
      }
      this.service.getListTruck(dataTablesParameters, page, dataTablesParameters.length, platNumber, codeCapacity, location)
      .subscribe(resp => { 
          this.dataTruck = resp.data;
          callback({
            recordsTotal: resp.totalCount,
            recordsFiltered: resp.totalCount,
             data:  []
          });
      });
    }

    onSelectMenu(id: Guid){
      this.service.getSubmenuById(id).subscribe(resp => {
        this.listSubMenu = resp;
      });
    }

    onSelectSubMenu(id: Guid){
      this.service.getMasterAuth(id).subscribe(resp => {
        this.listMasterAuth = resp;
      });
    }

    getSPKServiceList(dataTablesParameters, callback, padging: Boolean, spkNo: string, truckNo: string, driverName: string, status: string){
    var page = 1;
      if (padging == true){
        page = parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length) + 1;
      }

      this.service.getSPKServiceList(dataTablesParameters, page, dataTablesParameters.length, spkNo, truckNo, driverName, status)
      .subscribe(resp => { 
          this.dataListSPK = resp.data;
          callback({
            recordsTotal: resp.totalCount,
            recordsFiltered: resp.totalCount,
             data:  []
          });
      });
  }

  messageWarning(text: string){
    Swal.fire({
      title: ""+text,
      type: 'warning',
      confirmButtonText: '      Ok!     '
    });
    return;
  }
  
  addSPkDetail(){
    this.listSpkDetail.push( { spkDetailId : null, spkId : null, remark : this.spkDetailModel.remark, 
      createdAt : null, updatedAt : null, createdName : null,updatedName : null} );
  }

  createSPK(){
    // if (this.roleModel.role_name == null || this.roleModel.role_name == "" ){
    //   Swal.fire({
    //     title: "Data Masih Belum Lengkap !",
    //     type: 'warning',
    //     confirmButtonText: '      Ok!     '
    //   });
    //   return;
    // }
    Swal.fire({
      title: 'Simpan Data?',
      type: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '      Yes     '
      }).then((result) => {
        if (result.value) {
         // this.spinner.show();

          this.spkModel.spkDetail = [];
            for (let i = 0; i < this.listSpkDetail.length; i++) {
              this.spkModel.spkDetail.push({
                remark : this.listSpkDetail[i].remark
            });
          }
          console.log(this.spkModel);
          this.service.addSpkService(this.spkModel).subscribe( resp => {
            this.spinner.hide();
            if(resp.succes == false){
              Swal.fire({
                title: ""+resp.text,
                type: 'warning',
                confirmButtonText: '      Ok!     '
              });
            }
            else{
              Swal.fire({
                title: ""+resp.text,
                type: 'success',
                confirmButtonText: '      Ok!     '
              }).then((result) => {
                if (result.value) { 
                  this.getSPKServiceList(this.dt, this.cb,  true,"","","","");
                }              
              });
              this.modalSimpanClose.nativeElement.click();
            }
          })
        }
    });
  }

  processSPK(){
    // if (this.roleModel.role_name == null || this.roleModel.role_name == "" ){
    //   Swal.fire({
    //     title: "Data Masih Belum Lengkap !",
    //     type: 'warning',
    //     confirmButtonText: '      Ok!     '
    //   });
    //   return;
    // }
    Swal.fire({
      title: 'Proses SPK?',
      type: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '      Yes     '
      }).then((result) => {
        if (result.value) {
          this.service.processSpkService(this.proccesModel).subscribe( resp => {
            this.spinner.hide();
            if(resp.succes == false){
              Swal.fire({
                title: ""+resp.text,
                type: 'warning',
                confirmButtonText: '      Ok!     '
              });
            }
            else{
              Swal.fire({
                title: ""+resp.text,
                type: 'success',
                confirmButtonText: '      Ok!     '
              }).then((result) => {
                if (result.value) { 
                  this.getSPKServiceList(this.dt, this.cb,  true,"","","","");
                }              
              });
              this.modalProcessClose.nativeElement.click();
            }
          })
        }
    });
  }

  updateSPK(){
    // if (this.roleModel.role_name == null || this.roleModel.role_name == "" ){
    //   Swal.fire({
    //     title: "Data Masih Belum Lengkap !",
    //     type: 'warning',
    //     confirmButtonText: '      Ok!     '
    //   });
    //   return;
    // }
    Swal.fire({
      title: 'Update Data?',
      type: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '      Yes     '
      }).then((result) => {
        if (result.value) {
         // this.spinner.show();

          this.spkModel.spkDetail = [];
          for (let i = 0; i < this.listSpkDetail.length; i++) {
             if (this.listSpkDetail[i].spkDetailId == null){ 
              this.spkModel.spkDetail.push({
                spkDetailId : null,
                remark : this.listSpkDetail[i].remark
              });
             }
          }

          this.spkModel.spkDetailDelete = [];
          for (let i = 0; i < this.listSpkDetailDelete.length; i++) {
              this.spkModel.spkDetailDelete.push({
                spkDetailId : this.listSpkDetailDelete[i].spkDetailId,
                spkId : this.listSpkDetailDelete[i].spkId,
                remark : this.listSpkDetailDelete[i].remark
            });
          }

          console.log(this.spkModel);
          this.service.updateSpkService(this.spkModel).subscribe( resp => {
            this.spinner.hide();
            if(resp.succes == false){
              Swal.fire({
                title: ""+resp.text,
                type: 'warning',
                confirmButtonText: '      Ok!     '
              });
            }
            else{
              Swal.fire({
                title: ""+resp.text,
                type: 'success',
                confirmButtonText: '      Ok!     '
              }).then((result) => {
                if (result.value) { 
                  this.getSPKServiceList(this.dt, this.cb,  true,"","","","");
                }              
              });
              this.modalSimpanClose.nativeElement.click();
            }
          })
        }
    });
  }

  // addAuth(){
  //   if (this.authModel.subMenuId == null || this.authModel.roleId == null || this.authModel.authId == null || 
  //     this.authModel.subMenuId == "" || this.authModel.roleId == "" || this.authModel.authId == ""){
  //     Swal.fire({
  //       title: "Data Masih Belum Lengkap !",
  //       type: 'warning',
  //       confirmButtonText: '      Ok!     '
  //     });
  //     return;
  //   }
  //   Swal.fire({
  //     title: 'Simpan Data?',
  //     type: 'question',
  //     showCancelButton: true,
  //     confirmButtonColor: '#3085d6',
  //     cancelButtonColor: '#d33',
  //     confirmButtonText: '      Yes     '
  //     }).then((result) => {
  //       if (result.value) {
  //         this.service.addUserAuth(this.authModel).subscribe( resp => {
  //           this.spinner.hide();
  //           if(resp.succes == false){
  //             Swal.fire({
  //               title: ""+resp.text,
  //               type: 'warning',
  //               confirmButtonText: '      Ok!     '
  //             });
  //           }
  //           else{
  //             Swal.fire({
  //               title: ""+resp.text,
  //               type: 'success',
  //               confirmButtonText: '      Ok!     '
  //             }).then((result) => {
  //               if (result.value) { 
  //                 this.service.getAuthByRole(this.authModel.roleId).subscribe(resp => {
  //                   this.listAuthMenu = resp;
  //                   setTimeout(() => {
  //                     this.dtTriggerAuth.next(0);
  //                   }, 100);
  //                 });
  //               }              
  //             });
  //           }
  //         })
  //       }
  //   });
  // }


  

  
  // deleteRole(data: Role){
  //   this.roleModel.role_id = data.role_id;
  //   console.log("id : "+this.roleModel.role_id)
  //   Swal.fire({
  //     title: 'Delete Data?',
  //     type: 'question',
  //     showCancelButton: true,
  //     confirmButtonColor: '#3085d6',
  //     cancelButtonColor: '#d33',
  //     confirmButtonText: '      Yes     '
  //     }).then((result) => {
  //       if (result.value) {
  //         this.spinner.show();
  //         setTimeout(() => {
  //           this.spinner.hide();
  //         }, 5000);
  //         this.service.deleteRole(this.roleModel).subscribe(resp => {
  //           this.spinner.hide();
  //             if(resp.succes == false){
  //               Swal.fire({
  //                 title: ""+resp.text,
  //                 type: 'warning',
  //                 confirmButtonText: '      Ok!     '
  //               });
  //             }
  //             else{
  //               Swal.fire({
  //                 title: ""+resp.text,
  //                 type: 'success',
  //                 confirmButtonText: '      Ok!     '
  //               }).then((result) => {
  //                 if (result.value) { 
  //                   this.getRoleList(this.dt, this.cb, true);
  //                 }              
  //               });
  //             }
  //         })

  //       }
  //   })
  // }

  onSelectProcess(data: any){
    this.proccesModel.SpkId = data.spkId;
    this.proccesModel.SpkNo = data.spkNo;
    this.proccesModel.TruckNo = data.truckNo;
  }

  onSelectDetailSPK(data: any){
    this.show = true;
    this.update = true;
    this.service.getListSPKDetail(data.spkId).subscribe(resp => {
      this.listSpkDetail = resp;
    });
    this.spkModel.SpkId = data.spkId;
    this.spkModel.SpkNo = data.spkNo;
    this.spkModel.TruckId = data.truckId;
    this.spkModel.DriverId = data.driverId;
    this.spkModel.TruckNo = data.truckNo;
    this.spkModel.DriverName = data.driverName;
    this.spkModel.OilChange = data.oilChange;
    this.spkModel.StartDate = data.startDate;
    this.spkModel.EndDate = data.endDate;
  }

  onSelectUpdate(data: any){
    this.show = false;
    this.update = true;
    this.service.getListSPKDetail(data.spkId).subscribe(resp => {
      this.listSpkDetail = resp;
    });
    this.spkModel.SpkId = data.spkId;
    this.spkModel.SpkNo = data.spkNo;
    this.spkModel.TruckId = data.truckId;
    this.spkModel.DriverId = data.driverId;
    this.spkModel.TruckNo = data.truckNo;
    this.spkModel.DriverName = data.driverName;
    this.spkModel.OilChange = data.oilChange;
    this.spkModel.StartDate = data.startDate;
    this.spkModel.EndDate = data.endDate;
  }

  onSelectTruck(data: ListTruck){
    this.spkModel.TruckId = data.truck_id;
    this.spkModel.TruckNo = data.truck_plate_number;
    this.getDataTruck(this.dtTruck, this.cbTruck, "","","",true);
  } 

  onSelectDriver(data: Driver){
    this.spkModel.DriverId = data.driver_id;
    this.spkModel.DriverName = data.driver_name;
  }

  setNull(){
    this.show = false;
    this.update = false;
    var today = moment().format('YYYY-MM-DD HH:mm');
    this.spkModel.StartDate = today;
    this.spkModel.EndDate = today;
    this.spkModel.TruckId = null;
    this.spkModel.TruckNo = null;
    this.spkModel.DriverId = null;
    this.spkModel.DriverName = null;
    this.spkModel.OilChange = false;
    this.listSpkDetail = [];
    this.spkNo.startDate = this.spkModel.StartDate;
    this.spkNo.code = "SPK";
    this.service.generateSPKNo(this.spkNo).subscribe(resp => {
      if(resp.succes == false){
          Swal.fire({
            title: ""+resp.text,
            type: 'warning',
            confirmButtonText: '      Ok!     '
          });
      }
      else{
        this.spkModel.SpkNo = resp.text;
      }
    });
  }

  onTruck(data: ListTruck){
    // this.service.getVehicleSelect(data.truck_capacity_id).subscribe(resp => {
    //   this.tripModel.vehicle_type_id = resp.id;
    //   this.tripModel.vehicle_type_name = resp.code;
    // })
    // this.capacityId = data.truck_capacity_id.toString();
    // this.tripModel.truck_id = data.truck_id;
    // this.tripModel.truck_plate_number = data.truck_plate_number;
    // this.tripModel.capacity_id = data.truck_capacity_id;
    // this.getLabelCalculate();
    // this.getDataPort(this.dtPort, this.cbPort,  this.routeId, this.capacityId,"",true);
    // $('.searchInputTruck').val('');
    this.getDataTruck(this.dtTruck, this.cbTruck, "","","",true);  
  }

  deleteDataDetail(id: number, data: SpkServiceDetail){
    Swal.fire({
      title: 'Delete Data?',
      type: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '      Yes     '
      }).then((result) => {
        if (result.value) {
        this.listSpkDetail.splice(id, 1); this.changeDetectorRef.detectChanges();
        this.listSpkDetailDelete.push({spkDetailId : data.spkDetailId, spkId : data.spkId, remark : data.remark, 
          createdAt : data.createdAt, updatedAt : data.updatedAt, createdName : data.createdName,updatedName : data.updatedName});
        }
    })
  }

  
  printSPK(data: any){
    this.spinner.show();
    this.service.printSpkService(data.spkId, this.locationName).subscribe(resp =>{
      this.spinner.hide();
      let blob:Blob=resp.body as Blob;
        let url = window.URL.createObjectURL(blob);
        window.open(url);
      })
  }

  
  finish(spk: any){
    this.finishModel.SpkId = spk.spkId;
    Swal.fire({
      title: 'Selesaikan SPK '+spk.spkNo+' ?',
      type: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '      Yes     '
      }).then((result) => {
        if (result.value) {
          this.service.finishSPKService(this.finishModel).subscribe( resp => {
            this.spinner.hide();
            if(resp.succes == false){
              Swal.fire({
                title: ""+resp.text,
                type: 'warning',
                confirmButtonText: '      Ok!     '
              });
            }
            else{
              Swal.fire({
                title: ""+resp.text,
                type: 'success',
                confirmButtonText: '      Ok!     '
              }).then((result) => {
                if (result.value) { 
                 this.getSPKServiceList(this.dt, this.cb,  true,"","","","");
                }              
              });
            }
          })
        }
    });
  }

}





