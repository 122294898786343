
<!-- Main content -->
<section class="content">

    
    <div class="card">
      <div class="card-header">
      <strong>List Material</strong>
      <div *ngIf="!modalTableMaterial" class="float-right">
        <a type="button" class="btn btn-sm btn-warning pull-right" data-toggle="modal" (click)="exportAsXLSX()">&nbsp;&nbsp;&nbsp;<b>Export Data</b>&nbsp;&nbsp;&nbsp;&nbsp;<i class="fas fa-file-excel"></i>&nbsp;&nbsp;&nbsp;</a>&nbsp;&nbsp;&nbsp;&nbsp;
        <a type="button" class="btn btn-sm btn-primary pull-right" data-toggle="modal" (click)="ModalMaterial.show();clearFormMaterial()"><b>&nbsp;&nbsp;&nbsp;Input Material&nbsp;&nbsp;&nbsp;&nbsp;</b><i class="fa fa-pen"></i>&nbsp;&nbsp;&nbsp;</a>
      </div>
    </div>
      <div class="card-body">
    <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table-striped">
       <thead>
        <tr>
          <th>Action</th>
          <th>Kode Material</th>
          <th>Nama Material</th>
          <th>Harga</th>
          <th>UOM</th>
          <th>Tipe Cetakan DO</th>
          <th>Dibuat Oleh</th>
          <th>Dibuat Pada</th>
          <th>Diubah Oleh</th>
          <th>Diubah Pada</th>
        </tr>
      </thead> 
      <tbody>
        <tr *ngFor="let row of dataMaterial">
        <td><button *ngIf="!modalTableMaterial" title="Hapus Data" class="btn btn-sm btn-danger" (click)="deleteMaterial(row)" triggers="mouseenter:mouseleave"><i class="fa fa-trash"></i></button>&nbsp;
            <button *ngIf="!modalTableMaterial" title="Edit Data" (click)="onSelectMaterial(row);ModalMaterial.show()" class="btn btn-sm btn-primary" triggers="mouseenter:mouseleave"><i class="fa fa-pen"></i></button>&nbsp;
            <button *ngIf="modalTableMaterial" title="Pilih Data" (click)="onSelectMaterial(row);" class="btn btn-sm btn-primary" triggers="mouseenter:mouseleave"><i class="fa fa-check"></i></button>
        </td>       
          <td>{{row.material_code}}</td>
          <td>{{row.material_name}}</td>
          <td>{{row.material_price}}</td>
          <td>{{row.uom}}</td>
          <td>{{row.do_print_type}}</td>
          <td>{{row.created}}</td>
          <td>{{row.created_at | slice:0:10}}</td>  
          <td>{{row.updated_by}}</td>  
          <td>{{row.updated_at | slice:0:10}}</td>    
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <th><input hidden class="searchInput" /></th>
          <th><input id="codesearch" type="text" class="searchInput" placeholder="Search Kode"/></th>
          <th><input id="namesearch" type="text" class="searchInput" placeholder="Search Material"/></th>
          <th><input hidden type="text" class="searchInput"/></th>
          <th><input hidden type="text" class="searchInput"/></th>
          <th><input hidden type="text" class="searchInput"/></th>
          <th><input hidden type="text" class="searchInput"/></th>
          <th><input hidden type="text" class="searchInput"/></th>
          <th><input hidden type="text" class="searchInput"/></th>
          <th><input hidden type="text" class="searchInput"/></th>
        </tr>
      </tfoot>

    </table>
  </div>
  </div>

      


      <!-- <div id="print-section">
      Put here your html stuff that you want to print... {{test}}
    </div>
    
    <button printTitle="Print Title" printSectionId="print-section" ngxPrint>
      Print
    </button> -->







    <!-- /.card -->
</section>


<div  bsModal #ModalMaterial="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog  modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title"><strong>{{action}} Data Material</strong></div>
          <small><code style="color: black">&nbsp;&nbsp;*(semua kolom wajib di isi)</code></small>
          <button type="button" class="close" (click)="ModalMaterial.hide()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="form-group row">
            <label class="col-md-3 col-form-label" for="text-input">Kode Material</label>
            <div class="col-md-9">
              <input [(ngModel)]="materialModel.code" type="text" class="form-control">
          </div>
          </div> 
          <div class="form-group row">
            <label class="col-md-3 col-form-label" for="text-input">Nama Material</label>
            <div class="col-md-9">
              <input [(ngModel)]="materialModel.name" type="text" class="form-control" >
          </div>
          </div> 
          <div class="form-group row">
              <label class="col-md-3 col-form-label" for="text-input" >Harga</label>
              <div class="col-md-9">
                <input [(ngModel)]="materialModel.price" type="text" class="form-control" currencyMask [options]="{ prefix: '', thousands: '.', decimal: ',' ,precision: '0',align: 'left' }">
          </div>
          </div> 
          <div class="form-group row">
            <label class="col-md-3 col-form-label" for="text-input">UOM</label>
            <div class="col-md-9">
              <input [(ngModel)]="materialModel.uom" type="text" class="form-control" >
          </div>
          </div> 
          <div class="form-group row">
            <label class="col-md-3 col-form-label" for="text-input">Cetakan DO</label>
            <div class="col-md-9">                      
              <select [(ngModel)]="materialModel.type" id="select" name="select" class="form-control">
                <option value="DO_NORMAL">Normal</option>
                <option value="DO_PUPUK">Pupuk</option>
              </select>
           </div>
          </div> 
        </div>
        <div class="modal-footer">
          <button #ModalMaterialrHide type="button" class="btn btn-secondary" (click)="ModalMaterial.hide()"><b>Close</b></button>
          <button type="button" class="btn btn-primary"  (click)="saveDataMaterial();"><i class="fa fa-save"></i>&nbsp;&nbsp;<b>Save</b>&nbsp;&nbsp;</button>
        </div>
      </div>
    </div>
  </div>

  <!-- <button class="btn btn-sm btn-warning" data-toggle="modal" (click)="ModalMaterial.show()">Test</button> -->

  <ngx-spinner  bdColor = "rgba(248,248,248,0.95)" size = "large" color = "#262424" type = "ball-spin-fade" >
    <p style="font-size: 20px; color: white"></p>
  </ngx-spinner>

  <button hidden #ModalMaterialrShow (click)="ModalMaterial.show()"></button>

  