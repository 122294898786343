import { AfterViewInit, Component, OnInit, ViewChild,Input, ChangeDetectorRef, ElementRef  } from '@angular/core';
import { DataTableDirective } from 'angular-datatables'
import { TransportService} from '../../../services/api.service';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Guid } from 'guid-typescript';
import { DriverSubscriptionByCriteria } from '@/model/driverSubscriptionByCriteria';
import { MasterCode } from '@/model/masterCode';
import { ListSusut } from '@/model/listSusut';
import { LocationSettingSignature } from '@/model/locationSettingSignature';
import { UserAuth } from '@/model/userAuth';
import moment from 'moment';
import { saveAs } from 'file-saver';
import { AuthMenu } from '@/model/masterSubMenu';
import { UserAuthNew } from '@/model/respDto';

@Component({
  selector: 'app-claim-susut',
  templateUrl: './claim-susut.component.html',
  styleUrls: ['./claim-susut.component.scss']
})
export class ClaimSusutComponent  implements OnInit, AfterViewInit {
  @ViewChild('ModalSimpanHide') ModalSimpanHide: ElementRef;
  @ViewChild('ModalCancelClose') ModalCancelClose: ElementRef; 
  @ViewChild('ModalSignatureShow') ModalSignatureShow: ElementRef;   
  @ViewChild('modalPrint') modalPrint: ElementRef;   
  
  dataListSusut: ListSusut[] = [];
  dataSignature: LocationSettingSignature[] = [];
  listCode: MasterCode[] = [];
  datatabdtOptionsnt: DataTableDirective;
  dtOptionsSusut: any = {};
  dtTriggerSusut = new Subject();  
  reff: string;
  idSusut: Guid;
  reasonCancel: string;
  totalSusut: number;
  tolerance: number;
  public dt: any;
  public cb: any;

  authAcces: UserAuth[] =[];
  authAccessPlb: UserAuthNew[] =[];
  btnPrint: Boolean = true;
  btnCancel: Boolean = true;
  btnClaim: Boolean = true;


  @Input() exportModel = { from: null, to: null, location: null};
  @Input() susutModel = {  id: null, transaction_id: null, driver_id: null, detail_do_no: null, unitPrice: null, companyPaid: null,
    totalSusut: null, bayarTrip: null, potonganTripText: null, debDate: null, totalSusutQTY: null}
  @Input() printModel = {  userId: null, susut_id: null, id: null, diketahui: null, diketahui2: null, diketahui3: null, 
    diketahui4: null, disetujui: null, hide_diketahui2: false,hide_diketahui3: false,hide_diketahui4: false, location: null}
   
  constructor( public service: TransportService, private router: Router, private spinner: NgxSpinnerService, fb: FormBuilder, private changeDetectorRef: ChangeDetectorRef) {
  }

    ngOnInit(): void {
      this.userAcces();
      this.dtOptionsSusut = {  
        retrieve: true,
        pageLength: 10,
        ordering : false,
        scrollX: true,
        scrollCollapse: true,
        serverSide: true,
        processing: true,
        searching : false,
        ajax: (dataTablesParameters: any, callback) => {
         this.dt = dataTablesParameters; this.cb = callback;
         this.getSusutList(dataTablesParameters, callback, $('#reff').val().toString(),  $('#driver_name').val().toString(), $('#cancel').val().toString(),  $('#print').val().toString(), true);
        },
        columnDefs: [
          { width: 150, targets: [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15]}
        ],
      };

      const that = this;

      $('.searchInput').on("keyup", function(e) {
            
        var key = e.which;
        if (key == 13) // the enter key ascii code
        {
          that.getSusutList(that.dt, that.cb, $('#reff').val().toString(),  $('#driver_name').val().toString(), $('#cancel').val().toString(),  $('#print').val().toString(),  false);
        }
      });

      this.getSignature();
    }   
    
    ngAfterViewInit(): void {
      this.dtTriggerSusut.next(0);
    }
  
    ngOnDestroy(): void {
     this.dtTriggerSusut.unsubscribe();
    }

    getSusutList(dataTablesParameters, callback, reff: string, driver_name: string, print: string, cancel: string, padging: Boolean){
      var page = 1;
        if (padging == true){
          page = parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length) + 1;
        }

        this.service.getSusutList(dataTablesParameters, page, dataTablesParameters.length, reff, driver_name, cancel, print)
        .subscribe(resp => { 
            this.dataListSusut = resp.data;
            callback({
              recordsTotal: resp.totalCount,
              recordsFiltered: resp.totalCount,
              data:  []
            });
        });
   }

   getSignature(){
    this.service.getSignature().subscribe(resp => { 
            this.dataSignature = resp;
            this.printModel.disetujui = resp[1].name;
            this.printModel.diketahui = resp[2].name;
            this.printModel.diketahui2 = resp[4].name;
            this.printModel.diketahui3 = resp[5].name;
            this.printModel.diketahui4 = resp[3].name;
        });
   }

   onSelectPrint(data: ListSusut){
    if(data.acknowledge != true){
      return  Swal.fire({
        title: "Data Susut Belum Diakui!",
        type: 'warning',
        confirmButtonText: '      Ok!     '
      });
    }
    else{
      this.printModel.susut_id = data.id;
      this.printModel.id = data.transaction_id;
      this.ModalSignatureShow.nativeElement.click();
    }
   }

   Print(){
    this.printModel.userId = localStorage.getItem('userid');
    if( this.printModel.diketahui == null || this.printModel.diketahui2 == null || this.printModel.diketahui3 == null
      || this.printModel.diketahui4 == null || this.printModel.disetujui == null){
      
      return  Swal.fire({
        title: "Data Belum Lengkap!",
        type: 'warning',
        confirmButtonText: '      Ok!     '
      });
    }
    else{
      this.spinner.show();
      let currentDate = new Date().toLocaleDateString();;
      let name = "ReportSusut_"+currentDate;
      this.printModel.location = localStorage.getItem('locationname');
      this.service.printSusut(this.printModel).subscribe(resp =>{
        this.spinner.hide();
      let blob:Blob=resp.body as Blob;
        let url = window.URL.createObjectURL(blob);
        window.open(url);
        this.getSusutList(this.dt, this.cb, $('#reff').val().toString(),  $('#driver_name').val().toString(), $('#cancel').val().toString(),  $('#print').val().toString(), true);
      })      
    }
   }

   claimSusut(data: ListSusut){
    Swal.fire({
      title: 'Rollback Cancel?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '      Yes     '
    }).then((result) => {
      if (result.value) {
           this.spinner.show();
            this.service.doRollBackCancel(data.id).subscribe(resp => { 
              this.spinner.hide();
            if(resp.succes == false){
              Swal.fire({
                title: ""+resp.text,
                type: 'warning',
                confirmButtonText: '      Ok!     '
              });
            }
            else{
              Swal.fire({
                title: ""+resp.text,
                type: 'success',
                confirmButtonText: '      Ok!     '
              }).then((result) => {
                if (result.value) { 
                  //
                }              
              }, (error) => {
                ; 
              })
            }
            });
      }
    });
   }


   onSelectSusut(data: ListSusut){
    this.reff = data.wbreff;
    this.totalSusut = data.tonnage_send - data.tonnage_receive;
    this.service.getTolerance(data.material_id).subscribe( resp => {
      this.tolerance = resp.tolerance;
    })
    this.susutModel.id = data.id;
    this.susutModel.transaction_id = data.transaction_id;
    this.susutModel.driver_id = data.driver_id;
    this.susutModel.detail_do_no = data.detail_do_no;
    this.susutModel.totalSusut = data.total_shrinkage_qty;
    this.susutModel.id = data.id;
    var today = moment().format('YYYY-MM-DD');
    this.susutModel.debDate = today;
   }

   countTotal(){
    this.susutModel.totalSusut = this.totalSusut * this.susutModel.unitPrice;
   }

   onSelectCancel(data: ListSusut){
    this.idSusut = data.id;
   }


   cancelSusut(){
    Swal.fire({
      title: 'Simpan Data?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '      Yes     '
    }).then((result) => {
      if (result.value) {
           this.spinner.show();
            this.service.cancelSusut(this.idSusut,this.reasonCancel).subscribe(resp => { 
              this.spinner.hide();
            if(resp.succes == false){
              Swal.fire({
                title: ""+resp.text,
                type: 'warning',
                confirmButtonText: '      Ok!     '
              });
            }
            else{
              Swal.fire({
                title: ""+resp.text,
                type: 'success',
                confirmButtonText: '      Ok!     '
              }).then((result) => {
                if (result.value) { 
                  this.getSusutList(this.dt, this.cb, "",  "", "", "",false);
                  this.ModalCancelClose.nativeElement.click();
                }              
              }, (error) => {
                ; 
              })
            }
            });
      }
    });
   }

   addDataSusut(){
    this.susutModel.totalSusutQTY = this.totalSusut;
    if(
      this.susutModel.unitPrice == null || this.susutModel.companyPaid == null || this.susutModel.bayarTrip == null ||
       this.susutModel.debDate == null
    )
    {
      Swal.fire({
        title: "Data Belum Lengkap !",
        type: 'warning',
        confirmButtonText: '      Ok!     '
      });
      return;
    }
    if(this.susutModel.totalSusut != (this.susutModel.companyPaid + this.susutModel.bayarTrip)){
      return Swal.fire({
        title: "Total Tanggungan Perusahaan dan Bayar Supir Tidak Sama Dengan Total Harga Susut!",
        type: 'warning',
        confirmButtonText: '      Ok!     '
      });
    }
    Swal.fire({
      title: 'Simpan Data?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '      Yes     '
    }).then((result) => {
      if (result.value) {
           this.spinner.show();
            this.service.addClaimSusut(this.susutModel).subscribe(resp => { 
              this.spinner.hide();
            if(resp.succes == false){
              Swal.fire({
                title: ""+resp.text,
                type: 'warning',
                confirmButtonText: '      Ok!     '
              });
            }
            else{
              this.getSusutList(this.dt, this.cb, $('#reff').val().toString(),  $('#driver_name').val().toString(), $('#cancel').val().toString(),  $('#print').val().toString(), true);
              this.ModalSimpanHide.nativeElement.click();
              Swal.fire({
                title: ""+resp.text,
                type: 'success',
                confirmButtonText: '      Ok!     '
              }).then((result) => {
                if (result.value) { 
                  //
                }              
              }, (error) => {
                ; 
              })
            }
            });
      }
    });
   }

  
  messageWarning(text: string){
    Swal.fire({
      title: ""+text,
      type: 'warning',
      confirmButtonText: '      Ok!     '
    });
    return;
  }


  userAcces(){
    const menuUser = localStorage.getItem("menu");
    if (menuUser != "All"){
      this.btnCancel = false;
      this.btnClaim = false;
      this.btnPrint = false;

      const locationName = localStorage.getItem('locationname');
      if(locationName != "Palembang"){
        const subMenu = localStorage.getItem("acces_menu");
        let arrayMenu =  subMenu.split(",");
        if (arrayMenu.includes("claimsusut") == false){
          return this.router.navigate(["/home"]);
        }
        this.authAcces = JSON.parse(localStorage.getItem("authAccess"));
        this.authAcces.filter(item => item.menu_name == "DebtAcknowledgeForm").forEach(i => {
            if(i.name == "AKUI_SUSUT"){
              this.btnClaim = true;
            }
            if(i.name == "CANCEL_SUSUT" || i.name == "DELETE"){
              this.btnCancel = true;
            }
            if(i.name == "PRINT"){
              this.btnPrint = true;
            }
        });
      }
      else{
        this.authAccessPlb = JSON.parse(localStorage.getItem("authAccessPlb"));
        var a = this.authAccessPlb.filter(item => item.subMenu  == "tripmanual").length;
        if (a == 0){
          return this.router.navigate(["/home"]);
        }
        this.authAccessPlb.filter(item => item.subMenu == "claimsusut").forEach(i => {
            if(i.auth == "AKUI_SUSUT"){
              this.btnClaim = true;
            }
            if(i.auth == "CANCEL_SUSUT"){
              this.btnCancel = true;
            }
            if(i.auth == "PRINT"){
              this.btnPrint = true;
            }
        });
      }
  
    }
  }

  selectActive(){
    this.getSusutList(this.dt, this.cb, $('#reff').val().toString(),  $('#driver_name').val().toString(), $('#cancel').val().toString(),  $('#print').val().toString(), true);
  }

  countTotalDriverDebt(){
    this.susutModel.bayarTrip = this.susutModel.totalSusut - this.susutModel.companyPaid;
   }

   exportDataSusut(){
    if (this.exportModel.from != null && this.exportModel.to != null){
    this.spinner.show();
      this.exportModel.location = localStorage.getItem('locationname');
      this.service.exportDataSusut(this.exportModel).subscribe(resp =>{
        this.spinner.hide();
        const blob = new Blob([resp.body],
          { type: 'application/vnd.ms-excel' });
      const file = new File([blob], 'Data_Susut.xlsx',
          { type: 'application/vnd.ms-excel' });
  
        saveAs(file);
        this.modalPrint.nativeElement.click();
      })
    }
    else{
      
      Swal.fire({
        title: "Data Belum Lengkap!",
        type: 'warning',
        confirmButtonText: '      Ok!     '
      });
    }
  }
}




