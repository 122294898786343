import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {AuthenticatedResponse, loginModel} from '@/model/loginModel';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import { TransportService } from './api.service';
import { environment } from 'environments/environment.prod';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
    providedIn: 'root'
})
export class AppService {
    public user: any = null;
    invalidLogin: boolean;
    credentials: loginModel = {username:'', password:''};
    private apiUrl = `${environment.apiUrl}/api`;

    constructor(public service: TransportService, private router: Router, private toastr: ToastrService, private http: HttpClient) {}

    doLogin(model: loginModel){
        const url = `${this.apiUrl}/auth/login`;
        return this.http.post<AuthenticatedResponse>(url, model, {
                    headers: new HttpHeaders({ "Content-Type": "application/json"})
                });
      }

    
      public exportAsExcelFile(json: any[], excelFileName: string): void {  const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);  const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };  const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });  this.saveAsExcelFile(excelBuffer, excelFileName);
}private saveAsExcelFile(buffer: any, fileName: string): void {
   const data: Blob = new Blob([buffer], {type: EXCEL_TYPE});   FileSaver.saveAs(data, fileName + '_' + new Date().toLocaleDateString() + EXCEL_EXTENSION);
}


    //async loginByAuth({email, password}) {
    // loginByAuth({email, password}) {
    //     try {
    //         // const token = await Gatekeeper.loginByAuth(email, password);
    //         // localStorage.setItem('token', token);
    //         this.credentials.username = email;
    //         this.credentials.password = password;
    //         this.doLogin(this.credentials)
    //         .subscribe({
    //             next: (response: AuthenticatedResponse) => {
    //             const token = response.token;
    //             localStorage.setItem("token", token); 
    //             console.log("Login : "+token);
    //             this.invalidLogin = false; 
    //             this.router.navigate(["/"]);
    //             },
    //             error: (err: HttpErrorResponse) => this.invalidLogin = true
    //         })
    //         this.getProfile();
    //         //await this.getProfile();
    //         this.router.navigate(['/']);
    //     } catch (error) {
    //         this.toastr.error(error.message);
    //     }
    // }

    async getProfile() {
        try {
            // this.user = await Gatekeeper.getProfile();
            this.user = 'Admin';
        } catch (error) {
            this.logout();
            throw error;
        }
    }

    logout() {
        localStorage.removeItem('token');
        this.user = null;
        this.router.navigate(['/login-page']);
    }
}
