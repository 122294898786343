
<!-- Main content -->
<section class="content">    
    <div class="card">
      <div class="card-header">
      <strong>List Rute</strong>
      <div class="float-right">
        <a type="button" class="btn btn-sm btn-warning pull-right" data-toggle="modal" (click)="exportAsXLSX()">&nbsp;&nbsp;&nbsp;<b>Export Data</b>&nbsp;&nbsp;&nbsp;&nbsp;<i class="fas fa-file-excel"></i>&nbsp;&nbsp;&nbsp;</a>&nbsp;&nbsp;&nbsp;&nbsp;
        <a type="button" [hidden]="!btnCrud"  class="btn btn-sm btn-primary pull-right" data-toggle="modal" (click)="ModalRoute.show();clearFormRoute()"><b>&nbsp;&nbsp;&nbsp;Input Rute&nbsp;&nbsp;&nbsp;&nbsp;</b><i class="fa fa-pen"></i>&nbsp;&nbsp;&nbsp;</a>
      </div>
    </div>
      <div class="card-body">
    <table datatable  [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"  class="table-striped">
      <thead>
        <tr>
          <th>Action</th>
          <th>Rute</th>
          <th>Lokasi Muat</th>
          <th>Lokasi Bongkar</th>
          <th>Tipe Rute</th>
          <th>Diubah Oleh</th>
          <th>Dibuat Pada</th>
          <th>Diubah Oleh</th>
          <th>Diubah Pada</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let row of dataRoute">    
          <td>
            <button [hidden]="!btnCrud" title="Hapus" class="btn btn-sm btn-danger" triggers="mouseenter:mouseleave" (click)="deleteRoute(row);"><i class="fa fa-trash"></i></button>&nbsp;
            <button [hidden]="!btnCrud" title="Edit" (click)="onSelectRoute(row);ModalRoute.show()" class="btn btn-sm btn-primary"  data-toggle="modal"triggers="mouseenter:mouseleave"><i class="fa fa-pen"></i></button>
          </td> 
          <td>{{row.route_name}}</td>
          <td>{{row.loadtext}}</td>
          <td>{{row.unloadtext}}</td>
          <td>{{row.route_type}}</td>
          <td>{{row.createdby}}</td>
          <td>{{row.created_at | slice:0:10}}</td>  
          <td>{{row.updatedby}}</td>  
          <td>{{row.updated_at | slice:0:10}}</td>        
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <th><input hidden class="searchInput"/></th>
          <th><input id="namesearch" type="text" class="searchInput" placeholder="Cari Nama" /></th>
          <th><input id="loadsearch" type="text" class="searchInput" placeholder="Cari Nama" /></th>
          <th><input id="unloadsearch" type="text" class="searchInput" placeholder="Cari Nama" /></th>
          <th><input id="typesearch" type="text" class="searchInput" placeholder="Cari Type" /></th>
          <th><input hidden class="searchInput"/></th>
          <th><input hidden class="searchInput"/></th>
          <th><input hidden class="searchInput"/></th>
          <th><input hidden class="searchInput"/></th>
        </tr>
      </tfoot>

    </table>
  </div>
  </div>
</section>


<div  bsModal #ModalRoute="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog  modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title"><strong>{{action}} Data Port</strong></div>
          <small><code style="color: black">&nbsp;&nbsp;*(semua kolom wajib di isi)</code></small>
          <button type="button" class="close" #modalSaveClose (click)="ModalRoute.hide()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div [formGroup]="routeForm">
            <div class="form-group row">
              <label class="col-md-3 col-form-label" for="text-input">Nama Port</label>
              <div class="col-md-9">
                <input [(ngModel)]="routeModel.route_name" type="text" class="form-control" formControlName="route_name">
                <span class="text-danger" *ngIf="routeForm.controls['route_name'].hasError('required') && 
                (routeForm.controls['route_name'].dirty || routeForm.controls['route_name'].touched)">Kolom Tidak Boleh Kosong!</span>
            </div>
            </div> 
            <div class="form-group row">
              <label class="col-md-3 col-form-label" for="text-input">Tipe Rute</label>
              <div class="col-md-9">                   
                <select [(ngModel)]="routeModel.route_type" id="select" name="select" class="form-control" formControlName="route_type">
                    <option value="In">In</option>
                    <option value="Out">Out</option>
                </select>
                <span class="text-danger" *ngIf="routeForm.controls['route_type'].hasError('required') && 
                (routeForm.controls['route_type'].dirty || routeForm.controls['route_type'].touched)">Kolom Tidak Boleh Kosong!</span>
            </div>
            </div> 
              <div class="form-group row">
                <label class="col-md-3 col-form-label" for="text-input">Lokasi Muat</label>
                <input hidden [(ngModel)]="routeModel.load_port" disabled type="text" class="form-control" formControlName="other">
                <div class="col-md-9">
                    <input [(ngModel)]="muatSelect" disabled type="text" class="form-control col-md-9" formControlName="load_port">
                    <button style="position:absolute; right: 0; right: 75px; top: 4px;" class="btn btn-sm btn-primary" 
                    data-toggle="modal" (click)="loadDataMuat();ModalPort.show()"><i class="fa fa-plus"></i></button>
                    <span class="text-danger" *ngIf="routeForm.controls['load_port'].hasError('required') && 
                    (routeForm.controls['load_port'].dirty || routeForm.controls['load_port'].touched)">Kolom Tidak Boleh Kosong!</span>
                </div>
              </div> 
              <div class="form-group row">
                <label class="col-md-3 col-form-label" for="text-input">Lokasi Bongkar</label>
                <input hidden [(ngModel)]="routeModel.unload_port" disabled type="text" class="form-control" formControlName="other2">
                <div class="col-md-9">
                    <input [(ngModel)]="bongkarSelect" disabled type="text" class="form-control col-md-9" formControlName="unload_port">
                    <button style="position:absolute; right: 0; right: 75px; top: 4px;" class="btn btn-sm btn-primary" 
                    data-toggle="modal" (click)="loadDataBongkar();ModalPort.show()"><i class="fa fa-plus"></i></button>
                    <span class="text-danger col-md-9" *ngIf="routeForm.controls['unload_port'].hasError('required') && 
                    (routeForm.controls['unload_port'].dirty || routeForm.controls['unload_port'].touched)">Kolom Tidak Boleh Kosong!</span>
              </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" (click)="ModalRoute.hide()"><b>Close</b></button>
            <button type="button" class="btn btn-primary"  (click)="saveDataRoute();"><i class="fa fa-save"></i>&nbsp;&nbsp;<b>Save</b>&nbsp;&nbsp;</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div  bsModal #ModalPort="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog  modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title"><strong>List Data Port</strong></div>
          <button type="button" class="close" #modalSaveClose (click)="ModalPort.hide()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <table datatable  [dtOptions]="dtOptions2" [dtTrigger]="dtTrigger"  class="table-striped">
                <thead>
                  <tr>
                    <th>Nama Port</th>
                    <th>Biaya Bongkar</th>
                    <th>Dibuat Oleh</th>
                    <th>Dibuat Pada</th>
                    <th>Diubah Oleh</th>
                    <th>Diubah Pada</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of componentPort.dataPort">
                    <td>{{row.port_name}}</td>
                    <td>{{row.biaya_bongkar}}</td>
                    <td>{{row.createdby}}</td>
                    <td>{{row.created_at | slice:0:10}}</td>  
                    <td>{{row.updatedby}}</td>  
                    <td>{{row.updated_at | slice:0:10}}</td>    
                    <td>
                        <button (click)="onSelectPort(row);ModalPort.hide()" class="btn btn-sm btn-primary"  data-toggle="modal" triggers="mouseenter:mouseleave"><i class="fa fa-check"></i></button>
                    </td> 
                </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <th><input id="portname" type="text" class="searchInputV" placeholder="Cari Nama" name="search-name"/></th>
                    <th><input hidden class="searchInputV"/></th>
                    <th><input hidden class="searchInputV"/></th>
                    <th><input hidden class="searchInputV"/></th>
                    <th><input hidden class="searchInputV"/></th>
                    <th><input hidden class="searchInputV"/></th>
                    <th><input hidden class="searchInputV"/></th>
                  </tr>
                </tfoot>
              </table>
        </div>
      </div>
    </div>
  </div>

  <ngx-spinner  bdColor = "rgba(248,248,248,0.95)" size = "large" color = "#262424" type = "ball-spin-fade" >
    <p style="font-size: 20px; color: white"></p>
  </ngx-spinner>


  