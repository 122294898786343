import { AfterViewInit, Component, OnInit, ViewChild,Input, ChangeDetectorRef, ElementRef  } from '@angular/core';
import { DataTableDirective } from 'angular-datatables'
import { TransportService} from '../../../services/api.service';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Guid } from 'guid-typescript';
import { ListPayment } from '@/model/listPayment';
import { ListDebtHead } from '@/model/listDebtHead';
import { ListDriverDebt } from '@/model/listDriverDebt';
import { ListTripRecord } from '@/model/listTripRecord';
import { Driver } from '@/model/driver';
import { ListTruck } from '@/model/listTruck';
import { Truck } from '@/model/truck';
import { DebtPriority } from '@/model/debtPriority';
import { ListTrxDebt } from '@/model/listTrxDebt';
import { DriverDebtDetail } from '@/model/driverDebtDetail';
import saveAs from 'file-saver';
import { FinalResultDto } from '@/model/reportDto';
import { LocationSettingSignature } from '@/model/locationSettingSignature';
import moment from 'moment';
import { UserAuth } from '@/model/userAuth';
import { AuthMenu } from '@/model/masterSubMenu';
import { UserAuthNew } from '@/model/respDto';
@Component({
  selector: 'app-driver-debt',
  templateUrl: './driver-debt.component.html',
  styleUrls: ['./driver-debt.component.scss']
})
export class DriverDebtComponent  implements OnInit, AfterViewInit {
  @ViewChild('modalDetailShow') modalDetailShow: ElementRef;
  @ViewChild('ModalSimpanHide') ModalSimpanHide: ElementRef;
  @ViewChild('ModalReason') ModalReason: ElementRef;  
  @ViewChild('modalDetailSPSShow') modalDetailSPSShow: ElementRef;  
  @ViewChild('ModalSignatureShow') ModalSignatureShow: ElementRef; 
  @ViewChild('ModalRemarksClose') ModalRemarksClose: ElementRef;
  @ViewChild('ModalSimpanShow') ModalSimpanShow: ElementRef;          
  bol = true;
  datatabdtOptionsnt: DataTableDirective;
  dtOptionsDebtHead: any = {};
  dtOptionsDebtDetail: any = {};
  dtOptionsDriver: any = {};
  dtOptionsTruck: any = {};
  dtOptionsDetailSPS: any = {};
  dtTriggerDetailSPS = new Subject();
  dtTriggerDebtDetail = new Subject();
  dtTriggerDebtHead = new Subject();
  dtTriggerDriver = new Subject();
  dtTriggerTruck = new Subject();
  dataListDebtHead: ListDebtHead[] = [];
  dataListDebtDetail: ListDriverDebt[] = [];
  dataListTypeDebt: DebtPriority[] = [];
  dataDriver: Driver[] = [];
  dataTruck: ListTruck[] = [];
  detailDebt: DriverDebtDetail[] = [];
  action: string;
  driverId: Guid;
  driverName: string;
  public dtDebtHead: any;
  public cbDebtHead: any;
  public dtDriver: any;
  public cbDriver: any;
  public dtDebtDetail: any;
  public cbDebtDetail: any;
  public dtTruck: any;
  public cbTruck: any;
  public dtDetailSPS: any;
  public cbDetailSPS: any;
  public dtDetail: any;
  public cbDetail: any;
  public dataTripDto: any;
  total: number;
  reason: string;
  invoiceNo : string;
  public edit: boolean = false;
  public detail: boolean = true;
  authAcces: UserAuth[] =[];
  authAccessPlb: UserAuthNew[] =[];
  btnInsert: Boolean = true;
  dataSignature: LocationSettingSignature[] = [];
  @Input() debtModel = {  driver_id: null, truck_id: null, debt_type: null, debt_total: null, cash_payment: null, 
    trip_payment: null, trip_payment_text: null, company_paid: null, sps_date: null, ba_no: null, perihal: null, 
    sps_reason: null, driver_name: null, truck_plate: null, 
   }
   @Input() deleteDebtModel = {  debt_id: null, driver_id: null, reason: null}
   @Input() printModel = {  id: null,subscription_id: null, diketahui: null, diketahui2: null, diketahui3: null, diketahui4: null, disetujui: null,
    hide_diketahui2: false,hide_diketahui3: false,hide_diketahui4: false, location: null}
   @Input() closeModel = {  debt_id: null, remarks: null}
   @Input() cancelModel = {  debt_id: null, driver_id: null}
   @Input() reportModel = {  driver_id: null, from: null, to: null, paymentFrom: null, paymentTo: null, debt_type: null, sps_no: null, lunas: null, location: null}
   @Input() reporInstallmentModel = {  debt_id: null, reff: null, location: null}
  constructor( public service: TransportService, private router: Router, private spinner: NgxSpinnerService, fb: FormBuilder, private changeDetectorRef: ChangeDetectorRef) {
  }

    ngOnInit(): void {
      this.listType();
      this.dtOptionsDebtHead = {  
        //retrieve: true,
        pageLength: 10,
        ordering : false,
        // scrollX: true,
        // scrollCollapse: true,
        serverSide: true,
        processing: true,
        searching : false,
        ajax: (dataTablesParameters: any, callback) => {
         this.dtDebtHead = dataTablesParameters; this.cbDebtHead = callback;
         this.getListDebtHead(dataTablesParameters, callback,  "",  true);
        },
        columnDefs: [
          { width: 100, targets: [0,2]},
          { width: 300, targets: 1},
        ],
      };

      this.dtOptionsDebtDetail = {  
        bInfo : false,
        paging: false,
        retrieve: true,
        pageLength: 10,
        ordering : false,
        searching : false,
        columnDefs: [
          { width: 100, targets: [0,1,2,3,4,5,6,7,8,9]}
        ],
      };

      this.dtOptionsDetailSPS = {  
        retrieve: true,
        pageLength: 10,
        ordering : false,
        searching : false,
        columnDefs: [
          { width: 200, targets: [0,1,2,3,4,5,6,7]}
        ],
      };

      this.dtOptionsDriver = {
        retrieve: true,
        pageLength: 5,
        scrollX: true,
        scrollCollapse: true,
        serverSide: true,
        processing: true,
        searching : false,
        ajax: (dataTablesParameters: any, callback, dataDto: ListTripRecord) => {
         this.dataTripDto = dataDto
         this.dtDriver = dataTablesParameters; this.cbDriver = callback;
         this.getDataDriver(dataTablesParameters, callback, "", "","","","", "","","","", "","","", "",  true);
         },
        columnDefs: [
          { width: 150, targets: [0,2,3,4,5,6,7,8,9,10,12,13,14,15] },
          { width: 200, targets: [1] },
          { width: 300, targets: [11] },
        ],
      };

      this.dtOptionsTruck = {
        retrieve: true,
        pageLength: 5,
        scrollX: true,
        scrollCollapse: true,
        serverSide: true,
        processing: true,
        searching : false,
        ajax: (dataTablesParameters: any, callback) => {
        this.dtTruck = dataTablesParameters;
        this.cbTruck = callback;
         this.getDataTruck(dataTablesParameters, callback, "", "","",true);
        },
        columnDefs: [
          { width: 100, targets: 0 },
          { width: 150, targets: 1 },
          { width: 150, targets: 2 },
        ],
        
      };

      const that = this;
      $('.searchDetail').on("keyup", function(e) {
        var key = e.which;
        if (key == 13) // the enter key ascii code
        {
          that.getListDebtDriver(that.driverId, $('#trx_reff').val().toString(), "" , false);
        }
      });
      $('.searchInput').on("keyup", function(e) {
            
        var key = e.which;
        if (key == 13) // the enter key ascii code
        {
          that.getListDebtHead(that.dtDebtHead, that.cbDebtHead, $('#name').val().toString(), false);
        }
      });
      $('.searchInputTrucks').on("keyup", function(e) {
        var key = e.which;
        if (key == 13) // the enter key ascii code
        {
          that.getDataTruck(that.dtTruck, that.cbTruck, $('#platsearch').val().toString(), $('#codesearch').val().toString(),
           $('#locationsearch').val().toString(),false);
        }
      });  

      
       $('.searchInputD').on("keyup", function(e) {
        var key = e.which;
        if (key == 13) // the enter key ascii code
        {
          that.getDataDriver(that.dtDriver, that.cbDriver, $('#driver_name').val().toString(), $('#driver_license').val().toString(), 
          $('#driver_license_type').val().toString(),$('#agama').val().toString(),$('#birthplace').val().toString(),
          $('#ktp_no').val().toString(),$('#tanggal_lahir').val().toString(),$('#sl_code').val().toString(),
          $('#sl_code2').val().toString(),$('#sl_code3').val().toString(),$('#address').val().toString(),
          $('#valid_to').val().toString(),$('#valid_to_contract').val().toString(), false);
        }
      });

      this.getSignature();
      
    }   
    
    ngAfterViewInit(): void {
      this.dtTriggerDebtHead.next(0);
    }
  
    ngOnDestroy(): void {
     this.dtTriggerDebtHead.unsubscribe();
    }

    getListDebtHead(dataTablesParameters, callback, name: string, padging: Boolean){
    
      var page = 1;
        if (padging == true){
          page = parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length) + 1;
        }

        this.service.getListDebtHead(dataTablesParameters, page, dataTablesParameters.length, name)
        .subscribe(resp => { 
            this.dataListDebtHead = resp.data;
            callback({
              recordsTotal: resp.totalCount,
              recordsFiltered: resp.totalCount,
              data:  []
            });
        });
   }

   addReason(data: ListDriverDebt){
    this.cancelModel.debt_id = data.id;
    this.cancelModel.driver_id = data.driver_id;
   }

   deleteDebt(){
    if (this.reason == null || this.reason == ""){
      return Swal.fire({
        title: "Mohon isi kolom Alasan!",
        type: 'warning',
        confirmButtonText: '      Ok!     '
      });
    }
    Swal.fire({
      title: 'Delete Data?',
      // text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '      Yes     '
    }).then((result) => {
        if (result.value) {
          this.spinner.show();
            this.deleteDebtModel.debt_id = this.cancelModel.debt_id;
            this.deleteDebtModel.driver_id = this.cancelModel.driver_id;
            this.deleteDebtModel.reason = this.reason;
            this.service.deleteDebt(this.deleteDebtModel)
             .subscribe(resp => { 
              this.spinner.hide();
              this.getListDebtDriver(this.deleteDebtModel.driver_id, "", "" , false);
            if(resp.succes == false){
              Swal.fire({
                title: ""+resp.text,
                type: 'warning',
                confirmButtonText: '      Ok!     '
              });
            }
            else{
              Swal.fire({
                title: ""+resp.text,
                type: 'success',
                confirmButtonText: '      Ok!     '
              }).then((result) => {
                if (result.value) { 
                  //
                }              
              }, (error) => {
                ; 
              })
            }
            
            this.ModalReason.nativeElement.click();
            });
        }        
    
    }, (error) => {
      //; 
    });
   }

   onSelectSPS(data: ListDriverDebt){
    this.reporInstallmentModel.debt_id = data.id;
    this.reporInstallmentModel.reff = data.trx_reff;
    this.getDetailDriverDebt(data.id);
    this.spinner.show();
    setTimeout(() => {
      this.spinner.hide();
    }, 500);
    this.dtTriggerDetailSPS.next(0);
    this.modalDetailSPSShow.nativeElement.click();
   }

   onSelectDebt(data: ListDebtHead){
    this.getListDebtDriver(data.id, "", "" , false);
    this.driverId = data.id;
    this.driverName = data.driver_name;
    
    this.spinner.show();
    setTimeout(() => {
      this.spinner.hide();
    }, 500);
    this.dtTriggerDebtDetail.next(0);
    this.modalDetailShow.nativeElement.click();
   }

   onSelectTruck(data: Truck){
    this.debtModel.truck_id = data.truck_id;
    this.debtModel.truck_plate = data.truck_plate_number;
    
  } 

  onSelectDriver(data: Driver){
    this.debtModel.driver_id = data.driver_id;
    this.debtModel.driver_name = data.driver_name;
    this.driverId = data.driver_id;
  } 

  showTruck(){
    this.dtTriggerTruck.next(0);
  }

  showDriver(){
    this.dtTriggerDriver.next(0);
  }

  listType(){
    this.service.getTypeDebt()
      .subscribe(resp => { 
          this.dataListTypeDebt = resp;
      });
  }

  getDataTruck(dataTablesParameters, callback, platNumber: string, codeCapacity: string, location: string, padging : boolean){ 
    var page = 1;
    if (padging == true){
      page = parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length) + 1;
    }
    this.service.getListTruck(dataTablesParameters, page, dataTablesParameters.length, platNumber, codeCapacity, location)
    .subscribe(resp => { 
        this.dataTruck = resp.data;
        callback({
          recordsTotal: resp.totalCount,
          recordsFiltered: resp.totalCount,
           data:  []
        });
    });
  }

   getDataDriver(dataTablesParameters, callback,  driver_name: string, driver_license: string, driver_license_type: string, 
    agama: string, birthplace: string, ktp_no: string, tanggal_lahir: string, sl_code: string, sl_code2: string,
    sl_code3: string, address: string, valid_to: string, valid_to_contract: string, padging : boolean){ 
    var page = 1;
    if (padging == true){
      page = parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length) + 1;
    }
    this.service.getListDriver(dataTablesParameters, page, dataTablesParameters.length, driver_name, driver_license, driver_license_type, agama,
      birthplace,ktp_no,tanggal_lahir, sl_code, sl_code2, sl_code3, address, valid_to, valid_to_contract, "true", "false")
    .subscribe(resp => { 
        this.dataDriver = resp.data;
        callback({
          recordsTotal: resp.totalCount,
          recordsFiltered: resp.totalCount,
          data:  []
        });
    });
  }

   getDetailDriverDebt(driverdebt_id: Guid){
    this.service.getDebtTrxDetail(driverdebt_id).subscribe(resp => {
      this.detailDebt = resp;
    })
   }

   getListDebtDriver( driver_id: Guid, reff: string, contract_no: string, padging: Boolean){
    var page = 1;
    var perPage = 10;
      // if (padging == true){
      //   page = parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length) + 1;
      // }

      this.service.getListDebtDriver(null, page, perPage, driver_id, reff, contract_no)
      .subscribe(resp => { 
          this.dataListDebtDetail = resp;
          // callback({
          //   recordsTotal: resp.totalCount,
          //   recordsFiltered: resp.totalCount,
          //   data:  []
          // });
      });
   }

   addDataDriverDebt(){
    if(this.debtModel.driver_name == null || this.debtModel.debt_type == null || this.debtModel.truck_plate == null ||
      this.debtModel.debt_total == null || this.debtModel.company_paid == null || this.debtModel.sps_date == null ||
      this.debtModel.cash_payment == null || this.debtModel.trip_payment == null || this.debtModel.ba_no == null )
    {
      Swal.fire({
        title: "Data Belum Lengkap !",
        type: 'warning',
        confirmButtonText: '      Ok!     '
      });
      return;
    }
    Swal.fire({
      title: 'Simpan Data?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '      Yes     '
    }).then((result) => {
      if (result.value) {
           this.spinner.show();
            this.service.addDataDebtDriver(this.debtModel).subscribe(resp => { 
              this.spinner.hide();
            if(resp.succes == false){
              Swal.fire({
                title: ""+resp.text,
                type: 'warning',
                confirmButtonText: '      Ok!     '
              });
            }
            else{
              this.ModalSimpanHide.nativeElement.click();
              this.printModel.id = resp.text2;
              this.ModalSignatureShow.nativeElement.click();
              Swal.fire({
                title: ""+resp.text,
                type: 'success',
                confirmButtonText: '      Ok!     '
              }).then((result) => {
                if (result.value) { 
                  //
                }              
              }, (error) => {
                ; 
              })
            }
            });
      }
    });
   }


  messageWarning(text: string){
    Swal.fire({
      title: ""+text,
      type: 'warning',
      confirmButtonText: '      Ok!     '
    });
    return;
  }

  exportDetailDebt(){
      if(this.detail == true){
        this.spinner.show();
        this.reportModel.driver_id = this.driverId;
          let name = "Report_Detail_Debt.xlsx";
          this.reportModel.location = localStorage.getItem('locationname');
        this.service.reportDebtDetail(this.reportModel).subscribe(resp =>{
          this.spinner.hide();
          const blob = new Blob([resp.body],
            { type: 'application/vnd.ms-excel' });
        const file = new File([blob], name,
            { type: 'application/vnd.ms-excel' });

          saveAs(file);
        })
      }
      else{
          this.spinner.show();
          this.reportModel.driver_id = this.driverId;
            let name = "Report_Detail_Debt.xlsx";
          this.reportModel.location = localStorage.getItem('locationname');
          this.service.reportDebtHead(this.reportModel).subscribe(resp =>{
            this.spinner.hide();
            const blob = new Blob([resp.body],
              { type: 'application/vnd.ms-excel' });
          const file = new File([blob], name,
              { type: 'application/vnd.ms-excel' });

            saveAs(file);
          })
      }
  }

  exportDetailDebtInstallment(){
  this.spinner.show();
    let name = "Report_Detail_Angsuran_Hutang"+this.reporInstallmentModel.reff+".xlsx";
    this.reporInstallmentModel.location = localStorage.getItem('locationname');
     this.service.reportDebtDetailInstallment(this.reporInstallmentModel).subscribe(resp =>{
    this.spinner.hide();
    const blob = new Blob([resp.body],
      { type: 'application/vnd.ms-excel' });
  const file = new File([blob], name,
      { type: 'application/vnd.ms-excel' });

    saveAs(file);
  })
  }

  exportDetailDebtById(){
  this.reportModel.from = null;
  this.reportModel.to = null; 
  this.reportModel.paymentFrom = null;
  this.reportModel.paymentTo = null; 
  this.spinner.show();
  this.reportModel.driver_id = this.driverId;
  let currentDate = new Date().toLocaleDateString();;
  let name = "Report_Detail_Debt_"+this.driverName+"_"+currentDate+".xlsx";
  this.reportModel.location = localStorage.getItem('locationname');
  this.service.reportDebtDetail(this.reportModel).subscribe(resp =>{
    this.spinner.hide();
    const blob = new Blob([resp.body],
      { type: 'application/vnd.ms-excel' });
  const file = new File([blob], name,
      { type: 'application/vnd.ms-excel' });

    saveAs(file);
  })
  }

  getSignature(){
    this.service.getSignature().subscribe(resp => { 
            this.dataSignature = resp;
            this.printModel.disetujui = resp[1].name;
            this.printModel.diketahui = resp[2].name;
            this.printModel.diketahui2 = resp[4].name;
            this.printModel.diketahui3 = resp[5].name;
            this.printModel.diketahui4 = resp[3].name;
        });
   }

   printDebt(data: ListDriverDebt){
    console.log(data.subcription_id)
    this.printModel.id = data.id;
    this.printModel.subscription_id = data.subcription_id;
    if (data.driver_name == null || data.driver_name == ""){
      return Swal.fire({
        title: "Mohon isi no Nama supir terlebih dahulu!",
        type: 'warning',
        confirmButtonText: '      Ok!     '
      });
    }
    if (data.driver_license == null || data.driver_license == ""){
      return Swal.fire({
        title: "Mohon isi no SIM supir terlebih dahulu!",
        type: 'warning',
        confirmButtonText: '      Ok!     '
      });
    }
    this.ModalSignatureShow.nativeElement.click();
  }

  print(){
    if( this.printModel.diketahui == null || this.printModel.diketahui2 == null || this.printModel.diketahui3 == null
      || this.printModel.diketahui4 == null || this.printModel.disetujui == null){
      
      return  Swal.fire({
        title: "Data Belum Lengkap!",
        type: 'warning',
        confirmButtonText: '      Ok!     '
      });
    }
    else{
      this.spinner.show();
      this.printModel.location = localStorage.getItem('locationname');
      this.service.printDriverDebt(this.printModel).subscribe(resp =>{
        this.spinner.hide();
      let blob:Blob=resp.body as Blob;
        let url = window.URL.createObjectURL(blob);
        window.open(url);
      })
    }
  }

  setDebtId(data: ListDriverDebt){
    this.closeModel.debt_id = data.id;
  }

  closeDriverDebt(){
    if (this.closeModel.remarks == null || this.closeModel.remarks == ""){
      Swal.fire({
        title: "Mohon isi kolom Alasan!",
        type: 'warning',
        confirmButtonText: '      Ok!     '
      });
    }
    else{
      Swal.fire({
      title: 'Simpan Data?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '      Yes     '
    }).then((result) => {
      if (result.value) {
        this.service.closeDriverDebt(this.closeModel).subscribe(resp =>{
          if(resp.succes == false){
            Swal.fire({
              title: ""+resp.text,
              type: 'warning',
              confirmButtonText: '      Ok!     '
            });
          }
          else{
            this.ModalRemarksClose.nativeElement.click();
            Swal.fire({
              title: ""+resp.text,
              type: 'success',
              confirmButtonText: '      Ok!     '
            }).then((result) => {
              if (result.value) { 
                this.getDetailDriverDebt(this.closeModel.debt_id);
              }              
            })
          }
        })
      }
    })

    }
  }

  onSelectEdit(data: ListDriverDebt){
    this.service.getListTrxDebtByDebt(data.id).subscribe( resp => {
      if(resp.id == null){
        Swal.fire({
          title: "Bukan Data Hutang Transaksi!",
          type: 'warning',
          confirmButtonText: '      Ok!     '
        });
      }
      else{
        this.debtModel.driver_name = resp.driver_name;
        this.debtModel.debt_type = resp.type;
        this.debtModel.debt_total = resp.amount;
        this.debtModel.company_paid = resp.company_paid;
        this.debtModel.cash_payment = resp.cash_payment;
        this.debtModel.trip_payment = resp.trip_payment;
        this.debtModel.trip_payment_text = resp.trip_payment_text;
        // this.debtModel.truck_plate = data.truck;
        this.debtModel.ba_no = resp.ba_no;
        this.debtModel.perihal = resp.perihal;
        this.debtModel.sps_reason = resp.sps_reason;
        this.debtModel.sps_date  = resp.sps_date;
        this.edit = true;
        this.ModalSimpanShow.nativeElement.click();
      }
    })
   }

   clear(){
    this.debtModel.driver_name = null;
        this.debtModel.debt_type = null;
        this.debtModel.debt_total = null;
        this.debtModel.company_paid = null;
        this.debtModel.cash_payment = null;
        this.debtModel.trip_payment = null;
        // this.debtModel.truck_plate = data.truck;
        this.debtModel.ba_no = null;
        this.debtModel.perihal = null;
        this.debtModel.sps_reason = null;
        var today = moment().format('YYYY-MM-DD');
        this.debtModel.sps_date = today;
        this.edit = false;
   }

   userAcces(){
    const locationName = localStorage.getItem("locationname");
    const menuUser = localStorage.getItem("menu");
    if (menuUser != "All"){

      if (locationName != "Palembang"){
        const subMenu = localStorage.getItem("acces_menu");
        let arrayMenu =  subMenu.split(",");
        if (arrayMenu.includes("driverdebt") == false){
         return this.router.navigate(["/home"]);
        }
        this.btnInsert = false;
        this.authAcces = JSON.parse(localStorage.getItem("authAccess"));
        this.authAcces.filter(item => item.menu_name == "DebtHeadForm").forEach(i => {
            if(i.name == "INSERT"){
              this.btnInsert = true;
            }
        });
      }
      else{
          this.authAccessPlb = JSON.parse(localStorage.getItem("authAccessPlb"));
          var a = this.authAccessPlb.filter(item => item.subMenu  == "driverdebt").length;
          if (a == 0){
            return this.router.navigate(["/home"]);
          }
          this.btnInsert = false;
          this.authAccessPlb.filter(item => item.subMenu == "driverdebt").forEach(i => {
              if(i.auth == "CRUD"){
                this.btnInsert = true;
              }
          });
      }

    }
  }


  showExport(){
    var today = moment().format('YYYY-MM-DD');
    // this.reportModel.from = today;
    // this.reportModel.to = today;
    this.reportModel.from = null;
    this.reportModel.to = null;
    this.reportModel.paymentFrom = null;
    this.reportModel.paymentTo = null;
    this.reportModel.lunas = true;
    this.detail = true;
    this.debtModel.driver_name = null;
    this.driverId = null;
  }

  
  sort(){
      if (this.bol == false){
        this.dataListDebtHead.sort((a, b) => {
          return  a.driver_name > b.driver_name ? 1 : a.driver_name < b.driver_name ? -1 : 0
         })
         this.bol = true;
      }
      else{
        this.dataListDebtHead.sort((b, a) => {
          return  a.driver_name > b.driver_name ? 1 : a.driver_name < b.driver_name ? -1 : 0
         })
         this.bol = false;
      }
  }
  
}




