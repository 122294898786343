<section class="content">    
    <div class="card">
        <div class="card-header">
          <strong>List User</strong>
          <div class="float-right">
          <a type="button" (click)="ModalSimpan.show();setNull();" class="btn btn-sm btn-primary pull-right" data-toggle="modal">&nbsp;<b>Tambah User</b>&nbsp;&nbsp;<i class="fa fa-pen"></i>&nbsp;</a>
          </div>
        </div>
        <div class="card-body">
            <table datatable [dtOptions]="dtOptionsAcc" [dtTrigger]="dtTriggerAcc" class="table-striped">
                <thead>
                  <tr>
                    <th>Action</th>
                    <th>Username</th>
                    <th>Role</th>
                    <th>Nama Depan</th>
                    <th>Nama Belakang</th>
                    <th>Sub Lokasi</th>
                    <th>Dibuat Oleh</th>
                    <th>Dibuat Pada</th>
                    <th>Diubah Oleh</th>
                    <th>Diubah Pada</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of dataListAcc">    
                    <td>
                      <button (click)="onSelectDataUser(row);ModalUpdate.show()" title="Detail" class="btn btn-sm btn-primary" triggers="mouseenter:mouseleave"><i class="fa fa-pen"></i></button>&nbsp;
                      <button (click)="resetPass(row);" title="Reset Password" class="btn btn-sm btn-primary" triggers="mouseenter:mouseleave"><i class="fa fa-key"></i></button>&nbsp;
                      <button (click)="deleteData(row)" class="btn btn-sm btn-danger" title="Delete" triggers="mouseenter:mouseleave"><i class="fa fa-trash"></i></button>&nbsp;
                    </td>       
                    <td>{{row.user_name}}</td>
                    <td>{{row.user_role_name}}</td>
                    <td>{{row.first_name}}</td>
                    <td>{{row.last_name}}</td>
                    <td>{{row.sub_location}}</td>
                    <td>{{row.createdBy}}</td>
                    <td>{{row.created_at | slice:0:10}}</td>
                    <td>{{row.updatedBy}}</td>
                    <td>{{row.updated_at | slice:0:10}}</td>
                </tr>
                </tbody>
              </table>
        </div>
    </div>
  </section>

  <div  bsModal #ModalSimpan="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog" style="max-width: 50%;" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title">
            <div class="row">
              <strong>List User</strong>
            </div>
          </div>
          <button type="button" class="close" (click)="ModalSimpan.hide()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-12"> 
                    <div class="form-group row">
                        <label class="col-md-3 col-form-label" for="text-input">Username</label>
                        <div class="col-md-8">
                            <input [(ngModel)]="userModel.user_name" type="text" class="form-control">
                        </div> 
                    </div>
                </div>
                <div class="col-md-12"> 
                    <div class="form-group row">
                        <label class="col-md-3 col-form-label" for="text-input">Password</label>
                        <div class="col-md-8">
                            <input [(ngModel)]="userModel.user_password" type="text" class="form-control">
                        </div> 
                    </div>
                </div>
                <div class="col-md-12"> 
                    <div class="form-group row group">
                        <label class="col-md-3 col-form-label" for="text-input">Role</label>
                        <div class="col-md-8">
                            <select [(ngModel)]="userModel.user_role" id="select" name="select" class="form-control">
                                <option selected *ngFor="let row of listRole" [value]='row.role_id'><b>{{row.role_name}}</b></option>
                            </select>
                        </div>
                    </div> 
                </div>
                <div class="col-md-12"> 
                    <div class="form-group row">
                        <label class="col-md-3 col-form-label" for="text-input">Nama Depan</label>
                        <div class="col-md-8">
                            <input [(ngModel)]="userModel.first_name" type="text" class="form-control">
                        </div> 
                    </div>
                </div>
                <div class="col-md-12"> 
                    <div class="form-group row">
                        <label class="col-md-3 col-form-label" for="text-input">Nama Belakang</label>
                        <div class="col-md-8">
                            <input [(ngModel)]="userModel.last_name" type="text" class="form-control">
                        </div> 
                    </div>
                </div>
                <div class="col-md-12"> 
                    <div class="form-group row">
                        <label class="col-md-3 col-form-label" for="text-input">Sub Lokasi</label>
                        <div class="col-md-8">
                            <input [(ngModel)]="userModel.sub_location" type="text" class="form-control">
                        </div> 
                    </div>
                </div>
            </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button #modalSimpanClose type="button" class="btn btn-secondary" (click)="ModalSimpan.hide()">&nbsp;&nbsp;&nbsp;&nbsp;<b>Close</b>&nbsp;&nbsp;&nbsp;&nbsp;</button>
          <button type="button" class="btn btn-primary" (click)="createUser()">&nbsp;&nbsp;&nbsp;&nbsp;<i class="fa fa-save"></i>&nbsp;&nbsp;<b>Simpan</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</button>
        </div>
      </div>
    </div>
  </div> 

  <div  bsModal #ModalUpdate="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog" style="max-width: 50%;" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title">
            <div class="row">
              <strong>List User</strong>
            </div>
          </div>
          <button type="button" class="close" (click)="ModalUpdate.hide()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-12"> 
                    <div class="form-group row">
                        <label class="col-md-3 col-form-label" for="text-input">Username</label>
                        <div class="col-md-8">
                            <input [(ngModel)]="userModel.user_name" disabled type="text" class="form-control">
                        </div> 
                    </div>
                </div>
                <div class="col-md-12"> 
                    <div class="form-group row group">
                        <label class="col-md-3 col-form-label" for="text-input">Role</label>
                        <div class="col-md-8">
                            <select [(ngModel)]="userModel.user_role" id="select" name="select" class="form-control">
                                <option selected *ngFor="let row of listRole" [value]='row.role_id'><b>{{row.role_name}}</b></option>
                            </select>
                        </div>
                    </div> 
                </div>
                <div class="col-md-12"> 
                    <div class="form-group row">
                        <label class="col-md-3 col-form-label" for="text-input">Nama Depan</label>
                        <div class="col-md-8">
                            <input [(ngModel)]="userModel.first_name" type="text" class="form-control">
                        </div> 
                    </div>
                </div>
                <div class="col-md-12"> 
                    <div class="form-group row">
                        <label class="col-md-3 col-form-label" for="text-input">Nama Belakang</label>
                        <div class="col-md-8">
                            <input [(ngModel)]="userModel.last_name" type="text" class="form-control">
                        </div> 
                    </div>
                </div>
                <div class="col-md-12"> 
                    <div class="form-group row">
                        <label class="col-md-3 col-form-label" for="text-input">Sub Lokasi</label>
                        <div class="col-md-8">
                            <input [(ngModel)]="userModel.sub_location" type="text" class="form-control">
                        </div> 
                    </div>
                </div>
            </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button #modalUpdateClose type="button" class="btn btn-secondary" (click)="ModalUpdate.hide()">&nbsp;&nbsp;&nbsp;&nbsp;<b>Close</b>&nbsp;&nbsp;&nbsp;&nbsp;</button>
          <button type="button" class="btn btn-primary" (click)="UpdateDataUser()">&nbsp;&nbsp;&nbsp;&nbsp;<i class="fa fa-save"></i>&nbsp;&nbsp;<b>Simpan</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</button>
        </div>
      </div>
    </div>
  </div> 


  <ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>