import { AfterViewInit, Component, OnInit, ViewChild,Input  } from '@angular/core';
import { DataTableDirective } from 'angular-datatables'
import { TransportService} from '../../..//services/api.service';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { Guid } from 'guid-typescript'
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { Relation } from '@/model/relation';
import { Router } from '@angular/router';
import { AppService } from '@services/app.service';

@Component({
  selector: 'app-master-relation',
  templateUrl: './master-relation.component.html',
  styleUrls: ['./master-relation.component.scss']
})
export class MasterRelationComponent implements OnInit, AfterViewInit {
  relationForm: FormGroup;
  @ViewChild('modalSaveClose') modalClose;
  @ViewChild(DataTableDirective, {static: false})
  datatableElement: DataTableDirective;
  dtOptions: any = {};
  dataRelation: Relation[] = [];
  printDataRelation: Relation[] = [];
  dtTrigger = new Subject();
  @Input() relationModel = { relation_id : null, relation_code: '', relation_name: '', relation_address : '',
  relation_phone : '', relation_type : '', relation_sl_code : '', active: true, relation_npwp: '', relation_id_card: '',
  relation_owner_name: '', relation_bank_name: '', relation_bank_branch : '', relation_bank_account: '',  relation_bank_account_name : ''};
  action: string;
  public dt: any;
  public cb: any;
  public typeRelation: string = "";
  public relationPage : boolean = false;
  public modalShow : boolean = false;
  constructor( public service: TransportService, private spinner: NgxSpinnerService, fb: FormBuilder, private router: Router, private appServ : AppService) {
    this.relationForm = fb.group({
      'relation_id': [null],
      // 'email': [null, Validators.compose([Validators.required, Validators.pattern(/^(\d{10}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))$/)])],
      'relation_code': [null, Validators.required],
      'relation_name': [null, Validators.required],
      'relation_address': [null, Validators.required],
      'relation_phone': [null, Validators.required],
      'relation_type': [null, Validators.required],
      'relation_sl_code': [null, Validators.required],
      'relation_npwp': [null, Validators.required],
      'relation_id_card': [null, Validators.required],
      'relation_owner_name': [null, Validators.required],
      'relation_bank_name': [null, Validators.required],
      'relation_bank_branch': [null, Validators.required],
      'relation_bank_account': [null, Validators.required],
      'relation_bank_account_name': [null, Validators.required],
      'terms': [false]
    });
  }

    ngOnInit(): void {
      const menuUser = localStorage.getItem("menu");
      if (menuUser != "All"){
        const subMenu = localStorage.getItem("acces_menu");
        let arrayMenu =  subMenu.split(",");
        if (arrayMenu.includes("relation") == false){
          this.router.navigate(["/home"]);
        }
      }
      if (this.router.url.includes("master")){
        this.modalShow = false;
        if (this.router.url.includes("master-customer")){
          this.typeRelation = "CUSTOMER"
        } 
        if (this.router.url.includes("master-supplier")){
          this.typeRelation = "SUPPLIER"
        }
        if (this.router.url.includes("master-vendor")){
          this.typeRelation = "EKSPEDISI"
        }
        if (this.router.url.includes("master-relation")){
          this.relationPage = true;
        }
     }
     else{
      this.modalShow = true;
      if (this.service.pageRelation == "Penjual"){
        this.typeRelation = "SUPPLIER"
      }
      if (this.service.pageRelation == "Customer"){
        this.typeRelation = "CUSTOMER"
      }
      if (this.service.pageRelation == "Ekspedisi"){
        this.typeRelation = "EKSPEDISI"
      }
     }
      
      this.dtOptions = {
        responsive:true,
        serverSide: true,
        processing: true,
        searching : false,
        ordering : false,
        ajax: (dataTablesParameters: any, callback) => {
         this.dt = dataTablesParameters; this.cb = callback;
         this.getDataRelation(dataTablesParameters, callback, $('#codesearch').val().toString(), $('#namesearch').val().toString(), 
         $('#addresssearch').val().toString(),  $('#typesearch').val().toString(),
         $('#slcodesearch').val().toString(),true);
        },
        columnDefs: [
          { width: 100, targets: [0,1,4,5,6,7,8,9,10,11] },
          { width: 200, targets: 2 },
          { width: 300, targets: 3 },
        ],
      };

      const that = this;
      $('.searchInput').on("keyup", function(e) {
        var key = e.which;
        if (key == 13) // the enter key ascii code
        {
          that.getDataRelation(that.dt, that.cb, $('#codesearch').val().toString(), $('#namesearch').val().toString(), 
          $('#addresssearch').val().toString(),  $('#typesearch').val().toString(),
          $('#slcodesearch').val().toString(),false);
        }
      });  
      
      
    }   


    getDataRelation(dataTablesParameters, callback, relation_code: string, relation_name: string, relation_address: string, 
      relation_type: string, relation_sl_code: string, padging : boolean){ 
      if (this.relationPage == false){
        relation_type = this.typeRelation;
      }
      var page = 1;
      if (padging == true){
        page = parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length) + 1;
      }
      this.service.getListRelation(dataTablesParameters, page, dataTablesParameters.length, relation_code,
        relation_name, relation_address, relation_type, relation_sl_code)
      .subscribe(resp => { 
          this.dataRelation = resp.data;
          callback({
            recordsTotal: resp.totalCount,
            recordsFiltered: resp.totalCount,
             data:  []
          });
      });
    }
    
    ngAfterViewInit(): void {
      this.dtTrigger.next(0);
    }
  
    ngOnDestroy(): void {
      this.dtTrigger.unsubscribe();
    }
  
    rerender(): void {
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtTrigger.next(0);
      });
    }

    onSelectRelation(relation: Relation) {
      this.action = "Update";
      this.relationModel = relation;
      if (this.service.pageRelation == "Penjual"){
        this.service.onSelectPenjual(relation);
      }
      if (this.service.pageRelation == "Customer"){
        this.getDataRelation(this.dt, this.cb, "", "","","CUSTOMER","",true);
        this.service.onSelectCustomer(relation);
      }
      if (this.service.pageRelation == "Ekspedisi"){
        this.getDataRelation(this.dt, this.cb, "", "","","EKSPEDISI","",true);
        this.service.onSelectEkspedisi(relation);
      }
    }
  
  saveDataRelation(){

   this.service.markFormTouched(this.relationForm);
    if (this.relationForm.valid) {
      if (this.relationPage == false){
        this.relationModel.relation_type = this.typeRelation;
      }
      Swal.fire({
        title: 'Simpan Data?',
        type: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: '      Yes     '
      }).then((result) => {
        if (result.value) {
          this.spinner.show();
          this.service.addRelation(this.relationModel).subscribe(resp => {
            this.spinner.hide();
            if(resp.succes == false){
              Swal.fire({
                title: ""+resp.text,
                type: 'warning',
                confirmButtonText: '      Ok!     '
              });
            }
            else{
              Swal.fire({
                title: ""+resp.text,
                type: 'success',
                confirmButtonText: '      Ok!     '
              }).then((result) => {
                if (result.value) { 
                  this.getDataRelation(this.dt, this.cb, "", "","","","",true);
                  this.modalClose.nativeElement.click();
                }              
              });
            }
          });
        }
      })

    } else {
      this.relationForm.controls['terms'].setValue(false);
    }
   
  }

  deleteRelation(relation: Relation){
    Swal.fire({
      title: 'Delete Data?',
      // text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '      Yes     '
    }).then((result) => {
      if (result.value) {
        this.spinner.show();
        this.service.DeleteRelatioon(relation.relation_id).subscribe(verelationhicle => { 
          this.spinner.hide();
          Swal.fire({
            type: 'success',
            confirmButtonText: '      Succes!     '
          }).then((result) => {
            if (result.value) { 
              this.getDataRelation(this.dt, this.cb, "", "","","","",true);
            }
          
          }, (error) => {
            //; 
          })
        });
      }
    
    }, (error) => {
      //; 
    });
  }

  
  clearFormVehicle(){
    this.action = "Input";
    this.relationForm.reset();

  }
    
  exportAsXLSX():void {
  this.spinner.show();
  var relation_type = '';
  if (this.relationPage == false){
    relation_type = this.typeRelation;
  }
  else{
    relation_type =  $('#typesearch').val().toString();
  }
    this.service.getListRelation(this.dt, 1, 1000000,  $('#codesearch').val().toString(), $('#namesearch').val().toString(), 
    $('#addresssearch').val().toString(), relation_type, $('#slcodesearch').val().toString())
    .subscribe(resp => { 
        this.spinner.hide();
        this.printDataRelation = resp.data;
        this.appServ.exportAsExcelFile(this.printDataRelation, 'Master_Relation');
    });
  }
      
}


