import { AfterViewInit, Component, OnInit, ViewChild,Input, ChangeDetectorRef, ElementRef  } from '@angular/core';
import { DataTableDirective } from 'angular-datatables'
import { TransportService} from '../../../services/api.service';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Guid } from 'guid-typescript';
import { ListTripRecord } from '@/model/listTripRecord';
import { Driver } from '@/model/driver';
import { Truck } from '@/model/truck';
import { DriverSubscriptionHeader } from '@/model/driverSubscriptionHeader';
import { DriverSubscriptionByCriteria } from '@/model/driverSubscriptionByCriteria';
import { MasterCode } from '@/model/masterCode';
import { DriverSubscriptionVm } from '@/model/driverSubscriptionVm';
import { json } from 'stream/consumers';
import { LocationSettingSignature } from '@/model/locationSettingSignature';
import saveAs from 'file-saver';
import { AuthMenu } from '@/model/masterSubMenu';

@Component({
  selector: 'app-driver-bpjs',
  templateUrl: './driver-bpjs.component.html',
  styleUrls: ['./driver-bpjs.component.scss']
})
export class DriverBpjsComponent  implements OnInit, AfterViewInit {
  @ViewChild('modalDetailShow') modalDetailShow: ElementRef;
  @ViewChild('ModalSimpanHide') ModalSimpanHide: ElementRef;
  @ViewChild('ModalReason') ModalReason: ElementRef;  
  @ViewChild('modalCriteriaShow') modalCriteriaShow: ElementRef; 
  @ViewChild('ModalCriteriaAddHide') ModalCriteriaAddHide: ElementRef;
  @ViewChild('ModalGenerateShow') ModalGenerateShow: ElementRef;
  @ViewChild('ModalGenerateHide') ModalGenerateHide: ElementRef;
  dataSignature: LocationSettingSignature[] = []
  driver_name: string;
  subcriptionName: string;
  dataListGenerate: DriverSubscriptionVm[] = [];
  dataListDisplay: DriverSubscriptionVm[] = [];
  dataListHead: DriverSubscriptionHeader[] = [];
  listCode: MasterCode[] = [];
  dataListCriteria: DriverSubscriptionByCriteria[] = [];
  datatabdtOptionsnt: DataTableDirective;
  dtOptionsHead: any = {};
  dtTriggerHead = new Subject();  
  dtOptionsCriteria: any = {};
  dtTriggerCriteria = new Subject();
  dtOptionsDriver: any = {};
  dtOptionsGenerate: any = {};
  dtTriggerDriver = new Subject();
  dtTriggergenerate = new Subject();
  dataDriver: Driver[] = [];
  authAccessPlb: AuthMenu[] =[]; 
  action: string;
  monthDesc: string;
  tipePrint: string;
  public dt: any;
  public cb: any;
  public dtDetail: any;
  public cbDetail: any;
  public dataTripDto: any;
  @Input() debtModel = {  driver_id: null, truck_id: null, debt_type: null, debt_total: null, cash_payment: null, 
    trip_payment: null, trip_payment_text: null, company_paid: null, sps_date: null, ba_no: null, perihal: null, 
    sps_reason: null, driver_name: null, truck_plate: null, 
   }
  @Input() printDetailModel = {  header_id: null, payment_date: null, subcription_name: null, year: null, disetujui: null
    , diketahui1: null, diketahui2: null, dibuat: null, location: null, hide_diketahui: false, hide_diketahui2: false, hide_dibuat: false,}
  @Input() printExcelModel = {  header_id: null, subcription_name: null, location: null}
  @Input() deleteDebtModel = {  debt_id: null, driver_id: null, reason: null}
  @Input() headerModel = {  subscriptionId: null, payment_date: null, year: null, month: null}
  @Input() criteriaModel = {  id: null, action: null, driver_id: null, amount: null, year: null, subscription_id: null, month: null, driver_subscription_header_id: null}
  @Input() generateModel = {  driver_subscription_header_id: null, displayData: null, generate: null}
  @Input() bpjsModel = {  subscriptionType: null, month: null, year: null}
  constructor( public service: TransportService, private router: Router, private spinner: NgxSpinnerService, fb: FormBuilder, private changeDetectorRef: ChangeDetectorRef) {
  }

    ngOnInit(): void {
      this.userAcces();
      this.listType();
      this.dtOptionsHead = {  
        pageLength: 10,
        ordering : false,
        serverSide: true,
        processing: true,
        searching : false,
        ajax: (dataTablesParameters: any, callback) => {
         this.dt = dataTablesParameters; this.cb = callback;
         this.getListSubHeader(dataTablesParameters, callback, $('#tahun').val().toString(), $('#jenis').val().toString(), $('#bulan').val().toString(),  true);
        },
        columnDefs: [
          { width: 100, targets: [1,2,3,4,5,6,7,8]},
          { width: 200, targets: [0] },
        ],
      };

      this.dtOptionsGenerate = {
        retrieve: true,
        paging: false,
        ordering : false,
        bInfo : false,
        // scrollX: true,
        // scrollCollapse: true,
        searching : false,
        processing: true,
        columnDefs: [
          { width: 500, targets: [0,1,2,3,4] },
        ],
      };

      this.dtOptionsCriteria = { 
        retrieve: true,
        paging: false,
        ordering : false,
        bInfo : false,
        // scrollX: true,
        // scrollCollapse: true,
        searching : false,
        processing: true,
        columnDefs: [
          { width: 400, targets: [0,1,2,3,4,5,6]}
        ]
      };

      this.dtOptionsDriver = {
        retrieve: true,
        pageLength: 5,
        scrollX: true,
        scrollCollapse: true,
        serverSide: true,
        processing: true,
        searching : false,
        ajax: (dataTablesParameters: any, callback, dataDto: ListTripRecord) => {
         this.dataTripDto = dataDto
         this.getDataDriver(dataTablesParameters, callback, "", "","","","", "","","","", "","","", "",  true);
         },
        columnDefs: [
          { width: 150, targets: [0,2,3,4,5,6,7,8,9,10,12,13,14,15] },
          { width: 200, targets: [1] },
          { width: 300, targets: [11] },
        ],
      };

      const that = this;

      $('.searchInput').on("keyup", function(e) {
            
        var key = e.which;
        if (key == 13) // the enter key ascii code
        {
          that.getListSubHeader(that.dt, that.cb, $('#tahun').val().toString(), $('#jenis').val().toString(), $('#bulan').val().toString(),  false);
        }
      });

      this.getSignature();
      
    }   
    
    ngAfterViewInit(): void {
      this.dtTriggerHead.next(0);
    }
  
    ngOnDestroy(): void {
     this.dtTriggerHead.unsubscribe();
    }

    getListSubHeader(dataTablesParameters, callback, year: string, subscription_name: string, month: string, padging: Boolean){
    
      var page = 1;
        if (padging == true){
          page = parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length) + 1;
        }

        this.service.getListSubHeader(dataTablesParameters, page, dataTablesParameters.length, year, subscription_name, month)
        .subscribe(resp => { 
            this.dataListHead = resp.data;
            callback({
              recordsTotal: resp.totalCount,
              recordsFiltered: resp.totalCount,
              data:  []
            });
        });
   }

   getSubCriteria( header_id: string, subscription_name: string){
    var page = 1;
    var perPage = 10;
      this.service.getSubCriteria(null, page, perPage, header_id, subscription_name)
      .subscribe(resp => { 
        this.dataListCriteria = resp;
      });
   }

   deleteDebt(debt_id: Guid, driver_id: Guid, reason: string){
    Swal.fire({
      title: 'Delete Data?',
      // text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '      Yes     '
    }).then((result) => {
        if (result.value) {
          this.spinner.show();
          this.deleteDebtModel.debt_id = debt_id;
          this.deleteDebtModel.driver_id = driver_id;
          this.deleteDebtModel.reason = reason;
            this.service.deleteDebt(this.deleteDebtModel)
             .subscribe(resp => { 
              this.spinner.hide();
            if(resp.succes == false){
              Swal.fire({
                title: ""+resp.text,
                type: 'warning',
                confirmButtonText: '      Ok!     '
              });
            }
            else{
              this.ModalReason.nativeElement.click();
              Swal.fire({
                title: ""+resp.text,
                type: 'success',
                confirmButtonText: '      Ok!     '
              }).then((result) => {
                if (result.value) { 
                  //
                }              
              }, (error) => {
                ; 
              })
            }
            });
        }        
    
    }, (error) => {
      //; 
    });
   }
   driver_subscription_header_id
   onSelectCriteria(data: DriverSubscriptionHeader){
    this.getSubCriteria(data.driver_subscription_header_id.toString(), "");
    this.spinner.show();
    setTimeout(() => {
      this.spinner.hide();
      this.dtTriggerCriteria.next(0);
    }, 500);
    this.criteriaModel.month = data.month;
    this.monthDesc = data.month_name;
    this.criteriaModel.driver_subscription_header_id = data.driver_subscription_header_id.toString();
    this.criteriaModel.subscription_id = data.subscription_id;
    this.subcriptionName = data.subscription_name;
    this.criteriaModel.year = data.year;
    console.log(JSON.stringify(this.criteriaModel));
    this.modalCriteriaShow.nativeElement.click();
   }

   onSelectDetail(data: DriverSubscriptionByCriteria){
    this.driver_name = data.drivername;
    this.criteriaModel.id = data.driver_subscription_id;
    this.criteriaModel.action = "Update"
    this.criteriaModel.amount = data.amount;
    this.criteriaModel.driver_id = data.driver_id;
    this.criteriaModel.driver_subscription_header_id = data.driver_subscription_header_id;
    this.criteriaModel.month = data.monthInt;
    this.criteriaModel.subscription_id = data.driver_subscription_id;
    this.criteriaModel.year = data.year;
    this.headerModel.month = data.month;
   }

   clear(){
    this.criteriaModel.action = "Create"
    this.driver_name = null;
    this.criteriaModel.amount = null;
   }

   onSelectTruck(data: Truck){
    this.debtModel.truck_id = data.truck_id;
    this.debtModel.truck_plate = data.truck_plate_number;
    
  } 

  onSelectDriver(data: Driver){
    this.criteriaModel.driver_id = data.driver_id;
    this.driver_name = data.driver_name;
  } 

  showDriver(){
    this.dtTriggerDriver.next(0);
  }

  listType(){
    this.service.getListMasterCode("SPSGA")
      .subscribe(resp => { 
          this.listCode = resp;
      });
  }

  getDataDriver(dataTablesParameters, callback,  driver_name: string, driver_license: string, driver_license_type: string, 
    agama: string, birthplace: string, ktp_no: string, tanggal_lahir: string, sl_code: string, sl_code2: string,
    sl_code3: string, address: string, valid_to: string, valid_to_contract: string, padging : boolean){ 
    var page = 1;
    if (padging == true){
      page = parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length) + 1;
    }
    this.service.getListDriver(dataTablesParameters, page, dataTablesParameters.length, driver_name, driver_license, driver_license_type, agama,
      birthplace,ktp_no,tanggal_lahir, sl_code, sl_code2, sl_code3, address, valid_to, valid_to_contract, "true", "false")
    .subscribe(resp => { 
        this.dataDriver = resp.data;
        callback({
          recordsTotal: resp.totalCount,
          recordsFiltered: resp.totalCount,
          data:  []
        });
    });
  }

   addDataHeader(){
    if(this.headerModel.subscriptionId == null || this.headerModel.month == null || this.headerModel.payment_date == null ||
      this.headerModel.year == null )
    {
      Swal.fire({
        title: "Data Belum Lengkap !",
        type: 'warning',
        confirmButtonText: '      Ok!     '
      });
      return;
    }
    Swal.fire({
      title: 'Simpan Data?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '      Yes     '
    }).then((result) => {
      if (result.value) {
           this.spinner.show();
            this.service.addBpjsHeader(this.headerModel).subscribe(resp => { 
            this.spinner.hide();
            if(resp.succes == false){
              Swal.fire({
                title: ""+resp.text,
                type: 'warning',
                confirmButtonText: '      Ok!     '
              });
            }
            else{
              this.ModalSimpanHide.nativeElement.click();
              Swal.fire({
                title: ""+resp.text,
                type: 'success',
                confirmButtonText: '      Ok!     '
              }).then((result) => {
                if (result.value) { 
                  this.getListSubHeader(this.dt, this.cb, "", "", "",  false);
                }              
              }, (error) => {
                ; 
              })
            }
            });
      }
    });
   }

   addBpjsCriteria(){
    if(this.criteriaModel.driver_id == null || this.criteriaModel.amount == null || this.criteriaModel.year == null ||
      this.criteriaModel.subscription_id == null || this.criteriaModel.month == null || this.criteriaModel.driver_subscription_header_id == null)
    {
      Swal.fire({
        title: "Data Belum Lengkap !",
        type: 'warning',
        confirmButtonText: '      Ok!     '
      });
      return;
    }
    Swal.fire({
      title: 'Simpan Data?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '      Yes     '
    }).then((result) => {
      if (result.value) {
           this.spinner.show();
            this.service.addBpjsCriteria(this.criteriaModel).subscribe(resp => { 
              this.spinner.hide();
            if(resp.succes == false){
              Swal.fire({
                title: ""+resp.text,
                type: 'warning',
                confirmButtonText: '      Ok!     '
              });
            }
            else{
              Swal.fire({
                title: ""+resp.text,
                type: 'success',
                confirmButtonText: '      Ok!     '
              }).then((result) => {
                if (result.value) { 
                  this.getSubCriteria(this.criteriaModel.driver_subscription_header_id, "");
                  this.ModalCriteriaAddHide.nativeElement.click();
                }              
              }, (error) => {
                ; 
              })
            }
            });
      }
    });
   }

   addDataDriverDebt(){
    if(this.debtModel.driver_name == null || this.debtModel.debt_type == null || this.debtModel.truck_plate == null ||
      this.debtModel.debt_total == null || this.debtModel.company_paid == null || this.debtModel.sps_date == null ||
      this.debtModel.cash_payment == null || this.debtModel.trip_payment == null || this.debtModel.ba_no == null )
    {
      Swal.fire({
        title: "Data Belum Lengkap !",
        type: 'warning',
        confirmButtonText: '      Ok!     '
      });
      return;
    }
    Swal.fire({
      title: 'Simpan Data?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '      Yes     '
    }).then((result) => {
      if (result.value) {
           this.spinner.show();
            this.service.addDataDebtDriver(this.debtModel).subscribe(resp => { 
              this.spinner.hide();
            if(resp.succes == false){
              Swal.fire({
                title: ""+resp.text,
                type: 'warning',
                confirmButtonText: '      Ok!     '
              });
            }
            else{
              this.ModalSimpanHide.nativeElement.click();
              Swal.fire({
                title: ""+resp.text,
                type: 'success',
                confirmButtonText: '      Ok!     '
              }).then((result) => {
                if (result.value) { 
                  //
                }              
              }, (error) => {
                ; 
              })
            }
            });
      }
    });
   }

  
  generateBpjs(data: DriverSubscriptionHeader){
    this.bpjsModel.subscriptionType = data.subscription_id;
    this.bpjsModel.month = data.month;
    this.bpjsModel.year = data.year;
    this.service.generateBPJS(this.bpjsModel).subscribe(resp => {
      this.dataListGenerate = resp.generate;
      this.dataListDisplay = resp.displayData;
    })
  }

  saveGenerateBpjs(){
    Swal.fire({
      title: 'Simpan Data?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '      Yes     '
    }).then((result) => {
      if (result.value) {
           this.spinner.show();
            this.service.saveGenerateBpjs(this.generateModel).subscribe(resp => { 
              this.spinner.hide();
            if(resp.succes == false){
              Swal.fire({
                title: ""+resp.text,
                type: 'warning',
                confirmButtonText: '      Ok!     '
              });
            }
            else{
              this.ModalGenerateHide.nativeElement.click();
              this.getListSubHeader(this.dt, this.cb, $('#tahun').val().toString(), $('#jenis').val().toString(), $('#bulan').val().toString(),  true);
              Swal.fire({
                title: ""+resp.text,
                type: 'success',
                confirmButtonText: '      Ok!     '
              }).then((result) => {
                if (result.value) { 
                  //
                }              
              }, (error) => {
                ; 
              })
            }
            });
      }
    });
  }


  messageWarning(text: string){
    Swal.fire({
      title: ""+text,
      type: 'warning',
      confirmButtonText: '      Ok!     '
    });
    return;
  }

  deleteBpjsCriteria(data : DriverSubscriptionByCriteria){
    Swal.fire({
      title: 'Apakah anda yakin untuk menghapus?',
      type: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '      Yes     '
    }).then((result) => {
      if (result.value) {
        this.spinner.show();
        this.service.deleteBpjsCriteria(data.driver_subscription_id).subscribe(resp => {
          this.spinner.hide();
          if (resp.succes == true){
            Swal.fire({
              title: ""+resp.text,
              type: 'success',
              confirmButtonText: '      Ok!     ',
            }).then((result) => {
              if (result.value) { 
                this.getSubCriteria(this.criteriaModel.id, "");
              }
            });
          }
          else{
            Swal.fire({
              title: ""+resp.text,
              type: 'warning',
              confirmButtonText: '      Ok!     '
            });
          }
      })

      }
    });
   
  }

  printRekapan(data : DriverSubscriptionHeader){
    this.printDetailModel.header_id = data.driver_subscription_header_id;
    this.printDetailModel.payment_date = data.payment_date;
    this.printDetailModel.subcription_name = data.subscription_name;
    this.printDetailModel.year = data.year;
    this.tipePrint = "rekapan";
  }

  printDetail(data : DriverSubscriptionHeader){
    this.printDetailModel.header_id = data.driver_subscription_header_id;
    this.printDetailModel.payment_date = data.payment_date;
    this.printDetailModel.subcription_name = data.subscription_name;
    this.printDetailModel.year = data.year;
    this.tipePrint = "detail";
  }

  printExcel(data : DriverSubscriptionHeader){
    this.printExcelModel.header_id = data.driver_subscription_header_id;
    this.printExcelModel.subcription_name = data.subscription_name;
    this.printExcelModel.location = localStorage.getItem('locationname');
    Swal.fire({
      title: 'Apakah anda yakin Export Data?',
      type: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '      Yes     '
    }).then((result) => {
      if (result.value) {
        this.spinner.show();
        if(data.subscription_name == "Jamsostek"){
          this.service.exportBPJSRekapJamsostek(this.printExcelModel).subscribe(resp =>{
            this.spinner.hide();
            let name = "Rekap_BPJS_Jamsostek_"+data.month_name+".xlsx";
            const blob = new Blob([resp.body],
              { type: 'application/vnd.ms-excel' });
          const file = new File([blob], name,
              { type: 'application/vnd.ms-excel' });
        
            saveAs(file);
          })
        }
        else{
          this.service.exportBPJSRekap(this.printExcelModel).subscribe(resp =>{
            this.spinner.hide();
            let name = "Rekap_BPJS_Kesehatan_"+data.month_name+".xlsx";
            const blob = new Blob([resp.body],
              { type: 'application/vnd.ms-excel' });
          const file = new File([blob], name,
              { type: 'application/vnd.ms-excel' });
        
            saveAs(file);
          })
        }
      }
    });
  }


  printBpjsDetail(){
    Swal.fire({
      title: 'Apakah anda yakin Print Data?',
      type: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '      Yes     '
    }).then((result) => {
      if (result.value) {
        this.printDetailModel.location = localStorage.getItem('locationname');
        this.spinner.show();
        if(this.tipePrint == "detail"){
          this.service.printBPJSDetail(this.printDetailModel).subscribe( resp =>{
            this.spinner.hide();
            let blob:Blob=resp.body as Blob;
            let url = window.URL.createObjectURL(blob);
            window.open(url);
          })
        }
        if(this.tipePrint == "rekapan"){
          this.service.printBPJSRekap(this.printDetailModel).subscribe( resp =>{
            this.spinner.hide();
            let blob:Blob=resp.body as Blob;
            let url = window.URL.createObjectURL(blob);
            window.open(url);
          })
        }
      }
    });
  }

  getSignature(){
    this.service.getSignature().subscribe(resp => { 
            this.dataSignature = resp;
            this.printDetailModel.diketahui1 = resp[0].name;
            this.printDetailModel.diketahui2 = resp[0].name;
            this.printDetailModel.dibuat = resp[0].name;
            this.printDetailModel.disetujui = resp[0].name;
        });
   }

   async onSelectGenerate(data: any){
    this.spinner.show();
    this.bpjsModel.subscriptionType = data.subscription_id;
    this.bpjsModel.month = data.month;
    this.bpjsModel.year = data.year;
    await this.service.generateBPJS(this.bpjsModel).subscribe(resp => {   
      // this.dataListGenerate = resp.generate;
      // this.dataListDisplay = resp.displayData
      this.dataListGenerate = resp.displayData;
      this.dataListDisplay = resp.displayData
      this.generateModel.driver_subscription_header_id = data.driver_subscription_header_id;
      this.generateModel.displayData =  resp.displayData;
      this.generateModel.generate =  resp.generate;
      this.spinner.hide();
      if (resp.generate.length > 0) {
        setTimeout(() => {
          this.dtTriggergenerate.next(0);
        }, 500);
        this.ModalGenerateShow.nativeElement.click();
      }
      else{
        Swal.fire({
          title: "Tidak Ada Data Generate!",
          type: 'warning',
          confirmButtonText: '      Ok!     '
        });
      }
    })
    // this.spinner.show();
    // setTimeout(() => {
    //   this.spinner.hide();
    // }, 500);
    // this.dtTriggergenerate.next(0);
  }


  userAcces(){
    const menuUser = localStorage.getItem("menu");
    if (menuUser != "All"){
      const locationName = localStorage.getItem('locationname');
      if(locationName != "Palembang"){
        const subMenu = localStorage.getItem("acces_menu");
        let arrayMenu =  subMenu.split(",");
        if (arrayMenu.includes("driverbpjs") == false){
          return this.router.navigate(["/home"]);
        }
      }
      else{
        this.authAccessPlb = JSON.parse(localStorage.getItem("authAccessPlb"));
        var a = this.authAccessPlb.filter(item => item.subMenu  == "driverbpjs").length;
        if (a == 0){
          return this.router.navigate(["/home"]);
        }
      }
    }
  }




  
}




