import { ListSPK } from '@/model/listSPK';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { TransportService } from '@services/api.service';
import { Subject } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';

@Component({
  selector: 'app-list-approvals-do',
  templateUrl: './list-approvals-do.component.html',
  styleUrls: ['./list-approvals-do.component.scss']
})
export class ListApprovalsDoComponent implements OnInit, AfterViewInit {

  dataListSPK: ListSPK[] = [];
  dtOptions: any = {};
  dtTrigger = new Subject();
  constructor(public service: TransportService, private router: Router,  private spinner: NgxSpinnerService, ) { }

  ngOnInit(): void {
    this.getDataListSPK();
    this.dtOptions = {
      scrollX: true,
      scrollCollapse: true,
      pageLength: 10,
      ordering : false,
      searching : false,
      columnDefs: [
        { width: 100, targets: [1,2,3,4,5,6,7,8,10,11,12,13,14,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30] },
        { width: 200, targets: [9, 15] },
        { width: 220, targets: [0] }
      ],
    };
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next(0)
  }
  getDataListSPK(){
      this.service.getListApprovalDO()
      .subscribe(resp => { 
          this.dataListSPK = resp;
      });
  }

}
