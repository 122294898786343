<section class="content">    
    <div class="card">
      <div class="card-header">
        <strong>Sync Uang Jalan</strong>
      </div>
        <div class="card-body">
            <div class="scroll">
            <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table-striped">
                <thead>
                  <tr>
                    <th>Id</th>
                    <th>Sync Type</th>
                    <th>Sync Result</th>
                    <th>Sync Date</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of dataLogs">    
                    <td>{{row.id}}</td>
                    <td>{{row.sync_Type}}</td>
                    <td>{{row.sync_Result | slice:0:30}}</td>
                    <td>{{row.sync_Date | slice:0:10}} {{row.sync_Date | slice:11:16}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <br><br><br>
            <div style="text-align: center">
                <button (click)="ModalPrint.show()" type="button" class="btn btn-primary" >&nbsp;&nbsp;&nbsp;&nbsp;<i class="fa fa-sync"></i>&nbsp;&nbsp;<b>Sync Uang Jalan</b>&nbsp;&nbsp;&nbsp;&nbsp;</button>&nbsp;&nbsp;&nbsp;
            </div>
            <br><br>
        </div>
    </div>
  </section>

  

<div bsModal #ModalPrint="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog" style="max-width: 50%;" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title">
          </div>
          <button type="button" class="close" (click)="ModalPrint.hide()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-12"> 
                    <div class="form-group row">
                        <label class="col-md-3 col-form-label" for="text-input">Dari Tanggal</label>
                        <div class="col-md-8">
                            <input [(ngModel)]="syncModel.from" type="date" class="form-control">
                        </div>
                    </div> 
                    <div class="form-group row">
                      <label class="col-md-3 col-form-label" for="text-input">Sampai Tanggal</label>
                      <div class="col-md-8">
                          <input [(ngModel)]="syncModel.to" type="date" class="form-control">
                      </div>
                    </div> 
                </div> 
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" (click)="syncAisUJ()" class="btn btn-primary"><i class="fas fa-sync"></i>&nbsp;<b>Sync</b>&nbsp;</button>
          <button type="button" #modalPrintHide class="btn btn-secondary" (click)="ModalPrint.hide()">&nbsp;<b>Cancel</b>&nbsp;</button> 
      </div>
      </div>
    </div>
  </div>
  
  
  <ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
  >
  <p style="font-size: 20px; color: white">Loading...</p>
  </ngx-spinner>