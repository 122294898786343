
<!-- Main content -->
<section class="content">    
    <div class="card">
      <div class="card-header">
      <strong>List Relation</strong>
      <div class="float-right">
        <a type="button" class="btn btn-sm btn-warning pull-right" data-toggle="modal" (click)="exportAsXLSX()">&nbsp;&nbsp;&nbsp;<b>Export Data</b>&nbsp;&nbsp;&nbsp;&nbsp;<i class="fas fa-file-excel"></i>&nbsp;&nbsp;&nbsp;</a>&nbsp;&nbsp;&nbsp;&nbsp;
        <a type="button" class="btn btn-sm btn-primary pull-right" data-toggle="modal" (click)="ModalRelation.show();clearFormVehicle()"><b>&nbsp;&nbsp;&nbsp;Input Data&nbsp;&nbsp;&nbsp;&nbsp;</b><i class="fa fa-pen"></i>&nbsp;&nbsp;&nbsp;</a>
      </div>
    </div>
    <div class="card-body">
      <div class="main-wrapper">
        <div id="tablea"  class="responsive-table">
        <table datatable  [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table-striped">
          <thead>
            <tr>
              <th>Action</th>
              <th>Kode</th>
              <th>Nama</th>
              <th>Alamat</th>
              <th>Tipe</th>
              <th>Kode SL</th>
              <th>Phone</th>
              <th>Active</th>
              <th>Dibuat Oleh</th>
              <th>Dibuat Pada</th>
              <th>Diubah Oleh</th>
              <th>Diubah Pada</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let row of dataRelation">      
              <td style="width: 200;">
                <button *ngIf="!modalShow" class="btn btn-sm btn-danger" title="Delete"  triggers="mouseenter:mouseleave" (click)="deleteRelation(row);"><i class="fa fa-trash"></i></button>&nbsp;
                <button *ngIf="!modalShow" (click)="onSelectRelation(row);ModalRelation.show()" title="Edit"  class="btn btn-sm btn-primary" data-toggle="modal" triggers="mouseenter:mouseleave"><i class="fa fa-pen"></i></button>
                <button *ngIf="modalShow" (click)="onSelectRelation(row);" title="Pilih Data" class="btn btn-sm btn-primary" triggers="mouseenter:mouseleave"><i class="fa fa-pen"></i></button>
              </td>  
              <td>{{row.relation_code}}</td>
              <td>{{row.relation_name}}</td>
              <td>{{row.relation_address}}</td>
              <td>{{row.relation_type}}</td>
              <td>{{row.relation_sl_code}}</td>
              <td>{{row.relation_phone}}</td>
              <td>{{row.active ? 'Aktif' : 'Tidak Aktif'}}</td>
              <td>{{row.createdby}}</td>
              <td>{{row.created_at | slice:0:10}}</td>  
              <td>{{row.updatedby}}</td>  
              <td>{{row.updated_at | slice:0:10}}</td>   
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <th><input hidden class="searchInput"/></th>
              <th><input id="codesearch" type="text" class="searchInput" placeholder="Kode"/></th>
              <th><input id="namesearch" type="text" class="searchInput" placeholder="Nama"/></th>
              <th><input id="addresssearch" type="text" class="searchInput" placeholder="Alamat"/></th>
              <th><input [hidden]="!relationPage" id="typesearch" type="text" class="searchInput" placeholder="Type"/></th>
              <th><input id="slcodesearch" type="text" class="searchInput" placeholder="Kode SL"/></th>
              <th><input hidden class="searchInput"/></th>
              <th><input hidden class="searchInput"/></th>
              <th><input hidden class="searchInput"/></th>
              <th><input hidden class="searchInput"/></th>
              <th><input hidden class="searchInput"/></th>
              <th><input hidden class="searchInput"/></th>
            </tr>
          </tfoot>

        </table>
      </div>
    </div>
  </div>
  </div>
</section>


<div  bsModal #ModalRelation="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog" style="max-width: 80%;" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title"><strong>{{action}} Data Relasi</strong></div>
          <small><code style="color: black">&nbsp;&nbsp;*(semua kolom wajib di isi)</code></small>
          <button type="button" class="close" #modalSaveClose (click)="ModalRelation.hide()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
  
  <div [formGroup]="relationForm">        
   <div class="row">
    <div class="col-md-6">
            <div class="form-group row">
                <label class="col-md-3 col-form-label" for="text-input">Kode Relasi</label>
                <div class="col-md-8">
                       <input hidden [(ngModel)]="relationModel.relation_id" type="text" class="form-control" formControlName="relation_id">
                        <input [(ngModel)]="relationModel.relation_code" type="text" class="form-control" formControlName="relation_code">
                        <span class="text-danger" *ngIf="relationForm.controls['relation_code'].hasError('required') && 
                        (relationForm.controls['relation_code'].dirty || relationForm.controls['relation_code'].touched)">Kolom Tidak Boleh Kosong!</span>
                </div>
            </div> 
            <div class="form-group row">
                <label class="col-md-3 col-form-label" for="text-input">Nama Relasi</label>
                <div class="col-md-8">
                    <input [(ngModel)]="relationModel.relation_name" type="text" class="form-control" formControlName="relation_name">
                    <span class="text-danger" *ngIf="relationForm.controls['relation_name'].hasError('required') && 
                    (relationForm.controls['relation_name'].dirty || relationForm.controls['relation_name'].touched)">Kolom Tidak Boleh Kosong!</span>
                </div>
            </div> 
            <div class="form-group row">
                <label class="col-md-3 col-form-label" for="text-input">Alamat</label>
                <div class="col-md-8">
                    <textarea [(ngModel)]="relationModel.relation_address" type="text" class="form-control" formControlName="relation_address"></textarea>
                    <span class="text-danger" *ngIf="relationForm.controls['relation_address'].hasError('required') && 
                    (relationForm.controls['relation_address'].dirty || relationForm.controls['relation_address'].touched)">Kolom Tidak Boleh Kosong!</span>
                </div>
            </div>  
            <div class="form-group row">
                <label class="col-md-3 col-form-label" for="text-input">No Telepon</label>
                <div class="col-md-8">
                    <input [(ngModel)]="relationModel.relation_phone" type="text" class="form-control" formControlName="relation_phone">
                    <span class="text-danger" *ngIf="relationForm.controls['relation_phone'].hasError('required') && 
                    (relationForm.controls['relation_phone'].dirty || relationForm.controls['relation_phone'].touched)">Kolom Tidak Boleh Kosong!</span>
                </div>
            </div>     
            <div class="form-group row">
                <label class="col-md-3 col-form-label" for="text-input">Tipe Relasi</label>
                <div class="col-md-8">
                  <input [(ngModel)]="typeRelation" [ngModelOptions]="{standalone: true}" [disabled]="!relationPage" [hidden]="relationPage" type="text" class="form-control">             
                    <select [hidden]="!relationPage"  [(ngModel)]="relationModel.relation_type" id="select" name="select" class="form-control" formControlName="relation_type">
                        <option value="CUSTOMER">CUSTOMER</option>
                        <option value="EKSPEDISI">EKSPEDISI</option>
                        <option value="SUPPLIER">SUPPLIER</option>
                        <option value="PENJUAL">PENJUAL</option>
                    </select>
                    <span class="text-danger" *ngIf="relationForm.controls['relation_type'].hasError('required') && 
                    (relationForm.controls['relation_type'].dirty || relationForm.controls['relation_type'].touched)">Kolom Tidak Boleh Kosong!</span>
                </div>
            </div>  
            <div class="form-group row">
                <label class="col-md-3 col-form-label" for="text-input">Sub Ledger</label>
                <div class="col-md-8">
                    <input [(ngModel)]="relationModel.relation_sl_code" type="text" class="form-control" formControlName="relation_sl_code">
                    <span class="text-danger" *ngIf="relationForm.controls['relation_sl_code'].hasError('required') && 
                    (relationForm.controls['relation_sl_code'].dirty || relationForm.controls['relation_sl_code'].touched)">Kolom Tidak Boleh Kosong!</span>
                </div>
            </div> 
    </div>
    <div class="col-md-6"> 
        <div class="form-group row">
            <label class="col-md-3 col-form-label" for="text-input">KTP Relasi</label>
            <div class="col-md-8">
                  <input [(ngModel)]="relationModel.relation_id_card" type="text" class="form-control" formControlName="relation_id_card">
                  <span class="text-danger" *ngIf="relationForm.controls['relation_id_card'].hasError('required') && 
                    (relationForm.controls['relation_id_card'].dirty || relationForm.controls['relation_id_card'].touched)">Kolom Tidak Boleh Kosong!</span>
           </div>
        </div>  
        <div class="form-group row">
            <label class="col-md-3 col-form-label" for="text-input">NPWP Relasi</label>
            <div class="col-md-8">
                  <input [(ngModel)]="relationModel.relation_npwp" type="text" class="form-control" formControlName="relation_npwp">
                  <span class="text-danger" *ngIf="relationForm.controls['relation_npwp'].hasError('required') && 
                    (relationForm.controls['relation_npwp'].dirty || relationForm.controls['relation_npwp'].touched)">Kolom Tidak Boleh Kosong!</span>
           </div>
        </div>  
        <div class="form-group row">
            <label class="col-md-3 col-form-label" for="text-input">Nama Pemilik</label>
            <div class="col-md-8">
                  <input [(ngModel)]="relationModel.relation_owner_name" type="text" class="form-control" formControlName="relation_owner_name">
                  <span class="text-danger" *ngIf="relationForm.controls['relation_owner_name'].hasError('required') && 
                    (relationForm.controls['relation_owner_name'].dirty || relationForm.controls['relation_owner_name'].touched)">Kolom Tidak Boleh Kosong!</span>
           </div>
        </div>  
        <div class="form-group row">
            <label class="col-md-3 col-form-label" for="text-input">Nama Bank</label>
            <div class="col-md-8">
                  <input [(ngModel)]="relationModel.relation_bank_name" type="text" class="form-control" formControlName="relation_bank_name">
                  <span class="text-danger" *ngIf="relationForm.controls['relation_bank_name'].hasError('required') && 
                    (relationForm.controls['relation_bank_name'].dirty || relationForm.controls['relation_bank_name'].touched)">Kolom Tidak Boleh Kosong!</span>
           </div>
        </div>  
        <div class="form-group row">
            <label class="col-md-3 col-form-label" for="text-input">Cabang Bank</label>
            <div class="col-md-8">
                  <input [(ngModel)]="relationModel.relation_bank_branch" type="text" class="form-control" formControlName="relation_bank_branch">
                  <span class="text-danger" *ngIf="relationForm.controls['relation_bank_branch'].hasError('required') && 
                    (relationForm.controls['relation_bank_branch'].dirty || relationForm.controls['relation_bank_branch'].touched)">Kolom Tidak Boleh Kosong!</span>
           </div>
        </div>  
        <div class="form-group row">
            <label class="col-md-3 col-form-label" for="text-input">No Rekening</label>
            <div class="col-md-8">
                  <input [(ngModel)]="relationModel.relation_bank_account" type="text" class="form-control" formControlName="relation_bank_account">
                  <span class="text-danger" *ngIf="relationForm.controls['relation_bank_account'].hasError('required') && 
                    (relationForm.controls['relation_bank_account'].dirty || relationForm.controls['relation_bank_account'].touched)">Kolom Tidak Boleh Kosong!</span>
           </div>
        </div>  
        <div class="form-group row">
            <label class="col-md-3 col-form-label" for="text-input">Nama Rekening</label>
            <div class="col-md-8">
                  <input [(ngModel)]="relationModel.relation_bank_account_name" type="text" class="form-control" formControlName="relation_bank_account_name">
                  <span class="text-danger" *ngIf="relationForm.controls['relation_bank_account_name'].hasError('required') && 
                    (relationForm.controls['relation_bank_account_name'].dirty || relationForm.controls['relation_bank_account_name'].touched)">Kolom Tidak Boleh Kosong!</span>
           </div>
        </div>  
      </div>  

    </div>
  </div>

          <!-- <div [formGroup]="relationForm">
            <div class="form-group row">
             
             <div class="col-md-1"></div>
                
         </div> 
          
          </div> -->
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" (click)="ModalRelation.hide()"><b>Close</b></button>
            <button type="button" class="btn btn-primary"  (click)="saveDataRelation();"><i class="fa fa-save"></i>&nbsp;&nbsp;<b>Save</b>&nbsp;&nbsp;</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ngx-spinner  bdColor = "rgba(248,248,248,0.95)" size = "large" color = "#262424" type = "ball-spin-fade" >
    <p style="font-size: 20px; color: white"></p>
  </ngx-spinner>


  