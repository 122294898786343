import { AfterViewInit, Component, OnInit, ViewChild,Input  } from '@angular/core';
import { DataTableDirective } from 'angular-datatables'
import { TransportService} from '../../..//services/api.service';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { Guid } from 'guid-typescript'
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { Driver } from '@/model/driver';
import moment from 'moment';
import { DriverContract } from '@/model/driverContract';
import { AppService } from '@services/app.service';
import { saveAs } from 'file-saver';
import { UserAuth } from '@/model/userAuth';
import { Router } from '@angular/router';
import { AuthMenu } from '@/model/masterSubMenu';
import { UserAuthNew } from '@/model/respDto';


@Component({
  selector: 'app-master-driver',
  templateUrl: './master-driver.component.html',
  styleUrls: ['./master-driver.component.scss']
})
export class MasterDriverComponent implements OnInit, AfterViewInit {
  @ViewChild('modalSaveClose') modalClose;
  @ViewChild('modalSaveClose2') modalClose2;
  @ViewChild('ModalBlacklistClose') ModalBlacklistClose;
  @ViewChild('ModalBlacklistShow') ModalBlacklistShow;
  @ViewChild('ModalSlCodeClose') ModalSlCodeClose;
  
  
  @ViewChild(DataTableDirective, {static: false})
  datatableElement: DataTableDirective;
  dtOptions: any = {};
  dtOptions2: any = {};
  dataDriver: Driver[] = [];
  printDataDriver: Driver[] = [];
  dataContract: DriverContract[] = [];
  dtTrigger = new Subject();

  @Input() driverModel = { driver_id : null, driver_name: '', driver_license: '', sl_code : '', sl_code2: '',
  sl_code3 : '', valid_to : new Date(), active : false, valid_to_contract: new Date(), agama: '', address: '',
  birthplace: '', ktp_no: '', driver_license_type : '', ktp_name: '',  sim_name : '', tanggal_lahir: new Date(), bpjs: false, 
  bpjstk:false, jht : false, npwp: null, phone: null, nonactive_reason: null};

  @Input() simModel = { driver_id : null, name: '', sim: '', valid : new Date()};
  @Input() blacklistModel = { ktp: null, blacklist: null, reason: null};
  @Input() contractModel = { driver_id: null, contract_valid_from: Date(), contract_valid_to: Date()};
  @Input() printModel = { query: new Array() }
  @Input() printContractModel = { driver_id : null, contractDate: null, location: null};
  @Input() changeSLCodeModel = { driver_id : null, sl_code: null, bpjs: null, bpjstk: null, jht: null};
  action: string;
  public dt: any;
  public cb: any;
  driverName: string;
  authAcces: UserAuth[] =[];
  authAccessPlb: UserAuthNew[] =[];
  btnEdit: Boolean = true;
  btnInsert: Boolean = true;
  btnDelete: Boolean = true;
  location: string;

  constructor( public service: TransportService, private router: Router, private spinner: NgxSpinnerService, fb: FormBuilder, private appServ : AppService) {
  }

  exportAsXLSX():void {
    this.service.getListDriver(this.dt, 1, 1000000, $('#driver_name').val().toString(), $('#driver_license').val().toString(), 
    $('#driver_license_type').val().toString(),$('#agama').val().toString(),$('#birthplace').val().toString(),
    $('#ktp_no').val().toString(),$('#tanggal_lahir').val().toString(),$('#sl_code').val().toString(),
    $('#sl_code2').val().toString(),$('#sl_code3').val().toString(),$('#address').val().toString(),
    $('#valid_to').val().toString(),$('#valid_to_contract').val().toString(), "true", $('#blacklist').val().toString())
    .subscribe(resp => { 
        this.printDataDriver = resp.data;
        this.appServ.exportAsExcelFile(this.printDataDriver, 'sample');
    });
 }

 exportDriver():void {
  this.spinner.show();
  this.service.exportDriver( 1, 1000000, $('#driver_name').val().toString(), $('#driver_license').val().toString(), 
  $('#driver_license_type').val().toString(),$('#agama').val().toString(),$('#birthplace').val().toString(),
  $('#ktp_no').val().toString(),$('#tanggal_lahir').val().toString(),$('#sl_code').val().toString(),
  $('#sl_code2').val().toString(),$('#sl_code3').val().toString(),$('#address').val().toString(),
  $('#valid_to').val().toString(),$('#valid_to_contract').val().toString(),  $('#active').val().toString(), $('#blacklist').val().toString(), this.location)
  .subscribe(resp => { 
    this.spinner.hide();
      const blob = new Blob([resp.body],
        { type: 'application/vnd.ms-excel' });
      const file = new File([blob], 'ReportMasterDriver.xlsx',
        { type: 'application/vnd.ms-excel' });

      saveAs(file);
  });
}

    ngOnInit(): void {
      this.userAcces();
      this.dtOptions = {
        paging: true,
        pagingType: 'full_numbers',
        length: 10,
        serverSide: true,
        processing: true,
        searching : false,
        ordering : false,
        scrollX: true,
        scrollCollapse: true,
        ajax: (dataTablesParameters: any, callback) => {
          this.dt = dataTablesParameters; this.cb = callback;
          this.getDataDriver(dataTablesParameters, callback, $('#driver_name').val().toString(), $('#driver_license').val().toString(), 
          $('#driver_license_type').val().toString(),$('#agama').val().toString(),$('#birthplace').val().toString(),
          $('#ktp_no').val().toString(),$('#tanggal_lahir').val().toString(),$('#sl_code').val().toString(),
          $('#sl_code2').val().toString(),$('#sl_code3').val().toString(),$('#address').val().toString(),
          $('#valid_to').val().toString(),$('#valid_to_contract').val().toString(), "true", $('#blacklist').val().toString(),true);

         },
        columnDefs: [
          { width: 200, targets: [2,3,6,7,8,9,10,11,12,14,15,16, 17,18,19] },
          { width: 200, targets: [1] },
          { width: 250, targets: [0] },
          { width: 300, targets: [13] },
          { width: 50, targets: [4,5] },
        ],
      };

      this.dtOptions2 = {
        paging: false,
        searching : false,
        info: false
      };

      const that = this;
      $('.searchInput').on("keyup", function(e) {
        var key = e.which;
        if (key == 13) // the enter key ascii code
        {
          that.getDataDriver(that.dt, that.cb, $('#driver_name').val().toString(), $('#driver_license').val().toString(), 
          $('#driver_license_type').val().toString(),$('#agama').val().toString(),$('#birthplace').val().toString(),
          $('#ktp_no').val().toString(),$('#tanggal_lahir').val().toString(),$('#sl_code').val().toString(),
          $('#sl_code2').val().toString(),$('#sl_code3').val().toString(),$('#address').val().toString(),
          $('#valid_to').val().toString(),$('#valid_to_contract').val().toString(), $('#active').val().toString(), $('#blacklist').val().toString(), false);
        }
      }); 
      
    }   

    
    getDataDriver(dataTablesParameters, callback,  driver_name: string, driver_license: string, driver_license_type: string, 
      agama: string, birthplace: string, ktp_no: string, tanggal_lahir: string, sl_code: string, sl_code2: string,
      sl_code3: string, address: string, valid_to: string, valid_to_contract: string, active: string, blacklist: string, padging : boolean){ 
      var page = 1;
      if (padging == true){
        page = parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length) + 1;
      }
      this.service.getListDriver(dataTablesParameters, page, dataTablesParameters.length, driver_name, driver_license, driver_license_type, agama,
        birthplace,ktp_no,tanggal_lahir, sl_code, sl_code2, sl_code3, address, valid_to, valid_to_contract, active, blacklist)
      .subscribe(resp => { 
          this.dataDriver = resp.data;
          callback({
            recordsTotal: resp.totalCount,
            recordsFiltered: resp.totalCount,
            data:  []
          });
      });
      this.location = localStorage.getItem('locationname');
    }

   
    ngAfterViewInit(): void {
      this.dtTrigger.next(0);
    }
  
    ngOnDestroy(): void {
      this.dtTrigger.unsubscribe();
    }
  
    rerender(): void {
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtTrigger.next(0);
      });
    }

    onSelectDriver(driver: any) {
      this.action = "Update";
      this.driverModel = driver;
      this.driverModel.active = driver.active;
    }

    getDataContract(id: Guid){
      this.service.getDriverContract(id).subscribe(resp => {
        this.dataContract = resp;
      });
    }

    onSelectContract(data: Driver){
      var today = moment().format('YYYY-MM-DD');       
      this.contractModel.contract_valid_from = today;
      var date = new Date(this.contractModel.contract_valid_from);
      this.contractModel.contract_valid_to = moment(date).add(1,'y').format('YYYY-MM-DD');
      this.contractModel.driver_id = data.driver_id;
      this.getDataContract(data.driver_id);
    }

    
    selectActive(){
      this.getDataDriver(this.dt, this.cb, $('#driver_name').val().toString(), $('#driver_license').val().toString(), 
          $('#driver_license_type').val().toString(),$('#agama').val().toString(),$('#birthplace').val().toString(),
          $('#ktp_no').val().toString(),$('#tanggal_lahir').val().toString(),$('#sl_code').val().toString(),
          $('#sl_code2').val().toString(),$('#sl_code3').val().toString(),$('#address').val().toString(),
          $('#valid_to').val().toString(),$('#valid_to_contract').val().toString(), $('#active').val().toString(), $('#blacklist').val().toString(), false);
    }
    

  
  onSelectBlacklist(data: Driver){
    if (data.ktp_no == null || data.ktp_no == ""){
      return Swal.fire({
        title: "No KTP Masih Kosong!",
        type: 'warning',
        confirmButtonText: '      Ok!     '
      });
    }
    this.blacklistModel.ktp = data.ktp_no;
    this.blacklistModel.blacklist = data.blacklist;
    this.blacklistModel.reason = data.blacklist_reason;
    this.driverName = data.driver_name;
    this.ModalBlacklistShow.nativeElement.click();
  }

  onSelectSlCode(data: Driver){
    this.changeSLCodeModel.driver_id = data.driver_id;
    this.changeSLCodeModel.bpjs = data.bpjs;
    this.changeSLCodeModel.bpjstk = data.bpjstk;
    this.changeSLCodeModel.jht = data.jht;
  }

  changeSlCode(){
    if(this.changeSLCodeModel.sl_code == null || this.changeSLCodeModel.sl_code == ""){
      Swal.fire({
        title: "Data Belum Lengkap !",
        type: 'warning',
        confirmButtonText: '      Ok!     '
      });
      return;
    }

    Swal.fire({
      title: 'Ubah SL Code Supir?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '      Yes     '
    }).then((result) => {
      if (result.value) {
        this.spinner.show();
        this.service.changeSLCode(this.changeSLCodeModel).subscribe(resp => {
          this.spinner.hide();
          if(resp.succes == false){
            Swal.fire({
              title: ""+resp.text,
              type: 'warning',
              confirmButtonText: '      Ok!     '
            });
          }
          else{
            Swal.fire({
              title: ""+resp.text,
              type: 'success',
              confirmButtonText: '      Ok!     '
            }).then((result) => {
              if (result.value) { 
                this.getDataDriver(this.dt, this.cb, "", "","","","", "","","","", "","","", "", "", "",true);
                this.changeSLCodeModel.sl_code == null;
                this.ModalSlCodeClose.nativeElement.click();
              }              
            }, (error) => {
              ; 
            })
          }
          });
      }
    
    });

  }


  addBlacklist(){
    if(this.blacklistModel.blacklist == null || this.blacklistModel.reason == null){
      Swal.fire({
        title: "Data Belum Lengkap !",
        type: 'warning',
        confirmButtonText: '      Ok!     '
      });
      return;
    }
    Swal.fire({
      title: 'Simpan Data?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '      Yes     '
    }).then((result) => {
      if (result.value) {
        if (this.blacklistModel.blacklist == "true"){
          this.blacklistModel.blacklist = true;
        }
        else{
          this.blacklistModel.blacklist = false;
        }
        this.spinner.show();
        this.service.blacklistDriver(this.blacklistModel).subscribe(resp => {
          this.spinner.hide();
          if(resp.succes == false){
            Swal.fire({
              title: ""+resp.text,
              type: 'warning',
              confirmButtonText: '      Ok!     '
            });
          }
          else{
            Swal.fire({
              title: ""+resp.text,
              type: 'success',
              confirmButtonText: '      Ok!     '
            }).then((result) => {
              if (result.value) { 
                this.getDataDriver(this.dt, this.cb, "", "","","","", "","","","", "","","", "", "", "",true);
                this.ModalBlacklistClose.nativeElement.click();
                this.blacklistModel = { ktp: null, blacklist: null, reason: null};
              }              
            }, (error) => {
              ; 
            })
          }
          });
      }
    
    });

  }

  
  null(str){
    if (str == null || str == ""){
      return true;
    }
    return false;
  }
  //  { driver_id : null, driver_name: '', driver_license: '', sl_code : '', sl_code2: '',
  // sl_code3 : '', valid_to : new Date(), active : false, valid_to_contract: new Date(), agama: '', address: '',
  // birthplace: '', ktp_no: '', driver_license_type : '', ktp_name: '',  sim_name : '', tanggal_lahir: new Date(), bpjs: false, 
  // bpjstk:false, jht : false, npwp: null, phone: null}}

  saveDataDriver(){
    console.log(JSON.stringify(this.driverModel))
    if ( 
          this.null(this.driverModel.driver_name) || this.null(this.driverModel.driver_license) || this.null(this.driverModel.sl_code) ||
          this.null(this.driverModel.valid_to) || this.null(this.driverModel.agama) ||
          this.null(this.driverModel.address) || this.null(this.driverModel.birthplace) || this.null(this.driverModel.ktp_no) ||
          this.null(this.driverModel.ktp_name) || this.null(this.driverModel.sim_name) || this.null(this.driverModel.tanggal_lahir) 
      )
      {
        return  Swal.fire({
          title: "Data Belum Lengkap",
          type: 'warning',
          confirmButtonText: '      Ok!     '
        });
      }
      else {
      
      Swal.fire({
        title: 'Simpan Data?',
        type: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: '      Yes     '
      }).then((result) => {
        if (result.value) {
          this.spinner.show();
          this.service.addDriver(this.driverModel).subscribe(resp => {
            this.spinner.hide();
            if(resp.succes == false){
              Swal.fire({
                title: ""+resp.text,
                type: 'warning',
                confirmButtonText: '      Ok!     '
              });
            }
            else{
              Swal.fire({
                title: ""+resp.text,
                type: 'success',
                confirmButtonText: '      Ok!     '
              }).then((result) => {
                if (result.value) { 
                  this.getDataDriver(this.dt, this.cb, "", "","","","", "","","","", "","","", "", "", "", true);
                this.modalClose.nativeElement.click();
                }              
              }, (error) => {
                ; 
              })
            }

          });
        }
      });
    }
  }

  saveDataContract(){
       Swal.fire({
         title: 'Simpan Data?',
         type: 'warning',
         showCancelButton: true,
         confirmButtonColor: '#3085d6',
         cancelButtonColor: '#d33',
         confirmButtonText: '      Yes     '
       }).then((result) => {
         if (result.value) {
           this.spinner.show();
           this.service.addDriverContract(this.contractModel).subscribe(resp => {
             this.spinner.hide();
              if(resp.succes == false){
                Swal.fire({
                  title: ""+resp.text,
                  type: 'warning',
                  confirmButtonText: '      Ok!     '
                });
              }
              else{
                Swal.fire({
                  title: ""+resp.text,
                  type: 'success',
                  confirmButtonText: '      Ok!     '
                }).then((result) => {
                  if (result.value) { 
                    this.getDataContract(this.contractModel.driver_id);
                  }              
                }, (error) => {
                  ; 
                })
              }
           });
         }
       });
   }

  licenseChange(){
      if ( moment(this.driverModel.valid_to).isAfter(this.simModel.valid) == true){
        Swal.fire({
          title: "Masa berlaku SIM tidak valid, mohon cek kembali! (Masa berlaku SIM lama lebih baru)",
          type: 'warning',
          confirmButtonText: '      Ok!     '
        });
      }
      else{
        Swal.fire({
          title: 'Simpan Data?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: '      Yes     '
        }).then((result) => {
          if (result.value) {
            this.spinner.show();
            this.simModel.driver_id = this.driverModel.driver_id;
            this.service.changeLicense(this.simModel).subscribe(resp => {
            this.spinner.hide();
            if(resp.succes == false){
              Swal.fire({
                title: ""+resp.text,
                type: 'warning',
                confirmButtonText: '      Ok!     '
              });
            }
            else{
              Swal.fire({
                title: ""+resp.text,
                type: 'success',
                confirmButtonText: '      Ok!     '
              }).then((result) => {
                if (result.value) { 
                  this.getDataDriver(this.dt, this.cb, "", "","","","", "","","","", "","","", "", "", "", true);
                  this.modalClose2.nativeElement.click();
                }              
              }, (error) => {
                ; 
              })
            }
             
             
 
            });
          }
        });
      }
      
        

       
   }

  deleteDriver(relation: any){
    Swal.fire({
      title: 'Delete Data?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '      Yes     '
    }).then((result) => {
      if (result.value) {
        this.spinner.show();
        this.service.DeleteDriver(relation.driver_id).subscribe(verelationhicle => { 
          this.spinner.hide();
          Swal.fire({
            type: 'success',
            confirmButtonText: '      Succes!     '
          }).then((result) => {
            if (result.value) { 
              this.getDataDriver(this.dt, this.cb, "", "","","","", "","","","", "","","", "", "","",true);
            }
          
          }, (error) => {
            //; 
          })
        });
      }
    
    }, (error) => {
      //; 
    });
  }

  deleteContract(data: any){
    Swal.fire({
      title: 'Delete Data?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '      Yes     '
    }).then((result) => {
      if (result.value) {
        this.spinner.show();
        this.service.deleteDriverContract(data.driver_contract_id).subscribe(resp => { 
          this.spinner.hide();
          Swal.fire({
            type: 'success',
            confirmButtonText: '      Succes!     '
          }).then((result) => {
            if (result.value) { 
              this.getDataContract(this.contractModel.driver_id);
            }
          
          }, (error) => {
            //; 
          })
        });
      }
    
    }, (error) => {
      //; 
    });
  }

  
  clearFormDriver(){
    this.action = "Input";
    this.driverModel = { driver_id : null, driver_name: '', driver_license: '', sl_code : '', sl_code2: '',
      sl_code3 : '', valid_to : null, active : false, valid_to_contract: null, agama: '', address: '',
      birthplace: '', ktp_no: '', driver_license_type : '', ktp_name: '',  sim_name : '', tanggal_lahir: null, bpjs: false, 
      bpjstk:false, jht : false, npwp: null, phone: null, nonactive_reason: null};
  }
    

  printMasterDriver(){
    this.service.getListDriver(this.dt, 1, 1000000, $('#driver_name').val().toString(), $('#driver_license').val().toString(), 
    $('#driver_license_type').val().toString(),$('#agama').val().toString(),$('#birthplace').val().toString(),
    $('#ktp_no').val().toString(),$('#tanggal_lahir').val().toString(),$('#sl_code').val().toString(),
    $('#sl_code2').val().toString(),$('#sl_code3').val().toString(),$('#address').val().toString(),
    $('#valid_to').val().toString(),$('#valid_to_contract').val().toString(), "true", $('#blacklist').val().toString())
    .subscribe(resp => { 
        this.printDataDriver = resp.data;
        this.printDataDriver.slice(1,19);
        let currentDate = new Date().toLocaleDateString();;
        let name = "Report_Master_Driver_"+currentDate+".xlsx";
        this.service.printMasterData(this.printDataDriver).subscribe(resp =>{
        //this.spinner.hide();
        const blob = new Blob([resp.body],
        { type: 'application/vnd.ms-excel' });
        const file = new File([blob], name,
        { type: 'application/vnd.ms-excel' });

        saveAs(file);
        })
    });
  }

  printContract(data : DriverContract){
    this.spinner.show();
    this.printContractModel.driver_id = data.driver_id;
    this.printContractModel.contractDate = data.contract_valid_from;
    this.printContractModel.location = localStorage.getItem('locationname');
    this.service.printContract(this.printContractModel).subscribe(resp =>{
        this.spinner.hide();
        let blob:Blob=resp.body as Blob;
        let url = window.URL.createObjectURL(blob);
        window.open(url);
    });
    this.service.printContracStatement(this.printContractModel).subscribe(resp =>{
      this.spinner.hide();
      let blob:Blob=resp.body as Blob;
      let url = window.URL.createObjectURL(blob);
      window.open(url);
  });
  }

  
  userAcces(){
    const menuUser = localStorage.getItem("menu");
    const subMenu = localStorage.getItem("acces_menu");
    if (menuUser != "All"){
      this.btnEdit = false;
      this.btnDelete = false;
      this.btnInsert = false;
  
      const locationName = localStorage.getItem('locationname');
      if (locationName != "Palembang"){
        let arrayMenu =  subMenu.split(",");
        if (arrayMenu.includes("driver") == false){
          return this.router.navigate(["/home"]);
        }
        this.authAcces = JSON.parse(localStorage.getItem("authAccess"));
        this.authAcces.filter(item => item.menu_name == "DriverForm").forEach(i => {
            if(i.name == "INSERT"){
              this.btnInsert = true;
            }
            if(i.name == "UPDATE"){
              this.btnEdit = true;
            }
            if(i.name == "DELETE"){
              this.btnDelete = true;
            }
        });
      }
      else{
        this.authAccessPlb = JSON.parse(localStorage.getItem("authAccessPlb"));
        var a = this.authAccessPlb.filter(item => item.subMenu  == "driver").length;
        if (a == 0){
          return this.router.navigate(["/home"]);
        }
        this.authAccessPlb.filter(item => item.subMenu == "driver").forEach(i => {
            
            if(i.auth == "CRUD"){
              this.btnInsert = true;
              this.btnEdit = true;
              this.btnDelete = true;
            }
        });
      }

      
    }
  }
      
  numericOnly(event): boolean {    
    let patt = /^([0-9])$/;
    let result = patt.test(event.key);
    if (event.target.value.length >= 16){
      return false;
    }
    else{
      return result;
    }
}

onChangeDateContract(){
  var date = new Date(this.contractModel.contract_valid_from);
  this.contractModel.contract_valid_to = moment(date).add(1,'y').format('YYYY-MM-DD');
}

}



