import { AfterViewInit, Component, OnInit, ViewChild,Input, ChangeDetectorRef, ElementRef  } from '@angular/core';
import { TransportService} from '../../../services/api.service';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { AccClosing } from '@/model/accClosing';
import { MasterCode } from '@/model/masterCode';
import moment from 'moment';
import { User } from '@/model/user';
import { Role } from '@/model/role';

@Component({
  selector: 'app-re-pass',
  templateUrl: './re-pass.component.html',
  styleUrls: ['./re-pass.component.scss']
})
export class RePassComponent implements OnInit, AfterViewInit {
  @ViewChild('modalSimpanClose') modalSimpanClose: ElementRef;
  dtOptionsAcc: any = {};
  dtTriggerAcc = new Subject();
  dataListAcc: User[] = [];
  user: User;
  listRole: Role[] = [];
  public dt: any;
  public cb: any;
  @Input() userModel = { id: null, newPassword: null, oldPassword: null}
  constructor( public service: TransportService, private router: Router, private spinner: NgxSpinnerService, private changeDetectorRef: ChangeDetectorRef) {
  }

    ngOnInit(): void {
     const userId = localStorage.getItem("userid");
     this.userModel.id = userId;
      this.service.getUserById(userId).subscribe(resp => {
        this.user = resp;
      });

    }   

      
    ngAfterViewInit(): void {
    }
  
    ngOnDestroy(): void {
    }


  messageWarning(text: string){
    Swal.fire({
      title: ""+text,
      type: 'warning',
      confirmButtonText: '      Ok!     '
    });
    return;
  }

  setNull(){
    this.userModel.newPassword = null;
    this.userModel.oldPassword = null;
   }

  UpdatePassword(){
    if (this.userModel.newPassword == null || this.userModel.oldPassword == null || this.userModel.id == null){
      Swal.fire({
        title: "Data Masih Belum Lengkap !",
        type: 'warning',
        confirmButtonText: '      Ok!     '
      });
      return;
    }
    Swal.fire({
      title: 'Update Password?',
      type: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '      Yes     '
      }).then((result) => {
        if (result.value) {
          this.service.updatePassword(this.userModel).subscribe( resp => {
            this.spinner.hide();
            if(resp.succes == false){
              Swal.fire({
                title: ""+resp.text,
                type: 'warning',
                confirmButtonText: '      Ok!     '
              });
            }
            else{
              Swal.fire({
                title: ""+resp.text,
                type: 'success',
                confirmButtonText: '      Ok!     '
              }).then((result) => {
                if (result.value) { 
                  this.setNull();
                }              
              });
            }
          })
        }
    });
  }

  
}





