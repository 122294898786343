import { AfterViewInit, Component, OnInit, ViewChild,Input, ChangeDetectorRef, ElementRef  } from '@angular/core';
import { DataTableDirective } from 'angular-datatables'
import { TransportService} from '../../../services/api.service';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Guid } from 'guid-typescript';
import { ListPayment } from '@/model/listPayment';
import { Relation } from '@/model/relation';
import { ListOfInvoice } from '@/model/listOfInvoice';
import { MasterCode } from '@/model/masterCode';
import { PaymentDetailDto } from '@/model/paymentDetailDto';
import { LocationSettingBank } from '@/model/locationSettingBank';
import { stat } from 'fs';
import { CostType } from '@/model/costType';

@Component({
  selector: 'app-payment-list',
  templateUrl: './payment-list.component.html',
  styleUrls: ['./payment-list.component.scss']
})
export class PaymentListComponent  implements OnInit, AfterViewInit {
  @ViewChild('modalPotonganClose') modalPotonganClose: ElementRef;
  @ViewChild('modalCustomerShow') modalCustomerShow: ElementRef;
  
  @ViewChild('modalSimpanClose') modalSimpanClose: ElementRef;
  @ViewChild('modalPaymentClose') modalPaymentClose: ElementRef;
  @ViewChild(DataTableDirective, {static: false})
  datatabdtOptionsnt: DataTableDirective;
  model : any;
  dtOptionsPayment: any = {};
  dtTriggerPayment = new Subject();
  dtOptionsCustomer: any = {};
  dtTriggerCustomer = new Subject();
  dtOptions: any = {};
  dataBank: LocationSettingBank[] =[];
  dataInvoice : PaymentDetailDto[] =[];
  paymentDto : PaymentDetailDto;
  dataListPayment: ListPayment[] = [];
  dataRelation: Relation[]=[];
  dataTambahanPot: {payment_detail_id: Guid, type_text: string, invoice_id: Guid, invoice_no: string, cost_type_id:Guid, cost_type_text: string, amount: number, remarks: string} ;
  dataTambahan: Array<{payment_detail_id: Guid, type_text: string, invoice_id: Guid, invoice_no: string, cost_type_id:Guid, cost_type_text: string, amount: number, remarks: string}> = [];
  deleteDataTambahanList: Array<{payment_detail_id: Guid, type_text: string, invoice_id: Guid, invoice_no: string, cost_type_id:Guid, cost_type_text: string, amount: number, remarks: string}> = [];
  customer: string = "";
  AP: Boolean = false;
  save: Boolean = false;
  action: string;
  invoicePot: string;
  invoiceIdPot: Guid;
  totalPot: number;
  typeHutang: string;
  typePot: Guid;
  typePotText: string;
  public dt: any;
  public cb: any;
  public dtPay: any;
  public cbPay: any;
  public dataTripDto: any;
  total: number = 0;
  invoiceNo : string;
  rekBank : string;
  payment: Boolean = false;
  listType : CostType[] = [];
  listCostType: MasterCode[] = [];
  modelType: MasterCode;
  typePayment: string;
  @Input() paymentModel = { payment_status: null, payment_id: null, payment_date: null, bank: null, payment_no: null, payment_type: null, relation : null, total : null, paymentDetailList: new Array(), paymentDeleteList: new Array()}
  
  constructor( public service: TransportService, private router: Router, private spinner: NgxSpinnerService, fb: FormBuilder, private changeDetectorRef: ChangeDetectorRef) {
  }

    ngOnInit(): void {
      this.getListType();
      this.service.getListBank().subscribe(resp => {
        this.dataBank = resp;
      })
      this.dtOptionsPayment = {  
        retrieve: true,
        pageLength: 10,
        ordering : false,
        scrollX: true,
        scrollCollapse: true,
        serverSide: true,
        processing: true,
        searching : false,
        ajax: (dataTablesParameters: any, callback) => {
         this.dtPay = dataTablesParameters; this.cbPay = callback;
         this.getListPayment(dataTablesParameters, callback, $('#payment_type').val().toString(), $('#relation_name').val().toString(), 
         $('#payment_no').val().toString(),$('#status_name').val().toString(),  true);

        },
        columnDefs: [
          { width: 100, targets: [0,1,2,3,4,5,6,8,10,11,12]},
          { width: 300, targets: 7},
        ],
      };

      this.dtOptionsCustomer = {
        retrieve: true,
        pageLength: 5,
        scrollX: true,
        scrollCollapse: true,
        serverSide: true,
        processing: true,
        searching : false,
        ajax: (dataTablesParameters: any, callback) => {
         this.dt = dataTablesParameters; this.cb = callback;
         this.getDataRelation(dataTablesParameters, callback, $('#codecustomer').val().toString(), $('#namecustomer').val().toString(), 
         $('#addresscustomer').val().toString(), "CUSTOMER",
         $('#slcustomer').val().toString(),true);
        },
        columnDefs: [
          { width: 100, targets: [0,1,4,5,6,7,8,9,10] },
          { width: 200, targets: 2 },
          { width: 300, targets: 3 },
        ],
      };

      const that = this;

      $('.searchInput').on("keyup", function(e) {
            
        var key = e.which;
        if (key == 13) // the enter key ascii code
        {
          that.getListPayment(that.dtPay, that.cbPay, $('#payment_type').val().toString(), $('#relation_name').val().toString(), 
          $('#payment_no').val().toString(),$('#status_name').val().toString(), false);
        }
      });

      $('.searchCustomer').on("keyup", function(e) {
        var key = e.which;
        if (key == 13) // the enter key ascii code
        {
          that.getDataRelation(that.dt, that.cb, $('#codecustomer').val().toString(), $('#namecustomer').val().toString(), 
          $('#addresscustomer').val().toString(), "CUSTOMER",
          $('#slcustomer').val().toString(),false);
        }
      });  

      this.service.getListMasterCode("PaymentType").subscribe( resp => {
        this.listCostType = resp;
      })
      
    }   
    
    ngAfterViewInit(): void {
      this.dtTriggerPayment.next(0);
    }
  
    ngOnDestroy(): void {
     this.dtTriggerPayment.unsubscribe();
    }

    onSelectType(data : MasterCode){
      this.paymentModel.payment_type = data.id;
      this.typePayment = data.text;
      if(data.text == "Hutang(AP)"){
        this.getDataRelation(this.dt, this.cb, $('#codecustomer').val().toString(), $('#namecustomer').val().toString(), 
         $('#addresscustomer').val().toString(), "EKSPEDISI",
         $('#slcustomer').val().toString(),true);
         
      }
      else{
        this.getDataRelation(this.dt, this.cb, $('#codecustomer').val().toString(), $('#namecustomer').val().toString(), 
         $('#addresscustomer').val().toString(), "CUSTOMER",
         $('#slcustomer').val().toString(),true);
      }
    }

    getListPayment(dataTablesParameters, callback, type: string, customer: string, no: string, status: string, padging: Boolean){
    
    var page = 1;
      if (padging == true){
        page = parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length) + 1;
      }

      this.service.getListPayment(dataTablesParameters, page, dataTablesParameters.length, type, customer, no, status)
      .subscribe(resp => { 
          this.dataListPayment = resp.data;
          callback({
            recordsTotal: resp.totalCount,
            recordsFiltered: resp.totalCount,
             data:  []
          });
      });
  }

  getDataRelation(dataTablesParameters, callback, relation_code: string, relation_name: string, relation_address: string, 
    relation_type: string, relation_sl_code: string, padging : boolean){ 
    var page = 1;
    if (padging == true){
      page = parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length) + 1;
    }
    this.service.getListRelation(dataTablesParameters, page, dataTablesParameters.length, relation_code,
      relation_name, relation_address, relation_type, relation_sl_code)
      .subscribe(resp => { 
        this.dataRelation = resp.data;
        callback({
          recordsTotal: resp.totalCount,
          recordsFiltered: resp.totalCount,
           data:  []
        });
    });
  }

  setNull(){
    this.payment = false;
    this.paymentModel = { payment_status : null, payment_id: null, payment_date: null, bank: null, payment_no: null, payment_type: null, relation : null, total : null, paymentDetailList: new Array(), paymentDeleteList: new Array()}
    this.customer = null;
    this.dataInvoice = [];
    this.dataTambahan = [];
    this.total = 0;
  }

  saveAndPayment(){
    this.paymentModel.paymentDetailList = [];
    for (let i = 0; i < this.dataTambahan.length; i++) {
      this.paymentModel.paymentDetailList.push({
        payment_detail_id : this.dataTambahan[i].payment_detail_id,
        invoice_id : this.dataTambahan[i].invoice_id,
        invoice_no : this.dataTambahan[i].invoice_no,
        cost_type_id : this.dataTambahan[i].cost_type_id,
        cost_type_text : this.dataTambahan[i].cost_type_text,
        amount : this.dataTambahan[i].amount
      });
    }
    this.paymentModel.total = this.total;
      Swal.fire({
        title: 'Simpan Data?',
        type: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: '      Yes     '
        }).then((result) => {
          if (result.value) {
            this.spinner.show();
            setTimeout(() => {
              this.spinner.hide();
            }, 5000);
            if (this.paymentModel.payment_id == null){
                this.service.addDataPayment(this.paymentModel).subscribe( resp => {
                  this.spinner.hide();
                  if(resp.succes == false){
                    Swal.fire({
                      title: ""+resp.text,
                      type: 'warning',
                      confirmButtonText: '      Ok!     '
                    });
                  }
                  else{
                    this.service.confirmPayment(this.paymentModel).subscribe( resp => {
                      if(resp.succes == false){
                        Swal.fire({
                          title: ""+resp.text,
                          type: 'warning',
                          confirmButtonText: '      Ok!     '
                        });
                      }
                      else{
                        Swal.fire({
                          title: ""+resp.text,
                          type: 'success',
                          confirmButtonText: '      Ok!     '
                        }).then((result) => {
                          if (result.value) { 
                            this.getListPayment(this.dtPay, this.cbPay, "", "","", "",  true);
                            this.modalPaymentClose.nativeElement.click();
                            this.modalSimpanClose.nativeElement.click();                            
                          }              
                        });
                      }
                    });
                  }
                })
              }
              else{
                this.service.confirmPayment(this.paymentModel).subscribe( resp => {
                  if(resp.succes == false){
                    Swal.fire({
                      title: ""+resp.text,
                      type: 'warning',
                      confirmButtonText: '      Ok!     '
                    });
                  }
                  else{
                    Swal.fire({
                      title: ""+resp.text,
                      type: 'success',
                      confirmButtonText: '      Ok!     '
                    }).then((result) => {
                      if (result.value) { 
                        this.getListPayment(this.dtPay, this.cbPay, "", "","", "",  true);
                        this.modalPaymentClose.nativeElement.click();
                        this.modalSimpanClose.nativeElement.click();
                      }              
                    });
                  }
                });
              }
            }

     });
  }

  getListType(){
    this.service.getListCostTypeAll(null).subscribe(resp => {
      this.listType = resp.filter(x => x.screen_text == "INVOICE");
      
    })
  }

  onSelectBank(data: LocationSettingBank){
    this.paymentModel.bank = data.id;
    this.rekBank = data.account_number;
  }

  onSelectDataTambahan(data: any){
    this.dataTambahanPot = data;
    this.invoicePot = data.invoice_no;
    this.invoiceIdPot = data.invoice_id;
  }


  saveData(){
    if (this.dataTambahan == null || this.paymentModel.payment_no == null  || this.paymentModel.payment_type == null  || this.customer == null){
      Swal.fire({
        title: "Data Belum Lengkap !",
        type: 'warning',
        confirmButtonText: '      Ok!     '
      });
      return;
    }

    Swal.fire({
      title: 'Simpan Pembayaran?',
      type: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '      Yes     '
      }).then((result) => {
        if (result.value) {
          this.paymentModel.paymentDetailList = [];
          for (let i = 0; i < this.dataTambahan.length; i++) {
            this.paymentModel.paymentDetailList.push({
              payment_detail_id : this.dataTambahan[i].payment_detail_id,
              invoice_id : this.dataTambahan[i].invoice_id,
              invoice_no : this.dataTambahan[i].invoice_no,
              cost_type_id : this.dataTambahan[i].cost_type_id,
              cost_type_text : this.dataTambahan[i].cost_type_text,
              amount : this.dataTambahan[i].amount
            });
          }
          this.paymentModel.paymentDeleteList = [];
          for (let i = 0; i < this.deleteDataTambahanList.length; i++) {
            this.paymentModel.paymentDeleteList.push({
              payment_detail_id : this.deleteDataTambahanList[i].payment_detail_id,
              invoice_id : this.deleteDataTambahanList[i].invoice_id,
              invoice_no : this.deleteDataTambahanList[i].invoice_no,
              cost_type_id : this.deleteDataTambahanList[i].cost_type_id,
              cost_type_text : this.deleteDataTambahanList[i].cost_type_text,
              amount : this.deleteDataTambahanList[i].amount
            });
          }
          this.paymentModel.total = this.total;
          this.spinner.show();
          setTimeout(() => {
            this.spinner.hide();
          }, 5000);
          if(this.save == true){
            this.service.addDataPayment(this.paymentModel).subscribe( resp => {
              this.spinner.hide();
              if(resp.succes == false){
                Swal.fire({
                  title: ""+resp.text,
                  type: 'warning',
                  confirmButtonText: '      Ok!     '
                });
              }
              else{
                Swal.fire({
                  title: ""+resp.text,
                  type: 'success',
                  confirmButtonText: '      Ok!     '
                }).then((result) => {
                  if (result.value) { 
                    this.getListPayment(this.dtPay, this.cbPay, "", "","", "",  true);
                    this.modalSimpanClose.nativeElement.click();
                  }              
                });
              }
            })
          }
          else{
            this.service.updateDataPayment(this.paymentModel).subscribe( resp => {
              this.spinner.hide();
              if(resp.succes == false){
                Swal.fire({
                  title: ""+resp.text,
                  type: 'warning',
                  confirmButtonText: '      Ok!     '
                });
              }
              else{
                Swal.fire({
                  title: ""+resp.text,
                  type: 'success',
                  confirmButtonText: '      Ok!     '
                }).then((result) => {
                  if (result.value) { 
                    this.getListPayment(this.dtPay, this.cbPay,"", "","", "",  true);
                    this.modalSimpanClose.nativeElement.click();
                  }              
                });
              }
            })
          }
          
        }

      });

  }


  onSelectCustomer(data: Relation){
    this.paymentModel.relation = data.relation_id;
    this.customer = data.relation_name;
    this.service.getListBoxInvoice(data.relation_id, this.paymentModel.payment_type).subscribe(resp => {
      this.dataInvoice = resp;
    })
  }

  addDataTambahan(){
    if (this.paymentDto == null){
      Swal.fire({
        title: "Data Invoice Masih Kosong !",
        type: 'warning',
        confirmButtonText: '      Ok!     '
      });
      return;
    }
    let data = this.dataTambahan.find(ob => ob.invoice_id == this.paymentDto.invoice_id);
    if(data != undefined){
      Swal.fire({
        title: "Data Sudah Ada Dalam Tabel !",
        type: 'warning',
        confirmButtonText: '      Ok!     '
      });
      return; 
    }
    this.dataTambahan.push({payment_detail_id: null, type_text: "PIUTANG", invoice_id: this.paymentDto.invoice_id, invoice_no: this.paymentDto.invoice_no, cost_type_id: this.paymentDto.cost_type_id, 
      cost_type_text: this.paymentDto.cost_type_text, amount: this.paymentDto.amount, remarks: this.paymentDto.remarks});
      this.total = this.total + this.paymentDto.amount;
  }

  onSelectPayment(data: ListPayment){
    
    this.service.getDetailPayment(data.id).subscribe( resp => {
      this.dataTambahan = resp;
      // this.total = resp.reduce((sum, obj) => {
      //   return sum + obj.amount;
      // }, 0);
      this.total = data.pay_amount;
    } )
    this.paymentModel.payment_id = data.id
    this.paymentModel.payment_no = data.payment_no;
    this.paymentModel.payment_type = data.payment_type_id;
    this.paymentModel.total = this.total;
    this.paymentModel.relation = data.relation_id;
    this.customer = data.relation_name;
    this.paymentModel.payment_status = data.status_name;
    this.service.getListBoxInvoice(data.relation_id, this.paymentModel.payment_type).subscribe(resp => {
      this.dataInvoice = resp;
    })
    if (this.paymentModel.payment_status == "Payment Complete")
    {
      this.payment = true;
    }
    else{
      this.payment = false;
    }
  }

  deleteDataTambahan(id: number){
      this.dataTambahan.splice(id, 1); this.changeDetectorRef.detectChanges(); 
  } 

  minTotal(dataTambahan: any){
    if(this.save != true && dataTambahan.payment_detail_id != null){
      this.deleteDataTambahanList.push({payment_detail_id: dataTambahan.payment_detail_id, type_text: dataTambahan.type_text, invoice_id: dataTambahan.invoice_id, 
        invoice_no: dataTambahan.invoice_no, cost_type_id: dataTambahan.cost_type_id, cost_type_text: dataTambahan.cost_type_text,
        amount: dataTambahan.amount, remarks: dataTambahan.remarks});
    }
    if(dataTambahan.type_text = "PIUTANG"){
      this.total = this.total + dataTambahan.amount;
    }
    else if(dataTambahan.type_text = "HUTANG"){
      this.total = this.total - dataTambahan.amount;
    }
  }
  
  onSelectInvoice(data: PaymentDetailDto){
    this.paymentDto = data;
  }

  showCustomer(){
    this.dtTriggerCustomer.next(0);
    if(this.typePayment != null){
      this.modalCustomerShow.nativeElement.click();
    }
    else{
      return  Swal.fire({
        title: "Mohon Pilih Pembayaran!",
        type: 'warning',
        confirmButtonText: '      Ok!     '
      });
    }
  }

  deleteData(data: ListPayment){
    Swal.fire({
      title: 'Delete Data?',
      type: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '      Yes     '
      }).then((result) => {
        if (result.value) {
          this.spinner.show();
          setTimeout(() => {
            this.spinner.hide();
          }, 5000);
          this.service.deletePayment(data.id).subscribe(resp => {
            this.spinner.hide();
              if(resp.succes == false){
                Swal.fire({
                  title: ""+resp.text,
                  type: 'warning',
                  confirmButtonText: '      Ok!     '
                });
              }
              else{
                Swal.fire({
                  title: ""+resp.text,
                  type: 'success',
                  confirmButtonText: '      Ok!     '
                }).then((result) => {
                  if (result.value) { 
                    this.getListPayment(this.dtPay, this.cbPay, "", "","", "",  true);
                  }              
                });
              }
          })

        }
    })
  }

  messageWarning(text: string){
    Swal.fire({
      title: ""+text,
      type: 'warning',
      confirmButtonText: '      Ok!     '
    });
    return;
  }

  setSave(){
    this.save = true;
  }
  setUpdate(){
    this.save = false;
  }

  onSelectTypePot(data : CostType){
    this.typePot = data.id;
    this.typeHutang = data.type_text;
    this.typePotText = data.name;
  }

  addDataPotongan(){
    if (this.invoicePot == null || this.typePot == null || this.totalPot == null ){
      Swal.fire({
        title: "Data Belum Lengkap !",
        type: 'warning',
        confirmButtonText: '      Ok!     '
      });
      return;
    }

    this.dataTambahan.push({payment_detail_id: this.dataTambahanPot.payment_detail_id, type_text: this.typeHutang, invoice_id: this.dataTambahanPot.invoice_id, 
      invoice_no: this.dataTambahanPot.invoice_no, cost_type_id: this.dataTambahanPot.cost_type_id, cost_type_text: this.typePotText,
      amount: this.totalPot, remarks: this.dataTambahanPot.remarks});

      if(this.typeHutang == "PIUTANG"){
        this.total = this.total + this.totalPot;
      }
      else if(this.typeHutang == "HUTANG"){
        this.total = this.total - this.totalPot;
      }


      this.modalPotonganClose.nativeElement.click();

  }




  
}




