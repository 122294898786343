import { Guid } from "guid-typescript";

export class MasterMenu {
  menuId : Guid;
  menu : string;
  text : string;
}

export class MasterAuth {
  id : Guid;
  text : string;
}

export class SubLocation {
  locationSub : string;
}



