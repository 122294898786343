<section class="content">    
    <div class="card">
        <div class="card-header">
          <strong>List Role</strong>
          <div class="float-right">
          <a type="button" (click)="setNull();ModalSimpan.show()" class="btn btn-sm btn-primary pull-right" data-toggle="modal">&nbsp;<b>Input SPK</b>&nbsp;&nbsp;<i class="fa fa-pen"></i>&nbsp;</a>
          </div>
        </div>
        <div class="card-body">
            <table datatable [dtOptions]="dtOptionsAcc" [dtTrigger]="dtTriggerAcc" class="deeps table-striped">
                <thead>
                  <tr>
                    <th>Action</th>
                    <th>No SPK</th>
                    <th>Truk</th>
                    <th>Supir</th>
                    <th>Status</th>
                    <th>Dibuat Oleh</th>
                    <th>Dibuat Pada</th>
                    <th>Diubah Oleh</th>
                    <th>Diubah Pada</th>
                  </tr>
                </thead>
                 <tbody>
                    <tr *ngFor="let row of dataListSPK">    
                      <td>
                        <button (click)="onSelectDetailSPK(row);ModalSimpan.show()" title="Detail" class="btn btn-sm btn-primary" triggers="mouseenter:mouseleave"><i class="fa fa-list"></i></button>&nbsp;
                        <button [hidden]="row.status != 'NEW'" (click)="onSelectUpdate(row);ModalSimpan.show()" title="Detail" class="btn btn-sm btn-primary" triggers="mouseenter:mouseleave"><i class="fa fa-pen"></i></button>&nbsp;
                        <!-- <button [hidden]="row.status != 'NEW'" (click)="onSelectProcess(row);ModalProses.show()" class="btn btn-sm btn-primary"  data-toggle="modal" triggers="mouseenter:mouseleave"><i class="fa fa-play-circle"></i></button>&nbsp; -->
                        <button [hidden]="row.status != 'PROCESS'" (click)="finish(row);" class="btn btn-sm btn-warning"  data-toggle="modal" triggers="mouseenter:mouseleave"><i class="fa fa-flag"></i></button>&nbsp;
                        <button (click)="printSPK(row);" title="Print" class="btn btn-sm btn-primary" triggers="mouseenter:mouseleave"><i class="fa fa-print"></i></button>&nbsp;
                        <!-- <button class="btn btn-sm btn-danger" title="Delete" triggers="mouseenter:mouseleave"><i class="fa fa-trash"></i></button>&nbsp; -->
                      </td>       
                      <td>{{row.spkNo}}</td>       
                      <td>{{row.truckNo}}</td>       
                      <td>{{row.driverName}}</td>         
                      <td>{{row.status}}</td>    
                      <td>{{row.createdName}}</td>
                      <td>{{row.createdAt | slice:0:10}}</td>
                      <td>{{row.updatedName}}</td>
                      <td>{{row.createdAt | slice:0:10}}</td>
                   </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <th><input hidden class="searchInputS"/></th>
                      <th><input id="spkNo" type="text" class="searchInputS" placeholder="No SPK"/></th>
                      <th><input id="truckNo" type="text" class="searchInputS" placeholder="Truk"/></th>
                      <th><input id="driverName" type="text" class="searchInputS" placeholder="Nama Supir"/></th>
                      <th><input id="status" type="text" class="searchInputS" placeholder="Status"/></th>
                      <th><input hidden class="searchInputS"/></th>
                      <th><input hidden class="searchInputS"/></th>
                      <th><input hidden class="searchInputS"/></th>
                      <th><input hidden class="searchInputS"/></th>
                    </tr>
                  </tfoot>
              </table>
        </div>
    </div>
  </section>

  <div  bsModal #ModalSimpan="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog" style="max-width: 80%;" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title">
            <div class="row">
              <strong>SPK Service</strong>
            </div>
          </div>
          <button type="button" class="close" (click)="ModalSimpan.hide()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group row">
                          <label class="col-md-3 col-form-label" for="text-input">NO SPK</label>
                          <div class="col-md-8">
                              <input disabled type="text" [(ngModel)]="spkModel.SpkNo" value="Test1234" class="form-control">
                          </div>
                        </div>
                        <div class="form-group row">
                          <label class="col-md-3 col-form-label" for="text-input">Truck</label>
                          <input hidden type="text" class="form-control">
                          <div class="col-md-7">
                            <input [(ngModel)]="spkModel.TruckNo" disabled type="text" class="form-control">
                          </div>
                          <div class="col-md-2">
                              <button class="btn btn-sm btn-primary" (click)="showTruck();ModalTruck.show()"
                              data-toggle="modal"><i class="fa fa-plus"></i></button>
                          </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-3 col-form-label" for="text-input">Supir</label>
                            <input hidden type="text" class="form-control">
                            <div class="col-md-7">
                              <input [(ngModel)]="spkModel.DriverName" disabled type="text" class="form-control">
                            </div>
                            <div class="col-md-2">
                                <button class="btn btn-sm btn-primary" 
                                data-toggle="modal" (click)="ModalDriver.show();showDriver();"><i class="fa fa-plus"></i></button>
                            </div>
                          </div>
                    </div>
  
                    <div class="col-md-6">
                        <div class="form-group row group">
                            <label class="col-md-3 col-form-label" for="text-input">Include Ganti Oli</label>
                            <div class="col-md-8">
                                <select [(ngModel)]="spkModel.OilChange" class="form-control">
                                <option value=true><b>Ya</b></option>
                                <option value=false selected><b>Tidak</b></option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-3 col-form-label" for="text-input">Waktu Mulai</label>
                            <div class="col-md-8">
                                <input [(ngModel)]="spkModel.StartDate" type="datetime-local" class="form-control">
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-3 col-form-label" for="text-input">Waktu Selesai</label>
                            <div class="col-md-8">
                                <input [(ngModel)]="spkModel.EndDate" type="datetime-local" class="form-control">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
            <br>
            <div class="card">
              <div class="card-body">
                <!-- <div class="row">
                    <div class="col-md-6">
                        <div class="form-group row">
                          <label class="col-md-3 col-form-label" for="text-input">Remark Perbaikan</label>
                          <div class="col-md-8">
                              <input type="text" [(ngModel)]="spkDetailModel.remark" class="form-control">
                          </div>
                        </div>
                    </div>
                     <div class="col-md-3">
                        <div class="form-group row">
                            <button (click)="addSPkDetail()" type="button" class="btn btn-sm btn-primary">&nbsp;<b>Tambah</b>&nbsp;</button>
                        </div>
                    </div> 
                </div>
                <br> -->
                <div class="card">
                    <div class="card-header">
                        <div class="float-right">
                            <a type="button" class="btn btn-sm btn-warning pull-right" data-toggle="modal" (click)="ModalDetail.show()">&nbsp;<b>Tambah Data</b>&nbsp;&nbsp;<i class="fa fa-plus"></i>&nbsp;</a>
                        </div>
                    </div>
                    <div class="card-body">
                        <table datatable [dtOptions]="dtOptionsDetail" [dtTrigger]="dtTriggerDetail" class="table-striped">
                            <thead>
                            <tr>
                                <th>Action</th>
                                <th>Perbaikan</th>
                                <th>Dibuat Pada</th>
                                <th>Dibuat Oleh</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let row of listSpkDetail; let i=index">    
                                <td>
                                <button (click)="deleteDataDetail(i, row)" title="Detail" class="btn btn-sm btn-danger" triggers="mouseenter:mouseleave"><i class="fa fa-trash"></i></button>&nbsp;
                                </td>       
                                <td>{{row.remark}}</td>
                                <td>{{row.createdAt | slice:0:10}}</td>
                                <td>{{row.createdName}}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
              </div>
            </div>

        </div>
        <div class="modal-footer">
          <button #modalSimpanClose type="button" class="btn btn-secondary" (click)="ModalSimpan.hide()">&nbsp;&nbsp;&nbsp;&nbsp;<b>Close</b>&nbsp;&nbsp;&nbsp;&nbsp;</button>
          <button [disabled]="show" [hidden]="update" type="button" (click)="createSPK()" class="btn btn-primary"><i class="fa fa-save"></i>&nbsp;&nbsp;&nbsp;&nbsp;<b>Simpan</b>&nbsp;&nbsp;&nbsp;&nbsp;</button>
          <button [disabled]="show"  [hidden]="!update" type="button" (click)="updateSPK()" class="btn btn-primary"><i class="fa fa-save"></i>&nbsp;&nbsp;&nbsp;&nbsp;<b>Update</b>&nbsp;&nbsp;&nbsp;&nbsp;</button>
        </div>
      </div>
    </div>
  </div> 

  <div  bsModal #ModalDetail="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog" style="max-width: 50%;" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title">
            <div class="row">
              <strong>Tambah Perbaikan</strong>
            </div>
          </div>
          <button type="button" class="close" (click)="ModalDetail.hide()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-12"> 
                    <div class="form-group row">
                        <label class="col-md-3 col-form-label" for="text-input">Perbaikan</label>
                        <div class="col-md-8">
                            <input [(ngModel)]="spkDetailModel.remark" type="text" class="form-control">
                        </div> 
                    </div>
                </div>
            </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" (click)="ModalDetail.hide()">&nbsp;&nbsp;&nbsp;&nbsp;<b>Close</b>&nbsp;&nbsp;&nbsp;&nbsp;</button>
          <button type="button" class="btn btn-primary" (click)="addSPkDetail();ModalDetail.hide()">&nbsp;&nbsp;&nbsp;&nbsp;<i class="fa fa-save"></i>&nbsp;&nbsp;<b>Simpan</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</button>
        </div>
      </div>
    </div>
  </div> 

  
<div  bsModal #ModalTruck="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog  modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title"><strong>Pilih Truk</strong></div>
        <button type="button" class="close" (click)="ModalTruck.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <table datatable [dtOptions]="dtOptionsTruck" [dtTrigger]="dtTriggerTruck"  class="deeps table-striped">
          <thead>
            <tr>
              <th>Action</th>
              <th>Nomor Polisi</th>
              <th>Kode Kapasitas</th>
              <th>Lokasi Truk</th>
              <th>Nama Ekspedisi</th>
              <th>Dibuat Oleh</th>
              <th>Dibuat Pada</th>
              <th>Diubah Oleh</th>
              <th>Diubah Pada</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let row of dataTruck">
              <td>
                <button (click)="onSelectTruck(row);ModalTruck.hide()" class="btn btn-sm btn-primary"  data-toggle="modal" triggers="mouseenter:mouseleave"><i class="fa fa-check"></i></button>
              </td>
              <td>{{row.truck_plate_number}}</td>
              <td>{{row.truck_capacity_code}}</td>
              <td>{{row.truck_location}}</td>
              <td>{{row.truck_vendor_name}}</td>
              <td>{{row.created_by_text}}</td>
              <td>{{row.created_at_text | slice:0:10}}</td>
              <td>{{row.updated_by_text}}</td>
              <td>{{row.updated_at_text | slice:0:10}}</td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <th><input hidden class="searchInputTruck"/></th>
              <th><input id="platsearch" type="text" class="searchInputTruck" placeholder="Nomor Polisi" name="search-code"/></th>
              <th><input id="codesearch" type="text" class="searchInputTruck" placeholder="Kode Kapasitas" name="search-capacity"/></th>
              <th><input id="locationsearch" type="text" class="searchInputTruck" placeholder="Lokasi Truk" name="search-location"/></th>
              <th><input hidden class="searchInputTruck"/></th>
              <th><input hidden class="searchInputTruck"/></th>
              <th><input hidden class="searchInputTruck"/></th>
              <th><input hidden class="searchInputTruck"/></th>
              <th><input hidden class="searchInputTruck"/></th>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </div>
  </div>

  <div  bsModal #ModalDriver="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog  modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title"><strong>Pilih Supir</strong></div>
          <button type="button" class="close" (click)="ModalDriver.hide()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <table datatable [dtOptions]="dtOptionsDriver" [dtTrigger]="dtTriggerDriver" class="deeps table-striped">
            <thead>
              <tr>
                <th>Action</th>
                <th>Nama</th>
                <th>No SIM</th>
                <th>Jenis SIM</th>
                <th>Agama</th>
                <th>Tempat Lahir</th>
                <th>No KTP</th>
                <th>Tanggal Lahir</th>
                <th>SL</th>
                <th>SL Piutang</th>
                <th>SL Uang Jalan</th>
                <th>Alamat</th>
                <th>Berlaku Sampai</th>
                <th>Kontrak Sampai</th>
                <th>Dibuat Pada</th>
                <th>Diubah Pada</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let row of dataDriver">
                <td>
                  <button (click)="onSelectDriver(row);ModalDriver.hide()" class="btn btn-sm btn-primary"  data-toggle="modal" triggers="mouseenter:mouseleave"><i class="fa fa-check"></i></button>
                </td>
                <td>{{row.driver_name}}</td>
                <td>{{row.driver_license}}</td>
                <td>{{row.driver_license_type}}</td>
                <td>{{row.agama}}</td>
                <td>{{row.birthplace}}</td>
                <td>{{row.ktp_no}}</td>
                <td>{{row.tanggal_lahir | slice:0:10}}</td>
                <td>{{row.sl_code}}</td>
                <td>{{row.sl_code2}}</td>
                <td>{{row.sl_code3}}</td>
                <td>{{row.address}}</td>
                <td>{{row.valid_to | slice:0:10}}</td>
                <td>{{row.valid_to_contract | slice:0:10}}</td>
                <td>{{row.created_at | slice:0:10}}</td>
                <td>{{row.updated_at | slice:0:10}}</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th><input hidden class="searchInputD"/></th>
                <th><input id="driver_name" type="text" class="searchInputD" placeholder="Nama"/></th>
                <th><input id="driver_license" type="text" class="searchInputD" placeholder="No SIM"/></th>
                <th><input id="driver_license_type" type="text" class="searchInputD" placeholder="Tipe SIM"/></th>
                <th><input id="agama" type="text" class="searchInputD" placeholder="Agama"/></th>
                <th><input id="birthplace" type="text" class="searchInputD" placeholder="Tempat Lahir"/></th>
                <th><input id="ktp_no" type="text" class="searchInputD" placeholder="No KTP"/></th>
                <th><input id="tanggal_lahir" type="text" class="searchInputD" placeholder="Tanggal Lahir"/></th>
                <th><input id="sl_code" type="text" class="searchInputD" placeholder="SL"/></th>
                <th><input id="sl_code2" type="text" class="searchInputD" placeholder="SL Piutang"/></th>
                <th><input id="sl_code3" type="text" class="searchInputD" placeholder="SL UJ"/></th>
                <th><input id="address" type="text" class="searchInputD" placeholder="Alamat"/></th>
                <th><input id="valid_to" type="text" class="searchInputD" placeholder="Berlaku Sampai"/></th>
                <th><input id="valid_to_contract" type="text" class="searchInputD" placeholder="Kontrak Sampai"/></th>
                <th><input hidden class="searchInputD"/></th>
                <th><input hidden class="searchInputD"/></th>
              </tr>
            </tfoot>

          </table>
        </div>
      </div>
    </div>
    </div>

    <div  bsModal #ModalProses="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
      <div class="modal-dialog" style="max-width: 50%;" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <div class="modal-title">
              <div class="row">
                <strong>Proses SPK</strong>
              </div>
            </div>
            <button type="button" class="close" (click)="ModalProses.hide()" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="card">
              <div class="card-body">
                  <div class="row">
                      <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-md-3 col-form-label" for="text-input">NO SPK</label>
                            <div class="col-md-8">
                                <input disabled type="text" [(ngModel)]="proccesModel.SpkNo" value="Test1234" class="form-control">
                            </div>
                          </div>
                          <div class="form-group row group">
                            <label class="col-md-3 col-form-label" for="text-input">Kerusakan</label>
                            <div class="col-md-8">
                                <select [(ngModel)]="proccesModel.DamageType" class="form-control">
                                  <option value="Ringan"><b>Ringan</b></option>
                                  <option value="Sedang"><b>Sedang</b></option>
                                  <option value="Berat"><b>Berat</b></option>
                                  <option value="Sangat Berat"><b>Sangat Berat</b></option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group row group">
                          <label class="col-md-3 col-form-label" for="text-input">Bengkel</label>
                          <div class="col-md-8">
                              <select [(ngModel)]="proccesModel.Workshop" class="form-control">
                                <option value="FGS"><b>FGS</b></option>
                                <option value="Bengkel Luar"><b>Bengkel Luar</b></option>
                              </select>
                          </div>
                      </div>
                      </div>
                      <div class="col-md-6">
                          <div class="form-group row">
                              <label class="col-md-3 col-form-label" for="text-input">Truck</label>
                              <div class="col-md-8">
                                  <input [(ngModel)]="proccesModel.TruckNo" disabled type="text" class="form-control">
                              </div>
                          </div>
                          <div class="form-group row">
                              <label class="col-md-3 col-form-label" for="text-input">Keterangan</label>
                              <div class="col-md-8">
                                  <textarea [(ngModel)]="proccesModel.RemarkProcess" cols="8" type="text" class="form-control"></textarea>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button #modalProcessClose type="button" class="btn btn-secondary" (click)="ModalProses.hide()">&nbsp;&nbsp;&nbsp;&nbsp;<b>Close</b>&nbsp;&nbsp;&nbsp;&nbsp;</button>
            <button type="button" class="btn btn-primary" (click)="processSPK();">&nbsp;&nbsp;&nbsp;&nbsp;<i class="fa fa-save"></i>&nbsp;&nbsp;<b>Simpan</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</button>
          </div>
        </div>
      </div>
    </div> 


  <ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>