import { AfterViewInit, Component, OnInit, ViewChild,Input  } from '@angular/core';
import { DataTableDirective } from 'angular-datatables'
import { TransportService} from '../../..//services/api.service';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { Guid } from 'guid-typescript'
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import moment from 'moment';
import { CapacityTolerance } from '@/model/capacityTolerance';
import { Material } from '@/model/material';
import { Capacity } from '@/model/capacity';
import { MasterCapacityComponent } from '../master-capacity/master-capacity.component';
import { MasterMaterialComponent } from '../master-material/master-material.component';
import { MasterCode } from '@/model/masterCode';
import { ListCapacityTolerance } from '@/model/listCapacityTolerance';
import { Router } from '@angular/router';

@Component({
  selector: 'app-master-tolerance',
  templateUrl: './master-tolerance.component.html',
  styleUrls: ['./master-tolerance.component.scss'],
  providers: [MasterCapacityComponent, MasterMaterialComponent],
})
export class MasterToleranceComponent implements OnInit, AfterViewInit {
  toleranceForm: FormGroup;
  @ViewChild('modalSaveClose') modalClose;
  @ViewChild('ModalDriverShow') ModalShow;
  
  @ViewChild(DataTableDirective, {static: false})
  datatableElement: DataTableDirective;
  dtOptions: any = {};
  dtOptions2: any = {};
  dtOptions3: any = {};
  dataTolerance: CapacityTolerance[] = [];
  public dataType: MasterCode[] = [];
  dtTrigger = new Subject();
  dtTrigger2 = new Subject();
  dtTrigger3 = new Subject();
  @Input() toleranceModel = { id : Guid, material_id : null, capacity_id : null,
  tolerance : 0, uom : null, };
  action: string;
  public dt: any;
  public cb: any;
  public materialSelect: string;
  public capacitySelect: string;

  constructor( public service: TransportService, private router: Router,  private spinner: NgxSpinnerService, fb: FormBuilder
    , public componentMaterial: MasterMaterialComponent, public componentCapacity: MasterCapacityComponent) {
  }

    ngOnInit(): void {
      const menuUser = localStorage.getItem("menu");
      if (menuUser != "All"){
        const subMenu = localStorage.getItem("acces_menu");
        let arrayMenu =  subMenu.split(",");
        if (arrayMenu.includes("tolerance") == false){
          this.router.navigate(["/home"]);
        }
      }
      this.dtOptions = {
        responsive:true,
        serverSide: true,
        processing: true,
        searching : false,
        ordering : false,
        ajax: (dataTablesParameters: any, callback) => {
          this.dt = dataTablesParameters; this.cb = callback;
          this.getDataTolerance(dataTablesParameters, callback, $('#tolerance').val().toString(), $('#material_name').val().toString(), 
          $('#capacity_code').val().toString(),$('#uom_name').val().toString(),  true);
         },
         columnDefs: [
          { width: 110, targets: [0,1,4,5,6,7,8] },
          { width: 200, targets: [2,3] }
        ]
        };

        this.dtOptions2 = {
          fixedColumn : true,
          pageLength: 5,
          serverSide: true,
          processing: true,
          searching : false,
          scrollX: true,
          scrollCollapse: true,
          ajax: (dataTablesParameters: any, callback) => {
            this.dt = dataTablesParameters; this.cb = callback;
            this.componentMaterial.getDataMaterial(dataTablesParameters, callback, $('#codeMaterialsearch').val().toString(), $('#nameMaterialasearch').val().toString(),true);
           },
           columnDefs: [
            { width: 100, targets: [0,1,4,5,6,7,8] },
            { width: 200, targets: [2,3] }
          ]
        };

        this.dtOptions3 = {
          fixedColumn : true,
          pageLength: 5,
          serverSide: true,
          processing: true,
          searching : false,
          scrollX: true,
          scrollCollapse: true,
          ajax: (dataTablesParameters: any, callback) => {
            this.dt = dataTablesParameters; this.cb = callback;
            this.componentCapacity.getDataCapacity(dataTablesParameters, callback, $('#codecapacity').val().toString(), $('#namecapacity').val().toString(),
            $('#vehiclesearch').val().toString(),true);
           },
           columnDefs: [
            { width: 100, targets: [0,1,2,3,4,5,6] }
          ]
        };

      const that = this;
      $('.searchInput').on("keyup", function(e) {
        var key = e.which;
        if (key == 13) // the enter key ascii code
        {
          that.getDataTolerance(that.dt, that.cb, $('#tolerance').val().toString(), $('#material_name').val().toString(), 
          $('#capacity_code').val().toString(),$('#uom_name').val().toString(), false);
        }
      }); 

      $('.searchInputMaterial').on("keyup", function(e) {
        var key = e.which;
        if (key == 13) // the enter key ascii code
        {
          that.componentMaterial.getDataMaterial(that.dt, that.cb, $('#codeMaterialsearch').val().toString(), $('#nameMaterialasearch').val().toString(), false);
        }
      });

      $('.searchInputCapacity').on("keyup", function(e) {
        var key = e.which;
        if (key == 13) // the enter key ascii code
        {
          that.componentCapacity.getDataCapacity(that.dt, that.cb, $('#codecapacity').val().toString(), $('#namecapacity').val().toString(),
          $('#vehiclesearch').val().toString(), false);
        }
      });
      this.getDataType();
    }   

    getDataTolerance(dataTablesParameters, callback, tolerance: string, material_name: string, capacity_code: string, uom_name: string, padging : boolean){ 
      var page = 1;
      if (padging == true){
        page = parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length) + 1;
      }
     
      this.service.getListCapacityTolerance(dataTablesParameters, page, dataTablesParameters.length, tolerance, material_name, capacity_code, uom_name)
      .subscribe(resp => { 
          this.dataTolerance = resp.data;
          callback({
            recordsTotal: resp.totalCount,
            recordsFiltered: resp.totalCount,
            // data:  resp.data
            data:  []
          });
      });
    }

    getDataType(){
      this.service.getListMasterCode("UOM").subscribe(resp => {
        this.dataType = resp;
      })
    }
    
    ngAfterViewInit(): void {
      this.dtTrigger.next(0);
      this.dtTrigger2.next(0);
      this.dtTrigger3.next(0);
    }
  
    ngOnDestroy(): void {
      this.dtTrigger.unsubscribe();
      this.dtTrigger2.unsubscribe();
      this.dtTrigger3.unsubscribe();
    }
  
    rerender(): void {
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtTrigger.next(0);
        this.dtTrigger2.next(0);
        this.dtTrigger3.next(0);
      });
    }

    onSelectTolerance(tolerance: any) {
      this.action = "Update";
      this.materialSelect = tolerance.material_name;
      this.capacitySelect = tolerance.capacity_code;
      this.toleranceModel.id = tolerance.id;
      this.toleranceModel.capacity_id = tolerance.capacity_id;
      this.toleranceModel.material_id = tolerance.material_id;
      this.toleranceModel.tolerance = tolerance.tolerance;
      this.toleranceModel.uom = tolerance.uom;
    }

    onSelectMaterial(material: Material) {
      this.materialSelect = material.material_name;
      this.toleranceModel.material_id = material.material_id;
    }

    onSelectCapacity(capacity: Capacity) {
      this.capacitySelect = capacity.capacity_code;
      this.toleranceModel.capacity_id = capacity.capacity_id;
    }
  
  saveDataTolerance(){
    if (this.toleranceModel.material_id == null || this.toleranceModel.capacity_id == null || 
        this.toleranceModel.tolerance == null || this.toleranceModel.uom == null){
          return Swal.fire({
            title: "Data Belum Lengkap!",
            type: 'warning',
            confirmButtonText: '      Ok!     '
          });
      }
    Swal.fire({
      title: 'Simpan Data?',
      type: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '      Yes     '
    }).then((result) => {
      if (result.value) {
        this.spinner.show();
        this.service.addDataTolerance(this.toleranceModel).subscribe(resp => {
          this.spinner.hide();
          if(resp.succes == false){
            Swal.fire({
              title: ""+resp.text,
              type: 'warning',
              confirmButtonText: '      Ok!     '
            });
          }
          else{
            Swal.fire({
              title: ""+resp.text,
              type: 'success',
              confirmButtonText: '      Ok!     '
            }).then((result) => {
              if (result.value) { 
                this.getDataTolerance(this.dt, this.cb, "", "","","", true);
                this.modalClose.nativeElement.click();
              }              
            });
          }
        });
      }
    })
  }

  deleteTolerance(data: any){
    Swal.fire({
      title: 'Delete Data?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '      Yes     '
    }).then((result) => {
      if (result.value) {
        this.spinner.show();
        this.service.DeleteTolerance(data.id).subscribe(verelationhicle => { 
          this.spinner.hide();
          Swal.fire({
            type: 'success',
            confirmButtonText: '      Succes!     '
          }).then((result) => {
            if (result.value) { 
              this.getDataTolerance(this.dt, this.cb, "", "","","", true);
            }
          
          }, (error) => {
            //; 
          })
        });
      }
    
    }, (error) => {
      //; 
    });
  }

  
  clearFormVehicle(){
    this.action = "Input";
    this.toleranceForm.reset();

  }
    

      
}



