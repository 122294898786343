<!-- <form>
    <div class="multiselect">
      <div class="selectBox" (click)="showCheckboxes()">
        <select>
          <option>Select an option</option>
        </select>
        <div class="overSelect"></div>
      </div>
      <div id="checkboxes">
        <label for="one">
          <input type="checkbox" id="one" />First checkbox</label>
        <label for="two">
          <input type="checkbox" id="two" />Second checkbox</label>
        <label for="three">
          <input type="checkbox" id="three" />Third checkbox</label>
      </div>
    </div>
</form> -->

<section class="content">    
  <div class="card">
    <div class="card-header">
      <strong>Rekap Susut</strong>
      <div class="float-right">
      <a type="button" class="btn btn-sm btn-primary pull-right" data-toggle="modal" (click)="printSpsSusut()">&nbsp;&nbsp;&nbsp;<b>Export</b>&nbsp;&nbsp;&nbsp;&nbsp;<i class="fa fa-print"></i>&nbsp;&nbsp;&nbsp;</a>
      </div>
    </div>
      <div class="card-body">
          <table datatable [dtOptions]="dtOptionsPay" [dtTrigger]="dtTriggerPay" class="table-striped">
              <thead>
                <tr>
                  <th>Tanggal Susut</th>
                  <th>No SPS</th>
                  <th>Nama Sopir</th>
                  <th>Kode Supir</th>
                  <th>Claim</th>
                  <th>Nilai SPS</th>
                  <th>Sisa Hutang</th>
                  <th>Status</th>
                  <th>Commodity</th>
                  <th>Asal PKS</th>
                  <th>Tujuan</th>
                  <th>Total Susut</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let row of driverShrinkageRecapVm">         
                  <td>{{row.tanggal_Susut | slice:0:10}}</td>
                  <td>{{row.no_SPS}}</td>
                  <td>{{row.nama_Sopir}}</td>
                  <td>{{row.kode_Supir}}</td>
                  <td>{{row.claim | number}}</td>
                  <td>{{row.nilai_SPS | number}}</td>
                  <td>{{row.sisa_Hutang | number}}</td>
                  <td>{{row.status}}</td>
                  <td>{{row.commodity}}</td>
                  <td>{{row.asal_PKS}}</td>
                  <td>{{row.tujuan}}</td>
                  <td>{{row.total_Susut}}</td>
              </tr>
              </tbody>
              <!-- <tfoot>
                  <tr>
                      <th><input hidden class="searchInput"/></th>
                      <th><input id="driver_name" type="text" class="searchInput" placeholder="Nama Driver"/></th>
                      <th><input id="driver_license" type="text" class="searchInput" placeholder="No SIM"/></th>
                      <th><input id="trx_reff" type="text" class="searchInput" placeholder="No DO"/></th>
                      <th><input id="type_name" type="text" class="searchInput" placeholder="Type"/></th>
                      <th><input hidden class="searchInput"/></th>
                      <th><input hidden class="searchInput"/></th>
                      <th><input hidden class="searchInput"/></th>
                  </tr>
              </tfoot> -->
            </table>
      </div>
  </div>
</section>

 

<ngx-spinner
bdColor="rgba(51,51,51,0.8)"
size="medium"
color="#fff"
type="ball-scale-multiple"
>
<p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>