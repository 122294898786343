import { AfterViewInit, Component, OnInit, ViewChild,Input  } from '@angular/core';
import { DataTableDirective } from 'angular-datatables'
import { TransportService} from '../../..//services/api.service';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { Guid } from 'guid-typescript'
import { Vehicle } from '@/model/vehicle';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { DebtPriority } from '@/model/debtPriority';
import { MasterCode } from '@/model/masterCode';
import { AppService } from '@services/app.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-master-debtpriority',
  templateUrl: './master-debtpriority.component.html',
  styleUrls: ['./master-debtpriority.component.scss']
})
export class MasterDebtpriorityComponent implements OnInit, AfterViewInit {
  debtForm: FormGroup;
  @ViewChild('modalSaveClose') modalClose;
  @ViewChild(DataTableDirective, {static: false})
  datatableElement: DataTableDirective;
  dtOptions: any = {};
  public debt: DebtPriority;
  public dataType: MasterCode[] = [];
  dataDebt: DebtPriority[] = [];
  printDataDebt: DebtPriority[] = [];;
  dtTrigger = new Subject();
  @Input() debtModel = { id : null, code: '', priority: 0, debt_name: '', sequence_name:''};
  action: string;
  public dt: any;
  public cb: any;

  constructor( public service: TransportService, private router: Router,  private spinner: NgxSpinnerService, fb: FormBuilder, private appServ : AppService) {
    this.debtForm = fb.group({
      'code': [null, Validators.required],
      'priority': [null, Validators.required],
      'debt_name': [null, Validators.required],
      'sequence_name': [null, Validators.required],
      'terms': [false]
    });
  }

    ngOnInit(): void {
      const menuUser = localStorage.getItem("menu");
      if (menuUser != "All"){
        const subMenu = localStorage.getItem("acces_menu");
        let arrayMenu =  subMenu.split(",");
        if (arrayMenu.includes("debtprriority") == false){
          this.router.navigate(["/home"]);
        }
      }
      this.dtOptions = {
        responsive:true,
        serverSide: true,
        processing: true,
        searching : false,
        ordering : false,
        ajax: (dataTablesParameters: any, callback) => {
         this.dt = dataTablesParameters; this.cb = callback;
         this.getDataDebt(dataTablesParameters, callback, $('#codesearch').val().toString(), $('#namesearch').val().toString(),
         $('#seqsearch').val().toString(),$('#nameseqsearch').val().toString(),true);
        },
        columnDefs: [
          { width: 100, targets: [0,3,4,5,6,7,8] },
          { width: 300, targets: [1,2] }
        ],
      };

      const that = this;
      $('.searchInput').on("keyup", function(e) {
        var key = e.which;
        if (key == 13) // the enter key ascii code
        {
          that.getDataDebt(that.dt, that.cb, $('#codesearch').val().toString(), $('#namesearch').val().toString(),
          $('#seqsearch').val().toString(),$('#nameseqsearch').val().toString(),false);
        }
      });  
      this.getDataType();
      
    }   

    getDataDebt(dataTablesParameters, callback, code: string, debt_name: string, priority: string,  sequence_name: string, padging : boolean){ 
      var page = 1;
      if (padging == true){
        page = parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length) + 1;
      }
      this.service.getDebtPriority(dataTablesParameters, page, dataTablesParameters.length, code, debt_name, priority , sequence_name)
      .subscribe(resp => { 
          this.dataDebt = resp.data;
          callback({
            recordsTotal: resp.totalCount,
            recordsFiltered: resp.totalCount,
             data:  []
          });
      });
    }
    
    ngAfterViewInit(): void {
      this.dtTrigger.next(0);
    }
  
    ngOnDestroy(): void {
      this.dtTrigger.unsubscribe();
    }
  
    rerender(): void {
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtTrigger.next(0);
      });
    }

    onSelectDebt(debt: DebtPriority) {
      this.action = "Update";
      this.debtModel.id = debt.id;
      this.debtModel.code = debt.code;
      //this.vehicleModel.name= vehicle.name;
    }
  
  saveDataDebt(){
    this.service.markFormTouched(this.debtForm);
    if (this.debtForm.valid) {
      Swal.fire({
        title: 'Simpan Data?',
        type: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: '      Yes     '
      }).then((result) => {
        if (result.value) {
          this.spinner.show();
          this.service.addDebt(this.debtModel).subscribe(resp => {
            this.spinner.hide();
            if(resp.succes == false){
              Swal.fire({
                title: ""+resp.text,
                type: 'warning',
                confirmButtonText: '      Ok!     '
              });
            }
            else{
              Swal.fire({
                title: ""+resp.text,
                type: 'success',
                confirmButtonText: '      Ok!     '
              }).then((result) => {
                if (result.value) { 
                  this.getDataDebt(this.dt, this.cb, "", "","","",true);
                  this.modalClose.nativeElement.click();
                  this.debtForm.controls['terms'].setValue(true);
                }              
              });
            }
          });
        }
      })

    } else {
      this.debtForm.controls['terms'].setValue(false);
    }
   
    }

  deleteDebt(debt: DebtPriority){
    Swal.fire({
      title: 'Delete Data?',
      // text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '      Yes     '
    }).then((result) => {
      if (result.value) {
        this.spinner.show();
        this.service.DeleteDebt(debt.id).subscribe(vehicle => { 
          this.spinner.hide();
          Swal.fire({
            type: 'success',
            confirmButtonText: '      Succes!     '
          }).then((result) => {
            if (result.value) { 
              this.getDataDebt(this.dt, this.cb, "", "","","",true);
            }
          
          }, (error) => {
            //; 
          })
        });
      }
    
    }, (error) => {
      //; 
    });
  }

  
  clearFormDebt(){
    this.action = "Input";
    this.debtModel.id = null;
    this.debtModel.code = null;
   // this.vehicleModel.name = null;
  }

  getDataType(){
    this.service.getListMasterCode("NumberType").subscribe(resp => {
      this.dataType = resp;
    })
  }
    
  exportAsXLSX():void {
    this.spinner.show();
    this.service.getDebtPriority(this.dt, 1, 1000000, $('#codesearch').val().toString(), $('#namesearch').val().toString(),
    $('#seqsearch').val().toString(),$('#nameseqsearch').val().toString())
      .subscribe(resp => { 
        this.spinner.hide();
          this.printDataDebt = resp.data;
          this.appServ.exportAsExcelFile(this.printDataDebt, 'Master_Prioritas_Hutang');
      });
  }

      
}


