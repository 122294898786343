import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpEvent, HttpRequest, HttpParams } from '@angular/common/http';
import { Observable, observable } from 'rxjs';
import { Material } from '../model/material';
import { environment } from '../../environments/environment.prod';
import { Guid } from 'guid-typescript';
import { Vehicle, VehicleSelect } from '@/model/vehicle';
import { Ton } from '@/model/tonnase';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { MasterCode } from '@/model/masterCode';
import { Capacity } from '@/model/capacity';
import { Relation } from '@/model/relation';
import { Truck } from '@/model/truck';
import { AssetTruck, TruckAsset } from '@/model/truckAsset';
import { Port } from '@/model/port';
import { Route } from '@/model/route';
import { CostType } from '@/model/costType';
import { DebtPriority } from '@/model/debtPriority';
import { TravelCost } from '@/model/travelCost';
import { DetailTravelCost } from '@/model/detailTravelCost';
import { ListTravelCost, ListTravelCostRaw } from '@/model/listTravelCost';
import { CapacityTolerance } from '@/model/capacityTolerance';
import { Kebun } from '@/model/kebun';
import { RespCheckDo, RespDto, RespDtoBBM, RespDtoInvoice, RespDtoSusut, RespJournalDebitNoteDto, RespJournalDto, RespJournalInvOnlyDto, RespJournalManualInvoiceDto, RespPremi, RespSPKNo, RespSusutTripDto, RespUpdateDO, RespUploadDebitDto, RespUploadDto, RespUploadManualInvoiceDto } from '@/model/respDto';
import { DriverContract } from '@/model/driverContract';
import { SpkDto, SpkDtoRaw } from '@/model/spkDto';
import { MainDO } from '@/model/mainDO';
import { MainAdditionalCost } from '@/model/mainAdditionalCost';
import { ListDoDetail, ListDoDetailApp } from '@/model/listDoDetail';
import { StringDto } from '@/model/stringDto';
import { ListTripRecord, ListTripRecordRaw } from '@/model/listTripRecord';
import { RespDetailDo } from '@/model/respDetailDo';
import { LabelTotal } from '@/model/labelTotal';
import { CapacityPercentage } from '@/model/capacityPercentage';
import { ListPaymentTrx } from '@/model/listPaymentTrx';
import { ListOfPaymentTrans } from '@/model/listOfPaymentTrans';
import { ListOfTrxDoDetail } from '@/model/listOfTrxDoDetail';
import { DataTotal } from '@/model/dataTotal';
import { ListOfTrxCostDetail } from '@/model/listOfTrxCostDetail';
import { ListDriverDebt } from '@/model/listDriverDebt';
import { InvoiceRaw, ListOfInvoice } from '@/model/listOfInvoice';
import { LocationSettingBank } from '@/model/locationSettingBank';
import { InvoiceGenerateDto } from '@/model/invoiceGenerateDto';
import { ListInvoiceDetail } from '@/model/listInvoiceDetail';
import { PaymentDetailDto } from '@/model/paymentDetailDto';
import { AuthenticatedResponse, loginModel } from '@/model/loginModel';
import { DriverSubscriptionByCriteria } from '@/model/driverSubscriptionByCriteria';
import { DriverSubscriptionVm } from '@/model/driverSubscriptionVm';
import { RespGenBPJS } from '@/model/respGenBPJS';
import { DriverShrinkageRecapVm } from '@/model/driverShrinkageRecapVm';
import { ListTrxDebt } from '@/model/listTrxDebt';
import { DriverDebtDetail } from '@/model/driverDebtDetail';
import { LocationSettingSignature } from '@/model/locationSettingSignature';
import { CheckDOInvoiceDetail } from '@/model/checkDOInvoiceDetail';
import { FinalResultDto, Report, ReportDto, ReportResp } from '@/model/reportDto';
import { UserAuth } from '@/model/userAuth';
import { listTrxDetailPaymentTrip } from '@/model/listTrxDetailPaymentTrip';
import { ListSPK } from '@/model/listSPK';
import { Logs } from '@/model/logs';
import { User } from '@/model/user';
import { Role } from '@/model/role';
import { AuthMenu, MasterSubMenu } from '@/model/masterSubMenu';
import { MasterAuth, MasterMenu, SubLocation } from '@/model/masterMenu';
import { Approvals, Bapb, BapbDetail, Npb, NpbDetail, Spb, SpbDetail, SpkExistDoc, SpkService, SpkServiceDetail, ViewStock} from '@/model/spkService';
import { DebitNote, DebitNoteDetail } from '@/model/debitNote';
import { ManualInvoiceDetail, RevenueType } from '@/model/invoiceManual';

export interface Column {
  id: string;
  visible?: boolean
  label: string,
  hideOrder: number,
  width?: number
}

class DataTablesResponse {
  data: any[];
  totalPageCount: number;
  pageSize: number;
  totalCount: number;
}

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};




@Injectable({
  providedIn: 'root'
})

export class TransportService {
  private apiUrl = `${environment.apiUrl}/api`;
  public namaKebun: string;
  public lokasiKebun: string;
  public idKebun: number;
  public materialName: string = "";
  public idMaterial: Guid;
  public customerName: string = "";
  public idCustomer: Guid;
  public ekspedisiName: string = "";
  public idEkspedisi: Guid;
  public penjualName: string = "";
  public idPenjual: Guid;
  public pageRelation: string = "";
  public authAccess: UserAuth[] = [];
  constructor(private http: HttpClient) { }

  httpHead(){
    return {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' +  localStorage.getItem('token') })
    };
  }

  onSelectKebun(data: Kebun){
    this.namaKebun = data.nama;
    this.lokasiKebun = data.lokasi;
    this.idKebun = data.id;
  }

  onSelectMaterial(data: Material){
    this.materialName = data.material_name;
    this.idMaterial = data.material_id;
  }
  

  onSelectCustomer(data: Relation){
    this.idCustomer = data.relation_id;
    this.customerName = data.relation_name;
  } 

  onSelectPenjual(data: Relation){
    this.idPenjual = data.relation_id;
    this.penjualName = data.relation_name;
  } 

  onSelectEkspedisi(data: Relation){
    this.idEkspedisi = data.relation_id;
    this.ekspedisiName = data.relation_name;
  } 


  markFormTouched(group: FormGroup | FormArray) {
    Object.keys(group.controls).forEach((key: string) => {
      const control = group.controls[key];
      if (control instanceof FormGroup || control instanceof FormArray) { control.markAsTouched(); this.markFormTouched(control); }
      else { control.markAsTouched(); };
    });
  };


  getUsers():Observable<Column[]> {
    const url = `${this.apiUrl}/masterdata/material`;
    return this.http.get<Column[]>(url)
  }

  getDataMaterialTest(dt: any, page: number, perPage: number, code: string, name: string): Observable<DataTablesResponse> {
    const url = `${this.apiUrl}/masterdata/materialget?page=${page}&perPage=${perPage}&code=${code}&name=${name}`;
    return this.http.post<DataTablesResponse>(url, dt, this.httpHead());
  }

  getDataMaterial(): Observable<Material[]> {
    const url = `${this.apiUrl}/masterdata/material`;
    return this.http.get<Material[]>(url, this.httpHead());
  }

  getAllMaterial(): Observable<Material[]> {
    const url = `${this.apiUrl}/masterdata/material`;
    return this.http.get<Material[]>(url, this.httpHead());
  }

  getListMaterial(): Observable<Material[]> {
    const url = `${this.apiUrl}/masterdata/material/all`;
    return this.http.get<Material[]>(url, this.httpHead());
  }

  addMaterial(material: object): Observable<Material> {
    return this.http.post<Material>(`${this.apiUrl}/masterdata/material`, material, this.httpHead());
  }

  DeleteMaterial(vehicleId: Guid): Observable<Material> {
    return this.http.delete<Material>(`${this.apiUrl}/masterdata/deletematerial/${vehicleId}`, this.httpHead());
  }

  getDataVehicle(dt: any, page: number, perPage: number, code: string, name: string): Observable<DataTablesResponse> {
    const url = `${this.apiUrl}/masterdata/getvehicle?page=${page}&perPage=${perPage}&code=${code}&name=${name}`;
    return this.http.post<DataTablesResponse>(url, dt, this.httpHead());
  }

  addVehicle(vehicle: object): Observable<RespDto> {
    return this.http.post<RespDto>(`${this.apiUrl}/masterdata/vehicle`, vehicle, this.httpHead());
  }

  DeleteVehicle(vehicleId: Guid): Observable<Vehicle> {
    return this.http.delete<Vehicle>(`${this.apiUrl}/masterdata/deletevehicle/${vehicleId}`, this.httpHead());
  }

  getDataTonnase(dt: any, page: number, perPage: number, code: string, name: string, quantity: string, uoms: string): Observable<DataTablesResponse> {
    const url = `${this.apiUrl}/masterdata/gettonnase?page=${page}&perPage=${perPage}&code=${code}&name=${name}&quantity=${quantity}&uoms=${uoms}`;
    return this.http.post<DataTablesResponse>(url, dt, this.httpHead());
  }

  addTonnase(tonnase: object): Observable<RespDto> {
    return this.http.post<RespDto>(`${this.apiUrl}/masterdata/tonnase`, tonnase, this.httpHead());
  }

  DeleteTonnase(tonnaseId: Guid): Observable<Ton> {
    return this.http.delete<Ton>(`${this.apiUrl}/masterdata/deletetonnase/${tonnaseId}`, this.httpHead());
  }

  getListMasterCode(category: string): Observable<MasterCode[]> {
    const url = `${this.apiUrl}/masterdata/listcode?category=${category}`;
    return this.http.get<MasterCode[]>(url, this.httpHead());
  }

  getListAllMasterCode(): Observable<MasterCode[]> {
    const url = `${this.apiUrl}/masterdata/allcode`;
    return this.http.get<MasterCode[]>(url, this.httpHead());
  }

  getListCapacityPercentage(id: Guid): Observable<CapacityPercentage[]> {
    const url = `${this.apiUrl}/masterdata/getcapacitypercentage?id=${id}`;
    return this.http.get<CapacityPercentage[]>(url, this.httpHead());
  }

  addCapacityPercentage(capacity: object): Observable<CapacityPercentage> {
    return this.http.post<CapacityPercentage>(`${this.apiUrl}/masterdata/capacitypercentage`, capacity, this.httpHead());
  }

  deleteCapacityPercentage(id: Guid): Observable<CapacityPercentage> {
    return this.http.delete<CapacityPercentage>(`${this.apiUrl}/masterdata/deletecapacitypercentage/${id}`, this.httpHead());
  }

  addCapacity(capacity: object): Observable<RespDto> {
    return this.http.post<RespDto>(`${this.apiUrl}/masterdata/capacity`, capacity, this.httpHead());
  }

  DeleteCapacity(capacityId: Guid): Observable<Capacity> {
    return this.http.delete<Capacity>(`${this.apiUrl}/masterdata/deletecapacity/${capacityId}`, this.httpHead());
  }

  getDataCapacity(dt: any, page: number, perPage: number, code: string, name: string, vehicle: string): Observable<DataTablesResponse> {
    const url = `${this.apiUrl}/masterdata/getcapacity?page=${page}&perPage=${perPage}&code=${code}&name=${name}&vehicle=${vehicle}`;
    return this.http.post<DataTablesResponse>(url, dt, this.httpHead());
  }

  getListTruck(dt: any, page: number, perPage: number, platNumber: string, codeCapacity: string, location: string): Observable<DataTablesResponse> {
    const url = `${this.apiUrl}/masterdata/getlisttruck?page=${page}&perPage=${perPage}&platNumber=${platNumber}&codeCapacity=${codeCapacity}&location=${location}`;
    return this.http.post<DataTablesResponse>(url, dt, this.httpHead());
  }

  getLocation(): Observable<Location[]> {
    const url = `${this.apiUrl}/masterdata/getlocation`;
    return this.http.get<Location[]>(url, this.httpHead());
  }

  getListRelation(dt: any, page: number, perPage: number, relation_code: string, relation_name: string,
    relation_address: string, relation_type: string, relation_sl_code: string): Observable<DataTablesResponse> {
    const url = `${this.apiUrl}/masterdata/getrelation?page=${page}&perPage=${perPage}&relation_code=${relation_code}&relation_name=${relation_name}&relation_address=${relation_address}&relation_type=${relation_type}&relation_sl_code=${relation_sl_code}`;
    return this.http.post<DataTablesResponse>(url, dt, this.httpHead());
  }

  addTruck(capacity: object): Observable<RespDto> {
    return this.http.post<RespDto>(`${this.apiUrl}/masterdata/truck`, capacity, this.httpHead());
  }

  DeleteTruck(truckId: Guid): Observable<Truck> {
    return this.http.delete<Truck>(`${this.apiUrl}/masterdata/deletetruck/${truckId}`, this.httpHead());
  }

  getListTruckAsset(id: string,): Observable<TruckAsset[]> {
    const url = `${this.apiUrl}/masterdata/gettruckasset?id=${id}`;
    return this.http.get<TruckAsset[]>(url, this.httpHead());
  }

  getListPort(dt: any, page: number, perPage: number, port_name: string): Observable<DataTablesResponse> {
    const url = `${this.apiUrl}/masterdata/getport?page=${page}&perPage=${perPage}&port_name=${port_name}`;
    return this.http.post<DataTablesResponse>(url, dt, this.httpHead());
  }

  getListPortTransit(dt: any, page: number, perPage: number, route_id: string, capacity_id: string, name: string, date: string): Observable<DataTablesResponse> {
    const url = `${this.apiUrl}/masterdata/getporttransit?page=${page}&perPage=${perPage}&route_id=${route_id}&capacity_id=${capacity_id}&name=${name}&date=${date}`;
    return this.http.post<DataTablesResponse>(url, dt, this.httpHead());
  }

  addPort(port: object): Observable<RespDto> {
    return this.http.post<RespDto>(`${this.apiUrl}/masterdata/port`, port, this.httpHead());
  }

  DeletePort(portid: Guid): Observable<Port> {
    return this.http.delete<Port>(`${this.apiUrl}/masterdata/deleteport/${portid}`, this.httpHead());
  }

  getListRoute(dt: any, page: number, perPage: number, route_name: string, route_type: string, unloadtext: string, loadtext: string, material_id: string): Observable<DataTablesResponse> {
    const url = `${this.apiUrl}/masterdata/getroute?page=${page}&perPage=${perPage}&route_name=${route_name}&route_type=${route_type}&unloadtext=${unloadtext}&loadtext=${loadtext}&material_id=${material_id}`;
    return this.http.post<DataTablesResponse>(url, dt, this.httpHead());
  }

  addRoute(route: object): Observable<RespDto> {
    return this.http.post<RespDto>(`${this.apiUrl}/masterdata/route`, route, this.httpHead());
  }

  DeleteRoute(routeid: Guid): Observable<Route> {
    return this.http.delete<Route>(`${this.apiUrl}/masterdata/deleteroute/${routeid}`, this.httpHead());
  }

  DeleteRelatioon(relationId: Guid): Observable<Relation> {
    return this.http.delete<Relation>(`${this.apiUrl}/masterdata/deleterelation/${relationId}`, this.httpHead());
  }

  addRelation(relation: object): Observable<RespDto> {
    return this.http.post<RespDto>(`${this.apiUrl}/masterdata/relation`, relation, this.httpHead());
  }

  getListCostType(dt: any, page: number, perPage: number, code: string, name: string): Observable<DataTablesResponse> {
    const url = `${this.apiUrl}/masterdata/gettypecost?page=${page}&perPage=${perPage}&code=${code}&name=${name}`;
    return this.http.post<DataTablesResponse>(url, dt, this.httpHead());
  }

  getListCostTypeAll(screen: string): Observable<CostType[]> {
    const url = `${this.apiUrl}/masterdata/getlistcosttype?screen=${screen}`;
    return this.http.get<CostType[]>(url, this.httpHead());
  }

  addCostType(cost: object): Observable<RespDto> {
    return this.http.post<RespDto>(`${this.apiUrl}/masterdata/cost`, cost, this.httpHead());
  }

  DeleteCostType(costId: Guid): Observable<CostType> {
    return this.http.delete<CostType>(`${this.apiUrl}/masterdata/deletecost/${costId}`, this.httpHead());
  }

  getDebtPriority(dt: any, page: number, perPage: number, code: string, debt_name: string,  priority: string, sequence_name: string): Observable<DataTablesResponse> {
    const url = `${this.apiUrl}/masterdata/getdebtpriority?page=${page}&perPage=${perPage}&code=${code}&priority=${priority}&debt_name=${debt_name}&sequence_name=${sequence_name}`;
    return this.http.post<DataTablesResponse>(url, dt, this.httpHead());
  }

  addDebt(debt: object): Observable<RespDto> {
    return this.http.post<RespDto>(`${this.apiUrl}/masterdata/debt`, debt, this.httpHead());
  }


  DeleteDebt(DebtId: Guid): Observable<DebtPriority> {
    return this.http.delete<DebtPriority>(`${this.apiUrl}/masterdata/deletedebt/${DebtId}`, this.httpHead());
  }

  getTravelCost(dt: any, page: number, perPage: number, port: string, material: string, route: string, capacity: string): Observable<DataTablesResponse> {
    const url = `${this.apiUrl}/masterdata/gettravelcost?page=${page}&perPage=${perPage}&port=${port}&material=${material}&route=${route}&capacity=${capacity}`;
    return this.http.post<DataTablesResponse>(url, dt, this.httpHead());
  }

  addTravelCost(cost: object): Observable<RespDto> {
    return this.http.post<RespDto>(`${this.apiUrl}/masterdata/travelcost`, cost, this.httpHead());
  }

  DeleteTravelCost(travelCostId: Guid): Observable<TravelCost> {
    return this.http.delete<TravelCost>(`${this.apiUrl}/masterdata/deletetravelcost/${travelCostId}`, this.httpHead());
  }

  getDetailListTravelCost(id: Guid,): Observable<DetailTravelCost[]> {
    const url = `${this.apiUrl}/masterdata/getdetailtravelcost?id=${id}`;
    return this.http.get<DetailTravelCost[]>(url, this.httpHead());
  }

  getHistoryTravelCost(data: object): Observable<ListTravelCost[]> {
    return this.http.post<ListTravelCost[]>(`${this.apiUrl}/masterdata/gethistorytravelcost`, data, this.httpHead());
  }

  // getHistoryTravelCost(port_id: Guid,material_id: Guid, route_id: Guid, capacity_id: Guid): Observable<ListTravelCost[]> {
  //   const url = `${this.apiUrl}/masterdata/gethistorytravelcost?port_id=${port_id}&material_id=${material_id}&route_id=${route_id}&capacity_id=${capacity_id}`;
  //   return this.http.get<ListTravelCost[]>(url, this.httpHead());
  // }

  getListDriver(dt: any, page: number, perPage: number, driver_name: string, driver_license: string, driver_license_type: string, agama: string, birthplace: string, ktp_no: string, tanggal_lahir: string, sl_code: string, sl_code2: string, sl_code3: string, address: string, valid_to: string, valid_to_contract: string, active: string, blacklist: string): Observable<DataTablesResponse> {
    const url = `${this.apiUrl}/masterdata/getdriver?page=${page}&perPage=${perPage}&driver_name=${driver_name}&driver_license=${driver_license}&driver_license_type=${driver_license_type}&agama=${agama}&birthplace=${birthplace}&ktp_no=${ktp_no}&tanggal_lahir=${tanggal_lahir}&sl_code=${sl_code}&sl_code2=${sl_code2}&sl_code3=${sl_code3}&valid_to=${valid_to}&valid_to_contract=${valid_to_contract}&active=${active}&blacklist=${blacklist}`;
    return this.http.post<DataTablesResponse>(url, dt, this.httpHead());
  }

  exportDriver(page: number, perPage: number, driver_name: string, driver_license: string, driver_license_type: string, agama: string, birthplace: string, ktp_no: string, tanggal_lahir: string, sl_code: string, sl_code2: string, sl_code3: string, address: string, valid_to: string, valid_to_contract: string, active: string, blacklist: string, location: string) {
    const url = `${this.apiUrl}/masterdata/exportdriver?page=${page}&perPage=${perPage}&driver_name=${driver_name}&driver_license=${driver_license}&driver_license_type=${driver_license_type}&agama=${agama}&birthplace=${birthplace}&ktp_no=${ktp_no}&tanggal_lahir=${tanggal_lahir}&sl_code=${sl_code}&sl_code2=${sl_code2}&sl_code3=${sl_code3}&valid_to=${valid_to}&valid_to_contract=${valid_to_contract}&active=${active}&blacklist=${blacklist}&location=${location}`;
    return this.http.get(url, {observe : 'response', responseType: 'blob'});
  }

  addDriver(driver: object): Observable<RespDto> {
    return this.http.post<RespDto>(`${this.apiUrl}/masterdata/driver`, driver, this.httpHead());
  }

  blacklistDriver(data: object): Observable<RespDto> {
    return this.http.post<RespDto>(`${this.apiUrl}/masterdata/driver/blacklist`, data, this.httpHead());
  }

  changeLicense(driver: object): Observable<RespDto> {
    return this.http.post<RespDto>(`${this.apiUrl}/masterdata/driver/licensechange`, driver, this.httpHead());
  }

  DeleteDriver(driverId: Guid): Observable<TravelCost> {
    return this.http.delete<TravelCost>(`${this.apiUrl}/masterdata/deletedriver/${driverId}`, this.httpHead());
  }

  getListCapacityTolerance(dt: any, page: number, perPage: number, tolerance: string, material_name: string, capacity_code: string, uom_name: string): Observable<DataTablesResponse> {
    const url = `${this.apiUrl}/masterdata/gettolerance?page=${page}&perPage=${perPage}&tolerance=${tolerance}&material_name=${material_name}&capacity_code=${capacity_code}&uom_name=${uom_name}`;
    return this.http.post<DataTablesResponse>(url, dt, this.httpHead());
  }
  
  addDataTolerance(tolerance: object): Observable<RespDto> {
    return this.http.post<RespDto>(`${this.apiUrl}/masterdata/tolerance`, tolerance, this.httpHead());
  }

  DeleteTolerance(id: Guid): Observable<CapacityTolerance> {
    return this.http.delete<CapacityTolerance>(`${this.apiUrl}/masterdata/deletetolerance/${id}`, this.httpHead());
  }

  getListKebun(dt: any, page: number, perPage: number, code: string, name: string, location: string): Observable<DataTablesResponse> {
    const url = `${this.apiUrl}/masterdata/getkebun?page=${page}&perPage=${perPage}&code=${code}&name=${name}&location=${location}`;
    return this.http.post<DataTablesResponse>(url, dt, this.httpHead());
  }

  addDataKebun(data: object): Observable<RespDto> {
    return this.http.post<RespDto>(`${this.apiUrl}/masterdata/kebun`, data, this.httpHead());
  }

  deleteKebun(id: number): Observable<Kebun> {
    return this.http.delete<Kebun>(`${this.apiUrl}/masterdata/deletekebun/${id}`, this.httpHead());
  }

  getDriverContract(id: Guid):Observable<DriverContract[]> {
    const url = `${this.apiUrl}/masterdata/driver/getcontract/${id}`;
    return this.http.get<DriverContract[]>(url, this.httpHead())
  }

  addDriverContract(data: object): Observable<RespDto> {
    return this.http.post<RespDto>(`${this.apiUrl}/masterdata/driver/contract`, data, this.httpHead());
  }

  deleteDriverContract(id: Guid): Observable<DriverContract> {
    return this.http.delete<DriverContract>(`${this.apiUrl}/masterdata/contract/delete/${id}`, this.httpHead());
  }

  getListSPK(dt : SpkDto): Observable<DataTablesResponse> {
    const url = `${this.apiUrl}/spk/spkget`;
    return this.http.post<DataTablesResponse>(url, dt, this.httpHead());
  }

  addSPK(data: object): Observable<RespDto> {
    return this.http.post<RespDto>(`${this.apiUrl}/spk/add`, data, this.httpHead());
  }

  deleteSPKList(id: Guid): Observable<MainDO> {
    return this.http.delete<MainDO>(`${this.apiUrl}/spk/deletespk/${id}`, this.httpHead());
  }

  getMainAdditionalCost(id: string,): Observable<MainAdditionalCost[]> {
    const url = `${this.apiUrl}/spk/getadditional?id=${id}`;
    return this.http.get<MainAdditionalCost[]>(url, this.httpHead());
  }

  getDetailDo(id: string,no_do: string,status: string): Observable<ListDoDetail[]> {
    const url = `${this.apiUrl}/spk/getdetaildo?id=${id}&no_do=${no_do}&status=${status}`;
    return this.http.get<ListDoDetail[]>(url, this.httpHead());
  }

  getDetailDoTransfer(id: string): Observable<ListDoDetail[]> {
    const url = `${this.apiUrl}/spk/getdetaildotransfer?id=${id}`;
    return this.http.get<ListDoDetail[]>(url, this.httpHead());
  }

  getDetailDoNew(dt: any, page: number, perPage: number, no_do: string, main_do: string): Observable<DataTablesResponse> {
    const url = `${this.apiUrl}/spk/getdetaildonew?page=${page}&perPage=${perPage}&no_do=${no_do}&main_do=${main_do}`;
    return this.http.post<DataTablesResponse>(url, dt, this.httpHead());
  }

  generateDo(data: object): Observable<RespDto> {
    return this.http.post<RespDto>(`${this.apiUrl}/spk/generatedo`, data, this.httpHead());
  }

  createDOManual(data: object): Observable<RespDto> {
    return this.http.post<RespDto>(`${this.apiUrl}/spk/createdomanual`, data, this.httpHead());
  }

  getCalculateCapacity(id: string, quantity: number, text: string): Observable<StringDto> {
    const url = `${this.apiUrl}/spk/getcalculate?id=${id}&quantity=${quantity}&text=${text}`;
    return this.http.get<StringDto>(url, this.httpHead());
  }

  setCompleteSPK(id: Guid): Observable<RespDto> {
    const url = `${this.apiUrl}/spk/setcomplete?id=${id}`;
    return this.http.post<RespDto>(url, id, this.httpHead());
  }

  setRollbackSPK(id: Guid): Observable<RespDto> {
    const url = `${this.apiUrl}/spk/rollback?id=${id}`;
    return this.http.post<RespDto>(url, id, this.httpHead());
  }

  transferDoDetail(data: object): Observable<RespDto> {
    return this.http.post<RespDto>(`${this.apiUrl}/spk/transferdodetail`, data, this.httpHead());
  }
  
  updateEstimationList(data: object): Observable<RespDto> {
    return this.http.post<RespDto>(`${this.apiUrl}/spk/updateestimationlist`, data, this.httpHead());
  }

  updateEstimation(data: object): Observable<RespDto> {
    return this.http.post<RespDto>(`${this.apiUrl}/spk/updateestimation`, data, this.httpHead());
  }

  getListTripRecord(dt : ListTripRecord): Observable<DataTablesResponse> {
    const url = `${this.apiUrl}/trip/list`;
    return this.http.post<DataTablesResponse>(url, dt, this.httpHead());
  }

  getDoDetailByRoute(route_id: string): Observable<RespDetailDo[]> {
    const url = `${this.apiUrl}/trip/dodetail?route_id=${route_id}`;
    return this.http.post<RespDetailDo[]>(url, this.httpHead());
  }

  getLabeltotal(data: DataTotal): Observable<LabelTotal> {
    return this.http.post<LabelTotal>(`${this.apiUrl}/trip/labeltotal`, data, this.httpHead());
  }

  getListPaymentTrx(data: object): Observable<ListPaymentTrx[]> {
    const url = `${this.apiUrl}/trip/listpayment`;
    return this.http.post<ListPaymentTrx[]>(url,data, this.httpHead());
  }

  getPaymentCancelById(data: object): Observable<ListPaymentTrx[]> {
    const url = `${this.apiUrl}/trip/paymentcancel`;
    return this.http.post<ListPaymentTrx[]>(url, data, this.httpHead());
  }

  closeTrip(data: object): Observable<RespDto> {
    return this.http.post<RespDto>(`${this.apiUrl}/trip/closetrip`, data, this.httpHead());
  }

  getDoDetailTrans(id: Guid): Observable<ListOfTrxDoDetail[]> {
    const url = `${this.apiUrl}/trip/listdodetail?id=${id}`;
    return this.http.post<ListOfTrxDoDetail[]>(url, this.httpHead());
  }

  createTrip(data: object): Observable<RespDto> {
    return this.http.post<RespDto>(`${this.apiUrl}/trip/createtrip`, data, this.httpHead());
  }

  updateTrip(data: object): Observable<RespDto> {
    return this.http.post<RespDto>(`${this.apiUrl}/trip/updatetrip`, data, this.httpHead());
  }

  getListTransactionCost(id: Guid): Observable<ListOfTrxCostDetail[]> {
    const url = `${this.apiUrl}/trip/listtransactioncost?id=${id}`;
    return this.http.get<ListOfTrxCostDetail[]>(url, this.httpHead());
  }

  checkDriverDebt(driver_id: Guid): Observable<RespDto> {
    const url = `${this.apiUrl}/trip/checkdriverdebt?driver_id=${driver_id}`;
    return this.http.get<RespDto>(url, this.httpHead());
  }

  adaptCost(data: object): Observable<RespDto> {
    return this.http.post<RespDto>(`${this.apiUrl}/trip/adaptcost`, data, this.httpHead());
  }

  // adaptCost(transit_port: Guid, route_id: Guid, depart_date: Date, transaction_type_text: string, do_detail_no: string,): Observable<RespDto> {
  //   const url = `${this.apiUrl}/trip/adaptcost?transit_port=${transit_port}&route_id=${route_id}&depart_date=${depart_date}&transaction_type_text=${transaction_type_text}&do_detail_no=${do_detail_no}`;
  //   return this.http.get<RespDto>(url);
  // }
  
  getDriverdebtById(driver_id: Guid): Observable<ListDriverDebt[]> {
    const url = `${this.apiUrl}/trip/driverdebt?driver_id=${driver_id}`;
    return this.http.get<ListDriverDebt[]>(url, this.httpHead());
  }

  getAccClosing(from: string): Observable<RespDto> {
    const url = `${this.apiUrl}/trip/accclosing?from=${from}`;
    return this.http.get<RespDto>(url, this.httpHead());
  }

  checkPaymentTrans(id: string): Observable<Boolean> {
    const url = `${this.apiUrl}/trip/checkpaymenttrans?id=${id}`;
    return this.http.get<Boolean>(url, this.httpHead());
  }

  claimSusut(data: object): Observable<RespDtoSusut> {
    return this.http.post<RespDtoSusut>(`${this.apiUrl}/trip/claimsusut`, data, this.httpHead());
  }

  getListInvoice(dt : ListOfInvoice): Observable<DataTablesResponse> {
    const url = `${this.apiUrl}/invoice/list`;
    return this.http.post<DataTablesResponse>(url, dt, this.httpHead());
  }

  getInvoiceNo(invoiceDate: Date, split_oa: boolean): Observable<RespDtoInvoice> {
    const url = `${this.apiUrl}/invoice/invoiceno?invoiceDate=${invoiceDate}&split_oa=${split_oa}`;
    return this.http.get<RespDtoInvoice>(url, this.httpHead());
  }

  getListBank(): Observable<LocationSettingBank[]> {
    const url = `${this.apiUrl}/invoice/bank`;
    return this.http.get<LocationSettingBank[]>(url, this.httpHead());
  }

  generateInvoice(data: object): Observable<InvoiceGenerateDto[]> {
    return this.http.post<InvoiceGenerateDto[]>(`${this.apiUrl}/invoice/generateinvoice`, data, this.httpHead());
  }

  createInvoice(data: object): Observable<RespDto> {
    return this.http.post<RespDto>(`${this.apiUrl}/invoice/createinvoice`, data, this.httpHead());
  }

  getInvoiceDetail(invoice_id: Guid): Observable<ListInvoiceDetail[]> {
    const url = `${this.apiUrl}/invoice/detail?invoice_id=${invoice_id}`;
    return this.http.get<ListInvoiceDetail[]>(url, this.httpHead());
  }
  
  cancelInvoice(data: object): Observable<RespDto> {
    return this.http.post<RespDto>(`${this.apiUrl}/invoice/cancel`, data, this.httpHead());
  }

  getDebtTrxDetail(driverdebt_id: Guid): Observable<DriverDebtDetail[]> {
    const url = `${this.apiUrl}/calculation/debtdetail?driverdebt_id=${driverdebt_id}`;
    return this.http.get<DriverDebtDetail[]>(url, this.httpHead());
  }

  getListDebtHead(dt: any, page: number, perPage: number, name: string): Observable<DataTablesResponse> {
    const url = `${this.apiUrl}/calculation/list?page=${page}&perPage=${perPage}&name=${name}`;
    return this.http.post<DataTablesResponse>(url, dt, this.httpHead());
  }

  getListDebtDriver(dt: any, page: number, perPage: number, driver_id: Guid, reff: string, contract_no: string): Observable<ListDriverDebt[]> {
    const url = `${this.apiUrl}/calculation/detail?page=${page}&perPage=${perPage}&driver_id=${driver_id}&reff=${reff}&contract_no=${contract_no}`;
    return this.http.post<ListDriverDebt[]>(url, dt, this.httpHead());
  }

  addDataDebtDriver(data: object): Observable<RespDto> {
    return this.http.post<RespDto>(`${this.apiUrl}/calculation/createdebt`, data, this.httpHead());
  }

  getTypeDebt(): Observable<DebtPriority[]> {
    const url = `${this.apiUrl}/calculation/typedebt`;
    return this.http.get<DebtPriority[]>(url, this.httpHead());
  }

  deleteDebt(data: object): Observable<RespDto> {
    return this.http.post<RespDto>(`${this.apiUrl}/calculation/deletedebt`, data, this.httpHead());
  }

  // deleteDebt(debt_id: Guid, driver_id: Guid, reason: string): Observable<RespDto> {
  //   const url = `${this.apiUrl}/calculation/deletedebt?debt_id=${debt_id}&driver_id=${driver_id}&reason=${reason}`;
  //   return this.http.post<RespDto>(url, this.httpHead());
  // }

  testPrint(no : string) {
    const url = `${this.apiUrl}/spk/testpdf?no=${no}`;
    return this.http.get(url, {observe : 'response', responseType: 'blob'});
  }

  // doRollBack(transaction_id: Guid, realizationType: string): Observable<RespDto> {
  //   const url = `${this.apiUrl}/trip/rollback?transaction_id=${transaction_id}&realizationType=${realizationType}`;
  //   return this.http.post<RespDto>(url, this.httpHead());
  // }

  doRollBack(data: object): Observable<RespDto> {
    return this.http.post<RespDto>(`${this.apiUrl}/trip/rollback`, data, this.httpHead());
  }

  doRollBackCancel(transaction_id: Guid): Observable<RespDto> {
    const url = `${this.apiUrl}/trip/rollcancel?transaction_id=${transaction_id}`;
    return this.http.post<RespDto>(url, this.httpHead());
  }

  getTransactionRollback(transaction_id: Guid): Observable<boolean> {
    const url = `${this.apiUrl}/trip/rolltransaction?transaction_id=${transaction_id}`;
    return this.http.get<boolean>(url, this.httpHead());
  }

  getListBoxInvoice(relation_id: Guid, payment_type: Guid ): Observable<PaymentDetailDto[]> {
    const url = `${this.apiUrl}/payment/listinvoice?relation_id=${relation_id}&payment_type=${payment_type}`;
    return this.http.get<PaymentDetailDto[]>(url, this.httpHead());
  }

  addDataPayment(data: object): Observable<RespDto> {
    return this.http.post<RespDto>(`${this.apiUrl}/payment/add`, data, this.httpHead());
  }

  confirmPayment(data: object): Observable<RespDto> {
    return this.http.post<RespDto>(`${this.apiUrl}/payment/addpayment`, data, this.httpHead());
  }

  updateDataPayment(data: object): Observable<RespDto> {
    return this.http.post<RespDto>(`${this.apiUrl}/payment/update`, data, this.httpHead());
  }

  deletePayment(payment_id: Guid): Observable<RespDto> {
    const url = `${this.apiUrl}/payment/delete?payment_id=${payment_id}`;
    return this.http.get<RespDto>(url, this.httpHead());
  }
  
  getDetailPayment(payment_id: Guid ): Observable<PaymentDetailDto[]> {
    const url = `${this.apiUrl}/payment/detail?payment_id=${payment_id}`;
    return this.http.get<PaymentDetailDto[]>(url, this.httpHead());
  }

  getListPayment(dt: any, page: number, perPage: number, type: string, customer: string, no: string, status: string): Observable<DataTablesResponse> {
    const url = `${this.apiUrl}/payment/list?page=${page}&perPage=${perPage}&type=${type}&customer=${customer}&no=${no}&status=${status}`;
    return this.http.post<DataTablesResponse>(url, dt, this.httpHead());
  }

  getListAccClosing(dt: any, page: number, perPage: number, status: string, type: string): Observable<DataTablesResponse> {
    const url = `${this.apiUrl}/payment/listacc?page=${page}&perPage=${perPage}&status=${status}&type=${type}`;
    return this.http.post<DataTablesResponse>(url, dt, this.httpHead());
  }

  addDataClosing(data: object): Observable<RespDto> {
    return this.http.post<RespDto>(`${this.apiUrl}/payment/addacc`, data, this.httpHead());
  }

  deleteClosing(id: Guid): Observable<RespDto> {
    const url = `${this.apiUrl}/payment/acc/delete?id=${id}`;
    return this.http.get<RespDto>(url, this.httpHead());
  }

  printTripPayment(payment_id : Guid, location: string) {
    const url = `${this.apiUrl}/trip/printtravelcostpayment?payment_id=${payment_id}&location=${location}`;
    return this.http.get(url, {observe : 'response', responseType: 'blob'});
  }

  deleteTripPayment(transaction_payment_id: Guid): Observable<RespDto> {
    const url = `${this.apiUrl}/trip/deletepayment?transaction_payment_id=${transaction_payment_id}`;
    return this.http.get<RespDto>(url, this.httpHead());
  }

  finishTrip(data: object): Observable<RespDto> {
    return this.http.post<RespDto>(`${this.apiUrl}/trip/finishtrip`, data, this.httpHead());
  }

  printTripOutStanding(from : Date, to: Date, location: string) {
    const url = `${this.apiUrl}/invoice/printoutstanding?from=${from}&to=${to}&location=${location}`;
    return this.http.get(url,{observe : 'response', responseType: 'blob'} );
  }

  printSPKList(data: object) {
    const url = `${this.apiUrl}/spk/rekapspk`;
    return this.http.post(url, data, {observe : 'response', responseType: 'blob'});
  }

  printTripList(from : Date, to: Date, location: string) {
    const url = `${this.apiUrl}/trip/reporttriprecord?from=${from}&to=${to}&location=${location}`;
    return this.http.get(url, {observe : 'response', responseType: 'blob'});
  }

  getListSubHeader(dt: any, page: number, perPage: number, year: string, subscription_name: string, month: string): Observable<DataTablesResponse> {
    const url = `${this.apiUrl}/calculation/bpjs/header?page=${page}&perPage=${perPage}&year=${year}&subscription_name=${subscription_name}&month=${month}`;
    return this.http.post<DataTablesResponse>(url, dt, this.httpHead());
  }

  getSubCriteria(dt: any, page: number, perPage: number, header_id: string, driver: string): Observable<DriverSubscriptionByCriteria[]> {
    const url = `${this.apiUrl}/calculation/bpjs/criteria?page=${page}&perPage=${perPage}&header_id=${header_id}&driver=${driver}`;
    return this.http.post<DriverSubscriptionByCriteria[]>(url, dt, this.httpHead());
  }

  // generateBPJS(subscriptionType: Guid, month: number, year: string): Observable<RespGenBPJS> {
  //   const url = `${this.apiUrl}/calculation/bpjs/generate?subscriptionType=${subscriptionType}&month=${month}&year=${year}`;
  //   return this.http.post<RespGenBPJS>(url,this.httpHead());
  // }

  generateBPJS(data: object): Observable<RespGenBPJS> {
    const url = `${this.apiUrl}/calculation/bpjs/generate `;
    return this.http.post<RespGenBPJS>(url,data,this.httpHead());
  }

  addBpjsHeader(data: object): Observable<RespDto> {
    return this.http.post<RespDto>(`${this.apiUrl}/calculation/bpjs/add/header`, data, this.httpHead());
  }

  saveGenerateBpjs(data: object): Observable<RespDto> {
    return this.http.post<RespDto>(`${this.apiUrl}/calculation/bpjs/savegenerate`, data, this.httpHead());
  }

  addBpjsCriteria(data: object): Observable<RespDto> {
    return this.http.post<RespDto>(`${this.apiUrl}/calculation/bpjs/add/criteria`, data, this.httpHead());
  }
  
  deleteBpjsCriteria(driversubscription_id: string): Observable<RespDto> {
    const url = `${this.apiUrl}/calculation/bpjs/delete/criteria?driversubscription_id=${driversubscription_id}`;
    return this.http.post<RespDto>(url, this.httpHead());
  }

  getSusutList(dt: any, page: number, perPage: number, reff: string, driver_name: string, print: string, cancel: string): Observable<DataTablesResponse> {
    const url = `${this.apiUrl}/calculation/susut/list?page=${page}&perPage=${perPage}&reff=${reff}&driver_name=${driver_name}&print=${print}&cancel=${cancel}`;
    return this.http.post<DataTablesResponse>(url, dt, this.httpHead());
  }

  getTolerance(material_id: Guid ): Observable<CapacityTolerance> {
    const url = `${this.apiUrl}/calculation/susut/tolerance?material_id=${material_id}`;
    return this.http.get<CapacityTolerance>(url, this.httpHead());
  }

  addClaimSusut(data: object): Observable<RespDto> {
    return this.http.post<RespDto>(`${this.apiUrl}/calculation/susut/claim`, data, this.httpHead());
  }

  cancelSusut(id: Guid, canceledReason: string): Observable<RespDto> {
    const url = `${this.apiUrl}/calculation/susut/cancel?id=${id}&canceledReason=${canceledReason}`;
    return this.http.post<RespDto>(url, null,this.httpHead());
  }

  rollbackSusut(id: Guid): Observable<RespDto> {
    const url = `${this.apiUrl}/calculation/susut/rollback?id=${id}`;
    return this.http.post<RespDto>(url, this.httpHead());
  }

  getDebtPay(dt: any, page: number, perPage: number, driver_name: string, driver_license: string, trx_reff: string, type_name: string): Observable<DataTablesResponse> {
    const url = `${this.apiUrl}/calculation/debtpay/list?page=${page}&perPage=${perPage}&driver_name=${driver_name}&driver_license=${driver_license}&trx_reff=${trx_reff}&type_name=${type_name}`;
    return this.http.post<DataTablesResponse>(url, dt, this.httpHead());
  }

  addDebtPay(data: object): Observable<RespDto> {
    return this.http.post<RespDto>(`${this.apiUrl}/calculation/debtpay/add`, data, this.httpHead());
  }

  generatePremi(data: object): Observable<RespPremi> {
    return this.http.post<RespPremi>(`${this.apiUrl}/calculation/premi/generate`, data, this.httpHead());
  }

  savePremi(data: object): Observable<RespDto> {
    return this.http.post<RespDto>(`${this.apiUrl}/calculation/premi/save`, data, this.httpHead());
  }

  getPremiList(dt: any, page: number, perPage: number): Observable<DataTablesResponse> {
    const url = `${this.apiUrl}/calculation/premi/list?page=${page}&perPage=${perPage}`;
    return this.http.post<DataTablesResponse>(url, dt, this.httpHead());
  }

  getReportSusut(dt: any): Observable<DataTablesResponse> {
    const url = `${this.apiUrl}/general/susutsps`;
    return this.http.post<DataTablesResponse>(url,dt, this.httpHead());
  }

  printSpsSusut(location: string) {
    const url = `${this.apiUrl}/general/printsusutsps?location=${location}`;
    return this.http.get(url, {observe : 'response', responseType: 'blob'});
  }
  
  printReportTravelCost(from : Date, to: Date) {
    const url = `${this.apiUrl}/general/reportTravel?from=${from}&to=${to}&location=${location}`;
    return this.http.get(url, {observe : 'response', responseType: 'blob'});
  }

  printReportTravelCostExcel(from : Date, to: Date) {
    const url = `${this.apiUrl}/general/reportTravelExcel?from=${from}&to=${to}&location=${location}`;
    return this.http.get(url, {observe : 'response', responseType: 'blob'});
  }

  printReportPerformDriver(from : Date, to: Date, location: string) {
    const url = `${this.apiUrl}/general/performdriver?from=${from}&to=${to}&location=${location}`;
    return this.http.get(url, {observe : 'response', responseType: 'blob'});
  }

  printReportGeneral2(data: object) {
    const url = `${this.apiUrl}/general/generalreport`;
    return this.http.post(url, data, {observe : 'response', responseType: 'blob'});
  }

  printReportGeneral(data: object): Observable<Blob> {
    const url = `${this.apiUrl}/general/generalreport`;
    return this.http.post<Blob>(url, data, this.httpHead());
  }

  checkDo(data: object): Observable<RespCheckDo> {
    return this.http.post<RespCheckDo>(`${this.apiUrl}/trip/checkdo`, data, this.httpHead());
  }

  insertTransDistributed(data: object): Observable<RespDto> {
    return this.http.post<RespDto>(`${this.apiUrl}/trip/inserttrans`, data, this.httpHead());
  }

  printReportSpendTravelCostRekap(data: object) {
    const url = `${this.apiUrl}/trip/printreporttravelcostrekap`;
    return this.http.post(url, data, {observe : 'response', responseType: 'blob'});
  }

  printReportSpendTravelCost(data: object) {
    const url = `${this.apiUrl}/trip/printreporttravelcost`;
    return this.http.post(url, data, {observe : 'response', responseType: 'blob'});
  }

  printReportSpendTravelCostAdjusment(data: object) {
    const url = `${this.apiUrl}/trip/printreporttravelcostadjusment`;
    return this.http.post(url, data, {observe : 'response', responseType: 'blob'});
  }

  printReportSpendTravelCostRetur(data: object) {
    const url = `${this.apiUrl}/trip/printreporttravelcostreturn`;
    return this.http.post(url, data, {observe : 'response', responseType: 'blob'});
  }

  printMasterData(data: object) {
    const url = `${this.apiUrl}/masterdata/printdriver`;
    return this.http.post(url, data, {observe : 'response', responseType: 'blob'});
  }

  UploadFGS(fileToUpload: any, location: string): Observable<RespUploadDto> {
    const input: FormData = new FormData();
    input.append('file', fileToUpload);
    input.append('location', location);
    return this.http.post<RespUploadDto>(`${this.apiUrl}/trip/upload`, input);    
  }

  UploadCustomer(fileToUpload: any, location: string): Observable<RespUploadDto> {
    const input: FormData = new FormData();
    input.append('file', fileToUpload);
    input.append('location', location);
    return this.http.post<RespUploadDto>(`${this.apiUrl}/trip/uploadcustomer`, input);    
  }

  // UploadFGS(fileToUpload: any): Observable<HttpEvent<any>> {
  //   const input: FormData = new FormData();
  //   input.append('file', fileToUpload);
  //   let params = new HttpParams();
  
  //   const options = {
  //     params: params,
  //     reportProgress: true,
  //   };
  
  //   const req = new HttpRequest('POST',  `${this.apiUrl}/trip/upload` , input, options);
  //   return this.http.request(req); 
  // }

  upload(fileToUpload: any, code: any, pkps: any ): Observable<HttpEvent<any>> {
    const input = new FormData();
    input.append('file', fileToUpload);
    input.append('code', code);
    input.append('pkps', pkps);
  
    let params = new HttpParams();
  
    const options = {
      params: params,
      reportProgress: true,
    };
  
    const req = new HttpRequest('POST',  `${environment.apiUrl}/api/upload` , input, options);
    return this.http.request(req);
  }

  // printTravelCost(from_date : Date, to_date: Date, material: Guid, exclude_material: Guid, trip_type: Guid) {
  //   const url = `${this.apiUrl}/trip/printtravelcost?from_date=${from_date}&to_date=${to_date}&material=${material}&exclude_material=${exclude_material}&trip_type=${trip_type}`;
  //   return this.http.get(url, {observe : 'response', responseType: 'blob'});
  // }

  getSignature(): Observable<LocationSettingSignature[]> {
    const url = `${this.apiUrl}/masterdata/signature`;
    return this.http.get<LocationSettingSignature[]>(url, this.httpHead());
  }

  printSusut(data: object) {
    const url = `${this.apiUrl}/calculation/susut/print`;
    return this.http.post(url, data, {observe : 'response', responseType: 'blob'});
  }

  // printInvoice(id: Guid, sign_Id: Guid, pajak: Boolean) {
  //   const url = `${this.apiUrl}/invoice/printinvoice?id=${id}&sign_Id=${sign_Id}&pajak=${pajak}`;
  //   return this.http.get<Blob>(url, this.httpHead());
  // }
  
  printInvoice(id: Guid, sign_Id: Guid, pajak: Boolean, locationName: string) {
    const location = localStorage.getItem('location');
    const url = `${this.apiUrl}/invoice/printinvoice?id=${id}&sign_Id=${sign_Id}&pajak=${pajak}&location=${location}&locationName=${locationName}`;
    return this.http.get(url,  {observe : 'response', responseType: 'blob' });
  }

  printInvoiceSpecial(id: Guid, sign_Id: Guid, pajak: Boolean, locationName: string) {
    const location = localStorage.getItem('location');
    const url = `${this.apiUrl}/invoice/printinvoicespecial?id=${id}&sign_Id=${sign_Id}&pajak=${pajak}&location=${location}&locationName=${locationName}`;
    return this.http.get(url,  {observe : 'response', responseType: 'blob' });
  }

  printInvoicePPH(id: Guid, sign_Id: Guid, pajak: Boolean, locationName: string) {
    const location = localStorage.getItem('location');
    const url = `${this.apiUrl}/invoice/printinvoicepph?id=${id}&sign_Id=${sign_Id}&pajak=${pajak}&location=${location}&locationName=${locationName}`;
    return this.http.get(url,  {observe : 'response', responseType: 'blob' });
  }
  printInvoiceDetail(id: Guid, sign_Id: Guid, locationName: string) {
    const location = localStorage.getItem('location');
    const url = `${this.apiUrl}/invoice/printinvoicedetail?id=${id}&sign_Id=${sign_Id}&location=${location}&locationName=${locationName}`;
    return this.http.get(url,  {observe : 'response', responseType: 'blob' });
  }

  printInvoiceEkspedisi(id: Guid, sign_Id: Guid, pajak: Boolean, locationName: string) {
    const location = localStorage.getItem('location');
    const url = `${this.apiUrl}/invoice/printinvoiceekspedisi?id=${id}&sign_Id=${sign_Id}&pajak=${pajak}&location=${location}&locationName=${locationName}`;
    return this.http.get(url,  {observe : 'response', responseType: 'blob' });
  }

  printInvoiceEkspedisiDetail(id: Guid, sign_Id: Guid, locationName: string) {
    const location = localStorage.getItem('location');
    const url = `${this.apiUrl}/invoice/printinvoiceekspedisidetail?id=${id}&sign_Id=${sign_Id}&location=${location}&locationName=${locationName}`;
    return this.http.get(url,  {observe : 'response', responseType: 'blob' });
  }

  getCheckDetailDoInv(id: Guid ): Observable<CheckDOInvoiceDetail> {
    const url = `${this.apiUrl}/invoice/checkdetail?id=${id}`;
    return this.http.get<CheckDOInvoiceDetail>(url, this.httpHead());
  }

  getCheckCountInv(id: Guid ): Observable<number> {
    const url = `${this.apiUrl}/invoice/checkcount?id=${id}`;
    return this.http.get<number>(url, this.httpHead());
  }

  getCheckSpecialInv(id: Guid ): Observable<number> {
    const url = `${this.apiUrl}/invoice/checkspecial?id=${id}`;
    return this.http.get<number>(url, this.httpHead());
  }

  // downloadFile(inputs: className) {
  //   const headers = new HttpHeaders()
  //    .set('Accept', 'application/xlsx; charset=utf-8');
  //   const url = `${this.baseUrl}url/downloadxls`;

  //   return this.http.post(url, inputs, { headers, observe: 'response', responseType: 'blob' });
  // }

  releasePrintInvoice(id: Guid): Observable<RespDto> {
    const url = `${this.apiUrl}/invoice/releaseprint?id=${id}`;
    return this.http.post<RespDto>(url, id, this.httpHead());
  }

  UpdateStatusPrintInvoice(id: Guid): Observable<number> {
    const url = `${this.apiUrl}/invoice/updateprint?id=${id}`;
    return this.http.post<number>(url, id, this.httpHead());
  }
  
  printDO(do_no: string, material_id: string, do_id: string, location: string) {
    const url = `${this.apiUrl}/spk/printdo?do_no=${do_no}&material_id=${material_id}&do_id=${do_id}&location=${location}`;
    return this.http.get(url,  {observe : 'response', responseType: 'blob' });
  }

  updateDO(data: object) {
    return this.http.post<RespUpdateDO>(`${this.apiUrl}/trip/updatedo`, data, this.httpHead());
    // return this.http.post(`${this.apiUrl}/trip/updatedo`, data, this.httpHead());
  }

  generateJournalInvoice(data: object): Observable<RespJournalDto> {
    return this.http.post<RespJournalDto>(`${this.apiUrl}/journal/invoice`, data, this.httpHead());
  }

  saveJournalInvoice(data: object): Observable<RespDto> {
    return this.http.post<RespDto>(`${this.apiUrl}/journal/saveinvoice`, data, this.httpHead());
  }

  generateJournalTravelCost(data: object): Observable<RespJournalDto> {
    return this.http.post<RespJournalDto>(`${this.apiUrl}/journal/travelcost`, data, this.httpHead());
  }

  generateJournalTravelCostInvOnly(data: object): Observable<RespJournalInvOnlyDto> {
    return this.http.post<RespJournalInvOnlyDto>(`${this.apiUrl}/journal/travelcostinvonly`, data, this.httpHead());
  }

  saveUploadRealization(data: object): Observable<RespDto> {
    return this.http.post<RespDto>(`${this.apiUrl}/trip/saveupload`, data, this.httpHead());
  }

  changeLocation(location: string) {
    const url = `${this.apiUrl}/auth/changelocation?location=${location}`;
    return this.http.post(url, this.httpHead());
  }

  reportDebtDetail(data : object) {
    // const url = `${this.apiUrl}/calculation/reportdebtdetail?driver_id=${driver_id}`;
    // return this.http.get(url, {observe : 'response', responseType: 'blob'});
    const url = `${this.apiUrl}/calculation/reportdebtdetail`;
    return this.http.post(url, data, {observe : 'response', responseType: 'blob'});
  }

  reportDebtHead(data : object) {
    const url = `${this.apiUrl}/calculation/reportdebthead`;
    return this.http.post(url, data, {observe : 'response', responseType: 'blob'});
  }

  reportDebtDetailInstallment(data : object) {
    const url = `${this.apiUrl}/calculation/reportdebtdetailinstallment`;
    return this.http.post(url, data, {observe : 'response', responseType: 'blob'});
  }

  checkQueryreport(dt: any): Observable<FinalResultDto> {
    const url = `${this.apiUrl}/general/checkquery`;
    return this.http.post<FinalResultDto>(url,dt, this.httpHead());
  }

  saveCustomReport(data: object): Observable<RespDto> {
    return this.http.post<RespDto>(`${this.apiUrl}/general/savereport`, data, this.httpHead());
  }
 
  getListCustomReport(dt: any, page: number, perPage: number, name: string): Observable<DataTablesResponse> {
    const url = `${this.apiUrl}/general/listcustomreport?page=${page}&perPage=${perPage}&name=${name}`;
    return this.http.post<DataTablesResponse>(url, dt, this.httpHead());
  }

  getReportCode(id : Guid): Observable<Report> {
    const url = `${this.apiUrl}/general/getreportcode?id=${id}`;
    return this.http.get<Report>(url, this.httpHead());
  }

  showReport(data: object): Observable<ReportResp> {
    return this.http.post<ReportResp>(`${this.apiUrl}/general/showreport`, data, this.httpHead());
  }

  getAllReport(): Observable<Report[]> {
    const url = `${this.apiUrl}/general/getallreport`;
    return this.http.get<Report[]>(url, this.httpHead());
  }
  
  deleteDataReport(id: Guid): Observable<RespDto> {
    const url = `${this.apiUrl}/general/delete?id=${id}`;
    return this.http.post<RespDto>(url, this.httpHead());
  }

  getAccess(): Observable<UserAuth[]> {
    return this.http.post<UserAuth[]>(`${this.apiUrl}/auth/access`, this.httpHead());
  }

  getAuth(){
    this.getAccess().subscribe(resp => {
      this.authAccess = resp;
      console.log("authAcces : "+JSON.stringify(this.authAccess))
    })
  }

  getListDOMainyRoute(route_id: Guid):Observable<MainDO[]> {
    const url = `${this.apiUrl}/trip/listdomain?route_id=${route_id}`;
    return this.http.get<MainDO[]>(url, this.httpHead())
  }

  printDriverDebt(data: object) {
    const url = `${this.apiUrl}/calculation/driverdebt/print`;
    return this.http.post(url, data, {observe : 'response', responseType: 'blob'});
  }

  closeDriverDebt(data: object): Observable<RespDto> {
    return this.http.post<RespDto>(`${this.apiUrl}/calculation/driverdebt/close`, data, this.httpHead());
  }

  getListTrxDebtByDebt(debt_id: Guid): Observable<ListTrxDebt> {
    const url = `${this.apiUrl}/calculation/driverdebt/trxdebt?debt_id=${debt_id}`;
    return this.http.get<ListTrxDebt>(url, this.httpHead());
  }

  getDetailPaymentTrip(trx_payment_id: Guid, type: string): Observable<listTrxDetailPaymentTrip[]> {
    const url = `${this.apiUrl}/trip/detailpayment?trx_payment_id=${trx_payment_id}&type=${type}`;
    return this.http.get<listTrxDetailPaymentTrip[]>(url);
  }

  printContract(data: object) {
    const url = `${this.apiUrl}/masterdata/driver/printcontract`;
    return this.http.post(url, data, {observe : 'response', responseType: 'blob'});
  }

  printContracStatement(data: object) {
    const url = `${this.apiUrl}/masterdata/driver/printcontractstatement`;
    return this.http.post(url, data, {observe : 'response', responseType: 'blob'});
  }

  printBPJSDetail(data: object) {
    const url = `${this.apiUrl}/calculation/bpjs/printdetail`;
    return this.http.post(url, data, {observe : 'response', responseType: 'blob'});
  }

  printBPJSRekap(data: object) {
    const url = `${this.apiUrl}/calculation/bpjs/printrekap`;
    return this.http.post(url, data, {observe : 'response', responseType: 'blob'});
  }

  updateCancelRemark(data: object): Observable<RespDto> {
    return this.http.post<RespDto>(`${this.apiUrl}/trip/remarkcancel`, data, this.httpHead());
  }

  simulateUpdateTravelCost(data: object): Observable<RespDtoBBM> {
    return this.http.post<RespDtoBBM>(`${this.apiUrl}/masterdata/travelcost/simulate`, data, this.httpHead());
  }

  saveUpdateTravelCost(data: object): Observable<RespDto> {
    return this.http.post<RespDto>(`${this.apiUrl}/masterdata/travelcost/updatemess`, data, this.httpHead());
  }

  exportTravelCostMess(data: object) {
    const url = `${this.apiUrl}/masterdata/travelcost/export`;
    return this.http.post(url, data, {observe : 'response', responseType: 'blob'});
  }

  getVehicleSelect(capacity_id: Guid): Observable<VehicleSelect> {
    const url = `${this.apiUrl}/masterdata/vehicle/select?capacity_id=${capacity_id}`;
    return this.http.get<VehicleSelect>(url, this.httpHead());
  }

  deleteDoDetail(data: object): Observable<RespDto> {
    return this.http.post<RespDto>(`${this.apiUrl}/spk/deletedo`, data, this.httpHead());
  }

  updateVendorTrip(data: object): Observable<RespDto> {
    return this.http.post<RespDto>(`${this.apiUrl}/trip/vendorchange`, data, this.httpHead());
  }

  checkUpdateVendor(transaction_id: Guid):Observable<Boolean> {
    const url = `${this.apiUrl}/trip/checkvendorchange?transaction_id=${transaction_id}`;
    return this.http.get<Boolean>(url, this.httpHead())
  }

  checkSusut(id: string, driver_id: Guid):Observable<RespDto> {
    const url = `${this.apiUrl}/trip/checksusut?id=${id}&driver_id=${driver_id}`;
    return this.http.get<RespDto>(url, this.httpHead())
  }

  printDOTrip(transaction_id: Guid, location: string) {
    const url = `${this.apiUrl}/trip/printdo?transaction_id=${transaction_id}&location=${location}`;
    return this.http.get(url,  {observe : 'response', responseType: 'blob' });
  }

  updatePaymentDate(data: object): Observable<RespDto> {
    return this.http.post<RespDto>(`${this.apiUrl}/trip/updatepaymentdate`, data, this.httpHead());
  }

  getListApprovalDO(): Observable<ListSPK[]> {
    const url = `${this.apiUrl}/spk/listapprovaldo`;
    return this.http.post<ListSPK[]>(url, this.httpHead());
  }

  listDetailApprovalDo(id: string, all: Boolean): Observable<ListDoDetailApp[]> {
    const url = `${this.apiUrl}/spk/detailapprovaldo?main_do=${id}&all=${all}`;
    return this.http.get<ListDoDetailApp[]>(url, this.httpHead());
  }

  spkById(id: string): Observable<ListSPK> {
    const url = `${this.apiUrl}/spk/byid?id=${id}`;
    return this.http.get<ListSPK>(url, this.httpHead());
  }

  approvalDo(data: object): Observable<RespDto> {
    return this.http.post<RespDto>(`${this.apiUrl}/spk/approvedo`, data, this.httpHead());
  }

  setSusutTrip(data: Object): Observable<RespSusutTripDto> {
    return this.http.post<RespSusutTripDto>(`${this.apiUrl}/trip/setsusuttrip`, data, this.httpHead());
  }

  addSusutTrip(data: object): Observable<RespDto> {
    return this.http.post<RespDto>(`${this.apiUrl}/trip/createsusuttrip`, data, this.httpHead());
  }

  getListSPKGetRaw(dt : SpkDtoRaw): Observable<ListSPK[]> {
    const url = `${this.apiUrl}/spk/spkgetraw`;
    return this.http.post<ListSPK[]>(url, dt, this.httpHead());
  }

  getListTriprecordRaw(dt : ListTripRecordRaw): Observable<ListTripRecord[]> {
    const url = `${this.apiUrl}/trip/listraw`;
    return this.http.post<ListTripRecord[]>(url, dt, this.httpHead());
  }

  getListInvoiceRaw(dt : InvoiceRaw): Observable<ListOfInvoice[]> {
    const url = `${this.apiUrl}/invoice/listraw`;
    return this.http.post<ListOfInvoice[]>(url, dt, this.httpHead());
  }

  ReleasePrintDO(transaction_id: Guid): Observable<RespDto> {
    const url = `${this.apiUrl}/trip/releaseprint?transaction_id=${transaction_id}`;
    return this.http.post<RespDto>(url, this.httpHead());
  }

  CheckDOPrint(transaction_id: Guid): Observable<RespDto> {
    const url = `${this.apiUrl}/trip/checkdoprint?transaction_id=${transaction_id}`;
    return this.http.post<RespDto>(url, this.httpHead());
  }

  updateNoDoManual(data: object): Observable<RespDto> {
    return this.http.post<RespDto>(`${this.apiUrl}/spk/updatedomanual`, data, this.httpHead());
  }

  printAllTravelCostExcel(data: object) {
    const url = `${this.apiUrl}/trip/printreporttravelcostexcel`;
    return this.http.post(url, data, {observe : 'response', responseType: 'blob'});
  }

  printAllTravelCostRekapExcel(data: object) {
    const url = `${this.apiUrl}/trip/printreporttravelcostrekapexcel`;
    return this.http.post(url, data, {observe : 'response', responseType: 'blob'});
  }

  getListTravelCostRaw(dt : ListTravelCostRaw): Observable<ListTravelCost[]> {
    const url = `${this.apiUrl}/masterdata/gettravelcostraw`;
    return this.http.post<ListTravelCost[]>(url, dt, this.httpHead());
  }

  printInvOutsatndingExcel(data: object) {
    const url = `${this.apiUrl}/invoice/printoutstandingexcel`;
    return this.http.post(url, data, {observe : 'response', responseType: 'blob'});
  }

  checkReff(data: object): Observable<RespDto> {
    return this.http.post<RespDto>(`${this.apiUrl}/trip/checkreff`, data, this.httpHead());
  }

  checkWbReff(data: object): Observable<RespDto> {
    return this.http.post<RespDto>(`${this.apiUrl}/trip/checkwbreff`, data, this.httpHead());
  }

  cancelCompleteSPK(data: object): Observable<RespDto> {
    return this.http.post<RespDto>(`${this.apiUrl}/spk/cancelcomplete`, data, this.httpHead());
  }
  disableEditSPK(id: Guid): Observable<number> {
    const url = `${this.apiUrl}/spk/disableedit?id=${id}`;
    return this.http.post<number>(url, this.httpHead());
  }

  getLogsSync(): Observable<Logs[]> {
    const url = `${this.apiUrl}/journal/getlogs`;
    return this.http.get<Logs[]>(url, this.httpHead());
  }

  syncAISUJ(data: object): Observable<RespDto> {
    return this.http.post<RespDto>(`${this.apiUrl}/journal/syncaisuj`, data, this.httpHead());
  }

  finishTripByInvoice(data: object): Observable<RespDto> {
    return this.http.post<RespDto>(`${this.apiUrl}/invoice/finishtrip`, data, this.httpHead());
  }

  disableEditTrip(id: Guid): Observable<number> {
    const url = `${this.apiUrl}/trip/disableedit?id=${id}`;
    return this.http.post<number>(url, this.httpHead());
  }

  exportDataSusut(data: object) {
    const url = `${this.apiUrl}/calculation/susut/report`;
    return this.http.post(url, data, {observe : 'response', responseType: 'blob'});
  }

  exportBPJSRekap(data: object) {
    const url = `${this.apiUrl}/calculation/bpjs/printrekapexcel`;
    return this.http.post(url, data, {observe : 'response', responseType: 'blob'});
  }

  exportBPJSRekapJamsostek(data: object) {
    const url = `${this.apiUrl}/calculation/bpjs/printrekapexceljamsostek`;
    return this.http.post(url, data, {observe : 'response', responseType: 'blob'});
  }

  printDoSelected(data: object) {
    const url = `${this.apiUrl}/spk/printdoselected`;
    return this.http.post(url, data, {observe : 'response', responseType: 'blob'});
  }

  getListUser(dt: any, page: number, perPage: number): Observable<DataTablesResponse> {
    const url = `${this.apiUrl}/auth/user/list?page=${page}&perPage=${perPage}`;
    return this.http.post<DataTablesResponse>(url, dt, this.httpHead());
  }

  getRole(): Observable<Role[]> {
    const url = `${this.apiUrl}/auth/user/role`;
    return this.http.get<Role[]>(url, this.httpHead());
  }

  updateUser(data: object): Observable<RespDto> {
    return this.http.post<RespDto>(`${this.apiUrl}/auth/user/update`, data, this.httpHead());
  }

  deleteUser(id: Guid): Observable<RespDto> {
    const url = `${this.apiUrl}/auth/user/delete?id=${id}`;
    return this.http.post<RespDto>(url, this.httpHead());
  }

  resetPassword(id: Guid): Observable<RespDto> {
    const url = `${this.apiUrl}/auth/user/resetpass?id=${id}`;
    return this.http.post<RespDto>(url, this.httpHead());
  }

  getUserById(id: string): Observable<User> {
    const url = `${this.apiUrl}/auth/userid?id=${id}`;
    return this.http.post<User>(url, this.httpHead());
  }

  updatePassword(data: object): Observable<RespDto> {
    return this.http.post<RespDto>(`${this.apiUrl}/auth/user/updatepassword`, data, this.httpHead());
  }
  
  getRoleList(dt: any, page: number, perPage: number): Observable<DataTablesResponse> {
    const url = `${this.apiUrl}/auth/listrole?page=${page}&perPage=${perPage}`;
    return this.http.post<DataTablesResponse>(url, dt, this.httpHead());
  }

  getAllMenu(): Observable<MasterMenu[]> {
    const url = `${this.apiUrl}/auth/listmenu`;
    return this.http.post<MasterMenu[]>(url, this.httpHead());
  }

  getSubmenuById(id: Guid): Observable<MasterSubMenu[]> {
    const url = `${this.apiUrl}/auth/submenubyid?id=${id}`;
    return this.http.post<MasterSubMenu[]>(url, this.httpHead());
  }


  getMasterAuth(id: Guid): Observable<MasterAuth[]> {
    const url = `${this.apiUrl}/auth/listmasterauth?id=${id}`;
    return this.http.post<MasterAuth[]>(url, this.httpHead());
  }

  addUserAuth(data: object): Observable<RespDto> {
    return this.http.post<RespDto>(`${this.apiUrl}/auth/addauth`, data, this.httpHead());
  }

  getAuthByRole(id: string): Observable<AuthMenu[]> {
    const url = `${this.apiUrl}/auth/authmenubyrole?id=${id}`;
    return this.http.post<AuthMenu[]>(url, this.httpHead());
  }

  deleteAuthMenu(id: Guid): Observable<RespDto> {
    const url = `${this.apiUrl}/auth/authmenu/delete?id=${id}`;
    return this.http.post<RespDto>(url, this.httpHead());
  }
  
  createUser(data: object): Observable<RespDto> {
    return this.http.post<RespDto>(`${this.apiUrl}/auth/createuser`, data, this.httpHead());
  }

  addRole(data: object): Observable<RespDto> {
    return this.http.post<RespDto>(`${this.apiUrl}/auth/addrole`, data, this.httpHead());
  }

  deleteRole(data: object): Observable<RespDto> {
    return this.http.post<RespDto>(`${this.apiUrl}/auth/deleterole`, data, this.httpHead());
  }

  addTruckAsset(data: object): Observable<RespDto> {
    return this.http.post<RespDto>(`${this.apiUrl}/masterdata/truck/asset`, data, this.httpHead());
  }

  GetTruckAssetById(data: object): Observable<AssetTruck[]> {
    return this.http.post<AssetTruck[]>(`${this.apiUrl}/masterdata/truck/getasset`, data, this.httpHead());
  }

  deleteTruckAsset(data: object): Observable<RespDto> {
    return this.http.post<RespDto>(`${this.apiUrl}/masterdata/truck/deleteasset`, data, this.httpHead());
  }

  getSPKServiceList(dt: any, page: number, perPage: number, spkNo: string, truckNo: string, driverName: string, status: string): Observable<DataTablesResponse> {
    const url = `${this.apiUrl}/service/spk/list?page=${page}&perPage=${perPage}&spkNo=${spkNo}&truckNo=${truckNo}&driverName=${driverName}&status=${status}`;
    return this.http.post<DataTablesResponse>(url, dt, this.httpHead());
  }

  addSpkService(data: object): Observable<RespDto> {
    return this.http.post<RespDto>(`${this.apiUrl}/service/spk/add`, data, this.httpHead());
  }

  updateSpkService(data: object): Observable<RespDto> {
    return this.http.post<RespDto>(`${this.apiUrl}/service/spk/update`, data, this.httpHead());
  }

  generateSPKNo(data: object): Observable<RespSPKNo> {
    return this.http.post<RespSPKNo>(`${this.apiUrl}/service/spk/runningno`, data, this.httpHead());
  }

  getListSPKDetail(id: Guid): Observable<SpkServiceDetail[]> {
    const url = `${this.apiUrl}/service/spkdetail/list?id=${id}`;
    return this.http.post<SpkServiceDetail[]>(url, this.httpHead());
  }

  processSpkService(data: object): Observable<RespDto> {
    return this.http.post<RespDto>(`${this.apiUrl}/service/spk/process`, data, this.httpHead());
  }

  getListApprovals(data: object): Observable<Approvals[]> {
    const url = `${this.apiUrl}/service/listapproval`;
    return this.http.post<Approvals[]>(url, data, this.httpHead());
  }

  getSPKServiceById(id: Guid): Observable<SpkService> {
    const url = `${this.apiUrl}/service/spk/byid?id=${id}`;
    return this.http.post<SpkService>(url, this.httpHead());
  }

  getSPKServiceDetailById(id: Guid): Observable<SpkServiceDetail[]> {
    const url = `${this.apiUrl}/service/spk/detail/byid?spkId=${id}`;
    return this.http.post<SpkServiceDetail[]>(url, this.httpHead());
  }

  approveService(data: object): Observable<RespDto> {
    return this.http.post<RespDto>(`${this.apiUrl}/service/approval`, data, this.httpHead());
  }

  finishSPKService(data: object): Observable<RespDto> {
    return this.http.post<RespDto>(`${this.apiUrl}/service/spk/finish`, data, this.httpHead());
  }

  addNPB(data: object): Observable<RespDto> {
    return this.http.post<RespDto>(`${this.apiUrl}/service/npb/add`, data, this.httpHead());
  }

  updateNPB(data: object): Observable<RespDto> {
    return this.http.post<RespDto>(`${this.apiUrl}/service/npb/update`, data, this.httpHead());
  }

  getNpbList(dt: any, page: number, perPage: number, npbNo: string, spkNo: string, remark: string, status: string): Observable<DataTablesResponse> {
    const url = `${this.apiUrl}/service/npb/list?page=${page}&perPage=${perPage}&npbNo=${npbNo}&spkNo=${spkNo}&remark=${remark}&status=${status}`;
    return this.http.post<DataTablesResponse>(url, dt, this.httpHead());
  }

  getListNPBDetail(id: Guid): Observable<NpbDetail[]> {
    const url = `${this.apiUrl}/service/npb/detail/list?id=${id}`;
    return this.http.post<NpbDetail[]>(url, this.httpHead());
  }

  getListDataStock(data: object): Observable<ViewStock[]> {
    const url = `${this.apiUrl}/service/stock/list`;
    return this.http.post<ViewStock[]>(url, data, this.httpHead());
  }
  
  getListStock(dt: any, page: number, perPage: number, ItemCode: string, ItemSubKategori: string, ItemKategori: string, GL_Account: string, ItemName: string): Observable<DataTablesResponse> {
    const url = `${this.apiUrl}/service/stock/list?page=${page}&perPage=${perPage}&ItemCode=${ItemCode}&ItemSubKategori=${ItemSubKategori}&ItemKategori=${ItemKategori}&GL_Account=${GL_Account}&ItemName=${ItemName}`;
    return this.http.post<DataTablesResponse>(url, dt, this.httpHead());
  }

  getSpkListNpb(dt: any, page: number, perPage: number, spkNo: string, driverName: string, truckNo: string): Observable<DataTablesResponse> {
    const url = `${this.apiUrl}/service/npb/listspk?page=${page}&perPage=${perPage}&spkNo=${spkNo}&driverName=${driverName}&truckNo=${truckNo}`;
    return this.http.post<DataTablesResponse>(url, dt, this.httpHead());
  }

  UploadDebitNote(fileToUpload: any, location: string): Observable<RespUploadDebitDto> {
    const input: FormData = new FormData();
    input.append('file', fileToUpload);
    input.append('location', location);
    return this.http.post<RespUploadDebitDto>(`${this.apiUrl}/invoice/debit/upload`, input);    
  }

  UploadManualInvoice(fileToUpload: any, location: string): Observable<RespUploadManualInvoiceDto> {
    const input: FormData = new FormData();
    input.append('file', fileToUpload);
    input.append('location', location);
    return this.http.post<RespUploadManualInvoiceDto>(`${this.apiUrl}/invoice/manual/upload`, input);    
  }

  getListDebitNote(dt: any, page: number, perPage: number, ReceiptNo: string, Customer: string, Description: string, Route: string, SPK: string, DO: string): Observable<DataTablesResponse> {
    const url = `${this.apiUrl}/invoice/debit/list?page=${page}&perPage=${perPage}&ReceiptNo=${ReceiptNo}&Customer=${Customer}&Description=${Description}&Route=${Route}&SPK=${SPK}&DO=${DO}`;
    return this.http.post<DataTablesResponse>(url, dt, this.httpHead());
  }

  getListManualInvoice(dt: any, page: number, perPage: number, InvoiceMLNo: string, Customer: string, Description: string, Route: string, SPK: string): Observable<DataTablesResponse> {
    const url = `${this.apiUrl}/invoice/manual/list?page=${page}&perPage=${perPage}&InvoiceMLNo=${InvoiceMLNo}&Customer=${Customer}&Description=${Description}&Route=${Route}&SPK=${SPK}`;
    return this.http.post<DataTablesResponse>(url, dt, this.httpHead());
  } 

  saveUploadDebitNote(data: object): Observable<RespDto> {
    return this.http.post<RespDto>(`${this.apiUrl}/invoice/debit/saveupload`, data, this.httpHead());
  }

  saveUploadManualInvoice(data: object): Observable<RespDto> {
    return this.http.post<RespDto>(`${this.apiUrl}/invoice/manual/saveupload`, data, this.httpHead());
  }

  getDebitNoteDetail(debitId: Guid): Observable<DebitNoteDetail[]> {
    const url = `${this.apiUrl}/invoice/debit/detail?debitId=${debitId}`;
    return this.http.post<DebitNoteDetail[]>(url, this.httpHead());
  }

  getManualInvoiceDetail(id: Guid): Observable<ManualInvoiceDetail[]> {
    const url = `${this.apiUrl}/invoice/manual/detail?id=${id}`;
    return this.http.post<ManualInvoiceDetail[]>(url, this.httpHead());
  }

  printDebit(id: Guid, locationName: string) {
    const location = localStorage.getItem('location');
    const url = `${this.apiUrl}/invoice/debit/print?id=${id}&location=${location}&locationName=${locationName}`;
    return this.http.get(url,  {observe : 'response', responseType: 'blob' });
  }

  printManualInvoice(id: Guid, locationName: string) {
    const location = localStorage.getItem('location');
    const url = `${this.apiUrl}/invoice/manual/print?id=${id}&location=${location}&locationName=${locationName}`;
    return this.http.get(url,  {observe : 'response', responseType: 'blob' });
  }

  generateJournalManualInv(data: object): Observable<RespJournalManualInvoiceDto> {
    return this.http.post<RespJournalManualInvoiceDto>(`${this.apiUrl}/invoice/manual/journal`, data, this.httpHead());
  }

  generateJournalDebitNote(data: object): Observable<RespJournalDebitNoteDto> {
    return this.http.post<RespJournalDebitNoteDto>(`${this.apiUrl}/invoice/debit/journal`, data, this.httpHead());
  }

  deleteDebitNote(id: Guid): Observable<RespDto> {
    return this.http.post<RespDto>(`${this.apiUrl}/invoice/debit/delete/${id}`, this.httpHead());
  }
  
  deleteInvoiceManual(id: Guid): Observable<RespDto> {
    return this.http.post<RespDto>(`${this.apiUrl}/invoice/manual/delete/${id}`, this.httpHead());
  }

  getRevenueType(): Observable<RevenueType[]> {
    const url = `${this.apiUrl}/invoice/revenuetype`;
    return this.http.post<RevenueType[]>(url, this.httpHead());
  }

  printSpkService(id: Guid, locationName: string) {
    const location = localStorage.getItem('location');
    const url = `${this.apiUrl}/service/spk/print?id=${id}&location=${location}&locationName=${locationName}`;
    return this.http.get(url,  {observe : 'response', responseType: 'blob' });
  }

  printNPB(id: Guid, locationName: string) {
    const location = localStorage.getItem('location');
    const url = `${this.apiUrl}/service/npb/print?id=${id}&location=${location}&locationName=${locationName}`;
    return this.http.get(url,  {observe : 'response', responseType: 'blob' });
  }
  
  addSPB(data: object): Observable<RespDto> {
    return this.http.post<RespDto>(`${this.apiUrl}/service/spb/add`, data, this.httpHead());
  }

  updateSPB(data: object): Observable<RespDto> {
    return this.http.post<RespDto>(`${this.apiUrl}/service/spb/update`, data, this.httpHead());
  }

  getSpbList(dt: any, page: number, perPage: number, spbNo: string, spkNo: string, remark: string, status: string): Observable<DataTablesResponse> {
    const url = `${this.apiUrl}/service/spb/list?page=${page}&perPage=${perPage}&spbNo=${spbNo}&spkNo=${spkNo}&remark=${remark}&status=${status}`;
    return this.http.post<DataTablesResponse>(url, dt, this.httpHead());
  }

  getListSPBDetail(id: Guid): Observable<SpbDetail[]> {
    const url = `${this.apiUrl}/service/spb/detail/list?id=${id}`;
    return this.http.post<SpbDetail[]>(url, this.httpHead());
  }

  printSPB(id: Guid, locationName: string) {
    const location = localStorage.getItem('location');
    const url = `${this.apiUrl}/service/spb/print?id=${id}&location=${location}&locationName=${locationName}`;
    return this.http.get(url,  {observe : 'response', responseType: 'blob' });
  }

  addBAPB(data: object): Observable<RespDto> {
    return this.http.post<RespDto>(`${this.apiUrl}/service/bapb/add`, data, this.httpHead());
  }

  updateBAPB(data: object): Observable<RespDto> {
    return this.http.post<RespDto>(`${this.apiUrl}/service/bapb/update`, data, this.httpHead());
  }

  getBapbList(dt: any, page: number, perPage: number, bapbNo: string, spkNo: string, remark: string, status: string): Observable<DataTablesResponse> {
    const url = `${this.apiUrl}/service/bapb/list?page=${page}&perPage=${perPage}&bapbNo=${bapbNo}&spkNo=${spkNo}&remark=${remark}&status=${status}`;
    return this.http.post<DataTablesResponse>(url, dt, this.httpHead());
  }

  getListBAPBDetail(id: Guid): Observable<BapbDetail[]> {
    const url = `${this.apiUrl}/service/bapb/detail/list?id=${id}`;
    return this.http.post<BapbDetail[]>(url, this.httpHead());
  }

  getSubLocation(): Observable<SubLocation[]> {
    const url = `${this.apiUrl}/auth/listsublocation`;
    return this.http.post<SubLocation[]>(url, this.httpHead());
  }

  printBAPB(id: Guid, locationName: string) {
    const location = localStorage.getItem('location');
    const url = `${this.apiUrl}/service/bapb/print?id=${id}&location=${location}&locationName=${locationName}`;
    return this.http.get(url,  {observe : 'response', responseType: 'blob' });
  }

  getExistSPK(code: string, spkId: Guid): Observable<SpkExistDoc> {
    const url = `${this.apiUrl}/service/spkexist?code=${code}&spkId=${spkId}`;
    return this.http.post<SpkExistDoc>(url, this.httpHead());
  }

  getSPKServiceAll(spkNo: string, truckNo: string, driverName: string, status: string, damageType:string): Observable<SpkService[]> {
    const url = `${this.apiUrl}/service/spk/all?spkNo=${spkNo}&truckNo=${truckNo}&driverName=${driverName}&status=${status}&damageType=${damageType}`;
    return this.http.post<SpkService[]>(url, this.httpHead());
  }

  reportDebitNote(data: object) {
    const url = `${this.apiUrl}/invoice/debit/report`;
    return this.http.post(url, data, {observe : 'response', responseType: 'blob'});
  }

  getBAPBId(id: Guid): Observable<Bapb> {
    const url = `${this.apiUrl}/service/bapb/byid?id=${id}`;
    return this.http.post<Bapb>(url, this.httpHead());
  }

  getSPBId(id: Guid): Observable<Spb> {
    const url = `${this.apiUrl}/service/spb/byid?id=${id}`;
    return this.http.post<Spb>(url, this.httpHead());
  }

  getNPBId(id: Guid): Observable<Npb> {
    const url = `${this.apiUrl}/service/npb/byid?id=${id}`;
    return this.http.post<Npb>(url, this.httpHead());
  }

  changeSLCode(data: object): Observable<RespDto> {
    return this.http.post<RespDto>(`${this.apiUrl}/masterdata/driver/changeslcode`, data, this.httpHead());
  }

}


