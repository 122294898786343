<section class="content">    
  <div class="card">
      <div class="card-header">
        <strong>List SPK</strong>
      </div>
      <div class="card-body">
          <table datatable  [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"  class="deeps table-striped">
              <thead>
                <tr>
                  <th>Action</th>
                  <th>No DO</th>
                  <th>Type DO Kecil</th>
                  <th>No SPO</th>
                  <th>Tanggal SPK</th>
                  <th>No SPK</th>
                  <th>No Kontrak</th>
                  <th>No PO</th>
                  <th>Tanggal Kontrak</th>
                  <th>Nama Pelanggan</th>
                  <th>Tanggal Mulai</th>
                  <th>Tanggal Selesai</th>
                  <th>Nama Rute</th>
                  <th>Dari</th>
                  <th>Ke</th>
                  <th>Nama Penjual</th>
                  <th>Nama Material</th>
                  <th>Kuantitas</th>
                  <th>UOM</th>
                  <th>Biaya</th>
                  <th>Tipe Perhitungan</th>
                  <th>Tipe Perjalanan</th>
                  <th>FFA</th>
                  <th>MI</th>
                  <th>KK</th>
                  <th>Toleransi</th>
                  <th>Status</th>
                  <th>Remark</th>
                  <th>Dibuat Oleh</th>
                  <th>Dibuat Pada</th>
                  <th>Diubah Oleh</th>
                  <th>Diubah Pada</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let row of dataListSPK">    
                  <td>
                    <button routerLink="/detail-approvaldo/{{row.spk_id}}" title="Edit" class="btn btn-sm btn-primary" triggers="mouseenter:mouseleave"><i class="fa fa-pen"></i></button>&nbsp;
                  </td>       
                  <td>{{row.main_do_no}}</td>
                  <td>{{row.main_do_type_text}}</td>
                  <td>{{row.spo_no}}</td>
                  <td>{{row.spk_date | slice:0:10}}</td>
                  <td>{{row.spk_no}}</td>
                  <td>{{row.contract_no}}</td>
                  <td>{{row.po_no}}</td>
                  <td>{{row.contract_date | slice:0:10}}</td>
                  <td>{{row.customer_name}}</td>
                  <td>{{row.start_date | slice:0:10}}</td>
                  <td>{{row.finish_date | slice:0:10}}</td>
                  <td>{{row.route_name}}</td>
                  <td>{{row.route_from}}</td>
                  <td>{{row.route_to}}</td>
                  <td>{{row.seller_name}}</td>
                  <td>{{row.material_name}}</td>
                  <td>{{row.quantity}}</td>
                  <td>{{row.uom_name}}</td>
                  <td>{{row.cost}}</td>
                  <td>{{row.charge_type}}</td>
                  <td>{{row.trip_type_name}}</td>
                  <td>{{row.ffa}}</td>
                  <td>{{row.mi}}</td>
                  <td>{{row.kk}}</td>
                  <td>{{row.tolerance}}</td>
                  <td><input type="checkbox" [checked]="row.complete"/></td>
                  <td>{{row.remark}}</td>
                  <td>{{row.created_by_text}}</td>
                  <td>{{row.created_at_text | slice:0:10}}</td>
                  <td>{{row.updated_by_text}}</td>
                  <td>{{row.updated_at_text | slice:0:10}}</td>
              </tr>
              </tbody>
            </table>
      </div>
  </div>

</section>
  