import { AfterViewInit, Component, OnInit, ViewChild,Input  } from '@angular/core';
import { DataTableDirective } from 'angular-datatables'
import { TransportService} from '../../..//services/api.service';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { Guid } from 'guid-typescript'
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { Port } from '@/model/port';
import { CostType } from '@/model/costType';
import { MasterCode } from '@/model/masterCode';
import { AppService } from '@services/app.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-master-costtype',
  templateUrl: './master-costtype.component.html',
  styleUrls: ['./master-costtype.component.scss']
})
export class MasterCosttypeComponent implements OnInit, AfterViewInit {
  @Input() isDisabled: boolean = false;
  costForm: FormGroup;
  @ViewChild('modalSaveClose') modalClose;
  @ViewChild(DataTableDirective, {static: false})
  datatableElement: DataTableDirective;
  dtOptions: any = {};
  public cost: CostType;
  public dataCost: CostType[] = [];
  printDataCost: CostType[] = [];
  public dataScreen: MasterCode[] = [];
  public dataType: MasterCode[] = [];
  dtTrigger = new Subject();
  //public edit : boolean = false;
  @Input() costModel = { id : null, code: '', type:  Guid.create(), name: '', screen: null, gl_code: '', basic_cost: false};
  action: string;
  public dt: any;
  public cb: any;

  constructor( public service: TransportService, private router: Router,  private spinner: NgxSpinnerService, fb: FormBuilder, private appServ : AppService) {
    this.costForm = fb.group({
      // 'email': [null, Validators.compose([Validators.required, Validators.pattern(/^(\d{10}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))$/)])],
      'code': [null, Validators.required],
      'type': [null, Validators.required],
      'name': [null, Validators.required],
      'screen': [null, Validators.required],
      'gl_code': [null, Validators.required],
      'basic_cost': [null, Validators.required],
      'terms': [false]
    });
  }

    ngOnInit(): void {
      const menuUser = localStorage.getItem("menu");
      if (menuUser != "All"){
        const subMenu = localStorage.getItem("acces_menu");
        let arrayMenu =  subMenu.split(",");
        if (arrayMenu.includes("costtype") == false){
          this.router.navigate(["/home"]);
        }
      }
      this.dtOptions = {
        responsive:true,
        serverSide: true,
        processing: true,
        searching : false,
        ordering : false,
        ajax: (dataTablesParameters: any, callback) => {
         this.dt = dataTablesParameters; this.cb = callback;
         this.getDataCost(dataTablesParameters, callback,$('#codesearch').val().toString(),$('#namesearch').val().toString(),true);
        },
        columnDefs: [
          { width: 100, targets: [0,1,4,5,6,7,8,9,10] },
          { width: 300, targets: 2 },
          { width: 200, targets: 3 },
        ],
      };

      const that = this;
      $('.searchInput').on("keyup", function(e) {
        var key = e.which;
        if (key == 13) // the enter key ascii code
        {
          that.getDataCost(that.dt, that.cb,  $('#codesearch').val().toString(),$('#namesearch').val().toString(),false);
        }
      });  

      this.getDataScreen();
      this.getDataType();
      
    }

    getDataCost(dataTablesParameters, callback, code: string, name: string, padging : boolean){ 
      var page = 1;
      if (padging == true){
        page = parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length) + 1;
      }
      this.service.getListCostType(dataTablesParameters, page, dataTablesParameters.length, code, name)
      .subscribe(resp => { 
          this.dataCost = resp.data;
          callback({
            recordsTotal: resp.totalCount,
            recordsFiltered: resp.totalCount,
             data:  []
          });
      });
    }
    
    ngAfterViewInit(): void {
      this.dtTrigger.next(0);
    }
  
    ngOnDestroy(): void {
      this.dtTrigger.unsubscribe();
    }
  
    rerender(): void {
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtTrigger.next(0);
      });
    }

    getDataScreen(){
      this.service.getListMasterCode("costTypeScreen").subscribe(resp => {
        this.dataScreen = resp;
      })
    }

    getDataType(){
      this.service.getListMasterCode("CostType").subscribe(resp => {
        this.dataType = resp;
      })
    }

    onSelectCost(cost: CostType) {
      this.action = "Update";
      {(<HTMLInputElement> document.getElementById("codeBiaya")).disabled = true;}
      this.costModel.id = cost.id;
      this.costModel.code = cost.code;
      this.costModel.type = cost.type;
      this.costModel.name = cost.name;
      this.costModel.screen = cost.screen;
      this.costModel.gl_code = cost.gl_code;
      this.costModel.basic_cost = cost.basic_cost;
    }

    markFormTouched(group: FormGroup | FormArray) {
      Object.keys(group.controls).forEach((key: string) => {
        const control = group.controls[key];
        if (control instanceof FormGroup || control instanceof FormArray) { control.markAsTouched(); this.markFormTouched(control); }
        else { control.markAsTouched(); };
      });
    };
  
  
  saveDataCost(){

    // this.costModel.basic_cost = true;
    //  this.costModel.code = "test";
    //  this.costModel.gl_code = "12324";
    //  //this.costModel.id = null;
    //  this.costModel.name = "name";
    //  this.costModel.screen = Guid.parse("5cda3b12-ac5f-a42f-1871-2340fe876863");
    //  this.costModel.type = Guid.parse("5cda3b12-ac5f-a42f-1871-2340fe876863");

    this.markFormTouched(this.costForm);
    if (this.costForm.valid) {
      Swal.fire({
        title: 'Simpan Data?',
        type: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: '      Yes     '
      }).then((result) => {
        if (result.value) {
          this.spinner.show();
          this.service.addCostType(this.costModel).subscribe(resp => {
            this.spinner.hide();
            if(resp.succes == false){
              Swal.fire({
                title: ""+resp.text,
                type: 'warning',
                confirmButtonText: '      Ok!     '
              });
            }
            else{
              Swal.fire({
                title: ""+resp.text,
                type: 'success',
                confirmButtonText: '      Ok!     '
              }).then((result) => {
                if (result.value) { 
                  this.getDataCost(this.dt, this.cb, "","",true);
                   this.modalClose.nativeElement.click();
                  this.costForm.controls['terms'].setValue(true);
                }              
              });
            }
          });
        }
      })

    } else {
      this.costForm.controls['terms'].setValue(false);
    }
   
    }

  deleteCost(cost: CostType){
    Swal.fire({
      title: 'Delete Data?',
      // text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '      Yes     '
    }).then((result) => {
      if (result.value) {
        this.spinner.show();
        this.service.DeleteCostType(cost.id).subscribe(cost => { 
          this.spinner.hide();
          Swal.fire({
            type: 'success',
            confirmButtonText: '      Succes!     '
          }).then((result) => {
            if (result.value) { 
              this.getDataCost(this.dt, this.cb, "","", true);
            }
          
          }, (error) => {
            //; 
          })
        });
      }
    
    }, (error) => {
      //; 
    });
  }
  
  clearFormPort(){
    this.action = "Input"
    {(<HTMLInputElement> document.getElementById("codeBiaya")).disabled = false;};
     this.costModel.basic_cost = null;
     this.costModel.code = null;
     this.costModel.gl_code = null;
     this.costModel.id = null;
     this.costModel.name = null;
     this.costModel.screen = null;
     this.costModel.type = null;
  }
    
  exportAsXLSX():void {
    this.spinner.show();
    this.service.getListCostType(this.dt, 1, 1000000, $('#codesearch').val().toString(),$('#namesearch').val().toString())
      .subscribe(resp => { 
          this.spinner.hide();
          this.printDataCost = resp.data;
          this.appServ.exportAsExcelFile(this.printDataCost, 'Master_Tipe_Biaya');
      });
  }

      
}



