import { AfterViewInit, Component, OnInit, ViewChild,Input, ChangeDetectorRef, ElementRef  } from '@angular/core';
import { TransportService} from '../../../services/api.service';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { AccClosing } from '@/model/accClosing';
import { MasterCode } from '@/model/masterCode';
import moment from 'moment';

@Component({
  selector: 'app-acc-closing',
  templateUrl: './acc-closing.component.html',
  styleUrls: ['./acc-closing.component.scss']
})
export class AccClosingComponent  implements OnInit, AfterViewInit {
  @ViewChild('modalSimpanClose') modalSimpanClose: ElementRef;
  dtOptionsAcc: any = {};
  dtTriggerAcc = new Subject();
  dataListAcc: AccClosing[] = [];
  listType: MasterCode[] = [];
  listStatus: MasterCode[] = [];
  public dt: any;
  public cb: any;
  @Input() accModel = { id: null, closing_type: null, date_from: null, date_to: null, status: null}
  typeText: string;
  statusText: string;
  constructor( public service: TransportService,  private spinner: NgxSpinnerService, private changeDetectorRef: ChangeDetectorRef) {
  }

    ngOnInit(): void {
      this.service.getListMasterCode("ClosingType").subscribe(resp => {
        this.listType = resp;
        this.accModel.closing_type = resp[0].id;
      })
      this.service.getListMasterCode("ClosingStatus").subscribe(resp => {
        this.listStatus = resp;
        this.accModel.status = resp[0].id;
      })
      this.dtOptionsAcc = {  
        retrieve: true,
        pageLength: 10,
        ordering : false,
        scrollX: true,
        scrollCollapse: true,
        serverSide: true,
        processing: true,
        searching : false,
        ajax: (dataTablesParameters: any, callback) => {
         this.dt = dataTablesParameters; this.cb = callback;
         this.getListAcc(dataTablesParameters, callback, $('#status').val().toString(), $('#type').val().toString(),  true);

        },
        columnDefs: [
          { width: 160, targets: [0, 1,2,3,4,5,6,7,8]}
        ],
      };
      const that = this;

      $('.searchInput').on("keyup", function(e) {
            
        var key = e.which;
        if (key == 13) // the enter key ascii code
        {
          that.getListAcc(that.dt, that.cb, $('#status').val().toString(), $('#type').val().toString(), false);
        }
      });
    }   
    
    ngAfterViewInit(): void {
      this.dtTriggerAcc.next(0);
    }
  
    ngOnDestroy(): void {
     this.dtTriggerAcc.unsubscribe();
    }

    onSelectDataAcc(data: AccClosing){
      this.accModel = data;
      this.typeText = data.closing_type_text;
      this.statusText = data.status_text;
    }

    getListAcc(dataTablesParameters, callback, status: string, type: string, padging: Boolean){
    var page = 1;
      if (padging == true){
        page = parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length) + 1;
      }

      this.service.getListAccClosing(dataTablesParameters, page, dataTablesParameters.length, status, type)
      .subscribe(resp => { 
          this.dataListAcc = resp.data;
          callback({
            recordsTotal: resp.totalCount,
            recordsFiltered: resp.totalCount,
             data:  []
          });
      });
  }

  setNull(){
    var today = moment().format('YYYY-MM-DD');
    this.accModel.date_to = today;
    this.accModel.date_from = today;
    this.accModel = { id: null, closing_type: this.listType[0].id, date_from: today, date_to: today, status: this.listStatus[0].id}
  }

  messageWarning(text: string){
    Swal.fire({
      title: ""+text,
      type: 'warning',
      confirmButtonText: '      Ok!     '
    });
    return;
  }

  addDataClosing(){
    if (this.accModel.closing_type == null || this.accModel.date_from == null || this.accModel.date_to == null || this.accModel.status == null){
      Swal.fire({
        title: "Data Masih Belum Lengkap !",
        type: 'warning',
        confirmButtonText: '      Ok!     '
      });
      return;
    }

    Swal.fire({
      title: 'Simpan Account Closing?',
      type: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '      Yes     '
      }).then((result) => {
        if (result.value) {
          this.service.addDataClosing(this.accModel).subscribe( resp => {
            this.spinner.hide();
            if(resp.succes == false){
              Swal.fire({
                title: ""+resp.text,
                type: 'warning',
                confirmButtonText: '      Ok!     '
              });
            }
            else{
              Swal.fire({
                title: ""+resp.text,
                type: 'success',
                confirmButtonText: '      Ok!     '
              }).then((result) => {
                if (result.value) { 
                  this.getListAcc(this.dt, this.cb, "", "", true);
                  this.modalSimpanClose.nativeElement.click();
                }              
              });
            }
          })
        }
    });
  }

  deleteData(data: AccClosing){
    Swal.fire({
      title: 'Delete Data?',
      type: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '      Yes     '
      }).then((result) => {
        if (result.value) {
          this.spinner.show();
          setTimeout(() => {
            this.spinner.hide();
          }, 5000);
          this.service.deleteClosing(data.id).subscribe(resp => {
            this.spinner.hide();
              if(resp.succes == false){
                Swal.fire({
                  title: ""+resp.text,
                  type: 'warning',
                  confirmButtonText: '      Ok!     '
                });
              }
              else{
                Swal.fire({
                  title: ""+resp.text,
                  type: 'success',
                  confirmButtonText: '      Ok!     '
                }).then((result) => {
                  if (result.value) { 
                    this.getListAcc(this.dt, this.cb, "", "", true);
                  }              
                });
              }
          })

        }
    })
  }
  
}





