import { AfterViewInit, Component, OnInit, ViewChild,Input  } from '@angular/core';
import { DataTableDirective } from 'angular-datatables'
import { TransportService} from '../../..//services/api.service';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { Guid } from 'guid-typescript'
import { Vehicle } from '@/model/vehicle';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthMenu } from '@/model/masterSubMenu';

@Component({
  selector: 'app-master-vehicles',
  templateUrl: './master-vehicles.component.html',
  styleUrls: ['./master-vehicles.component.scss']
})
export class MasterVehiclesComponent implements OnInit, AfterViewInit {
  vehicleForm: FormGroup;
  @ViewChild('modalSaveClose') modalClose;
  @ViewChild(DataTableDirective, {static: false})
  datatableElement: DataTableDirective;
  dtOptions: any = {};
  public vehicle: Vehicle;
  dataVehicles: Vehicle[] = [];
  authAccessPlb: AuthMenu[] = [];
  dtTrigger = new Subject();
  @Input() vehicleModel = { id : null, code: '', name: ''};
  action: string;
  public dt: any;
  public cb: any;

  constructor( public service: TransportService, private router: Router,  private spinner: NgxSpinnerService, fb: FormBuilder) {
    this.vehicleForm = fb.group({
      // 'email': [null, Validators.compose([Validators.required, Validators.pattern(/^(\d{10}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))$/)])],
      'code': [null, Validators.required],
      'name': [null, Validators.required],
      'terms': [false]
    });
  }

  userAcces(){
    const menuUser = localStorage.getItem("menu");
    if (menuUser != "All"){
      const locationName = localStorage.getItem('locationname');
      if (locationName != "Palembang"){
        const subMenu = localStorage.getItem("acces_menu");
        let arrayMenu =  subMenu.split(",");
        if (arrayMenu.includes("vehicles") == false){
          this.router.navigate(["/home"]);
        }
      }
      else{
        this.authAccessPlb = JSON.parse(localStorage.getItem("authAccessPlb"));
        var a = this.authAccessPlb.filter(item => item.subMenu  == "vehicles").length;
        if (a == 0){
          return this.router.navigate(["/home"]);
        }
      }

    }
  }

    ngOnInit(): void {
      this.userAcces();
      this.dtOptions = {
        responsive:true,
        serverSide: true,
        processing: true,
        searching : false,
        ordering : false,
        ajax: (dataTablesParameters: any, callback) => {
         this.dt = dataTablesParameters; this.cb = callback;
         this.getDataVehicle(dataTablesParameters, callback, $('#codesearch').val().toString(), $('#namesearch').val().toString(),true);
        },
        columnDefs: [
          { width: 150, targets: [0,3,4,5,6] },
          { width: 300, targets: [1,2] },
        ],
        
      };

      const that = this;
      $('.searchInput').on("keyup", function(e) {
        var key = e.which;
        if (key == 13) // the enter key ascii code
        {
          that.getDataVehicle(that.dt, that.cb, $('#codesearch').val().toString(), $('#namesearch').val().toString(),false);
        }
      }); 
      
    }   

    getDataVehicle(dataTablesParameters, callback, code: string, name: string, padging : boolean){ 
      var page = 1;
      if (padging == true){
        page = parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length) + 1;
      }
      this.service.getDataVehicle(dataTablesParameters, page, dataTablesParameters.length, code, name)
      .subscribe(resp => { 
          this.dataVehicles = resp.data;
          callback({
            recordsTotal: resp.totalCount,
            recordsFiltered: resp.totalCount,
             data:  []
          });
      });
    }
    
    ngAfterViewInit(): void {
      this.dtTrigger.next(0);
    }
  
    ngOnDestroy(): void {
      this.dtTrigger.unsubscribe();
    }
  
    rerender(): void {
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtTrigger.next(0);
      });
    }

    onSelectVehicle(vehicle: Vehicle) {
      this.action = "Update";
      this.vehicleModel.id = vehicle.id;
      this.vehicleModel.code = vehicle.code;
      this.vehicleModel.name= vehicle.name;
    }
  
  saveDataVehicle(){
    this.service.markFormTouched(this.vehicleForm);
    if (this.vehicleForm.valid) {

      Swal.fire({
        title: 'Simpan Data?',
        type: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: '      Yes     '
      }).then((result) => {
        if (result.value) {
          this.spinner.show();
          this.service.addVehicle(this.vehicleModel).subscribe(resp => {
            this.spinner.hide();
            if(resp.succes == false){
              Swal.fire({
                title: ""+resp.text,
                type: 'warning',
                confirmButtonText: '      Ok!     '
              });
            }
            else{
              Swal.fire({
                title: ""+resp.text,
                type: 'success',
                confirmButtonText: '      Ok!     '
              }).then((result) => {
                if (result.value) { 
                  this.getDataVehicle(this.dt, this.cb, "", "",true);
                  this.modalClose.nativeElement.click();
                  this.vehicleForm.controls['terms'].setValue(true);
                }              
              });
            }
          });
        }
      })
    } else {
      this.vehicleForm.controls['terms'].setValue(false);
    }
   
    }

  deleteVehicle(vehicle: Vehicle){
    Swal.fire({
      title: 'Delete Data?',
      // text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '      Yes     '
    }).then((result) => {
      if (result.value) {
        this.spinner.show();
        this.service.DeleteVehicle(vehicle.id).subscribe(vehicle => { 
          this.spinner.hide();
          Swal.fire({
            type: 'success',
            confirmButtonText: '      Succes!     '
          }).then((result) => {
            if (result.value) { 
              this.getDataVehicle(this.dt, this.cb, "", "",true);
            }
          
          }, (error) => {
            //; 
          })
        });
      }
    
    }, (error) => {
      //; 
    });
  }


  
  clearFormVehicle(){
    this.action = "Input";
    this.vehicleModel.id = null;
    this.vehicleModel.code = null;
    this.vehicleModel.name = null;
  }
    

      
}


