
<section class="content">    
    <div class="card">
      <div class="card-header">
        <strong>Form Bagi DO Opslah</strong>
      </div>
        <div class="card-body">
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group row">
                        <label class="col-md-2 col-form-label" for="text-input">No DO Kecil</label>
                        <input hidden disabled type="text" class="form-control">
                        <div class="col-md-3">
                            <input [(ngModel)]="doFrom" disabled type="text" class="form-control">
                        </div>
                        <div class="col-md-1">
                            <button class="btn btn-sm btn-primary" (click)="showDetail();ModalDetail.show()"  
                            data-toggle="modal" ><i class="fa fa-plus"></i></button>
                        </div>
                        <label class="col-md-1 col-form-label" for="text-input">s/d</label>
                        <div class="col-md-3">
                            <input [(ngModel)]="doTo" disabled type="text" class="form-control">
                        </div>
                        <div class="col-md-1">
                            <button class="btn btn-sm btn-primary" (click)="showDetail2();ModalDetail2.show()"  
                            data-toggle="modal" ><i class="fa fa-plus"></i></button>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-md-2 col-form-label" for="text-input">Tanggal Bagi</label>
                        <div class="col-md-9">
                            <input [ngModel]="distModel.depart_date | date:'yyyy-MM-dd'" (ngModelChange)="distModel.depart_date = $event" type="date"  class="form-control">
                        </div>
                    </div> 
                </div>
                <div class="col-md-6">
                    <div class="form-group row">
                      <label class="col-md-2 col-form-label" for="text-input">Truck</label>
                      <div class="col-md-7">
                        <input value="{{truckName}}" type="text" class="form-control">
                      </div>
                      <div class="col-md-2">
                          <button  class="btn btn-sm btn-primary" (click)="showTruck();ModalTruck.show()"
                          data-toggle="modal"><i class="fa fa-plus"></i></button>
                      </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-md-2 col-form-label" for="text-input">Supir</label>
                        <div class="col-md-7">
                          <input [(ngModel)]="driverName" type="text" class="form-control">
                        </div>
                        <div class="col-md-2">
                            <button class="btn btn-sm btn-primary" (click)="showDriver();ModalDriver.show()"
                            data-toggle="modal"><i class="fa fa-plus"></i></button>
                        </div>
                     </div> 
                </div>
            </div> 
            <br>
            <div style="text-align: center">
                <button type="button" class="btn btn-warning">&nbsp;<i class="fa fa-recycle"></i>&nbsp;&nbsp;<b>Check Uang Jalan</b>&nbsp;&nbsp;</button>&nbsp;&nbsp;&nbsp;&nbsp;
                <!-- <button [disabled]="!save" (click)="SaveData()" type="button" class="btn btn-primary">&nbsp;<i class="fa fa-save"></i>&nbsp;&nbsp;<b>Simpan</b>&nbsp;&nbsp;</button> -->
            </div>
            <br><br>
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group row">
                                <p style="font-size: 22px;font-weight: bold;font-style: italic;" class="col-md-3">Total DO&nbsp;&nbsp; 0</p>
                                <p style="font-size: 22px;font-weight: bold;font-style: italic;" class="col-md-3">Uang Jalan/DO&nbsp;&nbsp; 0</p>
                                <p style="font-size: 22px;font-weight: bold;font-style: italic;" class="col-md-3">Total Uang Jalan&nbsp;&nbsp; 0</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <div class="card">
        <div class="card-body">
            <div class="row">
                <div class="col-md-3">
                    <div class="form-group row">
                        <label class="col-md-3 col-form-label" for="text-input">Jenis Biaya</label>
                        <div class="col-md-8">
                          <select [(ngModel)]="model" (ngModelChange)="selectCost($event)"  class="form-control" >
                            <option></option>
                            <option *ngFor="let row of ListCostType" [ngValue]="row"><b>{{row.name}}</b></option>
                          </select>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group row">
                        <label class="col-md-3 col-form-label" for="text-input">Jumlah</label>
                        <div class="col-md-8">
                            <input [(ngModel)]="addCostModel.amount" currencyMask [options]="{ prefix: '', thousands: '.', decimal: ',' ,precision: '0',align: 'left' }"  type="text" class="form-control" >
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group row">
                        <label class="col-md-3 col-form-label" for="text-input">Keterangan</label>
                        <div class="col-md-8">
                            <input [(ngModel)]="addCostModel.remark" type="text" class="form-control" >
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group row">
                      <label class="col-md-3 col-form-label" for="text-input">Jenis Trip</label>
                      <div class="col-md-8">
                          <select [(ngModel)]="addCostModel.trip" class="form-control">
                          <option selected><b></b></option>
                          <option value="false"><b>Satu Kali</b></option>
                          <option value="true"><b>Setiap Trip</b></option>
                          </select>
                      </div>
                  </div> 
              </div>
            </div>
            <br>
            <div style="text-align: center">
                <button (click)="addCost()" type="button" class="btn btn-sm btn-primary">&nbsp;Tambah&nbsp;</button>
            </div>
            <br>
            <br>
            <div class="card">
                <div class="card-body">
                  <div class="scroll">
                    <table class="table table-striped table-sm">
                        <thead>
                        <tr>
                            <th>No</th>
                            <th>Jenis Biaya</th>
                            <th>Jumlah</th>
                            <th>Keterangan</th>
                            <th>Setiap Trip</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let row of addCostList; let i=index">
                            <td>{{i+1}}</td>
                            <td>{{row.costName}}</td>
                            <td>{{row.amount | number}}</td>
                            <td>{{row.remark}}</td>
                            <td><input disabled [checked]="row.trip == 'true'" type="checkbox"/></td>
                            <!-- <td><button class="btn btn-sm btn-danger" (click)="deleteDataTambahan(i, row)" triggers="mouseenter:mouseleave"><i class="fa fa-trash"></i></button></td> -->
                        </tr>
                        </tbody>
                    </table>
                  </div>
                </div>
            </div>
        </div>
    </div>
  </section>


  
  <div bsModal #ModalDetail="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title">
            <div class="row">
              <strong>Pilih DO</strong>
            </div>
          </div>
          <button #modalFrom type="button" class="close" (click)="ModalDetail.hide()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <table datatable [dtOptions]="dtOptionsDetailDo" [dtTrigger]="dtTriggerDetail" class="table-striped">
                <thead>
                  <tr>
                    <th>Action</th>
                    <th>No DO</th>
                    <th>No DO Besar</th>
                    <th>Tanggal Terbit</th>
                    <th>Status DO</th>
                    <th>Estimasi Berat</th>
                    <th>Estimasi Kemasan</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of listDoDetail">    
                    <td>
                      <button (click)="onSelectFrom(row)" title="Pilih DO" class="btn btn-sm btn-primary" triggers="mouseenter:mouseleave"><i class="fa fa-check"></i></button>
                    </td> 
                    <td>{{row.do_no}}</td>
                    <td>{{row.main_do_no}}</td>
                    <td>{{row.do_date | slice:0:10}}</td>
                    <td>{{row.status_text}}</td>
                    <td>{{row.est_ton}}</td>
                    <td>{{row.est_pack}}</td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <th><input hidden class="searchDo"/></th>
                    <th><input id="doNo" type="text" class="searchDo" placeholder="No DO" /></th>
                    <th><input id="mainDo" type="text" class="searchDo" placeholder="No DO Besar" /></th>
                    <th><input hidden class="searchDo"/></th>
                    <th><input hidden /></th>
                    <th><input hidden class="searchDo"/></th>
                    <th><input hidden class="searchDo"/></th>
                  </tr>
                </tfoot>
              </table>
        </div>
      </div>
    </div>
  </div>

  <div bsModal #ModalDetail2="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title">
            <div class="row">
              <strong>Pilih DO</strong>
            </div>
          </div>
          <button #modalTo type="button" class="close" (click)="ModalDetail2.hide()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <table datatable [dtOptions]="dtOptionsDetailDo2" [dtTrigger]="dtTriggerDetail2" class="table-striped">
                <thead>
                  <tr>
                    <th>Action</th>
                    <th>No DO</th>
                    <th>Tanggal Terbit</th>
                    <th>Status DO</th>
                    <th>Estimasi Berat</th>
                    <th>Estimasi Kemasan</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of listDoDetail2">    
                    <td>
                      <button (click)="onSelectTo(row)" title="Pilih DO" class="btn btn-sm btn-primary" triggers="mouseenter:mouseleave"><i class="fa fa-check"></i></button>
                    </td> 
                    <td>{{row.do_no}}</td>
                    <td>{{row.do_date | slice:0:10}}</td>
                    <td>{{row.status_text}}</td>
                    <td>{{row.est_ton}}</td>
                    <td>{{row.est_pack}}</td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <th><input hidden class="searchDo2"/></th>
                    <th><input id="doNo2" type="text" class="searchDo2" placeholder="No DO" /></th>
                    <th><input hidden class="searchDo2"/></th>
                    <th><input hidden /></th>
                    <th><input hidden class="searchDo2"/></th>
                    <th><input hidden class="searchDo2"/></th>
                  </tr>
                </tfoot>
              </table>
        </div>
      </div>
    </div>
  </div>

  <div bsModal #ModalEkspedisi="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog  modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title">
            <div class="row">
              <strong>Pilih Ekspedisi</strong>
            </div>
          </div>
          <button type="button" class="close" (click)="ModalEkspedisi.hide()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <!-- <app-master-relation></app-master-relation> -->
            <table datatable [dtOptions]="dtOptionsEkspedisi" [dtTrigger]="dtTriggerEkspedisi" class="table-striped">
                <thead>
                  <tr>
                    <th>Action</th>
                    <th>Kode</th>
                    <th>Nama</th>
                    <th>Alamat</th>
                    <th>Kode SL</th>
                    <th>Phone</th>
                    <th>Active</th>
                    <th>Dibuat Oleh</th>
                    <th>Dibuat Pada</th>
                    <th>Diubah Oleh</th>
                    <th>Diubah Pada</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of dataEkspedisi">      
                    <td style="width: 200;">
                      <button (click)="onSelectEkspedisi(row);ModalEkspedisi.hide()" title="Edit"  class="btn btn-sm btn-primary" data-toggle="modal" triggers="mouseenter:mouseleave"><i class="fa fa-check"></i></button>
                    </td>  
                    <td>{{row.relation_code}}</td>
                    <td>{{row.relation_name}}</td>
                    <td>{{row.relation_address}}</td>
                    <td>{{row.relation_sl_code}}</td>
                    <td>{{row.relation_phone}}</td>
                    <td>{{row.active}}</td>
                    <td>{{row.createdby}}</td>
                    <td>{{row.created_at | slice:0:10}}</td>  
                    <td>{{row.updatedby}}</td>  
                    <td>{{row.updated_at | slice:0:10}}</td>   
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <th><input hidden class="searchEkspedisi"/></th>
                    <th><input id="codeEks" type="text" class="searchEkspedisi" placeholder="Kode"/></th>
                    <th><input id="nameEks" type="text" class="searchEkspedisi" placeholder="Nama"/></th>
                    <th><input id="addressEks" type="text" class="searchEkspedisi" placeholder="Alamat"/></th>
                    <th><input id="slEks" type="text" class="searchEkspedisi" placeholder="Kode SL"/></th>
                    <th><input hidden class="searchEkspedisi"/></th>
                    <th><input hidden class="searchEkspedisi"/></th>
                    <th><input hidden class="searchEkspedisi"/></th>
                    <th><input hidden class="searchEkspedisi"/></th>
                    <th><input hidden class="searchEkspedisi"/></th>
                    <th><input hidden class="searchEkspedisi"/></th>
                  </tr>
                </tfoot>
      
              </table>
        </div>
      </div>
    </div>
  </div>

  <div  bsModal #ModalDriver="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog  modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title"><strong>Pilih Supir</strong></div>
          <button type="button" class="close" (click)="ModalDriver.hide()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <table datatable [dtOptions]="dtOptionsDriver" [dtTrigger]="dtTriggerDriver" class="deeps table-striped">
            <thead>
              <tr>
                <th>Action</th>
                <th>Nama</th>
                <th>No SIM</th>
                <th>Jenis SIM</th>
                <th>Agama</th>
                <th>Tempat Lahir</th>
                <th>No KTP</th>
                <th>Tanggal Lahir</th>
                <th>SL</th>
                <th>SL Piutang</th>
                <th>SL Uang Jalan</th>
                <th>Alamat</th>
                <th>Berlaku Sampai</th>
                <th>Kontrak Sampai</th>
                <th>Dibuat Pada</th>
                <th>Diubah Pada</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let row of dataDriver">
                <td>
                  <button (click)="onSelectDriver(row);ModalDriver.hide()" class="btn btn-sm btn-primary"  data-toggle="modal" triggers="mouseenter:mouseleave"><i class="fa fa-check"></i></button>
                </td>
                <td>{{row.driver_name}}</td>
                <td>{{row.driver_license}}</td>
                <td>{{row.driver_license_type}}</td>
                <td>{{row.agama}}</td>
                <td>{{row.birthplace}}</td>
                <td>{{row.ktp_no}}</td>
                <td>{{row.tanggal_lahir | slice:0:10}}</td>
                <td>{{row.sl_code}}</td>
                <td>{{row.sl_code2}}</td>
                <td>{{row.sl_code3}}</td>
                <td>{{row.address}}</td>
                <td>{{row.valid_to | slice:0:10}}</td>
                <td>{{row.valid_to_contract | slice:0:10}}</td>
                <td>{{row.created_at | slice:0:10}}</td>
                <td>{{row.updated_at | slice:0:10}}</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th><input hidden class="searchInputD"/></th>
                <th><input id="driver_name" type="text" class="searchInputD" placeholder="Nama"/></th>
                <th><input id="driver_license" type="text" class="searchInputD" placeholder="No SIM"/></th>
                <th><input id="driver_license_type" type="text" class="searchInputD" placeholder="Tipe SIM"/></th>
                <th><input id="agama" type="text" class="searchInputD" placeholder="Agama"/></th>
                <th><input id="birthplace" type="text" class="searchInputD" placeholder="Tempat Lahir"/></th>
                <th><input id="ktp_no" type="text" class="searchInputD" placeholder="No KTP"/></th>
                <th><input id="tanggal_lahir" type="text" class="searchInputD" placeholder="Tanggal Lahir"/></th>
                <th><input id="sl_code" type="text" class="searchInputD" placeholder="SL"/></th>
                <th><input id="sl_code2" type="text" class="searchInputD" placeholder="SL Piutang"/></th>
                <th><input id="sl_code3" type="text" class="searchInputD" placeholder="SL UJ"/></th>
                <th><input id="address" type="text" class="searchInputD" placeholder="Alamat"/></th>
                <th><input id="valid_to" type="text" class="searchInputD" placeholder="Berlaku Sampai"/></th>
                <th><input id="valid_to_contract" type="text" class="searchInputD" placeholder="Kontrak Sampai"/></th>
                <th><input hidden class="searchInputD"/></th>
                <th><input hidden class="searchInputD"/></th>
              </tr>
            </tfoot>

          </table>
        </div>
      </div>
    </div>
    </div>

    
  <div  bsModal #ModalTruck="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog  modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title"><strong>Pilih Truk</strong></div>
          <button type="button" class="close" (click)="ModalTruck.hide()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <table datatable [dtOptions]="dtOptionsTruck" [dtTrigger]="dtTriggerTruck"  class="deeps table-striped">
            <thead>
              <tr>
                <th>Action</th>
                <th>Nomor Polisi</th>
                <th>Kode Kapasitas</th>
                <th>Lokasi Truk</th>
                <th>Nama Ekspedisi</th>
                <th>Dibuat Oleh</th>
                <th>Dibuat Pada</th>
                <th>Diubah Oleh</th>
                <th>Diubah Pada</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let row of dataTruck">
                <td>
                  <button (click)="onSelectTruck(row);ModalTruck.hide()" class="btn btn-sm btn-primary"  data-toggle="modal" triggers="mouseenter:mouseleave"><i class="fa fa-check"></i></button>
                </td>
                <td>{{row.truck_plate_number}}</td>
                <td>{{row.truck_capacity_code}}</td>
                <td>{{row.truck_location}}</td>
                <td>{{row.truck_vendor_name}}</td>
                <td>{{row.created_by_text}}</td>
                <td>{{row.created_at_text | slice:0:10}}</td>
                <td>{{row.updated_by_text}}</td>
                <td>{{row.updated_at_text | slice:0:10}}</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th><input hidden class="searchInputTruck"/></th>
                <th><input id="platsearch" type="text" class="searchInputTruck" placeholder="Nomor Polisi" name="search-code"/></th>
                <th><input id="codesearch" type="text" class="searchInputTruck" placeholder="Kode Kapasitas" name="search-capacity"/></th>
                <th><input id="locationsearch" type="text" class="searchInputTruck" placeholder="Lokasi Truk" name="search-location"/></th>
                <th><input hidden class="searchInputTruck"/></th>
                <th><input hidden class="searchInputTruck"/></th>
                <th><input hidden class="searchInputTruck"/></th>
                <th><input hidden class="searchInputTruck"/></th>
                <th><input hidden class="searchInputTruck"/></th>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
    </div>

  <ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
  >
  <p style="font-size: 20px; color: white">Loading...</p>
  </ngx-spinner>