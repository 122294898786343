import { AfterViewInit, Component, OnInit, ViewChild,Input, ChangeDetectorRef, ElementRef  } from '@angular/core';
import { DataTableDirective } from 'angular-datatables'
import { TransportService} from '../../../services/api.service';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Guid } from 'guid-typescript';
import { DebtListPay } from '@/model/debtListPay';
import moment from 'moment';
import { AuthMenu } from '@/model/masterSubMenu';

@Component({
  selector: 'app-debt-pay',
  templateUrl: './debt-pay.component.html',
  styleUrls: ['./debt-pay.component.scss']
})
export class DebtPayComponent  implements OnInit, AfterViewInit {
  @ViewChild('ModalSimpanHide') ModalSimpanHide: ElementRef;
  @ViewChild('ModalCancel') ModalCancel: ElementRef;   
  dataListDebtPay: DebtListPay[] = [];
  datatabdtOptionsnt: DataTableDirective;
  dtOptionsPay: any = {};
  dtTriggerPay = new Subject();
  authAccessPlb: AuthMenu[] =[];  
  tolerance: number;
  public dt: any;
  public cb: any;
  amountDebt: number;
  @Input() listModel = {  id: null, trx_reff: null,type_name: null, driver_name: null, driver_license: null, amount: null , remaining: null}
  @Input() debtModel = {  driverdebt_id: null, amount: null, company_paid : null, remarks: null, adjusment_date: null , payment_date: null}
  constructor( public service: TransportService, private router: Router, private spinner: NgxSpinnerService, fb: FormBuilder, private changeDetectorRef: ChangeDetectorRef) {
  }

    ngOnInit(): void {
      this.userAcces();
      this.dtOptionsPay = {  
        retrieve: true,
        pageLength: 10,
        ordering : false,
        // scrollX: true,
        // scrollCollapse: true,
        serverSide: true,
        processing: true,
        searching : false,
        ajax: (dataTablesParameters: any, callback) => {
         this.dt = dataTablesParameters; this.cb = callback;
         this.getDebtPayList(dataTablesParameters, callback, $('#driver_name').val().toString(),  $('#driver_license').val().toString(),
         $('#trx_reff').val().toString(), $('#type_name').val().toString(), true);
        },
        columnDefs: [
          { width: 170, targets: [0,2,3,4,5,6,7]},
          { width: 125, targets: [1]}
        ],
      };

      const that = this;

      $('.searchInput').on("keyup", function(e) {
            
        var key = e.which;
        if (key == 13) // the enter key ascii code
        {
          that.getDebtPayList(that.dt, that.cb, $('#driver_name').val().toString(),  $('#driver_license').val().toString(),
          $('#trx_reff').val().toString(), $('#type_name').val().toString(), false);
        }
      });
      
    }   

    public expanded = false;

    showCheckboxes() {
     
     var checkboxes = document.getElementById("checkboxes");
     if (!this.expanded) {
       checkboxes.style.display = "block";
       this.expanded = true;
     } else {
       checkboxes.style.display = "none";
       this.expanded = false;
     }
   }
    
    ngAfterViewInit(): void {
      this.dtTriggerPay.next(0);
    }
  
    ngOnDestroy(): void {
     this.dtTriggerPay.unsubscribe();
    }

    getDebtPayList(dataTablesParameters, callback, driver_name: string, driver_license: string, trx_reff: string, type_name: string, padging: Boolean){
      var page = 1;
        if (padging == true){
          page = parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length) + 1;
        }

        this.service.getDebtPay(dataTablesParameters, page, dataTablesParameters.length, driver_name, driver_license, trx_reff, type_name)
        .subscribe(resp => { 
            this.dataListDebtPay = resp.data;
            callback({
              recordsTotal: resp.totalCount,
              recordsFiltered: resp.totalCount,
              data:  []
            });
        });
   }

   AddDebtPay(){
    if(
      this.amountDebt == null 
    )
    {
      Swal.fire({
        title: "Data Belum Lengkap !",
        type: 'warning',
        confirmButtonText: '      Ok!     '
      });
      return;
    }
    this.debtModel.driverdebt_id = this.listModel.id;
    this.debtModel.amount = this.amountDebt;
    Swal.fire({
      title: 'Simpan Data?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '      Yes     '
    }).then((result) => {
      if (result.value) {
           this.spinner.show();
            this.service.addDebtPay(this.debtModel).subscribe(resp => { 
              this.spinner.hide();
            if(resp.succes == false){
              Swal.fire({
                title: ""+resp.text,
                type: 'warning',
                confirmButtonText: '      Ok!     '
              });
            }
            else{
              Swal.fire({
                title: ""+resp.text,
                type: 'success',
                confirmButtonText: '      Ok!     '
              }).then((result) => {
                if (result.value) { 
                  this.getDebtPayList(this.dt, this.cb, "",  "",
                   "", "", true);
                  this.ModalSimpanHide.nativeElement.click();
                }              
              }, (error) => {
                ; 
              })
            }
            });
      }
    });
   }

   onSelectDebt(data: any){
      this.listModel = data;
      var today = moment().format('YYYY-MM-DD');
      this.debtModel.payment_date = today;
   }
  
  messageWarning(text: string){
    Swal.fire({
      title: ""+text,
      type: 'warning',
      confirmButtonText: '      Ok!     '
    });
    return;
  }

  userAcces(){
    const menuUser = localStorage.getItem("menu");
    if (menuUser != "All"){
      const locationName = localStorage.getItem('locationname');
      if(locationName != "Palembang"){
        const subMenu = localStorage.getItem("acces_menu");
        let arrayMenu =  subMenu.split(",");
        if (arrayMenu.includes("debtpay") == false){
          return this.router.navigate(["/home"]);
      }
      }
      else{
        this.authAccessPlb = JSON.parse(localStorage.getItem("authAccessPlb"));
        var a = this.authAccessPlb.filter(item => item.subMenu  == "debtpay").length;
        if (a == 0){
          return this.router.navigate(["/home"]);
        }
      }
    }
  }




  
}





