import { AuthMenu } from '@/model/masterSubMenu';
import { UserAuthNew } from '@/model/respDto';
import {AppState} from '@/store/state';
import {UiState} from '@/store/ui/state';
import {Component, HostBinding, OnInit} from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import {Store} from '@ngrx/store';
import { TransportService } from '@services/api.service';
import {AppService} from '@services/app.service';
import { Guid } from 'guid-typescript';
import {Observable} from 'rxjs';

const BASE_CLASSES = 'main-sidebar elevation-4';
@Component({
    selector: 'app-menu-sidebar',
    templateUrl: './menu-sidebar.component.html',
    styleUrls: ['./menu-sidebar.component.scss']
})
export class MenuSidebarComponent implements OnInit {
    @HostBinding('class') classes: string = BASE_CLASSES;
    public ui: Observable<UiState>;
    public user;
    public menu = MENU;
    public subMenu: string;
    public arrayUser: Array<{id: string}> = [];
    public arrayUserChildren: Array<{id: string}> = [];
    tokenDecode : DecodeToken;
    authAccesNew: UserAuthNew[] =[];

    constructor(
        public appService: AppService,
        private store: Store<AppState>,  
        private jwtHelper: JwtHelperService,
        public service: TransportService,
    ) {}

    ngOnInit() {
        this.ui = this.store.select('ui');
        this.ui.subscribe((state: UiState) => {
            this.classes = `${BASE_CLASSES} ${state.sidebarSkin}`;
        });
        this.user = this.appService.user;
        const menuUser = localStorage.getItem("menu");
        const roleid = localStorage.getItem("roleid");
        const location = localStorage.getItem("locationname")

        this.subMenu = localStorage.getItem("acces_menu");
        let arrayMenu =  menuUser.split(",");
        let arrayChildren=  this.subMenu.split(",");

        if (location == "Palembang"){
                this.authAccesNew = JSON.parse(localStorage.getItem("authAccessPlb"));
    
                for (let i = 0; i < this.authAccesNew.length; i++) {
                
                    this.arrayUser.push({
                        id : this.authAccesNew[i].menu
                    }); 
                } 
        
                for (let i = 0; i < this.authAccesNew.length; i++) {
                    
                    this.arrayUserChildren.push({
                        id : this.authAccesNew[i].subMenu
                    }); 
                } 
    

                console.log(this.arrayUser)
                console.log(this.arrayUserChildren)

                if (menuUser != "All"){
                    this.menu = this.menu.filter(f => this.arrayUser.some(item => item.id.includes(f.id) ));
                }
        
                this.menu = this.menu.map((i)=>{
                if (this.subMenu == "All"){
                    return {
                        name: i.name,
                        iconClasses: i.iconClasses,
                        id: i.id,
                        children:  i.children
                    }
                }
                else{
                    return {
                        name: i.name,
                        iconClasses: i.iconClasses,
                        id: i.id,
                        children:  i.children.filter(f => this.arrayUserChildren.some(item => item.id.includes(f.id)))
                    }
                }
                })
        }
        else{
            for (let i = 0; i < arrayMenu.length; i++) {
            
                this.arrayUser.push({
                    id : arrayMenu[i]
                }); 
            } 
    
            for (let i = 0; i < arrayChildren.length; i++) {
                
                this.arrayUserChildren.push({
                    id : arrayChildren[i]
                }); 
            } 
    
            if (menuUser != "All"){
                this.menu = this.menu.filter(f => this.arrayUser.some(item => item.id.includes(f.id) ));
            }
    
            this.menu = this.menu.map((i)=>{
            if (this.subMenu == "All"){
                return {
                    name: i.name,
                    iconClasses: i.iconClasses,
                    id: i.id,
                    children:  i.children
                }
            }
            else{
                return {
                    name: i.name,
                    iconClasses: i.iconClasses,
                    id: i.id,
                    children:  i.children.filter(f => this.arrayUserChildren.some(item => item.id.includes(f.id)))
                }
            }
            })
        }
        

       

      
    }

}

export class DecodeToken {
    username : string;
    firstname : string;
    userid : string;
    location : string;
    locationname : string;
    rolename : string;
    roleid : string;
    menu : string;
    acces_menu: string;
  }
 


export const MENU = [
    // {
    //     name: 'Dashboard',
    //     iconClasses: 'fas fa-tachometer-alt',
    //     path: ['/'],
    //     id: 'material'
    // },
    // {
    //     name: 'Blank',
    //     iconClasses: 'fas fa-file',
    //     path: ['/blank']
    // },
    {
        name: 'Master Data',
        id: 'masterdata',
        iconClasses: 'fas fa-folder',        
        children: [
            {
                name: 'Material',
                iconClasses: 'fa fa-cubes',
                path: ['/master-material'],
                id: 'material'
            },
            {
                name: 'Tipe Kendaraan',
                iconClasses: 'fas fa-car',
                path: ['/master-vehicles'],
                id: 'vehicles'
            }
            ,
            {
                name: 'Tonnase Kapasitas',
                iconClasses: 'fa fa-balance-scale',
                path: ['/master-tonnase-capacity'],
                id: 'tonnasecapacity'
                
            }
            ,
            {
                name: 'Kapasitas',
                iconClasses: 'fas fa-balance-scale',
                path: ['/master-capacity'],
                id: 'capacity'             
            }
            ,
            {
                name: 'Truck',
                iconClasses: 'fas fa-truck',
                path: ['/master-truck'],
                id: 'truck'
            }
            ,
            {
                name: 'Port',
                iconClasses: 'fas fa-ship',
                path: ['/master-port'],
                id: 'ports'  
                
            }
            ,
            {
                name: 'Rute',
                iconClasses: 'fas fa-map',
                path: ['/master-route'],
                id: 'route'
            }
            ,
            {
                name: 'Relasi',
                iconClasses: 'fas fa-users',
                path: ['/master-relation'],
                id: 'relation'
                
            }
            ,
            // {
            //     name: 'Supplier',
            //     iconClasses: 'fas fa-cubes',
            //     path: ['/master-supplier'],
            //     id: 'supplier'
            // }
            // ,
            // {
            //     name: 'Customer',
            //     iconClasses: 'fas fa-users',
            //     path: ['/master-customer'],
            //     id: 'customer'
            // }
            // ,
            // {
            //     name: 'Vendor',
            //     iconClasses: 'fas fa-sitemap',
            //     path: ['/master-vendor'],
            //     id: 'vendor'
            // }
            // ,
            {
                name: 'Tipe Biaya',
                iconClasses: 'fas fa-comments-dollar',
                path: ['/master-costtype'],
                id: 'costtype'
                
            }
            ,
            // {
            //     name: 'Jenis Hutang',
            //     iconClasses: 'fas fa-hand-holding-usd',
            //     path: ['/master-debtpriority'],
            //     id: ''
            // },
            {
                name: 'Prioritas Hutang',
                iconClasses: 'fa fa-credit-card',
                path: ['/master-debtpriority'],
                id: 'debtprriority'                
            },
            {
                name: 'Biaya Perjalanan',
                iconClasses: 'fas fa-road',
                path: ['/master-travelcost'],
                id: 'master-travelcost'
                
            },
            {
                name: 'BBM Biaya Perjalanan',
                iconClasses: 'fas fa-fire',
                path: ['/travelcost-bbm'],
                id: 'travelcostbbm'
                
            },
            {
                name: 'Supir',
                iconClasses: 'fas fa-car',
                path: ['/master-driver'],
                id: 'driver'
                
            },
            {
                name: 'Toleransi',
                iconClasses: 'fa fa-minus',
                path: ['/master-tolerance'],
                id: 'tolerance'
                
            },
            {
                name: 'Kebun',
                iconClasses: 'fa fa-tree',
                path: ['/master-kebun'],
                id: 'farm'
                
            },
            // {
            //     name: 'Mekanik',
            //     iconClasses: 'fas fa-file',
                
            // },
            // {
            //     name: 'Kategori Jenis Pekerjaan',
            //     iconClasses: 'fas fa-file',
                
            // },
            // {
            //     name: 'Jenis Pekerjaan',
            //     iconClasses: 'fas fa-file',
                
            // },
            // {
            //     name: 'Toleransi',
            //     iconClasses: 'fas fa-file',
                
            // },
            // {
            //     name: 'Premi Ritasi',
            //     iconClasses: 'fas fa-file',
                
            // },
            // {
            //     name: 'Premi KM',
            //     iconClasses: 'fas fa-file',
                
            // },
            // {
            //     name: 'Kebun',
            //     iconClasses: 'fas fa-file',
                
            // },
            // {
            //     name: 'Katgeori Sparepart',
            //     iconClasses: 'fas fa-file',
                
            // },
            // {
            //     name: 'Sparepart',
            //     iconClasses: 'fas fa-file',
                
            // },
            // {
            //     name: 'Iuran Supir',
            //     iconClasses: 'fas fa-file',
                
            // },
            // {
            //     name: 'Sparepart UOM Master',
            //     iconClasses: 'fas fa-file',
                
            // },
            // {
            //     name: 'Lokasi Gudang',
            //     iconClasses: 'fas fa-file',
                
            // }
            // ,
            // {
            //     name: 'Tipe Servis',
            //     iconClasses: 'fas fa-file',
                
            // }
        ]
    },
    {
        name: 'Surat Perintah',
        id: 'spk',
        iconClasses: 'fa fa-tasks',        
        children: [
            {
                name: 'SPK List',
                iconClasses: 'fa fa-list',
                path: ['/spk-list'],
                id: ''
            },
            
        ]
    }
    ,
    {
        name: 'Bagi DO',
        id: 'do',
        iconClasses: 'fa fa-share-alt',        
        children: [
            {
                name: 'Bagi DO Manual',
                iconClasses: 'fa fa-list',
                path: ['/trip-manual'],
                id: 'tripmanual'
            },
            {
                name: 'Bagi DO Ekspedisi',
                iconClasses: 'fa fa-share',
                path: ['/trip-distributed'],
                id: 'tripdistributed'
            },
            {
                name: 'Laporan',
                iconClasses: 'fa fa-file',
                path: ['/report-triprecord'],
                id: 'reporttriprecord'
            },
            
            // {
            //     name: 'Laporan Uang Jalan',
            //     iconClasses: 'fa fa-print',
            //     path: ['/report-travelcost']
            // },
            
        ]
    },
    {
        name: 'Realization',
        iconClasses: 'fa fa-stream',  
        id: 'realization',      
        children: [
            {
                name: 'Realization List',
                iconClasses: 'fa fa-list',
                path: ['/realization-list'],
                id: 'realizationlist'
            },
            
        ]
    },
    {
        name: 'Invoice',
        iconClasses: 'fa fa-file-invoice',   
        id: 'invoice',       
        children: [
            {
                name: 'Invoice List',
                iconClasses: 'fa fa-list',
                path: ['/invoice-list'],
                id: 'invoicelist'
            },
            {
                name: 'Debit Note',
                iconClasses: 'fa fa-credit-card',
                path: ['/list-debitnote'],
                id: 'debitnote'
            },
            {
                name: 'Manual Invoice',
                iconClasses: 'fa fa-upload',
                path: ['/invoice-manual'],
                id: 'invoicemanual'
            },
            
        ]
    },
    {
        name: 'Pembayaran',
        iconClasses: 'fas fa-hand-holding-usd',    
        id: 'payment',      
        children: [
            // {
            //     name: 'List Pembayaran',
            //     iconClasses: 'fa fa-list',
            //     path: ['/payment-list'],
            //     id: 'listpayment'
            // },
            {
                name: 'Account Closing',
                iconClasses: 'fas fa-cash-register',
                path: ['/app-acc-closing'],
                id: 'accountclosing'
            }
            
        ]
    },
    {
        name: 'Kalkulasi Supir',
        iconClasses: 'fa fa-id-card',
        id: 'calculationdriver',            
        children: [
            // {
            //     name: 'Premi Driver',
            //     iconClasses: 'fas fa-coins',
            //     path: ['/driver-premi'],
            //     id: 'driverpremi'
            // },
            {
                name: 'Hutang Driver',
                iconClasses: 'fas fa-comments-dollar',
                path: ['/driver-debt'],
                id: 'driverdebt'
            },
            {
                name: 'BPJS Driver',
                iconClasses: 'fa fa-medkit',
                path: ['/driver-bpjs'],
                id: 'driverbpjs'
            },
            {
                name: 'Pengakuan Hutang Susut',
                iconClasses: 'fa fa-compress',
                path: ['/claim-susut'],
                id: 'claimsusut'
            },
            {
                name: 'Bayar Hutang',
                iconClasses: 'fa fa-credit-card',
                path: ['/debt-pay'],
                id: 'debtpay'
            }
            
            
            
        ]
    },
    {
        name: 'Laporan General',
        id: 'reportgeneral',
        iconClasses: 'fa fa-file',        
        children: [
            // {
            //     name: 'List Report',
            //     iconClasses: 'fa fa-list',
            //     path: ['/report-list'],
            //     id: 'listreport'
            // },
            {
                name: 'HTML',
                iconClasses: 'fa fa-list',
                path: ['/report-general'],
                id: 'html'
            },
            // {
            //     name: 'Laporan Susut',
            //     iconClasses: 'fa fa-compress',
            //     path: ['/sps-susut'],
            //     id: 'reportsusut'
            // },
            {
                name: 'Laporan Biaya Perjalanan',
                iconClasses: 'fas fa-road',
                path: ['/travelcost-report'],
                id: 'reporttravelcost'
            },
            {
                name: 'Laporan Performa Supir',
                iconClasses: 'fas fa-car',
                path: ['/driver-perform'],
                id: 'driverperform'
            }
            
        ]
    },
    {
        name: 'Laporan Keuangan',
        iconClasses: 'fas fa-newspaper',   
        id: 'reportpayment',       
        children: [
            {
                name: 'Journal Invoice',
                iconClasses: 'fa fa-file-invoice',
                path: ['/journal-invoice'],
                id: 'invoice'
            },   
            {
                name: 'Journal Uang Jalan',
                iconClasses: 'fas fa-road',
                path: ['/journal-travelcost'],
                id: 'journaltravelcost'
            }             
        ]
    },
    {
        name: 'List Approval',
        iconClasses: 'fas fa-newspaper',   
        id: 'listapproval',       
        children: [
            {
                name: 'Approval DO',
                iconClasses: 'fa fa-list',
                path: ['/list-approvaldo'],
                id: 'listapprovaldo'
            }        
        ]
    },
    {
        name: 'Sync Ais',
        iconClasses: 'fas fa-sync',   
        id: 'syncais',       
        children: [
            {
                name: 'Sync Uang Jalan',
                iconClasses: 'fa fa-sync',
                path: ['/sync-ais'],
                id: 'sync-ais-uj'
            }        
        ]
    },
    {
        name: 'Account Setting',
        iconClasses: 'fas fa-user',   
        id: 'account',       
        children: [
            {
                name: 'List User',
                iconClasses: 'fa fa-list',
                path: ['/user-list'],
                id: 'user-list'
            },
            {
                name: 'List Role',
                iconClasses: 'fa fa-list',
                path: ['/role-list'],
                id: 'role-list'
            }      
        ]
    },
    {
        name: 'Service',
        iconClasses: 'fa fa-wrench',   
        id: 'service',       
        children: [
            {
                name: 'SPK Service',
                iconClasses: 'fa fa-list',
                path: ['/spk-service'],
                id: 'spk-service'
            },
            {
                name: 'Approval Service',
                iconClasses: 'fa fa-check',
                path: ['/approval-service'],
                id: 'approval-service'
            },
            {
                name: 'List NPB',
                iconClasses: 'fa fa-list',
                path: ['/list-npb'],
                id: 'npb-service'
            },
            {
                name: 'List SPB',
                iconClasses: 'fa fa-list',
                path: ['/list-spb'],
                id: 'spb-service'
            }  ,
            {
                name: 'List BAPB',
                iconClasses: 'fa fa-list',
                path: ['/list-bapb'],
                id: 'bapb-service'
            }  
        ]
    },
];
