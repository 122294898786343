<section class="content">    
    <div class="card">
        <div class="card-header">
          <strong>List Approval</strong>
          <div class="float-right">
          <a type="button" (click)="ModalSPK.show()" class="btn btn-sm btn-primary pull-right" data-toggle="modal">&nbsp;<b>Input SPK</b>&nbsp;&nbsp;<i class="fa fa-pen"></i>&nbsp;</a>
          </div>
        </div>
        <div class="card-body">
            <table datatable [dtOptions]="dtOptionsAcc" [dtTrigger]="dtTriggerAcc" class="deeps table-striped">
                <thead>
                  <tr>
                    <th>Action</th>
                    <th>No Dokumen</th>
                    <th>Type</th>
                    <th>Approver</th>
                    <th>Keterangan</th>
                    <th>Dibuat Oleh</th>
                    <th>Dibuat Pada</th>
                    <th>Diubah Oleh</th>
                    <th>Diubah Pada</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of dataListApproval">    
                    <td>
                      <button (click)="onSelectDetail(row);" title="Detail" class="btn btn-sm btn-primary" triggers="mouseenter:mouseleave"><i class="fa fa-list"></i></button>&nbsp;
                      <button [hidden]="!btnProcess"  (click)="onSelectProcess(row);ModalProses.show()" class="btn btn-sm btn-primary"  data-toggle="modal" triggers="mouseenter:mouseleave"><i class="fa fa-play-circle"></i></button>&nbsp;
                      <button [hidden]="!btnApproval" (click)="onselectAprove(row);ModalApprove.show()" class="btn btn-sm btn-primary"  data-toggle="modal" triggers="mouseenter:mouseleave"><i class="fa fa-clipboard-check"></i></button>&nbsp;
                      <button [hidden]="row.status != 'NEW'" (click)="onSelectProcess(row);ModalProses.show()" class="btn btn-sm btn-primary"  data-toggle="modal" triggers="mouseenter:mouseleave"><i class="fa fa-play-circle"></i></button>
                      <!-- <button class="btn btn-sm btn-danger" title="Delete" triggers="mouseenter:mouseleave"><i class="fa fa-trash"></i></button>&nbsp; -->
                    </td>       
                    <td>{{row.documentNumber}}</td>   
                    <td>{{row.type}}</td>      
                    <td>{{row.approver}}</td>       
                    <td>{{row.remark}}</td>         
                    <td>{{row.createdByName}}</td>
                    <td>{{row.createdAt | slice:0:10}}</td>
                    <td>{{row.updatedByName}}</td>
                    <td>{{row.updatedAt | slice:0:10}}</td>
                </tr>
                </tbody>
              </table>
        </div>
    </div>
  </section>

  <div  bsModal #ModalSPK="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog" style="max-width: 80%;" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title">
            <div class="row">
              <strong>List Menu</strong>
            </div>
          </div>
          <button type="button" class="close" (click)="ModalSPK.hide()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group row">
                          <label class="col-md-3 col-form-label" for="text-input">NO SPK</label>
                          <div class="col-md-8">
                              <input disabled type="text" [(ngModel)]="spkModel.SpkNo" value="Test1234" class="form-control">
                          </div>
                        </div>
                        <div class="form-group row">
                          <label class="col-md-3 col-form-label" for="text-input">Truck</label>
                          <input hidden type="text" class="form-control">
                          <div class="col-md-8">
                            <input [(ngModel)]="spkModel.TruckNo" disabled type="text" class="form-control">
                          </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-3 col-form-label" for="text-input">Supir</label>
                            <input hidden type="text" class="form-control">
                            <div class="col-md-8">
                              <input [(ngModel)]="spkModel.DriverName" disabled type="text" class="form-control">
                            </div>
                          </div>
                    </div>
  
                    <div class="col-md-6">
                        <div class="form-group row group">
                            <label class="col-md-3 col-form-label" for="text-input">Include Ganti Oli</label>
                            <div class="col-md-8">
                                <select disabled [(ngModel)]="spkModel.OilChange" class="form-control">
                                <option value=true><b>Ya</b></option>
                                <option value=false selected><b>Tidak</b></option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-3 col-form-label" for="text-input">Waktu Mulai</label>
                            <div class="col-md-8">
                                <input disabled [(ngModel)]="spkModel.StartDate" type="datetime-local" class="form-control">
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-3 col-form-label" for="text-input">Waktu Selesai</label>
                            <div class="col-md-8">
                                <input disabled [(ngModel)]="spkModel.EndDate" type="datetime-local" class="form-control">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
            <br>
            <div class="card">
              <div class="card-body">
                <div class="card">
                    <div class="card-header">
                    </div>
                    <div class="card-body">
                        <table datatable [dtOptions]="dtOptionsDetail" [dtTrigger]="dtTriggerDetail" class="table-striped">
                            <thead>
                            <tr>
                                <!-- <th>Action</th> -->
                                <th>Perbaikan</th>
                                <th>Dibuat Pada</th>
                                <th>Dibuat Oleh</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let row of listSpkDetail; let i=index">    
                                <!-- <td>
                                <button (click)="deleteDataDetail(i, row)" title="Detail" class="btn btn-sm btn-danger" triggers="mouseenter:mouseleave"><i class="fa fa-trash"></i></button>&nbsp;
                                </td>        -->
                                <td>{{row.remark}}</td>
                                <td>{{row.createdAt | slice:0:10}}</td>
                                <td>{{row.createdName}}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
              </div>
            </div>

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" (click)="ModalSPK.hide()">&nbsp;&nbsp;&nbsp;&nbsp;<b>Close</b>&nbsp;&nbsp;&nbsp;&nbsp;</button>
        </div>
      </div>
    </div>
  </div> 

    <div  bsModal #ModalProses="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
        <div class="modal-dialog" style="max-width: 50%;" role="document">
        <div class="modal-content">
            <div class="modal-header">
            <div class="modal-title">
                <div class="row">
                <strong>Proses SPK</strong>
                </div>
            </div>
            <button #modalProcessClose type="button" class="close" (click)="ModalProses.hide()" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
            </div>
            <div class="modal-body">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group row">
                            <label class="col-md-3 col-form-label" for="text-input">NO SPK</label>
                            <div class="col-md-8">
                                <input disabled type="text" [(ngModel)]="proccesModel.SpkNo" value="Test1234" class="form-control">
                            </div>
                            </div>
                            <div class="form-group row group">
                            <label class="col-md-3 col-form-label" for="text-input">Kerusakan</label>
                            <div class="col-md-8">
                                <select [(ngModel)]="proccesModel.DamageType" class="form-control">
                                    <option value="Ringan"><b>Ringan</b></option>
                                    <option value="Sedang"><b>Sedang</b></option>
                                    <option value="Berat"><b>Berat</b></option>
                                    <option value="Sangat Berat"><b>Sangat Berat</b></option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group row group">
                            <label class="col-md-3 col-form-label" for="text-input">Bengkel</label>
                            <div class="col-md-8">
                                <select [(ngModel)]="proccesModel.Workshop" class="form-control">
                                <option value="FGS"><b>FGS</b></option>
                                <option value="Bengkel Luar"><b>Bengkel Luar</b></option>
                                </select>
                            </div>
                        </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group row">
                                <label class="col-md-3 col-form-label" for="text-input">Truck</label>
                                <div class="col-md-8">
                                    <input [(ngModel)]="proccesModel.TruckNo" disabled type="text" class="form-control">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-3 col-form-label" for="text-input">Keterangan</label>
                                <div class="col-md-8">
                                    <textarea [(ngModel)]="proccesModel.RemarkProcess" cols="8" type="text" class="form-control"></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            <div class="modal-footer">
            <button type="button" class="btn btn-danger" (click)="processSPK('REJECTED');">&nbsp;&nbsp;&nbsp;&nbsp;<i class="fa fa-ban"></i>&nbsp;&nbsp;<b>Reject</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</button>
            <button type="button" class="btn btn-primary" (click)="processSPK('APPROVED');">&nbsp;&nbsp;&nbsp;&nbsp;<i class="fa fa-save"></i>&nbsp;&nbsp;<b>Approve</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</button>
            </div>
        </div>
        </div>
    </div> 


    <div  bsModal #ModalApprove="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
        <div class="modal-dialog" style="max-width: 50%;" role="document">
        <div class="modal-content">
            <div class="modal-header">
            <div class="modal-title">
                <div class="row">
                    <strong>Approval  -  {{docNo}}</strong>
                </div>
            </div>
            <button #modalApproveClose type="button" class="close" (click)="ModalApprove.hide()" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
            </div>
            <div class="modal-body">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group row">
                            <label class="col-md-3 col-form-label" for="text-input">Remark</label>
                            <div class="col-md-8">
                                <textarea rows="4" type="text" [(ngModel)]="approveModel.Remark" class="form-control"></textarea>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            <div class="modal-footer">
            <button type="button" class="btn btn-danger" (click)="approve('REJECTED');">&nbsp;&nbsp;&nbsp;&nbsp;<i class="fa fa-ban"></i>&nbsp;&nbsp;<b>Reject</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</button>
            <button type="button" class="btn btn-primary" (click)="approve('APPROVED');">&nbsp;&nbsp;&nbsp;&nbsp;<i class="fa fa-save"></i>&nbsp;&nbsp;<b>Approve</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</button>
            </div>
        </div>
        </div>
    </div> 

    <div  bsModal #ModalNPB="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
      <div class="modal-dialog" style="max-width: 80%;" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <div class="modal-title">
              <div class="row">
                <strong>List Menu</strong>
              </div>
            </div>
            <button type="button" class="close" (click)="ModalNPB.hide()" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="card">
              <div class="card-body">
                  <div class="row">
                      <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-md-3 col-form-label" for="text-input">NO SPB</label>
                            <div class="col-md-8">
                                <input disabled type="text" [(ngModel)]="npbModel.NpbNo" class="form-control">
                            </div>
                          </div>
                          <div class="form-group row">
                              <label class="col-md-3 col-form-label" for="text-input">NO SPK</label>
                              <input  [(ngModel)]="npbModel.SpkId"  hidden type="text" class="form-control">
                              <div class="col-md-8">
                                <input [(ngModel)]="npbModel.SpkNo" disabled type="text" class="form-control">
                              </div>
                          </div>
                      </div>
  
                      <div class="col-md-6">
                          <div class="form-group row">
                              <label class="col-md-3 col-form-label" for="text-input">Tanggal NPB</label>
                              <div class="col-md-8">
                                  <input [(ngModel)]="npbModel.Date" type="datetime-local" class="form-control">
                              </div>
                          </div>
                          <div class="form-group row">
                              <label class="col-md-3 col-form-label" for="text-input">Keterangan</label>
                              <div class="col-md-8">
                                  <input [(ngModel)]="npbModel.Remark" type="text" class="form-control">
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
            </div>
              <br>
              <div class="card">
                <div class="card-body">
                  <div class="card">
                      <div class="card-body">
                          <table datatable [dtOptions]="dtOptionsDetailNPB" [dtTrigger]="dtTriggerDetailNPB" class="table-striped">
                              <thead>
                              <tr>
                                  <th>Kode Item</th>
                                  <th>Nama Item</th>
                                  <th>QTY</th>
                                  <th>UOM</th>
                                  <th>Dibuat Pada</th>
                                  <th>Dibuat Oleh</th>
                              </tr>
                              </thead>
                              <tbody>
                              <tr *ngFor="let row of listNpbDetail; let i=index">
                                  <td>{{row.stockCode}}</td>
                                  <td>{{row.itemName}}</td>
                                  <td>{{row.qty}}</td>
                                  <td>{{row.uom}}</td>
                                  <td>{{row.createdAt | slice:0:10}}</td>
                                  <td>{{row.createdName}}</td>
                              </tr>
                              </tbody>
                          </table>
                      </div>
                  </div>
                </div>
              </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" (click)="ModalNPB.hide()">&nbsp;&nbsp;&nbsp;&nbsp;<b>Close</b>&nbsp;&nbsp;&nbsp;&nbsp;</button>
          </div>
        </div>
      </div>
    </div>

    <div  bsModal #ModalBAPB="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
      <div class="modal-dialog" style="max-width: 80%;" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <div class="modal-title">
              <div class="row">
                <strong>List Menu</strong>
              </div>
            </div>
            <button type="button" class="close" (click)="ModalBAPB.hide()" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="card">
              <div class="card-body">
                  <div class="row">
                      <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-md-3 col-form-label" for="text-input">NO BAPB</label>
                            <div class="col-md-8">
                                <input disabled type="text" [(ngModel)]="bapbModel.BapbNo" class="form-control">
                            </div>
                          </div>
                          <div class="form-group row">
                              <label class="col-md-3 col-form-label" for="text-input">NO SPK</label>
                              <input  [(ngModel)]="bapbModel.SpkId"  hidden type="text" class="form-control">
                              <div class="col-md-8">
                                <input [(ngModel)]="bapbModel.SpkNo" disabled type="text" class="form-control">
                              </div>
                          </div>
                      </div>
  
                      <div class="col-md-6">
                          <div class="form-group row">
                              <label class="col-md-3 col-form-label" for="text-input">Tanggal BAPB</label>
                              <div class="col-md-8">
                                  <input [(ngModel)]="bapbModel.Date" type="datetime-local" class="form-control">
                              </div>
                          </div>
                          <div class="form-group row">
                              <label class="col-md-3 col-form-label" for="text-input">Keterangan</label>
                              <div class="col-md-8">
                                  <input [(ngModel)]="bapbModel.Remark" type="text" class="form-control">
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
            </div>
              <br>
              <div class="card">
                <div class="card-body">
                  <div class="card">
                      <div class="card-body">
                          <table datatable [dtOptions]="dtOptionsDetailBAPB" [dtTrigger]="dtTriggerDetailBAPB" class="table-striped">
                              <thead>
                              <tr>
                                  <th>Kode Item</th>
                                  <th>Nama Item</th>
                                  <th>QTY</th>
                                  <th>UOM</th>
                                  <th>Keterangan</th>
                                  <th>Dibuat Pada</th>
                                  <th>Dibuat Oleh</th>
                              </tr>
                              </thead>
                              <tbody>
                              <tr *ngFor="let row of listBapbDetail; let i=index">
                                  <td>{{row.stockCode}}</td>
                                  <td>{{row.itemName}}</td>
                                  <td>{{row.qty}}</td>
                                  <td>{{row.uom}}</td>
                                  <td>{{row.remark}}</td>
                                  <td>{{row.createdAt | slice:0:10}}</td>
                                  <td>{{row.createdName}}</td>
                              </tr>
                              </tbody>
                          </table>
                      </div>
                  </div>
                </div>
              </div>
  
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" (click)="ModalBAPB.hide()">&nbsp;&nbsp;&nbsp;&nbsp;<b>Close</b>&nbsp;&nbsp;&nbsp;&nbsp;</button>
          </div>
        </div>
      </div>
    </div>
  
    <div  bsModal #ModalSPB="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
      <div class="modal-dialog" style="max-width: 80%;" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <div class="modal-title">
              <div class="row">
                <strong>List Menu</strong>
              </div>
            </div>
            <button type="button" class="close" (click)="ModalSPB.hide()" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="card">
              <div class="card-body">
                  <div class="row">
                      <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-md-3 col-form-label" for="text-input">NO SPB</label>
                            <div class="col-md-8">
                                <input disabled type="text" [(ngModel)]="spbModel.SpbNo" class="form-control">
                            </div>
                          </div>
                          <div class="form-group row">
                              <label class="col-md-3 col-form-label" for="text-input">NO SPK</label>
                              <input  [(ngModel)]="spbModel.SpkId"  hidden type="text" class="form-control">
                              <div class="col-md-8">
                                <input [(ngModel)]="spbModel.SpkNo" disabled type="text" class="form-control">
                              </div>
                          </div>
                      </div>
  
                      <div class="col-md-6">
                          <div class="form-group row">
                              <label class="col-md-3 col-form-label" for="text-input">Tanggal SPB</label>
                              <div class="col-md-8">
                                  <input [(ngModel)]="spbModel.Date" type="datetime-local" class="form-control">
                              </div>
                          </div>
                          <div class="form-group row">
                              <label class="col-md-3 col-form-label" for="text-input">Keterangan</label>
                              <div class="col-md-8">
                                  <input [(ngModel)]="spbModel.Remark" type="text" class="form-control">
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
            </div>
              <br>
              <div class="card">
                <div class="card-body">
                  <div class="card">
                      <div class="card-body">
                          <table datatable [dtOptions]="dtOptionsDetailSPB" [dtTrigger]="dtTriggerDetailSPB" class="table-striped">
                              <thead>
                              <tr>
                                  <th>Kode Item</th>
                                  <th>Nama Item</th>
                                  <th>QTY</th>
                                  <th>UOM</th>
                                  <th>Keterangan</th>
                                  <th>Dibuat Pada</th>
                                  <th>Dibuat Oleh</th>
                              </tr>
                              </thead>
                              <tbody>
                              <tr *ngFor="let row of listSpbDetail; let i=index">
                                  <td>{{row.stockCode}}</td>
                                  <td>{{row.itemName}}</td>
                                  <td>{{row.qty}}</td>
                                  <td>{{row.uom}}</td>
                                  <td>{{row.remark}}</td>
                                  <td>{{row.createdAt | slice:0:10}}</td>
                                  <td>{{row.createdName}}</td>
                              </tr>
                              </tbody>
                          </table>
                      </div>
                  </div>
                </div>
              </div>
  
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" (click)="ModalSPB.hide()">&nbsp;&nbsp;&nbsp;&nbsp;<b>Close</b>&nbsp;&nbsp;&nbsp;&nbsp;</button>
          </div>
        </div>
      </div>
    </div>

  <button hidden #modalSpkShow (click)="ModalSPK.show()" ></button>
  <button hidden #modalNpbShow (click)="ModalNPB.show()" ></button>
  <button hidden #modalBapbShow (click)="ModalBAPB.show()" ></button>
  <button hidden #modalSpbShow (click)="ModalSPB.show()" ></button>


  <ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>