
<section id="header-section" class="content-block">
    <div class="account-content">
        <div class="container">
            <div class="account-box">
            <div class="account-wrapper">
                <h3 class="account-title">User Login</h3>
                <br>
                <!-- <p class="account-subtitle">Access to our dashboard</p> -->
                <form [formGroup]="loginForm" (ngSubmit)="loginByAuth()">
                <div class="form-group">
                    <select name="location"  [(ngModel)]="location" [ngModelOptions]="{standalone: true}" class="form-control">
                        <option disabled selected> Pilih Lokasi </option>
                        <option value="Palembang"><b>EKB</b></option>
                    </select>
                </div>
                <div class="form-group">
                    <!-- <label>Username</label -->
                    <input
                    formControlName="email"
                    type="email"
                    class="form-control"
                    placeholder=" Username"
                    class="form-control ng-untouched ng-pristine ng-valid"
                    />
                </div>
                <div class="form-group">
                    <div class="row">
                    <!-- <div class="col">
                        <label>Password</label>
                    </div> -->
                    </div>
                    <div class="position-relative">
                    <input
                        formControlName="password"
                        type="password"
                        class="form-control"
                        placeholder=" Password"
                    >
                    </div>
                </div>
                <div class="form-group text-center">
                    <button type="submit" name="signin" id="signin" class="form-submit" class="btn btn-primary account-btn">
                    Login
                    </button>
                </div>
                <div class="account-footer">
                </div>
                </form>
            </div>
            </div>
        </div>
        </div> 
</section>


<ngx-spinner
bdColor="rgba(51,51,51,0.8)"
size="medium"
color="#fff"
type="ball-scale-multiple"
>
<p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>