<section class="content">    
    <div class="card">
        <div class="card-header">
          <strong>Hutang Supir</strong>
          <div class="float-right">
            <a type="button" class="btn btn-sm btn-primary pull-right" data-toggle="modal" (click)="showExport();ModalPrint.show()">&nbsp;&nbsp;<b>Export Data</b>&nbsp;&nbsp;&nbsp;<i class="fa fa-file-excel"></i>&nbsp;&nbsp;&nbsp;</a>&nbsp;&nbsp;
          <a type="button" [hidden]="!btnInsert" class="btn btn-sm btn-primary pull-right" data-toggle="modal" (click)="clear();ModalSimpan.show()">&nbsp;&nbsp;<b>Tambah Data Hutang</b>&nbsp;&nbsp;&nbsp;<i class="fa fa-pen"></i>&nbsp;&nbsp;&nbsp;</a>
          </div>
        </div>
        <div class="card-body">
            <table datatable [dtOptions]="dtOptionsDebtHead" [dtTrigger]="dtTriggerDebtHead" class="table-striped">
                <thead>
                  <tr>
                    <th>Action</th>
                    <th>Nama Driver <a type="button" class="primary" (click)="sort()"><i class="fa fa-sort"></i></a></th>
                    <th>Sisa Hutang</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of dataListDebtHead">    
                    <td>
                      <button (click)="onSelectDebt(row);" class="btn btn-sm btn-primary" title="Detail" triggers="mouseenter:mouseleave"><i class="fa fa-list"></i></button>&nbsp;
                    </td>       
                    <td>{{row.driver_name}}</td>
                    <td>{{row.remaining}}</td>
                </tr>
                </tbody>
                <tfoot>
                    <tr>
                      <th><input hidden class="searchInput"/></th>
                      <th><input id="name" type="text" class="searchInput" placeholder="Nama Driver"/></th>
                      <th><input hidden class="searchInput"/></th>
                    </tr>
                  </tfoot>
              </table>
        </div>
    </div>
  </section>

  <div  bsModal #ModalDetail="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog" style="max-width: 70%;" role="document">
      <div class="modal-content">
        <div class="modal-body">
          <div class="card">
            <div class="card-header">
              <strong>Detail Hutang Supir</strong>
              <div class="float-right">
                <a type="button" class="btn btn-sm btn-primary pull-right" data-toggle="modal" (click)="exportDetailDebtById()">&nbsp;&nbsp;<b>Export Detail Debt</b>&nbsp;&nbsp;&nbsp;<i class="fa fa-file-excel"></i>&nbsp;&nbsp;&nbsp;</a>
              </div>
            </div>
            <div class="card-body">
              <div class="scroll">
                <table datatable [dtOptions]="dtOptionsDebtDetail" [dtTrigger]="dtTriggerDebtDetail" class="table-striped">
                  <thead>
                    <tr>
                      <th>Action</th>
                      <th>Nama Supir</th>
                      <th>Sisa Hutang</th>
                      <th>Total Hutang</th>
                      <th>SIM Supir</th>
                      <th>Trx Reff</th>
                      <th>No Kontrak</th>
                      <th>Jenis Hutang</th>
                      <th>Remarks</th>
                      <th>Dibuat Oleh</th>
                      <th>Dibuat Pada</th>
                    </tr>
                    <tr>
                      <th><input hidden class="searchDetail"/></th>
                      <th><input hidden class="searchDetail"/></th>
                      <th><input hidden class="searchDetail"/></th>
                      <th><input hidden class="searchDetail"/></th>
                      <th><input hidden class="searchDetail"/></th>
                      <th><input id="trx_reff" type="text" class="searchDetail" placeholder="Trx Reff"/></th>
                      <th><input hidden class="searchDetail"/></th>
                      <th><input hidden class="searchDetail"/></th>
                      <th><input hidden class="searchDetail"/></th>
                      <th><input hidden class="searchDetail"/></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let row of dataListDebtDetail">     
                      <td>
                        <button (click)="onSelectSPS(row)" class="btn btn-sm btn-primary" title="Detail" triggers="mouseenter:mouseleave"><i class="fa fa-list"></i></button>&nbsp;
                        <!-- <button [hidden]="!btnInsert" (click)="onSelectEdit(row)" class="btn btn-sm btn-primary" title="Edit" triggers="mouseenter:mouseleave"><i class="fa fa-pen"></i></button>&nbsp; -->
                        <button (click)="printDebt(row)" class="btn btn-sm btn-primary" title="Cetak" triggers="mouseenter:mouseleave"><i class="fa fa-print"></i></button>&nbsp;
                        <button (click)="addReason(row);ModalReason.show()" class="btn btn-sm btn-danger" title="Delete" triggers="mouseenter:mouseleave"><i class="fa fa-trash"></i></button>&nbsp;
                        <button (click)="setDebtId(row);ModalRemarks.show()" class="btn btn-sm btn-danger" title="Close" triggers="mouseenter:mouseleave"><i class="fa fa-times"></i></button>&nbsp;
                      </td>       
                      <td>{{row.driver_name}}</td>
                      <td>Rp. {{row.remaining | number}}</td>
                      <td>Rp. {{row.amount | number}}</td>
                      <td>{{row.driver_license}}</td>
                      <td>{{row.trx_reff}}</td>
                      <td>{{row.do_no}}</td>
                      <td>{{row.type_name}}</td>
                      <td>{{row.remarks}}</td>
                      <td>{{row.created_by_name}}</td>
                      <td>{{row.created_at | slice:0:10}}</td>  
                    </tr>
                  </tbody>
        
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button"class="btn btn-secondary" (click)="ModalDetail.hide()"><b>Close</b></button>
        </div>
      </div>
    </div>
  </div>

  <div  bsModal #ModalReason="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog" style="max-width: 50%;"  role="document">
      <div class="modal-content">
        <div class="modal-body">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-12"> 
                  <div class="form-group row">
                    <label class="col-md-4 col-form-label" for="text-input">Alasan Cancel</label>
                    <div class="col-md-7">
                      <textarea [(ngModel)]="reason" rows="4" type="text" class="form-control"></textarea>
                    </div>
                  </div> 
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" #ModalReason class="btn btn-secondary" (click)="ModalReason.hide()"><b>Close</b></button>
          <button type="button" class="btn btn-primary" (click)="deleteDebt()">Hapus Data</button>
        </div>
      </div>
    </div>
  </div>

  <div  bsModal #ModalSimpan="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog" style="max-width: 70%;"  role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title">
            <div class="row">
              <strong>Input Data Hutang</strong>
            </div>
          </div>
          <button type="button" #ModalSimpanHide class="close" (click)="ModalSimpan.hide()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-6"> 
                  <div class="form-group row">
                    <label class="col-md-4 col-form-label" for="text-input">Nama Supir</label>
                    <div class="col-md-6">
                        <input [(ngModel)]="debtModel.driver_name" type="text" class="form-control" >
                    </div>
                    <div class="col-md-2">
                      <button [disabled]="edit" class="btn btn-sm btn-primary" (click)="showDriver();ModalDriver.show()"
                      data-toggle="modal"><i class="fa fa-plus"></i></button>
                    </div>
                  </div> 
                  <div class="form-group row">
                    <label class="col-md-4 col-form-label" for="text-input">Tipe Hutang</label>
                    <div class="col-md-7">
                      <select [(ngModel)]="debtModel.debt_type" class="form-control">
                        <option *ngFor="let row of dataListTypeDebt" [value]='row.id'><b>{{row.debt_name}}</b></option>
                      </select>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-md-4 col-form-label" for="text-input">Jumlah Hutang</label>
                    <div class="col-md-7">
                        <input [(ngModel)]="debtModel.debt_total" currencyMask [options]="{ prefix: '', thousands: '.', decimal: ',' ,precision: '0',align: 'left' }" type="text" class="form-control">
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-md-4 col-form-label" for="text-input">Tanggungan Perusahaan</label>
                    <div class="col-md-7">
                        <input [disabled]="edit" [(ngModel)]="debtModel.company_paid" currencyMask [options]="{ prefix: '', thousands: '.', decimal: ',' ,precision: '0',align: 'left' }" type="text" class="form-control">
                    </div>
                 </div> 
                  <div class="form-group row">
                      <label class="col-md-4 col-form-label" for="text-input">Bayar Tunai</label>
                      <div class="col-md-7">
                          <input [disabled]="edit" [(ngModel)]="debtModel.cash_payment" currencyMask [options]="{ prefix: '', thousands: '.', decimal: ',' ,precision: '0',align: 'left' }" type="text" class="form-control">
                      </div>
                  </div> 
                  <div class="form-group row">
                    <label class="col-md-4 col-form-label" for="text-input">Potongan/Trip</label>
                    <div class="col-md-7">
                        <input [disabled]="edit" [(ngModel)]="debtModel.trip_payment" currencyMask [options]="{ prefix: '', thousands: '.', decimal: ',' ,precision: '0',align: 'left' }" type="text" class="form-control">
                    </div>
                  </div> 
                  <div class="form-group row">
                    <label class="col-md-4 col-form-label" for="text-input"></label>
                    <div class="col-md-7">
                      <textarea [(ngModel)]="debtModel.trip_payment_text" rows="2" type="text" class="form-control"></textarea>
                    </div>
                  </div> 
                </div>
                <div class="col-md-6"> 
                  <div class="form-group row">
                    <label class="col-md-4 col-form-label" for="text-input">No Polisi</label>
                    <div class="col-md-6">
                        <input [(ngModel)]="debtModel.truck_plate" type="text" class="form-control" >
                    </div>
                    <div class="col-md-2">
                      <button [disabled]="edit" class="btn btn-sm btn-primary" (click)="showTruck();ModalTruck.show()"
                      data-toggle="modal"><i class="fa fa-plus"></i></button>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-md-4 col-form-label" for="text-input">Tanggal SPS</label>
                    <div class="col-md-7">
                        <input [ngModel]="debtModel.sps_date | date:'yyyy-MM-dd'"  type="date" (ngModelChange)="debtModel.sps_date = $event"  class="form-control" >
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-md-4 col-form-label" for="text-input">No BA</label>
                    <div class="col-md-7">
                        <input [(ngModel)]="debtModel.ba_no" type="text" class="form-control" >
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-md-4 col-form-label" for="text-input">Perihal</label>
                    <div class="col-md-7">
                      <textarea [(ngModel)]="debtModel.perihal" rows="4" type="text" class="form-control"></textarea>
                    </div>
                  </div> 
                  <div class="form-group row">
                    <label class="col-md-4 col-form-label" for="text-input">Alasan</label>
                    <div class="col-md-7">
                      <textarea [(ngModel)]="debtModel.sps_reason" rows="4" type="text" class="form-control"></textarea>
                    </div>
                  </div> 
                </div>
            </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" (click)="ModalSimpan.hide()"><b>Close</b></button>
          <button type="button" class="btn btn-primary" (click)="addDataDriverDebt()"><i class="fa fa-save"></i>&nbsp;&nbsp;<b>Save</b>&nbsp;&nbsp;</button>
        </div>
      </div>
    </div>
  </div>

    <div  bsModal #ModalTruck="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
      <div class="modal-dialog  modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <div class="modal-title"><strong>Pilih Truk</strong></div>
            <button type="button" class="close" (click)="ModalTruck.hide()" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <table datatable [dtOptions]="dtOptionsTruck" [dtTrigger]="dtTriggerTruck"  class="table-striped">
              <thead>
                <tr>
                  <th>Action</th>
                  <th>Nomor Polisi</th>
                  <th>Kode Kapasitas</th>
                  <th>Lokasi Truk</th>
                  <th>Nama Ekspedisi</th>
                  <th>Dibuat Oleh</th>
                  <th>Dibuat Pada</th>
                  <th>Diubah Oleh</th>
                  <th>Diubah Pada</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let row of dataTruck">
                  <td>
                    <button (click)="onSelectTruck(row);ModalTruck.hide()" class="btn btn-sm btn-primary"  data-toggle="modal" triggers="mouseenter:mouseleave"><i class="fa fa-check"></i></button>
                  </td> 
                  <td>{{row.truck_plate_number}}</td>
                  <td>{{row.truck_capacity_code}}</td>
                  <td>{{row.truck_location}}</td>
                  <td>{{row.truck_vendor_name}}</td>
                  <td>{{row.created_by_text}}</td>
                  <td>{{row.created_at_text | slice:0:10}}</td>  
                  <td>{{row.updated_by_text}}</td>  
                  <td>{{row.updated_at_text | slice:0:10}}</td>       
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th><input hidden class="searchInputTrucks"/></th>
                  <th><input id="platsearch" type="text" class="searchInputTrucks" placeholder="Nomor Polisi" name="search-code"/></th>
                  <th><input id="codesearch" type="text" class="searchInputTrucks" placeholder="Kode Kapasitas" name="search-capacity"/></th>
                  <th><input id="locationsearch" type="text" class="searchInputTrucks" placeholder="Lokasi Truk" name="search-location"/></th>
                  <th><input hidden class="searchInputTrucks"/></th>
                  <th><input hidden class="searchInputTrucks"/></th>
                  <th><input hidden class="searchInputTrucks"/></th>
                  <th><input hidden class="searchInputTrucks"/></th>
                  <th><input hidden class="searchInputTrucks"/></th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
      </div>

      <div bsModal #ModalDetailSPS="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
        <div class="modal-dialog" style="max-width: 70%;" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <button type="button" class="close" (click)="ModalDetailSPS.hide()" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="card">
                <div class="card-header">
                  <strong>Detail Pembayaran Hutang</strong>
                  <div class="float-right">
                    <a type="button" class="btn btn-sm btn-primary pull-right" data-toggle="modal" (click)="exportDetailDebtInstallment()">&nbsp;&nbsp;<b>Export Detail Debt</b>&nbsp;&nbsp;&nbsp;<i class="fa fa-file-excel"></i>&nbsp;&nbsp;&nbsp;</a>
                  </div>
                </div>
                <div class="card-body">
                  <div class="scroll">
                    <table style="width: 100%;" datatable [dtOptions]="dtOptionsDetailSPS" [dtTrigger]="dtTriggerDetailSPS" class="table-striped">
                      <thead>
                        <tr>
                          <th>Jumlah</th>
                          <th>Tanggal Bayar</th>
                          <th>Dibayar Perusahaan</th>
                          <th>Keterangan</th>
                          <th>Transaction Reff</th>
                          <th>Truck No</th>
                          <th>Dibuat Oleh</th>
                          <th>Dibuat Pada</th>
                          <!-- <th>Diubah Oleh</th>
                          <th>Diubah Pada</th> -->
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let row of detailDebt">     
                          <td>Rp. {{row.amount | number}}</td>
                          <td>{{row.payment_date | slice:0:10}}</td>
                          <td><input disabled type="checkbox" [checked]="row.company_paid"/></td>
                          <!-- <td>{{row.company_paid ? 'Ya' : 'Tidak'}}</td> -->
                          <td>{{row.remarks}}</td>
                          <td>{{row.reff}}</td>
                          <td>{{row.truck_no}}</td>
                          <td>{{row.createdby}}</td>
                          <td>{{row.created_at | slice:0:10}}</td>  
                          <!-- <td>{{row.updatedby}}</td>
                          <td>{{row.updated_at | slice:0:10}}</td>   -->
                        </tr>
                      </tbody>            
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div bsModal #ModalSignature="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
        <div class="modal-dialog" style="max-width: 50%" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <div class="modal-title">
              </div>
              <button type="button" class="close" (click)="ModalSignature.hide()" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-12"> 
                        <div class="form-group row">
                            <label class="col-md-4 col-form-label" for="text-input">Disetujui</label>
                            <div class="col-md-8">
                              <select  [(ngModel)]="printModel.disetujui" class="form-control">
                                <option *ngFor="let row of dataSignature" [value]='row.name'><b>{{row.name}}</b></option>
                             </select>   
                            </div>
                        </div> 
                        <div class="form-group row">
                          <label class="col-md-4 col-form-label" for="text-input">Kepala Cabang</label>
                          <div class="col-md-8">
                            <select  [(ngModel)]="printModel.diketahui" class="form-control">
                              <option *ngFor="let row of dataSignature" [value]='row.name'><b>{{row.name}}</b></option>
                           </select>   
                          </div>
                      </div> 
                      <div class="form-group row">
                            <label class="col-md-4 col-form-label" for="text-input">Operational</label>
                            <div class="col-md-5">
                              <select  [(ngModel)]="printModel.diketahui2" class="form-control">
                                <option *ngFor="let row of dataSignature" [value]='row.name'><b>{{row.name}}</b></option>
                             </select>   
                            </div>
                            <div class="col-sm-1">
                              <input [(ngModel)]="printModel.hide_diketahui2" style="width: 40%;" type="checkbox" class="form-control">
                            </div>
                            <label class="col-sm-2 col-form-label" for="text-input">Sembunyikan</label>
                      </div> 
                      <div class="form-group row">
                        <label class="col-md-4 col-form-label" for="text-input">Admin DO/Store</label>
                        <div class="col-md-5">
                          <select  [(ngModel)]="printModel.diketahui3" class="form-control">
                            <option *ngFor="let row of dataSignature" [value]='row.name'><b>{{row.name}}</b></option>
                         </select>   
                        </div>
                        <div class="col-sm-1">
                          <input [(ngModel)]="printModel.hide_diketahui3" style="width: 40%;" type="checkbox" class="form-control">
                        </div>
                        <label class="col-sm-2 col-form-label" for="text-input">Sembunyikan</label>
                     </div> 
                     <div class="form-group row">
                        <label class="col-md-4 col-form-label" for="text-input">HRGA</label>
                        <div class="col-md-5">
                          <select  [(ngModel)]="printModel.diketahui4" class="form-control">
                            <option *ngFor="let row of dataSignature" [value]='row.name'><b>{{row.name}}</b></option>
                         </select>   
                        </div>
                        <div class="col-sm-1">
                          <input [(ngModel)]="printModel.hide_diketahui4" style="width: 40%;" type="checkbox" class="form-control">
                        </div>
                        <label class="col-sm-2 col-form-label" for="text-input">Sembunyikan</label>
                     </div> 
                    </div> 
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button (click)="print()" type="button" class="btn btn-primary"><i class="fas fa-print"></i>&nbsp;&nbsp;&nbsp;<b>Cetak</b>&nbsp;&nbsp;&nbsp;</button> 
          </div>
          </div>
        </div>
      </div>

      <div bsModal #ModalRemarks="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
        <div class="modal-dialog" style="max-width: 50%" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <div class="modal-title">
              </div>
              <button #ModalRemarksClose type="button" class="close" (click)="ModalRemarks.hide()" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-12"> 
                        <div class="form-group row">
                            <label class="col-md-4 col-form-label" for="text-input">Alasan Close</label>
                            <div class="col-md-8">
                              <textarea [(ngModel)]="closeModel.remarks" rows="3" type="text" class="form-control"></textarea>
                            </div>
                        </div> 
                    </div> 
                  </div>
                </div>
              </div>ModalPrint
            </div>
            <div class="modal-footer">
              <button (click)="closeDriverDebt()" type="button" class="btn btn-primary"><i class="fas fa-floppy-disk"></i>&nbsp;&nbsp;&nbsp;<b>Simpan</b>&nbsp;&nbsp;&nbsp;</button> 
          </div>
          </div>
        </div>
      </div>

      
<div bsModal #ModalPrint="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog" style="max-width: 50%;" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title">
        </div>
        <button type="button" class="close" (click)="ModalPrint.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-12"> 
                  <div class="form-group row">
                      <label class="col-md-3 col-form-label" for="text-input">Tanggal Buat Dari</label>
                      <div class="col-md-4">
                          <input type="date" [(ngModel)]="reportModel.from" class="form-control">
                      </div>
                      <label class="col-md-1 col-form-label" for="text-input">Sampai</label>
                      <div class="col-md-4">
                          <input type="date" [(ngModel)]="reportModel.to" class="form-control">
                      </div>
                  </div> 
                  <div class="form-group row">
                      <label class="col-md-3 col-form-label" for="text-input">Tanggal Bayar Dari</label>
                      <div class="col-md-4">
                          <input type="date" [(ngModel)]="reportModel.paymentFrom" class="form-control">
                      </div>
                      <label class="col-md-1 col-form-label" for="text-input">Sampai</label>
                      <div class="col-md-4">
                          <input type="date" [(ngModel)]="reportModel.paymentTo" class="form-control">
                      </div>
                  </div> 
                  <div class="form-group row">
                    <label class="col-md-3 col-form-label" for="text-input">Tipe Hutang</label>
                    <div class="col-md-8">
                      <select [(ngModel)]="reportModel.debt_type" class="form-control">
                        <option></option>
                        <option *ngFor="let row of dataListTypeDebt" [value]='row.debt_name'><b>{{row.debt_name}}</b></option>
                      </select>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-md-3 col-form-label" for="text-input">No SPS</label>
                    <div class="col-md-8">
                        <input type="text" [(ngModel)]="reportModel.sps_no" class="form-control">
                    </div>
                 </div> 
                 <div class="form-group row">
                  <label class="col-md-3 col-form-label" for="text-input">Nama Supir</label>
                  <div class="col-md-7">
                      <input [(ngModel)]="debtModel.driver_name" type="text" class="form-control" >
                  </div>
                  <div class="col-md-2">
                    <button class="btn btn-sm btn-primary" (click)="showDriver();ModalDriver.show()"
                    data-toggle="modal"><i class="fa fa-plus"></i></button>
                  </div>
                </div> 
                  <div class="form-group row">
                    <label class="col-md-3 col-form-label" for="text-input"></label>
                    <div class="col-sm-1">
                      <input [(ngModel)]="reportModel.lunas" style="width: 50%;" type="checkbox" class="form-control">
                    </div>
                    <label class="col-sm-3 col-form-label" for="text-input">Include Yang Sudah Lunas</label>
                  </div>
                  <div class="form-group row">
                    <label class="col-md-3 col-form-label" for="text-input"></label>
                    <div class="col-sm-1">
                      <input [(ngModel)]="detail" style="width: 50%;" type="checkbox" class="form-control">
                    </div>
                    <label class="col-sm-3 col-form-label" for="text-input">Include Detail Cicilan</label>
                  </div>
                  <label class="col-md-2 col-form-label" for="text-input"></label>
              </div> 
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" (click)="exportDetailDebt()" class="btn btn-primary"><i class="fa fa-file-excel"></i>&nbsp;<b>Export data</b>&nbsp;</button>
        <button type="button" #modalPrint class="btn btn-secondary" (click)="ModalPrint.hide()">&nbsp;<b>Cancel</b>&nbsp;</button> 
    </div>
    </div>
  </div>
</div>


<div  bsModal #ModalDriver="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog  modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title"><strong>Pilih Supir</strong></div>
        <button type="button" class="close" (click)="ModalDriver.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <table datatable [dtOptions]="dtOptionsDriver" [dtTrigger]="dtTriggerDriver" class="table-striped">
          <thead>
            <tr>
              <th>Action</th>
              <th>Nama</th>
              <th>No SIM</th>
              <th>Jenis SIM</th>
              <th>Agama</th>
              <th>Tempat Lahir</th>
              <th>No KTP</th>
              <th>Tanggal Lahir</th>
              <th>SL</th>
              <th>SL Piutang</th>
              <th>SL Uang Jalan</th>
              <th>Alamat</th>
              <th>Berlaku Sampai</th>
              <th>Kontrak Sampai</th>
              <th>Dibuat Pada</th>
              <th>Diubah Pada</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let row of dataDriver">
              <td>
                <button (click)="onSelectDriver(row);ModalDriver.hide()" class="btn btn-sm btn-primary"  data-toggle="modal" triggers="mouseenter:mouseleave"><i class="fa fa-check"></i></button>
              </td>
              <td>{{row.driver_name}}</td>
              <td>{{row.driver_license}}</td>
              <td>{{row.driver_license_type}}</td>
              <td>{{row.agama}}</td>
              <td>{{row.birthplace}}</td>
              <td>{{row.ktp_no}}</td>
              <td>{{row.tanggal_lahir | slice:0:10}}</td>
              <td>{{row.sl_code}}</td>
              <td>{{row.sl_code2}}</td>
              <td>{{row.sl_code3}}</td>
              <td>{{row.address}}</td>
              <td>{{row.valid_to | slice:0:10}}</td>
              <td>{{row.valid_to_contract | slice:0:10}}</td>
              <td>{{row.created_at | slice:0:10}}</td>  
              <td>{{row.updated_at | slice:0:10}}</td>   
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <th><input hidden class="searchInputD"/></th>
              <th><input id="driver_name" type="text" class="searchInputD" placeholder="Nama"/></th>
              <th><input id="driver_license" type="text" class="searchInputD" placeholder="No SIM"/></th>
              <th><input id="driver_license_type" type="text" class="searchInputD" placeholder="Tipe SIM"/></th>
              <th><input id="agama" type="text" class="searchInputD" placeholder="Agama"/></th>
              <th><input id="birthplace" type="text" class="searchInputD" placeholder="Tempat Lahir"/></th>
              <th><input id="ktp_no" type="text" class="searchInputD" placeholder="No KTP"/></th>
              <th><input id="tanggal_lahir" type="text" class="searchInputD" placeholder="Tanggal Lahir"/></th>
              <th><input id="sl_code" type="text" class="searchInputD" placeholder="SL"/></th>
              <th><input id="sl_code2" type="text" class="searchInputD" placeholder="SL Piutang"/></th>
              <th><input id="sl_code3" type="text" class="searchInputD" placeholder="SL UJ"/></th>
              <th><input id="address" type="text" class="searchInputD" placeholder="Alamat"/></th>
              <th><input id="valid_to" type="text" class="searchInputD" placeholder="Berlaku Sampai"/></th>
              <th><input id="valid_to_contract" type="text" class="searchInputD" placeholder="Kontrak Sampai"/></th>
              <th><input hidden class="searchInputD"/></th>
              <th><input hidden class="searchInputD"/></th>
            </tr>
          </tfoot>
    
        </table>
      </div>
    </div>
  </div>
  </div>
      
<button #modalDetailSPSShow hidden (click)="ModalDetailSPS.show()"></button>
<button #modalDetailShow hidden (click)="ModalDetail.show()"></button>
<button #ModalSignatureShow hidden (click)="ModalSignature.show()"></button>
<button #ModalSimpanShow hidden (click)="ModalSimpan.show()"></button>

<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>