
<!-- Main content -->
<section class="content">    
    <div class="card">
      <div class="card-header">
      <strong>List Data Prioritas Hutang</strong>
      <div class="float-right">
        <a type="button" class="btn btn-sm btn-warning pull-right" data-toggle="modal" (click)="exportAsXLSX()">&nbsp;&nbsp;&nbsp;<b>Export Data</b>&nbsp;&nbsp;&nbsp;&nbsp;<i class="fas fa-file-excel"></i>&nbsp;&nbsp;&nbsp;</a>&nbsp;&nbsp;&nbsp;&nbsp;
        <a type="button" class="btn btn-sm btn-primary pull-right" data-toggle="modal" (click)="ModalDebt.show();clearFormDebt()"><b>&nbsp;&nbsp;&nbsp;Input Prioritas Hutang&nbsp;&nbsp;&nbsp;&nbsp;</b><i class="fa fa-pen"></i>&nbsp;&nbsp;&nbsp;</a>
      </div>
    </div>
      <div class="card-body">
    <table datatable  [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"  class="table-striped">
      <thead>
        <tr>
          <th>Action</th>
          <th>Code</th>
          <th>Nama</th>
          <th>Urutan</th>
          <th>Nama Urutan</th>
          <th>Dibuat Oleh</th>
          <th>Dibuat Pada</th>
          <th>Diubah Oleh</th>
          <th>Diubah Pada</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let row of dataDebt">    
          <td>
            <button title="Hapus" class="btn btn-sm btn-danger" triggers="mouseenter:mouseleave" (click)="deleteDebt(row);"><i class="fa fa-trash"></i></button>&nbsp;
            <button title="Edit" (click)="onSelectDebt(row);ModalDebt.show()" class="btn btn-sm btn-primary"  data-toggle="modal"triggers="mouseenter:mouseleave"><i class="fa fa-pen"></i></button>
          </td>       
          <td>{{row.code}}</td>
          <td>{{row.debt_name}}</td>
          <td>{{row.priority}}</td>
          <td>{{row.sequence_name}}</td>
          <td>{{row.createdby}}</td>
          <td>{{row.created_at | slice:0:10}}</td>  
          <td>{{row.updatedby}}</td>  
          <td>{{row.updated_at | slice:0:10}}</td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <th><input hidden class="searchInput"/></th>
          <th><input id="codesearch" type="text" class="searchInput" placeholder="Kode"/></th>
          <th><input id="namesearch" type="text" class="searchInput" placeholder="Nama"/></th>
          <th><input id="seqsearch" type="text" class="searchInput" placeholder="Urutan"/></th>
          <th><input id="nameseqsearch" type="text" class="searchInput" placeholder="Nama Urutan"/></th>
          <th><input hidden class="searchInput"/></th>
          <th><input hidden class="searchInput"/></th>
          <th><input hidden class="searchInput"/></th>
          <th><input hidden class="searchInput"/></th>
        </tr>
      </tfoot>

    </table>
  </div>
  </div>
</section>


<div  bsModal #ModalDebt="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog  modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title"><strong>{{action}} Data Prioritas Hutang</strong></div>
          <small><code style="color: black">&nbsp;&nbsp;*(semua kolom wajib di isi)</code></small>
          <button type="button" class="close" #modalSaveClose (click)="ModalDebt.hide()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div [formGroup]="debtForm">
            <div class="form-group row">
                <label class="col-md-3 col-form-label" for="text-input">Urutan</label>
                <div class="col-md-9">
                  <input [(ngModel)]="debtModel.priority" type="text" class="form-control" formControlName="priority">
                  <span class="text-danger" *ngIf="debtForm.controls['priority'].hasError('required') && 
                  (debtForm.controls['priority'].dirty || debtForm.controls['priority'].touched)">Kolom Tidak Boleh Kosong!</span>
              </div>
              </div> 
              <div class="form-group row">
                <label class="col-md-3 col-form-label" for="text-input">Nama Urutan</label>
                <div class="col-md-9">
                  <select [(ngModel)]="debtModel.sequence_name" id="select" name="select" class="form-control" formControlName="sequence_name">
                    <option *ngFor="let row of dataType" [value]='row.text'><b>{{row.text}}</b></option>
                  </select>
                  <span class="text-danger" *ngIf="debtForm.controls['sequence_name'].hasError('required') && 
                  (debtForm.controls['sequence_name'].dirty || debtForm.controls['sequence_name'].touched)">Kolom Tidak Boleh Kosong!</span>
              </div>
              </div> 
            <div class="form-group row">
              <label class="col-md-3 col-form-label" for="text-input">Kode</label>
              <div class="col-md-9">
                <input [(ngModel)]="debtModel.code" type="text" class="form-control" formControlName="code">
                <span class="text-danger" *ngIf="debtForm.controls['code'].hasError('required') && 
                (debtForm.controls['code'].dirty || debtForm.controls['code'].touched)">Kolom Tidak Boleh Kosong!</span>
            </div>
            </div> 
            <div class="form-group row">
              <label class="col-md-3 col-form-label" for="text-input">Nama</label>
              <div class="col-md-9">
                <input [(ngModel)]="debtModel.debt_name" type="text" class="form-control" formControlName="debt_name">
                <span class="text-danger" *ngIf="debtForm.controls['debt_name'].hasError('required') && 
                (debtForm.controls['debt_name'].dirty || debtForm.controls['debt_name'].touched)">Kolom Tidak Boleh Kosong!</span>
            </div>
            </div> 
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" (click)="ModalDebt.hide()"><b>Close</b></button>
            <button type="button" class="btn btn-primary"  (click)="saveDataDebt();"><i class="fa fa-save"></i>&nbsp;&nbsp;<b>Save</b>&nbsp;&nbsp;</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ngx-spinner  bdColor = "rgba(248,248,248,0.95)" size = "large" color = "#262424" type = "ball-spin-fade" >
    <p style="font-size: 20px; color: white"></p>
  </ngx-spinner>


  