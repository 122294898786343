<section class="content">
    <div class="card">
        <div class="card-header">
          <strong>BPJS Supir</strong>
          <div class="float-right">
          <a type="button" class="btn btn-sm btn-primary pull-right" data-toggle="modal" (click)="ModalHeader.show()">&nbsp;&nbsp;<b>Tambah Data</b>&nbsp;&nbsp;&nbsp;<i class="fa fa-pen"></i>&nbsp;&nbsp;&nbsp;</a>
          </div>
        </div>
        <div class="card-body">
            <table datatable [dtOptions]="dtOptionsHead" [dtTrigger]="dtTriggerHead" class="table-striped">
                <thead>
                  <tr>
                    <th>Action</th>
                    <th>Payment Date</th>
                    <th>Bulan</th>
                    <th>Jenis Iuran</th>
                    <th>Tahun</th>
                    <th>Tanggal Input</th>
                    <th>Input Oleh</th>
                    <th>Tanggal Ubah</th>
                    <th>Ubah Oleh</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of dataListHead">
                    <td>
                      <button (click)="onSelectCriteria(row);" class="btn btn-sm btn-primary" title="Detail" triggers="mouseenter:mouseleave"><i class="fa fa-list"></i></button>&nbsp;
                      <button (click)="onSelectGenerate(row);" class="btn btn-sm btn-primary" title="Generate" triggers="mouseenter:mouseleave"><i class="fas fa-random"></i></button>&nbsp;
                      <button (click)="printRekapan(row);ModalSignature.show()" class="btn btn-sm btn-warning" title="Cetak Rekapan"><i class="fa fa-print"></i></button>&nbsp;
                      <button (click)="printExcel(row)" class="btn btn-sm btn-warning" title="Export Rekapan Excel"><i class="fa fa-file-excel"></i></button>&nbsp;
                      <button (click)="printDetail(row);ModalSignature.show()" class="btn btn-sm btn-primary" title="Cetak Detail"><i class="fa fa-print"></i></button>&nbsp;
                    </td>
                    <td>{{row.payment_date | slice:0:10}}</td>
                    <td>{{row.month_name}}</td>
                    <td>{{row.subscription_name}}</td>
                    <td>{{row.year}}</td>
                    <td>{{row.created_at | slice:0:10}}</td>
                    <td>{{row.create_user}}</td>
                    <td>{{row.updated_at | slice:0:10}}</td>
                    <td>{{row.update_user}}</td>
                </tr>
                </tbody>
                <tfoot>
                    <tr>
                      <th><input hidden class="searchInput"/></th>
                      <th><input hidden class="searchInput"/></th>
                      <th><input id="bulan" type="text" class="searchInput" placeholder="Bulan"/></th>
                      <th><input id="jenis" type="text" class="searchInput" placeholder="Jenis Iuran"/></th>
                      <th><input id="tahun" type="text" class="searchInput" placeholder="Tahun"/></th>
                      <th><input hidden class="searchInput"/></th>
                      <th><input hidden class="searchInput"/></th>
                      <th><input hidden class="searchInput"/></th>
                      <th><input hidden class="searchInput"/></th>
                    </tr>
                </tfoot>
              </table>
        </div>
    </div>
  </section>


  <div  bsModal #ModalCriteria="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog" style="max-width: 70%;" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title">
            <div class="row">
              <strong>Detail BPJS</strong>
            </div>
          </div>
          <button type="button" class="close" (click)="ModalCriteria.hide()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="card">
            <div class="card-header">
                <strong>BPJS Supir</strong>
                <div class="float-right">
                <a type="button" class="btn btn-sm btn-primary pull-right" data-toggle="modal" (click)="clear();ModalCriteriaAdd.show()">&nbsp;&nbsp;<b>Tambah Data</b>&nbsp;&nbsp;&nbsp;<i class="fa fa-pen"></i>&nbsp;&nbsp;&nbsp;</a>
                </div>
            </div>
            <div class="card-body">
              <div class="scroll">
                <table datatable [dtOptions]="dtOptionsCriteria" [dtTrigger]="dtTriggerCriteria" class="table-striped">
                  <thead>
                    <tr>
                      <th>Action</th>
                      <th>No Kontrak</th>
                      <th>Nama Supir</th>
                      <th>Bulan</th>
                      <th>Tahun</th>
                      <th>Tipe</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let row of dataListCriteria">
                      <td>
                        <button (click)="onSelectDetail(row);ModalCriteriaAdd.show()" class="btn btn-sm btn-primary" title="Detail" triggers="mouseenter:mouseleave"><i class="fa fa-list"></i></button>&nbsp;
                        <button class="btn btn-sm btn-danger" title="Delete" triggers="mouseenter:mouseleave" (click)="deleteBpjsCriteria(row);"><i class="fa fa-trash"></i></button>&nbsp;
                      </td>
                      <td>{{row.contractNo}}</td>
                      <td>{{row.drivername}}</td>
                      <td>{{row.month}}</td>
                      <td>{{row.year}}</td>
                      <td>{{row.subscriptionType}}</td>
                      <td>{{row.amount}}</td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <th><input hidden class="searchInput"/></th>
                      <th><input hidden class="searchInput"/></th>
                      <th><input hidden class="searchInput"/></th>
                      <th><input hidden class="searchInput"/></th>
                      <th><input hidden class="searchInput"/></th>
                      <th><input hidden class="searchInput"/></th>
                      <th><input hidden class="searchInput"/></th>
                    </tr>
                  </tfoot>

                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


<div bsModal #ModalHeader="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog" style="max-width: 50%;" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title">
          </div>
          <button #ModalSimpanHide  type="button" class="close" (click)="ModalHeader.hide()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                      <div class="form-group row">
                        <label class="col-md-3 col-form-label" for="text-input">Bulan</label>
                        <div class="col-md-8">
                          <select class="form-control" [(ngModel)]="headerModel.month">
                            <option [value]=1><b>Januari</b></option>
                            <option [value]=2><b>Februari</b></option>
                            <option [value]=3><b>Maret</b></option>
                            <option [value]=4><b>April</b></option>
                            <option [value]=5><b>Mei</b></option>
                            <option [value]=6><b>Juni</b></option>
                            <option [value]=7><b>Juli</b></option>
                            <option [value]=8><b>Agustus</b></option>
                            <option [value]=9><b>September</b></option>
                            <option [value]=10><b>Oktober</b></option>
                            <option [value]=11><b>November</b></option>
                            <option [value]=12><b>Desember</b></option>
                          </select>
                        </div>
                    </div>
                            <!-- <input disabled type="date" [(ngModel)]="monthDesc"  class="form-control"> -->
                           
                    
                    <div class="form-group row">
                        <label class="col-md-3 col-form-label" for="text-input">Tipe</label>
                        <div class="col-md-8">
                          <select  class="form-control" [(ngModel)]="headerModel.subscriptionId">
                            <option *ngFor="let row of listCode" [value]='row.id'><b>{{row.text}}</b></option>
                          </select>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-md-3 col-form-label" for="text-input">Tahun</label>
                        <div class="col-md-8">
                            <input type="text" [(ngModel)]="headerModel.year" class="form-control">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-md-3 col-form-label" for="text-input">Tanggal Bayar</label>
                        <div class="col-md-8">
                            <input  type="date" [ngModel]="headerModel.payment_date| date:'yyyy-MM-dd'" (ngModelChange)="headerModel.payment_date = $event"  class="form-control">
                        </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" (click)="addDataHeader()" class="btn btn-primary"><i class="fa fa-print"></i>&nbsp;<b>Save</b>&nbsp;</button>
          <button type="button" #ModalHeader class="btn btn-secondary" (click)="ModalHeader.hide()">&nbsp;<b>Cancel</b>&nbsp;</button>
      </div>
      </div>
    </div>
  </div>

  <div bsModal #ModalCriteriaAdd="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog" style="max-width: 50%;" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title">
          </div>
          <button #ModalCriteriaAddHide type="button" class="close" (click)="ModalCriteriaAdd.hide()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                    <div class="form-group row">
                        <label class="col-md-3 col-form-label" for="text-input">Nama Supir</label>
                        <div class="col-md-7">
                            <input disabled [(ngModel)]="driver_name" type="text" class="form-control" >
                        </div>
                        <div class="col-md-1">
                          <button [disabled]="criteriaModel.action == 'Update'" class="btn btn-sm btn-primary" (click)="showDriver();ModalDriver.show()"
                          data-toggle="modal"><i class="fa fa-plus"></i></button>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-md-3 col-form-label" for="text-input">Jenis Iuran</label>
                        <div class="col-md-8">
                            <input disabled type="text" [(ngModel)]="subcriptionName" class="form-control">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-md-3 col-form-label" for="text-input">Bulan</label>
                        <div class="col-md-8">
                          <select disabled class="form-control" [(ngModel)]="criteriaModel.month">
                            <option [value]=1><b>Januari</b></option>
                            <option [value]=2><b>Februari</b></option>
                            <option [value]=3><b>Maret</b></option>
                            <option [value]=4><b>April</b></option>
                            <option [value]=5><b>Mei</b></option>
                            <option [value]=6><b>Juni</b></option>
                            <option [value]=7><b>Juli</b></option>
                            <option [value]=8><b>Agustus</b></option>
                            <option [value]=9><b>September</b></option>
                            <option [value]=10><b>Oktober</b></option>
                            <option [value]=11><b>November</b></option>
                            <option [value]=12><b>Desember</b></option>
                          </select>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-md-3 col-form-label" for="text-input">Tahun</label>
                        <div class="col-md-8">
                            <input disabled type="text" [(ngModel)]="criteriaModel.year" class="form-control">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-md-3 col-form-label" for="text-input">Biaya</label>
                        <div class="col-md-8">
                            <input type="text" currencyMask [options]="{ prefix: '', thousands: '.', decimal: ',' ,precision: '0',align: 'left' }" [(ngModel)]="criteriaModel.amount" class="form-control">
                        </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" (click)="addBpjsCriteria()" class="btn btn-primary"><i class="fa fa-print"></i>&nbsp;<b>Save</b>&nbsp;</button>
          <button type="button" #ModalCriteria class="btn btn-secondary" (click)="ModalCriteriaAdd.hide()">&nbsp;<b>Cancel</b>&nbsp;</button>
      </div>
      </div>
    </div>
  </div>

  <div  bsModal #ModalDriver="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog  modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title"><strong>Pilih Supir</strong></div>
          <button type="button" class="close" (click)="ModalDriver.hide()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <table datatable [dtOptions]="dtOptionsDriver" [dtTrigger]="dtTriggerDriver" class="table-striped">
            <thead>
              <tr>
                <th>Action</th>
                <th>Nama</th>
                <th>No SIM</th>
                <th>Jenis SIM</th>
                <th>Agama</th>
                <th>Tempat Lahir</th>
                <th>No KTP</th>
                <th>Tanggal Lahir</th>
                <th>SL</th>
                <th>SL Piutang</th>
                <th>SL Uang Jalan</th>
                <th>Alamat</th>
                <th>Berlaku Sampai</th>
                <th>Kontrak Sampai</th>
                <th>Dibuat Pada</th>
                <th>Diubah Pada</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let row of dataDriver">
                <td>
                  <button (click)="onSelectDriver(row);ModalDriver.hide()" class="btn btn-sm btn-primary"  data-toggle="modal" triggers="mouseenter:mouseleave"><i class="fa fa-check"></i></button>
                </td>
                <td>{{row.driver_name}}</td>
                <td>{{row.driver_license}}</td>
                <td>{{row.driver_license_type}}</td>
                <td>{{row.agama}}</td>
                <td>{{row.birthplace}}</td>
                <td>{{row.ktp_no}}</td>
                <td>{{row.tanggal_lahir | slice:0:10}}</td>
                <td>{{row.sl_code}}</td>
                <td>{{row.sl_code2}}</td>
                <td>{{row.sl_code3}}</td>
                <td>{{row.address}}</td>
                <td>{{row.valid_to | slice:0:10}}</td>
                <td>{{row.valid_to_contract | slice:0:10}}</td>
                <td>{{row.created_at | slice:0:10}}</td>
                <td>{{row.updated_at | slice:0:10}}</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th><input hidden class="searchInputD"/></th>
                <th><input id="driver_name" type="text" class="searchInputD" placeholder="Nama"/></th>
                <th><input id="driver_license" type="text" class="searchInputD" placeholder="No SIM"/></th>
                <th><input id="driver_license_type" type="text" class="searchInputD" placeholder="Tipe SIM"/></th>
                <th><input id="agama" type="text" class="searchInputD" placeholder="Agama"/></th>
                <th><input id="birthplace" type="text" class="searchInputD" placeholder="Tempat Lahir"/></th>
                <th><input id="ktp_no" type="text" class="searchInputD" placeholder="No KTP"/></th>
                <th><input id="tanggal_lahir" type="text" class="searchInputD" placeholder="Tanggal Lahir"/></th>
                <th><input id="sl_code" type="text" class="searchInputD" placeholder="SL"/></th>
                <th><input id="sl_code2" type="text" class="searchInputD" placeholder="SL Piutang"/></th>
                <th><input id="sl_code3" type="text" class="searchInputD" placeholder="SL UJ"/></th>
                <th><input id="address" type="text" class="searchInputD" placeholder="Alamat"/></th>
                <th><input id="valid_to" type="text" class="searchInputD" placeholder="Berlaku Sampai"/></th>
                <th><input id="valid_to_contract" type="text" class="searchInputD" placeholder="Kontrak Sampai"/></th>
                <th><input hidden class="searchInputD"/></th>
                <th><input hidden class="searchInputD"/></th>
              </tr>
            </tfoot>

          </table>
        </div>
      </div>
    </div>
  </div>

  <div bsModal #ModalSignature="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog" style="max-width: 50%" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title">
          </div>
          <button type="button" class="close" (click)="ModalSignature.hide()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                    <div class="form-group row">
                        <label class="col-md-4 col-form-label" for="text-input">Disetujui</label>
                        <div class="col-md-8">
                          <select  [(ngModel)]="printDetailModel.disetujui" class="form-control">
                            <option *ngFor="let row of dataSignature" [value]='row.name'><b>{{row.name}}</b></option>
                         </select>
                        </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-md-4 col-form-label" for="text-input">Diketahui Oleh</label>
                      <div class="col-md-5">
                        <select  [(ngModel)]="printDetailModel.diketahui1" class="form-control">
                          <option *ngFor="let row of dataSignature" [value]='row.name'><b>{{row.name}}</b></option>
                       </select>
                      </div>
                      <div class="col-sm-1">
                        <input [(ngModel)]="printDetailModel.hide_diketahui" style="width: 40%;" type="checkbox" class="form-control">
                      </div>
                      <label class="col-sm-2 col-form-label" for="text-input">Sembunyikan</label>
                    </div>
                  <div class="form-group row">
                        <label class="col-md-4 col-form-label" for="text-input">Diketahui oleh 2</label>
                        <div class="col-md-5">
                          <select  [(ngModel)]="printDetailModel.diketahui2" class="form-control">
                            <option *ngFor="let row of dataSignature" [value]='row.name'><b>{{row.name}}</b></option>
                         </select>
                        </div>
                        <div class="col-sm-1">
                          <input [(ngModel)]="printDetailModel.hide_diketahui2" style="width: 40%;" type="checkbox" class="form-control">
                        </div>
                        <label class="col-sm-2 col-form-label" for="text-input">Sembunyikan</label>
                  </div>
                  <div class="form-group row">
                    <label class="col-md-4 col-form-label" for="text-input">Dibuat Oleh</label>
                    <div class="col-md-8">
                      <select  [(ngModel)]="printDetailModel.dibuat" class="form-control">
                        <option *ngFor="let row of dataSignature" [value]='row.name'><b>{{row.name}}</b></option>
                     </select>
                    </div>
                 </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button [hidden]="tipePrint != 'detail'" (click)="printBpjsDetail()" type="button" class="btn btn-primary"><i class="fas fa-print"></i>&nbsp;&nbsp;&nbsp;<b>Cetak Detail</b>&nbsp;&nbsp;&nbsp;</button>
          <button [hidden]="tipePrint != 'rekapan'" (click)="printBpjsDetail()" type="button" class="btn btn-primary"><i class="fas fa-print"></i>&nbsp;&nbsp;&nbsp;<b>Cetak Rekapan</b>&nbsp;&nbsp;&nbsp;</button>
        </div>
      </div>
    </div>
  </div>

  <div bsModal #ModalGenerate="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog" style="max-width: 50%;" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title">
            <strong>Generate Data</strong>
          </div>
          <button #ModalGenerateHide type="button" class="close" (click)="ModalGenerate.hide()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="card">
            <div class="card-body">
            <div class="scroll">
              <table datatable [dtOptions]="dtOptionsGenerate" [dtTrigger]="dtTriggergenerate" class="table-striped">
                <thead>
                  <tr>
                    <th>No Kontrak</th>
                    <th>Nama Supir</th>
                    <th>Bulan</th>
                    <th>Tahun</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of dataListGenerate">
                    <td>{{row.contractNo}}</td>
                    <td>{{row.driverName}}</td>
                    <td>{{row.month}}</td>
                    <td>{{row.year}}</td>
                    <td>{{row.amount | number}}</td>
                  </tr>
                </tbody>
              </table>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button (click)="saveGenerateBpjs()" type="button" class="btn btn-primary"><i class="fas fa-save"></i>&nbsp;&nbsp;&nbsp;<b>Simpan Data</b>&nbsp;&nbsp;&nbsp;</button>
        </div>
      </div>
    </div>
  </div>

<button #modalCriteriaShow hidden (click)="ModalCriteria.show()"></button>
<button #ModalGenerateShow hidden (click)="ModalGenerate.show()"></button>

<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>