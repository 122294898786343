import { AfterViewInit, Component, OnInit, ViewChild,Input, ChangeDetectorRef, ElementRef  } from '@angular/core';
import { TransportService} from '../../../services/api.service';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import moment from 'moment';
import { Guid } from 'guid-typescript';
import { Bapb, BapbDetail, Npb, NpbDetail, SpkService, SpkServiceDetail, ViewStock } from '@/model/spkService';
import { ListTruck } from '@/model/listTruck';
import { Driver } from '@/model/driver';

@Component({
  selector: 'app-list-bapb',
  templateUrl: './list-bapb.component.html',
  styleUrls: ['./list-bapb.component.scss']
})
export class ListBapbComponent implements OnInit, AfterViewInit {
  @ViewChild('modalSimpanClose') modalSimpanClose: ElementRef;
  @ViewChild('ModalDetailClose') ModalDetailClose: ElementRef;
  @ViewChild('modalSPKClose') modalSPKClose: ElementRef;
  @ViewChild('modalNPBClose') modalNPBClose: ElementRef;
  dtOptionsAcc: any = {};
  dtOptionsDetail: any = {};
  dtTriggerDetail = new Subject();
  dtTriggerAcc = new Subject();
  dataListBAPB: Bapb[] = [];
  dtOptionsStock: any = {};
  dtTriggerStock = new Subject();
  listBapbDetail: BapbDetail[] = [];
  listBapbDetailDelete: BapbDetail[] = [];
  listSPK: SpkService[] = [];
  dataStock: ViewStock[] = [];
  dtOptionsSpk: any = {};
  dtTriggerSpk = new Subject();
  public dt: any;
  public cb: any;
  itemQty: number;

  public dtSpk: any;
  public cbSpk: any;
  public dtDriver: any;
  public cbDriver: any;
  public dtStock: any;
  public cbStock: any;
  public update : boolean;
  public show : boolean;
  locationName: string;
  dtOptionsNpb: any = {};
  dtTriggerNpb = new Subject();
  dataListNPB: Npb[] = [];
  menu: Guid;
  @Input() listApps = { roleName: null}
  @Input() spkNo = { startDate: null, code: null}
  @Input() bapbDetailModel = {itemId: null, stockCode: null,itemName: null,qty: null, uom: null, remark: null}

  @Input() bapbModel = { BapbId: null, SpkId: null, BapbNo: null,  SpkNo: null, Date: null, Remark: null,bapbDetail: new Array(), bapbDetailDelete: new Array()}
  constructor( public service: TransportService, private router: Router, private spinner: NgxSpinnerService, private changeDetectorRef: ChangeDetectorRef) {
  }

    ngOnInit(): void {
      this.listApps.roleName = localStorage.getItem("rolename");
      this.locationName = localStorage.getItem('locationname');
      this.service.getListApprovals(this.listApps).subscribe(resp => {
        console.log(JSON.stringify(resp))
       });
      //this.userAccess();
      this.dtOptionsAcc = {  
        scrollX: true,
        scrollCollapse: true,
        pageLength: 10,
        ordering : false,
        serverSide: true,
        processing: true,
        searching : false,
        ajax: (dataTablesParameters: any, callback) => {
         this.dt = dataTablesParameters; this.cb = callback;
         this.getBapbList(dataTablesParameters, callback,  true,"","","","");

        },
        columnDefs: [
          { width: 200, targets: [0]},
          { width: 200, targets: [1,2,3,4,5,6,7,8,9]}
        ],
      };

      this.dtOptionsSpk = {  
        scrollX: true,
        scrollCollapse: true,
        pageLength: 10,
        ordering : false,
        serverSide: true,
        processing: true,
        searching : false,
        ajax: (dataTablesParameters: any, callback) => {
         this.dtSpk = dataTablesParameters; this.cbSpk = callback;
         this.getSpkNpb(dataTablesParameters, callback, "","","", true);

        },
        columnDefs: [
          { width: 200, targets: [0]},
          { width: 200, targets: [1,2,3,4,5,6,7]}
        ],
      };

      this.dtOptionsDetail = {
        retrieve: true,
        //pageLength: 100,
        ordering : false,
        // scrollX: true,
        // scrollCollapse: true,
        searching : false,
        columnDefs: [
          { width: 400, targets: [0,1,2,3,4,5,6,7,8] }
        ],
      };

      this.dtOptionsStock = {
        retrieve: true,
        pageLength: 5,
        scrollX: true,
        scrollCollapse: true,
        serverSide: true,
        processing: true,
        searching : false,
        ajax: (dataTablesParameters: any, callback) => {
         this.dtStock = dataTablesParameters; this.cbStock = callback;
         this.getDataStock(dataTablesParameters, callback, "", "","","","",  true);
         },
        columnDefs: [
          { width: 150, targets: [0,1,2,3,4,5,6,7,8] }
        ],
      };

      
      this.dtOptionsNpb = {  
        scrollX: true,
        scrollCollapse: true,
        pageLength: 10,
        ordering : false,
        serverSide: true,
        processing: true,
        searching : false,
        ajax: (dataTablesParameters: any, callback) => {
         this.dt = dataTablesParameters; this.cb = callback;
         this.getNPBList(dataTablesParameters, callback,  true,"","","","");

        },
        columnDefs: [
          { width: 200, targets: [0]},
          { width: 200, targets: [1,2,3,4,5,6,7,8,9]}
        ],
      };


      const that = this;
      $('.searchInputD').on("keyup", function(e) {
        var key = e.which;
        if (key == 13) // the enter key ascii code
        {
          that.getSpkNpb(that.dtSpk, that.cbSpk, $('#spkNo').val().toString(), $('#driverName').val().toString(),$('#truckNo').val().toString(),false);
        }
      });

      $('.searchInputS').on("keyup", function(e) {
        var key = e.which;
        if (key == 13) // the enter key ascii code
        {
          that.getDataStock(that.dtStock, that.cbStock, $('#ItemCode').val().toString(), $('#ItemSubKategori').val().toString(),
          $('#ItemKategori').val().toString(),$('#GL_Account').val().toString(),$('#ItemName').val().toString(),false);
        }
      });

      
      $('.searchInputN').on("keyup", function(e) {
        var key = e.which;
        if (key == 13) // the enter key ascii code
        {
          that.getBapbList(that.dt, that.cb, false, $('#bapbNo').val().toString(), $('#spkNo').val().toString(),
          $('#remark').val().toString(),$('#status').val().toString());
        }
      });
      
      $('.searchInputP').on("keyup", function(e) {
        var key = e.which;
        if (key == 13) // the enter key ascii code
        {
          that.getNPBList(that.dt, that.cb, false, $('#npbNo').val().toString(), $('#spkNo').val().toString(),
          $('#remark').val().toString(),$('#status').val().toString());
        }
      });
      
    }   

    userAccess(){
      const username = localStorage.getItem("username");
      if (!username.includes("admin")){
          return this.router.navigate(["/home"]);
      }
    }
    
    ngAfterViewInit(): void {
      this.dtTriggerAcc.next(this.dtOptionsAcc)
      this.dtTriggerDetail.next(this.dtOptionsDetail);
    }
  
    ngOnDestroy(): void {
      this.dtTriggerAcc.unsubscribe();
      this.dtTriggerDetail.unsubscribe();
    }


    showSpk(){
      this.dtTriggerSpk.next(this.dtOptionsSpk);
    }

    showStock(){
      this.dtTriggerStock.next(this.dtOptionsStock);
    }

    showNpb(){
      this.dtTriggerNpb.next(this.dtOptionsNpb);
    }

    getNPBList(dataTablesParameters, callback, padging: Boolean, npbNo: string, spkNo: string, remark: string, status: string){
      var page = 1;
        if (padging == true){
          page = parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length) + 1;
        }
        this.service.getNpbList(dataTablesParameters, page, dataTablesParameters.length,npbNo,spkNo,remark, status)
        .subscribe(resp => { 
            this.dataListNPB = resp.data;
            callback({
              recordsTotal: resp.totalCount,
              recordsFiltered: resp.totalCount,
               data:  []
            });
        });
    }

    getSpkNpb(dataTablesParameters, callback, spkNo: string, driverName: string, truckNo: string,padging : boolean){ 
      var page = 1;
      if (padging == true){
        page = parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length) + 1;
      }
      this.service.getSpkListNpb(dataTablesParameters, page, dataTablesParameters.length, spkNo, driverName, truckNo)
      .subscribe(resp => {
          this.listSPK = resp.data;
          callback({
            recordsTotal: resp.totalCount,
            recordsFiltered: resp.totalCount,
            data:  []
          });
      });
    }

    getDataStock(dataTablesParameters, callback, ItemCode: string, ItemSubKategori: string, ItemKategori: string, 
      GL_Account: string, ItemName: string, padging : boolean){ 
      var page = 1;
      if (padging == true){
        page = parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length) + 1;
      }
      this.service.getListStock(dataTablesParameters, page, dataTablesParameters.length, ItemCode , ItemSubKategori,
        ItemKategori, GL_Account, ItemName)
      .subscribe(resp => { 
          this.dataStock = resp.data;
          callback({
            recordsTotal: resp.totalCount,
            recordsFiltered: resp.totalCount,
            data:  []
          });
      });
    }

    getBapbList(dataTablesParameters, callback, padging: Boolean,bapbNo: string, spkNo: string, remark: string, status: string){
    var page = 1;
      if (padging == true){
        page = parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length) + 1;
      }

      this.service.getBapbList(dataTablesParameters, page, dataTablesParameters.length,bapbNo,spkNo,remark,status)
      .subscribe(resp => { 
          this.dataListBAPB = resp.data;
          callback({
            recordsTotal: resp.totalCount,
            recordsFiltered: resp.totalCount,
             data:  []
          });
      });
  }

  messageWarning(text: string){
    Swal.fire({
      title: ""+text,
      type: 'warning',
      confirmButtonText: '      Ok!     '
    });
    return;
  }

  addBAPBDetail(){
    if (this.bapbDetailModel.qty == "" || this.bapbDetailModel.qty == null){
      return Swal.fire({
        title: "Data Belum Lengkap!",
        type: 'warning',
        confirmButtonText: '      Ok!     '
      });
    }
    else if (this.bapbDetailModel.qty > this.itemQty){
      return Swal.fire({
        title: "Stok Tidak Cukup!",
        type: 'warning',
        confirmButtonText: '      Ok!     '
      });
    }
    else{
      this.listBapbDetail.push( { bapbDetailId : null, bapbId : null,
        itemId : this.bapbDetailModel.itemId,  stockCode : this.bapbDetailModel.stockCode, itemName : this.bapbDetailModel.itemName, 
        qty : this.bapbDetailModel.qty, uom : this.bapbDetailModel.uom, remark: this.bapbDetailModel.remark,
        createdAt : null, updatedAt : null, createdName : null,updatedName : null} );
        this.ModalDetailClose.nativeElement.click();
        this.bapbDetailModel.qty = 0;
        this.bapbDetailModel.stockCode = "";
        this.bapbDetailModel.itemName = "";
        this.bapbDetailModel.uom = "";
        console.log(JSON.stringify(this.listBapbDetail))
    }
  }

  createSPK(){
    // if (this.roleModel.role_name == null || this.roleModel.role_name == "" ){
    //   Swal.fire({
    //     title: "Data Masih Belum Lengkap !",
    //     type: 'warning',
    //     confirmButtonText: '      Ok!     '
    //   });
    //   return;
    // }
    Swal.fire({
      title: 'Simpan Data?',
      type: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '      Yes     '
      }).then((result) => {
        if (result.value) {
         // this.spinner.show();

          this.bapbModel.bapbDetail = [];
            for (let i = 0; i < this.listBapbDetail.length; i++) {
              this.bapbModel.bapbDetail.push({
                itemId : this.listBapbDetail[i].itemId,
                stockCode : this.listBapbDetail[i].stockCode,
                itemName : this.listBapbDetail[i].itemName,
                qty : this.listBapbDetail[i].qty,
                uom : this.listBapbDetail[i].uom
            });
          }
          this.service.addBAPB(this.bapbModel).subscribe( resp => {
            this.spinner.hide();
            if(resp.succes == false){
              Swal.fire({
                title: ""+resp.text,
                type: 'warning',
                confirmButtonText: '      Ok!     '
              });
            }
            else{
              Swal.fire({
                title: ""+resp.text,
                type: 'success',
                confirmButtonText: '      Ok!     '
              }).then((result) => {
                if (result.value) { 
                  this.getBapbList(this.dt, this.cb,  true,"","","","");
                }              
              });
              this.modalSimpanClose.nativeElement.click();
            }
          })
        }
    });
  }

  updateSPK(){
    // if (this.roleModel.role_name == null || this.roleModel.role_name == "" ){
    //   Swal.fire({
    //     title: "Data Masih Belum Lengkap !",
    //     type: 'warning',
    //     confirmButtonText: '      Ok!     '
    //   });
    //   return;
    // }
    Swal.fire({
      title: 'Update Data?',
      type: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '      Yes     '
      }).then((result) => {
        if (result.value) {
         // this.spinner.show();

          this.bapbModel.bapbDetail = [];
          for (let i = 0; i < this.listBapbDetail.length; i++) {
             if (this.listBapbDetail[i].bapbDetailId == null){ 
              this.bapbModel.bapbDetail.push({
                bapbDetailId : null,
                itemId : this.listBapbDetail[i].itemId,
                StockCode : this.listBapbDetail[i].stockCode,
                itemName : this.listBapbDetail[i].itemName,
                qty : this.listBapbDetail[i].qty,
                uom : this.listBapbDetail[i].uom
              });
             }
          }

          this.bapbModel.bapbDetailDelete = [];
          for (let i = 0; i < this.listBapbDetailDelete.length; i++) {
              this.bapbModel.bapbDetailDelete.push({
                bapbDetailId : this.listBapbDetailDelete[i].bapbDetailId,
                bapbId : this.listBapbDetailDelete[i].bapbId
            });
          }

          console.log(this.bapbModel);
          this.service.updateBAPB(this.bapbModel).subscribe( resp => {
            this.spinner.hide();
            if(resp.succes == false){
              Swal.fire({
                title: ""+resp.text,
                type: 'warning',
                confirmButtonText: '      Ok!     '
              });
            }
            else{
              Swal.fire({
                title: ""+resp.text,
                type: 'success',
                confirmButtonText: '      Ok!     '
              }).then((result) => {
                if (result.value) { 
                  this.getBapbList(this.dt, this.cb,  true,"","","","");
                }              
              });
              this.modalSimpanClose.nativeElement.click();
            }
          })
        }
    });
  }

  
  onSelectDetailNpb(data: any){
    this.spinner.show();
    this.service.getListNPBDetail(data.npbId).subscribe(resp => {
      this.listBapbDetail = [];
      for (let i = 0; i < resp.length; i++) {
        this.listBapbDetail.push({
          bapbDetailId : null,
          bapbId : null,
          itemId : resp[i].itemId,
          stockCode : resp[i].stockCode,
          itemName : resp[i].itemName,
          qty : resp[i].qty,
          uom : resp[i].uom,
          remark : "",
          createdAt: null,
          createdName: null,
          updatedAt: null,
          updatedName: null
      });
      this.spinner.hide();
      this.modalNPBClose.nativeElement.click();
    }
    });
  }

  onSelectDetailSPK(data: any){
    this.show = true;
    this.update = true;
    this.service.getListBAPBDetail(data.bapbId).subscribe(resp => {
      this.listBapbDetail = resp;
    });
    this.bapbModel.BapbId = data.bapbId;
    this.bapbModel.BapbNo = data.bapbNo;
    this.bapbModel.SpkId = data.spkId;
    this.bapbModel.SpkNo = data.spkNo;
    this.bapbModel.Date = data.date;
    this.bapbModel.Remark = data.remark;
  }

  onSelectUpdate(data: any){
    this.show = false;
    this.update = true;
    this.service.getListBAPBDetail(data.bapbId).subscribe(resp => {
      this.listBapbDetail = resp;
    });
    this.bapbModel.BapbId = data.bapbId;
    this.bapbModel.BapbNo = data.npbNo;
    this.bapbModel.SpkId = data.spkId;
    this.bapbModel.Date = data.date;
    this.bapbModel.Remark = data.remark;
  }

  onSelectSpk(data: SpkService){
      this.service.getExistSPK("BAPB",data.spkId).subscribe(resp => {
       if (resp.docNo == null || resp.docNo == ""){
        this.bapbModel.SpkId = data.spkId;
        this.bapbModel.SpkNo = data.spkNo;
         this.modalSPKClose.nativeElement.click();
       }
       else{
         Swal.fire({
           title: "Sudah ada NPB ("+resp.docNo+ ")Terbuat Dalam SPK Tersebut!",
           type: 'warning',
           confirmButtonText: '      Ok!     '
         });
       }
     })
  }

  onSelectStock(data: ViewStock){
    this.bapbDetailModel.itemId = data.itemID;
    this.bapbDetailModel.itemName = data.itemName;
    this.bapbDetailModel.stockCode = data.itemCode;
    this.bapbDetailModel.uom = data.uom;
    this.itemQty = data.qty;
 }

  onSelectDriver(data: Driver){
    // this.bapbModel.DriverId = data.driver_id;
    // this.bapbModel.DriverName = data.driver_name;
  }

  setNull(){
    this.show = false;
    this.update = false;
    var today = moment().format('YYYY-MM-DD HH:mm');
    this.bapbModel.Date = today;
    this.bapbModel.SpkId = null;
    this.bapbModel.Remark = null;
    this.listBapbDetail = [];
    this.spkNo.startDate = this.bapbModel.Date;
    this.spkNo.code= "BAPB";
    this.service.generateSPKNo(this.spkNo).subscribe(resp => {
      if(resp.succes == false){
          Swal.fire({
            title: ""+resp.text,
            type: 'warning',
            confirmButtonText: '      Ok!     '
          });
      }
      else{
        this.bapbModel.BapbNo = resp.text;
      }
    });
  }


  deleteDataDetail(id: number, data: BapbDetail){
    Swal.fire({
      title: 'Delete Data?',
      type: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '      Yes     '
      }).then((result) => {
        if (result.value) {
        this.listBapbDetail.splice(id, 1); this.changeDetectorRef.detectChanges();
        this.listBapbDetailDelete.push({bapbDetailId : data.bapbDetailId, bapbId : data.bapbId, itemId : data.itemId,
          stockCode : data.stockCode, itemName : data.itemName, qty : data.qty, uom : data.uom, remark: data.remark,
          createdAt : data.createdAt, updatedAt : data.updatedAt, createdName : data.createdName,updatedName : data.updatedName});
        }
    })
  }

  printBAPB(data: any){
    this.spinner.show();
    this.service.printBAPB(data.bapbId, this.locationName).subscribe(resp =>{
      this.spinner.hide();
      let blob:Blob=resp.body as Blob;
        let url = window.URL.createObjectURL(blob);
        window.open(url);
      })
  }


}














