import { AfterViewInit, Component, OnInit, ViewChild,Input  } from '@angular/core';
import { DataTableDirective } from 'angular-datatables'
import { TransportService} from '../../..//services/api.service';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { Guid } from 'guid-typescript'
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Route } from '@/model/route';
import { MasterPortComponent } from '../master-port/master-port.component';
import { Port } from '@/model/port';
import { AppService } from '@services/app.service';
import { Router } from '@angular/router';
import { AuthMenu } from '@/model/masterSubMenu';
import { UserAuthNew } from '@/model/respDto';

@Component({
  selector: 'app-master-route',
  templateUrl: './master-route.component.html',
  styleUrls: ['./master-route.component.scss'],
  providers: [MasterPortComponent],
})
export class MasterRouteComponent implements OnInit, AfterViewInit {
  routeForm: FormGroup;
  @ViewChild('modalSaveClose') modalClose;
  @ViewChild(DataTableDirective, {static: false})
  datatableElement: DataTableDirective;
  dtOptions: any = {};
  dtOptions2: any = {};
  public route: Route;
  public muatSelect: string;
  public bongkarSelect: string;
  public loadData: string;
  public dataRoute: Route[] = [];
  pintDataRoute: Route[] = [];
  dtTrigger = new Subject();
  authAccessPlb: UserAuthNew[] = [];
  @Input() routeModel = { route_id : null, route_name: '', route_type: '', load_port : null, unload_port: null};
  action: string;
  public dt: any;
  public cb: any;
  btnCrud : boolean = true;

  constructor( public service: TransportService, private router: Router,  private spinner: NgxSpinnerService, fb: FormBuilder, public componentPort: MasterPortComponent, private appServ : AppService) {
    this.routeForm = fb.group({
      // 'email': [null, Validators.compose([Validators.required, Validators.pattern(/^(\d{10}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))$/)])],
      'route_name': [null, Validators.required],
      'route_type': [null, Validators.required],
      'load_port': [null, Validators.required],
      'unload_port': [null, Validators.required],
      'other':[null],
      'other2':[null],
      'terms': [false]
    });
  }

  
  userAcces(){
    const menuUser = localStorage.getItem("menu");
    if (menuUser != "All"){
      const locationName = localStorage.getItem('locationname');
      if (locationName != "Palembang"){
        const subMenu = localStorage.getItem("acces_menu");
        let arrayMenu =  subMenu.split(",");
        if (arrayMenu.includes("route") == false){
          this.router.navigate(["/home"]);
        }
      }
      else{
        this.authAccessPlb = JSON.parse(localStorage.getItem("authAccessPlb"));
        var a = this.authAccessPlb.filter(item => item.subMenu  == "route").length;
        if (a == 0){
          return this.router.navigate(["/home"]);
        }
        this.btnCrud = false;
        this.authAccessPlb.filter(item => item.subMenu == "route").forEach(i => {
          if(i.auth == "CRUD"){
            this.btnCrud = true;
          }
         });
      }

    }
  }

    ngOnInit(): void {
      this.userAcces();
      this.dtOptions = {
        responsive:true,
        serverSide: true,
        processing: true,
        searching : false,
        ordering : false,
        ajax: (dataTablesParameters: any, callback) => {
         this.dt = dataTablesParameters; this.cb = callback;
         this.getDataRoute(dataTablesParameters, callback,$('#namesearch').val().toString(),  $('#typesearch').val().toString()
         ,  $('#unloadsearch').val().toString(),  $('#loadsearch').val().toString(), null,true);
        },
        columnDefs: [
          { width: 120, targets: [0,1,4,5,6,7,8] },
          { width: 200, targets: [2,3] },
         ],
      };

      this.dtOptions2 = {
        pagingType: 'full_numbers',
        pageLength: 5,
        serverSide: true,
        processing: true,
        searching : false,
        ajax: (dataTablesParameters: any, callback) => {
         this.dt = dataTablesParameters; this.cb = callback;
         this.componentPort.getDataPort(dataTablesParameters, callback,$('#portname').val().toString(),true);
        }
      };

      const that = this;
      $('.searchInput').on("keyup", function(e) {
        var key = e.which;
        if (key == 13) // the enter key ascii code
        {
          that.getDataRoute(that.dt, that.cb,  $('#namesearch').val().toString(),  $('#typesearch').val().toString()
          ,  $('#unloadsearch').val().toString(),  $('#loadsearch').val().toString(), null,false);
        }
      });  

      $('.searchInputV').on("keyup", function(e) {
        var key = e.which;
        if (key == 13) // the enter key ascii code
        {
          that.componentPort.getDataPort(that.dt, that.cb, $('#portname').val().toString(), false);
        }
      }); 

      $('#searchInputV').on("keyup", function(e) {
        var key = e.which;
        if (key == 13) // the enter key ascii code
        {
          that.componentPort.getDataPort(that.dt, that.cb, $('#portname').val().toString(), false);
        }
      }); 
      
    }

    getDataRoute(dataTablesParameters, callback, route_name: string, route_type: string, unloadtext: string, loadtext: string,material_id: string, padging : boolean){ 
      var page = 1;
      if (padging == true){
        page = parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length) + 1;
      }
      this.service.getListRoute(dataTablesParameters, page, dataTablesParameters.length, route_name, route_type, unloadtext, loadtext, material_id)
      .subscribe(resp => { 
          this.dataRoute = resp.data;
          callback({
            recordsTotal: resp.totalCount,
            recordsFiltered: resp.totalCount,
             data:  []
          });
      });
    }
    
    ngAfterViewInit(): void {
      this.dtTrigger.next(0);
    }
  
    ngOnDestroy(): void {
      this.dtTrigger.unsubscribe();
    }
  
    rerender(): void {
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtTrigger.next(0);
      });
    }

    onSelectRoute(route: Route) {
      this.action = "Update";
      this.routeModel.route_id = route.route_id;
      this.routeModel.route_name = route.route_name;
      this.routeModel.unload_port= route.unload_port;
      this.routeModel.load_port= route.load_port;
      this.muatSelect = route.loadtext;
      this.bongkarSelect = route.unloadtext;
      this.routeModel.route_type = route.route_type;

    }

    loadDataMuat(){
      this.loadData = "Muat"
    }

    loadDataBongkar(){
      this.loadData = "Bongkar"
    }

    onSelectPort(port: Port) {
      if (this.loadData == "Muat"){
        this.routeModel.load_port = port.port_id;
        this.muatSelect = port.port_name;
      }
      if (this.loadData == "Bongkar"){
        this.routeModel.unload_port = port.port_id;
        this.bongkarSelect = port.port_name;
      }
      
    }
  
  saveDataRoute(){
    this.service.markFormTouched(this.routeForm);
    if (this.routeForm.valid) {
      Swal.fire({
        title: 'Simpan Data?',
        type: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: '      Yes     '
      }).then((result) => {
        if (result.value) {
          this.spinner.show();
          this.service.addRoute(this.routeModel).subscribe(resp => {
            this.spinner.hide();
            if(resp.succes == false){
              Swal.fire({
                title: ""+resp.text,
                type: 'warning',
                confirmButtonText: '      Ok!     '
              });
            }
            else{
              Swal.fire({
                title: ""+resp.text,
                type: 'success',
                confirmButtonText: '      Ok!     '
              }).then((result) => {
                if (result.value) { 
                  this.getDataRoute(this.dt, this.cb,"","","","", "",true);
                  this.modalClose.nativeElement.click();
                  this.routeForm.controls['terms'].setValue(true);
                }              
              });
            }
          });
        }
      })

    } else {
      this.routeForm.controls['terms'].setValue(false);
    }
   
    }

  deleteRoute(route: Route){
    Swal.fire({
      title: 'Delete Data?',
      // text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '      Yes     '
    }).then((result) => {
      if (result.value) {
        this.spinner.show();
        this.service.DeleteRoute(route.route_id).subscribe(route => { 
          this.spinner.hide();
          Swal.fire({
            type: 'success',
            confirmButtonText: '      Succes!     '
          }).then((result) => {
            if (result.value) { 
              this.getDataRoute(this.dt, this.cb, "","","","", "",true);
            }
          
          }, (error) => {
            //; 
          })
        });
      }
    
    }, (error) => {
      //; 
    });
  }

  
  clearFormRoute(){
    this.action = "Input";
    this.routeModel.route_id = null;
    this.routeModel.route_name = null;
    this.routeModel.route_type = null;
    this.routeModel.load_port = null;
    this.routeModel.unload_port = null;
  }
    
  exportAsXLSX():void {
    this.service.getListRoute(this.dt, 1, 1000000, $('#namesearch').val().toString(),  $('#typesearch').val().toString()
    ,  $('#unloadsearch').val().toString(),  $('#loadsearch').val().toString(), null)
    .subscribe(resp => { 
        this.pintDataRoute = resp.data;
        this.appServ.exportAsExcelFile(this.pintDataRoute, 'Master_Route');
    });
  }
      
}



