import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables'
@Component({
    selector: 'app-blank',
    templateUrl: './blank.component.html',
    styleUrls: ['./blank.component.scss']
})
export class BlankComponent implements OnInit, AfterViewInit {
 name = "Angular PDF";
 @ViewChild(DataTableDirective, {static: false})
  datatableElement: DataTableDirective;

    dtOptions: DataTables.Settings = {};

    ngOnInit(): void {
        
        this.dtOptions = {
          pagingType: 'full_numbers'
        };
      }

      ngAfterViewInit(): void {
        this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.columns().every(function () {
            const that = this;
            $('input', this.footer()).on('keyup change', function () {
              if (that.search() !== this['value']) {
                that
                  .search(this['value'])
                  .draw();
              }
            });
          });
        });
      }
}

