
<!-- Main content -->

<section class="content">  
    
  <div class="card">
      <div class="card-header">
        <strong>List Driver</strong>
        <div class="float-right">
          <a type="button" class="btn btn-sm btn-warning pull-right" data-toggle="modal" (click)="exportDriver()">&nbsp;&nbsp;&nbsp;<b>Export Data</b>&nbsp;&nbsp;&nbsp;&nbsp;<i class="fas fa-file-excel"></i>&nbsp;&nbsp;&nbsp;</a>&nbsp;&nbsp;&nbsp;&nbsp;
          <a type="button" [hidden]="!btnInsert" class="btn btn-sm btn-primary pull-right" data-toggle="modal" (click)="ModalDriver.show();clearFormDriver()">&nbsp;&nbsp;&nbsp;<b>Input Driver</b>&nbsp;&nbsp;&nbsp;&nbsp;<i class="fa fa-pen"></i>&nbsp;&nbsp;&nbsp;</a>
        </div>
      </div>
      <div class="card-body">
    <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table-striped">
      <thead>
        <tr>
          <th>Action</th>
          <th>Nama</th>
          <th>No SIM</th>
          <th>Jenis SIM</th>
          <th>Berlaku Sampai</th>
          <th>Kontrak Sampai</th>
          <th>Active</th>
          <th>Blacklist</th>
          <th>Agama</th>
          <th>Tempat Lahir</th>
          <th>No KTP</th>
          <th>Tanggal Lahir</th>
          <th>SL</th>
          <th>SL Piutang</th>
          <th>SL Uang Jalan</th>
          <th>Alamat</th>
          <th>Dibuat Oleh</th>
          <th>Dibuat Pada</th>
          <th>Diubah Oleh</th>
          <th>Diubah Pada</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let row of dataDriver">
          <td><button [hidden]="!btnDelete" class="btn btn-sm btn-danger" title="Delete" (click)="deleteDriver(row);"><i class="fa fa-trash"></i></button>&nbsp;
            <!-- <button (click)="onSelectDriver(row);ModalDriver.show()" class="btn btn-sm btn-primary" title="Edit"><i class="fa fa-pen"></i></button>&nbsp;
            <button (click)="onSelectDriver(row);ModalSIM.show()" class="btn btn-sm btn-primary" title="Ganti SIM"><i class="fa fa-id-card"></i></button>&nbsp; -->
            <button [hidden]="!btnEdit" (click)="onSelectDriver(row);ModalDriver.show()" class="btn btn-sm btn-primary" title="Edit"><i class="fa fa-pen"></i></button>&nbsp;
            <button [hidden]="!btnEdit" (click)="onSelectDriver(row);ModalSIM.show()" class="btn btn-sm btn-primary" title="Ganti SIM"><i class="fa fa-id-card"></i></button>&nbsp;
            <button [hidden]="!btnEdit" (click)="onSelectContract(row);ModalContract.show()" class="btn btn-sm btn-primary" title="Kontrak Supir"><i class="fa fa-sticky-note"></i></button>&nbsp;
            <button [hidden]="!btnDelete" (click)="onSelectBlacklist(row)" class="btn btn-sm btn-danger" title="Blacklist Supir"><i class="fa fa-ban"></i></button>&nbsp;
            <button [hidden]="!btnEdit" (click)="onSelectSlCode(row);ModalSLCode.show()" class="btn btn-sm btn-warning" title="Update SL Code"><i class="fas fa-code"></i></button>
          </td>
          <td>{{row.driver_name}}</td>
          <td>{{row.driver_license}}</td>
          <td>{{row.driver_license_type}}</td>
          <td>{{row.valid_to | slice:0:10}}</td>
          <td>{{row.valid_to_contract | slice:0:10}}</td>
          <td><input disabled type="checkbox" [checked]="row.active"/></td>
          <td><input disabled type="checkbox" [checked]="row.blacklist"/></td>
          <td>{{row.agama}}</td>
          <td>{{row.birthplace}}</td>
          <td>{{row.ktp_no}}</td>
          <td>{{row.tanggal_lahir | slice:0:10}}</td>
          <td>{{row.sl_code}}</td>
          <td>{{row.sl_code2}}</td>
          <td>{{row.sl_code3}}</td>
          <td>{{row.address}}</td>
          <td>{{row.createdby}}</td>  
          <td>{{row.created_at | slice:0:10}}</td>  
          <td>{{row.updatedby}}</td>  
          <td>{{row.updated_at | slice:0:10}}</td>   
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <th><input hidden class="searchInput"/></th>
          <th><input id="driver_name" type="text" class="searchInput" placeholder="Nama"/></th>
          <th><input id="driver_license" type="text" class="searchInput" placeholder="No SIM"/></th>
          <th><input id="driver_license_type" type="text" class="searchInput" placeholder="Tipe SIM"/></th>
          <th>
            <select (change)="selectActive()" id="active" class="searchInput">
              <option><b>All</b></option>
              <option selected value=true><b>Aktif</b></option>
              <option value=false><b>Tidak Aktif</b></option>
           </select> 
          </th>
          <th>
            <select (change)="selectActive()" id="blacklist" class="searchInput">
              <option><b>All</b></option>
              <option value=true><b>BlackList</b></option>
              <option value=false><b>Tidak Balcklist</b></option>
           </select> 
          </th>
          <th><input id="agama" type="text" class="searchInput" placeholder="Agama"/></th>
          <th><input id="birthplace" type="text" class="searchInput" placeholder="Tempat Lahir"/></th>
          <th><input id="ktp_no" type="text" class="searchInput" placeholder="No KTP"/></th>
          <th><input id="tanggal_lahir" type="text" class="searchInput" placeholder="Tanggal Lahir"/></th>
          <th><input id="sl_code" type="text" class="searchInput" placeholder="SL"/></th>
          <th><input id="sl_code2" type="text" class="searchInput" placeholder="SL Piutang"/></th>
          <th><input id="sl_code3" type="text" class="searchInput" placeholder="SL UJ"/></th>
          <th><input id="address" type="text" class="searchInput" placeholder="Alamat"/></th>
          <th><input id="valid_to" type="text" class="searchInput" placeholder="Berlaku Sampai"/></th>
          <th><input id="valid_to_contract" type="text" class="searchInput" placeholder="Kontrak Sampai"/></th>
          <th><input hidden class="searchInput"/></th>
          <th><input hidden class="searchInput"/></th>
          <th><input hidden class="searchInput"/></th>
          <th><input hidden class="searchInput"/></th>
        </tr>
      </tfoot>

    </table>
  </div>
  </div>
</section>


<div  bsModal #ModalDriver="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog" style="max-width: 80%;" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title"><strong>{{action}} Data Driver</strong></div>
          <small><code style="color: black">&nbsp;&nbsp;*(semua kolom wajib di isi)</code></small>
          <button type="button" class="close" #modalSaveClose (click)="ModalDriver.hide()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
        
   <div class="row">
    <div class="col-md-6">
            <div class="form-group row">
                <label class="col-md-3 col-form-label" for="text-input">Nama Supir</label>
                <div class="col-md-8">
                        <input hidden [(ngModel)]="driverModel.driver_id" type="text" class="form-control">
                        <input [(ngModel)]="driverModel.driver_name" type="text" class="form-control input-upper">
                </div>
            </div> 
            <div class="form-group row">
                <label class="col-md-3 col-form-label" for="text-input">Nama KTP</label>
                <div class="col-md-8">
                    <input [(ngModel)]="driverModel.ktp_name" type="text" class="form-control input-upper">
                </div>
            </div>  
            <div class="form-group row">
                <label class="col-md-3 col-form-label" for="text-input">No KTP</label>
                <div class="col-md-8">
                    <input [(ngModel)]="driverModel.ktp_no" (keypress)="numericOnly($event)" max-numbers="16" type="text" class="form-control">
                </div>
            </div> 
            <div class="form-group row">
                <label class="col-md-3 col-form-label" for="text-input">Nama SIM</label>
                <div class="col-md-8">
                    <input [(ngModel)]="driverModel.sim_name" type="text" class="form-control input-upper">
                </div>
            </div>  
            <div class="form-group row">
                <label class="col-md-3 col-form-label" for="text-input">No SIM</label>
                <div class="col-md-8">
                    <input [(ngModel)]="driverModel.driver_license" (keypress)="numericOnly($event)" type="text" class="form-control">
                </div>
            </div>
            <div class="form-group row">
                <label class="col-md-3 col-form-label" for="text-input">Jenis SIM</label>
                <div class="col-md-8">
                    <input [(ngModel)]="driverModel.driver_license_type" type="text" class="form-control input-upper">
                </div>
            </div>     
            <div class="form-group row">
                <label class="col-md-3 col-form-label" for="text-input">NPWP</label>
                <div class="col-md-8">
                    <input [(ngModel)]="driverModel.npwp" [ngModelOptions]="{standalone: true}"  type="text" class="form-control">
                </div>
            </div> 
            <div class="form-group row">
                <label class="col-md-3 col-form-label" for="text-input">No HP</label>
                <div class="col-md-8">
                    <input [(ngModel)]="driverModel.phone" [ngModelOptions]="{standalone: true}"  type="text" class="form-control">
                </div>
            </div> 
            <div class="form-group row">
                <label class="col-md-3 col-form-label" for="text-input">Tempat Lahir</label>
                <div class="col-md-8">
                    <input [(ngModel)]="driverModel.birthplace" type="text" class="form-control input-upper">
                </div>
            </div> 
            <div class="form-group row">
                <label class="col-md-3 col-form-label" for="text-input">Tanggal Lahir</label>
                <div class="col-md-8">
                    <input [ngModel]="driverModel.tanggal_lahir | date:'yyyy-MM-dd'" (ngModelChange)="driverModel.tanggal_lahir = $event"  type="date" class="form-control">
                </div>
            </div> 
    </div>
    <div class="col-md-6"> 
        <div class="form-group row">
            <label class="col-md-3 col-form-label" for="text-input">Agama</label>
            <div class="col-md-8">                   
                <select [(ngModel)]="driverModel.agama" id="select" name="select" class="form-control">
                    <option value="Budha">Budha</option>
                    <option value="Hindu">Hindu</option>
                    <option value="Islam">Islam</option>
                    <option value="Katolik">Katolik</option>
                    <option value="Kristen">Kristen</option>
                    <option value="Other">Other</option>
                </select>
            </div>
        </div> 
        <div class="form-group row">
            <label class="col-md-3 col-form-label" for="text-input">SL Piutang Supir</label>
            <div class="col-md-8">
                  <input [(ngModel)]="driverModel.sl_code" type="text" class="form-control">
           </div>
        </div> 
        <!-- <div class="form-group row">
            <label class="col-md-3 col-form-label" for="text-input">SL Piutang UJ Supir</label>
            <div class="col-md-8">
                  <input [(ngModel)]="driverModel.sl_code2" type="text" class="form-control" formControlName="sl_code2">
                  <span class="text-danger" *ngIf="driverForm.controls['sl_code2'].hasError('required') && 
                    (driverForm.controls['sl_code2'].dirty || driverForm.controls['sl_code2'].touched)">Kolom Tidak Boleh Kosong!</span>
           </div>
        </div>  
        <div class="form-group row">
            <label class="col-md-3 col-form-label" for="text-input">SL Uang Muka Supir</label>
            <div class="col-md-8">
                  <input [(ngModel)]="driverModel.sl_code3" type="text" class="form-control" formControlName="sl_code3">
                  <span class="text-danger" *ngIf="driverForm.controls['sl_code3'].hasError('required') && 
                    (driverForm.controls['sl_code3'].dirty || driverForm.controls['sl_code3'].touched)">Kolom Tidak Boleh Kosong!</span>
           </div>
        </div>   -->
        <div class="form-group row">
            <label class="col-md-3 col-form-label" for="text-input">SIM Berlaku Sampai</label>
            <div class="col-md-8">
                  <input [disabled]="action != 'Input'" [ngModel]="driverModel.valid_to | date:'yyyy-MM-dd'" (ngModelChange)="driverModel.valid_to = $event"  type="date" class="form-control">
           </div>
        </div>  
        <div class="form-group row">
            <label class="col-md-3 col-form-label" for="text-input">Kemitraan Sampai</label>
            <div class="col-md-8">
                  <input [disabled]="action != 'Input'" [ngModel]="driverModel.valid_to_contract | date:'yyyy-MM-dd'" (ngModelChange)="driverModel.valid_to_contract = $event"  type="date" class="form-control">
           </div>
        </div>  
        <div class="form-group row">
            <label class="col-md-3 col-form-label" for="text-input">Alamat</label>
            <div class="col-md-8">
                  <textarea [(ngModel)]="driverModel.address" rows="3" type="text" class="form-control input-upper"></textarea>
           </div>
        </div>   
        <div class="form-group row">
            <label class="col-md-3 col-form-label" for="text-input">Status Aktif</label>
            <div class="col-sm-1">
                <input type="checkbox" [(ngModel)]="driverModel.active" [checked]="driverModel.active" [ngModelOptions]="{standalone: true}" class="form-control">
            </div>
        </div> 
        <div class="form-group row">
          <label class="col-md-3 col-form-label" for="text-input"></label>
          <div class="col-md-8">
                <textarea rows="2" [(ngModel)]="driverModel.nonactive_reason" type="text" class="form-control input-upper"></textarea>
         </div>
      </div> 
        <div class="form-group row">
        </div> 
        <div class="form-group row">
        </div> 
        <div class="form-group row">
        </div> 
        <div class="form-group row">
          <div class="row">
            <div class="col-md-4">
              <div class="form-group row">
                  <div class="col-md-3">
                      <input type="checkbox" [(ngModel)]="driverModel.bpjs" [checked]="driverModel.bpjs" [ngModelOptions]="{standalone: true}" class="form-control">
                  </div>
                  <label class="col-md-8 col-form-label" for="text-input">BPJS Kesehatan</label>
              </div> 
            </div>
            <div class="col-md-4">
              <div class="form-group row">
                  <div class="col-md-3">
                      <input type="checkbox" [(ngModel)]="driverModel.bpjstk" [checked]="driverModel.bpjstk" [ngModelOptions]="{standalone: true}" class="form-control">
                  </div>
                  <label class="col-md-8 col-form-label" for="text-input">BPJS Ketenagakerjaan</label>
              </div> 
            </div>
            <div class="col-md-4">
              <div class="form-group row">
                  <div class="col-md-3">
                      <input type="checkbox" [(ngModel)]="driverModel.jht" [checked]="driverModel.jht" [ngModelOptions]="{standalone: true}" class="form-control">
                  </div>
                  <label class="col-md-8 col-form-label" for="text-input">Jaminan Pensiun</label>
              </div> 
            </div>
          </div>
        </div>
      </div>  

    </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" (click)="ModalDriver.hide()"><b>Close</b></button>
            <button type="button" class="btn btn-primary"  (click)="saveDataDriver();"><i class="fa fa-save"></i>&nbsp;&nbsp;<b>Save</b>&nbsp;&nbsp;</button>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div  bsModal #ModalSLCode="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog  modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title">
            <div class="row">
              <strong>Ubah SL Code</strong>
            </div>
            <br>
          </div>
        </div>
        <div class="modal-body">
            <div class="form-group row">
              <label class="col-md-3 col-form-label" for="text-input">SL Code</label>
              <div class="col-md-8">
                    <input [(ngModel)]="changeSLCodeModel.sl_code" type="text" class="form-control">
             </div>
            </div>  
        <div class="modal-footer">
          <button #ModalSlCodeClose type="button" class="btn btn-secondary" (click)="ModalSLCode.hide()"><b>Close</b></button>
          <button type="button" class="btn btn-primary" (click)="changeSlCode()"><i class="fa fa-save"></i>&nbsp;&nbsp;<b>Update</b>&nbsp;&nbsp;</button>
        </div>
        </div>
      </div>
    </div>
  </div>

  
<div  bsModal #ModalSIM="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog  modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title">
          <div class="row">
            <strong>Form Ganti SIM</strong>
          </div>
          <br>
          <!-- <div class="row">
            <label><code style="color: black">Peringatan!
              Data supir dengan SIM yang lama masih akan disimpan 
              dengan status TIDAK AKTIF, dan data supir yang baru 
              akan di buat secara otomatis dengan sl code dan nama
              supir yang sama.
              
              Semua hutang supir yang masih ada akan di pindahan
              ke data supir dengan SIM baru.</code>
            </label>
          </div> -->
        </div>
        <button type="button" class="close" #modalSaveClose (click)="ModalSIM.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
          <div class="form-group row">
            <label class="col-md-3 col-form-label" for="text-input">Nama Supir</label>
            <div class="col-md-8">
                  <input disabled [(ngModel)]="driverModel.driver_name" type="text" class="form-control input-upper">
           </div>
          </div>  
          <div class="form-group row">
            <label class="col-md-3 col-form-label" for="text-input">No SIM</label>
            <div class="col-md-8">
                  <input [(ngModel)]="simModel.sim" type="text" class="form-control">
           </div>
          </div>  
          <div class="form-group row">
            <label class="col-md-3 col-form-label" for="text-input">Masa Berlaku</label>
            <div class="col-md-8">
                  <input [(ngModel)]="simModel.valid" type="date" class="form-control">
           </div>
          </div>  
      <div class="modal-footer">
        <button #modalSaveClose2 type="button" class="btn btn-secondary" (click)="ModalSIM.hide()"><b>Close</b></button>
        <button type="button" class="btn btn-primary" (click)="licenseChange()"><i class="fa fa-save"></i>&nbsp;&nbsp;<b>Save</b>&nbsp;&nbsp;</button>
      </div>
      </div>
    </div>
  </div>
</div>

<div  bsModal #ModalContract="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog  modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title"><strong>List Data Kontrak</strong></div>
        <button type="button" class="close" (click)="ModalContract.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="card">
          <div class="card-body">
            <div class="form-group row">
              <label class="col-md-3 col-form-label" for="text-input">Berlaku Sejak</label>
              <div class="col-md-9">
                <input [(ngModel)]="contractModel.contract_valid_from" (ngModelChange)="onChangeDateContract()" type="date" class="form-control">
              </div>
            </div> 
            <div class="form-group row">
              <label class="col-md-3 col-form-label" for="text-input">Berlaku Sampai</label>
              <div class="col-md-9">
                <input [(ngModel)]="contractModel.contract_valid_to" type="date" class="form-control">
              </div>
            </div> 
            <br>
            <div style="text-align: center">
                <button type="button" class="btn btn-sm btn-primary" (click)="saveDataContract()">&nbsp;Tambah&nbsp;</button>
            </div>
            <br>
            <br>
            <table datatable [dtOptions]="dtOptions2" [dtTrigger]="dtTrigger" class="table-striped">
              <thead>
                <tr>
                  <th>Action</th>
                  <th>Berlaku Sejak</th>
                  <th>Berlaku Sampai</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let row of dataContract">
                  <td>
                    <button class="btn btn-sm btn-danger" title="Delete" (click)="deleteContract(row);"><i class="fa fa-trash"></i></button>&nbsp;
                    <button class="btn btn-sm btn-primary" title="Cetak Kontrak" (click)="printContract(row);"><i class="fa fa-print"></i></button>&nbsp;
                  </td>
                  <td>{{row.contract_valid_from | slice:0:10}}</td>
                  <td>{{row.contract_valid_to | slice:0:10}}</td> 
                </tr>
              </tbody>
        
            </table>
          </div>
      </div>
      </div>
    </div>
  </div>
</div>


<div bsModal #ModalBlacklist="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog" style="max-width: 50%" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title">
        </div>
        <button #ModalBlacklistClose type="button" class="close" (click)="ModalBlacklist.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-12"> 
                    <div class="form-group row">
                        <label class="col-md-4 col-form-label" for="text-input">Nama Supir</label>
                        <div class="col-md-7">
                            <input disabled [(ngModel)]="driverName" type="text" class="form-control input-upper">
                        </div>
                    </div> 
                    <div class="form-group row">
                      <label class="col-md-4 col-form-label" for="text-input">Blacklist Supir</label>
                      <div class="col-md-7">
                        <select [(ngModel)]="blacklistModel.blacklist"  class="form-control">
                          <option value=true><b>Ya</b></option>
                          <option value=false><b>Tidak</b></option>
                        </select>   
                      </div>
                  </div>  
                  <div class="form-group row">
                      <label class="col-md-4 col-form-label" for="text-input">Alasan Blacklist</label>
                      <div class="col-md-7">
                          <textarea [(ngModel)]="blacklistModel.reason" rows="3" type="text" class="form-control input-upper"></textarea>
                      </div>
                  </div> 
              </div> 
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" (click)="addBlacklist()" class="btn btn-primary"><i class="fas fa-save"></i>&nbsp;<b>Simpan</b>&nbsp;</button> 
    </div>
    </div>
  </div>
</div>

<button hidden #ModalBlacklistShow (click)="ModalBlacklist.show()"></button>

  <ngx-spinner  bdColor = "rgba(248,248,248,0.95)" size = "large" color = "#262424" type = "ball-spin-fade" >
    <p style="font-size: 20px; color: white"></p>
  </ngx-spinner>

