
import { SpkService, SpkServiceDetail } from '@/model/spkService';
import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import { Router } from '@angular/router';
import { TransportService } from '@services/api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject, Subscription, timer } from 'rxjs';
@Component({
  selector: 'app-dashboard-service',
  templateUrl: './dashboard-service.component.html',
  styleUrls: ['./dashboard-service.component.scss']
})

export class DashboardServiceComponent implements OnInit, AfterViewInit {
 @ViewChild('openModalSPK') openModalSPK: ElementRef;
 subscription: Subscription;
 everyFiveSeconds: Observable<number> = timer(0, 10000);
 dataSpk : SpkService[]=[];
 listSpkDetail: SpkServiceDetail[] = [];
 dataNew : SpkService[]=[];
 dataProcess : SpkService[]=[];
 dataFinish : SpkService[]=[];
 countRingan: number;
 countSedang: number;
 countBerat: number;
 countSangatBerat: number; 
  dtOptionsAcc: any = {};
  dtTriggerAcc = new Subject();
  dtOptionsDetail: any = {};
  dtTriggerDetail = new Subject();
  public dt: any;
  public cb: any;
  dataListSPK: SpkService[] = [];
  @Input() spkModel = { SpkId: null, SpkNo: null, TruckId: null, DriverId: null, TruckNo: null, DriverName: null
    , OilChange: null, StartDate: null, EndDate: null, spkDetail: new Array(), spkDetailDelete: new Array()}
  
  constructor(public service: TransportService, private router: Router, private spinner: NgxSpinnerService, private changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit(): void {
    
    this.getListSPK("");
    const that = this;
      $('.searchInput').on("keyup", function(e) {
        var key = e.which;
        if (key == 13) // the enter key ascii code
        {
          that.getListSPK($('#truckNo').val().toString());
        }
      });

      this.dtOptionsAcc = {
        retrieve: true,
        ordering : false,
        searching : false,
      };

      this.dtOptionsDetail = {
        retrieve: true,
        ordering : false,
        searching : false,
        columnDefs: [
          { width: 80, targets: [0,1,2,3] }
        ],
      };

  }
  ngAfterViewInit(): void {
    this.dtTriggerAcc.next(this.dtOptionsAcc);
    this.dtTriggerDetail.next(this.dtOptionsDetail);
  }
  ngOnDestroy() {
    this.dtTriggerAcc.unsubscribe();
    this.dtTriggerDetail.unsubscribe();
    this.subscription.unsubscribe();
  }

  showSPK(damageType: string){
    this.service.getSPKServiceAll("","","","",damageType).subscribe( resp =>{
      this.dataListSPK = resp;
      this.openModalSPK.nativeElement.click();
     })
  }

  getListSPK(truckNo: string){
    this.service.getSPKServiceAll("",truckNo,"","","").subscribe( resp =>{
      this.dataSpk = resp;
     this.dataNew = resp.filter(x => x.status.includes("NEW") || x.status.includes("APPROVED"));
      this.dataProcess = resp.filter(x => x.status == "PROCESS" || x.status == "WAITING FINISH APPROVAL");
      this.dataFinish = resp.filter(x => x.status == "FINISHED");
      this.countRingan = resp.filter(x => x.damageType == "Ringan").length;
      this.countSedang = resp.filter(x => x.damageType == "Sedang").length;
      this.countBerat = resp.filter(x => x.damageType == "Berat").length;
      this.countSangatBerat = resp.filter(x => x.damageType == "Sangat Berat").length;
    })
  }

  onSelectDetailSPK(data: any){
    this.service.getListSPKDetail(data.spkId).subscribe(resp => {
      this.listSpkDetail = resp;
    });
    this.spkModel.SpkId = data.spkId;
    this.spkModel.SpkNo = data.spkNo;
    this.spkModel.TruckId = data.truckId;
    this.spkModel.DriverId = data.driverId;
    this.spkModel.TruckNo = data.truckNo;
    this.spkModel.DriverName = data.driverName;
    this.spkModel.OilChange = data.oilChange;
    this.spkModel.StartDate = data.startDate;
    this.spkModel.EndDate = data.endDate;
  }
}
