import { AfterViewInit, Component, OnInit, ViewChild,Input, ChangeDetectorRef, ElementRef  } from '@angular/core';
import { TransportService} from '../../../services/api.service';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { AccClosing } from '@/model/accClosing';
import { MasterCode } from '@/model/masterCode';
import moment from 'moment';
import { User } from '@/model/user';
import { Role } from '@/model/role';
import { SubLocation } from '@/model/masterMenu';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit, AfterViewInit {
  @ViewChild('modalSimpanClose') modalSimpanClose: ElementRef;
  @ViewChild('modalUpdateClose') modalUpdateClose: ElementRef
  dtOptionsAcc: any = {};
  dtTriggerAcc = new Subject();
  dataListAcc: User[] = [];
  listRole: Role[] = [];
  public dt: any;
  public cb: any;
  @Input() userModel = { id: null, user_name: null, user_password: null, user_role: null, first_name: null, last_name: null, sub_location: null}
  constructor( public service: TransportService, private router: Router, private spinner: NgxSpinnerService, private changeDetectorRef: ChangeDetectorRef) {
  }

    ngOnInit(): void {
      this.userAccess();
      this.service.getRole().subscribe(resp => {
        this.listRole = resp;
      });

      this.dtOptionsAcc = {  
        retrieve: true,
        pageLength: 10,
        ordering : false,
        scrollX: true,
        scrollCollapse: true,
        serverSide: true,
        processing: true,
        searching : false,
        ajax: (dataTablesParameters: any, callback) => {
         this.dt = dataTablesParameters; this.cb = callback;
         this.getListUser(dataTablesParameters, callback,  true);

        },
        columnDefs: [
          { width: 160, targets: [0, 1,2,3,4,5,6,7,8,9]}
        ],
      };
    }   

    userAccess(){
      const username = localStorage.getItem("username");
      if (!username.includes("admin")){
          return this.router.navigate(["/home"]);
      }
    }
    
    ngAfterViewInit(): void {
      this.dtTriggerAcc.next(0);
    }
  
    ngOnDestroy(): void {
     this.dtTriggerAcc.unsubscribe();
    }

    onSelectDataUser(data: User){
      this.userModel.id = data.user_id;
      this.userModel.user_name = data.user_name;
      this.userModel.user_role = data.user_role;
      this.userModel.first_name = data.first_name;
      this.userModel.last_name = data.last_name;
      this.userModel.sub_location = data.sub_location;
    }

  getListUser(dataTablesParameters, callback, padging: Boolean){
    var page = 1;
      if (padging == true){
        page = parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length) + 1;
      }

      this.service.getListUser(dataTablesParameters, page, dataTablesParameters.length)
      .subscribe(resp => { 
          this.dataListAcc = resp.data;
          callback({
            recordsTotal: resp.totalCount,
            recordsFiltered: resp.totalCount,
             data:  []
          });
      });
  }

  setNull(){
   this.userModel = {id: null, user_name: null, user_password: null, user_role: null, first_name: null, last_name: null, sub_location: null}
  }

  messageWarning(text: string){
    Swal.fire({
      title: ""+text,
      type: 'warning',
      confirmButtonText: '      Ok!     '
    });
    return;
  }

  createUser(){
    if (this.userModel.first_name == null || this.userModel.last_name == null || this.userModel.user_password == null || this.userModel.user_role == null || this.userModel.user_name == null){
      Swal.fire({
        title: "Data Masih Belum Lengkap !",
        type: 'warning',
        confirmButtonText: '      Ok!     '
      });
      return;
    }
    Swal.fire({
      title: 'Simpan User?',
      type: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '      Yes     '
      }).then((result) => {
        if (result.value) {
          this.spinner.show();
          this.service.createUser(this.userModel).subscribe( resp => {
            this.spinner.hide();
            if(resp.succes == false){
              Swal.fire({
                title: ""+resp.text,
                type: 'warning',
                confirmButtonText: '      Ok!     '
              });
            }
            else{
              Swal.fire({
                title: ""+resp.text,
                type: 'success',
                confirmButtonText: '      Ok!     '
              }).then((result) => {
                if (result.value) { 
                  this.getListUser(this.dt, this.cb, true);
                  this.modalSimpanClose.nativeElement.click();
                }              
              });
            }
          })
        }
    });
  }

  UpdateDataUser(){
    if (this.userModel.first_name == null || this.userModel.last_name == null || this.userModel.user_role == null || this.userModel.user_name == null){
      Swal.fire({
        title: "Data Masih Belum Lengkap !",
        type: 'warning',
        confirmButtonText: '      Ok!     '
      });
      return;
    }
    Swal.fire({
      title: 'Update User?',
      type: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '      Yes     '
      }).then((result) => {
        if (result.value) {
          this.service.updateUser(this.userModel).subscribe( resp => {
            this.spinner.hide();
            if(resp.succes == false){
              Swal.fire({
                title: ""+resp.text,
                type: 'warning',
                confirmButtonText: '      Ok!     '
              });
            }
            else{
              Swal.fire({
                title: ""+resp.text,
                type: 'success',
                confirmButtonText: '      Ok!     '
              }).then((result) => {
                if (result.value) { 
                  this.getListUser(this.dt, this.cb, true);
                  this.modalUpdateClose.nativeElement.click();
                }              
              });
            }
          })
        }
    });
  }

  deleteData(data: User){
    Swal.fire({
      title: 'Delete Data?',
      type: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '      Yes     '
      }).then((result) => {
        if (result.value) {
          this.spinner.show();
          setTimeout(() => {
            this.spinner.hide();
          }, 5000);
          this.service.deleteUser(data.user_id).subscribe(resp => {
            this.spinner.hide();
              if(resp.succes == false){
                Swal.fire({
                  title: ""+resp.text,
                  type: 'warning',
                  confirmButtonText: '      Ok!     '
                });
              }
              else{
                Swal.fire({
                  title: ""+resp.text,
                  type: 'success',
                  confirmButtonText: '      Ok!     '
                }).then((result) => {
                  if (result.value) { 
                    this.getListUser(this.dt, this.cb, true);
                  }              
                });
              }
          })

        }
    })
  }

  resetPass(data: User){
    Swal.fire({
      title: 'Reset Password?',
      type: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '      Yes     '
      }).then((result) => {
        if (result.value) {
          this.spinner.show();
          setTimeout(() => {
            this.spinner.hide();
          }, 5000);
          this.service.resetPassword(data.user_id).subscribe(resp => {
            this.spinner.hide();
              if(resp.succes == false){
                Swal.fire({
                  title: ""+resp.text,
                  type: 'warning',
                  confirmButtonText: '      Ok!     '
                });
              }
              else{
                Swal.fire({
                  title: ""+resp.text,
                  type: 'success',
                  confirmButtonText: '      Ok!     '
                }).then((result) => {
                  if (result.value) { 
                    this.getListUser(this.dt, this.cb, true);
                  }              
                });
              }
          })

        }
    })
  }
  
}





