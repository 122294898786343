<section class="content">
    <div class="card">
        <div class="card-header">
          <strong>List BAPB</strong>
          <div class="float-right">
          <a type="button" (click)="setNull();ModalSimpan.show();" class="btn btn-sm btn-primary pull-right" data-toggle="modal">&nbsp;<b>Input BAPB</b>&nbsp;&nbsp;<i class="fa fa-pen"></i>&nbsp;</a>
          </div>
        </div>
        <div class="card-body">
            <table datatable [dtOptions]="dtOptionsAcc" [dtTrigger]="dtTriggerAcc" class="deeps table-striped">
                <thead>
                  <tr>
                    <th>Action</th>
                    <th>No BAPB</th>
                    <th>No SPK</th>
                    <th>Keterangan</th>
                    <th>Status</th>
                    <th>Tanggal BAPB</th>
                    <th>Dibuat Oleh</th>
                    <th>Dibuat Pada</th>
                    <th>Diubah Oleh</th>
                    <th>Diubah Pada</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of dataListBAPB">
                    <td>
                      <button (click)="onSelectDetailSPK(row);ModalSimpan.show()" title="Detail" class="btn btn-sm btn-primary" triggers="mouseenter:mouseleave"><i class="fa fa-list"></i></button>&nbsp;
                      <button (click)="printBAPB(row);" title="Print" class="btn btn-sm btn-primary" triggers="mouseenter:mouseleave"><i class="fa fa-print"></i></button>&nbsp;
                      <button [hidden]="row.status != 'NEW'" (click)="onSelectUpdate(row);ModalSimpan.show()" title="Detail" class="btn btn-sm btn-primary" triggers="mouseenter:mouseleave"><i class="fa fa-pen"></i></button>&nbsp;
                       <!-- <button [hidden]="row.status != 'PROCESS'" (click)="finish(row);" class="btn btn-sm btn-warning"  data-toggle="modal" triggers="mouseenter:mouseleave"><i class="fa fa-flag"></i></button> -->
                    </td>
                    <td>{{row.bapbNo}}</td>
                    <td>{{row.spkNo}}</td>
                    <td>{{row.remark}}</td>
                    <td>{{row.status}}</td>
                    <td>{{row.date | slice:0:10}}</td>
                    <td>{{row.createdName}}</td>
                    <td>{{row.createdAt | slice:0:10}}</td>
                    <td>{{row.updatedName}}</td>
                    <td>{{row.createdAt | slice:0:10}}</td>
                </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <th><input hidden class="searchInputN"/></th>
                    <th><input id="bapbNo" type="text" class="searchInputN" placeholder="No BAPB"/></th>
                    <th><input id="spkNo" type="text" class="searchInputN" placeholder="Np SPK"/></th>
                    <th><input id="remark" type="text" class="searchInputN" placeholder="Keterangan"/></th>
                    <th><input id="status" type="text" class="searchInputN" placeholder="Status"/></th>
                    <th><input hidden class="searchInputN"/></th>
                    <th><input hidden class="searchInputN"/></th>
                    <th><input hidden class="searchInputN"/></th>
                    <th><input hidden class="searchInputN"/></th>
                    <th><input hidden class="searchInputN"/></th>
                  </tr>
                </tfoot>
              </table>
        </div>
    </div>
  </section>

  <div  bsModal #ModalSimpan="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog" style="max-width: 80%;" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title">
            <div class="row">
              <strong>List Menu</strong>
            </div>
          </div>
          <button type="button" class="close" (click)="ModalSimpan.hide()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group row">
                          <label class="col-md-3 col-form-label" for="text-input">NO BAPB</label>
                          <div class="col-md-8">
                              <input disabled type="text" [(ngModel)]="bapbModel.BapbNo" class="form-control">
                          </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-3 col-form-label" for="text-input">NO SPK</label>
                            <input  [(ngModel)]="bapbModel.SpkId"  hidden type="text" class="form-control">
                            <div class="col-md-7">
                              <input [(ngModel)]="bapbModel.SpkNo" disabled type="text" class="form-control">
                            </div>
                            <div class="col-md-2">
                                <button class="btn btn-sm btn-primary"
                                data-toggle="modal" (click)="ModalSPK.show();showSpk();"><i class="fa fa-plus"></i></button>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group row">
                            <label class="col-md-3 col-form-label" for="text-input">Tanggal BAPB</label>
                            <div class="col-md-8">
                                <input [(ngModel)]="bapbModel.Date" type="datetime-local" class="form-control">
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-3 col-form-label" for="text-input">Keterangan</label>
                            <div class="col-md-8">
                                <input [(ngModel)]="bapbModel.Remark" type="text" class="form-control">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
            <br>
            <div class="card">
              <div class="card-body">
                <div class="card">
                    <div class="card-header">
                        <div class="float-right">
                            <a type="button" class="btn btn-sm btn-primary pull-right" data-toggle="modal" (click)="ModalNPB.show();showNpb();">&nbsp;<b>Clone Item NPB</b>&nbsp;&nbsp;<i class="fa fa-clone"></i>&nbsp;</a>&nbsp;&nbsp;&nbsp;&nbsp;
                            <a type="button" class="btn btn-sm btn-warning pull-right" data-toggle="modal" (click)="ModalDetail.show()">&nbsp;<b>Tambah Data</b>&nbsp;&nbsp;<i class="fa fa-plus"></i>&nbsp;</a>
                        </div>
                    </div>
                    <div class="card-body">
                        <table datatable [dtOptions]="dtOptionsDetail" [dtTrigger]="dtTriggerDetail" class="table-striped">
                            <thead>
                            <tr>
                                <th>Action</th>
                                <th>Kode Item</th>
                                <th>Nama Item</th>
                                <th>QTY</th>
                                <th>UOM</th>
                                <th>Keterangan</th>
                                <th>Dibuat Pada</th>
                                <th>Dibuat Oleh</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let row of listBapbDetail; let i=index">
                                <td>
                                <button (click)="deleteDataDetail(i, row)" title="Detail" class="btn btn-sm btn-danger" triggers="mouseenter:mouseleave"><i class="fa fa-trash"></i></button>&nbsp;
                                </td>
                                <td>{{row.stockCode}}</td>
                                <td>{{row.itemName}}</td>
                                <td>{{row.qty}}</td>
                                <td>{{row.uom}}</td>
                                <td>{{row.remark}}</td>
                                <td>{{row.createdAt | slice:0:10}}</td>
                                <td>{{row.createdName}}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
              </div>
            </div>

        </div>
        <div class="modal-footer">
          <button #modalSimpanClose type="button" class="btn btn-secondary" (click)="ModalSimpan.hide()">&nbsp;&nbsp;&nbsp;&nbsp;<b>Close</b>&nbsp;&nbsp;&nbsp;&nbsp;</button>
          <button [disabled]="show" [hidden]="update" type="button" (click)="createSPK()" class="btn btn-primary"><i class="fa fa-save"></i>&nbsp;&nbsp;&nbsp;&nbsp;<b>Simpan</b>&nbsp;&nbsp;&nbsp;&nbsp;</button>
          <button [disabled]="show"  [hidden]="!update" type="button" (click)="updateSPK()" class="btn btn-primary"><i class="fa fa-save"></i>&nbsp;&nbsp;&nbsp;&nbsp;<b>Update</b>&nbsp;&nbsp;&nbsp;&nbsp;</button>
        </div>
      </div>
    </div>
  </div>

  <div  bsModal #ModalDetail="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog" style="max-width: 50%;" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title">
            <div class="row">
              <strong>Tambah Item</strong>
            </div>
          </div>
          <button type="button" class="close" (click)="ModalDetail.hide()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group row">
                            <label class="col-md-3 col-form-label" for="text-input">Kode Item</label>
                            <input  [(ngModel)]="bapbDetailModel.itemId"  hidden type="text" class="form-control">
                            <div class="col-md-7">
                              <input disabled [(ngModel)]="bapbDetailModel.stockCode" disabled type="text" class="form-control">
                            </div>
                            <div class="col-md-2">
                                <button class="btn btn-sm btn-primary"
                                data-toggle="modal" (click)="ModalStock.show();showStock();"><i class="fa fa-plus"></i></button>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-3 col-form-label" for="text-input">Nama Item</label>
                            <div class="col-md-8">
                                <input disabled [(ngModel)]="bapbDetailModel.itemName" type="text" class="form-control">
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-3 col-form-label" for="text-input">UOM</label>
                            <div class="col-md-8">
                                <input disabled [(ngModel)]="bapbDetailModel.uom" type="text" class="form-control">
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-3 col-form-label" for="text-input">Stock</label>
                            <div class="col-md-8">
                                <input disabled [(ngModel)]="itemQty" type="text" class="form-control">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group row">
                            <label class="col-md-3 col-form-label" for="text-input">QTY</label>
                            <div class="col-md-8">
                                <input [(ngModel)]="bapbDetailModel.qty" type="text" class="form-control">
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-3 col-form-label" for="text-input">Keterangan</label>
                            <div class="col-md-8">
                            <textarea [(ngModel)]="bapbDetailModel.remark" rows="3" type="text" class="form-control"></textarea>
                            </div>
                        </div> 
                    </div>
                </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button #ModalDetailClose type="button" class="btn btn-secondary" (click)="ModalDetail.hide()">&nbsp;&nbsp;&nbsp;&nbsp;<b>Close</b>&nbsp;&nbsp;&nbsp;&nbsp;</button>
          <button type="button" class="btn btn-primary" (click)="addBAPBDetail()">&nbsp;&nbsp;&nbsp;&nbsp;<i class="fa fa-save"></i>&nbsp;&nbsp;<b>Simpan</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</button>
        </div>
      </div>
    </div>
  </div> 

 <div  bsModal #ModalSPK="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog  modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title"><strong>Pilih SPK</strong></div>
          <button #modalSPKClose type="button" class="close" (click)="ModalSPK.hide()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <table datatable [dtOptions]="dtOptionsSpk" [dtTrigger]="dtTriggerSpk" class="deeps table-striped">
            <thead>
              <tr>
                <th>Action</th>
                <th>No SPK</th>
                <th>Truk</th>
                <th>Supir</th>
                <th>Status</th>
                <th>Dibuat Oleh</th>
                <th>Dibuat Pada</th>
                <th>Diubah Oleh</th>
                <th>Diubah Pada</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let row of listSPK">
                <td>
                  <button (click)="onSelectSpk(row);" class="btn btn-sm btn-primary"  data-toggle="modal" triggers="mouseenter:mouseleave"><i class="fa fa-check"></i></button>
                </td>
                <td>{{row.spkNo}}</td>
                <td>{{row.truckNo}}</td>
                <td>{{row.driverName}}</td>
                <td>{{row.status}}</td>
                <td>{{row.createdName}}</td>
                <td>{{row.createdAt | slice:0:10}}</td>
                <td>{{row.updatedName}}</td>
                <td>{{row.createdAt | slice:0:10}}</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th><input hidden class="searchInputD"/></th>
                <th><input id="spkNo" type="text" class="searchInputD" placeholder="No SPK"/></th>
                <th><input id="truckNo" type="text" class="searchInputD" placeholder="No Truk"/></th>
                <th><input id="driverName" type="text" class="searchInputD" placeholder="Nama Supir"/></th>
                <th><input hidden class="searchInputD"/></th>
                <th><input hidden class="searchInputD"/></th>
                <th><input hidden class="searchInputD"/></th>
                <th><input hidden class="searchInputD"/></th>
                <th><input hidden class="searchInputD"/></th>
              </tr>
            </tfoot>

          </table>
        </div>
      </div>
    </div>
    </div>

    
    <div  bsModal #ModalNPB="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
      <div class="modal-dialog  modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <div class="modal-title"><strong>Pilih SPK</strong></div>
            <button #modalNPBClose type="button" class="close" (click)="ModalNPB.hide()" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <table datatable [dtOptions]="dtOptionsNpb" [dtTrigger]="dtTriggerNpb" class="deeps table-striped">
              <thead>
                <tr>
                  <th>Action</th>
                  <th>No NPB</th>
                  <th>No SPK</th>
                  <th>Keterangan</th>
                  <th>Status</th>
                  <th>Tanggal NPB</th>
                  <th>Dibuat Oleh</th>
                  <th>Dibuat Pada</th>
                  <th>Diubah Oleh</th>
                  <th>Diubah Pada</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let row of dataListNPB">
                  <td>
                  <button (click)="onSelectDetailNpb(row);" title="Detail" class="btn btn-sm btn-primary" triggers="mouseenter:mouseleave"><i class="fa fa-check"></i></button>&nbsp;
                  </td>
                  <td>{{row.npbNo}}</td>
                  <td>{{row.spkNo}}</td>
                  <td>{{row.remark}}</td>
                  <td>{{row.status}}</td>
                  <td>{{row.date | slice:0:10}}</td>
                  <td>{{row.createdName}}</td>
                  <td>{{row.createdAt | slice:0:10}}</td>
                  <td>{{row.updatedName}}</td>
                  <td>{{row.createdAt | slice:0:10}}</td>
              </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th><input hidden class="searchInputN"/></th>
                  <th><input id="npbNo" type="text" class="searchInputP" placeholder="No NPB"/></th>
                  <th><input id="spkNo" type="text" class="searchInputP" placeholder="Np SPK"/></th>
                  <th><input id="remark" type="text" class="searchInputP" placeholder="Keterangan"/></th>
                  <th><input id="status" type="text" class="searchInputP" placeholder="Status"/></th>
                  <th><input hidden class="searchInputP"/></th>
                  <th><input hidden class="searchInputP"/></th>
                  <th><input hidden class="searchInputP"/></th>
                  <th><input hidden class="searchInputP"/></th>
                  <th><input hidden class="searchInputP"/></th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
      </div>

    <div  bsModal #ModalStock="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
        <div class="modal-dialog  modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <div class="modal-title"><strong>Pilih Item</strong></div>
              <button type="button" class="close" (click)="ModalStock.hide()" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <table datatable [dtOptions]="dtOptionsStock" [dtTrigger]="dtTriggerStock" class="deeps table-striped">
                <thead>
                  <tr>
                    <th>Action</th>
                    <th>Kode Item</th>
                    <th>Item</th>
                    <th>Stock</th>
                    <th>Sub Kategori</th>
                    <th>Kategori</th>
                    <th>GL Account</th>
                    <th>UOM</th>
                    <th>Harga Unit</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of dataStock">
                    <td>
                      <button (click)="onSelectStock(row);ModalStock.hide()" class="btn btn-sm btn-primary"  data-toggle="modal" triggers="mouseenter:mouseleave"><i class="fa fa-check"></i></button>
                    </td>
                    <td>{{row.itemCode}}</td>
                    <td>{{row.itemName}}</td>
                    <td>{{row.qty}}</td>
                    <td>{{row.itemSubKategori}}</td>
                    <td>{{row.itemKategori}}</td>
                    <td>{{row.gL_Account}}</td>
                    <td>{{row.uom}}</td>
                    <td>{{row.unitPrice}}</td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <th><input hidden class="searchInputS"/></th>
                    <th><input id="ItemCode" type="text" class="searchInputS" placeholder="Kode Item"/></th>
                    <th><input id="ItemName" type="text" class="searchInputS" placeholder="Item"/></th>
                    <th><input id="ItemSubKategori" type="text" class="searchInputS" placeholder="Sub Kategori"/></th>
                    <th><input id="ItemKategori" type="text" class="searchInputS" placeholder="Kategori"/></th>
                    <th><input id="GL_Account" type="text" class="searchInputS" placeholder="GL Account"/></th>
                    <th><input hidden class="searchInputS"/></th>
                    <th><input hidden class="searchInputS"/></th>
                  </tr>
                </tfoot>
    
              </table>
            </div>
          </div>
        </div>
        </div>

  <ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>