<section class="content">    
    <div class="card">
        <div class="card-header">
          <strong>List Role</strong>
          <div class="float-right">
          <a type="button" (click)="ModalSimpan.show();setNull();" class="btn btn-sm btn-primary pull-right" data-toggle="modal">&nbsp;<b>Tambah Role</b>&nbsp;&nbsp;<i class="fa fa-pen"></i>&nbsp;</a>
          </div>
        </div>
        <div class="card-body">
            <table datatable [dtOptions]="dtOptionsAcc" [dtTrigger]="dtTriggerAcc" class="table-striped">
                <thead>
                  <tr>
                    <th>Action</th>
                    <th>Role</th>
                    <th>Dibuat Oleh</th>
                    <th>Dibuat Pada</th>
                    <th>Diubah Oleh</th>
                    <th>Diubah Pada</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of dataListAcc">    
                    <td>
                      <button (click)="onSelectDataUser(row);ModalUpdate.show()" title="Detail" class="btn btn-sm btn-primary" triggers="mouseenter:mouseleave"><i class="fa fa-pen"></i></button>&nbsp;
                      <button (click)="deleteRole(row)" class="btn btn-sm btn-danger" title="Delete" triggers="mouseenter:mouseleave"><i class="fa fa-trash"></i></button>&nbsp;
                      <!-- <button (click)="resetPass(row);" title="Reset Password" class="btn btn-sm btn-primary" triggers="mouseenter:mouseleave"><i class="fa fa-key"></i></button>&nbsp;
                      <button (click)="deleteData(row)" class="btn btn-sm btn-danger" title="Delete" triggers="mouseenter:mouseleave"><i class="fa fa-trash"></i></button>&nbsp; -->
                    </td>       
                    <td>{{row.role_name}}</td>
                    <td>{{row.createdBy}}</td>
                    <td>{{row.created_at | slice:0:10}}</td>
                    <td>{{row.updatedBy}}</td>
                    <td>{{row.updated_at | slice:0:10}}</td>
                </tr>
                </tbody>
              </table>
        </div>
    </div>
  </section>

  <div  bsModal #ModalUpdate="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog" style="max-width: 100%;" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title">
            <div class="row">
              <strong>List Menu</strong>
            </div>
          </div>
          <button type="button" class="close" (click)="ModalUpdate.hide()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-4">
                        <div class="form-group row group">
                          <label class="col-md-3 col-form-label" for="text-input">Menu</label>
                          <div class="col-md-8">
                              <select [(ngModel)]="menu" (ngModelChange)="onSelectMenu($event)" id="select" name="select" class="form-control">
                                  <option></option>
                                  <option selected *ngFor="let row of listMenu" [value]='row.menuId'><b>{{row.text}}</b></option>
                              </select>
                          </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group row group">
                          <label class="col-md-3 col-form-label" for="text-input">Sub Menu</label>
                          <div class="col-md-8">
                              <select [(ngModel)]="authModel.subMenuId" (ngModelChange)="onSelectSubMenu($event)" id="select" name="select" class="form-control">
                                  <option></option>
                                  <option selected *ngFor="let row of listSubMenu" [value]='row.subMenuId'><b>{{row.text}}</b></option>
                              </select>
                          </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group row group">
                          <label class="col-md-3 col-form-label" for="text-input">Akses</label>
                          <div class="col-md-8">
                              <select [(ngModel)]="authModel.authId" id="select" name="select" class="form-control">
                                  <option></option>
                                  <option selected *ngFor="let row of listMasterAuth" [value]='row.id'><b>{{row.text}}</b></option>
                              </select>
                          </div>
                        </div>
                    </div>
               </div>
            </div>
          </div>
            <div style="text-align: center">
              <button type="button" (click)="addAuth()" class="btn btn-primary"><i class="fas fa-floppy-disk"></i>&nbsp;<b>Simpan Data</b>&nbsp;</button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </div>
            <br>
            <div class="card">
              <div class="card-body">
                  <table datatable [dtOptions]="dtOptionsAuth" [dtTrigger]="dtTriggerAuth" class="table-striped">
                      <thead>
                        <tr>
                          <th>Action</th>
                          <th>Menu</th>
                          <th>Sub Menu</th>
                          <th>Akses</th>
                          <th>Dibuat Pada</th>
                          <th>Dibuat Oleh</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let row of listAuthMenu">    
                          <td>
                            <button (click)="deleteData(row)" title="Detail" class="btn btn-sm btn-danger" triggers="mouseenter:mouseleave"><i class="fa fa-trash"></i></button>&nbsp;
                          </td>       
                          <td>{{row.menuName}}</td>
                          <td>{{row.subMenuName}}</td>
                          <td>{{row.authName}}</td>
                          <td>{{row.createdAt | slice:0:10}}</td>
                          <td>{{row.createdName}}</td>
                      </tr>
                      </tbody>
                    </table>
              </div>
            </div>

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" (click)="ModalUpdate.hide()">&nbsp;&nbsp;&nbsp;&nbsp;<b>Close</b>&nbsp;&nbsp;&nbsp;&nbsp;</button>
        </div>
      </div>
    </div>
  </div> 

  <div  bsModal #ModalSimpan="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog" style="max-width: 50%;" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title">
            <div class="row">
              <strong>List User</strong>
            </div>
          </div>
          <button type="button" class="close" (click)="ModalSimpan.hide()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-12"> 
                    <div class="form-group row">
                        <label class="col-md-3 col-form-label" for="text-input">Role Name</label>
                        <div class="col-md-8">
                            <input [(ngModel)]="roleModel.role_name" type="text" class="form-control">
                        </div> 
                    </div>
                </div>
            </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button #modalSimpanClose type="button" class="btn btn-secondary" (click)="ModalSimpan.hide()">&nbsp;&nbsp;&nbsp;&nbsp;<b>Close</b>&nbsp;&nbsp;&nbsp;&nbsp;</button>
          <button type="button" class="btn btn-primary" (click)="createRole()">&nbsp;&nbsp;&nbsp;&nbsp;<i class="fa fa-save"></i>&nbsp;&nbsp;<b>Simpan</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</button>
        </div>
      </div>
    </div>
  </div> 


  <ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>