import { AfterViewInit, Component, OnInit, ViewChild,Input, ChangeDetectorRef, ElementRef  } from '@angular/core';
import { TransportService} from '../../../services/api.service';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { AccClosing } from '@/model/accClosing';
import { MasterCode } from '@/model/masterCode';
import moment from 'moment';
import { User } from '@/model/user';
import { Role } from '@/model/role';
import { MasterAuth, MasterMenu, SubLocation } from '@/model/masterMenu';
import { AuthMenu, MasterSubMenu } from '@/model/masterSubMenu';
import { Guid } from 'guid-typescript';

@Component({
  selector: 'app-list-role',
  templateUrl: './list-role.component.html',
  styleUrls: ['./list-role.component.scss']
})
export class ListRoleComponent implements OnInit, AfterViewInit {
  @ViewChild('modalSimpanClose') modalSimpanClose: ElementRef;
  dtOptionsAcc: any = {};
  dtOptionsAuth: any = {};
  dtTriggerAuth = new Subject();
  dtTriggerAcc = new Subject();
  dataListAcc: Role[] = [];
  listRole: Role[] = [];
  listMenu: MasterMenu[] = [];
  listSubMenu: MasterSubMenu[] = [];
  listMasterAuth: MasterAuth[] = [];
  listAuthMenu: AuthMenu[] = [];
  public dt: any;
  public cb: any;
  menu: Guid;
  @Input() authModel = { roleId: null, subMenuId: null, authId: null}
  @Input() roleModel = { role_id: null, role_name: null}
  constructor( public service: TransportService, private router: Router, private spinner: NgxSpinnerService, private changeDetectorRef: ChangeDetectorRef) {
  }

    ngOnInit(): void {
      this.userAccess();
      this.service.getAllMenu().subscribe(resp => {
        this.listMenu = resp;
      });  
      

      this.dtOptionsAcc = {  
        retrieve: true,
        pageLength: 10,
        ordering : false,
        scrollX: true,
        scrollCollapse: true,
        serverSide: true,
        processing: true,
        searching : false,
        ajax: (dataTablesParameters: any, callback) => {
         this.dt = dataTablesParameters; this.cb = callback;
         this.getRoleList(dataTablesParameters, callback,  true);

        },
        columnDefs: [
          { width: 280, targets: [0, 1,2,3,4]}
        ],
      };

      this.dtOptionsAuth = {
        retrieve: true,
        pageLength: 100,
        ordering : false,
        // scrollX: true,
        // scrollCollapse: true,
        searching : true,
        columnDefs: [
          { width: 200, targets: [1,2,3,4,5]},
          { width: 50, targets: [0] }
        ],
      };

      
    }   

    userAccess(){
      const username = localStorage.getItem("username");
      if (!username.includes("admin")){
          return this.router.navigate(["/home"]);
      }
    }
    
    ngAfterViewInit(): void {
      this.dtTriggerAcc.next(0);
    }
  
    ngOnDestroy(): void {
     this.dtTriggerAcc.unsubscribe();
    }

    onSelectDataUser(data: Role){
      this.authModel.roleId = data.role_id;
      this.service.getAuthByRole(data.role_id.toString()).subscribe(resp => {
        this.listAuthMenu = resp;
        setTimeout(() => {
          this.dtTriggerAuth.next(0);
        }, 100);
      });
    }

    onSelectMenu(id: Guid){
      this.service.getSubmenuById(id).subscribe(resp => {
        this.listSubMenu = resp;
      });
    }

    onSelectSubMenu(id: Guid){
      this.service.getMasterAuth(id).subscribe(resp => {
        this.listMasterAuth = resp;
      });
    }

    

    getRoleList(dataTablesParameters, callback, padging: Boolean){
    var page = 1;
      if (padging == true){
        page = parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length) + 1;
      }

      this.service.getRoleList(dataTablesParameters, page, dataTablesParameters.length)
      .subscribe(resp => { 
          this.dataListAcc = resp.data;
          callback({
            recordsTotal: resp.totalCount,
            recordsFiltered: resp.totalCount,
             data:  []
          });
      });
  }

  setNull(){
   this.authModel = {authId: null, roleId: null, subMenuId: null}
   this.roleModel = {role_id: null, role_name: null}
  }

  messageWarning(text: string){
    Swal.fire({
      title: ""+text,
      type: 'warning',
      confirmButtonText: '      Ok!     '
    });
    return;
  }

  createRole(){
    if (this.roleModel.role_name == null || this.roleModel.role_name == "" ){
      Swal.fire({
        title: "Data Masih Belum Lengkap !",
        type: 'warning',
        confirmButtonText: '      Ok!     '
      });
      return;
    }
    Swal.fire({
      title: 'Simpan Data?',
      type: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '      Yes     '
      }).then((result) => {
        if (result.value) {
          this.spinner.show();
          this.service.addRole(this.roleModel).subscribe( resp => {
            this.spinner.hide();
            if(resp.succes == false){
              Swal.fire({
                title: ""+resp.text,
                type: 'warning',
                confirmButtonText: '      Ok!     '
              });
            }
            else{
              Swal.fire({
                title: ""+resp.text,
                type: 'success',
                confirmButtonText: '      Ok!     '
              }).then((result) => {
                if (result.value) { 
                  this.getRoleList(this.dt, this.cb,  true);
                }              
              });
              this.modalSimpanClose.nativeElement.click();
            }
          })
        }
    });
  }

  addAuth(){
    if (this.authModel.subMenuId == null || this.authModel.roleId == null || this.authModel.authId == null || 
      this.authModel.subMenuId == "" || this.authModel.roleId == "" || this.authModel.authId == ""){
      Swal.fire({
        title: "Data Masih Belum Lengkap !",
        type: 'warning',
        confirmButtonText: '      Ok!     '
      });
      return;
    }
    Swal.fire({
      title: 'Simpan Data?',
      type: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '      Yes     '
      }).then((result) => {
        if (result.value) {
          this.service.addUserAuth(this.authModel).subscribe( resp => {
            this.spinner.hide();
            if(resp.succes == false){
              Swal.fire({
                title: ""+resp.text,
                type: 'warning',
                confirmButtonText: '      Ok!     '
              });
            }
            else{
              Swal.fire({
                title: ""+resp.text,
                type: 'success',
                confirmButtonText: '      Ok!     '
              }).then((result) => {
                if (result.value) { 
                  this.service.getAuthByRole(this.authModel.roleId).subscribe(resp => {
                    this.listAuthMenu = resp;
                    setTimeout(() => {
                      this.dtTriggerAuth.next(0);
                    }, 100);
                  });
                }              
              });
            }
          })
        }
    });
  }

  deleteData(data: AuthMenu){
    Swal.fire({
      title: 'Delete Data?',
      type: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '      Yes     '
      }).then((result) => {
        if (result.value) {
          this.spinner.show();
          setTimeout(() => {
            this.spinner.hide();
          }, 5000);
          this.service.deleteAuthMenu(data.menuAuthId).subscribe(resp => {
            this.spinner.hide();
              if(resp.succes == false){
                Swal.fire({
                  title: ""+resp.text,
                  type: 'warning',
                  confirmButtonText: '      Ok!     '
                });
              }
              else{
                Swal.fire({
                  title: ""+resp.text,
                  type: 'success',
                  confirmButtonText: '      Ok!     '
                }).then((result) => {
                  if (result.value) { 
                    this.service.getAuthByRole(data.roleId.toString()).subscribe(resp => {
                      this.listAuthMenu = resp;
                      setTimeout(() => {
                        this.dtTriggerAuth.next(0);
                      }, 100);
                    });
                  }              
                });
              }
          })

        }
    })
  }

  
  deleteRole(data: Role){
    this.roleModel.role_id = data.role_id;
    console.log("id : "+this.roleModel.role_id)
    Swal.fire({
      title: 'Delete Data?',
      type: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '      Yes     '
      }).then((result) => {
        if (result.value) {
          this.spinner.show();
          setTimeout(() => {
            this.spinner.hide();
          }, 5000);
          this.service.deleteRole(this.roleModel).subscribe(resp => {
            this.spinner.hide();
              if(resp.succes == false){
                Swal.fire({
                  title: ""+resp.text,
                  type: 'warning',
                  confirmButtonText: '      Ok!     '
                });
              }
              else{
                Swal.fire({
                  title: ""+resp.text,
                  type: 'success',
                  confirmButtonText: '      Ok!     '
                }).then((result) => {
                  if (result.value) { 
                    this.getRoleList(this.dt, this.cb, true);
                  }              
                });
              }
          })

        }
    })
  }
  
}





