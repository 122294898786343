import { AfterViewInit, Component, OnInit } from '@angular/core';
import { DataTableDirective } from 'angular-datatables'
import { TransportService} from '../../../services/api.service';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { DriverShrinkageRecapVm } from '@/model/driverShrinkageRecapVm';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-sps-susut',
  templateUrl: './sps-susut.component.html',
  styleUrls: ['./sps-susut.component.scss']
})

export class SpsSusutComponent  implements OnInit, AfterViewInit {
  driverShrinkageRecapVm: DriverShrinkageRecapVm[] = [];
  datatabdtOptionsnt: DataTableDirective;
  dtOptionsPay: any = {};
  dtTriggerPay = new Subject();  
  tolerance: number;
  public dt: any;
  public cb: any;
  amountDebt: number;
  location: string;
  constructor( public service: TransportService, private spinner: NgxSpinnerService) {
  }

    ngOnInit(): void {
      this.dtOptionsPay = {  
        retrieve: true,
pageLength: 10,
        ordering : false,
        scrollX: true,
        scrollCollapse: true,
        serverSide: true,
        processing: true,
        searching : false,
        ajax: (dataTablesParameters: any, callback) => {
         this.dt = dataTablesParameters; this.cb = callback;
         this.getDataSusut(dataTablesParameters, callback);
        },
        columnDefs: [
          { width: 120, targets: [0,2,3,4,5,6,7,8,9,10,11]},
          { width: 180, targets: [1]}
        ],
      };

    }   

    public expanded = false;

    showCheckboxes() {
     
     var checkboxes = document.getElementById("checkboxes");
     if (!this.expanded) {
       checkboxes.style.display = "block";
       this.expanded = true;
     } else {
       checkboxes.style.display = "none";
       this.expanded = false;
     }
   }
    
    ngAfterViewInit(): void {
      this.dtTriggerPay.next(0);
    }
  
    ngOnDestroy(): void {
     this.dtTriggerPay.unsubscribe();
    }

   getDataSusut(dataTablesParameters, callback){

      this.service.getReportSusut(dataTablesParameters)
      .subscribe(resp => { 
          this.driverShrinkageRecapVm = resp.data;
          callback({
            recordsTotal: resp.totalCount,
            recordsFiltered: resp.totalCount,
            data:  []
          });
      });
  }
   

  messageWarning(text: string){
    Swal.fire({
      title: ""+text,
      type: 'warning',
      confirmButtonText: '      Ok!     '
    });
    return;
  }

  printSpsSusut(){
    this.spinner.show();
    this.location = localStorage.getItem('locationname');
    let currentDate = new Date().toLocaleDateString();;
    let name = "ReportSusut_"+currentDate+".xlsx";
    this.service.printSpsSusut(this.location).subscribe(resp =>{
      this.spinner.hide();
      const blob = new Blob([resp.body],
        { type: 'application/vnd.ms-excel' });
    const file = new File([blob], name,
        { type: 'application/vnd.ms-excel' });

      saveAs(file);
    })
  }

  
}





