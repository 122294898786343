import { AfterViewInit, Component, OnInit, ViewChild,Input  } from '@angular/core';
import { DataTableDirective } from 'angular-datatables'
import { TransportService} from '../../..//services/api.service';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { Guid } from 'guid-typescript'
import { Ton } from '@/model/tonnase';
import { MasterCode } from '@/model/masterCode';
import { AppService } from '@services/app.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-master-tonnase-capacity',
  templateUrl: './master-tonnase-capacity.component.html',
  styleUrls: ['./master-tonnase-capacity.component.scss']
})
export class MasterTonnaseCapacityComponent implements OnInit, AfterViewInit {
 @ViewChild(DataTableDirective, {static: false})
  datatableElement: DataTableDirective;
  dtOptions: any = {};
  public tonnase: Ton;
  dataTonnase: Ton[] = [];
  printDataTonnase: Ton[] = [];
  dataMasterCode: MasterCode[] = [];
  dtTrigger = new Subject();
  @Input() tonnaseModel = { id : null, code: '', name: '',quantity: 0, uom: null};
  action: string;
  public dt: any;
  public cb: any;

  constructor( public service: TransportService, private router: Router,  private spinner: NgxSpinnerService, private appServ : AppService) {
  }

    ngOnInit(): void {
      const menuUser = localStorage.getItem("menu");
      if (menuUser != "All"){
        const subMenu = localStorage.getItem("acces_menu");
        let arrayMenu =  subMenu.split(",");
        if (arrayMenu.includes("tonnasecapacity") == false){
          this.router.navigate(["/home"]);
        }
      }
      this.dtOptions = {
        responsive:true,
        serverSide: true,
        processing: true,
        searching : false,
        ordering : false,
        ajax: (dataTablesParameters: any, callback) => {
         this.dt = dataTablesParameters; this.cb = callback;
         this.getDataTonnase(dataTablesParameters, callback, $('#codesearch').val().toString(), $('#namesearch').val().toString(),
         $('#quantitysearch').val().toString(), $('#uomsearch').val().toString(),true);
        },
        columnDefs: [
          { width: 125, targets: [0,4,5,6,7,8] },
          { width: 160, targets: [1,2,3] }
        ],
      };

      const that = this;
      $('.searchInput').on("keyup", function(e) {
        var key = e.which;
        if (key == 13) // the enter key ascii code
        {
          that.getDataTonnase(that.dt, that.cb, $('#codesearch').val().toString(), $('#namesearch').val().toString(),
           $('#quantitysearch').val().toString(), $('#uomsearch').val().toString(),false);
        }
      });  
      this.getDataMasterCode();
      
    }


    getDataTonnase(dataTablesParameters, callback, code: string, name: string, quantity: string, uoms: string, padging : boolean){ 
      var page = 1;
      if (padging == true){
        page = parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length) + 1;
      }
      this.service.getDataTonnase(dataTablesParameters, page, dataTablesParameters.length, code, name, quantity, uoms)
      .subscribe(resp => { 
          this.dataTonnase = resp.data;
          callback({
            recordsTotal: resp.totalCount,
            recordsFiltered: resp.totalCount,
             data:  []
          });
      });
    }

    getDataMasterCode(){
      this.service.getListMasterCode("uom").subscribe(data => {
        this.dataMasterCode = data;
      })
    }

    

    ngAfterViewInit(): void {
      this.dtTrigger.next(0);
    }
  
    ngOnDestroy(): void {
      this.dtTrigger.unsubscribe();
    }
  
    rerender(): void {
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtTrigger.next(0);
      });
    }

    onSelectTonnase(tonnase: Ton) {
      this.action = "Update";
      this.tonnaseModel.id = tonnase.id;
      this.tonnaseModel.code = tonnase.code;
      this.tonnaseModel.name= tonnase.name;
      this.tonnaseModel.quantity= tonnase.quantity;
      this.tonnaseModel.uom=  Guid.parse(tonnase.uoms);
    }
  
  saveDataTonnase(){

    Swal.fire({
      title: 'Simpan Data?',
      type: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '      Yes     '
    }).then((result) => {
      if (result.value) {
        this.spinner.show();
        this.service.addTonnase(this.tonnaseModel).subscribe(resp => {
          this.spinner.hide();
          if(resp.succes == false){
            Swal.fire({
              title: ""+resp.text,
              type: 'warning',
              confirmButtonText: '      Ok!     '
            });
          }
          else{
            Swal.fire({
              title: ""+resp.text,
              type: 'success',
              confirmButtonText: '      Ok!     '
            }).then((result) => {
              if (result.value) { 
                this.getDataTonnase(this.dt, this.cb, "", "","","",true);
              }              
            });
          }
        });
      }
    })
    }

  deleteTonnase(tonnase: Ton){
    Swal.fire({
      title: 'Delete Data?',
      // text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '      Yes     '
    }).then((result) => {
      if (result.value) {
        this.spinner.show();
        this.service.DeleteTonnase(tonnase.id).subscribe(tonnase => { 
          this.spinner.hide();
          Swal.fire({
            type: 'success',
            confirmButtonText: '      Succes!     '
          }).then((result) => {
            if (result.value) { 
              this.getDataTonnase(this.dt, this.cb, "", "","","",true);
            }
          
          }, (error) => {
            //; 
          })
        });
      }
    
    }, (error) => {
      //; 
    });
  }

  
  clearFormTonnase(){
    this.action = "Input";
    this.tonnaseModel.id = null;
    this.tonnaseModel.code = null;
    this.tonnaseModel.name = null;
    this.tonnaseModel.quantity = null;
    this.tonnaseModel.uom = null;
  }
    
  exportAsXLSX():void {
    this.service.getDataTonnase(this.dt, 1, 1000000, $('#codesearch').val().toString(), $('#namesearch').val().toString(),
    $('#quantitysearch').val().toString(), $('#uomsearch').val().toString())
      .subscribe(resp => { 
          this.printDataTonnase = resp.data;
          this.appServ.exportAsExcelFile(this.printDataTonnase, 'Master_Kapasitas');
      });
  }
      
}


