import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { TransportService} from '../../../services/api.service';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { saveAs } from 'file-saver';
import { FormControl, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { FinalResultDto, PrintReportDto, Report, ReportDto } from '@/model/reportDto';
@Component({
  selector: 'app-report-list',
  templateUrl: './report-list.component.html',
  styleUrls: ['./report-list.component.scss']
})
export class ReportListComponent  implements OnInit, AfterViewInit {
  dtOptionsQuery: any = {};
  declare $: any;
  tableShow = false;
  code : string;
  dtTriggerQuery = new Subject();
  public dt: any;
  public cb: any;
  public rows: any;
  layout = false;
  public testHtml = "<th>id</th><th>location</th><th>month</th><th>year</th><th>code</th><th>value</th><th>created_at</th><th>created_by</th><th>updated_at</th><th>updated_by</th><th>deleted</th>"
  dataPrint: PrintReportDto;
  allReport: Report[] = [];
  
  @Input() reportModel = { rpt: null, fields: null, from: null, to: null, pnot: null,
    sort1: null, sort2: null, asc: null, showcols: null, subttl: null}
  strJson : string;
  strHead : string;
  strFoot : string;
  finalResult: FinalResultDto;
  dataReport: ReportDto[]=[];
  dtOptionsListCustom: any = {};
  dtTriggerListCustom = new Subject();
  columnTest = "[[id],[location],[month],[year],[code],[value],[created_at],[created_by],[updated_at],[updated_by],[deleted]]"
  @Input() queryModel = { id: null, query: null, mode: null}

  constructor( public service: TransportService, private spinner: NgxSpinnerService) {
  } 
  
  selectedItems: any = null;
  form: FormGroup = new FormGroup({
    items: new FormControl(null)
  });
    ngOnInit(): void {

    this.getAllReport();
    
    const that = this;
  
  $("#btnShow").on("click", function(e) { 
    that.spinner.show();
    var from = "";
    var to = "";
    var sort1 = $("[name=sort1]").val();
    var sort2 = $("[name=sort2]").val();

    var arrFields = that.reportModel.fields.split(',');
    for (var i = 0; i < arrFields.length; i++) {
        from += ($('[name="fr_' + arrFields[i] + '"]').val() || "") + ",";
        to += ($('[name="to_' + arrFields[i] + '"]').val() || "") + ",";
    }

    var pnot = "";
    var showcols = "";
    for (i = 0; i < arrFields.length;i++)
    {
        pnot += "0,";
        showcols += "1,";
    }
    that.reportModel.from  = from;
    that.reportModel.to  = to;
    that.reportModel.pnot  = pnot;
    that.reportModel.asc  = "";
    that.reportModel.showcols  = showcols;
    that.reportModel.subttl  = "0";
    that.reportModel.sort1  = sort1;
    that.reportModel.sort2  = sort2;

    // that.reportModel.rpt  = "Report_Config";
    // that.reportModel.fields  = "id,location,month,year,code,value,created_at,created_by,updated_at,updated_by,deleted";
    // that.reportModel.from  = ",,,,,,,,,,";
    // that.reportModel.to  = ",,,,,,,,,,";
    // that.reportModel.pnot  = "0,0,0,0,0,0,0,0,0,0,0";
    // that.reportModel.sort1  = "";
    // that.reportModel.sort2  = "";
    // that.reportModel.asc  = "";
    // that.reportModel.showcols  = "1,1,1,1,1,1,1,1,1,1,1";
    // that.reportModel.subttl  = "0";
    that.service.showReport(that.reportModel).subscribe( resp => {
      that.dataPrint = resp.report;
      that.strJson = resp.report.strJSON;
      that.strHead = resp.report.strHead;
      that.strFoot = resp.report.strFoot;
    $('#jqueryDatatable').append(that.strHead);
    $('#jqueryDatatable').DataTable({
      retrieve: true,
      // columns: columns,
      data: JSON.parse(that.strJson.toString()),
      orderCellsTop : false,
      paging: true,
      pageLength: 10,
     ordering : false,
      deferRender: true,
      scrollX: true,
      dom: 'Bfrtip',
  })
      .columns.adjust()
      $('#jqueryDatatable').append(that.strFoot);
     
  })
  that.spinner.hide();
});

 
    
    
    }   

    ngAfterViewInit(): void {
      // this.dtTriggerListCustom.next(0);
       
    }
  
    ngOnDestroy(): void {
    }

    getAllReport(){
      this.service.getAllReport().subscribe( resp => {
        this.allReport = resp;
      })
    }

    selectCode(data: Report){
      this.layout = true;
      var code = $("#Code").val();
            var layout = $("#reportLayout");
            var sort = $("#sort1");
            var sort2 = $("#sort2");
            var sort3 = $("#sort3");
            layout.empty();
            sort.empty();
            sort2.empty();
            sort3.empty();
        this.service.getReportCode(data.id).subscribe(result => {
        const that = this;
        this.reportModel.rpt  = result.code;
        this.reportModel.fields  = result.fields;

        var fields = result.fields.split(',');
        var headers = result.headers.split(',');
        var formats = result.formats.split(',');
        var filters = result.filters.split(',');
        var primarySort = result.primarySort;
        var secondarySort = result.secondarySort;
        sort.empty();
        sort2.empty();
        sort3.empty();

        sort2.append($("<option></option>"));
        sort3.append($("<option></option>"));

        for (var i = 0; i < fields.length; i++) {
            if (filters[i] == "1") {
                var blueprint = $(".blueprint").clone();
                blueprint.removeClass("blueprint");
                $("label[name=label]", blueprint).html(headers[i]);
                var from = $("input[name=from]", blueprint).attr("name", "fr_" + fields[i]);
                var to = $("input[name=to]", blueprint).attr("name", "to_" + fields[i]);
                if (formats[i]) {
                    if (formats[i].indexOf("dd/MM/yyyy") !== -1 || formats[i].indexOf("yyyy-MM-dd") !== -1) {
                        from.addClass("form-control");
                        to.addClass("form-control");

                        $(from).attr("type", "date");
                        $(to).attr("type", "date");
                    }
                }
                blueprint.appendTo(layout).show();;
                sort.append($("<option></option>")
                    .attr("value", fields[i]).text(headers[i]));
                sort2.append($("<option></option>")
                    .attr("value", fields[i]).text(headers[i]));
                sort3.append($("<option></option>")
                    .attr("value", fields[i]).text(headers[i]));
            }
        }

        if (primarySort) sort.val(primarySort).change();
        else sort.prop('selectedIndex', 0);

        if (secondarySort) sort2.val(secondarySort).change();
        else sort2.prop('selectedIndex', 0);

        $("#reportFilterLayoutDiv").css("display", "block");
        $("#Fields").val(fields);
        $("#Headers").val(headers);
        $("#Formats").val(formats);


      //   this.service.showReport(this.reportModel).subscribe( resp => {
      //     this.dataPrint = resp.report;
      //     this.strJson = resp.report.strJSON;
      //     this.strHead = resp.report.strHead;
      //     var columnsArr = []; 
      //     var columns = []; 
      //     columnsArr = this.strHead.split(",");
      //     columnsArr.forEach( i =>{
      //       columns.push({
      //         title: i,
      //     });
      //     })
      // })
    });
  }

  resetTable(){
    $('#jqueryDatatable').DataTable().destroy();
    $('#jqueryDatatable').empty();
  }



}




