<section class="content">    
    <div class="card">
        <div class="card-header">
          <strong>Invoice</strong>
          <div class="float-right">
            <a type="button" class="btn btn-sm btn-warning pull-right" data-toggle="modal" (click)="ModalSearch.show();setNullSearch();">&nbsp;<b>Cari Data</b>&nbsp;&nbsp;<i class="fa fa-search"></i>&nbsp;</a> &nbsp;&nbsp;&nbsp;
            <a type="button" class="btn btn-sm btn-primary pull-right" data-toggle="modal" (click)="ModalPrint.show()">&nbsp;<b>Laporan Rekap</b>&nbsp;&nbsp;<i class="fa fa-print"></i>&nbsp;</a> &nbsp;&nbsp;&nbsp;
            <a [hidden]="!btnCreate" type="button" class="btn btn-sm btn-primary pull-right" data-toggle="modal" (click)="clearGenerate();ModalTrip.show()">&nbsp;&nbsp;<b>Tambah Invoice</b>&nbsp;&nbsp;&nbsp;<i class="fa fa-pen"></i>&nbsp;&nbsp;&nbsp;</a>
          </div>
        </div>
        <div class="card-body">
            <table datatable [dtOptions]="dtOptionsInvoice" [dtTrigger]="dtTriggerInvoice" class="table-striped">
                <thead>
                  <tr>
                    <th>Action</th>
                    <th>Nama Customer</th>
                    <th>Status</th>
                    <th>Tipe Invoice</th>
                    <th>No Invoice</th>
                    <th>Tanggal Invoice</th>
                    <th>Tanggal Jatuh Tempo</th>
                    <th>Tanggal Cetak</th>
                    <th>Tanggal Kirim</th>
                    <th>Tanggal Terima</th>
                    <th>Jumlah Perhitungan</th>
                    <th>Nama Bank</th>
                    <th>Rekening Bank</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of dataListTripRecord">    
                    <td>
                      <button (click)="onSelectDetail(row)" title="Detail" class="btn btn-sm btn-primary" triggers="mouseenter:mouseleave"><i class="fa fa-list"></i></button>&nbsp;
                      <button [hidden]="!btnCancel" (click)="cancelInvoice(row)" class="btn btn-sm btn-danger" title="Batal Invoice" triggers="mouseenter:mouseleave"><i class="fa fa-times"></i></button>&nbsp;
                      <button (click)="onSelectPrint(row);" class="btn btn-sm btn-primary" title="Cetak Invoice" triggers="mouseenter:mouseleave"><i class="fa fa-print"></i></button>&nbsp;
                      <button (click)="ReleasePrint(row);" class="btn btn-sm btn-primary" title="Release Print" triggers="mouseenter:mouseleave"><i class="fa fa-recycle"></i></button>&nbsp;
                      <!-- <button [hidden]="!btnPrint" (click)="onSelectPrint(row);" class="btn btn-sm btn-primary" title="Cetak Invoice" triggers="mouseenter:mouseleave"><i class="fa fa-print"></i></button>&nbsp;
                      <button [hidden]="!btnRelease" (click)="ReleasePrint(row);" class="btn btn-sm btn-primary" title="Release Print" triggers="mouseenter:mouseleave"><i class="fa fa-recycle"></i></button>&nbsp; -->
                    </td>       
                    <td>{{row.relation_name}}</td>
                    <td>{{row.statusInvoice}}</td>
                    <td>{{row.invoiceType}}</td>
                    <td>{{row.invoice_no}}</td>
                    <td>{{row.invoice_date | slice:0:10}}</td>
                    <td>{{row.expired_date | slice:0:10}}</td>
                    <td>{{row.printed_date | slice:0:10}}</td>
                    <td>{{row.send_date | slice:0:10}}</td>
                    <td>{{row.receive_date | slice:0:10}}</td>
                    <td>Rp. {{row.amount | number}}</td>
                    <td>{{row.bank_name}}</td>
                    <td>{{row.bank_account}}</td>
                </tr>
                </tbody>
                <!-- <tfoot>
                    <tr>
                      <th><input id="spk_no_" type="text" class="searchInput" style="width: 150px;" placeholder="No SPK"/></th>
                      <th><input id="relation_name" type="text" class="searchInput" placeholder="Nama Customer"/></th>
                      <th><input id="statusInvoice" type="text" class="searchInput" placeholder="Status"/></th>
                      <th><input id="type_text" type="text" class="searchInput" placeholder="Tipe Invoice"/></th>
                      <th><input id="invoice_no" type="text" class="searchInput" placeholder="No Invoice"/></th>
                      <th><input id="invoice_date" type="text" class="searchInput" placeholder="Tanggal Invoice"/></th>
                      <th><input hidden class="searchInput"/></th>
                      <th><input hidden class="searchInput"/></th>
                      <th><input hidden class="searchInput"/></th>
                      <th><input hidden class="searchInput"/></th>
                      <th><input hidden class="searchInput"/></th>
                      <th><input hidden id="bank_name" type="text" class="searchInput" placeholder="Nama Bank"/></th>
                      <th><input hidden id="bank_account" type="text" class="searchInput" placeholder="Rekening Bank"/></th>
                    </tr>
                  </tfoot> -->
              </table>
        </div>
    </div>
  </section>

  <div bsModal #ModalTrip="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog" style="max-width: 90%;" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <!-- <div class="modal-title"><strong>{{action}} Data SPK</strong></div> -->
          <div class="modal-title"><strong>Input Invoice Baru</strong></div>
          <button type="button" class="close" #modalSaveClose (click)="ModalTrip.hide()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">    
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-4"> 
                        <div class="form-group row">
                            <label class="col-md-3 col-form-label" for="text-input">Nomor Invoice</label>
                            <div class="col-md-8">
                                <input [disabled]="(!AP)" [(ngModel)]="invoiceModel.invoice_no" type="text" class="form-control">
                                <div [hidden]="(!invoiceModel.merge_oa)"><br><input disabled [(ngModel)]="invoiceModel.invoice_no2" type="text" class="form-control"></div>
                            </div>
                        </div> 
                        <div class="form-group row group">
                            <label class="col-md-3 col-form-label" for="text-input">Jenis Invoice</label>
                            <div class="col-md-8">
                              <select [(ngModel)]="invoiceModel.invoice_type" (change)="onSelectType(invoiceModel.invoice_type)" class="form-control">
                                <option value="Hutang(AP)"><b>Hutang(AP)</b></option>
                                <option value="Piutang(AR)" selected><b>Piutang(AR)</b></option>
                              </select>
                            </div>
                        </div>  
                        <div [hidden]="!AP" class="form-group row">
                          <label class="col-md-3 col-form-label" for="text-input">No SPK External</label>
                          <div class="col-md-8">
                              <input [(ngModel)]="invoiceModel.external_spk_no"  type="text" class="form-control">
                          </div>
                        </div> 
                        <div [hidden]="!AP" class="form-group row">
                          <label class="col-md-3 col-form-label" for="text-input">Tanggal Mulai</label>
                          <div class="col-md-8">
                              <input [ngModel]="invoiceModel.external_spk_start_date | date:'yyyy-MM-dd'" (ngModelChange)="invoiceModel.external_spk_start_date = $event" type="date" class="form-control" >
                          </div>
                        </div> 
                        <div class="form-group row">
                          <label class="col-md-3 col-form-label" for="text-input"></label>
                          <div class="col-md-1">
                            <input type="checkbox" [(ngModel)]="invoiceModel.ship"  class="form-control" id="finish_date">
                          </div>
                          <label class="col-md-7 col-form-label" for="text-input">Kapal/Tongkang</label>
                        </div>
                    </div> 
                    <div class="col-md-4"> 
                      <div class="form-group row group">
                        <label class="col-md-3 col-form-label" for="text-input">Nama Bank</label>
                        <div class="col-md-8">
                          <select [(ngModel)]="model" (ngModelChange)="onSelectBank($event)" class="form-control">
                            <option *ngFor="let row of dataBank" [ngValue]='row'><b>{{row.name}}</b></option>
                          </select>
                        </div>
                     </div>  
                    <div class="form-group row">
                      <label class="col-md-3 col-form-label" for="text-input">Rekening Bank</label>
                      <div class="col-md-8">
                          <input disabled [(ngModel)]="invoiceModel.bank_number" type="text" class="form-control">
                      </div>
                    </div> 
                    <div [hidden]="AP" class="form-group row group">
                      <label class="col-md-3 col-form-label" for="text-input">Tipe Pendapatan</label>
                      <div class="col-md-8">
                        <select [(ngModel)]="typeR" (ngModelChange)="onSelectRevenue($event)" class="form-control">
                          <option value=""><b></b></option>
                          <option *ngFor="let row of revenueType" [ngValue]='row'><b>{{row.name}}</b></option>
                        </select>
                      </div>
                   </div>  
                    <div [hidden]="!AP" class="form-group row">
                      <label class="col-md-3 col-form-label" for="text-input">SPK External</label>
                      <div class="col-md-8">
                          <input [ngModel]="invoiceModel.external_spk_date | date:'yyyy-MM-dd'" (ngModelChange)="invoiceModel.external_spk_date = $event" type="date" class="form-control" >
                      </div>
                    </div> 
                    <div [hidden]="!AP" class="form-group row">
                      <label class="col-md-3 col-form-label" for="text-input">Tanggal Selesai</label>
                      <div class="col-md-8">
                          <input [ngModel]="invoiceModel.external_spk_finish_date | date:'yyyy-MM-dd'" (ngModelChange)="invoiceModel.external_spk_finish_date = $event" type="date" class="form-control"  >
                      </div>
                    </div> 
                  </div>
                  <div class="col-md-4"> 
                    <div class="form-group row">
                      <label class="col-md-3 col-form-label" for="text-input">Tanggal Invoice</label>
                      <div class="col-md-8">
                          <!-- <input type="date" class="form-control" [(ngModel)]="invoiceModel.invoice_date" (change)="generateInvoiceNo($event)"> -->
                          <input type="date" class="form-control" [(ngModel)]="invoiceModel.invoice_date" (change)="generateInvoiceNo(invoiceModel.invoice_date)">
                      </div>
                  </div> 
                  <div class="form-group row">
                      <label class="col-md-3 col-form-label" for="text-input">Jatuh Tempo</label>
                      <div class="col-md-8">
                          <input [(ngModel)]="invoiceModel.expired_date" type="date" class="form-control" >
                      </div>
                  </div> 
                  <div [hidden]="AP" class="form-group row">
                    <label class="col-md-3 col-form-label" for="text-input"></label>
                    <div class="col-md-1">
                      <input type="checkbox" [(ngModel)]="invoiceModel.merge_oa" (change)="changeMergeOA()" class="form-control" id="finish_date">
                    </div>
                    <label class="col-md-7 col-form-label" for="text-input">Pisah OA dan Biaya Lain-lain</label>
                  </div>
                </div>
    
                </div>  
              </div>
        </div>
        <div class="card">
          <div class="card-body">
              <div class="row">
                  <div class="col-md-4">
                    <div class="col-md-12">
                      <div class="form-group row">
                        <label class="col-md-3 col-form-label" for="text-input">Nomor SPK</label>
                        <div class="col-md-6">
                           <textarea [(ngModel)]="spkList" rows="3" type="text" class="form-control"></textarea>
                       </div>
                        <div class="col-md-2">
                          <button title="Tambah" (click)="showSPK();ModalSPK.show()" class="btn btn-sm btn-primary" data-toggle="modal"><i class="fa fa-plus"></i></button>
                          <br><button style="margin-top: 2px;" title="Hapus"  (click)="clearSPK()" class="btn btn-sm btn-danger" data-toggle="modal"><i class="fa fa-trash"></i></button>
                        </div>
                      </div>   
                    </div>
                    <div class="col-md-12"> 
                      <div class="form-group row">
                        <label class="col-md-3 col-form-label" for="text-input">Material</label>
                        <div class="col-md-6">
                           <textarea [(ngModel)]="materialList" rows="3" type="text" class="form-control"></textarea>
                       </div>
                        <div class="col-md-2">
                          <button class="btn btn-sm btn-primary" (click)="showMaterial();ModalMaterial.show()" data-toggle="modal"><i class="fa fa-plus"></i></button>
                          <br><button style="margin-top: 2px;" title="Hapus"  (click)="clearMaterial()" class="btn btn-sm btn-danger" data-toggle="modal"><i class="fa fa-trash"></i></button>
                        </div>
                      </div>   
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group row">
                      <label class="col-md-3 col-form-label" for="text-input">Vendor</label>
                      <div class="col-md-7">
                          <input disabled [(ngModel)]="ekspedisiSelect" type="text" class="form-control">
                      </div>
                      <div class="col-md-1">
                        <button [disabled]="!AP" class="btn btn-sm btn-primary" (click)="showEkspedisi();ModalEkspedisi.show()" data-toggle="modal"><i class="fa fa-plus"></i></button>
                      </div>
                    </div> 
                    <div class="form-group row">
                      <label class="col-md-3 col-form-label" for="text-input">Customer</label>
                      <div class="col-md-7">
                          <input disabled [(ngModel)]="customer" type="text" class="form-control">
                      </div>
                      <div class="col-md-1">
                        <button [disabled]="AP" class="btn btn-sm btn-primary" (click)="showCustomer();ModalCustomer.show()" data-toggle="modal"><i class="fa fa-plus"></i></button>
                      </div>
                    </div> 
                    <div class="form-group row">
                      <label class="col-md-3 col-form-label" for="text-input">No Faktur Pajak</label>
                      <div class="col-md-8">
                          <input [(ngModel)]="invoiceModel.factur_no" type="text" class="form-control">
                      </div>
                    </div> 
                  </div>
                  <div class="col-md-4">
                    <div class="form-group row">
                      <label class="col-md-3 col-form-label" for="text-input">Tanggal Realisasi</label>
                      <div class="col-md-4">
                          <input [ngModel]="invoiceModel.realization_from | date:'yyyy-MM-dd'" (ngModelChange)="invoiceModel.realization_from = $event" type="date" class="form-control" id="finish_date">
                      </div>
                      <label class="col-md-1 col-form-label" for="text-input">&nbsp;&nbsp;-</label>
                      <div class="col-md-4">
                        <input [ngModel]="invoiceModel.realization_to | date:'yyyy-MM-dd'" (ngModelChange)="invoiceModel.realization_to = $event" type="date" class="form-control"   id="finish_date">
                      </div>
                    </div> 
                    <div class="form-group row">
                      <label class="col-md-3 col-form-label" for="text-input">Tanggal Selesai</label>
                      <div class="col-md-4">
                          <input [ngModel]="invoiceModel.complete_from | date:'yyyy-MM-dd'" (ngModelChange)="invoiceModel.complete_from = $event" type="date" class="form-control" id="finish_date">
                      </div>
                      <label class="col-md-1 col-form-label" for="text-input">&nbsp;&nbsp;-</label>
                      <div class="col-md-4">
                        <input [ngModel]="invoiceModel.complete_to | date:'yyyy-MM-dd'" (ngModelChange)="invoiceModel.complete_to = $event" type="date" class="form-control"   id="finish_date">
                      </div>
                    </div> 
                    <div class="form-group row">
                      <div class="col-md-1">
                        <input type="checkbox" [checked]="invoiceModel.show_oa" (change)="invoiceModel.show_oa = !invoiceModel.show_oa" class="form-control" id="finish_date">
                      </div>
                      <label class="col-md-3 col-form-label" for="text-input">Tampilkan OA</label>
                      <div class="col-md-1">
                        <input type="checkbox" [checked]="invoiceModel.include_ppn" (change)="invoiceModel.include_ppn = !invoiceModel.include_ppn"  class="form-control" id="finish_date">
                      </div>
                      <label class="col-md-3 col-form-label" for="text-input">Include PPN</label>
                      <div class="col-md-1">
                        <input type="checkbox" [checked]="invoiceModel.include_pph" (change)="invoiceModel.include_pph = !invoiceModel.include_pph" class="form-control" id="finish_date">
                      </div>
                      <label class="col-md-3 col-form-label" for="text-input">Include PPh</label>
                    </div> 
                  </div>
              </div>
              
          </div>
        </div>
        <br>
        <div style="text-align: center">
          <button (click)="getDatagenerate()" type="button" class="btn btn-primary"><i class="fas fa-random"></i>&nbsp;<b>Generate</b>&nbsp;</button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <button (click)="clearGenerate()" type="button" class="btn btn-danger"><i class="fa fa-eraser"></i>&nbsp;<b>Bersih</b>&nbsp;</button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <!-- <button type="button" class="btn btn-warning"><i class="fas fa-info"></i>&nbsp;<b>Informasi</b>&nbsp;</button> -->
       </div>
       <br><br>
       <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-md-2">
              <div class="form-group row group">
                <label class="col-md-3">Sort by</label>
                <div class="col-md-9">
                  <select  [(ngModel)]="filter" (ngModelChange)="sort($event)">
                    <option value="send">Tanggal Kirim</option>
                    <option value="rcv">Tanggal Terima</option>
                    <option value="end">Tanggal Selesai</option>
                    <option value="spk">No SPK</option>
                    <option value="plat">Plat Truk</option>
                    <option value="reff">Trx Reff</option>
                    <option value="biaya">Jumlah Biaya</option>
                    <option value="tambahan">Jumlah Biaya Tambahan</option>
                  </select>
                </div>
              </div>  
            </div>
          </div>
        <div class="scroll">
          <table datatable [dtOptions]="dtOptionsGenerate" [dtTrigger]="dtTriggerGenerate" class="table-striped">
            <thead>
              <tr>
                <th>Select &nbsp;<input (change)="checkAll()" [checked]="true" type="checkbox"/></th>
                <th>No</th>
                <th>No Invoice</th>
                <th>No SPK</th>
                <th>Trx Reff</th>
                <th>No Do Kecil</th>
                <th>Material</th>
                <th>Plat Truk</th>
                <th>Tonase Kirim</th>
                <th>Tonase Terima</th>
                <th>Jumlah Biaya</th>
                <th>Jumlah Biaya Tambahan</th>
                <th>Jenis Perhitungan</th>
                <th>Tanggal Terima</th>
                <th>Tanggal Kirim</th>
                <th>Tanggal Selesai</th>
                <th>Include PPN</th>
                <th>PPN Biaya</th>
                <th>PPN Biaya Tambahan</th>
                <th>No Faktur Pajak</th>
                <th>PPH</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let row of dataListGenereate; let i=index">    
                <td><input type="checkbox" [checked]="row.selected" (change)="checkSelected(row)"/></td>
                <td>{{i+1}}</td>
                <td>{{row.invoice_no}}</td>
                <td>{{row.spk_no}}</td>
                <td>{{row.reff}}</td>
                <td>{{row.do_no}}</td>
                <td>{{row.material}}</td>
                <td>{{row.vehicle_number}}</td>
                <td>{{row.tonnase_send}}</td>
                <td>{{row.tonnase_received}}</td>
                <td>{{row.amount | number}}</td>
                <td>{{row.amount_adjusment | number}}</td>
                <td>{{row.charge_type}}</td>
                <td>{{row.receive_date | slice:0:10}}</td>
                <td>{{row.send_date | slice:0:10}}</td>
                <td>{{row.complete_date | slice:0:10}}</td>
                <td>{{row.ppn_include}}</td>
                <td>{{row.ppn_charge | number}}</td>
                <td>{{row.ppn_adjusment | number}}</td>
                <td>{{row.factur_no}}</td>  
                <td>{{row.pph_include}}</td>     
              </tr>
            </tbody>
          </table>
        </div>
        </div>
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-4"> 
                  <div class="form-group row group">
                      <label class="col-md-4 col-form-label" for="text-input">Data Terpilih</label>
                      <label class="col-md-3 col-form-label" for="text-input">{{select}}/{{totalData}}</label>
                  </div> 
              </div>
              <div class="col-md-4"> 
                <div class="form-group row group">
                  <label class="col-md-4 col-form-label" for="text-input">Total Kirim</label>
                  <label class="col-md-3 col-form-label" for="text-input">{{totalSend | number}}</label>
                </div> 
              </div>
            </div>
            <div class="row">
              <div class="col-md-4"> 
                  <div class="form-group row group">
                    <label class="col-md-4 col-form-label" for="text-input">Total Invoice OA</label>
                    <label class="col-md-3 col-form-label" for="text-input">{{totalInv | number}}</label>
                  </div> 
              </div>
              <div class="col-md-4"> 
                <div class="form-group row group">
                  <label class="col-md-4 col-form-label" for="text-input">Total Terima</label>
                  <label class="col-md-3 col-form-label" for="text-input">{{totalRcv | number}}</label>
                </div> 
              </div>
            </div>
            <div class="row">
              <div class="col-md-4"> 
                  <div class="form-group row group">
                    <label class="col-md-4 col-form-label" for="text-input">Total Invoice Lain-lain</label>
                    <label class="col-md-3 col-form-label" for="text-input">{{totalInvOth | number}}</label>
                  </div> 
              </div>
            </div>
          </div>
        </div>
      </div>
        </div>
        <div class="modal-footer">
            <!-- <button #modalCreateClose type="button" class="btn btn-secondary" (click)="ModalTrip.hide()"><b>Close</b></button>-->
            <button type="button" (click)="createInvoice()" class="btn btn-primary"><i class="fa fa-save"></i>&nbsp;<b>Simpan</b>&nbsp;</button>
            <button type="button" class="btn btn-secondary" (click)="ModalTrip.hide()">&nbsp;<b>Cancel</b>&nbsp;</button> 
        </div>
      </div>
    </div>
  </div>

<!-- 
  <div  bsModal #ModalMaterial="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog  modal-lg" role="document">
      <div class="modal-content">
        
        </div>
      </div>
    </div>
  </div> -->



  <div bsModal #ModalEkspedisi="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog  modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title">
            <div class="row">
              <strong>Pilih Ekspedisi</strong>
            </div>
          </div>
          <button type="button" class="close" (click)="ModalEkspedisi.hide()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <!-- <app-master-relation></app-master-relation> -->
            <table datatable [dtOptions]="dtOptionsEkspedisi" [dtTrigger]="dtTriggerEkspedisi" class="table-striped">
                <thead>
                  <tr>
                    <th>Action</th>
                    <th>Kode</th>
                    <th>Nama</th>
                    <th>Alamat</th>
                    <th>Kode SL</th>
                    <th>Phone</th>
                    <th>Active</th>
                    <th>Dibuat Oleh</th>
                    <th>Dibuat Pada</th>
                    <th>Diubah Oleh</th>
                    <th>Diubah Pada</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of dataEkspedisi">      
                    <td style="width: 200;">
                      <button (click)="onSelectEkspedisi(row);ModalEkspedisi.hide()" title="Edit"  class="btn btn-sm btn-primary" data-toggle="modal" triggers="mouseenter:mouseleave"><i class="fa fa-check"></i></button>
                    </td>  
                    <td>{{row.relation_code}}</td>
                    <td>{{row.relation_name}}</td>
                    <td>{{row.relation_address}}</td>
                    <td>{{row.relation_sl_code}}</td>
                    <td>{{row.relation_phone}}</td>
                    <td>{{row.active}}</td>
                    <td>{{row.createdby}}</td>
                    <td>{{row.created_at | slice:0:10}}</td>  
                    <td>{{row.updatedby}}</td>  
                    <td>{{row.updated_at | slice:0:10}}</td>   
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <th><input hidden class="searchEkspedisi"/></th>
                    <th><input id="codeEks" type="text" class="searchEkspedisi" placeholder="Kode"/></th>
                    <th><input id="nameEks" type="text" class="searchEkspedisi" placeholder="Nama"/></th>
                    <th><input id="addressEks" type="text" class="searchEkspedisi" placeholder="Alamat"/></th>
                    <th><input id="slEks" type="text" class="searchEkspedisi" placeholder="Kode SL"/></th>
                    <th><input hidden class="searchEkspedisi"/></th>
                    <th><input hidden class="searchEkspedisi"/></th>
                    <th><input hidden class="searchEkspedisi"/></th>
                    <th><input hidden class="searchEkspedisi"/></th>
                    <th><input hidden class="searchEkspedisi"/></th>
                    <th><input hidden class="searchEkspedisi"/></th>
                  </tr>
                </tfoot>
      
              </table>
        </div>
      </div>
    </div>
  </div>
  

  
<div  bsModal #ModalMaterial="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog  modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title">
          <div class="row">
            <strong>Pilih Material</strong>
          </div>
        </div>
        <button type="button" class="close" (click)="ModalMaterial.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
          <!-- <app-master-material></app-master-material> -->
          <table datatable [dtOptions]="dtOptionsMaterial" [dtTrigger]="dtTriggerMaterial" class="table-striped">
            <thead>
             <tr>
               <th>Action</th>
               <th>Kode Material</th>
               <th>Nama Material</th>
               <th>Harga</th>
               <th>UOM</th>
               <th>Tipe Cetakan DO</th>
               <th>Dibuat Oleh</th>
               <th>Dibuat Pada</th>
               <th>Diubah Oleh</th>
               <th>Diubah Pada</th>
             </tr>
           </thead> 
           <tbody>
             <tr *ngFor="let row of dataMaterial">
             <td>
              <button title="Pilih Data" (click)="onSelectMaterial(row);ModalMaterial.hide()" class="btn btn-sm btn-primary" triggers="mouseenter:mouseleave"><i class="fa fa-check"></i></button>
             </td>       
               <td>{{row.material_code}}</td>
               <td>{{row.material_name}}</td>
               <td>{{row.material_price}}</td>
               <td>{{row.uom}}</td>
               <td>{{row.do_print_type}}</td>
               <td>{{row.created}}</td>
               <td>{{row.created_at | slice:0:10}}</td>  
               <td>{{row.updated_by}}</td>  
               <td>{{row.updated_at | slice:0:10}}</td>    
             </tr>
           </tbody>
           <tfoot>
             <tr>
               <th><input hidden class="searchMaterial" /></th>
               <th><input id="codeMaterial" type="text" class="searchMaterial" placeholder="Search Kode"/></th>
               <th><input id="nameMaterial" type="text" class="searchMaterial" placeholder="Search Material"/></th>
               <th><input hidden type="text" class="searchMaterial"/></th>
               <th><input hidden type="text" class="searchMaterial"/></th>
               <th><input hidden type="text" class="searchMaterial"/></th>
               <th><input hidden type="text" class="searchMaterial"/></th>
               <th><input hidden type="text" class="searchMaterial"/></th>
               <th><input hidden type="text" class="searchMaterial"/></th>
               <th><input hidden type="text" class="searchMaterial"/></th>
             </tr>
           </tfoot>
     
         </table>
      </div>
    </div>
  </div>
</div>


  <div  bsModal #ModalSPK="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog" style="max-width: 70%;" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title">
            <div class="row">
              <strong>Pilih SPK</strong>
            </div>
          </div>
          <button #hideSPK type="button" class="close" (click)="ModalSPK.hide()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <table datatable  [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"  class="table-striped">
            <thead>
              <tr>
                <th>Action</th>
                <th>No DO</th>
                <th>Type DO Kecil</th>
                <th>No SPO</th>
                <th>Tanggal SPK</th>
                <th>No SPK</th>
                <th>No Kontrak</th>
                <th>No PO</th>
                <th>Tanggal Kontrak</th>
                <th>Nama Pelanggan</th>
                <th>Tanggal Mulai</th>
                <th>Tanggal Selesai</th>
                <th>Nama Rute</th>
                <th>Dari</th>
                <th>Ke</th>
                <th>Nama Penjual</th>
                <th>Nama Material</th>
                <th>Kuantitas</th>
                <th>UOM</th>
                <th>Biaya</th>
                <th>Tipe Perhitungan</th>
                <th>Tipe Perjalanan</th>
                <th>FFA</th>
                <th>MI</th>
                <th>KK</th>
                <th>Toleransi</th>
                <th>Status</th>
                <th>Remark</th>
                <th>Dibuat Oleh</th>
                <th>Dibuat Pada</th>
                <th>Diubah Oleh</th>
                <th>Diubah Pada</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let row of dataListSPK">    
                <td>
                  <button (click)="onSelectSPK(row);" title="Edit" class="btn btn-sm btn-primary" triggers="mouseenter:mouseleave"><i class="fa fa-pen"></i></button>&nbsp;
                </td>       
                <td>{{row.main_do_no}}</td>
                <td>{{row.main_do_type_text}}</td>
                <td>{{row.spo_no}}</td>
                <td>{{row.spk_date | slice:0:10}}</td>
                <td>{{row.spk_no}}</td>
                <td>{{row.contract_no}}</td>
                <td>{{row.po_no}}</td>
                <td>{{row.contract_date | slice:0:10}}</td>
                <td>{{row.customer_name}}</td>
                <td>{{row.start_date | slice:0:10}}</td>
                <td>{{row.finish_date | slice:0:10}}</td>
                <td>{{row.route_name}}</td>
                <td>{{row.route_from}}</td>
                <td>{{row.route_to}}</td>
                <td>{{row.seller_name}}</td>
                <td>{{row.material_name}}</td>
                <td>{{row.quantity}}</td>
                <td>{{row.uom_name}}</td>
                <td>{{row.cost}}</td>
                <td>{{row.charge_type}}</td>
                <td>{{row.trip_type_name}}</td>
                <td>{{row.ffa}}</td>
                <td>{{row.mi}}</td>
                <td>{{row.kk}}</td>
                <td>{{row.tolerance}}</td>
                <td><input type="checkbox" [checked]="row.complete"/></td>
                <td>{{row.remark}}</td>
                <td>{{row.created_by_text}}</td>
                <td>{{row.created_at_text | slice:0:10}}</td>
                <td>{{row.updated_by_text}}</td>
                <td>{{row.updated_at_text | slice:0:10}}</td>
            </tr>
            </tbody>
            <tfoot>
                <tr>
                  <th><input hidden class="searchInput2"/></th>
                  <th><input id="main_do_no" type="text" class="searchInput2" placeholder="No DO"/></th>
                  <th><input id="main_do_type" placeholder="Type DO Kecil" class="searchInput2"/></th>
                  <th><input id="spo_no" placeholder="No SPO" class="searchInput2"/></th>
                  <th><input id="spk_date" placeholder="Tanggal SPK" class="searchInput2"/></th>
                  <th><input id="spk_no" placeholder="No SPK" class="searchInput2"/></th>
                  <th><input id="contract_no" placeholder="No Kontrak" class="searchInput2"/></th>
                  <th><input id="po_no" placeholder="No PO" class="searchInput2"/></th>
                  <th><input id="contract_date" placeholder="Tanggal Kontrak" class="searchInput2"/></th>
                  <th><input id="customer_name" placeholder="Nama Pelanggan" class="searchInput2"/></th>
                  <th><input id="start_date" placeholder="Tanggal Mulai" class="searchInput2"/></th>
                  <th><input id="finish_date" placeholder="Tanggal Selesai" class="searchInput2"/></th>
                  <th><input id="route_name" placeholder="Nama Rute" class="searchInput2"/></th>
                  <th><input id="route_from" placeholder="Dari" class="searchInput2"/></th>
                  <th><input id="route_to" placeholder="Ke" class="searchInput2"/></th>
                  <th><input id="seller_name" placeholder="Nama Penjual" class="searchInput2"/></th>
                  <th><input id="material_name" placeholder="Nama Material" class="searchInput2"/></th>
                  <th><input id="quantity" placeholder="Kuantitas" class="searchInput2"/></th>
                  <th><input id="uom_name" placeholder="Uom" class="searchInput2"/></th>
                  <th><input id="cost" placeholder="Biaya" class="searchInput2"/></th>
                  <th><input id="charge_type" placeholder="Tipe Perhitungan" class="searchInput2"/></th>
                  <th><input id="trip_type_name" placeholder="Tipe Perjalanan" class="searchInput2"/></th>
                  <th><input id="ffa" placeholder="FFA" class="searchInput2"/></th>
                  <th><input id="mi" placeholder="MI" class="searchInput2"/></th>
                  <th><input id="kk" placeholder="KK" class="searchInput2"/></th>
                  <th><input id="tolerance" placeholder="Toleransi" class="searchInput2"/></th>
                  <th><input hidden class="searchInput2"/></th>
                  <th><input id="remark" placeholder="Remark" class="searchInput2"/></th>
                  <th><input hidden class="searchInput2"/></th>
                  <th><input hidden class="searchInput2"/></th>
                  <th><input hidden class="searchInput2"/></th>
                  <th><input hidden class="searchInput2"/></th>
                </tr>
              </tfoot>
          </table>
        </div>
      </div>
    </div>
  </div>


  <div bsModal #ModalDetail="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog" style="max-width: 70%;" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title">
          </div>
          <button #modalDetailClose type="button" class="close" (click)="ModalDetail.hide()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-6"> 
                    <div class="form-group row">
                        <label class="col-md-3 col-form-label" for="text-input">Nomor Invoice</label>
                        <div class="col-md-8">
                            <input disabled value="{{invoiceModel.invoice_no}}" type="text" class="form-control">
                        </div>
                    </div> 
                    <div class="form-group row">
                      <label class="col-md-3 col-form-label" for="text-input">Nama Customer</label>
                      <div class="col-md-8">
                          <input disabled value="{{invoiceModel.relation_name}}" type="text" class="form-control">
                      </div>
                    </div> 
                    <div class="form-group row">
                      <label class="col-md-3 col-form-label" for="text-input">Status Invoice</label>
                      <div class="col-md-8">
                          <input disabled value="{{invoiceModel.statusInvoice}}" type="text" class="form-control">
                      </div>
                    </div> 
                </div> 
                <div class="col-md-6"> 
                  <div class="form-group row">
                      <label class="col-md-3 col-form-label" for="text-input">Tanggal Invoice</label>
                      <div class="col-md-8">
                          <input disabled value="{{invoiceModel.invoice_date | slice:0:10}}" type="text" class="form-control">
                      </div>
                  </div> 
                  <div class="form-group row">
                    <label class="col-md-3 col-form-label" for="text-input">Jatuh Tempo</label>
                    <div class="col-md-8">
                        <input disabled value="{{invoiceModel.expired_date | slice:0:10}}" type="text" class="form-control">
                    </div>
                  </div> 
                  <div class="form-group row">
                    <label class="col-md-3 col-form-label" for="text-input">Total</label>
                    <div class="col-md-8">
                        <input disabled value="Rp. {{total | number}}" type="text" class="form-control">
                    </div>
                  </div> 
                </div> 
              </div>
            </div>
          </div>
          <br>
          <div class="card">
            <div class="card-body">
            <div class="card-header">
              <div class="float-right">
                <a type="button" class="btn btn-sm btn-primary pull-right" data-toggle="modal" [hidden]="!finishShow" (click)="ModalFinish.show()">&nbsp;&nbsp;<b>Completed Doc</b>&nbsp;&nbsp;&nbsp;<i class="fa fa-flag-checkered"></i>&nbsp;&nbsp;&nbsp;</a>
              </div>
            </div>
            <div class="scroll">
              <table datatable [dtOptions]="dtOptionsDetailList" [dtTrigger]="dtTriggerDetailList" class="table-striped">
                <thead>
                  <tr>
                    <th>No SPK</th>
                    <th>No Do Kecil</th>
                    <th>No Truk</th>
                    <th>Tonase Kirim</th>
                    <th>Tonase Terima</th>
                    <th>Jumlah Biaya</th>
                    <th>Jumlah Biaya Tambahan</th>
                    <th>Keterangan</th>
                    <th>Tanggal Terima</th>
                    <th>Tanggal Kirim</th>
                    <th>Include PPN</th>
                    <th>PPN Biaya</th>
                    <th>PPN Biaya Tambahan</th>
                    <th>No Faktur Pajak</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of detailListInvoice">    
                    <td>{{row.spk_no}}</td>
                    <td>{{row.do_no}}</td>
                    <td>{{row.truck_plate_number}}</td>
                    <td>{{row.tonnage_send}}</td>
                    <td>{{row.tonnage_received}}</td>
                    <td>{{row.amount}}</td>
                    <td>{{row.additional_amount}}</td>
                    <td>{{row.remark}}</td>
                    <td>{{row.receive_date | slice:0:10}}</td>
                    <td>{{row.send_date | slice:0:10}}</td>
                    <td>{{row.tax_included ? 'Ya' : 'Tidak'}}</td>
                    <td>{{row.tax_amount}}</td>
                    <td>{{row.tax_additional_amount}}</td>
                    <td>{{row.tax_no}}</td>      
                  </tr>
                </tbody>
              </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <div bsModal #ModalPrint="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog" style="max-width: 50%;"  role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title">
          </div>
          <button type="button" class="close" (click)="ModalPrint.hide()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-12"> 
                    <div class="form-group row">
                        <label class="col-md-3 col-form-label" for="text-input">Dari Tanggal</label>
                        <div class="col-md-8">
                            <input type="date" [ngModel]="printDateFrom | date:'yyyy-MM-dd'" (ngModelChange)="printDateFrom = $event"  class="form-control">
                        </div>
                    </div> 
                    <div class="form-group row">
                      <label class="col-md-3 col-form-label" for="text-input">Ke Tanggal</label>
                      <div class="col-md-8">
                          <input  type="date" [ngModel]="printDateTo | date:'yyyy-MM-dd'" (ngModelChange)="printDateTo = $event"  class="form-control">
                      </div>
                    </div> 
                </div> 
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" (click)="printOut()" class="btn btn-primary"><i class="fa fa-print"></i>&nbsp;<b>Print</b>&nbsp;</button>&nbsp;&nbsp;   
          <button type="button" class="btn btn-primary" (click)="printOutStandExcel()"><i class="fa fa-file-excel"></i>&nbsp;<b>Export Excel</b>&nbsp;</button>&nbsp;&nbsp; 
          <button type="button" #modalPrint class="btn btn-secondary" (click)="ModalPrint.hide()">&nbsp;<b>Cancel</b>&nbsp;</button> 
      </div>
      </div>
    </div>
  </div>

  <div bsModal #ModalSignature="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog" style="max-width: 50%" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title">
          </div>
          <button type="button" class="close" (click)="ModalSignature.hide()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-12"> 
                    <div class="form-group row">
                        <label class="col-md-4 col-form-label" for="text-input">Nama Berwenang</label>
                        <div class="col-md-8">
                          <select  [(ngModel)]="signature1" class="form-control">
                            <option *ngFor="let row of dataSignature" [value]='row.id'><b>{{row.name}}</b></option>
                         </select>   
                        </div>
                    </div> 
                    <div class="form-group row">
                      <label class="col-md-4 col-form-label" for="text-input">Diketahui Oleh</label>
                      <div class="col-md-8">
                        <select  [(ngModel)]="signature2" class="form-control">
                          <option *ngFor="let row of dataSignature" [value]='row.id'><b>{{row.name}}</b></option>
                       </select>   
                      </div>
                  </div> 
                  <div class="form-group row">
                    <label class="col-md-4 col-form-label" for="text-input"></label>
                    <div class="col-md-1">
                      <input type="checkbox" [(ngModel)]="printInvoice.pajak" class="form-control">
                    </div>
                    <label class="col-md-7 col-form-label" for="text-input">Bebas Pajak</label>
                </div>
                </div> 
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button (click)="Print()" type="button" class="btn btn-primary"><i class="fas fa-print"></i>&nbsp;&nbsp;&nbsp;<b>Cetak</b>&nbsp;&nbsp;&nbsp;</button> 
      </div>
      </div>
    </div>
  </div>


  <div bsModal #ModalSearch="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog" style="max-width: 75%;" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title">
           <b>Pencarian</b>
          </div>
          <button type="button" class="close" (click)="ModalSearch.hide()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-6"> 
                  <div class="form-group row">
                    <label class="col-md-3 col-form-label" for="text-input">No Invoice</label>
                    <div class="col-md-8">
                        <input type="text" [(ngModel)]="searchModel.invoiceNo"  class="form-control">
                    </div>
                  </div> 
                  <div class="form-group row">
                    <label class="col-md-3 col-form-label" for="text-input">Tanggal Invoice</label>
                    <div class="col-md-8">
                        <input type="date" [(ngModel)]="searchModel.invoiceDate"  class="form-control">
                    </div>
                  </div> 
                  <div class="form-group row">
                    <label class="col-md-3 col-form-label" for="text-input">Status</label>
                    <div class="col-md-8">
                      <select  class="form-control" [(ngModel)]="searchModel.statusId">
                        <option selected><b></b></option>
                        <option *ngFor="let row of listInvoiceStatus" [value]='row.id'><b>{{row.text}}</b></option>
                      </select>
                    </div>
                  </div> 
                </div>
                <div class="col-md-6"> 
                  <div class="form-group row">
                    <label class="col-md-3 col-form-label" for="text-input">Customer</label>
                    <input hidden disabled type="text" class="form-control">
                    <div class="col-md-7">
                      <!-- <input hidden [(ngModel)]="customer" disabled type="text" class="form-control"> -->
                      <input [(ngModel)]="customer" [ngModelOptions]="{standalone: true}" disabled type="text" class="form-control">
                    </div>
                    <div class="col-md-2">
                        <button class="btn btn-sm btn-primary" (click)="custFinds();showCustomer();ModalCustomer.show()"  
                        data-toggle="modal" ><i class="fa fa-plus"></i></button>
                    </div>
                  </div> 
                  <div class="form-group row">
                    <label class="col-md-3 col-form-label" for="text-input">No SPK</label>
                    <div class="col-md-8">
                        <input type="text" [(ngModel)]="searchModel.spkNo"  class="form-control">
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-md-3 col-form-label" for="text-input">Tipe</label>
                    <div class="col-md-8">
                      <select [(ngModel)]="searchModel.typeId" class="form-control">
                        <option selected><b></b></option>
                        <option *ngFor="let row of listInvoiceType" [value]='row.id'><b>{{row.text}}</b></option>
                      </select>
                    </div>
                  </div> 
                  <div class="form-group row">
                    <div class="col-sm-3"></div>
                    <div class="col-sm-1">
                      <input [(ngModel)]="searchModel.cancel" style="width: 50%;" type="checkbox" class="form-control">
                    </div>
                    <label class="col-sm-3 col-form-label" for="text-input">Include DO Selesai</label>
                    <div class="col-sm-4">
                    </div>
                 </div>
                </div>
              </div>
            </div>
          </div>
  
        </div>
        <div class="modal-footer">
          <button type="button" (click)="searchData();ModalSearch.hide()" class="btn btn-primary"><i class="fa fa-search"></i>&nbsp;<b>Cari</b>&nbsp;</button>
          <button type="button" class="btn btn-secondary" (click)="ModalSearch.hide()">&nbsp;<b>Close</b>&nbsp;</button> 
      </div>
      </div>
    </div>
  </div>

  
  <div  bsModal #ModalCustomer="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog  modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title">
            <div class="row">
              <strong>Pilih Customer</strong>
            </div>
          </div>
          <button #modalCustomerHide type="button" class="close" (click)="ModalCustomer.hide()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <table datatable [dtOptions]="dtOptionsCustomer" [dtTrigger]="dtTriggerCustomer" class="table-striped">
                <thead>
                  <tr>
                    <th>Action</th>
                    <th>Kode</th>
                    <th>Nama</th>
                    <th>Alamat</th>
                    <th>Kode SL</th>
                    <th>Phone</th>
                    <th>Active</th>
                    <th>Dibuat Oleh</th>
                    <th>Dibuat Pada</th>
                    <th>Diubah Oleh</th>
                    <th>Diubah Pada</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of dataRelation">      
                    <td style="width: 200;">
                      <button (click)="onSelectCustomer(row);" title="Edit"  class="btn btn-sm btn-primary" data-toggle="modal" triggers="mouseenter:mouseleave"><i class="fa fa-check"></i></button>
                    </td>  
                    <td>{{row.relation_code}}</td>
                    <td>{{row.relation_name}}</td>
                    <td>{{row.relation_address}}</td>
                    <td>{{row.relation_sl_code}}</td>
                    <td>{{row.relation_phone}}</td>
                    <td>{{row.active}}</td>cl
                    <td>{{row.createdby}}</td>
                    <td>{{row.created_at | slice:0:10}}</td>  
                    <td>{{row.updatedby}}</td>  
                    <td>{{row.updated_at | slice:0:10}}</td>   
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <th><input hidden class="searchCustomer"/></th>
                    <th><input id="codecustomer" type="text" class="searchCustomer" placeholder="Kode"/></th>
                    <th><input id="namecustomer" type="text" class="searchCustomer" placeholder="Nama"/></th>
                    <th><input id="addresscustomer" type="text" class="searchCustomer" placeholder="Alamat"/></th>
                    <th><input id="slcustomer" type="text" class="searchCustomer" placeholder="Kode SL"/></th>
                    <th><input hidden class="searchCustomer"/></th>
                    <th><input hidden class="searchCustomer"/></th>
                    <th><input hidden class="searchCustomer"/></th>
                    <th><input hidden class="searchCustomer"/></th>
                    <th><input hidden class="searchCustomer"/></th>
                    <th><input hidden class="searchCustomer"/></th>
                  </tr>
                </tfoot>
      
              </table>
        </div>
      </div>
    </div>
  </div>

  <div  bsModal #ModalFinish="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog" style="max-width: 40%;" role="document">
      <div class="modal-content">
        <div class="modal-body">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-12"> 
                    <div class="form-group row">
                        <label class="col-md-3 col-form-label" for="text-input">Tanggal Deliver</label>
                        <div class="col-md-8">
                            <input [(ngModel)]="finishModel.delivery" type="date" class="form-control">
                        </div>
                    </div> 
                </div> 
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button #modalFinishClose type="button" class="btn btn-secondary" (click)="ModalFinish.hide()"><b>Close</b></button>
          <button type="button" class="btn btn-primary" (click)="finishTrip()"><b>Selesai</b></button>
        </div>
      </div>
    </div>
  </div>
  

<button #modalDetail hidden (click)="ModalDetail.show()"></button>
<button #ModalSignatureShow hidden (click)="ModalSignature.show()"></button>

<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>
