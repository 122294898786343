import { ListSPK } from '@/model/listSPK';
import { AfterViewInit, Component, OnInit,Input } from '@angular/core';
import { TransportService } from '@services/api.service';
import { Subject } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { ListDoDetail, ListDoDetailApp } from '@/model/listDoDetail';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-detail-approvals-do',
  templateUrl: './detail-approvals-do.component.html',
  styleUrls: ['./detail-approvals-do.component.scss']
})
export class DetailApprovalsDoComponent implements OnInit, AfterViewInit {
  public listDoDetail: ListDoDetailApp[] = [];
  public spk: ListSPK;
  customerName: string; maindo_no: string; spk_no: string; contract_no:string;
  dtOptionsDetailDo: any = {};
  dtTriggerDetail = new Subject();
  checkAllDO : Boolean = false;
  all: Boolean = false;
  selectAll: Boolean = false;
  mainDoId: string;
  @Input() inputApprove = {  list: new Array()}
  constructor(public service: TransportService,  private spinner: NgxSpinnerService,  private router:Router, private route: ActivatedRoute,) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.mainDoId = params['id'];
      this.getDetailDo(this.mainDoId, false);
      this.service.listDetailApprovalDo(this.mainDoId, false).subscribe(resp => {
        this.listDoDetail = resp;
        this.listDoDetail.forEach(x => {
          x.select = true
        })
      });
      this.checkAllDO = false;
      this.getSPKById(this.mainDoId);
   });
    
    this.dtOptionsDetailDo = {
      retrieve: true,
      paging: false,
      ordering : false,
      bInfo : false,
      // scrollX: true,
      // scrollCollapse: true,
      searching : false,
      processing: true,
      columnDefs: [
        { width: 100, targets: [1,2,3,4] },
        { width: 20, targets: 0 },
      ],
    };
  }

  ngAfterViewInit(): void {
    this.dtTriggerDetail.next(0)
  }

  getSPKById(id: string){
    this.service.spkById(id).subscribe(resp => {
      this.spk = resp;
      this.customerName = resp.customer_name;
      this.spk_no = resp.spk_no;
      this.maindo_no = resp.main_do_no;
      this.contract_no = resp.contract_no;
    });
  }

  getDetailDo(id: string, all: boolean){
    this.service.listDetailApprovalDo(id, all).subscribe(resp => {
      this.listDoDetail = resp;
      this.dtTriggerDetail.next(0);
    });
    this.checkAllDO = false;
  }

  checkAll(){
    if (this.listDoDetail.find(x => x.approve_status == false).select == false){ 
     this.listDoDetail.filter(x => x.approve_status == false).forEach(x => {
        x.select = true;
      })
    }
    else{
      this.listDoDetail.filter(x => x.approve_status == false).forEach(x => {
        x.select = false;
      })
    }
  }
  
  checkSelected(data: ListDoDetail){
    if (this.listDoDetail.find(x => x.id == data.id).select != true){
         this.listDoDetail.find(x => x.id == data.id).select = true;
    }
    else{
      this.listDoDetail.find(x => x.id == data.id).select = false;
    }
  }
  
  filterDo(data){
    this.getDetailDo(this.mainDoId, data);
  }

  approveDo(){
    this.inputApprove.list = [];
    this.listDoDetail.filter(x => x.select == true).forEach(x => {
      this.inputApprove.list.push({
        id : x.id
      })
    })
    Swal.fire({
      title: 'Approve DO?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '      Yes     '
    }).then((result) => {
      if (result.value) {
        if (this.inputApprove.list.length == 0){
          return  Swal.fire({
            title: "Belum Ada Data Dipilih!",
            type: 'warning',
            confirmButtonText: '      Ok!     '
          });
        }

           this.spinner.show();
           this.service.approvalDo(this.inputApprove).subscribe( resp => {
            this.spinner.hide();
            if(resp.succes == false){
              Swal.fire({
                title: ""+resp.text,
                type: 'warning',
                confirmButtonText: '      Ok!     '
              });
            }
            else{
              Swal.fire({
                title: ""+resp.text,
                type: 'success',
                confirmButtonText: '      Ok!     '
              }).then((result) => {
                if (result.value) { 
                  this.getDetailDo(this.mainDoId, false);
                }              
              }, (error) => {
                ; 
              })
            }
           })
      }
    })
  }


}
