<pf-dropdown hide-arrow class="user-menu">
    <div slot="button">
        <a [routerLink]="['/re-pass']" class="nav-link">
            <pf-image
                [src]="user.picture"
                fallback-src="assets/img/key.png"
                class="user-image-small"
                width="28"
                height="26"
                alt="User Image"
                rounded
            ></pf-image>
        </a>
    </div>
    <div slot="button">
        <a class="nav-link" (click)="logout()">
            <pf-image
                [src]="user.picture"
                fallback-src="assets/img/logout.png"
                class="user-image-small"
                width="28"
                height="26"
                alt="User Image"
                rounded
            ></pf-image>
        </a>
    </div>
    <!-- <div slot="menu">
        <li class="user-header bg-primary">
            <pf-image
                [src]="user.picture"
                fallback-src="assets/img/default-profile.png"
                class="user-image-big"
                alt="User Image"
                width="90"
                height="90"
                rounded
            ></pf-image>

            <p>
                <span>{{ user.email }}</span>
                <small>
                    <span class="mr-2">Member since</span>
                    <span>{{ formatDate(user.createdAt) }}</span>
                </small>
            </p>
        </li>
        <li class="user-body">
            <div class="row">
                <div class="col-4 text-center">
                    <a [routerLink]="['/']">Followers</a>
                </div>
                <div class="col-4 text-center">
                    <a [routerLink]="['/']">Sales</a>
                </div>
                <div class="col-4 text-center">
                    <a [routerLink]="['/']">Friends</a>
                </div>
            </div>
        </li>
        <li class="user-footer">
            <a [routerLink]="['/profile']" class="btn btn-default btn-flat">
                Profile
            </a> 
            <a (click)="logout()" class="btn btn-default btn-flat float-right">
                Sign out
            </a>
        </li>
    </div> -->
</pf-dropdown>


