import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { TransportService} from '../../../services/api.service';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { saveAs } from 'file-saver';
import { FormControl, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { FinalResultDto, Report, ReportDto } from '@/model/reportDto';
import { Guid } from 'guid-typescript';
@Component({
  selector: 'app-report-custom',
  templateUrl: './report-custom.component.html',
  styleUrls: ['./report-custom.component.scss']
})
export class ReportCustomComponent  implements OnInit, AfterViewInit {
  @ViewChild('ModalCreateClose') ModalCreateClose;
  dtOptionsQuery: any = {};
  tableShow = false;
  dtTriggerQuery = new Subject();
  idReport : Guid;
  public dt: any;
  public cb: any;
  public rows: any;
  finalResult: FinalResultDto;
  dataReport: ReportDto[]=[];
  dtOptionsListCustom: any = {};
  dtTriggerListCustom = new Subject();
  @Input() queryModel = { id: null, query: null, mode: null}
  @Input() reportModel = { Id: null, Code: null, Name: null, Query: null, Fields: null, Headers: null,
    ShowAsHeaders: null, ShowAsSubHeaders: null, Filters: null, Formats: null, Totals: null,
    PrimarySort: null, SecondarySort: null}
 fields = ""; headers = ""; formats = ""; filters = ""; totals = ""; showAsHeaders = ""; showAsSubHeaders = "";

  constructor( public service: TransportService, private spinner: NgxSpinnerService) {
  } 
  
  selectedItems: any = null;
  form: FormGroup = new FormGroup({
    items: new FormControl(null)
  });
  someClickHandler(info: any): void {
  }

    ngOnInit(): void {
      
      const that = this;

      this.dtOptionsListCustom = {
        retrieve: true,
        pageLength: 10,
        ordering : false,
        orderCellsTop : false,
        scrollX: true,
        scrollCollapse: true,
        serverSide: true,
        processing: true,
        searching : false,
        ajax: (dataTablesParameters: any, callback) => {
         this.dt = dataTablesParameters; this.cb = callback;
         this.getDataListCustom(dataTablesParameters, callback, $('#name').val().toString(),true);
        },
        columnDefs: [
          { width: 200, targets: [0,1,2] },
          { width: 1000, targets: 3 },
        ],
      };

     
    this.dtOptionsQuery = {
      retrieve: true,
      paging: false,
      scrollX: true,
      scrollCollapse: true,
      serverSide: true,
      processing: true,
      searching : false,
      ajax: (dataTablesParameters: any, callback) => {
        that.cb = callback;
        // this.queryModel.query = "select * from t_config";
        // this.queryModel.mode = "Create"
        //that.checkQuery(callback, "", "");
        
      },
      columns: [{
        title: 'Check',
        data: 'checkbox'
      }, {
        title: 'Field Name',
        data: 'fieldName'
      }, {
        title: 'Header Name',
        data: 'headerName'
      }, {
        title: 'Format',
        data: 'format'
      }, {
        title: 'Filter',
        data: 'filter'
      }, {
        title: 'Total',
        data: 'total'
      }, {
        title: 'Show Header',
        data: 'showHeader'
      }, {
        title: 'Show SubHeader',
        data: 'showSubHeader'
      }],
      columnDefs: [
        { width: 100, targets: [0,4,5,6,7] },
        { width: 250, targets: [1,2,3] },
      ],
      rowCallback: (row: Node, data: any[] | Object, index: number) => {
        const self = this;
        that.rows = row;
        that.getDataRows(row);
      }
    };
    
    $('#check').on("click", function(e) { 
      if (that.reportModel.Query == null){
        return Swal.fire({
          title: "Query Masih Kosong !",
          type: 'warning',
          confirmButtonText: '      Ok!     '
        });
      }
      that.checkQuery(that.cb, that.reportModel.Query, "Create", null);
    });
     
    
    }   

    ngAfterViewInit(): void {
       this.dtTriggerListCustom.next(0);
    }
  
    ngOnDestroy(): void {
    }

  checkQuery(callback, query, mode, id){
     this.queryModel.id = id;
     this.queryModel.query = query;
     this.queryModel.mode = mode;
    // this.service.checkQueryreport(this.queryModel).subscribe(resp => {
    //   this.finalResult = resp;
    // })
    this.service.checkQueryreport(this.queryModel).subscribe(resp => {
      callback({
        recordsTotal: resp.json.length,
        recordsFiltered: resp.json.length,
        data: resp.json
      });
    })
    
  }

  getDataRows(row){
    const that = this;

          var table = $("#tableID tbody tr");
         
          that.reportModel.Fields = ''
          that.reportModel.Headers = ''
          that.reportModel.Formats = ''
          that.reportModel.Filters = ''
          that.reportModel.Totals = ''
          that.reportModel.ShowAsHeaders = ''
          that.reportModel.ShowAsSubHeaders = ''
          for (var x = 0; x < table.length; x++) {
              var y = $(table[x]).find('td');

              if (y.length > 0) {
                  var isChecked = $(y[0]).find('input').prop("checked");

                  if (isChecked) {
                      var field = $(y[1]).find('input').val();
                      var header = $(y[2]).find('input').val();
                      var format = $(y[3]).find('input').val();
                      var filter = $(y[4]).find('input').prop("checked");
                      var total = $(y[5]).find('input').prop("checked");
                      var showAsHeader = $(y[6]).find('input').prop("checked");
                      var showAsSubHeader = $(y[7]).find('input').prop("checked");

                      that.reportModel.Fields += field + ",";
                      that.reportModel.Headers += header + ",";
                      that.reportModel.Formats += format + ",";
                      that.reportModel.Filters += (filter ? "1," : "0,");
                      that.reportModel.Totals += (total ? "1," : "0,");
                      that.reportModel.ShowAsHeaders += (showAsHeader ? "1," : "0,");
                      that.reportModel.ShowAsSubHeaders += (showAsSubHeader ? "1," : "0,");
                  }

              }
            }
        
        return row;
  }

  showCheck(){
    this.dtTriggerQuery.next(0);
  }

  saveDataReport(){
    if(this.reportModel.Code == null || this.reportModel.Name == null || this.reportModel.Query == null ){
      return Swal.fire({
        title: "Data Belum Lengkap !",
        type: 'warning',
        confirmButtonText: '      Ok!     '
      });
    }
    this.getDataRows(this.rows);
    Swal.fire({
      title: 'Simpan Report?',
      // text: "You won't be able to revert this!",
      type: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '      Yes     '
    }).then((result) => {
      if (result.value) {
        this.spinner.show();
        this.service.saveCustomReport(this.reportModel).subscribe(resp => {
          this.spinner.hide();
          Swal.fire({
            text: ''+resp.text,
            type: 'success',
            confirmButtonText: '      Succes!     '
          }).then((result) => {
            if (result.value) { 
              this.ModalCreateClose.nativeElement.click();
              this.getDataListCustom(this.dt, this.cb, $('#name').val().toString(),true);
              //get report list
            }
          
          }, (error) => {
            //; 
          })
        });
      }
    
    }, (error) => {
    });
  } 

  getDataListCustom(dataTablesParameters, callback, name: string, padging : boolean){ 
    var page = 1;
    if (padging == true){
      page = parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length) + 1;
    }
    this.service.getListCustomReport(dataTablesParameters, page, dataTablesParameters.length, name)
      .subscribe(resp => { 
        this.dataReport = resp.data;
        callback({
          recordsTotal: resp.totalCount,
          recordsFiltered: resp.totalCount,
           data:  []
        });
        
      this.dtTriggerListCustom.next(0);
    });
  }

  
  deleteData(data: Report){
    Swal.fire({
      title: 'Delete Report?',
      // text: "You won't be able to revert this!",
      type: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '      Yes     '
    }).then((result) => {
      if (result.value) {
        this.spinner.show();
        this.service.deleteDataReport(data.id).subscribe(resp => {
          this.spinner.hide();
          Swal.fire({
            type: 'warning',
            text: ''+resp.text,
            confirmButtonText: '      Ok!     '
          }).then((result) => {
            if (result.value) { 
              this.getDataListCustom(this.dt, this.cb, $('#name').val().toString(), true);
            }
          
          }, (error) => {
            //; 
          })
        });
      }
    
    }, (error) => {
    });
  }

  clearInput(){
    this.reportModel.Id = null;
    this.reportModel.Code = null;
    this.reportModel.Name = null;
    this.reportModel.SecondarySort = null;
    this.reportModel.PrimarySort = null;
    this.reportModel.Query = null;
    //$('#tableID').DataTable().destroy();
    //$('#tableID').empty();
  }


  editReport(row: Report){
    this.dtTriggerQuery.next(0);
    this.reportModel.Id = row.id;
    this.reportModel.Code = row.code;
    this.reportModel.Name = row.name;
    this.reportModel.SecondarySort = row.secondarySort;
    this.reportModel.PrimarySort = row.primarySort;
    this.reportModel.Query = row.query;
    setTimeout(()=>{                
    this.checkQuery(this.cb, row.query, "Edit", row.id);
  }, 10);
  }


}




