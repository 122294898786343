import { Guid } from "guid-typescript";

export class SpkService {
    spkId : Guid;
    spkNo : string;
    truckId : Guid;
    truckNo : string;
    driverId : Guid;
    driverName : string;
    oilChange : boolean;
    startDate : Date;
    endDate : Date;
    created_at : Date;
    updated_at : Date;
    createdName : string;
    updatedName : string;
    damageType : string;
    workshop : string;
    remarkProcess : string;
    status : string;
  }

  export class SpkServiceDetail {
    spkDetailId : Guid;
    spkId : Guid;
    remark : string;
    createdAt : Date;
    updatedAt : Date;
    createdName : string;
    updatedName : string;
  }
  

  export class Npb {
    npbId : Guid;
    spkId : Guid;
    npbNo : string;
    date : Date;
    remark : string;
    createdAt : Date;
    updatedAt : Date;
    createdName : string;
    updatedName : string;
    spkNo : string;
    status : string;
  }

  export class NpbDetail {
    npbDetailId : Guid;
    npbId : Guid;
    itemId : number;
    stockCode : string;
    itemName : string;
    qty : number;
    uom : string;
    createdAt : Date;
    updatedAt : Date;
    createdName : string;
    updatedName : string;
  }

  export class Spb {
    spbId : Guid;
    spkId : Guid;
    spbNo : string;
    date : Date;
    remark : string;
    createdAt : Date;
    updatedAt : Date;
    createdName : string;
    updatedName : string;
    status : string;
    spkNo : string;
  }

  export class SpbDetail {
    spbDetailId : Guid;
    spbId : Guid;
    remark : string; 
    itemId : number;
    stockCode : string;
    itemName : string;
    qty : number;
    uom : string;
    createdAt : Date;
    updatedAt : Date;
    createdName : string;
    updatedName : string;
  }

  export class Bapb {
    bapbId : Guid;
    spkId : Guid;
    bapbNo : string;
    date : Date;
    remark : string;
    createdAt : Date;
    updatedAt : Date;
    createdName : string;
    updatedName : string;
    status : string;
    spkNo : string;
  }

  export class BapbDetail {
    bapbDetailId : Guid;
    bapbId : Guid;
    remark : string;
    itemId : number;
    stockCode : string;
    itemName : string;
    qty : number;
    uom : string;
    createdAt : Date;
    updatedAt : Date;
    createdName : string;
    updatedName : string;
  }
  export class Approvals {
    id : Guid;
    documentId : Guid;
    documentNumber : string;
    type : string;
    approver : Date;
    status : string;
    createdAt : Date;
    createdByName : Date;
    updatedAt : string;
    cpdatedByName : string;
  }

  export class ViewStock {
    comLocID : string;
    itemID : number;
    itemCode : string;
    itemSubKategori : string;
    itemKategori : string;
    gL_Account : string;
    itemName : string;
    uom : string;
    qty : number;
    unitPrice : number;
  }

  export class SpkExistDoc {
    docNo : string;
  }