
<!-- <form>
    <div class="multiselect">
      <div class="selectBox" (click)="showCheckboxes()">
        <select>
          <option>Select an option</option>
        </select>
        <div class="overSelect"></div>
      </div>
      <div id="checkboxes">
        <label for="one">
          <input type="checkbox" id="one" />First checkbox</label>
        <label for="two">
          <input type="checkbox" id="two" />Second checkbox</label>
        <label for="three">
          <input type="checkbox" id="three" />Third checkbox</label>
      </div>
    </div>
</form> -->

<section class="content">    
    <div class="card">
      <div class="card-header">
        <strong>Journal Invoice</strong>
      </div>
        <div class="card-body">
            <div class="row">
                <div class="col-md-4">
                    <div class="form-group row">
                        <label class="col-md-3 col-form-label" for="text-input">Tanggal Invoice</label>
                        <div class="col-md-8">
                          <input  type="date" [ngModel]="inputModel.from | date:'yyyy-MM-dd'" (ngModelChange)="inputModel.from = $event"  class="form-control">
                        </div>
                    </div> 
                    <div class="form-group row">
                        <label class="col-md-3 col-form-label" for="text-input">Bulan Invoice</label>
                        <div class="col-md-8">
                            <input [(ngModel)]="inputModel.month" currencyMask [options]="{ prefix: '', thousands: '', decimal: ',' ,precision: '0',align: 'left' }" type="text"class="form-control">
                        </div>
                    </div> 
                </div>
                <div class="col-md-4">
                    <div class="form-group row">
                        <label class="col-md-3 col-form-label" for="text-input">Sampai Tanggal</label>
                        <div class="col-md-8">
                          <input  type="date" [ngModel]="inputModel.to | date:'yyyy-MM-dd'" (ngModelChange)="inputModel.to = $event"  class="form-control">
                        </div>
                    </div> 
                    <div class="form-group row">
                        <label class="col-md-3 col-form-label" for="text-input">Tahun Invoice</label>
                        <div class="col-md-8">
                            <input [(ngModel)]="inputModel.year" currencyMask [options]="{ prefix: '', thousands: '', decimal: ',' ,precision: '0',align: 'left' }" type="text"class="form-control">
                        </div>
                    </div> 
                </div>
                <div class="col-md-4">
                    <div class="form-group row">
                        <label class="col-md-3 col-form-label" for="text-input">Start Counter</label>
                        <div class="col-md-8">
                            <input [(ngModel)]="inputModel.start_counter" currencyMask [options]="{ prefix: '', thousands: '', decimal: ',' ,precision: '0',align: 'left' }" type="text"class="form-control">
                        </div>
                    </div> 
                </div>
            </div> 
            <br>
            <br>
            <div style="text-align: center">
                <button type="button" (click)="generateInvoice()" class="btn btn-warning">&nbsp;<i class="fa fa-recycle"></i>&nbsp;&nbsp;&nbsp;&nbsp;<b>Generate</b>&nbsp;&nbsp;&nbsp;&nbsp;</button>&nbsp;&nbsp;
                <button type="button" (click)="saveInvoice()" class="btn btn-primary">&nbsp;<i class="fa fa-save"></i>&nbsp;&nbsp;&nbsp;&nbsp;<b>Simpan</b>&nbsp;&nbsp;&nbsp;&nbsp;</button>&nbsp;&nbsp;
                <button type="button" (click)="exportExcel()" class="btn btn-primary">&nbsp;<i class="fa fa-file-excel"></i>&nbsp;&nbsp;&nbsp;&nbsp;<b>Export Excel</b>&nbsp;&nbsp;&nbsp;&nbsp;</button>&nbsp;&nbsp;
            </div>
            <br>
            <br>
            <div class="card-body">
            <div class="scroll">
                <table datatable [dtOptions]="dtOptionsJournal" [dtTrigger]="dtTriggerJournal" class="table-striped">
                  <thead>
                    <tr>
                      <th>Journal Invoice No</th>
                      <th>PRJ</th>
                      <th>Date</th>
                      <th>Remark</th>
                      <th>CCY</th>
                      <th>Rate</th>
                      <th>Credit</th>
                      <th>Debit</th>
                      <th>GL Code</th>
                      <th>Truck No</th>
                      <th>SL Code</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let row of dataListJournal">     
                      <td>{{row.journalInvoiceNo}}</td>
                      <td>{{row.prj}}</td>
                      <td>{{row.date | slice:0:10}}</td>
                      <td>{{row.remark}}</td>
                      <td>{{row.ccy}}</td>
                      <td>{{row.rate}}</td>
                      <td>Rp. {{row.credit | number}}</td>
                      <td>Rp. {{row.debit | number}}</td>
                      <td>{{row.glCode}}</td>
                      <td>{{row.truckNo}}</td>
                      <td>{{row.slCode}}</td>
                    </tr>
                  </tbody>        
                </table>
              </div>
            </div>
        </div>
    </div>
  </section>
  
   
  
  <ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
  >
  <p style="font-size: 20px; color: white">Loading...</p>
  </ngx-spinner>