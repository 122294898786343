import { AfterViewInit, Component, OnInit, ViewChild,Input, ChangeDetectorRef, ElementRef  } from '@angular/core';
import { TransportService} from '../../../services/api.service';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import moment from 'moment';
import { Guid } from 'guid-typescript';
import { Npb, NpbDetail, SpkService, SpkServiceDetail, ViewStock } from '@/model/spkService';
import { ListTruck } from '@/model/listTruck';
import { Driver } from '@/model/driver';
import { DebitNote, DebitNoteDetail, JournalDebitNote, JournalDebitNoteExport, UploadDebitNote } from '@/model/debitNote';
import { RespUploadDebitDto } from '@/model/respDto';
import {formatDate } from '@angular/common';
import { AppService } from '@services/app.service';
import saveAs from 'file-saver';

@Component({
  selector: 'app-debit-note',
  templateUrl: './debit-note.component.html',
  styleUrls: ['./debit-note.component.scss']
})
export class DebitNoteComponent  implements OnInit, AfterViewInit {
  @ViewChild('modalSimpanClose') modalSimpanClose: ElementRef;
  @ViewChild('uploadFile') uploadFile: ElementRef;
  @ViewChild('ModalUploadFGSClose') ModalUploadFGSClose: ElementRef; 
  dataUpload: UploadDebitNote[]=[];
  dataSave: UploadDebitNote[]=[];
  @Input() inputSave = {  list: new Array()}
  public clean = false;
  select: number = 0;
  totalData: number = 0;
  fileName: string;
  dtOptionsAcc: any = {};
  dtOptionsDetail: any = {};
  dtTriggerDetail = new Subject();
  dtTriggerAcc = new Subject();
  dataListDebit: DebitNote[] = [];
  dtOptionsDriver: any = {};
  dtTriggerDriver = new Subject();
  dtOptionsStock: any = {};
  dtTriggerStock = new Subject();
  listDebitNoteDetail: DebitNoteDetail[] = [];
  listNpbDetailDelete: NpbDetail[] = [];
  dataDriver: Driver[] = [];
  listSPK: SpkService[] = [];
  dataStock: ViewStock[] = [];
  dtOptionsSpk: any = {};
  dtTriggerSpk = new Subject();
  public dt: any;
  public cb: any;
  dtOptionsJournal: any = {};
  dtTriggerJournal = new Subject();  
  public dtSpk: any;
  public cbSpk: any;
  public dtDriver: any;
  public cbDriver: any;
  public dtStock: any;
  public cbStock: any;
  public update : boolean;
  public show : boolean;
  locationName: string;
  printDateFrom : Date;
  printDateTo : Date;
  @Input() reportModel = {from: null, to: null, location: null};
  menu: Guid;
  @Input() listApps = { roleName: null}
  @Input() spkNo = { startDate: null, code: null}
  @Input() npbDetailModel = {itemId: null, itemCode: null,itemName: null,qty: null, uom: null}
  @Input() inputModel = {  from: null, to: null, month: null, year: null, start_counter: null}
  @Input() debitModel = { debitNoteId: null, receiptNo: null, receiptDate: null,  customer: null,
     address: null, description: null, route: null, spk: null, do: null, type: null,}
  dataListJournal: JournalDebitNote[] = [];
  dataExportListJournal: JournalDebitNoteExport[] = [];
  @Input() npbModel = { NpbId: null, SpkId: null, NpbNo: null,  SpkNo: null, Date: null, Remark: null,npbDetail: new Array(), npbDetailDelete: new Array()}
  constructor( public service: TransportService, private appServ: AppService, private router: Router, private spinner: NgxSpinnerService, private changeDetectorRef: ChangeDetectorRef) {
  }

    ngOnInit(): void {
      this.listApps.roleName = localStorage.getItem("rolename");
      this.locationName = localStorage.getItem('locationname');
     // this.userAccess();
      this.dtOptionsAcc = {  
        scrollX: true,
        scrollCollapse: true,
        pageLength: 10,
        ordering : false,
        serverSide: true,
        processing: true,
        searching : false,
        ajax: (dataTablesParameters: any, callback) => {
         this.dt = dataTablesParameters; this.cb = callback;
         this.getListDebitNote(dataTablesParameters, callback,  true, "","","","","","");

        },
        columnDefs: [
          { width: 200, targets: [0]},
          { width: 200, targets: [1,2,3,4,5,6,7,8,9,10,11,12]}
        ],
      };

      this.dtOptionsDetail = {
        retrieve: true,
        //pageLength: 100,
        ordering : false,
        // scrollX: true,
        // scrollCollapse: true,
        searching : false,
        columnDefs: [
          { width: 400, targets: [1]},
          { width: 80, targets: [0,2,3,4] }
        ],
      };

      this.dtOptionsDriver = {
        retrieve: true,
        pageLength: 5,
        scrollX: true,
        scrollCollapse: true,
        serverSide: true,
        processing: true,
        searching : false,
        ajax: (dataTablesParameters: any, callback) => {
         this.dtDriver = dataTablesParameters; this.cbDriver = callback;
         this.getDataDriver(dataTablesParameters, callback, "", "","","","", "","","","", "","","", "",  true);
         },
        columnDefs: [
          { width: 150, targets: [0,2,3,4,5,6,7,8,9,10,12,13,14,15] },
          { width: 200, targets: [1] },
          { width: 300, targets: [11] },
        ],
      };

      this.dtOptionsStock = {
        retrieve: true,
        pageLength: 5,
        scrollX: true,
        scrollCollapse: true,
        serverSide: true,
        processing: true,
        searching : false,
        ajax: (dataTablesParameters: any, callback) => {
         this.dtStock = dataTablesParameters; this.cbStock = callback;
         this.getDataStock(dataTablesParameters, callback, "", "","","","",  true);
         },
        columnDefs: [
          { width: 150, targets: [0,2,3,4,5,6,7,8,9,10,12,13,14,15] },
          { width: 200, targets: [1] },
          { width: 300, targets: [11] },
        ],
      };

      this.dtOptionsJournal = {  
        retrieve: true,
        pageLength: 10,
        ordering : false,
        searching : false,
        columnDefs: [
          { width: 100, targets: [0,1,2,4,5,6,7,8,9,10,11,12,13]},
          { width: 500, targets: [3]}
        ],
      };

      const that = this;
      $('.searchInput').on("keyup", function(e) {
        var key = e.which;
        if (key == 13) // the enter key ascii code
        {
          that.getListDebitNote(that.dt, that.cb,  true, $('#ReceiptNo').val().toString(),$('#Customer').val().toString()
          ,$('#Description').val().toString(),$('#Route').val().toString(),$('#SPK').val().toString(),$('#DO').val().toString());;
        }
      }); 
      
    }   

    userAccess(){
      const username = localStorage.getItem("rolename");
      if (!username.includes("admin")){
          return this.router.navigate(["/home"]);
      }
    }
    
    ngAfterViewInit(): void {
      this.dtTriggerAcc.next(this.dtOptionsAcc)
      this.dtTriggerDetail.next(this.dtOptionsDetail);
      this.dtTriggerJournal.next(this.dtOptionsJournal);
    }
  
    ngOnDestroy(): void {
      this.dtTriggerAcc.unsubscribe();
      this.dtTriggerDetail.unsubscribe();
    }

    showDriver(){
      this.dtTriggerDriver.next(this.dtOptionsDriver);
    }

    showSpk(){
      this.dtTriggerSpk.next(this.dtOptionsSpk);
    }

    showStock(){
      this.dtTriggerStock.next(this.dtOptionsStock);
    }

    browseFile(){
      this.uploadFile.nativeElement.click();
      (<HTMLInputElement> document.getElementById("upload")).disabled = false;
    }
    onFileSelected(event: Event) {
      const target = event.target as HTMLInputElement;
      if (target.files && target.files.length > 0) {
          this.fileName = target.files[0].name;
      }
  }
    uploadDebitNote(){
      const fi = this.uploadFile.nativeElement;
      if (fi.files && fi.files[0]) {
        const fileToUpload = fi.files[0];
      this.spinner.show();
        this.service.UploadDebitNote(fileToUpload,this.locationName).subscribe( resp => { 
          this.spinner.hide();
          if(resp.succes == true){
            this.dataUpload = resp.list;
            this.select = resp.list.filter(x => x.selected == true).length;
            this.totalData = resp.list.length;
            (<HTMLInputElement> document.getElementById("upload")).disabled = true;
          }
          else{
            Swal.fire({
              title: ""+resp.text,
              type: 'warning',
              confirmButtonText: '      Ok!     '
            });
          }
        })
    }
  }

  selectAll(){
    if (this.clean == false){
      this.dataUpload.filter(item => item.error != true).forEach(i => {
        i.selected = true;
      });
      this.clean = true;
  }
  else{
    this.dataUpload.filter(item => item.error != true).forEach(i => {
      i.selected = false;
    });
      this.clean = false;
  }
}

  uploadClear(){
    (<HTMLInputElement> document.getElementById("upload")).disabled = true;
    this.fileName = "";
    this.dataUpload = [];
  }

  checkSelected(){
    this.select = this.dataUpload.filter(x => x.selected == true).length;
  }

    getDataDriver(dataTablesParameters, callback,  driver_name: string, driver_license: string, driver_license_type: string, 
      agama: string, birthplace: string, ktp_no: string, tanggal_lahir: string, sl_code: string, sl_code2: string,
      sl_code3: string, address: string, valid_to: string, valid_to_contract: string, padging : boolean){ 
      var page = 1;
      if (padging == true){
        page = parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length) + 1;
      }
      this.service.getListDriver(dataTablesParameters, page, dataTablesParameters.length, driver_name, driver_license, driver_license_type, agama,
        birthplace,ktp_no,tanggal_lahir, sl_code, sl_code2, sl_code3, address, valid_to, valid_to_contract, "true", "false")
      .subscribe(resp => { 
          this.dataDriver = resp.data;
          callback({
            recordsTotal: resp.totalCount,
            recordsFiltered: resp.totalCount,
            data:  []
          });
      });
    }

    getDataStock(dataTablesParameters, callback, ItemCode: string, ItemSubKategori: string, ItemKategori: string, 
      GL_Account: string, ItemName: string, padging : boolean){ 
      var page = 1;
      if (padging == true){
        page = parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length) + 1;
      }
      this.service.getListStock(dataTablesParameters, page, dataTablesParameters.length, ItemCode , ItemSubKategori,
        ItemKategori, GL_Account, ItemName)
      .subscribe(resp => { 
          this.dataStock = resp.data;
          callback({
            recordsTotal: resp.totalCount,
            recordsFiltered: resp.totalCount,
            data:  []
          });
      });
    }

    getListDebitNote(dataTablesParameters, callback, padging: Boolean,  ReceiptNo: string, Customer: string, 
      Description: string, Route: string, SPK: string, DO: string){
    var page = 1;
      if (padging == true){
        page = parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length) + 1;
      }

      this.service.getListDebitNote(dataTablesParameters, page, dataTablesParameters.length,ReceiptNo,Customer,Description,Route,SPK,DO)
      .subscribe(resp => { 
          this.dataListDebit = resp.data;
          callback({
            recordsTotal: resp.totalCount,
            recordsFiltered: resp.totalCount,
             data:  []
          });
      });
  }

  messageWarning(text: string){
    Swal.fire({
      title: ""+text,
      type: 'warning',
      confirmButtonText: '      Ok!     '
    });
    return;
  }

  saveUpload(){
    this.dataSave = this.dataUpload.filter(item => item.selected == true);
    if(this.dataSave == null){
      return Swal.fire({
        title: "Belum Ada Data yang dipilih!",
        type: 'warning',
        confirmButtonText: '      Ok!     '
      });
    }
    if(this.dataSave.length == 0){
      return Swal.fire({
        title: "Belum Ada Data yang dipilih!",
        type: 'warning',
        confirmButtonText: '      Ok!     '
      });
    }
    this.inputSave.list = [];
    Swal.fire({
      title: 'Simpan Data?',
      // text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '      Yes     '
    }).then((result) => {
      this.dataSave.forEach(x => {
        this.inputSave.list.push({
          receiptNo: x.receiptNo,
          receiptDate: x.receiptDate,
          customer: x.customer,
          address: x.address,
          description: x.description,
          route: x.route,
          spk: x.spk,
          do: x.do,
          amount: x.amount,
          truckNumber: x.truckNumber,
          qty: x.qty,
          oa: x.oa,
          type: x.type,
          printDate: x.printDate,
          error_message: x.error_message,
          error: x.error,
          selected: x.selected,
        })
      });
        if (result.value) {
          this.spinner.show();
          this.service.saveUploadDebitNote(this.inputSave).subscribe( resp => {
            this.spinner.hide();
            if(resp.succes == false){
              Swal.fire({
                title: ""+resp.text,
                type: 'warning',
                confirmButtonText: '      Ok!     '
              });
            }
            else{
              this.getListDebitNote(this.dt, this.cb,  true, "","","","","","");
              this.ModalUploadFGSClose.nativeElement.click();
              Swal.fire({
                title: ""+resp.text,
                type: 'success',
                confirmButtonText: '      Ok!     '
              }).then((result) => {
                this.dataUpload = [];
                this.dataSave = [];
                if (result.value) { 
                }              
              }, (error) => {
              ; 
              })
            }
          })
        }
    });
  }

  onSelectDetailDebit(data: any){
    this.service.getDebitNoteDetail(data.debitNoteId).subscribe(resp => {
      this.listDebitNoteDetail = resp;
    });
    this.debitModel.receiptNo = data.receiptNo;
    this.debitModel.receiptDate = data.receiptDate;
    this.debitModel.customer = data.customer;
    this.debitModel.description = data.description;
    this.debitModel.spk = data.spk;
    this.debitModel.do = data.do;
    this.debitModel.type = data.type;
    this.debitModel.route = data.route;
  }

  generateJournal(){
    if (this.inputModel.from == null || this.inputModel.to == null ||this.inputModel.month == null ||
      this.inputModel.year == null ||this.inputModel.start_counter == null){
        return Swal.fire({
          title: "Data Belum Lengkap!",
          type: 'warning',
          confirmButtonText: '      Ok!     '
        });
      }
      
      this.dataListJournal = [];
      this.spinner.show();
      this.service.generateJournalDebitNote(this.inputModel)
      .subscribe(resp => { 
        this.spinner.hide();
        if(resp.succes == false){
          Swal.fire({
            title: ""+resp.text,
            type: 'warning',
            confirmButtonText: '      Ok!     '
          });
        }
          this.dataListJournal = resp.list;
      });
   }


  printDebit(data: any){
    this.spinner.show();
    this.service.printDebit(data.debitNoteId, this.locationName).subscribe(resp =>{
      this.spinner.hide();
      let blob:Blob=resp.body as Blob;
        let url = window.URL.createObjectURL(blob);
        window.open(url);
      })
  }

  exportExcel(){
    if(this.dataListJournal == null){
      return Swal.fire({
        title: "Tabel generate Journal Kosong!",
        type: 'warning',
        confirmButtonText: '      Ok!     '
      });
    }
    if(this.dataListJournal.length == 0){
      return Swal.fire({
        title: "Tabel generate Journal Kosong!",
        type: 'warning',
        confirmButtonText: '      Ok!     '
      });
    }
    this.dataExportListJournal = [];
    this.dataListJournal.forEach(x => {
      this.dataExportListJournal.push({
        No_Bukti : x.journalNo,
        PRJ : x.prj,
        Tanggal : formatDate(x.date, 'yyyy-MM-dd', 'en-US'),
        Keterangan : x.remark,
        CCY : x.ccy,
        RATE : x.rate,
        Credit : x.credit,
        Debet : x.debit,
        GL : x.gl,
        IO : x.io,
        Cust_Supp: x.custSup
      })
    });
    
    this.appServ.exportAsExcelFile(this.dataExportListJournal, 'Journal_Uang_Jalan');
 
}


delete(data: any){
  Swal.fire({
    title: 'Hapus Data?',
    // text: "You won't be able to revert this!",
    type: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: '      Yes     '
  }).then((result) => { 
    if (result.value) {
      this.spinner.show();
      this.service.deleteDebitNote(data.debitNoteId).subscribe( resp => {
        this.spinner.hide();
        if(resp.succes == false){
          Swal.fire({
            title: ""+resp.text,
            type: 'warning',
            confirmButtonText: '      Ok!     '
          });
        }
        else{
          Swal.fire({
            title: ""+resp.text,
            type: 'success',
            confirmButtonText: '      Ok!     '
          }).then((result) => {
            if (result.value) { 
            }              
          }, (error) => {
          ; 
          })
        }
      })
    }
  });
}


printOutStandExcel(){
  if (this.printDateFrom != null || this.printDateTo != null ){
    this.spinner.show();
    this.reportModel.from = this.printDateFrom;
    this.reportModel.to = this.printDateTo;
    this.reportModel.location = localStorage.getItem('locationname');
    this.service.reportDebitNote(this.reportModel).subscribe(resp =>{
      this.spinner.hide();
      let name = "Rekap_Debit_Note.xlsx";
      const blob = new Blob([resp.body],
        { type: 'application/vnd.ms-excel' });
    const file = new File([blob], name,
        { type: 'application/vnd.ms-excel' });
  
      saveAs(file);
    })
  }
  else{
    Swal.fire({
      title: "Tanggal Belum Lengkap!",
      type: 'warning',
      confirmButtonText: '      Ok!     '
    });
  }
 
}


}













