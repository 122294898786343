<section class="content">    
<div class="card">
    <div class="card-header">
        <strong>Approval DO</strong>
    </div>
    <div class="card-body">

<div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-md-6"> 
          <div class="form-group row">
            <label class="col-md-3 col-form-label" for="text-input">Customer</label>
            <div class="col-md-8">
                <input disabled value="{{customerName}}" type="text" class="form-control">
            </div>
          </div> 
          <div class="form-group row">
            <label class="col-md-3 col-form-label" for="text-input">No DO Besar</label>
            <div class="col-md-8">
                <input disabled value="{{maindo_no}}" type="text" class="form-control">
            </div>
          </div> 
        </div>
        <div class="col-md-6"> 
          <div class="form-group row">
            <label class="col-md-3 col-form-label" for="text-input">No SPK</label>
            <div class="col-md-8">
                <input disabled value="{{spk_no}}" type="text" class="form-control">
            </div>
          </div> 
          <div class="form-group row">
            <label class="col-md-3 col-form-label" for="text-input">No Contract</label>
            <div class="col-md-8">
                <input disabled value="{{contract_no}}" type="text" class="form-control">
            </div>
          </div> 
        </div>
      </div>
    </div>
  </div>
  <br>
    <div style="text-align: center">
        <button type="button" (click)="approveDo()" class="btn btn-primary">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>Approve</b>&nbsp;&nbsp;<i class="fas fa-check"></i>&nbsp;&nbsp;</button>
    </div>
  <br>
  <div class="card">
    <div class="card-header">
      <select [(ngModel)]="all" (change)="filterDo(all)" id="cancel" class="searchInput">
        <option selected value="false"><b>Menunggu Approval</b></option>
        <option value="true"><b>All</b></option>
     </select> 
    </div>
    <div class="card-body">
      <div class="scroll">
      <table datatable [dtOptions]="dtOptionsDetailDo" [dtTrigger]="dtTriggerDetail" class="table-striped">
        <thead>
          <tr>
            <th>Select &nbsp;<input (change)="checkAll()" type="checkbox"/></th>
            <th>No DO</th>
            <th>Tanggal Terbit</th>
            <th>Status DO</th>
            <th>Status Approve</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let row of listDoDetail">
            <td><input [disabled]="row.approve_status == true || row.approve_status == null" [checked]="row.select" type="checkbox" (change)="checkSelected(row)"/></td> 
            <td>{{row.do_no}}</td>
            <td>{{row.do_date | slice:0:10}}</td>
            <td>{{row.status_text}}</td>
            <td><input disabled type="checkbox" [checked]="row.approve_status == true || row.approve_status == null"/></td>
          </tr>
        </tbody>
      </table>
      </div>
    </div>
    <!-- <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="ModalDetailDo.hide()"><b>Close</b></button>
    </div> -->
  </div>
    
</div>
</div>
</section>