<section class="content">    
  <div class="card">
      <div class="card-header">
        <strong>List SPK</strong>
        <div class="float-right">
          <a type="button" class="btn btn-sm btn-warning pull-right" data-toggle="modal" (click)="clearSearch();ModalSearch.show()">&nbsp;<b>Cari Data</b>&nbsp;&nbsp;<i class="fa fa-search"></i>&nbsp;</a> &nbsp;&nbsp;&nbsp;
          <a type="button" class="btn btn-sm btn-primary pull-right" data-toggle="modal" (click)="ModalPrint.show()">&nbsp;<b>Laporan Rekap SPK</b>&nbsp;&nbsp;<i class="fa fa-print"></i>&nbsp;</a> &nbsp;&nbsp;&nbsp;
          <a [hidden]="!btnCreate" type="button" class="btn btn-sm btn-primary pull-right" data-toggle="modal" (click)="ModalSPK.show();clearInput()">&nbsp;&nbsp;&nbsp;<b>Input SPK</b>&nbsp;&nbsp;&nbsp;&nbsp;<i class="fa fa-pen"></i>&nbsp;&nbsp;&nbsp;</a>
        </div>
      </div>
      <div class="card-body">
          <table datatable  [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"  class="deeps table-striped">
              <thead>
                <tr>
                  <th>Action</th>
                  <th>No DO</th>
                  <th>Type DO Kecil</th>
                  <th>No SPO</th>
                  <th>Tanggal SPK</th>
                  <th>No SPK</th>
                  <th>No Kontrak</th>
                  <th>No PO</th>
                  <th>Tanggal Kontrak</th>
                  <th>Nama Pelanggan</th>
                  <th>Tanggal Mulai</th>
                  <th>Tanggal Selesai</th>
                  <th>Nama Rute</th>
                  <th>Dari</th>
                  <th>Ke</th>
                  <th>Nama Penjual</th>
                  <th>Nama Material</th>
                  <th>Kuantitas</th>
                  <th>UOM</th>
                  <th>Biaya</th>
                  <th>Tipe Perhitungan</th>
                  <th>Tipe Perjalanan</th>
                  <th>FFA</th>
                  <th>MI</th>
                  <th>KK</th>
                  <th>Toleransi</th>
                  <th>Status</th>
                  <th>Remark</th>
                  <th>Dibuat Oleh</th>
                  <th>Dibuat Pada</th>
                  <th>Diubah Oleh</th>
                  <th>Diubah Pada</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let row of dataListSPK">    
                  <td>
                    <button (click)="showDataSPK(row)" title="Lihat Data" class="btn btn-sm btn-primary" triggers="mouseenter:mouseleave"><i class="fa fa-eye"></i></button>&nbsp;
                    <button [hidden]="!btnUpdate" (click)="onSelectSPK(row)" title="Edit" class="btn btn-sm btn-primary" triggers="mouseenter:mouseleave"><i class="fa fa-pen"></i></button>&nbsp;
                    <button (click)="onSelectDetailDO(row);ModalDetailDo.show();" title="Detail DO" class="btn btn-sm btn-primary" triggers="mouseenter:mouseleave"><i class="fa fa-list"></i></button>&nbsp;
                    <button [hidden]="!btnComplete" [disabled]="row.complete == true" (click)="completeSPK(row)" title="Selesai" class="btn btn-sm btn-primary" triggers="mouseenter:mouseleave"><i class="fa fa-flag-checkered"></i></button>&nbsp;
                    <button [hidden]="!btnComplete" [disabled]="row.complete == false" (click)="cancelComplete(row)" title="Batal Selesai" class="btn btn-sm btn-danger" triggers="mouseenter:mouseleave"><i class="fa fa-flag-checkered"></i></button>&nbsp;
                    <button [hidden]="!btnTransfer" (click)="onSelectTransfer(row);ModalTransfer.show();" title="Transfer DO Kecil" class="btn btn-sm btn-primary" triggers="mouseenter:mouseleave"><i class="fas fa-exchange-alt"></i></button>&nbsp;
                    <button [hidden]="!btnDelete" class="btn btn-sm btn-danger" title="Delete" triggers="mouseenter:mouseleave" (click)="deleteSPK(row);"><i class="fa fa-trash"></i></button>
                  </td>       
                  <td>{{row.main_do_no}}</td>
                  <td>{{row.main_do_type_text}}</td>
                  <td>{{row.spo_no}}</td>
                  <td>{{row.spk_date | slice:0:10}}</td>
                  <td>{{row.spk_no}}</td>
                  <td>{{row.contract_no}}</td>
                  <td>{{row.po_no}}</td>
                  <td>{{row.contract_date | slice:0:10}}</td>
                  <td>{{row.customer_name}}</td>
                  <td>{{row.start_date | slice:0:10}}</td>
                  <td>{{row.finish_date | slice:0:10}}</td>
                  <td>{{row.route_name}}</td>
                  <td>{{row.route_from}}</td>
                  <td>{{row.route_to}}</td>
                  <td>{{row.seller_name}}</td>
                  <td>{{row.material_text}}</td>
                  <td>{{row.quantity}}</td>
                  <td>{{row.uom_name}}</td>
                  <td>{{row.cost}}</td>
                  <td>{{row.charge_type}}</td>
                  <td>{{row.trip_type_name}}</td>
                  <td>{{row.ffa}}</td>
                  <td>{{row.mi}}</td>
                  <td>{{row.kk}}</td>
                  <td>{{row.tolerance}}</td>
                  <td><input type="checkbox" [checked]="row.complete"/></td>
                  <td>{{row.remark}}</td>
                  <td>{{row.created_by_text}}</td>
                  <td>{{row.created_at_text | slice:0:10}}</td>
                  <td>{{row.updated_by_text}}</td>
                  <td>{{row.updated_at_text | slice:0:10}}</td>
              </tr>
              </tbody>
              <!-- <tfoot>
                  <tr>
                    <th><input id="do_kecil" type="text" class="searchInput" style="width: 170px;" placeholder="DO Kecil"/></th>
                    <th><input id="main_do_no" type="text" class="searchInput" placeholder="No DO Besar"/></th>
                    <th><input id="main_do_type" placeholder="Type DO Kecil" class="searchInput"/></th>
                    <th><input id="spo_no" placeholder="No SPO" class="searchInput"/></th>
                    <th><input id="spk_date" placeholder="Tanggal SPK" class="searchInput"/></th>
                    <th><input id="spk_no" placeholder="No SPK" class="searchInput"/></th>
                    <th><input id="contract_no" placeholder="No Kontrak" class="searchInput"/></th>
                    <th><input id="po_no" placeholder="No PO" class="searchInput"/></th>
                    <th><input id="contract_date" placeholder="Tanggal Kontrak" class="searchInput"/></th>
                    <th><input id="customer_name" placeholder="Nama Pelanggan" class="searchInput"/></th>
                    <th><input id="start_date" placeholder="Tanggal Mulai" class="searchInput"/></th>
                    <th><input id="finish_date" placeholder="Tanggal Selesai" class="searchInput"/></th>
                    <th><input id="route_name" placeholder="Nama Rute" class="searchInput"/></th>
                    <th><input id="route_from" placeholder="Dari" class="searchInput"/></th>
                    <th><input id="route_to" placeholder="Ke" class="searchInput"/></th>
                    <th><input id="seller_name" placeholder="Nama Penjual" class="searchInput"/></th>
                    <th><input id="material_name" placeholder="Nama Material" class="searchInput"/></th>
                    <th><input id="quantity" placeholder="Kuantitas" class="searchInput"/></th>
                    <th><input id="uom_name" placeholder="Uom" class="searchInput"/></th>
                    <th><input id="cost" placeholder="Biaya" class="searchInput"/></th>
                    <th><input id="charge_type" placeholder="Tipe Perhitungan" class="searchInput"/></th>
                    <th><input id="trip_type_name" placeholder="Tipe Perjalanan" class="searchInput"/></th>
                    <th><input id="ffa" placeholder="FFA" class="searchInput"/></th>
                    <th><input id="mi" placeholder="MI" class="searchInput"/></th>
                    <th><input id="kk" placeholder="KK" class="searchInput"/></th>
                    <th><input id="tolerance" placeholder="Toleransi" class="searchInput"/></th>
                    <th><input hidden class="searchInput"/></th>
                    <th><input id="remark" placeholder="Remark" class="searchInput"/></th>
                    <th><input hidden class="searchInput"/></th>
                    <th><input hidden class="searchInput"/></th>
                    <th><input hidden class="searchInput"/></th>
                    <th><input hidden class="searchInput"/></th>
                  </tr>
                </tfoot> -->
            </table>
      </div>
  </div>

</section>


<div bsModal #ModalSPK="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog" style="max-width: 90%;" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <!-- <div class="modal-title"><strong>{{action}} Data SPK</strong></div> -->
        <div class="modal-title"><strong>Input Data SPK</strong></div>
        <button type="button" class="close" (click)="ModalSPK.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">  
      <div [formGroup]="spkForm">      
      <div class="card">
          <div class="card-body">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-4"> 
                      <div class="form-group row group">
                          <label class="col-md-3 col-form-label" for="text-input">No DO</label>
                          <div class="col-md-8">
                              <input hidden type="text" class="form-control" [(ngModel)]="spkModel.id" [ngModelOptions]="{standalone: true}">
                              <input type="text" class="form-control" [(ngModel)]="spkModel.main_do_no" formControlName="main_do_no">
                          </div>
                      </div> 
                      <div class="form-group row">
                          <label class="col-md-3 col-form-label" for="text-input">No Kontrak</label>
                          <div class="col-md-8">
                              <input type="text" class="form-control" [(ngModel)]="spkModel.contract_no" formControlName="contract_no">
                          </div>
                      </div> 
                      <div class="form-group row">
                          <label class="col-md-3 col-form-label" for="text-input">No SPK</label>
                          <div class="col-md-8">
                              <input type="text" class="form-control" [(ngModel)]="spkModel.spk_no" (focusout)="setSPO()" formControlName="spk_no">
                          </div>
                      </div> 
                      <div class="form-group row">
                          <label class="col-md-3 col-form-label" for="text-input">Tanggal Mulai</label>
                          <div class="col-md-8">
                              <input type="date" class="form-control" [ngModel]="spkModel.start_date | date:'yyyy-MM-dd'" (ngModelChange)="spkModel.start_date = $event" formControlName="start_date">
                          </div>
                      </div> 
                      <div class="form-group row">
                          <label class="col-md-3 col-form-label" for="text-input">Pembeli</label>
                          <div class="col-md-7">
                            <input [(ngModel)]="spkModel.pembeli" [ngModelOptions]="{standalone: true}" disabled type="text" class="form-control">
                          </div>
                          <div class="col-md-2">
                            <button [disabled]="!pupuk" class="btn btn-sm btn-primary" (click)="showKebun();ModalKebun.show()"
                            data-toggle="modal" ><i class="fa fa-plus"></i></button>
                          </div>
                      </div>
                  </div>
  
                  <div class="col-md-4"> 
                      <div class="form-group row">
                          <label class="col-md-3 col-form-label" for="text-input">Tanggal DO</label>
                          <div class="col-md-8">
                              <input type="date" class="form-control" [ngModel]="spkModel.main_do_date | date:'yyyy-MM-dd'" (ngModelChange)="spkModel.main_do_date = $event"  formControlName="main_do_date">
                          </div>
                      </div> 
                      <div class="form-group row">
                          <label class="col-md-3 col-form-label" for="text-input">Tanggal Kontrak</label>
                          <div class="col-md-8">
                              <input type="date" class="form-control" [ngModel]="spkModel.contract_date | date:'yyyy-MM-dd'" (ngModelChange)="spkModel.contract_date = $event"  formControlName="contract_date">
                          </div>
                      </div> 
                      <div class="form-group row">
                          <label class="col-md-3 col-form-label" for="text-input">Tanggal SPK</label>
                          <div class="col-md-8">
                              <input type="date" class="form-control" [ngModel]="spkModel.spk_date | date:'yyyy-MM-dd'" (ngModelChange)="spkModel.spk_date = $event"  formControlName="spk_date">
                          </div>
                      </div> 
                      <div class="form-group row">
                          <label class="col-md-3 col-form-label" for="text-input">Tanggal Selesai</label>
                          <div class="col-md-8">
                              <input type="date" class="form-control" [ngModel]="spkModel.finish_date | date:'yyyy-MM-dd'" (ngModelChange)="spkModel.finish_date = $event"  formControlName="finish_date">
                          </div>
                      </div>   
                      <div class="form-group row">
                        <label class="col-md-3 col-form-label" for="text-input">Kebun</label>
                        <div class="col-md-8">
                            <input [(ngModel)]="spkModel.kebun" [ngModelOptions]="{standalone: true}" disabled type="text" class="form-control">
                        </div>
                     </div>
                  </div>
  
                  <div class="col-md-4"> 
                      <div class="form-group row">
                          <label class="col-md-3 col-form-label" for="text-input">Tipe DO Kecil</label>
                          <div class="col-md-8">
                            <select class="form-control" [(ngModel)]="spkModel.main_do_type" formControlName="main_do_type">
                              <option *ngFor="let row of DoType;  let i = index" [value]='row.id'><b>{{row.text}}</b></option>
                            </select>   
                          </div>
                      </div> 
                      <div class="form-group row">
                          <label class="col-md-3 col-form-label" for="text-input">No PO</label>
                          <div class="col-md-8">
                              <input type="text" class="form-control" [(ngModel)]="spkModel.po" formControlName="po">
                          </div>
                      </div> 
                      <div class="form-group row">
                          <label class="col-md-3 col-form-label" for="text-input">Tipe Perjalanan</label>
                          <div class="col-md-8">
                            <select  class="form-control" [(ngModel)]="spkModel.trip_type" formControlName="trip_type">
                              <option *ngFor="let row of TripType" [value]='row.id'><b>{{row.text}}</b></option>
                            </select>
                          </div>
                      </div> 
                      <div class="form-group row">
                          <label class="col-md-3 col-form-label" for="text-input">No SPO</label>
                          <div class="col-md-8">
                              <input type="text" class="form-control" [(ngModel)]="spkModel.spo_no" formControlName="spo_no">
                          </div>
                      </div> 
                      <div class="form-group row">
                          <label class="col-md-3 col-form-label" for="text-input">Jenis Pupuk</label>
                          <div class="col-md-8">
                              <input [disabled]="!pupuk" type="text" class="form-control" [(ngModel)]="spkModel.jenis_pupuk" formControlName="jenis_pupuk">
                          </div>
                      </div> 
                  </div> 
              </div>  
              </div>
            </div>
              
              <br>
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-4"> 
                      <div class="form-group row">
                          <label class="col-md-3 col-form-label" for="text-input">Material</label>
                          <input hidden disabled type="text" class="form-control">
                          <div class="col-md-7">
                              <input hidden [(ngModel)]="spkModel.material_id" disabled type="text" class="form-control" formControlName="material_id">
                              <input [(ngModel)]="materialName" [ngModelOptions]="{standalone: true}" disabled type="text" class="form-control">
                          </div>
                          <div class="col-md-2">
                              <!-- <button [disabled]="(action == 'Update')" class="btn btn-sm btn-primary" (click)="showMaterial();ModalMaterial.show()" -->
                              <button [disabled]="!edit" class="btn btn-sm btn-primary" (click)="showMaterial();ModalMaterial.show()"  
                              data-toggle="modal" ><i class="fa fa-plus"></i></button>
                          </div>
                      </div> 
                      <div class="form-group row">
                        <label class="col-md-3 col-form-label" for="text-input">Material Text</label>
                        <div class="col-md-8">
                            <input [(ngModel)]="spkModel.material_text" [ngModelOptions]="{standalone: true}" type="text" class="form-control" >
                        </div>
                      </div> 
                      </div>
                    <div class="col-md-4"> 
                        <div class="form-group row">
                            <label class="col-md-3 col-form-label" for="text-input">Rute</label>
                            <input hidden disabled type="text" class="form-control">
                            <div class="col-md-7">
                                <input hidden [(ngModel)]="spkModel.route_id" disabled type="text" class="form-control" formControlName="route_id">
                                <input [(ngModel)]="routeName" [ngModelOptions]="{standalone: true}" disabled type="text" class="form-control">
                            </div>
                            <div class="col-md-2">
                              <button [disabled]="!edit" class="btn btn-sm btn-primary" (click)="showRoute();ModalRoute.show()"
                              data-toggle="modal" ><i class="fa fa-plus"></i></button>
                                <!-- <button [disabled]="(action == 'Update')" class="btn btn-sm btn-primary" (click)="showRoute();ModalRoute.show()"
                                data-toggle="modal" ><i class="fa fa-plus"></i></button> -->
                            </div>
                        </div> 
                        <div class="form-group row">
                            <label class="col-md-3 col-form-label" for="text-input">Customer</label>
                            <input hidden disabled type="text" class="form-control">
                            <div class="col-md-7">
                              <input hidden [(ngModel)]="spkModel.customer_id" disabled type="text" class="form-control" formControlName="customer_id">
                              <input [(ngModel)]="customerName" [ngModelOptions]="{standalone: true}" disabled type="text" class="form-control">
                            </div>
                            <div class="col-md-2">
                                <button class="btn btn-sm btn-primary" (click)="showCustomer();ModalCustomer.show()"  
                                data-toggle="modal" ><i class="fa fa-plus"></i></button>
                            </div>
                        </div> 
                    </div>
                    <div class="col-md-4"> 
                        <div class="form-group row">
                            <label class="col-md-3 col-form-label" for="text-input">Penjual</label>
                            <input hidden disabled type="text" class="form-control">
                            <div class="col-md-7">
                                <input hidden [(ngModel)]="spkModel.seller_id" disabled type="text" class="form-control" formControlName="seller_id">
                                <input [(ngModel)]="supplierName" [ngModelOptions]="{standalone: true}" disabled type="text" class="form-control">
                            </div>
                            <div class="col-md-2">
                                <button class="btn btn-sm btn-primary" (click)="showSupplier();ModalSupplier.show()"  
                                data-toggle="modal" ><i class="fa fa-plus"></i></button>
                            </div>
                        </div> 
                        <div class="form-group row">
                            <label class="col-md-3 col-form-label" for="text-input">Ekspedisi</label>
                            <input hidden disabled type="text" class="form-control">
                            <div class="col-md-7">
                                <input hidden [(ngModel)]="spkModel.vendor_id" [ngModelOptions]="{standalone: true}" disabled type="text" class="form-control">
                                <input [(ngModel)]="ekspedisiName" [ngModelOptions]="{standalone: true}" disabled type="text" class="form-control">
                            </div>
                            <div class="col-md-2">
                                <button class="btn btn-sm btn-primary"  (click)="showEkspedisi();ModalEkspedisi.show()"
                                data-toggle="modal" ><i class="fa fa-plus"></i></button>
                            </div>
                        </div> 
                    </div>
                </div>
                </div>
              </div>
                  
            </div>
      </div>
      <div class="card">
          <div class="card-body">
              <tabset #tabset>
                  <tab heading="Perhitungan">
                      <div class="card">
                          <div class="card-body">
                              <div class="row">
                                  <div class="col-md-4"> 
                                      <div class="form-group row group">
                                          <label class="col-md-4 col-form-label" for="text-input">OA Rp</label>
                                          <div class="col-md-7">
                                              <input [(ngModel)]="spkModel.cost" currencyMask [options]="{ prefix: '', thousands: '.', decimal: ',' ,precision: '0',align: 'left' }" type="text" class="form-control" formControlName="cost">
                                          </div>
                                      </div> 
                                      <div class="form-group row">
                                          <label class="col-md-4 col-form-label" for="text-input">Partai</label>
                                          <div class="col-md-7">
                                              <input [(ngModel)]="spkModel.quantity" currencyMask [options]="{ prefix: '', thousands: '.', decimal: ',' ,precision: '0',align: 'left' }" type="text" class="form-control" formControlName="quantity">
                                          </div>
                                      </div> 
                                      <div class="form-group row">
                                          <label class="col-md-4 col-form-label" for="text-input">Jenis Perhitungan</label>
                                          <div class="col-md-7">
                                            <select [(ngModel)]="spkModel.charge_type" class="form-control" formControlName="charge_type">
                                              <option *ngFor="let row of ChargeType" [value]='row.id'><b>{{row.text}}</b></option>
                                            </select>
                                          </div>
                                      </div> 
                                  </div>
                  
                                  <div class="col-md-4"> 
                                      <div class="form-group row">
                                          <label class="col-md-4 col-form-label" for="text-input">UOM</label>
                                          <div class="col-md-7">
                                            <select [(ngModel)]="spkModel.uom" class="form-control" formControlName="uom">
                                              <option *ngFor="let row of UOM" [value]='row.id'><b>{{row.text}}</b></option>
                                            </select>
                                          </div>
                                      </div> 
                                      <div class="form-group row">
                                          <label class="col-md-4 col-form-label" for="text-input">Perhitungan Invoice</label>
                                          <div class="col-md-7">
                                            <select [(ngModel)]="spkModel.invoice_type" class="form-control" formControlName="invoice_type">
                                              <option *ngFor="let row of InvoiceCalcType" [value]='row.id'><b>{{row.text}}</b></option>
                                            </select>
                                          </div>
                                      </div> 
                                      <div class="form-group row">
                                          <label class="col-md-4 col-form-label" for="text-input">FFA</label>
                                          <div class="col-md-7">
                                              <input [(ngModel)]="spkModel.ffa" currencyMask [options]="{ prefix: '', thousands: '.', decimal: ',' ,precision: '2',align: 'left' }" type="text" class="form-control" formControlName="ffa">
                                          </div>
                                      </div> 
                                  </div>
                  
                                  <div class="col-md-4"> 
                                      <div class="form-group row">
                                          <label class="col-md-4 col-form-label" for="text-input">M & I</label>
                                          <div class="col-md-7">
                                              <input [(ngModel)]="spkModel.mi" currencyMask [options]="{ prefix: '', thousands: '.', decimal: ',' ,precision: '2',align: 'left' }" type="text" class="form-control" formControlName="mi">
                                          </div>
                                      </div> 
                                      <div class="form-group row">
                                          <label class="col-md-4 col-form-label" for="text-input">KK</label>
                                          <div class="col-md-7">
                                              <input [(ngModel)]="spkModel.kk" currencyMask [options]="{ prefix: '', thousands: '.', decimal: ',' ,precision: '2',align: 'left' }" type="text" class="form-control" formControlName="kk">
                                          </div>
                                      </div> 
                                      <div class="form-group row">
                                          <label class="col-md-4 col-form-label" for="text-input">Toleransi</label>
                                          <div class="col-md-7">
                                              <input [(ngModel)]="spkModel.tolerance" currencyMask [options]="{ prefix: '', thousands: '.', decimal: ',' ,precision: '2',align: 'left' }" type="text" class="form-control" formControlName="tolerance">
                                          </div>
                                      </div> 
                                  </div> 
                              </div> 

                          </div>
                      </div> 
                  </tab>
                  <tab heading="Tambahan Biaya">
                      <div class="card">
                          <!-- <div class="card-header">
                          <strong>Data Tambahan</strong>
                         </div> -->
                          <div class="card-body">
                              <div class="row">
                                  <div class="col-md-4"> 
                                      <div class="form-group row">
                                          <label class="col-md-4 col-form-label" for="text-input">Nama Biaya</label>
                                          <div class="col-md-7">
                                            
                                            <!-- <input  [(ngModel)]="modelCostType" [ngModelOptions]="{standalone: true}" (focusout)="selectCost(modelCostType)"  list="menace" name="menace" class="form-control">
                                            <datalist id="menace">
                                            <option *ngFor="let row of ListCostType" [ngValue]="row">{{row.name}} </option>
                                            </datalist> -->
                                            <!-- <input hidden [(ngModel)]="tambahanModel.cost_type" [ngModelOptions]="{standalone: true}" type="text" class="form-control" > -->
                                            <select [(ngModel)]="modelCostType" [ngModelOptions]="{standalone: true}" (ngModelChange)="selectCost($event)" class="form-control" >
                                              <option *ngFor="let row of ListCostType" [ngValue]="row"><b>{{row.name}}</b></option>
                                            </select>
                                          </div>
                                      </div> 
                                  </div>
                                  <div class="col-md-4"> 
                                      <div class="form-group row">
                                          <label class="col-md-4 col-form-label" for="text-input">Jumlah</label>
                                          <div class="col-md-7">
                                              <input [(ngModel)]="tambahanModel.amount" [ngModelOptions]="{standalone: true}" type="text" class="form-control" >
                                          </div>
                                      </div> 
                                  </div>
                                  <div class="col-md-4"> 
                                      <div class="form-group row">
                                          <label class="col-md-4 col-form-label" for="text-input">Jenis Perhitungan</label>
                                          <div class="col-md-7">
                                              <input hidden [(ngModel)]="tambahanModel.charge_type" [ngModelOptions]="{standalone: true}" type="text" class="form-control" >
                                              <select [(ngModel)]="model" [ngModelOptions]="{standalone: true}" (ngModelChange)="selectChargeType($event)" class="form-control" >
                                                <option *ngFor="let row of ChargeType" [ngValue]="row"><b>{{row.text}}</b></option>
                                              </select>
                                          </div>
                                      </div> 
                                  </div>
                              </div>
                              <br>
                              <div style="text-align: center">
                                  <button type="button" (click)="AddDataTambahan();" class="btn btn-sm btn-primary"><i class="fa fa-pen"></i>&nbsp;&nbsp;<b>Tambah</b>&nbsp;&nbsp;</button>
                              </div>
                              <br>
                              <br>
                              <div class="card">
                                  <div class="card-body">
                                      <table class="table table-striped table-sm">
                                          <thead>
                                          <tr>
                                              <th>Nama Biaya</th>
                                              <th>Jumlah</th>
                                              <th>Jenis Perhitungan</th>
                                              <th>Action</th>
                                          </tr>
                                          </thead>
                                          <tbody>
                                          <tr *ngFor="let row of filterCategory(dataTambahanSave); let i=index">
                                              <td>{{row.costName}}</td>
                                              <td>{{row.amount}}</td>
                                              <td>{{row.costType}}</td>
                                              <td><button class="btn btn-sm btn-danger" triggers="mouseenter:mouseleave" (click)="deleteAsset(i);"><i class="fa fa-trash"></i></button></td>
                                          </tr>
                                          </tbody>
                                      </table>
                                  </div>    
                              </div>
                          </div>
                      </div>
                  </tab>
                  <tab heading="Remark Operasional">
                      <div class="card">
                          <div class="card-body">
                              <div class="col-md-12">
                                  <div class="form-group row">
                                      <label class="col-md-1 col-form-label" for="text-input">Remark SPK</label>
                                      <div class="col-md-11">
                                          <textarea [(ngModel)]="spkModel.remark" rows="6" type="text" class="form-control" formControlName="remark"></textarea>
                                      </div>
                                  </div> 
                              </div>
                          </div>
                      </div>
                  </tab>
              </tabset>
          </div>
      </div>
      </div>
      </div>
      <div class="modal-footer">
          <button #modalSaveClose type="button" class="btn btn-secondary" (click)="ModalSPK.hide()">&nbsp;<b>Close</b>&nbsp;</button>
          <!-- <button [disabled]="complete" type="button" class="btn btn-primary"  (click)="saveDataSPK();"><i class="fa fa-save"></i>&nbsp;&nbsp;<b>Save</b>&nbsp;&nbsp;</button> -->
          <button [disabled]="see" type="button" class="btn btn-primary" (click)="saveDataSPK();"><i class="fa fa-save"></i>&nbsp;&nbsp;<b>Save</b>&nbsp;&nbsp;</button>
          </div>
    </div>
  </div>
</div>



<div  bsModal #ModalKebun="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog  modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title">
          <div class="row">
            <strong>Pilih Pembeli</strong>
          </div>
        </div>
        <button type="button" class="close" (click)="ModalKebun.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
          <!-- <app-master-kebun></app-master-kebun> -->
          <table datatable  [dtOptions]="dtOptionsKebun" [dtTrigger]="dtTriggerKebun"  class="table-striped deeps">
            <thead>
              <tr>
                <th>Action</th>
                <th>Kode</th>
                <th>Nama</th>
                <th>Lokasi</th>
                <th>Alamat</th>
                <th>Telepon</th>
                <th>Fax</th>
                <th>Dibuat Oleh</th>
                <th>Dibuat Pada</th>
                <th>Diubah Oleh</th>
                <th>Diubah Pada</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let row of dataKebun">    
                <td>
                 <button (click)="onSelectKebun(row);ModalKebun.hide()" class="btn btn-sm btn-primary" title="Pilih Data" triggers="mouseenter:mouseleave"><i class="fa fa-check"></i></button>
                </td>       
                <td>{{row.kode}}</td>
                <td>{{row.nama}}</td>
                <td>{{row.lokasi}}</td>
                <td>{{row.alamat}}</td>
                <td>{{row.telepon}}</td>
                <td>{{row.fax}}</td>
                <td>{{row.createdby}}</td>
                <td>{{row.created_at | slice:0:10}}</td>  
                <td>{{row.updatedby}}</td>  
                <td>{{row.updated_at | slice:0:10}}</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th><input hidden class="searchKebun"/></th>
                <th><input id="codeKebun" type="text" class="searchKebun" placeholder="Kode"/></th>
                <th><input id="nameKebun" type="text" class="searchKebun" placeholder="Nama Kebun"/></th>
                <th><input id="locationKebun" type="text" class="searchKebun" placeholder="Lokasi"/></th>
                <th><input hidden class="searchKebun"/></th>
                <th><input hidden class="searchKebun"/></th>
                <th><input hidden class="searchKebun"/></th>
                <th><input hidden class="searchKebun"/></th>
                <th><input hidden class="searchKebun"/></th>
                <th><input hidden class="searchKebun"/></th>
              </tr>
            </tfoot>
          </table>
      </div>
    </div>
  </div>
</div>


<div bsModal #ModalEkspedisi="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog  modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title">
          <div class="row">
            <strong>Pilih Ekspedisi</strong>
          </div>
        </div>
        <button type="button" class="close" (click)="ModalEkspedisi.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
          <!-- <app-master-relation></app-master-relation> -->
          <table datatable [dtOptions]="dtOptionsEkspedisi" [dtTrigger]="dtTriggerEkspedisi" class="table-striped deeps">
              <thead>
                <tr>
                  <th>Action</th>
                  <th>Kode</th>
                  <th>Nama</th>
                  <th>Alamat</th>
                  <th>Kode SL</th>
                  <th>Phone</th>
                  <th>Active</th>
                  <th>Dibuat Oleh</th>
                  <th>Dibuat Pada</th>
                  <th>Diubah Oleh</th>
                  <th>Diubah Pada</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let row of dataEkspedisi">      
                  <td style="width: 200;">
                    <button (click)="onSelectEkspedisi(row);ModalEkspedisi.hide()" title="Edit"  class="btn btn-sm btn-primary" data-toggle="modal" triggers="mouseenter:mouseleave"><i class="fa fa-check"></i></button>
                  </td>  
                  <td>{{row.relation_code}}</td>
                  <td>{{row.relation_name}}</td>
                  <td>{{row.relation_address}}</td>
                  <td>{{row.relation_sl_code}}</td>
                  <td>{{row.relation_phone}}</td>
                  <td>{{row.active}}</td>
                  <td>{{row.createdby}}</td>
                  <td>{{row.created_at | slice:0:10}}</td>  
                  <td>{{row.updatedby}}</td>  
                  <td>{{row.updated_at | slice:0:10}}</td>   
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th><input hidden class="searchEkspedisi"/></th>
                  <th><input id="codeEks" type="text" class="searchEkspedisi" placeholder="Kode"/></th>
                  <th><input id="nameEks" type="text" class="searchEkspedisi" placeholder="Nama"/></th>
                  <th><input id="addressEks" type="text" class="searchEkspedisi" placeholder="Alamat"/></th>
                  <th><input id="slEks" type="text" class="searchEkspedisi" placeholder="Kode SL"/></th>
                  <th><input hidden class="searchEkspedisi"/></th>
                  <th><input hidden class="searchEkspedisi"/></th>
                  <th><input hidden class="searchEkspedisi"/></th>
                  <th><input hidden class="searchEkspedisi"/></th>
                  <th><input hidden class="searchEkspedisi"/></th>
                  <th><input hidden class="searchEkspedisi"/></th>
                </tr>
              </tfoot>
    
            </table>
      </div>
    </div>
  </div>
</div>





<div bsModal #ModalDetailDo="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog" style="max-width: 90%;" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title">
          <div class="row">
            <strong>Detail DO</strong>
          </div>
        </div>
        <button type="button" class="close" (click)="ModalDetailDo.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-6"> 
                <div class="form-group row">
                  <label class="col-md-3 col-form-label" for="text-input">Customer</label>
                  <div class="col-md-8">
                      <input hidden type="text" class="form-control" [(ngModel)]="generateDoModel.main_do_id" [ngModelOptions]="{standalone: true}">
                      <input disabled value="{{customerName}}" type="text" class="form-control">
                  </div>
                </div> 
                <div class="form-group row">
                  <label class="col-md-3 col-form-label" for="text-input">No DO Besar</label>
                  <div class="col-md-8">
                      <input disabled value="{{spkModel.main_do_no}}" type="text" class="form-control">
                  </div>
                </div> 
                <div class="form-group row">
                  <label class="col-md-3 col-form-label" for="text-input">Partai</label>
                  <div class="col-md-8">
                      <input disabled value="{{spkModel.quantity | number}}" type="text" class="form-control" formControlName="quantity">
                  </div>
                </div> 
              </div>
              <div class="col-md-6"> 
                <div class="form-group row">
                  <label class="col-md-3 col-form-label" for="text-input">No SPK</label>
                  <div class="col-md-8">
                      <input disabled value="{{spkModel.spk_no}}" type="text" class="form-control">
                  </div>
                </div> 
                <div class="form-group row">
                  <label class="col-md-3 col-form-label" for="text-input">No Contract</label>
                  <div class="col-md-8">
                      <input disabled value="{{spkModel.contract_no}}" type="text" class="form-control">
                  </div>
                </div> 
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <div class="card-header">
              <!-- <strong>List DO</strong> -->
              <!-- <div class="row">
                <div class="col-md-2">
                  <div class="form-group row group">
                    <label class="col-md-3">Status DO</label>
                    <div class="col-md-9">
                      <select>
                        <option>Tanggal Kirim</option>
                        <option>Tanggal Terima</option>
                        <option>Tanggal Selesai</option>
                      </select>
                    </div>
                  </div>  
                </div>
              </div> -->
              <select [(ngModel)]="filter" (ngModelChange)="sort($event)">
                <option value="All">All</option>
                <option value="New">New</option>
                <option value="Delivery Completed">Delivery Completed</option>
                <option value="Invoice Generated">Invoice Generated</option>
                <option value="Payment Received">Payment Received</option>
                <option value="Printed">Printed</option>
                <option value="Realization">Realization</option>
                <option value="Closed">Closed</option>
                <option value="DO Distributed">DO Distributed</option>
              </select>&nbsp;&nbsp;&nbsp;
              <input type="text" (keyup)="filterDo($event)" placeholder="Cari DO Kecil" />
              <div class="float-right">
                <button [hidden]="!printDoEkspedisi" class="btn btn-sm btn-warning pull-right" data-toggle="modal" (click)="printDOSelectedEkspedisi()">&nbsp;&nbsp;Print DO Ekspedisi&nbsp;&nbsp;<i class="fa fa-print"></i>&nbsp;&nbsp;&nbsp;</button>&nbsp;&nbsp;
                <button [hidden]="!btnDetailPrintPonti" class="btn btn-sm btn-warning pull-right" data-toggle="modal" (click)="printDOSelected()">&nbsp;&nbsp;Print DO&nbsp;&nbsp;<i class="fa fa-print"></i>&nbsp;&nbsp;&nbsp;</button>&nbsp;&nbsp;
                <button [hidden]="!btnDetailPrintPonti" class="btn btn-sm btn-primary pull-right" data-toggle="modal" (click)="exportAsXLSX()">&nbsp;&nbsp;Export DO&nbsp;&nbsp;<i class="fa fa-file-excel"></i>&nbsp;&nbsp;&nbsp;</button>&nbsp;&nbsp;
                <button [hidden]="!btnDetailUpdate"  class="btn btn-sm btn-primary pull-right" data-toggle="modal" (click)="openEstimasiList();">&nbsp;&nbsp;Update Estimasi List DO&nbsp;&nbsp;<i class="fa fa-calculator"></i>&nbsp;&nbsp;&nbsp;</button>&nbsp;&nbsp;
                <button [disabled]="!ekspedisi" class="btn btn-sm btn-primary pull-right" data-toggle="modal" (click)="ModalManual.show();">&nbsp;&nbsp;Create DO Manual&nbsp;&nbsp;<i class="fa fa-pen"></i>&nbsp;&nbsp;&nbsp;</button>&nbsp;&nbsp;
                <button [disabled]="ekspedisi" class="btn btn-sm btn-primary pull-right" data-toggle="modal" (click)="setNullGenerate();ModalGenerate.show();showCapacity();">&nbsp;&nbsp;Generate DO&nbsp;&nbsp;<i class="fa fa-pen"></i>&nbsp;&nbsp;&nbsp;</button>
              </div>
            </div>
            <div class="scroll">
              
            <table datatable [dtOptions]="dtOptionsDetailDo" [dtTrigger]="dtTriggerDetail" class="table-striped">
              <thead>
                <tr>
                  <th>Action</th>
                  <th>Select <input [checked]="checkAllDO" (change)="checkAll()" type="checkbox"/></th>
                  <th>No</th>
                  <th>No DO</th>
                  <th>Tanggal Terbit</th>
                  <th>Status DO</th>
                  <th>Approve</th>
                  <th>Print</th>
                  <th>Timbangan</th>
                  <th>Truck</th>
                  <th>Ekspedisi</th>
                  <th>QTY Kirim</th>
                  <th>QTY Terima</th>
                  <th>Sack Kirim</th>
                  <th>Sack Terima</th>
                  <th>Estimasi Berat</th>
                  <th>Estimasi Kemasan</th>
                  <th>Delivery Completed</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let row of listDoDetail; let i=index">    
                  <td>
                    <button [hidden]="!btnDetailUpdate" (click)="onSelectUpdateEstimation(row);ModalEstimasi.show()" title="Edit Estimasi" class="btn btn-sm btn-primary" triggers="mouseenter:mouseleave"><i class="fa fa-calculator"></i></button>&nbsp;
                    <button [hidden]="!btnDetailClose"  [disabled]="(row.status_text !='DO Distributed')"   (click)="onSelectCancel(row);ModalCancel.show()" title="Tutup Transaksi" class="btn btn-sm btn-primary" triggers="mouseenter:mouseleave"><i class="fa fa-window-close"></i></button>&nbsp;
                    <!-- <button [hidden]="!btnCrudDO"  [disabled]="(row.status_text!='DO Distributed' && row.status_text!='New')" (click)="printDo(row)" title="Cetak DO" class="btn btn-sm btn-primary" triggers="mouseenter:mouseleave"><i class="fa fa-print"></i></button>&nbsp; -->
                    <button [hidden]="!btnDetailDelete"  [disabled]="(row.status_text !='New')" (click)="deleteDo(row)" title="Hapus DO" class="btn btn-sm btn-danger"><i class="fa fa-trash"></i></button>&nbsp;
                    <button [hidden]="!btnDetailPrint" [disabled]="(row.status_text!='DO Distributed' && row.status_text!='New') || (row.vendor == null)" (click)="printDo(row)" title="Cetak DO" class="btn btn-sm btn-primary" triggers="mouseenter:mouseleave"><i class="fa fa-print"></i></button>&nbsp;
                    <button [hidden]="!btnDetailPrintPonti" [disabled]="(row.status_text!='DO Distributed' && row.status_text!='New')" (click)="printDo(row)" title="Cetak DO" class="btn btn-sm btn-primary" triggers="mouseenter:mouseleave"><i class="fa fa-print"></i></button>&nbsp;
                    <button [hidden]="btnDetailUpdate == false || ekspedisi == false" (click)="onSelectUpdateDoNo(row);ModalUpdateNoDO.show()" title="Ubah No DO" class="btn btn-sm btn-primary" triggers="mouseenter:mouseleave"><i class="fa fa-pen"></i></button>
                  </td> 
                  <td><input [checked]="row.select" type="checkbox" (change)="checkSelected(row)"/></td> 
                  <td>{{i+1}}</td>
                  <td>{{row.do_no}}</td>
                  <td>{{row.do_date | slice:0:10}}</td>
                  <td>{{row.status_text}}</td>
                  <td><input disabled type="checkbox" [checked]="row.approve_status == true || row.approve_status == null"/></td>
                  <td><input disabled type="checkbox" [checked]="row.print_status"/></td>
                  <td>{{row.reff}}</td>
                  <td>{{row.truck}}</td>
                  <td>{{row.relation_name}}</td>
                  <td>{{row.sendQty}}</td>
                  <td>{{row.rcvQty}}</td>
                  <td>{{row.packSend}}</td>
                  <td>{{row.packRcv}}</td>
                  <td>{{row.est_ton}}</td>
                  <td>{{row.est_pack}}</td>
                  <td><input disabled type="checkbox" [checked]="row.delivery_date != null"/></td>
                </tr>
              </tbody>
            </table>
            </div>
            <br><br>
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-4"> 
                      <div class="form-group row group">
                          <label class="col-md-4 col-form-label" for="text-input">Total Realisasi Kirim</label>
                          <label class="col-md-3 col-form-label" for="text-input">{{qtySend | number}}</label>
                      </div> 
                  </div>
                  <div class="col-md-4"> 
                    <div class="form-group row group">
                      <label class="col-md-4 col-form-label" for="text-input">Total Estimasi</label>
                      <label class="col-md-3 col-form-label" for="text-input">{{estTon | number}}</label>
                    </div> 
                  </div>
                  <div class="col-md-4"> 
                    <div class="form-group row group">
                      <label class="col-md-4 col-form-label" for="text-input">R. Kemasan Kirim</label>
                      <label class="col-md-3 col-form-label" for="text-input">{{rPcakSend | number}}</label>
                    </div> 
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4"> 
                      <div class="form-group row group">
                        <label class="col-md-4 col-form-label" for="text-input">Total Realisasi Terima</label>
                        <label class="col-md-3 col-form-label" for="text-input">{{qtyReceived | number}}</label>
                      </div> 
                  </div>
                  <div class="col-md-4"> 
                    <div class="form-group row group">
                      <label class="col-md-4 col-form-label" for="text-input">Estimasi Kemasan</label>
                      <label class="col-md-3 col-form-label" for="text-input">{{estPack | number}}</label>
                    </div> 
                  </div>
                  <div class="col-md-4"> 
                    <div class="form-group row group">
                      <label class="col-md-4 col-form-label" for="text-input">R. Kemasan Terima</label>
                      <label class="col-md-3 col-form-label" for="text-input">{{rPackReceived | number}}</label>
                    </div> 
                  </div>
                </div>
              </div>
            </div>
            
          </div>
          <!-- <div class="modal-footer">
            <button type="button" class="btn btn-secondary" (click)="ModalDetailDo.hide()"><b>Close</b></button>
          </div> -->
        </div>
          
      </div>
    </div>
  </div>
</div>

<div  bsModal #ModalListEstimasi="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog" style="max-width: 35%;" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title"><strong>*Ubah Perkiraan Pengiriman</strong></div>
        <button type="button" class="close" #modalCloseEstList (click)="ModalListEstimasi.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
          <div class="form-group row">
            <label class="col-md-3 col-form-label" for="text-input">Perkiraan Berat</label>
            <div class="col-md-9">
              <input type="text" class="form-control" [(ngModel)]="updateEstimationListModel.est_ton" currencyMask [options]="{ prefix: '', thousands: '.', decimal: ',' ,precision: '0',align: 'left' }">
          </div>
          </div> 
          <div class="form-group row">
            <label class="col-md-3 col-form-label" for="text-input">Perkiraan Kemasan</label>
            <div class="col-md-9">
              <input type="text" class="form-control" [(ngModel)]="updateEstimationListModel.est_pack" currencyMask [options]="{ prefix: '', thousands: '.', decimal: ',' ,precision: '0',align: 'left' }">
          </div>
          </div> 
          <div class="form-group row">
            <label class="col-md-3 col-form-label" for="text-input">Satuan Kemasan</label>
            <div class="col-md-9">
              <select class="form-control" [(ngModel)]="updateEstimationListModel.est_pack_uom">
                <option *ngFor="let row of UOM" [value]='row.id'><b>{{row.text}}</b></option>
              </select>
            </div>
          </div> 
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="ModalListEstimasi.hide()"><b>Close</b></button>
        <button type="button" class="btn btn-primary" (click)="updateEstimationList()"><i class="fa fa-save"></i>&nbsp;&nbsp;<b>Update Estimasi</b>&nbsp;&nbsp;</button>
      </div>
    </div>
  </div>
  </div>

<div  bsModal #ModalEstimasi="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
<div class="modal-dialog" style="max-width: 35%;" role="document">
  <div class="modal-content">
    <div class="modal-header">
      <div class="modal-title"><strong>Ubah Perkiraan Pengiriman</strong></div>
      <button type="button" class="close" #modalCloseEst (click)="ModalEstimasi.hide()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <div class="form-group row">
          <label class="col-md-3 col-form-label" for="text-input">Perkiraan Berat</label>
          <div class="col-md-9">
            <input type="text" class="form-control" [(ngModel)]="updateEstimationModel.est_ton" currencyMask [options]="{ prefix: '', thousands: '.', decimal: ',' ,precision: '0',align: 'left' }">
        </div>
        </div> 
        <div class="form-group row">
          <label class="col-md-3 col-form-label" for="text-input">Perkiraan Kemasan</label>
          <div class="col-md-9">
            <input type="text" class="form-control" [(ngModel)]="updateEstimationModel.est_pack" currencyMask [options]="{ prefix: '', thousands: '.', decimal: ',' ,precision: '0',align: 'left' }">
        </div>
        </div> 
        <div class="form-group row">
          <label class="col-md-3 col-form-label" for="text-input">Satuan Kemasan</label>
          <div class="col-md-9">
            <select class="form-control" [(ngModel)]="updateEstimationModel.est_pack_uom">
              <option *ngFor="let row of UOM" [value]='row.id'><b>{{row.text}}</b></option>
            </select>
          </div>
        </div> 
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="ModalEstimasi.hide()"><b>Close</b></button>
      <button type="button" class="btn btn-primary" (click)="updateEstimation()"><i class="fa fa-save"></i>&nbsp;&nbsp;<b>Save</b>&nbsp;&nbsp;</button>
    </div>
  </div>
</div>
</div>

<div  bsModal #ModalGenerate="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
<div class="modal-dialog" style="max-width: 35%;" role="document">
  <div class="modal-content">
    <div class="modal-header">
      <div class="modal-title"><strong>Generate DO</strong></div>
      <button type="button" #ModalGenerateHide class="close" (click)="ModalGenerate.hide()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <div class="form-group row">
          <label class="col-md-3 col-form-label" for="text-input">Sisa Partai</label>
          <div class="col-md-9">
            <input value="{{labelCalculate}}" disabled type="text" class="form-control">
          </div>
        </div> 
        <div class="form-group row">
          <label class="col-md-3 col-form-label" for="text-input">Planning Kapasitas Mobil</label>
          <input hidden disabled type="text" class="form-control">
          <div class="col-md-7">
            <input hidden disabled type="text" class="form-control">
            <input [(ngModel)]="capacityTon" [ngModelOptions]="{standalone: true}" disabled type="text" class="form-control">
          </div>
          <div class="col-md-2">
              <button class="btn btn-sm btn-primary" (click)="showCapacity();ModalCapacity.show()"  
              data-toggle="modal"><i class="fa fa-plus"></i></button>
          </div>
        </div> 
        <div class="form-group row">
          <label class="col-md-3 col-form-label" for="text-input">Banyak DO</label>
          <div class="col-md-9">
            <input [(ngModel)]="generateDoModel.do_count" type="text" class="form-control">
          </div>
        </div> 
        <div class="form-group row">
          <label class="col-md-3 col-form-label" for="text-input">Tanggal DO</label>
          <div class="col-md-9">
            <input [ngModel]="generateDoModel.do_date | date:'yyyy-MM-dd'" (ngModelChange)="generateDoModel.do_date = $event"  type="date" class="form-control">
          </div>
        </div> 
        
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="ModalGenerate.hide()"><b>Close</b></button>
      <button (click)="generateDO()" type="button" class="btn btn-primary"><i class="fa fa-save"></i>&nbsp;&nbsp;<b>Save</b>&nbsp;&nbsp;</button>
    </div>
  </div>
</div>
</div>

<div  bsModal #ModalCapacity="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
<div class="modal-dialog" style="max-width: 35%;" role="document">
  <div class="modal-content">
    <div class="modal-header">
      <div class="modal-title"><strong>Pilih Kapasitas Mobil</strong></div>
      <button type="button" class="close" (click)="ModalCapacity.hide()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <table datatable  [dtOptions]="dtOptionsCapacity" [dtTrigger]="dtTriggerCapacity"  class="table-striped deeps">
        <thead>
          <tr>
            <th>Action</th>
            <th>Kode</th>
            <th>Keterangan</th>
            <th>Tipe Kendaraan</th>
            <th>Tonnase</th>
            <th>Diubah Oleh</th>
            <th>Diubah Pada</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let row of dataCapacity">
            <td>
              <button (click)="onSelectCapacity(row);ModalCapacity.hide()" class="btn btn-sm btn-primary"  data-toggle="modal" triggers="mouseenter:mouseleave"><i class="fa fa-check"></i></button>
            </td> 
            <td>{{row.capacity_code}}</td>
            <td>{{row.capacity_note}}</td>
            <td>{{row.vehicleType}}</td>
            <td>{{row.tonCode}}</td>
            <td>{{row.updatedby}}</td>  
            <td>{{row.updated_at | slice:0:10}}</td>    
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <th><input hidden class="searchInputCapacity"/></th>
            <th><input id="codecapacity" type="text" class="searchInputCapacity" placeholder="Kode" /></th>
            <th><input id="namecapacity" type="text" class="searchInputCapacity" placeholder="Nama" /></th>
            <th><input id="vehiclesearch" type="text" class="searchInputCapacity" placeholder="Kendaraan"/></th>
            <th><input hidden class="searchInputCapacity"/></th>
            <th><input hidden class="searchInputCapacity"/></th>
            <th><input hidden class="searchInputCapacity"/></th>
          </tr>
        </tfoot>
      </table>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="ModalCapacity.hide()"><b>Close</b></button>
      <button type="button" class="btn btn-primary"><i class="fa fa-save"></i>&nbsp;&nbsp;<b>Save</b>&nbsp;&nbsp;</button>
    </div>
  </div>
</div>
</div>



<div  bsModal #ModalCancel="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog" style="max-width: 75%;" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title"><strong>Alasan Cancel</strong></div>
        <button #ModalCancelHide type="button" class="close" (click)="ModalCancel.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">

        <div class="card">
          <div class="card-body">
            <table datatable [dtOptions]="dtOptionsCancel" [dtTrigger]="dtTriggerCancel" class="deeps table-striped">
              <thead>
                <tr>
                  <th>Payment No</th>
                  <th>Payment Date</th>
                  <th>Jumlah</th>
                  <th>Potongan</th>
                  <th>Diterima</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let row of dataListCancelPayment">
                  <td>{{row.trx_payment_no}}</td>
                  <td>{{row.payment_date | slice:0:10}}</td>
                  <td>Rp. {{row.total | number}}</td>
                  <td>Rp. {{row.debt | number}}</td>
                  <td>Rp. {{row.total_paid | number}}</td>
                </tr>
              </tbody>

            </table>
          </div>
        </div>

          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-6">
                  <!-- <div class="form-group row">
                    <label class="col-md-4 col-form-label" for="text-input">Total Yang Telah Dibayarkan</label>
                    <div class="col-md-8">
                      <input value="{{amountTotalCancel | number}}" disabled type="text" class="form-control">
                    </div>
                  </div> -->
                  <div class="form-group row">
                    <label class="col-md-4 col-form-label" for="text-input">Tanggungan Perusahaan</label>
                    <div class="col-md-8">
                      <input [(ngModel)]="companyCost" [ngModelOptions]="{standalone: true}" (ngModelChange)="onTypeAmount()" currencyMask [options]="{ prefix: '', thousands: '.', decimal: ',' ,precision: '0',align: 'left' }" type="text" class="form-control">
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-md-4 col-form-label" for="text-input">Dikembalikan Supir</label>
                    <div class="col-md-8">
                      <input [(ngModel)]="dirverReturn" [ngModelOptions]="{standalone: true}" (ngModelChange)="onTypeAmount()" currencyMask [options]="{ prefix: '', thousands: '.', decimal: ',' ,precision: '0',align: 'left' }" type="text" class="form-control">
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-md-4 col-form-label" for="text-input">Hutang Supir</label>
                    <div class="col-md-8">
                      <input value="{{driverDebtCancel | number}}" disabled type="text" class="form-control">
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group row">
                    <label class="col-md-3 col-form-label" for="text-input">No Pembayaran</label>
                    <div class="col-md-9">
                      <input disabled type="text" class="form-control">
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-md-3 col-form-label" for="text-input">Tanggal bayar</label>
                    <div class="col-md-9">
                      <input [ngModel]="addCloseModel.payDate | date:'yyyy-MM-dd'" (ngModelChange)="addCloseModel.payDate = $event"  type="date" class="form-control">
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-md-3 col-form-label" for="text-input">No BA</label>
                    <div class="col-md-9">
                      <input [(ngModel)]="addCloseModel.baNo"  type="text" class="form-control">
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-md-3 col-form-label" for="text-input">Alasan</label>
                    <div class="col-md-9">
                      <textarea [(ngModel)]="addCloseModel.reason" rows="4" type="text" class="form-control"></textarea>
                    </div>
                  </div>
                </div>
                </div>
            </div>
          </div>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="ModalCancel.hide()"><b>Close</b></button>
        <button type="button" class="btn btn-primary" (click)="saveCancel()"><i class="fa fa-save"></i>&nbsp;&nbsp;<b>Save</b>&nbsp;&nbsp;</button>
      </div>
    </div>
  </div>
</div>


<div  bsModal #ModalCancel="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog" style="max-width: 75%;" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title"><strong>Alasan Cancel</strong></div>
        <button type="button" class="close" (click)="ModalCancel.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
<!-- 
        <div class="card">
          <div class="card-body">
            <table datatable [dtOptions]="dtOptionsCancel" [dtTrigger]="dtTrigger" class="table-striped">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Payment No</th>
                  <th>Payment Date</th>
                  <th>Jumlah</th>
                  <th>Potongan</th>
                  <th>Diterima</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let row of dataEkspedisi">      
                  <td>{{row.relation_code}}</td>
                  <td>{{row.relation_name}}</td>
                  <td>{{row.relation_address}}</td>
                  <td>{{row.relation_sl_code}}</td>
                  <td>{{row.relation_phone}}</td>
                  <td>{{row.active}}</td>
                </tr>
              </tbody>
    
            </table>
          </div>
        </div>
        <br>
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group row">
                  <label class="col-md-3 col-form-label" for="text-input">Total Yang Telah Dibayarkan</label>
                  <div class="col-md-9">
                    <input [(ngModel)]="capacityTon" disabled type="text" class="form-control">
                  </div>
                </div> 
                <div class="form-group row">
                  <label class="col-md-3 col-form-label" for="text-input">Tanggungan Perusahaan</label>
                  <div class="col-md-9">
                    <input [(ngModel)]="capacityTon" type="text" class="form-control">
                  </div>
                </div> 
                <div class="form-group row">
                  <label class="col-md-3 col-form-label" for="text-input">Dikembalikan Supir</label>
                  <div class="col-md-9">
                    <input [(ngModel)]="capacityTon" type="text" class="form-control">
                  </div>
                </div> 
                <div class="form-group row">
                  <label class="col-md-3 col-form-label" for="text-input">Hutang Supir</label>
                  <div class="col-md-9">
                    <input [(ngModel)]="capacityTon" disabled type="text" class="form-control">
                  </div>
                </div> 
              </div>
              <div class="col-md-6">
                <div class="form-group row">
                  <label class="col-md-3 col-form-label" for="text-input">No Pembayaran</label>
                  <div class="col-md-9">
                    <input [(ngModel)]="capacityTon" disabled type="text" class="form-control">
                  </div>
                </div> 
                <div class="form-group row">
                  <label class="col-md-3 col-form-label" for="text-input">Tanggal bayar</label>
                  <div class="col-md-9">
                    <input [(ngModel)]="capacityTon" type="text" class="form-control">
                  </div>
                </div> 
                <div class="form-group row">
                  <label class="col-md-3 col-form-label" for="text-input">Alasan</label>
                  <div class="col-md-9">
                    <textarea name="" id="" cols="30" rows="4"></textarea>
                  </div>
                </div> 
              </div>
              </div>
            </div>
          </div> -->


      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="ModalCancel.hide()"><b>Close</b></button>
        <button type="button" class="btn btn-primary"><i class="fa fa-save"></i>&nbsp;&nbsp;<b>Save</b>&nbsp;&nbsp;</button>
      </div>
    </div>
  </div>
</div>

<div bsModal #ModalTransfer="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog" style="max-width: 90%;" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title">
          <div class="row">
            <strong>Transfer DO Kecil</strong>
          </div>
        </div>
        <button type="button" class="close" (click)="ModalTransfer.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-6"> 
                <div class="form-group row">
                  <label class="col-md-3 col-form-label" for="text-input">Dari SPK</label>
                  <div class="col-md-8">
                      <input disabled [(ngModel)]="spkFrom" type="text" class="form-control">
                  </div>
                </div> 
              </div>
              <div class="col-md-6"> 
                <div class="form-group row">
                  <label class="col-md-3 col-form-label" for="text-input">Ke SPK</label>
                  <input hidden disabled [(ngModel)]="spkFrom" type="text" class="form-control">
                  <div class="col-md-7">
                      <input hidden [(ngModel)]="spkToId" type="text" class="form-control">
                      <input [(ngModel)]="spkTo" disabled type="text" class="form-control">
                  </div>
                  <div class="col-md-2">
                      <button class="btn btn-sm btn-primary" (click)="showSPK();ModalSelectSPK.show()"
                      data-toggle="modal" ><i class="fa fa-plus"></i></button>
                  </div>
                </div> 
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <!-- <div class="card-header">
              <strong>List DO</strong>
            </div> -->
            <table datatable [dtOptions]="dtOptionsDetailDo2" [dtTrigger]="dtTriggerDetail2" class="table-striped">
              <thead>
                <tr>
                  <th>Select</th>
                  <th>No DO</th>
                  <th>Tanggal Terbit</th>
                  <th>Status DO</th>
                  <th>Timbangan</th>
                  <th>Truck</th>
                  <th>Estimasi Berat</th>
                  <th>Estimasi Kemasan</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let row of listDoDetail2">   
                  <td><input type="checkbox" (change)="checkSelected2(row)"/></td> 
                  <td>{{row.do_no}}</td>
                  <td>{{row.do_date | slice:0:10}}</td>
                  <td>{{row.status_text}}</td>
                  <td>{{row.reff}}</td>
                  <td>{{row.truck}}</td>
                  <td>{{row.est_ton}}</td>
                  <td>{{row.est_pack}}</td>
                </tr>
              </tbody>
              <!-- <tfoot>
                <tr>
                  <th><input id="noDetail" type="text" class="searchRute" placeholder="No DO" /></th>
                  <th><input hidden class="searchRute"/></th>
                  <th><input id="statusDetail" type="text" class="searchRute" placeholder="Status DO" /></th>
                  <th><input hidden class="searchRute"/></th>
                  <th><input hidden class="searchRute"/></th>
                  <th><input hidden class="searchRute"/></th>
                  <th><input hidden class="searchRute"/></th>
                </tr>
              </tfoot> -->
            </table>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary"  #modalCloseTransfer (click)="ModalTransfer.hide()"><b>Close</b></button>
            <button type="button" class="btn btn-primary"  (click)="transferDODetail();"><i class="fas fa-exchange-alt"></i>&nbsp;<b>Transfer</b>&nbsp;</button>
          </div>
        </div>
          
      </div>
    </div>
  </div>
</div>

<div bsModal #ModalSelectSPK="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog" style="max-width: 60%;" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title">
          <div class="row">
            <strong>Pilih SPK</strong>
          </div>
        </div>
        <button type="button" class="close" (click)="ModalSelectSPK.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="card">
          <div class="card-body">
            <div class="card-header">
              <strong>List SPK</strong>
            </div>
            <table datatable [dtOptions]="dtOptionsSpk" [dtTrigger]="dtTriggerSpk" class="table-striped">
              <thead>
                <tr>
                  <th>Action</th>
                  <th>No DO</th>
                  <th>Type DO Kecil</th>
                  <th>No SPO</th>
                  <th>Tanggal SPK</th>
                  <th>No SPK</th>
                  <th>No Kontrak</th>
                  <th>No PO</th>
                  <th>Tanggal Kontrak</th>
                  <th>Nama Pelanggan</th>
                  <th>Tanggal Mulai</th>
                  <th>Tanggal Selesai</th>
                  <th>Nama Rute</th>
                  <th>Dari</th>
                  <th>Ke</th>
                  <th>Nama Penjual</th>
                  <th>Nama Material</th>
                  <th>Kuantitas</th>
                  <th>UOM</th>
                  <th>Biaya</th>
                  <th>Tipe Perhitungan</th>
                  <th>Tipe Perjalanan</th>
                  <th>FFA</th>
                  <th>MI</th>
                  <th>KK</th>
                  <th>Toleransi</th>
                  <th>Status</th>
                  <th>Remark</th>
                  <th>Dibuat Oleh</th>
                  <th>Dibuat Pada</th>
                  <th>Diubah Oleh</th>
                  <th>Diubah Pada</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let row of dataListSPKSelect">    
                  <td>
                    <button (click)="onSelectSPKTransfer(row);ModalSelectSPK.hide()" title="Edit" class="btn btn-sm btn-primary" triggers="mouseenter:mouseleave"><i class="fa fa-check"></i></button>&nbsp;
                  </td>       
                  <td>{{row.main_do_no}}</td>
                  <td>{{row.main_do_type}}</td>
                  <td>{{row.spo_no}}</td>
                  <td>{{row.spk_date | slice:0:10}}</td>
                  <td>{{row.spk_no}}</td>
                  <td>{{row.contract_no}}</td>
                  <td>{{row.po_no}}</td>
                  <td>{{row.contract_date | slice:0:10}}</td>
                  <td>{{row.customer_name}}</td>
                  <td>{{row.start_date | slice:0:10}}</td>
                  <td>{{row.finish_date | slice:0:10}}</td>
                  <td>{{row.route_name}}</td>
                  <td>{{row.route_from}}</td>
                  <td>{{row.route_to}}</td>
                  <td>{{row.seller_name}}</td>
                  <td>{{row.material_name}}</td>
                  <td>{{row.quantity}}</td>
                  <td>{{row.uom_name}}</td>
                  <td>{{row.cost}}</td>
                  <td>{{row.charge_type}}</td>
                  <td>{{row.trip_type_name}}</td>
                  <td>{{row.ffa}}</td>
                  <td>{{row.mi}}</td>
                  <td>{{row.kk}}</td>
                  <td>{{row.tolerance}}</td>
                  <td><input type="checkbox" [checked]="row.complete"/></td>
                  <td>{{row.remark}}</td>
                  <td>{{row.created_by_text}}</td>
                  <td>{{row.created_at_text | slice:0:10}}</td>
                  <td>{{row.updated_by_text}}</td>
                  <td>{{row.updated_at_text | slice:0:10}}</td>
              </tr>
              </tbody>
              <tfoot>
                  <tr>
                    <th><input hidden class="searchInput2"/></th>
                    <th><input id="main_do_no2" type="text" class="searchInput2" placeholder="No DO"/></th>
                    <th><input id="main_do_type2" placeholder="Type DO Kecil" class="searchInput2"/></th>
                    <th><input id="spo_no2" placeholder="No SPO" class="searchInput2"/></th>
                    <th><input id="spk_date2" placeholder="Tanggal SPK" class="searchInput2"/></th>
                    <th><input id="spk_no2" placeholder="No SPK" class="searchInput2"/></th>
                    <th><input id="contract_no2" placeholder="No Kontrak" class="searchInput2"/></th>
                    <th><input id="po_no2" placeholder="No PO" class="searchInput2"/></th>
                    <th><input id="contract_date2" placeholder="Tanggal Kontrak" class="searchInput2"/></th>
                    <th><input id="customer_name2" placeholder="Nama Pelanggan" class="searchInput2"/></th>
                    <th><input id="start_date2" placeholder="Tanggal Mulai" class="searchInput2"/></th>
                    <th><input id="finish_date2" placeholder="Tanggal Selesai" class="searchInput2"/></th>
                    <th><input id="route_name2" placeholder="Nama Rute" class="searchInput2"/></th>
                    <th><input id="route_from2" placeholder="Dari" class="searchInput2"/></th>
                    <th><input id="route_to2" placeholder="Ke" class="searchInput2"/></th>
                    <th><input id="seller_name2" placeholder="Nama Penjual" class="searchInput2"/></th>
                    <th><input id="material_name2" placeholder="Nama Material" class="searchInput2"/></th>
                    <th><input id="quantity2" placeholder="Kuantitas" class="searchInput2"/></th>
                    <th><input id="uom_name2" placeholder="Uom" class="searchInput2"/></th>
                    <th><input id="cost2" placeholder="Biaya" class="searchInput2"/></th>
                    <th><input id="charge_type2" placeholder="Tipe Perhitungan" class="searchInput2"/></th>
                    <th><input id="trip_type_name2" placeholder="Tipe Perjalanan" class="searchInput2"/></th>
                    <th><input id="ffa2" placeholder="FFA" class="searchInput2"/></th>
                    <th><input id="mi2" placeholder="MI" class="searchInput2"/></th>
                    <th><input id="kk2" placeholder="KK" class="searchInput2"/></th>
                    <th><input id="tolerance2" placeholder="Toleransi" class="searchInput2"/></th>
                    <th><input hidden class="searchInput2"/></th>
                    <th><input id="remark2" placeholder="Remark" class="searchInput2"/></th>
                    <th><input hidden class="searchInput2"/></th>
                    <th><input hidden class="searchInput2"/></th>
                    <th><input hidden class="searchInput2"/></th>
                    <th><input hidden class="searchInput2"/></th>
                  </tr>
                </tfoot>
            </table>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" (click)="ModalSelectSPK.hide()"><b>Close</b></button>
          </div>
        </div>
          
      </div>
    </div>
  </div>
</div>


<div bsModal #ModalPrint="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog" style="max-width: 50%;" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title">
        </div>
        <button type="button" class="close" (click)="ModalPrint.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-12"> 
                  <div class="form-group row">
                      <label class="col-md-3 col-form-label" for="text-input">Dari Tanggal</label>
                      <div class="col-md-8">
                          <input type="date" [(ngModel)]="printModel.from" class="form-control">
                      </div>
                  </div> 
                  <div class="form-group row">
                    <label class="col-md-3 col-form-label" for="text-input">Ke Tanggal</label>
                    <div class="col-md-8">
                        <input  type="date" [(ngModel)]="printModel.to" class="form-control">
                    </div>
                  </div> 
              </div> 
              <div class="col-md-12"> 
                <div class="form-group row">
                  <label class="col-md-3 col-form-label" for="text-input">Material</label>
                  <div class="col-md-6">
                     <textarea [(ngModel)]="materialList" rows="3" type="text" class="form-control"></textarea>
                 </div>
                  <div class="col-md-2">
                    <button class="btn btn-sm btn-primary" (click)="showMaterial2();ModalMaterial2.show()" data-toggle="modal"><i class="fa fa-plus"></i></button>
                  </div>
                </div>   
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" (click)="exportSPK()" class="btn btn-primary"><i class="fa fa-print"></i>&nbsp;<b>Print</b>&nbsp;</button>
        <button type="button" #modalPrint class="btn btn-secondary" (click)="ModalPrint.hide()">&nbsp;<b>Cancel</b>&nbsp;</button> 
    </div>
    </div>
  </div>
</div>

<div bsModal #ModalManual="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog" style="max-width: 50%;"  role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title">
        </div>
        <button type="button" class="close" (click)="ModalManual.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-12"> 
                  <div class="form-group row">
                      <label class="col-md-3 col-form-label" for="text-input">No Do</label>
                      <div class="col-md-8">
                          <input type="text" [(ngModel)]="manualDoModel.do_no" class="form-control">
                      </div>
                  </div> 
                  <div class="form-group row">
                    <label class="col-md-3 col-form-label" for="text-input">Tanggal DO</label>
                    <div class="col-md-8">
                        <input  type="date" [(ngModel)]="manualDoModel.do_date" class="form-control">
                    </div>
                  </div> 
              </div> 
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" (click)="createDo()" class="btn btn-primary"><i class="fa fa-print"></i>&nbsp;<b>Simpan</b>&nbsp;</button>
        <button type="button" #ModalManualHide class="btn btn-secondary" (click)="ModalManual.hide()">&nbsp;<b>Cancel</b>&nbsp;</button> 
    </div>
    </div>
  </div>
</div>


  
<div  bsModal #ModalMaterial2="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog  modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title">
          <div class="row">
            <strong>Pilih Material</strong>
          </div>
        </div>
        <button type="button" class="close" (click)="ModalMaterial2.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
          <!-- <app-master-material></app-master-material> -->
          <table datatable [dtOptions]="dtOptionsMaterial2" [dtTrigger]="dtTriggerMaterial2" class="table-striped deeps">
            <thead>
             <tr>
               <th>Action</th>
               <th>Kode Material</th>
               <th>Nama Material</th>
               <th>Harga</th>
               <th>UOM</th>
               <th>Tipe Cetakan DO</th>
               <th>Dibuat Oleh</th>
               <th>Dibuat Pada</th>
               <th>Diubah Oleh</th>
               <th>Diubah Pada</th>
             </tr>
           </thead> 
           <tbody>
             <tr *ngFor="let row of dataMaterial2">
             <td>
              <button title="Pilih Data" (click)="onSelectMaterial2(row);ModalMaterial2.hide()" class="btn btn-sm btn-primary" triggers="mouseenter:mouseleave"><i class="fa fa-check"></i></button>
             </td>       
               <td>{{row.material_code}}</td>
               <td>{{row.material_name}}</td>
               <td>{{row.material_price}}</td>
               <td>{{row.uom}}</td>
               <td>{{row.do_print_type}}</td>
               <td>{{row.created}}</td>
               <td>{{row.created_at | slice:0:10}}</td>  
               <td>{{row.updated_by}}</td>  
               <td>{{row.updated_at | slice:0:10}}</td>    
             </tr>
           </tbody>
           <tfoot>
             <tr>
               <th><input hidden class="searchMaterial" /></th>
               <th><input id="codeMaterial2" type="text" class="searchMaterial2" placeholder="Search Kode"/></th>
               <th><input id="nameMaterial2" type="text" class="searchMaterial2" placeholder="Search Material"/></th>
               <th><input hidden type="text" class="searchMaterial"/></th>
               <th><input hidden type="text" class="searchMaterial"/></th>
               <th><input hidden type="text" class="searchMaterial"/></th>
               <th><input hidden type="text" class="searchMaterial"/></th>
               <th><input hidden type="text" class="searchMaterial"/></th>
               <th><input hidden type="text" class="searchMaterial"/></th>
               <th><input hidden type="text" class="searchMaterial"/></th>
             </tr>
           </tfoot>
     
         </table>
      </div>
    </div>
  </div>
</div>

<div bsModal #ModalSearch="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog" style="max-width: 75%;" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title">
          Pencarian
        </div>
        <button type="button" class="close" (click)="ModalSearch.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="card">
          <div class="card-body">

            <div class="row">
              <div class="col-md-4"> 
                <div class="form-group row">
                  <label class="col-md-3 col-form-label" for="text-input">No DO</label>
                  <div class="col-md-8">
                      <input type="text" [(ngModel)]="searchModel.doNo"  class="form-control">
                  </div>
                </div> 
                <div class="form-group row">
                  <label class="col-md-3 col-form-label" for="text-input">No SPK</label>
                  <div class="col-md-8">
                      <input type="text" [(ngModel)]="searchModel.spkNo" class="form-control">
                  </div>
                </div> 
              </div>
              <div class="col-md-4"> 
                <div class="form-group row">
                  <label class="col-md-3 col-form-label" for="text-input">Tipe DO</label>
                  <div class="col-md-8">
                    <select [(ngModel)]="searchModel.doType" class="form-control">
                      <option selected><b></b></option>
                      <option value="281B9734-2333-422C-BC81-99DEB4EEB781"><b>FGS</b></option>
                      <option value="0B171FCD-21C9-4105-9C20-A3F48B072EE1"><b>CUSTOMER</b></option>
                    </select>
                  </div>
                </div> 
                <div class="form-group row">
                  <label class="col-md-3 col-form-label" for="text-input">No Contract</label>
                  <div class="col-md-8">
                      <input type="text" [(ngModel)]="searchModel.contractNo"  class="form-control">
                  </div>
                </div> 
              </div>
              <div class="col-md-4"> 
                <div class="form-group row">
                  <label class="col-md-3 col-form-label" for="text-input">No DO Kecil</label>
                  <div class="col-md-8">
                      <input type="text" [(ngModel)]="searchModel.detailDo"  class="form-control">
                  </div>
                </div> 
                <!-- <div class="form-group row">
                  <label class="col-md-3 col-form-label" for="text-input">Jumlah Data</label>
                  <div class="col-md-8">
                      <input type="text" [(ngModel)]="searchModel.doNo"  class="form-control">
                  </div>
                </div>  -->
              </div>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-4"> 
                <div class="form-group row">
                  <label class="col-md-4 col-form-label" for="text-input">Tanggal DO</label>
                  <div class="col-md-8">
                      <input type="date" [(ngModel)]="searchModel.doDateFrom"  class="form-control">
                  </div>
                </div> 
                <div class="form-group row">
                  <label class="col-md-4 col-form-label" for="text-input">Tanggal Kontrak</label>
                  <div class="col-md-8">
                      <input type="date" [(ngModel)]="searchModel.contractFrom"  class="form-control">
                  </div>
                </div> 
                <div class="form-group row">
                  <label class="col-md-4 col-form-label" for="text-input">Tanggal SPK</label>
                  <div class="col-md-8">
                      <input type="date" [(ngModel)]="searchModel.spkDateFrom"  class="form-control">
                  </div>
                </div> 
              </div>
              <div class="col-md-4"> 
                <div class="form-group row">
                  <label class="col-md-2 col-form-label" for="text-input">S/D</label>
                  <div class="col-md-8">
                      <input type="date" [(ngModel)]="searchModel.doDateTo"  class="form-control">
                  </div>
                </div> 
                <div class="form-group row">
                  <label class="col-md-2 col-form-label" for="text-input">S/D</label>
                  <div class="col-md-8">
                      <input type="date" [(ngModel)]="searchModel.contractTo"  class="form-control">
                  </div>
                </div> 
                <div class="form-group row">
                  <label class="col-md-2 col-form-label" for="text-input">S/D</label>
                  <div class="col-md-8">
                      <input type="date" [(ngModel)]="searchModel.spkDateTo"  class="form-control">
                  </div>
                </div> 
              </div>
              <div class="col-md-4"> 
                
              </div>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-4">
                <div class="form-group row">
                  <label class="col-md-3 col-form-label" for="text-input">Penjual</label>
                  <input hidden disabled type="text" class="form-control">
                  <div class="col-md-7">
                      <input hidden [(ngModel)]="spkModel.seller_id" disabled type="text" class="form-control">
                      <input [(ngModel)]="supplierName" [ngModelOptions]="{standalone: true}" disabled type="text" class="form-control">
                  </div>
                  <div class="col-md-2">
                      <button class="btn btn-sm btn-primary" (click)="showSupplier();ModalSupplier.show()"  
                      data-toggle="modal" ><i class="fa fa-plus"></i></button>
                  </div>
                </div>  
                <div class="form-group row">
                  <label class="col-md-3 col-form-label" for="text-input">Rute</label>
                  <input hidden disabled type="text" class="form-control">
                  <div class="col-md-7">
                      <input hidden [(ngModel)]="spkModel.route_id" disabled type="text" class="form-control">
                      <input [(ngModel)]="routeName" [ngModelOptions]="{standalone: true}" disabled type="text" class="form-control">
                  </div>
                  <div class="col-md-2">
                      <!-- <button [disabled]="(action == 'Update')" class="btn btn-sm btn-primary" (click)="showRoute();ModalRoute.show()" -->
                        <button [disabled]="!edit" class="btn btn-sm btn-primary" (click)="showRoute();ModalRoute.show()"
                      data-toggle="modal" ><i class="fa fa-plus"></i></button>
                  </div>
               </div> 
              </div>
              <div class="col-md-4">
                <div class="form-group row">
                  <label class="col-md-3 col-form-label" for="text-input">Customer</label>
                  <input hidden disabled type="text" class="form-control">
                  <div class="col-md-7">
                    <input hidden [(ngModel)]="spkModel.customer_id" disabled type="text" class="form-control">
                    <input [(ngModel)]="customerName" [ngModelOptions]="{standalone: true}" disabled type="text" class="form-control">
                  </div>
                  <div class="col-md-2">
                      <button class="btn btn-sm btn-primary" (click)="showCustomer();ModalCustomer.show()"  
                      data-toggle="modal" ><i class="fa fa-plus"></i></button>
                  </div>
                </div> 
                <div class="form-group row">
                  <label class="col-md-3 col-form-label" for="text-input">Material</label>
                  <input hidden disabled type="text" class="form-control">
                  <div class="col-md-7">
                      <input hidden [(ngModel)]="spkModel.material_id" disabled type="text" class="form-control">
                      <input [(ngModel)]="materialName" [ngModelOptions]="{standalone: true}" disabled type="text" class="form-control">
                  </div>
                  <div class="col-md-2">
                      <!-- <button [disabled]="(action == 'Update')" class="btn btn-sm btn-primary" (click)="showMaterial();ModalMaterial.show()"  -->
                        <button [disabled]="!edit" class="btn btn-sm btn-primary" (click)="showMaterial();ModalMaterial.show()" 
                      data-toggle="modal" ><i class="fa fa-plus"></i></button>
                  </div>
                </div> 
              </div>
              <div class="col-md-4">
                <div class="form-group row">
                  <div class="col-sm-1">
                    <input [(ngModel)]="searchModel.complete" style="width: 100%;" type="checkbox" class="form-control">
                  </div>
                  <label class="col-sm-4 col-form-label" for="text-input">Include DO Selesai</label>
                  <div class="col-sm-6">
                  </div>
               </div> 
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="modal-footer">
        <button type="button" (click)="searchData();ModalSearch.hide()" class="btn btn-primary"><i class="fa fa-search"></i>&nbsp;<b>Cari</b>&nbsp;</button>
        <button type="button" class="btn btn-secondary" (click)="ModalSearch.hide()">&nbsp;<b>Close</b>&nbsp;</button> 
    </div>
    </div>
  </div>
</div>


<div bsModal #ModalRoute="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog  modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title">
          <div class="row">
            <strong>Pilih Rute</strong>
          </div>
        </div>
        <button type="button" class="close" (click)="ModalRoute.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
          <!-- <app-master-relation></app-master-relation> -->
          <table datatable [dtOptions]="dtOptionsRoute" [dtTrigger]="dtTriggerRoute" class="table-striped deeps">
            <thead>
              <tr>
                <th>Action</th>
                <th>Rute</th>
                <th>Lokasi Muat</th>
                <th>Lokasi Bongkar</th>
                <th>Tipe Rute</th>
                <th>Diubah Oleh</th>
                <th>Dibuat Pada</th>
                <th>Diubah Oleh</th>
                <th>Diubah Pada</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let row of dataRoute">    
                <td>
                  <button (click)="onSelectRoute(row);ModalRoute.hide()" class="btn btn-sm btn-primary"  data-toggle="modal" triggers="mouseenter:mouseleave"><i class="fa fa-check"></i></button>
                </td> 
                <td>{{row.route_name}}</td>
                <td>{{row.loadtext}}</td>
                <td>{{row.unloadtext}}</td>
                <td>{{row.route_type}}</td>
                <td>{{row.createdby}}</td>
                <td>{{row.created_at | slice:0:10}}</td>  
                <td>{{row.updatedby}}</td>  
                <td>{{row.updated_at | slice:0:10}}</td>        
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th><input hidden class="searchRute"/></th>
                <th><input id="nameRute" type="text" class="searchRute" placeholder="Cari Nama" /></th>
                <th><input id="loadRute" type="text" class="searchRute" placeholder="Cari Muat" /></th>
                <th><input id="unloadRute" type="text" class="searchRute" placeholder="Cari Bongkar" /></th>
                <th><input id="typeRute" type="text" class="searchRute" placeholder="Cari Type" /></th>
                <th><input hidden class="searchRute"/></th>
                <th><input hidden class="searchRute"/></th>
                <th><input hidden class="searchRute"/></th>
                <th><input hidden class="searchRute"/></th>
              </tr>
            </tfoot>
          </table>
      </div>
    </div>
  </div>
</div>


<div  bsModal #ModalMaterial="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog  modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title">
          <div class="row">
            <strong>Pilih Material</strong>
          </div>
        </div>
        <button type="button" class="close" (click)="ModalMaterial.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
          <!-- <app-master-material></app-master-material> -->
          <table datatable [dtOptions]="dtOptionsMaterial" [dtTrigger]="dtTriggerMaterial" class="table-striped deeps">
            <thead>
             <tr>
               <th>Action</th>
               <th>Kode Material</th>
               <th>Nama Material</th>
               <th>Harga</th>
               <th>UOM</th>
               <th>Tipe Cetakan DO</th>
               <th>Dibuat Oleh</th>
               <th>Dibuat Pada</th>
               <th>Diubah Oleh</th>
               <th>Diubah Pada</th>
             </tr>
           </thead> 
           <tbody>
             <tr *ngFor="let row of dataMaterial">
             <td>
              <button (click)="ModalMaterial.hide();onSelectMaterial(row)" title="Pilih Data"  class="btn btn-sm btn-primary"><i class="fa fa-check"></i></button>
             </td>       
               <td>{{row.material_code}}</td>
               <td>{{row.material_name}}</td>
               <td>{{row.material_price}}</td>
               <td>{{row.uom}}</td>
               <td>{{row.do_print_type}}</td>
               <td>{{row.created}}</td>
               <td>{{row.created_at | slice:0:10}}</td>  
               <td>{{row.updated_by}}</td>  
               <td>{{row.updated_at | slice:0:10}}</td>    
             </tr>
           </tbody>
           <tfoot>
             <tr>
               <th><input hidden class="searchMaterial" /></th>
               <th><input id="codeMaterial" type="text" class="searchMaterial" placeholder="Search Kode"/></th>
               <th><input id="nameMaterial" type="text" class="searchMaterial" placeholder="Search Material"/></th>
               <th><input hidden type="text" class="searchMaterial"/></th>
               <th><input hidden type="text" class="searchMaterial"/></th>
               <th><input hidden type="text" class="searchMaterial"/></th>
               <th><input hidden type="text" class="searchMaterial"/></th>
               <th><input hidden type="text" class="searchMaterial"/></th>
               <th><input hidden type="text" class="searchMaterial"/></th>
               <th><input hidden type="text" class="searchMaterial"/></th>
             </tr>
           </tfoot>
     
         </table>
      </div>
    </div>
  </div>
</div>


<div  bsModal #ModalCustomer="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog  modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title">
          <div class="row">
            <strong>Pilih Customer</strong>
          </div>
        </div>
        <button type="button" class="close" (click)="ModalCustomer.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
          <table datatable [dtOptions]="dtOptionsCustomer" [dtTrigger]="dtTriggerCustomer" class="table-striped deeps">
              <thead>
                <tr>
                  <th>Action</th>
                  <th>Kode</th>
                  <th>Nama</th>
                  <th>Alamat</th>
                  <th>Kode SL</th>
                  <th>Phone</th>
                  <th>Active</th>
                  <th>Dibuat Oleh</th>
                  <th>Dibuat Pada</th>
                  <th>Diubah Oleh</th>
                  <th>Diubah Pada</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let row of dataRelation">      
                  <td style="width: 200;">
                    <button (click)="onSelectCustomer(row);ModalCustomer.hide()" title="Edit"  class="btn btn-sm btn-primary" data-toggle="modal" triggers="mouseenter:mouseleave"><i class="fa fa-check"></i></button>
                  </td>  
                  <td>{{row.relation_code}}</td>
                  <td>{{row.relation_name}}</td>
                  <td>{{row.relation_address}}</td>
                  <td>{{row.relation_sl_code}}</td>
                  <td>{{row.relation_phone}}</td>
                  <td>{{row.active}}</td>
                  <td>{{row.createdby}}</td>
                  <td>{{row.created_at | slice:0:10}}</td>  
                  <td>{{row.updatedby}}</td>  
                  <td>{{row.updated_at | slice:0:10}}</td>   
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th><input hidden class="searchCustomer"/></th>
                  <th><input id="codecustomer" type="text" class="searchCustomer" placeholder="Kode"/></th>
                  <th><input id="namecustomer" type="text" class="searchCustomer" placeholder="Nama"/></th>
                  <th><input id="addresscustomer" type="text" class="searchCustomer" placeholder="Alamat"/></th>
                  <th><input id="slcustomer" type="text" class="searchCustomer" placeholder="Kode SL"/></th>
                  <th><input hidden class="searchCustomer"/></th>
                  <th><input hidden class="searchCustomer"/></th>
                  <th><input hidden class="searchCustomer"/></th>
                  <th><input hidden class="searchCustomer"/></th>
                  <th><input hidden class="searchCustomer"/></th>
                  <th><input hidden class="searchCustomer"/></th>
                </tr>
              </tfoot>
    
            </table>
      </div>
    </div>
  </div>
</div>

<div bsModal #ModalSupplier="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog  modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title">
          <div class="row">
            <strong>Pilih Penjual</strong>
          </div>
        </div>
        <button type="button" class="close" (click)="ModalSupplier.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
          <!-- <app-master-relation></app-master-relation> -->
          <table datatable [dtOptions]="dtOptionsSupplier" [dtTrigger]="dtTriggerSupplier" class="table-striped deeps">
              <thead>
                <tr>
                  <th>Action</th>
                  <th>Kode</th>
                  <th>Nama</th>
                  <th>Alamat</th>
                  <th>Kode SL</th>
                  <th>Phone</th>
                  <th>Active</th>
                  <th>Dibuat Oleh</th>
                  <th>Dibuat Pada</th>
                  <th>Diubah Oleh</th>
                  <th>Diubah Pada</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let row of dataSupplier">      
                  <td style="width: 200;">
                    <button (click)="onSelectSupplier(row);ModalSupplier.hide()" title="Edit"  class="btn btn-sm btn-primary" data-toggle="modal" triggers="mouseenter:mouseleave"><i class="fa fa-check"></i></button>
                  </td>  
                  <td>{{row.relation_code}}</td>
                  <td>{{row.relation_name}}</td>
                  <td>{{row.relation_address}}</td>
                  <td>{{row.relation_sl_code}}</td>
                  <td>{{row.relation_phone}}</td>
                  <td>{{row.active}}</td>
                  <td>{{row.createdby}}</td>
                  <td>{{row.created_at | slice:0:10}}</td>  
                  <td>{{row.updatedby}}</td>  
                  <td>{{row.updated_at | slice:0:10}}</td>   
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th><input hidden class="searchSupplier"/></th>
                  <th><input id="codeSup" type="text" class="searchSupplier" placeholder="Kode"/></th>
                  <th><input id="nameSup" type="text" class="searchSupplier" placeholder="Nama"/></th>
                  <th><input id="addressSup" type="text" class="searchSupplier" placeholder="Alamat"/></th>
                  <th><input id="slSup" type="text" class="searchSupplier" placeholder="Kode SL"/></th>
                  <th><input hidden class="searchSupplier"/></th>
                  <th><input hidden class="searchSupplier"/></th>
                  <th><input hidden class="searchSupplier"/></th>
                  <th><input hidden class="searchSupplier"/></th>
                  <th><input hidden class="searchSupplier"/></th>
                  <th><input hidden class="searchSupplier"/></th>
                </tr>
              </tfoot>
    
            </table>
      </div>
    </div>
  </div>
</div>

<div  bsModal #ModalUpdateNoDO="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog" style="max-width: 35%;" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title"><strong>Ubah No DO</strong></div>
        <button type="button" class="close" #modalUpdateDoNoClose (click)="ModalUpdateNoDO.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
          <div class="form-group row">
            <label class="col-md-3 col-form-label" for="text-input">No DO</label>
            <div class="col-md-9">
              <input type="text" class="form-control" [(ngModel)]="updateDoNoModel.no">
           </div>
          </div> 
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="ModalUpdateNoDO.hide()"><b>Close</b></button>
        <button type="button" class="btn btn-primary" (click)="updateDoNoManual()"><i class="fa fa-save"></i>&nbsp;&nbsp;<b>Save</b>&nbsp;&nbsp;</button>
      </div>
    </div>
  </div>
  </div>

<button hidden #modalOpenEstList hide (click)="ModalListEstimasi.show()"></button>
<button hidden #modalSPKShow hide (click)="ModalSPK.show()"></button>

<ngx-spinner  bdColor = "rgba(248,248,248,0.95)" size = "large" color = "#262424" type = "ball-spin-fade" >
<p style="font-size: 20px; color: white"></p>
</ngx-spinner>