import { AfterViewInit, Component, OnInit, ViewChild,Input  } from '@angular/core';
import { DataTableDirective } from 'angular-datatables'
import { TransportService} from '../../..//services/api.service';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { Guid } from 'guid-typescript'
import { Vehicle } from '@/model/vehicle';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Kebun } from '@/model/kebun';
import { Router } from '@angular/router';
import { SpkListComponent } from '@pages/spk/spk-list/spk-list.component';

@Component({
  selector: 'app-master-kebun',
  templateUrl: './master-kebun.component.html',
  styleUrls: ['./master-kebun.component.scss'],
 
})
export class MasterKebunComponent implements OnInit, AfterViewInit {
  kebunForm: FormGroup;
  @ViewChild('modalSaveClose') modalClose;
  @ViewChild(DataTableDirective, {static: false})
  datatableElement: DataTableDirective;
  dtOptionsKebun: any = {};
  dataKebun: Kebun[] = [];
  dtTrigger = new Subject();
  @Input() kebunModel = { id : 0, kode: '', nama: '',lokasi: '',alamat: '',telepon: '',fax: ''};
  action: string;
  public dt: any;
  public cb: any;
  public modalTableKebun: boolean=false;
  

  constructor(public service: TransportService, private router: Router, private spinner: NgxSpinnerService, fb: FormBuilder) {
    this.kebunForm = fb.group({
      'id': [null],
      'kode': [null, Validators.required],
      'nama': [null, Validators.required],
      'lokasi': [null, Validators.required],
      'alamat': [null, Validators.required],
      'telepon': [null, Validators.required],
      'fax': [null, Validators.required],
      'terms': [false]
    });
    
  }

    ngOnInit(): void {
      const menuUser = localStorage.getItem("menu");
      if (menuUser != "All"){
        const subMenu = localStorage.getItem("acces_menu");
        let arrayMenu =  subMenu.split(",");
        if (arrayMenu.includes("farm") == false){
          this.router.navigate(["/home"]);
        }
      }
      if (this.router.url.includes("master-kebun")){
        this.dtOptionsKebun = {
          
          serverSide: true,
          processing: true,
          searching : false,
          ordering : false,
          ajax: (dataTablesParameters: any, callback) => {
           this.dt = dataTablesParameters; this.cb = callback;
           this.getDataKebun(dataTablesParameters, callback, "", "", "",true);
          },
          columnDefs: [
            { width: 100, targets: [0,1,4,5,6,7,8,9,10] },
            { width: 250, targets: [2,3] },
          ],
          
        };
      }
      else{
        this.modalTableKebun = true;
        this.dtOptionsKebun = {
          pageLength: 5,
          scrollX: true,
          scrollCollapse: true,
          serverSide: true,
          processing: true,
          searching : false,
          ajax: (dataTablesParameters: any, callback) => {
           this.dt = dataTablesParameters; this.cb = callback;
           this.getDataKebun(dataTablesParameters, callback, $('#codesearch').val().toString(), $('#namesearch').val().toString() , $('#locationsearch').val().toString(),true);
          },
          columnDefs: [
            { width: 100, targets: [0,1,4,5,6,7,8,9,10] },
            { width: 250, targets: [2,3] },
          ],
          
        };
      }
      

      const that = this;
      $('.searchInput').on("keyup", function(e) {
        var key = e.which;
        if (key == 13) // the enter key ascii code
        {
          that.getDataKebun(that.dt, that.cb, $('#codesearch').val().toString(), $('#namesearch').val().toString() , $('#locationsearch').val().toString(),false);
        }
      });  
      
    }   


    getDataKebun(dataTablesParameters, callback, code: string, name: string, location: string, padging : boolean){ 
      var page = 1;
      if (padging == true){
        page = parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length) + 1;
      }
      this.service.getListKebun(dataTablesParameters, page, dataTablesParameters.length, code, name, location)
      .subscribe(resp => { 
          this.dataKebun = resp.data;
          callback({
            recordsTotal: resp.totalCount,
            recordsFiltered: resp.totalCount,
             data:  []
          });
      });
    }
    
    ngAfterViewInit(): void {
      this.dtTrigger.next(0);
    }
  
    ngOnDestroy(): void {
      this.dtTrigger.unsubscribe();
    }
  
    rerender(): void {
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtTrigger.next(0);
      });
    }

    onSelectKebun(data: Kebun) {
      this.action = "Update";
      this.kebunModel = data;
      this.service.onSelectKebun(data);
    }
  
  saveDataKebun(){
    this.service.markFormTouched(this.kebunForm);
    if (this.kebunForm.valid) {
      Swal.fire({
        title: 'Simpan Data?',
        type: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: '      Yes     '
      }).then((result) => {
        if (result.value) {
            this.spinner.show();
            this.service.addDataKebun(this.kebunModel).subscribe(resp => {
              this.spinner.hide();
              if(resp.succes == false){
                Swal.fire({
                  title: ""+resp.text,
                  type: 'warning',
                  confirmButtonText: '      Ok!     '
                });
              }
              else{
                Swal.fire({
                  title: ""+resp.text,
                  type: 'success',
                  confirmButtonText: '      Ok!     '
                }).then((result) => {
                  if (result.value) { 
                    this.getDataKebun(this.dt, this.cb, "", "", "",true);
                    this.modalClose.nativeElement.click();
                  }              
                });
              }

            });
        }
      });
    } else {
      this.kebunForm.controls['terms'].setValue(false);
    }
   
    }

  deleteKebun(data: Kebun){
    Swal.fire({
      title: 'Delete Data?',
      // text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '      Yes     '
    }).then((result) => {
      if (result.value) {
        this.spinner.show();
        this.service.deleteKebun(data.id).subscribe(resp => { 
          this.spinner.hide();
          Swal.fire({
            type: 'success',
            confirmButtonText: '      Succes!     '
          }).then((result) => {
            if (result.value) { 
              this.getDataKebun(this.dt, this.cb, "", "", "",true);
            }
          
          }, (error) => {
            //; 
          })
        });
      }
    
    }, (error) => {
      //; 
    });
  }

  
  clearFormKebun(){
    this.action = "Input";
    this.kebunForm.reset();
  }
    

      
}


