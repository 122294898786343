import { AfterViewInit, Component, OnInit, ViewChild,Input, ChangeDetectorRef, ElementRef  } from '@angular/core';
import { DataTableDirective } from 'angular-datatables'
import { TransportService} from '../../../services/api.service';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { Guid } from 'guid-typescript'
import { Vehicle } from '@/model/vehicle';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { DebtPriority } from '@/model/debtPriority';
import { MasterCode } from '@/model/masterCode';
import { TabsetComponent, TabDirective } from 'ngx-bootstrap/tabs';
import { SpkDto, SpkDtoRaw } from '@/model/spkDto';
import { ListSPK } from '@/model/listSPK';
import { MasterKebunComponent } from '@pages/main-menu/master-kebun/master-kebun.component';
import { Router } from '@angular/router';
import { MasterRelationComponent } from '@pages/main-menu/master-relation/master-relation.component';
import { Relation } from '@/model/relation';
import { Route } from '@/model/route';
import { CostType } from '@/model/costType';
import { Kebun } from '@/model/kebun';
import { Material } from '@/model/material';
import moment from 'moment';
import { MainAdditionalCost } from '@/model/mainAdditionalCost';
import { ListDoDetail } from '@/model/listDoDetail';
import { Capacity } from '@/model/capacity';
import { saveAs } from 'file-saver';
import { forEach } from 'jszip';
import { UserAuth } from '@/model/userAuth';
import { EstimationDto } from '@/model/estimationDto';
import { ListPaymentTrx } from '@/model/listPaymentTrx';
import { AppService } from '@services/app.service';
import { AuthMenu } from '@/model/masterSubMenu';
import { UserAuthNew } from '@/model/respDto';
@Component({
  selector: 'app-spk-list',
  templateUrl: './spk-list.component.html',
  styleUrls: ['./spk-list.component.scss'],
  providers: [MasterKebunComponent, MasterRelationComponent]
})
export class SpkListComponent implements OnInit, AfterViewInit {
  spkForm: FormGroup;
  @ViewChild('modalSaveClose') modalSaveClose: ElementRef;
  @ViewChild('ModalManualHide') ModalManualHide: ElementRef;
  @ViewChild('ModalGenerateHide') ModalGenerateHide: ElementRef;
  @ViewChild('modalCloseTransfer') modalCloseTransfer: ElementRef;
  @ViewChild('modalCloseEst') modalCloseEst: ElementRef; 
  @ViewChild('modalPrint') modalPrint: ElementRef;
  @ViewChild('modalCloseEstList') modalCloseEstList: ElementRef; 
  @ViewChild('modalOpenEstList') modalOpenEstList: ElementRef; 
  @ViewChild('modalUpdateDoNoClose') modalUpdateDoNoClose: ElementRef; 
  @ViewChild('modalSPKShow') modalSPKShow: ElementRef; 
  @ViewChild('ModalCancelHide') ModalCancelHide: ElementRef; 
  
  
  
  @ViewChild(DataTableDirective, {static: false})

  datatabdtOptionsnt: DataTableDirective;
  model : any;
  dtOptions: any = {};
  dtOptionsSpk: any = {};
  dataKebun: Kebun[] = [];
  dataMaterial: Material[] = [];
  dataMaterial2: Material[] = [];
  dataCapacity: Capacity[] = [];
  dataListCancelPayment: ListPaymentTrx[]=[]
  driverDebtCancel: number = 0;
  driverDebtCancelTemp: number = 0;
  companyCost: number = 0;
  amountTotalCancel: number = 0;
  dirverReturn: number = 0;
  @Input() addCloseModel = {do_id: null, payDate: null, totalAmount: 0, totalUj: 0,companyCost: 0, sisa: 0, reason: '', baNo: ''};
  dataMainAdditional : MainAdditionalCost[]=[];
  dtOptionsCancel: any = {};
  dtOptionsCustomer: any = {};
  dtOptionsCapacity: any = {};
  dtOptionsSupplier: any = {};
  dtOptionsEkspedisi: any = {};
  dtOptionsRoute: any = {};
  dtOptionsDetailDo: any = {};
  dtOptionsDetailDo2: any = {};
  dtTriggerDetail2 = new Subject();
  dtOptionsKebun: any = {};
  dtOptionsMaterial: any = {};
  dtOptionsMaterial2: any = {};
  dtTrigger = new Subject();
  dtTriggerCancel = new Subject();
  labelCalculate: string;
  dtTriggerCapacity = new Subject();
  dtTriggerDetail = new Subject();
  dtTriggerCustomer = new Subject();
  dtTriggerSupplier = new Subject();
  dtTriggerRoute = new Subject();
  dtTriggerEkspedisi = new Subject();
  dtTriggerKebun = new Subject();
  dtTriggerSpk = new Subject();
  dtTriggerMaterial = new Subject();
  dtTriggerMaterial2 = new Subject();
  dataRelation: Relation[]=[];
  public debt: DebtPriority;
  dataEkspedisi: Relation[]=[];
  dataSupplier: Relation[]=[];
  public dataType: MasterCode[] = [];
  dataDebt: DebtPriority[] = [];
  dataRoute: Route[] = [];
  dataListSPK: ListSPK[] = [];
  dataListSPKSelect: ListSPK[] = [];
  action: string;
  complete: boolean;
  public dtCustomer: any;
  public cbCustomer: any;
  public dtCapacity: any;
  public cbCapacity: any;
  public dtSupplier: any;
  public cbSupplier: any;
  public dtRoute: any;
  public cbRoute: any;
  public dtEkspedisi: any;
  public cbEkspedisi: any;
  public dtDetailDo: any;
  public cbDetailDo: any;
  public dtSPK: any;
  public cbSPK: any;
  public dtKebun: any;
  public cbKebun: any;
  public dtMaterial: any;
  public cbMaterial: any;
  public filter: string;
  public dataSpkDto: any;
  public dataSpkDtoRaw: SpkDtoRaw;
  disableSwitching: boolean;
  public customerName: string;
  public capacityTon: string;
  public supplierName: string;
  public routeName: string;
  public ekspedisiName: string;
  public costName: string = "";
  public costType: string = "";
  public getSpkDto: SpkDto;
  public materialName: string
  materialList: string = "";
  mate
  spkFrom: string;
  spkFrom_id: Guid;
  spkFrom_type: string;
  spkTo_type: string;
  spkToId: Guid;
  spkTo: string;
  ekspedisi : boolean;
  qtySend : number = 0;
  qtyReceived : number = 0;
  estTon : number = 0;
  estPack : number = 0;
  rPcakSend : number = 0;
  rPackReceived : number = 0;
  authAcces: UserAuth[] =[];
  authAccessPlb: UserAuthNew[] =[];
  btnCreate: Boolean = true;
  btnTransfer: Boolean = true;
  btnDelete: Boolean = true;
  btnUpdate: Boolean = true;
  btnComplete: Boolean = true;
  btnCancel: Boolean = true;
  btnCrudDO: Boolean = true;
  pupuk : Boolean = false;
  checkAllDO : Boolean = false;
  btnDetailPrint: Boolean  = true;
  btnDetailPrintPonti: Boolean  = true;
  btnDetailUpdate: Boolean  = true;
  btnDetailDelete: Boolean  = true;
  btnDetailClose: Boolean  = true;
  locationName: string;
  edit: boolean = true;
  see: boolean = true;
  @Input() paymentModel = {transactionId: null};
  dataTambahan: Array<{cost_type: Guid, charge_type: Guid, amount: number, costName: string,  costType: string}> = [];
  dataTambahanSave: Array<{cost_type: Guid, charge_type: Guid, amount: number, costName: string,  costType: string, deleted: boolean, id: Guid}> = [];
  @Input() searchModel = { doNo: null, doDateTo: null, doType: null, doDateFrom: null,spkNo: null,contractNo: null,
    spkDateFrom: null,spkDateTo: null,contractFrom: null,contractTo: null,seller: null,customer: null,
    material: null,route: null,detailDo: null,complete: null,};
  @Input() updateDoNoModel = { id: null, no: null };
  @Input() updateEstimationModel = { id: null, est_ton: 0, est_pack: 0, est_pack_uom: null };
  @Input() updateEstimationListModel = { listId: new Array(), est_ton: 0, est_pack: 0, est_pack_uom: null };
  @Input() tambahanModel = { cost_type: null, charge_type: null, amount: 0  };
  @Input() spkModel = { id: null, parent_id: null, main_do_type: null, main_do_no: '', cost: 0, 
           main_do_date: null, spk_no: '', spk_date: null, contract_no: '', po: '', contract_date: null, 
           customer_id: null, route_id: null, seller_id: null, vendor_id: null, 
           material_id: null, material_text: '', quantity: 0, uom: null, charge_type: null, 
           trip_type: null, invoice_type: null, ffa: 0, mi: 0, kk: 0, tolerance: 0, complete: false,
           start_date: null, finish_date: null, remark: '', pembeli: '', kebun: '', jenis_pupuk: '', spo_no: '', costMain: new Array() }
  @Input() generateDoModel = { main_do_id: null, do_count: 0, do_date: null};
  @Input() manualDoModel = { id: null, main_do_id: null, do_no: null, do_date: null  };
  @Input() transferDoModel = { to_id: null, doDetail: new Array() };
  @Input() printModel = { from: null, to: null, materialListId: null, location: null };
  @Input() printModelSelected = { listId: new Array(), location: null };
  @Input() deleteDOModel = { id: null };
  @Input() spkIdModel = { id: null };
  public ChargeType: MasterCode[] = [];
  public listDoDetail: ListDoDetail[] = [];
  public listDoDetailSource: ListDoDetail[] = [];
  public listDoDetail2: ListDoDetail[] = [];
  public listEstimation:  EstimationDto[] = [];
  public DoType: MasterCode[] = [];
  public UOM: MasterCode[] = [];
  public TripType: MasterCode[] = [];
  public InvoiceCalcType: MasterCode[] = [];
  public ListCostType: CostType[] = [];
  public modelCostType: CostType;
  printDoEkspedisi: Boolean = true;
  @ViewChild('tabset') tabset: TabsetComponent;
  constructor( public service: TransportService, private router: Router, private spinner: NgxSpinnerService, fb: FormBuilder, private appServ : AppService,
    public componentRelation: MasterRelationComponent, private componentKebun: MasterKebunComponent, private changeDetectorRef: ChangeDetectorRef) {
    this.spkForm = fb.group({
      'location': [null, Validators.required],
      'main_do_type': [null, Validators.required],
      'main_do_no': [null, Validators.required],
      'main_do_date': [null, Validators.required],
      'spk_no': [null, Validators.required],
      'spk_date': [null, Validators.required],
      'contract_no': [null, Validators.required],
      'po': [null, Validators.required],
      'contract_date': [null, Validators.required],
      'customer_id': [null, Validators.required],
      'route_id': [null, Validators.required],
      'seller_id': [null, Validators.required],
      'vendor_id': [null, Validators.required],
      'material_id': [null, Validators.required],
      'material_text': [null, Validators.required],
      'quantity': [null, Validators.required],
      'uom': [null, Validators.required],
      'cost': [null, Validators.required],
      'charge_type': [null, Validators.required],
      'trip_type': [null, Validators.required],
      'invoice_type': [null, Validators.required],
      'ffa': [null, Validators.required],
      'mi': [null, Validators.required],
      'kk': [null, Validators.required],
      'tolerance': [null, Validators.required],
      'complete': [null, Validators.required],
      'start_date': [null, Validators.required],
      'finish_date': [null, Validators.required],
      'remark': [null, Validators.required],
      'pembeli': [null, Validators.required],
      'kebun': [null, Validators.required],
      'jenis_pupuk': [null, Validators.required],
      'spo_no': [null, Validators.required],
      'terms': [false]
    });
  }

    ngOnInit(): void {

      this.userAcces();
      this.dtOptions = {
        scrollX: true,
        scrollCollapse: true,
        pageLength: 10,
        pagination: false,
        ordering : false,
        serverSide: true,
        processing: true,
        searching : false,
        // ajax: (dataTablesParameters: any, callback, dataDto: SpkDto) => {
        ajax: (dataTablesParameters: any, callback, dataDto: SpkDtoRaw) => {
         this.dataSpkDtoRaw = dataDto
         this.dtSPK = dataTablesParameters; this.cbSPK = callback;
         this.getDataListSPK(dataTablesParameters, callback, dataDto, true);

        },
        columnDefs: [
          { width: 100, targets: [1,2,3,4,5,6,7,8,10,11,12,13,14,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30] },
          { width: 200, targets: [9, 15] },
          { width: 320, targets: [0] }
        ],
      };


      this.dtOptionsCustomer = {
        retrieve: true,
        pageLength: 5,
        scrollX: true,
        scrollCollapse: true,
        serverSide: true,
        processing: true,
        searching : false,
        ajax: (dataTablesParameters: any, callback, dataDto: SpkDto) => {
         this.dtCustomer = dataTablesParameters; this.cbCustomer = callback;
         this.getDataRelation(dataTablesParameters, callback, "", "","","CUSTOMER","",true);
        },
        columnDefs: [
          { width: 100, targets: [0,1,4,5,6,7,8,9,10] },
          { width: 200, targets: 2 },
          { width: 300, targets: 3 },
        ],
      };

      this.dtOptionsSupplier = {
        retrieve: true,
        pageLength: 5,
        scrollX: true,
        scrollCollapse: true,
        serverSide: true,
        processing: true,
        searching : false,
        ajax: (dataTablesParameters: any, callback, dataDto: SpkDto) => {
         this.dtSupplier = dataTablesParameters; this.cbSupplier = callback;
         this.getSupplier(dataTablesParameters, callback, "", "","","PENJUAL","",true);
        },
        columnDefs: [
          { width: 100, targets: [0,1,4,5,6,7,8,9,10] },
          { width: 200, targets: 2 },
          { width: 300, targets: 3 },
        ],
      };

      this.dtOptionsEkspedisi = {
        retrieve: true,
        pageLength: 5,
        scrollX: true,
        scrollCollapse: true,
        serverSide: true,
        processing: true,
        searching : false,
        ajax: (dataTablesParameters: any, callback, dataDto: SpkDto) => {
         this.dtEkspedisi = dataTablesParameters; this.cbEkspedisi = callback;
         this.getDataEkspedisi(dataTablesParameters, callback, "", "","","Ekspedisi","",true);
        },
        columnDefs: [
          { width: 100, targets: [0,1,4,5,6,7,8,9,10] },
          { width: 200, targets: 2 },
          { width: 300, targets: 3 },
        ],
      };

      this.dtOptionsRoute = {
        retrieve: true,
        pageLength: 5,
        scrollX: true,
        scrollCollapse: true,
        serverSide: true,
        processing: true,
        searching : false,
        ajax: (dataTablesParameters: any, callback) => {
         this.dtRoute = dataTablesParameters; this.cbRoute = callback;
         this.getDataRoute(dataTablesParameters, callback,"",  "",  "",  "", null,true);
        },
        columnDefs: [
          { width: 100, targets: [0,1,4,5,6,7,8] },
          { width: 200, targets: 2 },
          { width: 200, targets: 3 },
         ],
      };

      this.dtOptionsKebun = {
        retrieve: true,
        pageLength: 5,
        scrollX: true,
        scrollCollapse: true,
        serverSide: true,
        processing: true,
        searching : false,
        ajax: (dataTablesParameters: any, callback) => {
         this.dtKebun = dataTablesParameters; this.cbKebun = callback;
         this.getDataKebun(dataTablesParameters, callback,"","","",true);
        },
        columnDefs: [
          { width: 100, targets: [0,1,4,5,6,7,8,9,10] },
          { width: 250, targets: [2,3] },
        ],
      };

      this.dtOptionsMaterial = {
        retrieve: true,
        pageLength: 5,
        scrollX: true,
        scrollCollapse: true,
        serverSide: true,
        processing: true,
        searching : false,
        ajax: (dataTablesParameters: any, callback) => {
          this.dtMaterial = dataTablesParameters; this.cbMaterial = callback;
          this.getDataMaterial(dataTablesParameters, callback, "","",true);
        },
        columnDefs: [
          { width: 100, targets: 0 },
          { width: 200, targets: 2 },
        ]
      };

      this.dtOptionsMaterial2 = {
        retrieve: true,
        pageLength: 5,
        scrollX: true,
        scrollCollapse: true,
        serverSide: true,
        processing: true,
        searching : false,
        ajax: (dataTablesParameters: any, callback) => {
          this.dtMaterial = dataTablesParameters; this.cbMaterial = callback;
          this.getDataMaterial2(dataTablesParameters, callback, "","",true);
        },
        columnDefs: [
          { width: 100, targets: 0 },
          { width: 200, targets: 2 },
        ]
      };

      this.dtOptionsDetailDo = {
        retrieve: true,
        paging: false,
        ordering : false,
        bInfo : false,
        // scrollX: true,
        // scrollCollapse: true,
        searching : false,
        processing: true,
        columnDefs: [
          { width: 100, targets: [1,2,3,4,5,6,7,8,9,10,11,12,13,14] },
          { width: 200, targets: 0 },
        ],
      };

      this.dtOptionsDetailDo2 = {
        retrieve: true,
        paging: true,
        pagingType: 'full_numbers',
        length: 5,
        scrollX: true,
        scrollCollapse: true,
        processing: true,
        searching : false,
        columnDefs: [
          { width: 175, targets: [0,2,3,4,5,6] },
          { width: 350, targets: 1 },
        ],
      };

      this.dtOptionsCapacity = {
        retrieve: true,
        pageLength: 5,
        serverSide: true,
        processing: true,
        searching : false,
        scrollX: true,
        scrollCollapse: true,
        ajax: (dataTablesParameters: any, callback) => {
          this.dtCapacity = dataTablesParameters; this.cbCapacity = callback;
          this.getDataCapacity(dataTablesParameters, callback, "", "", "",true);
         },
         columnDefs: [
          { width: 100, targets: [0,1,2,3,4,5,6] }
        ]
      };

      this.dtOptionsSpk = {
        retrieve: true,
        scrollX: true,
        scrollCollapse: true,
        serverSide: true,
        processing: true,
        searching : false,
        ajax: (dataTablesParameters: any, callback, dataDto: SpkDto) => {
         this.dataSpkDto = dataDto
         this.dtSPK = dataTablesParameters; this.cbSPK = callback;
         this.getDataListSPKSelect(dataTablesParameters, callback, dataDto, true);

        },
        columnDefs: [
          { width: 100, targets: [0,1,2,3,4,5,6,7,8,10,11,12,13,14,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30] },
          { width: 200, targets: [9, 15] },
        ],
      };

      const that = this; 

      $('.searchInputCapacity').on("keyup", function(e) {
        var key = e.which;
        if (key == 13) // the enter key ascii code
        {
          that.getDataCapacity(that.dtCapacity, that.cbCapacity, $('#codecapacity').val().toString(),$('#namecapacity').val().toString(),$('#vehiclesearch').val().toString(), false);
        }
      }); 

      $('.searchEkspedisi').on("keyup", function(e) {
        var key = e.which;
        if (key == 13) // the enter key ascii code
        {
          that.getDataEkspedisi(that.dtEkspedisi, that.cbEkspedisi, $('#codeEks').val().toString(), $('#nameEks').val().toString(), 
          $('#addressEks').val().toString(), "EKSPEDISI",
          $('#slEks').val().toString(),false);
        }
      });  

      $('.searchSupplier').on("keyup", function(e) {
        var key = e.which;
        if (key == 13) // the enter key ascii code
        {
          that.getSupplier(that.dtSupplier, that.cbSupplier, $('#codeSup').val().toString(), $('#nameSup').val().toString(), 
          $('#addressSup').val().toString(), "PENJUAL",
          $('#slSup').val().toString(),false);
        }
      });  

      $('.searchRute').on("keyup", function(e) {
        var key = e.which;
        if (key == 13) // the enter key ascii code
        {
          that.getDataRoute(that.dtRoute, that.cbRoute,  $('#nameRute').val().toString(),  $('#typeRute').val().toString()
          ,  $('#unloadRute').val().toString(),  $('#loadRute').val().toString(), null,false);
        }
      });  

      $('.searchMaterial').on("keyup", function(e) {
        var key = e.which;
        if (key == 13) // the enter key ascii code
        {
          that.getDataMaterial(that.dtMaterial, that.cbMaterial, $('#codeMaterial').val().toString(), $('#nameMaterial').val().toString(),false);
        }
      }); 

      $('.searchMaterial2').on("keyup", function(e) {
        var key = e.which;
        if (key == 13) // the enter key ascii code
        {
          that.getDataMaterial2(that.dtMaterial, that.cbMaterial, $('#codeMaterial2').val().toString(), $('#nameMaterial2').val().toString(),false);
        }
      }); 

      $('.searchKebun').on("keyup", function(e) {
        var key = e.which;
        if (key == 13) // the enter key ascii code
        {
          that.getDataKebun(that.dtKebun, that.cbKebun, $('#codeKebun').val().toString(), $('#nameKebun').val().toString() , $('#locationKebun').val().toString(),false);
        }
      });  

      $('.searchCustomer').on("keyup", function(e) {
        var key = e.which;
        if (key == 13) // the enter key ascii code
        {
          that.getDataRelation(that.dtCustomer, that.cbCustomer, $('#codecustomer').val().toString(), $('#namecustomer').val().toString(), 
          $('#addresscustomer').val().toString(), "CUSTOMER",
          $('#slcustomer').val().toString(),false);
        }
      });  
      
      $('.searchInput').on("keyup", function(e) {
            
        var key = e.which;
        if (key == 13) // the enter key ascii code
        {
          that.dataSpkDto.main_do_no =  $('#main_do_no').val().toString();
          that.dataSpkDto.main_do_type =  $('#main_do_type').val().toString();
          that.dataSpkDto.spo_no =  $('#spo_no').val().toString();
          that.dataSpkDto.spk_date =  $('#spk_date').val().toString();
          that.dataSpkDto.spk_no =  $('#spk_no').val().toString();
          that.dataSpkDto.contract_no =  $('#contract_no').val().toString();
          that.dataSpkDto.po_no =  $('#po_no').val().toString();
          that.dataSpkDto.contract_date =  $('#contract_date').val().toString();
          that.dataSpkDto.customer_name =  $('#customer_name').val().toString();
          that.dataSpkDto.start_date =  $('#start_date').val().toString();
          that.dataSpkDto.finish_date =  $('#finish_date').val().toString();
          that.dataSpkDto.route_name =  $('#route_name').val().toString();
          that.dataSpkDto.route_from =  $('#route_from').val().toString();
          that.dataSpkDto.route_to =  $('#route_to').val().toString();
          that.dataSpkDto.seller_name =  $('#seller_name').val().toString();
          that.dataSpkDto.material_name =  $('#material_name').val().toString();
          that.dataSpkDto.quantity =  $('#quantity').val().toString();
          that.dataSpkDto.uom_name =  $('#uom_name').val().toString();
          that.dataSpkDto.cost =  $('#cost').val().toString();
          that.dataSpkDto.charge_type =  $('#charge_type').val().toString();
          that.dataSpkDto.trip_type_name =  $('#trip_type_name').val().toString();
          that.dataSpkDto.ffa =  $('#ffa').val().toString();
          that.dataSpkDto.mi =  $('#mi').val().toString();
          that.dataSpkDto.kk =  $('#kk').val().toString();
          that.dataSpkDto.tolerance =  $('#tolerance').val().toString();
          that.dataSpkDto.remark =  $('#remark').val().toString();
          that.dataSpkDto.do_kecil = $('#do_kecil').val().toString();
          that.getDataListSPK(that.dtSPK, that.cbSPK, that.dataSpkDto, false);
        }
      });

      $('.searchInput2').on("keyup", function(e) {
            
        var key = e.which;
        if (key == 13) // the enter key ascii code
        {
          that.dataSpkDto.main_do_no =  $('#main_do_no2').val().toString();
          that.dataSpkDto.main_do_type =  $('#main_do_type2').val().toString();
          that.dataSpkDto.spo_no =  $('#spo_no2').val().toString();
          that.dataSpkDto.spk_date =  $('#spk_date2').val().toString();
          that.dataSpkDto.spk_no =  $('#spk_no2').val().toString();
          that.dataSpkDto.contract_no =  $('#contract_no2').val().toString();
          that.dataSpkDto.po_no =  $('#po_no2').val().toString();
          that.dataSpkDto.contract_date =  $('#contract_date2').val().toString();
          that.dataSpkDto.customer_name =  $('#customer_name2').val().toString();
          that.dataSpkDto.start_date =  $('#start_date2').val().toString();
          that.dataSpkDto.finish_date =  $('#finish_date2').val().toString();
          that.dataSpkDto.route_name =  $('#route_name2').val().toString();
          that.dataSpkDto.route_from =  $('#route_from2').val().toString();
          that.dataSpkDto.route_to =  $('#route_to2').val().toString();
          that.dataSpkDto.seller_name =  $('#seller_name2').val().toString();
          that.dataSpkDto.material_name =  $('#material_name2').val().toString();
          that.dataSpkDto.quantity =  $('#quantity2').val().toString();
          that.dataSpkDto.uom_name =  $('#uom_name2').val().toString();
          that.dataSpkDto.cost =  $('#cost2').val().toString();
          that.dataSpkDto.charge_type =  $('#charge_type2').val().toString();
          that.dataSpkDto.trip_type_name =  $('#trip_type_name2').val().toString();
          that.dataSpkDto.ffa =  $('#ffa').val().toString();
          that.dataSpkDto.mi =  $('#mi2').val().toString();
          that.dataSpkDto.kk =  $('#kk2').val().toString();
          that.dataSpkDto.tolerance =  $('#tolerance2').val().toString();
          that.dataSpkDto.remark =  $('#remark2').val().toString();
          that.getDataListSPKSelect(that.dtSPK, that.cbSPK, that.dataSpkDto, false );
        }
      });
      
    
      this.getDataType();
      
      
    }   

  goto(id){
    this.tabset.tabs[id].active = true;
  }

  getListCancelPayment(id: Guid){
    this.spinner.show();
    this.paymentModel.transactionId = id;
    this.dataListCancelPayment.length = 0;
    this.service.getPaymentCancelById(this.paymentModel).subscribe( resp => {
      this.dataListCancelPayment = resp;
      this.amountTotalCancel = this.dataListCancelPayment.reduce((sum, obj) => {
        return sum + obj.total;
      }, 0);
      this.driverDebtCancel = this.dataListCancelPayment.reduce((sum, obj) => {
        return sum + obj.total;
      }, 0);
      this.driverDebtCancelTemp = this.driverDebtCancel;
      this.spinner.hide();
    })
  }

  onTypeAmount(){
    this.driverDebtCancel = this.driverDebtCancelTemp - (this.companyCost + this.dirverReturn);
  }

  onSelectCancel(data: ListDoDetail){
    this.addCloseModel.payDate = null;
    this.addCloseModel.reason = null;
    this.companyCost = 0;
    this.dirverReturn = 0;
    this.addCloseModel.do_id = data.id;
    this.getListCancelPayment(data.id);
    setTimeout(() => {
      this.dtTriggerCancel.next(this.dtOptionsCancel);
    }, 500);
    var today = moment().format('YYYY-MM-DD');
    this.addCloseModel.payDate = today;
  }
  
  // onSelectCancel(data: ListDoDetail){
  //   this.addCloseModel.do_id = data.id;
  // }

  saveCancel(){
    this.addCloseModel.companyCost = this.companyCost;
    this.addCloseModel.sisa = this.driverDebtCancel;
    this.addCloseModel.totalAmount = this.dirverReturn;
    this.addCloseModel.totalUj = this.amountTotalCancel;
      Swal.fire({
        title: 'Apakah anda yakin untuk menutup transaksi ini?',
        type: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: '      Yes     '
      }).then((result) => {
        if (result.value) {
          this.spinner.show();
          this.service.closeTrip(this.addCloseModel).subscribe(resp => {
            this.spinner.hide();
            if (resp.succes == true){
              Swal.fire({
                type: 'success',
                title: ""+resp.text,
                confirmButtonText: '      Ok!     '
              }).then((result) => {
                if (result.value) { 
                  this.ModalCancelHide.nativeElement.click();
                  this.getDetailDo(this.generateDoModel.main_do_id,"","");
                }
              
              });
            }
            else{
              Swal.fire({
                title: ""+resp.text,
                type: 'warning',
                confirmButtonText: '      Ok!     '
              });
            }
          });
        }
      
      }, (error) => {
      });

  }

  completeSPK(data : ListSPK){
    Swal.fire({
      title: 'Anda Yakin Do ini telah selesai ?',
      // text: "You won't be able to revert this!",
      type: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '      Yes     '
    }).then((result) => {
      if (result.value) {
        this.spinner.show();
        this.service.setCompleteSPK(data.spk_id).subscribe(resp => {
          this.spinner.hide();
          if(resp.succes == false){
            Swal.fire({
              title: ""+resp.text,
              type: 'warning',
              confirmButtonText: '      Ok!     '
            });
          }
          else{
            Swal.fire({
              title: ""+resp.text,
              type: 'success',
              confirmButtonText: '      Ok!     '
            }).then((result) => {
              if (result.value) { 
                this.getDataListSPK(this.dtSPK, this.cbSPK,this.dataSpkDtoRaw, true);
              }              
            }, (error) => {
              ; 
            })
          }
        });

      }
    
    }, (error) => {
    });
   
  }

  batalSPK(data : ListSPK){
    Swal.fire({
      title: 'Anda Yakin Do Batal Selesai ?',
      // text: "You won't be able to revert this!",
      type: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '      Yes     '
    }).then((result) => {
      if (result.value) {
        this.spinner.show();
        this.service.setRollbackSPK(data.spk_id).subscribe(resp => {
          this.spinner.hide();
          if(resp.succes == false){
            Swal.fire({
              title: ""+resp.text,
              type: 'warning',
              confirmButtonText: '      Ok!     '
            });
          }
          else{
            Swal.fire({
              title: ""+resp.text,
              type: 'success',
              confirmButtonText: '      Ok!     '
            }).then((result) => {
              if (result.value) { 
                this.getDataListSPK(this.dtSPK, this.cbSPK,this.dataSpkDtoRaw, true);
              }              
            }, (error) => {
              ; 
            })
          }
        });

      }
    
    }, (error) => {
    });
   
  }

  updateEstimationList(){
    if(this.updateEstimationListModel.est_ton == null || this.updateEstimationListModel.est_pack == null || this.updateEstimationListModel.est_pack_uom == null){
     return Swal.fire({
        title: "Data Belum Lengkap!",
        type: 'warning',
        confirmButtonText: '      Ok!     '
      });
    }
    Swal.fire({
      title: 'Anda Anda Yakin ?',
      // text: "You won't be able to revert this!",
      type: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '      Yes     '
    }).then((result) => {
      if (result.value) {
        this.spinner.show();
        this.listDoDetail.filter(x => x.select == true).forEach(list => {
          this.updateEstimationListModel.listId.push({
            id: list.id,
          })
        })
        this.service.updateEstimationList(this.updateEstimationListModel).subscribe(resp => {
          this.spinner.hide();
          if(resp.succes == false){
            Swal.fire({
              title: ""+resp.text,
              type: 'warning',
              confirmButtonText: '      Ok!     '
            });
          }
          else{
            Swal.fire({
              title: ""+resp.text,
              type: 'success',
              confirmButtonText: '      Ok!     '
            }).then((result) => {
              if (result) { 
                this.modalCloseEstList.nativeElement.click();
                this.getDetailDo(this.generateDoModel.main_do_id.toString(), "", "");
                this.checkAllDO = false;
              }              
            }, (error) => {
            })
          }
        });

      }
    
    }, (error) => {
    });
}

  updateEstimation(){
    
    Swal.fire({
      title: 'Anda Anda Yakin ?',
      // text: "You won't be able to revert this!",
      type: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '      Yes     '
    }).then((result) => {

      if (result.value) {
        this.spinner.show();
        this.service.updateEstimation(this.updateEstimationModel).subscribe(resp => {
          this.spinner.hide();
          if(resp.succes == false){
            Swal.fire({
              title: ""+resp.text,
              type: 'warning',
              confirmButtonText: '      Ok!     '
            });
          }
          else{
            Swal.fire({
              title: ""+resp.text,
              type: 'success',
              confirmButtonText: '      Ok!     '
            }).then((result) => {
              if (result) { 
                this.modalCloseEst.nativeElement.click();
                this.getDetailDo(this.generateDoModel.main_do_id.toString(), "", "");
              }              
            }, (error) => {
            })
          }
        });
        this.spinner.hide();

      }
    
    }, (error) => {
    });
}

  transferDODetail(){
    if (this.spkTo == null){
      return Swal.fire({
        title: "SPK Tujuan Masih Kosong!",
        type: 'warning',
        confirmButtonText: '      Ok!     '
      });
    }
    this.transferDoModel.to_id = this.spkToId;
    this.transferDoModel.doDetail = [];
     this.listDoDetail2.filter(x => x.select == true).forEach(data => {
        this.transferDoModel.doDetail.push({
          id: data.id
        })
     });
    
      Swal.fire({
        title: 'Anda Anda Yakin ?',
        // text: "You won't be able to revert this!",
        type: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: '      Yes     '
      }).then((result) => {
  
        if (result.value) {
          this.spinner.show();
          this.service.transferDoDetail(this.transferDoModel).subscribe(resp => {
            this.spinner.hide();
            if(resp.succes == false){
              Swal.fire({
                title: ""+resp.text,
                type: 'warning',
                confirmButtonText: '      Ok!     '
              });
            }
            else{
              Swal.fire({
                title: ""+resp.text,
                type: 'success',
                confirmButtonText: '      Ok!     '
              }).then((result) => {
                if (result) { 
                  
                  this.modalCloseTransfer.nativeElement.click();
                  this.getDataListSPK(this.dtSPK, this.cbSPK,this.dataSpkDtoRaw, true)
                }              
              }, (error) => {
              })
            }
          });
          this.spinner.hide();
  
        }
      
      }, (error) => {
      });
  }

  getDataListSPK(dataTablesParameters, callback, data: SpkDtoRaw, padging){
    
    var page = 1;
      if (padging == true){
        page = parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length) + 1;
      }
      data.page = page;
      data.perPage = dataTablesParameters.length;
      this.service.getListSPKGetRaw(data)
      .subscribe(resp => { 
          this.dataListSPK = resp;
          callback({
            recordsTotal: dataTablesParameters.length,
            recordsFiltered: dataTablesParameters.length,
             data:  []
          });
      });
  }

  // getDataListSPK(dataTablesParameters, callback, data: SpkDto, padging){
    
  //   var page = 1;
  //     if (padging == true){
  //       page = parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length) + 1;
  //     }
  //     data.page = page;
  //     data.perPage = dataTablesParameters.length;
  //     this.service.getListSPK(data)
  //     .subscribe(resp => { 
  //         this.dataListSPK = resp.data;
  //         callback({
  //           recordsTotal: resp.totalCount,
  //           recordsFiltered: resp.totalCount,
  //            data:  []
  //         });
  //     });
  // }

  getMainAdditionalCost(id: string){
    this.dataTambahan = [];
    this.dataTambahanSave = [];
    this.service.getMainAdditionalCost(id)
    .subscribe(resp => { 
        this.dataMainAdditional = resp;
        for (let i = 0; i < resp.length; i++) {
          this.dataTambahan.push({
            costName : resp[i].cost_type_text,
            costType : resp[i].charge_type_text,
            cost_type : resp[i].cost_type,
            charge_type : resp[i].charge_type,
            amount: resp[i].amount
        });
        this.dataTambahanSave.push({
          costName : resp[i].cost_type_text,
          costType : resp[i].charge_type_text,
          cost_type : resp[i].cost_type,
          charge_type : resp[i].charge_type,
          amount: resp[i].amount,
          deleted : resp[i].deleted,
          id : resp[i].id
      });
      }
    });
  }

  getDataListSPKSelect(dataTablesParameters, callback,data: SpkDto, padging: boolean){
    
    var page = 1;
      if (padging == true){
        page = parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length) + 1;
      }
      data.page = page;
      data.perPage = dataTablesParameters.length;
      this.service.getListSPK(data)
      .subscribe(resp => { 
          this.dataListSPKSelect = resp.data;
          callback({
            recordsTotal: resp.totalCount,
            recordsFiltered: resp.totalCount,
             data:  []
          });
      });
  }
    
    ngAfterViewInit(): void {
      this.dtTrigger.next(0);
      //this.dtTriggerSpk.next(0);
      // this.dtTriggerCustomer.next(0);
      // this.dtTriggerSupplier.next(0);
    }
  
    ngOnDestroy(): void {
      this.dtTrigger.unsubscribe();
      // this.dtTriggerCustomer.unsubscribe();
      // this.dtTriggerSupplier.unsubscribe();
    }
  
    // rerender(): void {
    //   this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
    //     dtInstance.destroy();
    //     this.dtTrigger.next(0);
    //   });
    // }

    cancelComplete(data: any){
      Swal.fire({
        title: 'Anda Anda Yakin ?',
        // text: "You won't be able to revert this!",
        type: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: '      Yes     '
      }).then((result) => {
        if (result.value) {
          this.spkIdModel.id = data.spk_id;
          this.spinner.show();
          this.service.cancelCompleteSPK(this.spkIdModel).subscribe(resp => {
            this.spinner.hide();
            if (resp.succes == false){
              Swal.fire({
                title: ""+resp.text,
                type: 'warning',
                confirmButtonText: '      Ok!     '
              });
            }
            else{
              this.getDataListSPK(this.dtSPK, this.cbSPK,this.dataSpkDtoRaw, true);
              Swal.fire({
                title: ""+resp.text,
                type: 'success',
                confirmButtonText: '      Ok!     '
              });
            }
          });
            }
      });
    }

    onSelectSPK(data: any) {
      this.service.disableEditSPK(data.spk_id).subscribe(resp => {
      if (resp > 0){
        Swal.fire({
          title: "SPK tersebut sudah punya invoice!",
          type: 'warning',
          confirmButtonText: '      Ok!     '
        });
      }
      else{ 
        this.modalSPKShow.nativeElement.click();
        this.see = false;
        this.edit = true;
        this.action = "Update";
        this.spkModel = data;
        this.spkModel.id = data.spk_id;
        this.materialName = data.material_name;
        this.routeName = data.route_name;
        this.customerName = data.customer_name;
        this.supplierName = data.seller_name;
        this.ekspedisiName = data.vendor_name;
        this.spkModel.po = data.po_no;
        this.spkModel.main_do_type = data.do_type_id;
        this.spkModel.trip_type = data.trip_type_id;
        this.spkModel.charge_type = data.charge_type_id;
        this.spkModel.uom = data.uom_id;
        if(data.do_print_type == "DO_PUPUK"){
          this.pupuk = true;
        }
        else{
          this.pupuk = false;
        }
        this.complete = data.complete;
        this.modelCostType = null;
        this.tambahanModel.amount = 0;
        this.model = null;
        this.getMainAdditionalCost(data.spk_id.toString());
          }
        })
    }

  onSelectSPKTransfer(data: ListSPK){
    if (this.spkFrom_type != data.main_do_type_text){
      Swal.fire({
        title: "Tipe SPK tidak sama!",
        type: 'warning',
        confirmButtonText: '      Ok!     '
      });
    }
    else{
      this.spkTo = data.main_do_no;
      this.spkToId = data.spk_id;
      this.spkTo_type = data.main_do_type_text;
    }
  }
  
  saveDataSPK(){
    if(this.pupuk == false && (this.isNull(this.spkModel.main_do_no) || this.isNull(this.spkModel.contract_no)
    || this.isNull(this.spkModel.main_do_date) || this.isNull(this.spkModel.main_do_type) 
    || this.isNull(this.spkModel.po)  || this.isNull(this.spkModel.spo_no) ||  this.isNull(this.spkModel.contract_no)
    || this.isNull(this.spkModel.material_text) || this.isNull(this.spkModel.seller_id)
    || this.isNull(this.spkModel.route_id)  || this.isNull(this.spkModel.uom)
    || this.isNull(this.spkModel.customer_id))
    // if(this.pupuk == false && (this.isNull(this.spkModel.main_do_no) || this.isNull(this.spkModel.contract_no) || this.isNull(this.spkModel.start_date) 
    // || this.isNull(this.spkModel.main_do_date) || this.isNull(this.spkModel.main_do_type) ||  this.isNull(this.spkModel.contract_date) 
    // || this.isNull(this.spkModel.po) || this.isNull(this.spkModel.spk_date) || this.isNull(this.spkModel.finish_date) 
    // || this.isNull(this.spkModel.trip_type) || this.isNull(this.spkModel.spo_no) ||  this.isNull(this.spkModel.contract_no)
    // || this.isNull(this.spkModel.material_text) || this.isNull(this.spkModel.seller_id) || this.isNull(this.spkModel.invoice_type)
    // || this.isNull(this.spkModel.route_id) || this.isNull(this.spkModel.charge_type) || this.isNull(this.spkModel.uom)
    // || this.isNull(this.spkModel.customer_id))
    ){
      return Swal.fire({
        title: "Data Belum Lengkap!",
        type: 'warning',
        confirmButtonText: '      Ok!     '
      });
    }
    if (this.pupuk == true && (this.isNull(this.spkModel.jenis_pupuk) || this.isNull(this.spkModel.kebun) || this.isNull(this.spkModel.pembeli) )){
      return Swal.fire({
        title: "Data Belum Lengkap!",
        type: 'warning',
        confirmButtonText: '      Ok!     '
      });
    }


      Swal.fire({
        title: 'Simpan Data?',
        type: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: '      Yes     '
      }).then((result) => {
        if (result.value) {
          this.spinner.show();
          this.spkModel.costMain = [];
          for (let i = 0; i < this.dataTambahanSave.length; i++) {
            this.spkModel.costMain.push({
              cost_type : this.dataTambahanSave[i].cost_type,
              charge_type : this.dataTambahanSave[i].charge_type,
              amount: this.dataTambahanSave[i].amount,
              id: this.dataTambahanSave[i].id,
              deleted: this.dataTambahanSave[i].deleted,

          });
          }
          this.service.addSPK(this.spkModel).subscribe(resp => {
            this.spinner.hide();
            if(resp.succes == false){
              Swal.fire({
                title: ""+resp.text,
                type: 'warning',
                confirmButtonText: '      Ok!     '
              });
            }
            else{
              Swal.fire({
                title: ""+resp.text,
                type: 'success',
                confirmButtonText: '      Ok!     '
              }).then((result) => {
                if (result.value) { 
                     this.modalSaveClose.nativeElement.click();
                     this.getDataListSPK(this.dtSPK, this.cbSPK,this.dataSpkDtoRaw, true);
                }              
              });
            }
          });
        }
      });
  }

  createDo(){
    if(this.manualDoModel.do_no == null || this.manualDoModel.do_date == null)
    {
      return Swal.fire({
        title: "Data Belum Lengkap!",
        type: 'warning',
        confirmButtonText: '      Ok!     '
      });
    }
    else{
      Swal.fire({
        title: 'Simpan Data?',
        type: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: '      Yes     '
      }).then((result) => {
        if (result.value) {
          this.spinner.show();
          this.service.createDOManual(this.manualDoModel).subscribe(resp => {
          this.spinner.hide();
          if(resp.succes == false){
            Swal.fire({
              title: ""+resp.text,
              type: 'warning',
              confirmButtonText: '      Ok!     '
            });
          }
          else{
            Swal.fire({
              title: ""+resp.text,
              type: 'success',
              confirmButtonText: '      Ok!     '
            }).then((result) => {
              if (result.value) { 
                this.getSpkDto
                this.getDetailDo(this.generateDoModel.main_do_id,"","");
                this.ModalManualHide.nativeElement.click();
                this.manualDoModel.do_no = null;
                this.manualDoModel.do_date = null;
              }              
            }, (error) => {
              ; 
            })
          }
          }); 
        }
      });       
    }
  }


    generateDO(){
      if(this.generateDoModel.do_count == null || this.generateDoModel.do_date == null)
      {
        Swal.fire({
          title: "Data Belum Lengkap!",
          type: 'warning',
          confirmButtonText: '      Ok!     '
        });
      }
      else{
        Swal.fire({
          title: 'Generate DO?',
          // text: "You won't be able to revert this!",
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: '     Yes     '
        }).then((result) => {
          if (result.value) {
            this.spinner.show();
        this.service.generateDo(this.generateDoModel).subscribe(resp => {
        this.spinner.hide();
        if(resp.succes == false){
          this.getDetailDo(this.generateDoModel.main_do_id,"","");
          this.ModalGenerateHide.nativeElement.click();
          Swal.fire({
            title: ""+resp.text,
            type: 'warning',
            confirmButtonText: '      Ok!     '
          });
        }
        else{
          Swal.fire({
            title: ""+resp.text,
            type: 'success',
            confirmButtonText: '      Ok!     '
          }).then((result) => {
            if (result.value) { 
              this.getSpkDto
              this.getDetailDo(this.generateDoModel.main_do_id,"","");
              this.ModalGenerateHide.nativeElement.click();
            }              
          }, (error) => {
            ; 
          })
        }
        });   
        }
      });

                  
      }
    }

    deleteSPK(data: ListSPK){
    Swal.fire({
      title: 'Delete Data?',
      // text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '     Yes     '
    }).then((result) => {
      if (result.value) {
        this.spinner.show();
        this.service.deleteSPKList(data.spk_id).subscribe(vehicle => { 
          this.spinner.hide();
          Swal.fire({
            type: 'success',
            confirmButtonText: '     Succes!     '
          }).then((result) => {
            if (result.value) { 
              this.getDataListSPK(this.dtSPK, this.cbSPK, this.dataSpkDtoRaw, true);
            }
          
          }, (error) => {
            //; 
          })
        });
      }
    
    }, (error) => {
      //; 
    });
  }

  onSelectCapacity(data: Capacity){
    this.capacityTon = data.quantityTon.toString()+" KG";
    $('.searchInputCapacity').val('');
    this.getDataCapacity(this.dtCapacity, this.cbCapacity, "", "", "",true);
    this.generateDoModel.do_count = Math.round(this.spkModel.quantity / data.quantityTon);
  }

  onSelectCustomer(data: Relation){
    this.customerName = data.relation_name;
    this.spkModel.customer_id = data.relation_id;
    $('.searchCustomer').val('');
    this.getDataRelation(this.dtCustomer, this.cbCustomer, "", "","","CUSTOMER","",true);
  }

  onSelectMaterial(data : Material){
    this.spkModel.material_id = data.material_id;
    this.spkModel.material_text = data.material_name;
    this.materialName = data.material_name;
    if(data.do_print_type == "DO_PUPUK"){
      this.pupuk = true;
    }
    else{
      this.pupuk = false;
    }
    this.getDataRoute(this.dtRoute, this.cbRoute,"", "", "", "", "",true);
    $('.searchMaterial').val('');
    this.getDataMaterial(this.dtMaterial, this.cbMaterial, "","",true);
  }
  
  onSelectKebun(data: Kebun){
    this.spkModel.pembeli = data.nama;
    this.spkModel.kebun = data.lokasi;
    $('.searchKebun').val('');
    this.getDataKebun(this.dtKebun, this.cbKebun,"","","",true);
  }

  onSelectEkspedisi(data: Relation){
    this.ekspedisiName = data.relation_name;
    this.spkModel.vendor_id = data.relation_id;
    $('.searchEkspedisi').val('');
    this.getDataEkspedisi(this.dtEkspedisi, this.cbEkspedisi, "", "","","Ekspedisi","",true);
  }  

  onSelectSupplier(data: Relation){
    this.supplierName = data.relation_name;
    this.spkModel.seller_id = data.relation_id;
    $('.searchSupplier').val('');
    this.getSupplier(this.dtSupplier, this.cbSupplier, "", "","","PENJUAL","",true);
  } 
  
  onSelectRoute(data: Route){
    this.spkModel.route_id = data.route_id;
    this.routeName = data.route_name;
    $('.searchRute').val('');
    this.getDataRoute(this.dtRoute, this.cbRoute,"",  "",  "",  "", null,true);
    
  } 

  showKebun(){
    this.componentKebun.modalTableKebun = true;
    this.dtTriggerKebun.next(0);
  }
  
  showSupplier(){
    this.dtTriggerSupplier.next(0);
  }

  showCustomer(){
    this.dtTriggerCustomer.next(0);
  }

  showRoute(){
    this.dtTriggerRoute.next(0);
  }

  showCapacity(){
    this.dtTriggerCapacity.next(0);
  }

  showEkspedisi(){
    this.dtTriggerEkspedisi.next(0);
  }

  showSPK(){
   this.dtTriggerSpk.next(0);
  }

  showMaterial(){
    this.dtTriggerMaterial.next(0);
  }

  showMaterial2(){
    this.dtTriggerMaterial2.next(0);
  }


  selectCost(data: CostType){
    this.costName = data.name;
    this.tambahanModel.cost_type = data.id;
  }

  selectChargeType(data: MasterCode){
    this.costType = data.text;
    this.tambahanModel.charge_type = data.id;
  }

  getDetailDo(id: string,no_do: string,status: string){
    this.qtySend = 0; this.qtyReceived = 0; this.estPack = 0; this.estTon = 0; this.rPcakSend = 0; this.rPackReceived = 0;
    this.service.getDetailDo(id, no_do, status).subscribe(resp => {
      this.listDoDetail = resp;
      this.listDoDetailSource = resp;
      this.listDoDetail.forEach(x => {
        this.qtySend += x.sendQty;
        this.qtyReceived += x.rcvQty;
        this.estPack += x.est_pack;
        this.estTon+= x.est_ton;
        this.rPcakSend+= x.packSend;
        this.rPackReceived+= x.packRcv;
      })
      this.dtTriggerDetail.next(0);
    });
    this.checkAllDO = false;
  }


  onSelectUpdateEstimation(data: ListDoDetail){
    this.updateEstimationModel.id = data.id;
    this.updateEstimationModel.est_pack = data.est_pack;
    this.updateEstimationModel.est_ton = data.est_ton;
    this.updateEstimationModel.est_pack_uom = data.est_pack_uom;
  }

  onSelectUpdateDoNo(data: any){
    this.updateDoNoModel.id = data.id;
    this.updateDoNoModel.no = data.do_no;
  }

  onSelectDetailDO(data: any){
    this.listDoDetail = [];
    if (data.main_do_type_text == "FGS"){
      this.ekspedisi = false;
    }
    else{
      this.ekspedisi = true;
    }
    this.spkModel = data;
    this.generateDoModel.main_do_id = data.spk_id;
    this.manualDoModel.main_do_id = data.spk_id;
    this.customerName = data.customer_name;
    this.getDetailDo(data.spk_id.toString(), "", "");
    this.service.getCalculateCapacity(data.spk_id, data.quantity, data.uom_name).subscribe(resp => {
      this.labelCalculate = resp.value;
    });
    var today = moment().format('YYYY-MM-DD');
    this.manualDoModel.do_date = today;
  }


  onSelectTransfer(data: ListSPK){
    this.spkFrom = data.spk_no;
    this.spkFrom_id = data.spk_id;
    this.spkFrom_type = data.main_do_type_text;
    this.service.getDetailDoTransfer(data.spk_id.toString()).subscribe(resp => {
      this.listDoDetail2 = resp;
      this.dtTriggerDetail2.next(0);
    });
  }

  getDataType(){
    this.service.getListMasterCode("ChargeType").subscribe(resp => {
      this.ChargeType = resp;
    });
    this.service.getListMasterCode("DoType").subscribe(resp => {
      this.DoType = resp;
    });
    this.service.getListMasterCode("UOM").subscribe(resp => {
      this.UOM = resp;
    });
    this.service.getListMasterCode("TripType").subscribe(resp => {
      this.TripType = resp;
    });
    this.service.getListMasterCode("InvoiceCalcType").subscribe(resp => {
      this.InvoiceCalcType = resp;
    });
    this.service.getListCostTypeAll("").subscribe(resp => {
      this.ListCostType = resp;
    });
    
  }
    
  getDataRelation(dataTablesParameters, callback, relation_code: string, relation_name: string, relation_address: string, 
    relation_type: string, relation_sl_code: string, padging : boolean){ 
    var page = 1;
    if (padging == true){
      page = parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length) + 1;
    }
    this.service.getListRelation(dataTablesParameters, page, dataTablesParameters.length, relation_code,
      relation_name, relation_address, relation_type, relation_sl_code)
      .subscribe(resp => { 
        this.dataRelation = resp.data;
        callback({
          recordsTotal: resp.totalCount,
          recordsFiltered: resp.totalCount,
           data:  []
        });
        this.dtTriggerCustomer.next(0);
    });
  }

  getDataEkspedisi(dataTablesParameters, callback, relation_code: string, relation_name: string, relation_address: string, 
    relation_type: string, relation_sl_code: string, padging : boolean){ 
    var page = 1;
    if (padging == true){
      page = parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length) + 1;
    }
    this.service.getListRelation(dataTablesParameters, page, dataTablesParameters.length, relation_code,
      relation_name, relation_address, relation_type, relation_sl_code)
      .subscribe(resp => { 
        this.dataEkspedisi = resp.data;
        callback({
          recordsTotal: resp.totalCount,
          recordsFiltered: resp.totalCount,
           data:  []
        });
    });
  }

  getSupplier(dataTablesParameters, callback, relation_code: string, relation_name: string, relation_address: string, 
    relation_type: string, relation_sl_code: string, padging : boolean){ 
    var page = 1;
    if (padging == true){
      page = parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length) + 1;
    }
    this.service.getListRelation(dataTablesParameters, page, dataTablesParameters.length, relation_code,
      relation_name, relation_address, relation_type, relation_sl_code)
      .subscribe(resp => { 
        this.dataSupplier = resp.data;
        callback({
          recordsTotal: resp.totalCount,
          recordsFiltered: resp.totalCount,
           data:  []
        });
        this.dtTriggerSupplier.next(0);
    });
  }

  getDataRoute(dataTablesParameters, callback, route_name: string, route_type: string, unloadtext: string, loadtext: string, material_id: string,padging : boolean){ 
    if (this.spkModel.material_text != null && this.spkModel.material_text != ""){
      material_id = this.spkModel.material_id.toString();
    }
    
    var page = 1;
    if (padging == true){
      page = parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length) + 1;
    }
    this.service.getListRoute(dataTablesParameters, page, dataTablesParameters.length, route_name, route_type, unloadtext, loadtext, material_id)
    .subscribe(resp => { 
        this.dataRoute = resp.data;
        callback({
          recordsTotal: resp.totalCount,
          recordsFiltered: resp.totalCount,
           data:  []
        });
    });
  }

  AddDataTambahan() {
    if(this.isNull(this.costName) || this.isNull(this.costType)){
      Swal.fire({
        title: "Data Belum Lengkap!",
        type: 'warning',
        confirmButtonText: '      Ok!     '
      });
    }
    else{
      this.dataTambahan.push( { cost_type: this.tambahanModel.cost_type, charge_type: this.tambahanModel.charge_type, 
        amount: this.tambahanModel.amount,  costName: this.costName, costType: this.costType} );
        this.dataTambahanSave.push( { cost_type: this.tambahanModel.cost_type, charge_type: this.tambahanModel.charge_type, 
          amount: this.tambahanModel.amount,  costName: this.costName, costType: this.costType, deleted: false, id: null} );
    }
  }

  deleteAsset(id: number)
  { 
    this.dataTambahanSave[id].deleted = true;
    this.dataTambahanSave = this.dataTambahanSave;
    // this.dataTambahanSave.splice(id, 1); this.changeDetectorRef.detectChanges(); 
    // console.log(JSON.stringify(this.dataTambahanSave))
  } 

  filterCategory(people: any[]): any[] {
    return people.filter(p => p.deleted != true);
  }

  getDataKebun(dataTablesParameters, callback, code: string, name: string, location: string, padging : boolean){ 
    var page = 1;
    if (padging == true){
      page = parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length) + 1;
    }
    this.service.getListKebun(dataTablesParameters, page, dataTablesParameters.length, code, name, location)
    .subscribe(resp => { 
        this.dataKebun = resp.data;
        callback({
          recordsTotal: resp.totalCount,
          recordsFiltered: resp.totalCount,
           data:  []
        });
    });
  }

  getDataMaterial(dataTablesParameters, callback, code: string, name: string, padging : boolean){ 
    var page = 1;
    if (padging == true){
      page = parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length) + 1;
    }
    this.service.getDataMaterialTest(dataTablesParameters, page, dataTablesParameters.length, code, name)
    .subscribe(resp => { 
        this.dataMaterial = resp.data;
        callback({
          recordsTotal: resp.totalCount,
          recordsFiltered: resp.totalCount,
          data:  []
          //  data:  resp.data
        });
    });
  }

  getDataMaterial2(dataTablesParameters, callback, code: string, name: string, padging : boolean){ 
    var page = 1;
    if (padging == true){
      page = parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length) + 1;
    }
    this.service.getDataMaterialTest(dataTablesParameters, page, dataTablesParameters.length, code, name)
    .subscribe(resp => { 
        this.dataMaterial2 = resp.data;
        callback({
          recordsTotal: resp.totalCount,
          recordsFiltered: resp.totalCount,
          data:  []
          //  data:  resp.data
        });
    });
  }

  onChangeDate(value){
    value = new Date(value);
  }

  getDataCapacity(dataTablesParameters, callback, code: string, name: string, vehicle: string, padging : boolean){ 
    var page = 1;
    if (padging == true){
      page = parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length) + 1;
    }
    this.service.getDataCapacity(dataTablesParameters, page, dataTablesParameters.length, code, name, vehicle)
    .subscribe(resp => { 
        this.dataCapacity = resp.data;
        callback({
          recordsTotal: resp.totalCount,
          recordsFiltered: resp.totalCount,
           data:  []
        });
    });
  }

  PrintTest(){
    this.service.testPrint("testprit1")
    .subscribe(resp => {
      let blob:Blob=resp.body as Blob;
      let url = window.URL.createObjectURL(blob);
      window.open(url);
    });
  }


isNull(str){
  return str === null || str.match(/^[\s\n\r]*$/) !== null;
}

clearInput(){
  this.see = false;
  this.edit = true;
  this.action = "";
  this.complete = false;
  var today = moment().format('YYYY-MM-DD');
  this.materialName = null; this.routeName = null; this.customerName = null; this.supplierName = null;
  this.spkModel = { id: null, parent_id: null, main_do_type: this.DoType[1].id, main_do_no: '', cost: 0, 
  main_do_date: today, spk_no: '', spk_date: today, contract_no: '', po: '', contract_date: today, 
  customer_id: null, route_id: null, seller_id: null, vendor_id: null, 
  material_id: null, material_text: '', quantity: 0, uom: this.UOM[1].id, charge_type: this.ChargeType[0].id, 
  trip_type: this.TripType[2].id, invoice_type: this.InvoiceCalcType[this.InvoiceCalcType.length - 1].id, ffa: 0, mi: 0, kk: 0, tolerance: 0, complete: false,
  start_date: today, finish_date: today, remark: '', pembeli: '', kebun: '', jenis_pupuk: '', spo_no: '', costMain: new Array() }
  this.ekspedisiName = null;
  // this.spkModel.main_do_type = this.DoType[0].id;
  // this.spkModel.trip_type = this.TripType[this.TripType.length - 1].id;
  // this.spkModel.charge_type = this.ChargeType[0].id;
  // this.spkModel.uom = this.UOM[this.UOM.length - 3].id;
  // this.spkModel.invoice_type = this.InvoiceCalcType[this.InvoiceCalcType.length - 1].id;
  this.dataTambahan = [];
  this.tambahanModel = { cost_type: null, charge_type: null, amount: 0  };
  this.modelCostType = null;
  this.tambahanModel.amount = 0;
  this.model = null;
  //this.modelCostType = null;
}

exportSPK(){

  this.printModel.location = localStorage.getItem('locationname');
  if (this.printModel.from != null && this.printModel.to != null){
  this.spinner.show();
    this.service.printSPKList(this.printModel).subscribe(resp =>{
      this.spinner.hide();
      const blob = new Blob([resp.body],
        { type: 'application/vnd.ms-excel' });
    const file = new File([blob], 'RekapOutstanding.xlsx',
        { type: 'application/vnd.ms-excel' });

      saveAs(file);
      this.modalPrint.nativeElement.click();
    })
  }
  else{
    
    Swal.fire({
      title: "Data Belum Lengkap!",
      type: 'warning',
      confirmButtonText: '      Ok!     '
    });
  }
}

printDo(data: ListDoDetail){
  this.spinner.show();
  this.service.printDO(data.do_no, data.material_id.toString(), data.id.toString(), localStorage.getItem('locationname')).subscribe(resp =>{
    this.spinner.hide();
  let blob:Blob=resp.body as Blob;
    let url = window.URL.createObjectURL(blob);
    window.open(url);
  })
}

printDOSelected(){
  if (this.listDoDetail.filter(x => x.select == true).length < 1){
    return Swal.fire({
      title: "Belum Ada Data yg Dipilih!",
      type: 'warning',
      confirmButtonText: '      Ok!     '
    });
  }
  this.spinner.show();
  this.printModelSelected.listId = [];
  this.printModelSelected.location = this.locationName;
  this.listDoDetail.filter(x => x.select == true).forEach(list => {
    this.printModelSelected.listId.push({
      id: list.id,
    })
  })
  this.service.printDoSelected(this.printModelSelected).subscribe(resp =>{
    this.spinner.hide();
    let blob:Blob=resp.body as Blob;
    let url = window.URL.createObjectURL(blob);
    window.open(url);
    //this.clean();
  });

}

printDOSelectedEkspedisi(){
  if (this.listDoDetail.filter(x => x.select == true).length < 1){
    return Swal.fire({
      title: "Belum Ada Data yg Dipilih!",
      type: 'warning',
      confirmButtonText: '      Ok!     '
    });
  }
  var non = 0;
  this.printModelSelected.listId = [];
  this.printModelSelected.location = this.locationName;
  this.listDoDetail.filter(x => x.select == true).forEach(list => {
    if (list.vendor == null){
      non =+ 1;
    }
    this.printModelSelected.listId.push({
      id: list.id,
    })
  })
  if (non > 0){
    
    return Swal.fire({
      title: "Ada Data Non Ekspedisi Terpilih!",
      type: 'warning',
      confirmButtonText: '      Ok!     '
    });
  }
  else{
    this.spinner.show();
    this.service.printDoSelected(this.printModelSelected).subscribe(resp =>{
      this.spinner.hide();
      let blob:Blob=resp.body as Blob;
      let url = window.URL.createObjectURL(blob);
      window.open(url);
      //this.clean();
    });

  }
}

checkSelected(data: ListDoDetail){
  if (this.listDoDetail.find(x => x.id == data.id).select != true){
       this.listDoDetail.find(x => x.id == data.id).select = true;
  }
  else{
    this.listDoDetail.find(x => x.id == data.id).select = false;
  }
}

checkSelected2(data: ListDoDetail){
  if (this.listDoDetail2.find(x => x.id == data.id).select != true){
       this.listDoDetail2.find(x => x.id == data.id).select = true;
  }
  else{
    this.listDoDetail2.find(x => x.id == data.id).select = false;
  }
}

userAcces(){
  this.locationName = localStorage.getItem("locationname");
  const menuUser = localStorage.getItem("menu");
  if (menuUser != "All"){
   
    this.btnCreate = false;
    this.btnTransfer = false;
    this.btnDelete = false;
    this.btnUpdate = false;
    this.btnComplete = false;
    this.btnCancel = false;
    this.btnCrudDO = false;
    this.btnDetailPrint = false;
    this.btnDetailPrintPonti = false;
    this.btnDetailUpdate = false;
    this.btnDetailDelete = false;
    this.btnDetailClose = false;
    this.printDoEkspedisi = false

    if (this.locationName != "Palembang"){
      let arrayMenu =  menuUser.split(",");
      if (arrayMenu.includes("spk") == false){
        return this.router.navigate(["/home"]);
      }
      this.authAcces = JSON.parse(localStorage.getItem("authAccess"));
      
      this.authAcces.filter(item => item.menu_name == "CreateSpkForm").forEach(i => {
          if(i.name == "INSERT"){
            this.btnCreate = true;
          }
          if(i.name == "COMPLETE_SPK"){
            this.btnComplete = true;
          }
          if(i.name == "UPDATE"){
            this.btnUpdate = true;
          }
          if(i.name == "DELETE"){
            this.btnDelete = true;
          }
          if(i.name == "CANCEL_SPK"){
            this.btnCancel = true;
          }
          if(i.name == "TRANSFER_DO_KECIL"){
            this.btnTransfer = true;
          }
          if(i.name == "DO_DETAIL_CRUD"){
            this.btnCrudDO = true;
          }
          if(i.name == "CRUD"){
            this.btnCrudDO = true;
            this.btnUpdate = true;
            this.btnCreate = true;
            this.btnDelete = true;
          }
  
   
      });
  
      this.authAcces.filter(item => item.menu_name == "DoListofSpk").forEach(i => {
        if(i.name == "DELETE"){
          this.btnDetailDelete = true;
        }
        if(i.name == "PRINT"){
          if (this.locationName == "Pontianak"){
            this.btnDetailPrint = false;
            this.btnDetailPrintPonti =true;
          }
          else{
            this.btnDetailPrintPonti =false
            this.btnDetailPrint = true;
          }
        }
        if(i.name == "UPDATE"){
          this.btnDetailUpdate = true;
        }
        if(i.name == "CLOSE_TRIP"){
          this.btnDetailClose = true;
        }
      });
    }
    else{
      this.authAccessPlb = JSON.parse(localStorage.getItem("authAccessPlb"));
      var a = this.authAccessPlb.filter(item => item.subMenu  == "spk-list").length;
      if (a == 0){
        return this.router.navigate(["/home"]);
      }
      
      this.authAccessPlb.filter(item => item.subMenu == "spk-list").forEach(i => {
          if(i.auth == "COMPLETE_SPK"){
            this.btnComplete = true;
            this.btnDetailClose = true;
          }
          if(i.auth == "CANCEL_SPK"){
            this.btnCancel = true;
          }
          if(i.auth == "TRANSFER_DO_KECIL"){
            this.btnTransfer = true;
          }
          if(i.auth == "CRUD"){
            this.btnCrudDO = true;
            this.btnUpdate = true;
            this.btnCreate = true;
            this.btnDelete = true;
            this.btnDetailUpdate = true;
            this.btnDetailDelete = true;
          }
          if(i.auth == "PRINT_DO_EKSPEDISI"){
            this.printDoEkspedisi = true;
          }

          if(i.auth == "PRINT"){
            if (this.locationName == "Pontianak"){
              this.btnDetailPrint = false;
              this.btnDetailPrintPonti =true;
            }
            else{
              this.btnDetailPrintPonti =false
              this.btnDetailPrint = true;
            }
          }
  
   
      });
    }
    
  }

}

openEstimasiList(){
  if (this.listDoDetail.filter(x => x.select == true).length < 1){
    return Swal.fire({
      title: "Belum Ada Data yg Dipilih!",
      type: 'warning',
      confirmButtonText: '      Ok!     '
    });
  }
  this.modalOpenEstList.nativeElement.click();
}

checkAll(){
  if (this.listDoDetail[0].select == false){ 
   this.listDoDetail.forEach(x => {
      x.select = true;
    })
  }
  else{
    this.listDoDetail.forEach(x => {
      x.select = false;
    })
  }
  console.log(JSON.stringify(this.listDoDetail))
}

showDataSPK(data: any){
      this.see = true;
      this.modalSPKShow.nativeElement.click();
      this.spkModel = data;
      this.spkModel.id = data.spk_id;
      this.materialName = data.material_name;
      this.routeName = data.route_name;
      this.customerName = data.customer_name;
      this.supplierName = data.seller_name;
      this.ekspedisiName = data.vendor_name;
      this.spkModel.po = data.po_no;
      this.spkModel.main_do_type = data.do_type_id;
      this.spkModel.trip_type = data.trip_type_id;
      this.spkModel.charge_type = data.charge_type_id;
      this.spkModel.uom = data.uom_id;
      this.complete = data.complete;
      this.modelCostType = null;
      this.tambahanModel.amount = 0;
      this.getMainAdditionalCost(data.spk_id.toString());
}

editDoNO(data: ListDoDetail){
  this.manualDoModel.id = data.id;
  this.manualDoModel.do_no = data.do_no;
  this.manualDoModel.do_date = moment(data.do_date).format('YYYY-MM-DD');;
}

clearDoManual(){
  this.manualDoModel.id = null;
  this.manualDoModel.do_no = null;
}

onSelectMaterial2(data: Material){
  if (this.printModel.materialListId == "" || this.printModel.materialListId == null){
    this.printModel.materialListId = data.material_id;
  }
  else{
      this.printModel.materialListId = this.printModel.materialListId +","+data.material_id;
  }
  if (this.materialList == "")
  {
    this.materialList = data.material_name;
  }
  else{
    this.materialList = this.materialList + "\n" +data.material_name;
  }
  $('.searchMaterial2').val('');
  this.getDataMaterial2(this.dtMaterial, this.cbMaterial, "","",true);
}


  setNullGenerate(){
    this.generateDoModel.do_count = 0;
    var today = moment().format('YYYY-MM-DD');
    this.generateDoModel.do_date = today;
  }

  deleteDo(data: ListDoDetail){
    this.deleteDOModel.id = data.id;
    if (data.status_text != "New"){
      return Swal.fire({
        title: "Tidak dapat menghapus DO "+data.do_no+" yang berstatus "+data.status_text,
        type: 'warning',
        confirmButtonText: '      Ok!     '
      });
    }
    Swal.fire({
      title: 'Hapus DO?',
      type: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '      Yes     '
    }).then((result) => {
      if (result.value) {
        this.service.deleteDoDetail(this.deleteDOModel).subscribe(resp => {
          if(resp.succes == false){
            Swal.fire({
              title: ""+resp.text,
              type: 'warning',
              confirmButtonText: '      Ok!     '
            });
          }
          else{
            Swal.fire({
              title: ""+resp.text,
              type: 'success',
              confirmButtonText: '      Ok!     '
            }).then((result) => {
              if (result.value) { 
                this.getDetailDo(this.generateDoModel.main_do_id,"","");
                this.ModalGenerateHide.nativeElement.click();
              }              
            }, (error) => {
              ; 
            })
          }
    
        })
      }

    });
    
  }

  clearSearch(){
    this.searchModel = { doNo: null, doDateTo: null, doType: null, doDateFrom: null,spkNo: null,contractNo: null,
      spkDateFrom: null,spkDateTo: null,contractFrom: null,contractTo: null,seller: null,customer: null,
      material: null,route: null,detailDo: null,complete: null,};
      this.spkModel.seller_id = null; this.spkModel.customer_id = null; this.spkModel.material_id = null; 
      this.spkModel.route_id = null; this.customerName = null; this.materialName = null;
      this.routeName = null; this.supplierName = null;
  }
  
  searchData(){
    this.dataSpkDtoRaw.doNo = this.searchModel.doNo;
    this.dataSpkDtoRaw.doType = this.searchModel.doType;
    this.dataSpkDtoRaw.doDateTo = this.searchModel.doDateTo ;
    this.dataSpkDtoRaw.doDateFrom = this.searchModel.doDateFrom ;
    this.dataSpkDtoRaw.spkNo = this.searchModel.spkNo;
    this.dataSpkDtoRaw.contractNo = this.searchModel.contractNo;
    this.dataSpkDtoRaw.spkDateFrom = this.searchModel.spkDateFrom ;
    this.dataSpkDtoRaw.spkDateTo = this.searchModel.spkDateTo ;
    this.dataSpkDtoRaw.contractFrom = this.searchModel.contractFrom ;
    this.dataSpkDtoRaw.contractTo = this.searchModel.contractTo ;
    this.dataSpkDtoRaw.seller = this.spkModel.seller_id;
    this.dataSpkDtoRaw.customer = this.spkModel.customer_id;
    this.dataSpkDtoRaw.material = this.spkModel.material_id;
    this.dataSpkDtoRaw.route = this.spkModel.route_id;
    this.dataSpkDtoRaw.detailDo = this.searchModel.detailDo;
    this.dataSpkDtoRaw.complete = this.searchModel.complete;
    this.getDataListSPK(this.dtSPK, this.cbSPK,this.dataSpkDtoRaw, true);
  }
     
  setSPO(){
    this.spkModel.spo_no = this.spkModel.spk_no;
  }


  updateDoNoManual(){
    Swal.fire({
      title: 'Ubah No Do?',
      // text: "You won't be able to revert this!",
      type: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '      Yes     '
    }).then((result) => {

      if (result.value) {
        this.spinner.show();
        this.service.updateNoDoManual(this.updateDoNoModel).subscribe(resp => {
          this.spinner.hide();
          if(resp.succes == false){
            Swal.fire({
              title: ""+resp.text,
              type: 'warning',
              confirmButtonText: '      Ok!     '
            });
          }
          else{
            Swal.fire({
              title: ""+resp.text,
              type: 'success',
              confirmButtonText: '      Ok!     '
            }).then((result) => {
              if (result) { 
                this.modalUpdateDoNoClose.nativeElement.click();
                this.getDetailDo(this.generateDoModel.main_do_id.toString(), "", "");
              }              
            }, (error) => {
            })
          }
        });
        this.spinner.hide();

      }
    
    }, (error) => {
    });
}

sort(column){
  this.listDoDetail = this.listDoDetailSource;
  if (column != "All"){
    this.listDoDetail = this.listDoDetail.filter(x => x.status_text == column)
  }
}

filterDo($event){
  this.listDoDetail = this.listDoDetailSource;
  this.listDoDetail = this.listDoDetail.filter(x => x.do_no.includes($event.target.value))
  .sort((a,b) => a.do_no.localeCompare(b.do_no));
}


exportAsXLSX():void {
      this.appServ.exportAsExcelFile(this.listDoDetail, 'List_DO_'+this.listDoDetail[0].main_do_no);
}

}



