import { AfterViewInit, Component, OnInit, ViewChild,Input, ChangeDetectorRef, ElementRef  } from '@angular/core';
import { DataTableDirective } from 'angular-datatables'
import { TransportService} from '../../../../services/api.service';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { Vehicle } from '@/model/vehicle';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MasterCode } from '@/model/masterCode';
import { TabsetComponent, TabDirective } from 'ngx-bootstrap/tabs';
import { Router } from '@angular/router';
import { Relation } from '@/model/relation';
import { RespDetailDo } from '@/model/respDetailDo';
import { InvoiceRaw, ListOfInvoice } from '@/model/listOfInvoice';
import { ListSPK } from '@/model/listSPK';
import { SpkDto } from '@/model/spkDto';
import { Material } from '@/model/material';
import { LocationSettingBank } from '@/model/locationSettingBank';
import { InvoiceGenerateDto } from '@/model/invoiceGenerateDto';
import { ListInvoiceDetail } from '@/model/listInvoiceDetail';
import { Guid } from 'guid-typescript';
import { LocationSettingSignature } from '@/model/locationSettingSignature';
import { UserAuth } from '@/model/userAuth';
import moment from 'moment';
import saveAs from 'file-saver';
import { AuthMenu } from '@/model/masterSubMenu';
import { UserAuthNew } from '@/model/respDto';
import { RevenueType } from '@/model/invoiceManual';

@Component({
  selector: 'app-invoice-list',
  templateUrl: './invoice-list.component.html',
  styleUrls: ['./invoice-list.component.scss']
})
export class InvoiceListComponent  implements OnInit, AfterViewInit {
  @ViewChild(TabsetComponent) tabset: TabsetComponent;
  spkForm: FormGroup;
  model : any;
  typeR : any;
  @ViewChild('clickTab') clickTab;
  @ViewChild('modalSaveClose') modalSaveClose: ElementRef;
  @ViewChild('modalPrint') modalPrint: ElementRef;
  @ViewChild('modalDetail') modalDetail: ElementRef;
  @ViewChild('modalDetailClose') modalDetailClose: ElementRef;
  @ViewChild('modalFinishClose') modalFinishClose: ElementRef;
  @ViewChild('modalCreateClose') modalCreateClose: ElementRef; 
  @ViewChild('hideSPK') hideSPK: ElementRef; 
  @ViewChild('debtClick') debtClick: ElementRef; 
  @ViewChild('paymentClick') paymentClick: ElementRef;
  @ViewChild('modalCustomerHide') modalCustomerHide: ElementRef;
  @ViewChild('ModalSignatureShow') ModalSignatureShow: ElementRef;
  
  
 //@ViewChild('currentTabId') currentTabId; 
  listInvoiceStatus: MasterCode[] = [];
  revenueType: RevenueType[] = [];
  listInvoiceType: MasterCode[] = [];
  type : string = "text";
  public currentTabId: number; 
  closeForm: FormGroup;
  @ViewChild(DataTableDirective, {static: false})
  datatabdtOptionsnt: DataTableDirective;
  dtOptions: any = {};
  dataCancel : any = {};
  dtOptionsGenerate: any = {};
  dtOptionsDetailList: any = {};
  dtOptionsDetail: any = {};
  dtOptionsMaterial: any = {};
  dtOptionsInvoice: any = {};
  dtOptionsCustomer: any = {};
  dtOptionsEkspedisi: any = {};
  dtTrigger = new Subject();
  dtTriggerDetailList = new Subject();
  dtTriggerMaterial = new Subject();
  dtTriggerCustomer = new Subject();
  dtTriggerInvoice = new Subject();
  dtTriggerEkspedisi = new Subject();
  dtTriggerGenerate = new Subject();
  dtTriggerDetail = new Subject();
  checkPaid: Boolean = false;
  dataRelation: Relation[]=[];
  dataVehicles: Vehicle[] = [];
  dataEkspedisi: Relation[]=[];
  dataSupplier: Relation[]=[];
  public dataType: MasterCode[] = [];
  dataListSPK: ListSPK[] = [];
  dataListDo: RespDetailDo[] = [];
  dataSignature: LocationSettingSignature[] = [];
  public dataSpkDto: any;  
  dataInvRawDto : InvoiceRaw;
  name: string = '';
  finishShow: Boolean;
  dataTotalPayment: number = 0;
  dataListTripRecord: ListOfInvoice[] = [];
  action: string;
  public dt: any;
  public cb: any;
  public dataTripDto: any;
  total: number;
  invoiceNo : string;
  printDateFrom : Date;
  printDateTo : Date;
  dataBank: LocationSettingBank[] = [];
  actionUpdate: Boolean = false;
  cost: Boolean = false;
  totalHutangTrip = 0;
  dataMaterial: Material[] = [];
  spkList: string = "";
  materialList: string = "";
  customer: string = "";
  ekspedisiSelect: string = "";
  AP: Boolean = false;
  signature1: Guid;
  signature2: Guid;
  dataListGenereate: InvoiceGenerateDto[] = [];
  detailListInvoice: ListInvoiceDetail[] = [];
  tipeTrip: string;
  pph: Boolean;
  filter: string;
  authAcces: UserAuth[] =[];
  authAccessPlb: UserAuthNew[] =[];
  btnCreate: Boolean = true;
  btnPrint: Boolean = true;
  btnRelease: Boolean = true;
  btnCancel: Boolean = true;
  custFind: Boolean = false;
  start: Boolean = false;
  @Input() invoiceModel = { invoice_type: null, relation_name: null, statusInvoice: null, total_payment: null, second_payment: null, split_oa: null, show_oa: false,
    external_spk_no: null, external_spk_date: null, external_spk_start_date: null, external_spk_finish_date: null, customer_id: null, 
    vendor_id: null, invoice_date: null, expired_date: null, invoice_no: null, invoice_no2: null,
    main_do_id: null, merge_oa: null,  factur_no: null,  bank: null, bank_number: null, generateDtos: new Array(),
    material_id : null,  realization_from: null, realization_to: null,  complete_from: null,
    complete_to: null, include_ppn: null, include_pph: null,   bill_to: null, amount: 0, status: null, invoiceRun: null,
    ship: null, revenueType: null
   }
   @Input() reportModel = {from: null, to: null, location: null};
   @Input() searchModel = {invoiceNo: null, relation_type: null, spkNo: null, customerId: null, 
    invoiceDate: null, statusId: null, typeId: null, cancel: false}
    // @Input() searchModel = { doNo: null, doDateTo: null, doType: null, doDateFrom: null,spkNo: null,contractNo: null,
    //   spkDateFrom: null,spkDateTo: null,contractFrom: null,contractTo: null,seller: null,customer: null,
    //   material: null,route: null,detailDo: null,complete: null,};
   @Input() printInvoice = { id: null, sign_Id: null, pajak: false}
   @Input() printInvoice2 = { id: null, sign_Id: null}
   @Input() finishModel = { id: null, delivery: null}
   totalInv: number = 0;
   totalInvOth: number = 0;
   totalSend: number = 0;
   totalRcv: number = 0;
   select: number = 0;
   totalData: number = 0;
   location: string;
  constructor( public service: TransportService, private router: Router, private spinner: NgxSpinnerService, fb: FormBuilder, private changeDetectorRef: ChangeDetectorRef) {
    this.closeForm = fb.group({
      'payDate': [null, Validators.required],
      'reason': [null, Validators.required],
      // 'totalAmount': [],
      // 'companyCost': [],
      // 'totalUangJalan': [],
      // 'sisa': [],
      'terms': [false]
    });
  }

    ngOnInit(): void {
      this.start = true;
      this.getListType();
      this.userAcces();
      this.service.getListBank().subscribe(resp => {
        this.dataBank = resp;
      })
      this.dtOptionsInvoice = {     
        retrieve: true,
        pageLength: 10,
        ordering : false,
        scrollX: true,
        scrollCollapse: true,
        serverSide: true,
        processing: true,
        searching : false,
        ajax: (dataTablesParameters: any, callback, dataDto: InvoiceRaw) => {
         this.dataInvRawDto = dataDto;
         this.service.getListMasterCode("InvoiceStatus")
          .subscribe(resp => { 
              this.listInvoiceStatus = resp;
              if (this.start == true){ 
                if (this.listInvoiceStatus.length > 1){
                  dataDto.statusId = this.listInvoiceStatus[3].id;
                }
               }
            this.dt = dataTablesParameters; this.cb = callback;
            this.getListInvoice(dataTablesParameters, callback,  dataDto, true );
          });
         

        },
        columnDefs: [
          { width: 100, targets: [2,3,4,5,6,7,8,10,12],
          },
          { width: 200, targets: [0,1] },
          { width: 300, targets: [11] },
        ],
      };

      this.dtOptions = {
        retrieve: true,
        scrollX: true,
        scrollCollapse: true,
        serverSide: true,
        processing: true,
        searching : false,
        ajax: (dataTablesParameters: any, callback, dataDto: SpkDto) => {
         this.dataSpkDto = dataDto
         this.dt = dataTablesParameters; this.cb = callback;
         this.getDataListSPK(dataTablesParameters, callback, dataDto, true);

        },
        columnDefs: [
          { width: 100, targets: [1,2,3,4,5,6,7,8,10,11,12,13,14,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30] },
          { width: 200, targets: [9, 15] },
          { width: 200, targets: [0] }
        ],
      };

      
      this.dtOptionsMaterial = {
        retrieve: true,
        pageLength: 5,
        scrollX: true,
        scrollCollapse: true,
        serverSide: true,
        processing: true,
        searching : false,
        ajax: (dataTablesParameters: any, callback) => {
          this.dt = dataTablesParameters; this.cb = callback;
          this.getDataMaterial(dataTablesParameters, callback, $('#codeMaterial').val().toString(), $('#nameMaterial').val().toString(),true);
        },
        columnDefs: [
          { width: 100, targets: 0 },
          { width: 200, targets: 2 },
        ]
      };

      this.dtOptionsEkspedisi = {
        retrieve: true,
        pageLength: 5,
        scrollX: true,
        scrollCollapse: true,
        serverSide: true,
        processing: true,
        searching : false,
        ajax: (dataTablesParameters: any, callback, dataDto: SpkDto) => {
         this.getDataEkspedisi(dataTablesParameters, callback, "", "","","Ekspedisi","",true);
        },
        columnDefs: [
          { width: 100, targets: [0,1,4,5,6,7,8,9,10] },
          { width: 200, targets: 2 },
          { width: 300, targets: 3 },
        ],
      };

      this.dtOptionsGenerate = {
        retrieve: true,
        paging: false,
        ordering : false,
        bInfo : false,
        scrollX: true,
        scrollCollapse: true,
        //serverSide: true,
        searching : false,
        columnDefs: [
          { width: 100, targets: [2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19] },
          { width: 30, targets: [0,1] },
        ],
      };

      this.dtOptionsDetailList = {
        retrieve: true,
        pageLength: 10,
        ordering : false,
        // scrollX: true,
        // scrollCollapse: true,
        searching : false,
        columnDefs: [
          { width: 100, targets: [0,3,4,5,6,7,8,9,10,11,12,13] },
          { width: 200, targets: [1,2] },
        ],
      };
      
      this.dtOptionsCustomer = {
        retrieve: true,
        pageLength: 5,
        scrollX: true,
        scrollCollapse: true,
        serverSide: true,
        processing: true,
        searching : false,
        ajax: (dataTablesParameters: any, callback, dataDto: SpkDto) => {
         this.dt = dataTablesParameters; this.cb = callback;
         this.getDataRelation(dataTablesParameters, callback, "", "","","CUSTOMER","",true);
        },
        columnDefs: [
          { width: 100, targets: [0,1,4,5,6,7,8,9,10] },
          { width: 200, targets: 2 },
          { width: 300, targets: 3 },
        ],
      };

      this.service.getRevenueType().subscribe( resp =>{
        this.revenueType = resp;
      })
     
     
      $('.searchInput2').on("keyup", function(e) {
            
        var key = e.which;
        if (key == 13) // the enter key ascii code
        {
          that.dataSpkDto.main_do_no =  $('#main_do_no').val().toString();
          that.dataSpkDto.main_do_type =  $('#main_do_type').val().toString();
          that.dataSpkDto.spo_no =  $('#spo_no').val().toString();
          that.dataSpkDto.spk_date =  $('#spk_date').val().toString();
          that.dataSpkDto.spk_no =  $('#spk_no').val().toString();
          that.dataSpkDto.contract_no =  $('#contract_no').val().toString();
          that.dataSpkDto.po_no =  $('#po_no').val().toString();
          that.dataSpkDto.contract_date =  $('#contract_date').val().toString();
          that.dataSpkDto.customer_name =  $('#customer_name').val().toString();
          that.dataSpkDto.start_date =  $('#start_date').val().toString();
          that.dataSpkDto.finish_date =  $('#finish_date').val().toString();
          that.dataSpkDto.route_name =  $('#route_name').val().toString();
          that.dataSpkDto.route_from =  $('#route_from').val().toString();
          that.dataSpkDto.route_to =  $('#route_to').val().toString();
          that.dataSpkDto.seller_name =  $('#seller_name').val().toString();
          that.dataSpkDto.material_name =  $('#material_name').val().toString();
          that.dataSpkDto.quantity =  $('#quantity').val().toString();
          that.dataSpkDto.uom_name =  $('#uom_name').val().toString();
          that.dataSpkDto.cost =  $('#cost').val().toString();
          that.dataSpkDto.charge_type =  $('#charge_type').val().toString();
          that.dataSpkDto.trip_type_name =  $('#trip_type_name').val().toString();
          that.dataSpkDto.ffa =  $('#ffa').val().toString();
          that.dataSpkDto.mi =  $('#mi').val().toString();
          that.dataSpkDto.kk =  $('#kk').val().toString();
          that.dataSpkDto.tolerance =  $('#tolerance').val().toString();
          that.dataSpkDto.remark =  $('#remark').val().toString();
          that.getDataListSPK(that.dt, that.cb, that.dataSpkDto, false );
        }
      });

      $('.searchMaterial').on("keyup", function(e) {
        var key = e.which;
        if (key == 13) // the enter key ascii code
        {
          that.getDataMaterial(that.dt, that.cb, $('#codeMaterial').val().toString(), $('#nameMaterial').val().toString(),false);
        }
      }); 
     

      const that = this;

      $('.searchInput').on("keyup", function(e) {
            
        var key = e.which;
        if (key == 13) // the enter key ascii code
        {
          that.dataTripDto.relation_name =  $('#relation_name').val().toString();
          that.dataTripDto.statusInvoice =  $('#statusInvoice').val().toString();
          that.dataTripDto.type_text =  $('#type_text').val().toString();
          that.dataTripDto.invoice_no =  $('#invoice_no').val().toString();
          that.dataTripDto.invoice_date =  $('#invoice_date').val().toString();
          that.dataTripDto.bank_name =  $('#bank_name').val().toString();
          that.dataTripDto.bank_account =  $('#bank_account').val().toString();
          that.dataTripDto.spk_no =  $('#spk_no_').val().toString();
          that.getListInvoice(that.dt, that.cb, that.dataTripDto, false );
        }
      });

      $('.searchCustomer').on("keyup", function(e) {
        var key = e.which;
        if (key == 13) // the enter key ascii code
        {
          that.getDataRelation(that.dt, that.cb, $('#codecustomer').val().toString(), $('#namecustomer').val().toString(), 
          $('#addresscustomer').val().toString(), "CUSTOMER",
          $('#slcustomer').val().toString(),false);
        }
      });  

      $('.searchEkspedisi').on("keyup", function(e) {
        var key = e.which;
        if (key == 13) // the enter key ascii code
        {
          that.getDataEkspedisi(that.dt, that.cb, $('#codeEks').val().toString(), $('#nameEks').val().toString(), 
          $('#addressEks').val().toString(), "EKSPEDISI",
          $('#slEks').val().toString(),false);
        }
      });  
      this.getSignature();
      this.location = localStorage.getItem('locationname');
    }   
    
    ngAfterViewInit(): void {
      this.dtTriggerInvoice.next(this.dtOptionsInvoice);
      this.dtTriggerGenerate.next(this.dtOptionsGenerate);
    }
  
    
    ngOnDestroy(): void {
     this.dtTriggerInvoice.unsubscribe();
     this.dtTriggerGenerate.unsubscribe();
    }

  //   getListInvoice(dataTablesParameters, callback,data: ListOfInvoice, padging){
    
  //   var page = 1;
  //     if (padging == true){
  //       page = parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length) + 1;
  //     }
  //     data.page = page;
  //     data.perPage = dataTablesParameters.length;
      
  //     this.service.getListInvoice(data)
  //     .subscribe(resp => { 
  //         this.dataListTripRecord = resp.data;
  //         callback({
  //           recordsTotal: resp.totalCount,
  //           recordsFiltered: resp.totalCount,
  //            data:  []
  //         });
  //     });
  // }

  getListInvoice(dataTablesParameters, callback,data: InvoiceRaw, padging){
    var page = 1;
      if (padging == true){
        page = parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length) + 1;
      }
      data.page = page;
      data.perPage = dataTablesParameters.length;
      
      this.service.getListInvoiceRaw(data)
      .subscribe(resp => { 
          this.dataListTripRecord = resp;
          callback({
            recordsTotal: dataTablesParameters.length,
            recordsFiltered: dataTablesParameters.length,
             data:  []
          });
      });
  }

  getDataMaterial(dataTablesParameters, callback, code: string, name: string, padging : boolean){ 
    var page = 1;
    if (padging == true){
      page = parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length) + 1;
    }
    this.service.getDataMaterialTest(dataTablesParameters, page, dataTablesParameters.length, code, name)
    .subscribe(resp => { 
        this.dataMaterial = resp.data;
        callback({
          recordsTotal: resp.totalCount,
          recordsFiltered: resp.totalCount,
          data:  []
          //  data:  resp.data
        });
    });
  }

  getDataRelation(dataTablesParameters, callback, relation_code: string, relation_name: string, relation_address: string, 
    relation_type: string, relation_sl_code: string, padging : boolean){ 
    var page = 1;
    if (padging == true){
      page = parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length) + 1;
    }
    this.service.getListRelation(dataTablesParameters, page, dataTablesParameters.length, relation_code,
      relation_name, relation_address, relation_type, relation_sl_code)
      .subscribe(resp => { 
        this.dataRelation = resp.data;
        callback({
          recordsTotal: resp.totalCount,
          recordsFiltered: resp.totalCount,
           data:  []
        });
    });
  }


  getDataEkspedisi(dataTablesParameters, callback, relation_code: string, relation_name: string, relation_address: string, 
    relation_type: string, relation_sl_code: string, padging : boolean){ 
    var page = 1;
    if (padging == true){
      page = parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length) + 1;
    }
    this.service.getListRelation(dataTablesParameters, page, dataTablesParameters.length, relation_code,
      relation_name, relation_address, relation_type, relation_sl_code)
      .subscribe(resp => { 
        this.dataEkspedisi = resp.data;
        callback({
          recordsTotal: resp.totalCount,
          recordsFiltered: resp.totalCount,
           data:  []
        });
    });
  }

  cancelInvoice(data: ListOfInvoice){
    this.dataCancel.id = data.invoiceId;
    this.dataCancel.invoice_no = data.invoice_no;
    this.dataCancel.invoice_type = data.invoiceType;
    this.dataCancel.invoice_date = data.invoice_date;
    Swal.fire({
      title: 'Batal Invoice?',
      // text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '      Yes     '
    }).then((result) => {
        if (result.value) {
          this.spinner.show();
            this.service.cancelInvoice(this.dataCancel)
             .subscribe(resp => { 
              this.spinner.hide();
            if(resp.succes == false){
              Swal.fire({
                title: ""+resp.text,
                type: 'warning',
                confirmButtonText: '      Ok!     '
              });
            }
            else{
              Swal.fire({
                title: ""+resp.text,
                type: 'success',
                confirmButtonText: '      Ok!     '
              }).then((result) => {
                if (result.value) { 
                  this.getListInvoice(this.dt, this.cb, this.dataInvRawDto, true );
                }              
              }, (error) => {
                ; 
              })
            }
            });
        }        
    
    }, (error) => {
      //; 
    });
  }

  checkAll(){
    if (this.dataListGenereate[0].selected == false){ 
     this.dataListGenereate.forEach(x => {
        x.selected = true;
      })
    }
    else{
      this.dataListGenereate.forEach(x => {
        x.selected = false;
      })
    }
  }


  async onSelectDetail(data: any){
    this.invoiceModel = data;
    this.finishModel.id = data.id;
    if (data.invoiceType == "Hutang(AP)"){
      this.finishShow = false;
    }
    else{
      this.finishShow = true;
    }
    await this.service.getInvoiceDetail(data.id).subscribe(resp => {
      this.detailListInvoice = resp;
      this.total = resp.reduce((sum, obj) => {
        return sum + obj.amount + obj.additional_amount;
      }, 0);
    });
    this.spinner.show();
    setTimeout(() => {
      this.spinner.hide();
    }, 500);
    this.dtTriggerDetailList.next(0);
    this.modalDetail.nativeElement.click();
  }

  onSelectBank(data: LocationSettingBank){
    if(data){
      this.invoiceModel.bank = data.id;
      this.invoiceModel.bank_number = data.account_number;
      console.log(this.invoiceModel.bank+this.invoiceModel.bank_number)
    }
  }

  onSelectRevenue(data: RevenueType){
    this.invoiceModel.revenueType = data.id;
    // this.invoiceModel.bank_number = data.account_number;
    console.log(this.invoiceModel.revenueType)
  }
  
  onSelectSPK(data: ListSPK){
    if (this.invoiceModel.main_do_id == ""){
       this.invoiceModel.main_do_id = data.spk_id;
    }
    else{
        this.invoiceModel.main_do_id = this.invoiceModel.main_do_id +","+data.spk_id;
    }
    if (this.spkList == "")
    {
      this.spkList = data.spk_no+"|"+data.main_do_no;
    }
    else{
      this.spkList = this.spkList + "\n" +data.spk_no+"|"+data.main_do_no;
    }
    this.hideSPK.nativeElement.click();
  }

  clearSPK(){
    this.invoiceModel.main_do_id = "";
    this.spkList= "";
  }

  clearMaterial(){
    this.invoiceModel.material_id = "";
    this.materialList = "";
  }

  onSelectMaterial(data: Material){
    if (this.invoiceModel.material_id == ""){
      this.invoiceModel.material_id = data.material_id;
    }
    else{
        this.invoiceModel.material_id = this.invoiceModel.material_id +","+data.material_id;
    }
    if (this.materialList == "")
    {
      this.materialList = data.material_name;
    }
    else{
      this.materialList = this.materialList + "\n" +data.material_name;
    }
  }

  onSelectCustomer(data: Relation){
    if (this.custFind == true){
      if (data.relation_npwp == null || data.relation_npwp == "" || data.relation_bank_account == null || data.relation_bank_account == ""){
        Swal.fire({
          title: "Data Customer Belum Lengkap!",
          type: 'warning',
          confirmButtonText: '      Ok!     '
        });
      }
      else{
        this.invoiceModel.customer_id = data.relation_id;
        this.customer = data.relation_name;
        this.invoiceModel.customer_id = data.relation_id;
        this.modalCustomerHide.nativeElement.click();
      }
    }
    else{
        this.invoiceModel.customer_id = data.relation_id;
        this.customer = data.relation_name;
        this.invoiceModel.customer_id = data.relation_id;
        this.modalCustomerHide.nativeElement.click();
    }
    this.custFind = false;
  }

  onSelectEkspedisi(data: Relation){
    this.ekspedisiSelect = data.relation_name;
    this.invoiceModel.vendor_id = data.relation_id;
  }
  
  messageWarning(text: string){
    Swal.fire({
      title: ""+text,
      type: 'warning',
      confirmButtonText: '      Ok!     '
    });
    return;
  }

  transform(value: any, args?: any): any {
    if(args === 'Checked'){
        return value.filter(x=>x.checked)
    }else if(args === 'NotChecked'){
         return value.filter(x=>!x.checked)
    }else{
      return value
    }
  }

  showSPK(){
    this.dtTrigger.next(this.dtOptions);
  }

  showMaterial(){
    this.dtTriggerMaterial.next(this.dtOptionsMaterial);
  }

  showCustomer(){
    this.dtTriggerCustomer.next(this.dtOptionsCustomer);
  }

  showEkspedisi(){
    this.dtTriggerEkspedisi.next(this.dtOptionsEkspedisi);
  }

  onSelectType(type){
    if (type == "Hutang(AP)"){
      this.AP = true;
      this.invoiceModel.invoice_no = "";
    } 
    else{
      this.AP = false;
      this.generateInvoiceNo(this.invoiceModel.invoice_date);
    }
  }
     
  getDataType(){
    this.service.getListMasterCode("TripType").subscribe(resp => {
      this.dataType = resp;
    })
  }

  
  generateInvoiceNo(date: Date){
    if (this.invoiceModel.merge_oa == null){
      this.invoiceModel.merge_oa = false;
    }
    this.service.getInvoiceNo(date, this.invoiceModel.merge_oa).subscribe(resp => {
      this.invoiceModel.invoice_no = resp.text;
      this.invoiceModel.invoice_no2 = resp.text2;
      this.invoiceModel.invoiceRun = resp.booleanType;
    })
  }

  selectBank(data: LocationSettingBank){
    this.invoiceModel.bank_number = data.account_number;
  }

  getDataListSPK(dataTablesParameters, callback,data: SpkDto, padging: boolean){
    var page = 1;
      if (padging == true){
        page = parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length) + 1;
      }
      data.page = page;
      data.perPage = dataTablesParameters.length;
      
      this.service.getListSPK(data)
      .subscribe(resp => { 
          
          this.dataListSPK = resp.data;
          callback({
            recordsTotal: resp.totalCount,
            recordsFiltered: resp.totalCount,
             data:  []
          });
      });
  }

  createInvoice(){

    // if (this.invoiceModel.invoice_no == null || this.invoiceModel.invoice_type == null || this.invoiceModel.invoice_date == null ||
    //   this.invoiceModel.expired_date == null || this.spkList == null || this.spkList == "")
    if (this.invoiceModel.invoice_no == null || this.invoiceModel.invoice_type == null || this.invoiceModel.invoice_date == null ||
      this.invoiceModel.expired_date == null)
      {
       return Swal.fire({
          title: "Data Belum Lengkap!",
          type: 'warning',
          confirmButtonText: '      Ok!     '
        });
      }

      if (this.AP == true){
        if (this.invoiceModel.vendor_id == null || this.invoiceModel.external_spk_date == null || this.invoiceModel.external_spk_no == null  || this.invoiceModel.external_spk_no == ""){
          return Swal.fire({
            title: "Data Belum Lengkap!",
            type: 'warning',
            confirmButtonText: '      Ok!     '
          });
        }
      }
      else{
        if (this.invoiceModel.customer_id == null){
          return Swal.fire({
            title: "Data Belum Lengkap!",
            type: 'warning',
            confirmButtonText: '      Ok!     '
          });
        }
        if (this.invoiceModel.revenueType == null || this.invoiceModel.revenueType == undefined ){
          return Swal.fire({
            title: "Tipe Pendapatan Belum Dipilih!",
            type: 'warning',
            confirmButtonText: '      Ok!     '
          });
        }
      }
    if ( this.dataListGenereate.filter(x => x.selected == true).length == 0){
      return Swal.fire({
        title: "Belum Ada Data Yang Dipilih!",
        type: 'warning',
        confirmButtonText: '      Ok!     '
      });
    }
    Swal.fire({
      title: 'Simpan Data?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '      Yes     '
    }).then((result) => {
      if (result.value) {
            this.spinner.show();
            setTimeout(() => {
              this.spinner.hide();
            }, 10000);
            this.invoiceModel.generateDtos = [];
            this.invoiceModel.total_payment = 0;
            this.invoiceModel.second_payment = 0;
            this.dataListGenereate.filter(x => x.selected == true).forEach( dataInv =>{
              this.invoiceModel.second_payment += dataInv.amount_adjusment+dataInv.ppn_adjusment;
              this.invoiceModel.total_payment += dataInv.amount + dataInv.ppn_charge;
              // this.invoiceModel.total_payment += dataInv.amount + dataInv.amount_adjusment+ dataInv.ppn_charge+dataInv.ppn_adjusment;
              this.invoiceModel.generateDtos.push({
                  invoice_no : dataInv.invoice_no,
                  spk_no : dataInv.spk_no,
                  reff : dataInv.reff,
                  do_no : dataInv.do_no,
                  material : dataInv.material,
                  vehicle_number : dataInv.vehicle_number,
                  tonnase_send : dataInv.tonnase_send,
                  tonnase_received : dataInv.tonnase_received,
                  amount : dataInv.amount,
                  totalInDouble : dataInv.amount,
                  amount_adjusment : dataInv.amount_adjusment,
                  additionalTotalInDouble : dataInv.additionalTotalInDouble,
                  charge_type : dataInv.charge_type,
                  receive_date : dataInv.receive_date,
                  send_date : dataInv.send_date,
                  complete_date : dataInv.complete_date,
                  trx_detail_id : dataInv.trx_detail_id,
                  route_Type : dataInv.route_Type,
                  oa_ekspedisi : dataInv.oa_ekspedisi,
                  trx_id : dataInv.trx_id,
                  do_detail_id : dataInv.do_detail_id,
                  type : dataInv.type,
                  ppn_include : dataInv.ppn_include,
                  ppn_charge : dataInv.ppn_charge,
                  total_tax : dataInv.total_tax,
                  ppn_adjusment : dataInv.ppn_adjusment,
                  additionalTotalTax : dataInv.additionalTotalTax,
                  factur_no : dataInv.factur_no,
                  pph_include : dataInv.pph_include
              });
            })
            
            console.log(JSON.stringify(this.invoiceModel));
            this.service.createInvoice(this.invoiceModel).subscribe(resp => { 
              this.spinner.hide();
            if(resp.status == "UpdateInv"){
              Swal.fire({
                title: ""+resp.text,
                type: 'warning',
                confirmButtonText: '      Ok!     '
              });
              this.invoiceModel.invoice_no = resp.text2;
              return;
            }
            if(resp.succes == false){
              Swal.fire({
                title: ""+resp.text,
                type: 'warning',
                confirmButtonText: '      Ok!     '
              });
            }
            else{
              Swal.fire({
                title: ""+resp.text,
                type: 'success',
                confirmButtonText: '      Ok!     '
              }).then((result) => {
                if (result.value) { 
                  this.modalSaveClose.nativeElement.click();
                  this.getListInvoice(this.dt, this.cb, this.dataInvRawDto, true );
                }              
              }, (error) => {
                ; 
              })
            }
            });
      }
    });

    
  }

  async getDatagenerate(){
    // if (this.invoiceModel.invoice_no == null || this.invoiceModel.invoice_type == null || this.invoiceModel.invoice_date == null ||
    //   this.invoiceModel.expired_date == null || this.spkList == null || this.spkList == "" )
    if (this.invoiceModel.invoice_no == null || this.invoiceModel.invoice_type == null || this.invoiceModel.invoice_date == null ||
      this.invoiceModel.expired_date == null)
      {
        Swal.fire({
          title: "Data Belum Lengkap!",
          type: 'warning',
          confirmButtonText: '      Ok!     '
        });
        return;
      }
    if (this.AP == true){
      if (this.invoiceModel.vendor_id == null){
        return Swal.fire({
          title: "Isi Data Customer atau Vendor Terlebih Dahulu!",
          type: 'warning',
          confirmButtonText: '      Ok!     '
        });
      }
    }
    else{
      if (this.invoiceModel.customer_id == null){
        return Swal.fire({
          title: "Isi Data Customer atau Vendor Terlebih Dahulu!",
          type: 'warning',
          confirmButtonText: '      Ok!     '
        });
      }
    }
    if (this.invoiceModel.include_ppn == true && (this.invoiceModel.factur_no == null || this.invoiceModel.factur_no == "")){
        return Swal.fire({
          title: "Mohon isi no faktur pajak!",
          type: 'warning',
          confirmButtonText: '      Ok!     '
        });
    }
    this.spinner.show();
    await this.service.generateInvoice(this.invoiceModel).subscribe(resp => { 
      this.spinner.hide();
      if(resp.length == 0){
        return Swal.fire({
          title: "Tidak Ada Data Ditemukan",
          type: 'warning',
          confirmButtonText: '      Ok!     '
        });
      }
      this.dataListGenereate = resp;
      this.totalInv = 0; this.totalSend = 0; this.totalRcv = 0; this.totalInvOth = 0;
      this.select = resp.length;
      this.totalData = resp.length;
      this.dataListGenereate.forEach(x => {
        this.totalInv += x.amount;
        this.totalInvOth += x.amount_adjusment;
        this.totalSend += x.tonnase_send;
        this.totalRcv += x.tonnase_received;
      })
      
    });
    setTimeout(() => {
      this.spinner.hide();
    }, 5000);
    this.dtTriggerGenerate.next(this.dtOptionsGenerate);
  }

  clearGenerate(){
    var today = moment().format('YYYY-MM-DD');
    this.dataListGenereate = [];
    this.invoiceModel = { invoice_type: null, relation_name: null, statusInvoice: null, total_payment: null, second_payment: null, split_oa: null, show_oa: false,
      external_spk_no: null, external_spk_date: null, external_spk_start_date: null, external_spk_finish_date: null, customer_id: null, 
      vendor_id: null, invoice_date: today, expired_date: today, invoice_no: null, invoice_no2: null,
      main_do_id: null, merge_oa: null,  factur_no: null,  bank: null, bank_number: null, generateDtos: new Array(),
      material_id : null,  realization_from: null, realization_to: null,  complete_from: null,
      complete_to: null, include_ppn: null, include_pph: null,   bill_to: null, amount: 0, status: null, invoiceRun: null, ship: null, revenueType: null}
    this.spkList = "";
    this.materialList = "";
    this.customer = null
    this.model = this.dataBank[0];
    this.onSelectBank(this.model);
    this.invoiceModel.invoice_type = "Piutang(AR)";
    this.onSelectType(2);
    this.generateInvoiceNo(this.invoiceModel.invoice_date);
    this.ekspedisiSelect = null;
  }

  changeMergeOA(){
    this.generateInvoiceNo(this.invoiceModel.invoice_date);
  }
  

  date(){
    this.type = "date";
  }

  printOut(){
    this.spinner.show();
    if (this.printDateFrom != null && this.printDateTo != null){
      this.service.printTripOutStanding(this.printDateFrom, this.printDateTo, this.location).subscribe(resp =>{
        this.spinner.hide();
        let blob:Blob=resp.body as Blob;
        let url = window.URL.createObjectURL(blob);
        window.open(url);
        this.modalPrint.nativeElement.click();
      })
    }
    else{
      Swal.fire({
        title: "Data Belum Lengkap!",
        type: 'warning',
        confirmButtonText: '      Ok!     '
      });
    }
  }

  getSignature(){
    this.service.getSignature().subscribe(resp => { 
            this.dataSignature = resp;
        });
   }


   onSelectPrint(data: ListOfInvoice){
    if(data.statusInvoice != "New"){
      return  Swal.fire({
        title: "Invoice Ini Sudah Pernah Dicetak Sebelumnya",
        type: 'warning',
        confirmButtonText: '      Ok!     '
      });
    }
    this.spinner.show();
    if (data.invoiceType == "Piutang(AR)"){
      this.service.getCheckCountInv(data.id).subscribe( resp =>{
        if (resp > 0){
          this.service.getCheckDetailDoInv(data.id).subscribe( resp =>{
            this.spinner.hide();
            return  Swal.fire({
              title: "Tidak Dapat Mencetak Invoice Dikarenakan Masih Terdapat Dokumen yang Belum Dilengkapi! (No Do "+resp.do_no+", Nama Driver "+resp.driver_name+", Truk "+resp.truck_plate_number+" )",
              type: 'warning',
              confirmButtonText: '      Ok!     '
            });
          })
        }
        else{
          this.spinner.hide();
          this.printInvoice.id = data.invoiceId;
          this.printInvoice2.id = data.invoiceId;
          this.ModalSignatureShow.nativeElement.click();
          this.tipeTrip = data.invoiceType;
          this.pph = data.pph_included;
        }
      })
    }
    else{
      this.spinner.hide();
      this.printInvoice.id = data.invoiceId;
      this.printInvoice2.id = data.invoiceId;
      this.ModalSignatureShow.nativeElement.click();
      this.tipeTrip = data.invoiceType;
      this.pph = data.pph_included;
    }

    // else{
    //}
   }

   ReleasePrint(data: ListOfInvoice){
    if(data.statusInvoice != "Printed"){
      return Swal.fire({
        title: "Status Invoice ini Bukan Dicetak",
        type: 'warning',
        confirmButtonText: '      Ok!     '
      });
    }
    Swal.fire({
      title: 'Release Print?',
      // text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '      Yes     '
    }).then((result) => {
      if (result.value) {
        this.spinner.show();
        this.service.releasePrintInvoice(data.id).subscribe(resp => {
          this.spinner.hide();
          if (resp.succes == false){
            Swal.fire({
              title: ""+resp.text,
              type: 'warning',
              confirmButtonText: '      Ok!     '
            });
          }
          else{
            Swal.fire({
              title: ""+resp.text,
              type: 'success',
              confirmButtonText: '      Ok!     '
            });
            this.getListInvoice(this.dt, this.cb, this.dataInvRawDto, true);
          }
        });
      }
      

    });
    
   }

   Print(){
    if( this.signature1 == null || this.signature1 == null){
      return  Swal.fire({
        title: "Data Belum Lengkap!",
        type: 'warning',
        confirmButtonText: '      Ok!     '
      });
    }
    else{
     
      this.printInvoice.sign_Id = this.signature1;
      this.printInvoice2.sign_Id = this.signature2;
      this.spinner.show();

      this.service.getCheckSpecialInv(this.printInvoice.id).subscribe( resp =>{
       if (resp > 0){
          if (this.tipeTrip.includes("Piutang(AR)")) {
            if (this.pph == true) {
             this.service.printInvoicePPH(this.printInvoice.id, this.printInvoice.sign_Id, this.printInvoice.pajak, this.location).subscribe(resp =>{
             let blob:Blob=resp.body as Blob;
               let url = window.URL.createObjectURL(blob);
               window.open(url);
             })
            }
            else{
             this.service.printInvoice(this.printInvoice.id, this.printInvoice.sign_Id, this.printInvoice.pajak, this.location).subscribe(resp =>{
             let blob:Blob=resp.body as Blob;
               let url = window.URL.createObjectURL(blob);
               window.open(url);
             })
            }
           
   
           this.service.printInvoiceDetail(this.printInvoice2.id, this.printInvoice2.sign_Id, this.location).subscribe(resp =>{
             this.spinner.hide();
           let blob:Blob=resp.body as Blob;
             let url = window.URL.createObjectURL(blob);
             window.open(url);
           })
         }
         else{
   
           this.service.printInvoiceEkspedisi(this.printInvoice.id, this.printInvoice.sign_Id, this.printInvoice.pajak, this.location).subscribe(resp =>{
           let blob:Blob=resp.body as Blob;
             let url = window.URL.createObjectURL(blob);
             window.open(url);
           })
   
           this.service.printInvoiceEkspedisiDetail(this.printInvoice2.id, this.printInvoice2.sign_Id, this.location).subscribe(resp =>{
             this.spinner.hide();
           let blob:Blob=resp.body as Blob;
             let url = window.URL.createObjectURL(blob);
             window.open(url);
           })
         }
        }
        else{

          this.service.printInvoiceSpecial(this.printInvoice.id, this.printInvoice.sign_Id, this.printInvoice.pajak, this.location).subscribe(resp =>{
            let blob:Blob=resp.body as Blob;
              let url = window.URL.createObjectURL(blob);
              window.open(url);
            })

          this.service.printInvoiceDetail(this.printInvoice2.id, this.printInvoice2.sign_Id, this.location).subscribe(resp =>{
            this.spinner.hide();
          let blob:Blob=resp.body as Blob;
            let url = window.URL.createObjectURL(blob);
            window.open(url);
          })
        }
      });

    
      this.service.UpdateStatusPrintInvoice(this.printInvoice.id).subscribe( resp => {
        if (resp == 0){
           Swal.fire({
            title: "Gagal Ubah Status Print!",
            type: 'warning',
            confirmButtonText: '      Ok!     '
          });
        }
        this.getListInvoice(this.dt, this.cb, this.dataInvRawDto, true );

      });
     
    }
   }


   userAcces(){
    const menuUser = localStorage.getItem("menu");
    if (menuUser != "All"){
      this.btnCreate = false;
      this.btnRelease = false;
      this.btnPrint = false;
      this.btnCancel = false;
      
      const locationName = localStorage.getItem('locationname');
      if(locationName != "Palembang"){
        
      let arrayMenu =  menuUser.split(",");
      if (arrayMenu.includes("invoice") == false){
        return this.router.navigate(["/home"]);
      }

        this.authAcces = JSON.parse(localStorage.getItem("authAccess")); 
        this.authAcces.filter(item => item.menu_name  == "InvoiceForm").forEach(i => {
            if(i.name == "INSERT"){
              this.btnCreate = true;
            }
            if(i.name == "CANCEL_RECEIVE_INVOICE" || i.name == "CANCEL_SEND_INVOICE"){
              this.btnCancel = true;
            }
            // if(i.name == "RELEASE_PRINT_INVOICE"){
            //   this.btnRelease = true;
            // }
            // if(i.name == "RELEASE_PRINT_INVOICE"){
            //   this.btnPrint = true;
            // }
        });
      }
      else{
        this.authAccessPlb = JSON.parse(localStorage.getItem("authAccessPlb")); 

        var a = this.authAccessPlb.filter(item => item.subMenu  == "tripmanual").length;
        if (a == 0){
          return this.router.navigate(["/home"]);
        }
        this.authAccessPlb.filter(item => item.subMenu  == "invoicelist").forEach(i => {
            if(i.auth == "CRUD"){
              this.btnCreate = true;
            }
            if(i.auth == "CANCEL_INVOICE"){
              this.btnCancel = true;
            }
        });
      }
      
    }
  }

  checkSelected(data: InvoiceGenerateDto){
    if (this.dataListGenereate.find(x => x.do_no == data.do_no).selected != true){
         this.dataListGenereate.find(x => x.do_no == data.do_no).selected = true;
        this.totalInv = 0; this.totalSend = 0; this.totalRcv = 0;
        this.select = this.dataListGenereate.filter(x => x.selected == true).length;
          this.dataListGenereate.filter(x => x.selected == true).forEach(x => {
            this.totalInv += x.amount;
            this.totalSend += x.tonnase_send;
            this.totalRcv += x.tonnase_received;
          })
    }
    else{
      this.dataListGenereate.find(x => x.do_no == data.do_no).selected = false;
      this.totalInv = 0; this.totalSend = 0; this.totalRcv = 0;
      this.select = this.dataListGenereate.filter(x => x.selected == true).length;
        this.dataListGenereate.filter(x => x.selected == true).forEach(x => {
          this.totalInv += x.amount;
          this.totalSend += x.tonnase_send;
          this.totalRcv += x.tonnase_received;
        })
    }
  }

  custFinds(){
    this.custFind = true;
  }

  getListType(){

    this.service.getListMasterCode("InvoiceType")
    .subscribe(resp => { 
        this.listInvoiceType = resp;
    });
  }

  searchData(){
    this.dataInvRawDto.customerId = this.invoiceModel.customer_id;
    this.dataInvRawDto.invoiceNo = this.searchModel.invoiceNo;
    this.dataInvRawDto.relation_type = this.searchModel.relation_type;
    this.dataInvRawDto.spkNo = this.searchModel.spkNo;
    this.dataInvRawDto.invoiceDate = this.searchModel.invoiceDate;
    this.dataInvRawDto.statusId = this.searchModel.statusId;
    this.dataInvRawDto.typeId = this.searchModel.typeId;
    this.dataInvRawDto.cancel = this.searchModel.cancel;
    this.getListInvoice(this.dt, this.cb, this.dataInvRawDto, true);
  }


  setNullSearch(){
    this.searchModel = {invoiceNo: null, relation_type: null, spkNo: null, customerId: null, 
      invoiceDate: null, statusId: null, typeId: null, cancel: false}
    this.customer = null;
    this.invoiceModel.customer_id = null;
  }

  sort(column){
    if(column == "send"){
      this.dataListGenereate.sort((a, b) => moment(b.send_date).unix() - moment(a.send_date).unix());
    }
    if(column == "rcv"){
      this.dataListGenereate.sort((a, b) => moment(b.receive_date).unix() - moment(a.receive_date).unix());
    }
    if(column == "end"){
      this.dataListGenereate.sort((a, b) => moment(b.complete_date).unix() - moment(a.complete_date).unix());
    }
    if(column == "spk"){
      this.dataListGenereate.sort((a, b) => {
        return  a.spk_no > b.spk_no ? 1 : a.spk_no < b.spk_no ? -1 : 0
       })
    }
    if(column == "plat"){
      this.dataListGenereate.sort((a, b) => {
        return  a.vehicle_number > b.vehicle_number ? 1 : a.vehicle_number < b.vehicle_number ? -1 : 0
       })
    }
    if(column == "reff"){
      this.dataListGenereate.sort((a, b) => {
        return  a.reff > b.reff ? 1 : a.reff < b.reff ? -1 : 0
       })
    }
    if(column == "biaya"){
      this.dataListGenereate.sort((a, b) => {
        return  a.amount > b.amount ? 1 : a.amount < b.amount ? -1 : 0
       })
    }
    if(column == "tambahan"){
      this.dataListGenereate.sort((a, b) => {
        return  a.amount_adjusment > b.amount_adjusment ? 1 : a.amount_adjusment < b.amount_adjusment ? -1 : 0
       })
    }
    

    
  }

  finishTrip(){
    if(this.finishModel.delivery == null || this.finishModel.id == null){
      return Swal.fire({
        title: "Data Belum Lenngkap!",
        type: 'warning',
        confirmButtonText: '      Ok!     '
      });
    }
    Swal.fire({
      title: 'Update Data?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '      Yes     '
    }).then((result) => {
      if (result.value) {
        this.spinner.show();
        this.service.finishTripByInvoice(this.finishModel).subscribe(resp =>{
          this.spinner.hide();
          if (resp.succes == false){
            Swal.fire({
              title: ""+resp.text,
              type: 'warning',
              confirmButtonText: '      Ok!     '
            });
          }
          else{
            Swal.fire({
              title: ""+resp.text,
              type: 'success',
              confirmButtonText: '      Ok!     '
            });
            this.modalDetailClose.nativeElement.click();
            this.modalFinishClose.nativeElement.click();
            this.getListInvoice(this.dt, this.cb, this.dataInvRawDto, true);
          }
        })
      }
    });
  }


  printOutStandExcel(){
    if (this.printDateFrom != null || this.printDateTo != null ){
      this.spinner.show();
      this.reportModel.from = this.printDateFrom;
      this.reportModel.to = this.printDateTo;
      this.reportModel.location = localStorage.getItem('locationname');
      this.service.printInvOutsatndingExcel(this.reportModel).subscribe(resp =>{
        this.spinner.hide();
        let name = "Rekap_Invoice.xlsx";
        const blob = new Blob([resp.body],
          { type: 'application/vnd.ms-excel' });
      const file = new File([blob], name,
          { type: 'application/vnd.ms-excel' });
    
        saveAs(file);
      })
    }
    else{
      Swal.fire({
        title: "Tanggal Belum Lengkap!",
        type: 'warning',
        confirmButtonText: '      Ok!     '
      });
    }
   
  }

//  sort(sort){
//     if(sort = 'asc'){
//        this.sortArrayOfObjects(this.dataListGenereate, 'send_date', 'ascending')
//     }
//     else{
//       this.sortArrayOfObjects(this.dataListGenereate, 'send_date', 'descending')
//     }
//   }
//  sortArrayOfObjects = <T>(
//     data: T[],
//     keyToSort: keyof T,
//     direction: 'ascending' | 'descending' | 'none',
//   ) => {
//     if (direction === 'none') {
//       return data
//     }
//     const compare = (objectA: T, objectB: T) => {
//       const valueA = objectA[keyToSort]
//       const valueB = objectB[keyToSort]
  
//       if (valueA === valueB) {
//         return 0
//       }
  
//       if (valueA > valueB) {
//         return direction === 'ascending' ? 1 : -1
//       } else {
//         return direction === 'ascending' ? -1 : 1
//       }
//     }
  
//     return data.slice().sort(compare)
//   }

}




