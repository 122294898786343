import { AfterViewInit, Component, OnInit, ViewChild,Input, ChangeDetectorRef, ElementRef} from '@angular/core';
import { DataTableDirective } from 'angular-datatables'
import { TransportService} from '../../..//services/api.service';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { Guid } from 'guid-typescript'
import { FormGroup, FormBuilder, Validators, FormArray, } from '@angular/forms';
import { MasterCode } from '@/model/masterCode';
import { TravelCost } from '@/model/travelCost';
import { ListTravelCost, TravelCostBBM, TravelCostDetail } from '@/model/listTravelCost';
import { MasterRouteComponent } from '../master-route/master-route.component';
import { Route } from '@/model/route';
import { MasterPortComponent } from '../master-port/master-port.component';
import { Port } from '@/model/port';
import { MasterMaterialComponent } from '../master-material/master-material.component';
import { Material } from '@/model/material';
import { MasterCapacityComponent } from '../master-capacity/master-capacity.component';
import { Capacity } from '@/model/capacity';
import { MasterCosttypeComponent } from '../master-costtype/master-costtype.component';
import { CostType } from '@/model/costType';
import { DetailTravelCost } from '@/model/detailTravelCost';
import { saveAs } from 'file-saver';
import { Vehicle } from '@/model/vehicle';
import { Router } from '@angular/router';

@Component({
  selector: 'app-travelcost-bbm',
  templateUrl: './travelcost-bbm.component.html',
  styleUrls: ['./travelcost-bbm.component.scss'],
})
export class TravelcostBbmComponent implements OnInit, AfterViewInit {
  @ViewChild('ModalSimpanShow') ModalSimpanShow: ElementRef;
  @ViewChild('ModalSimpanClose') ModalSimpanClose: ElementRef;
  dataRoute: Route[] = [];
  dataMaterial: Material[] = [];
  dataCapacity: Capacity[] = [];
  listTable : TravelCostBBM[];
  listOfOldCost : TravelCost[];
  listOfNewCost : TravelCost[];
  listOfBBM : TravelCostDetail[];
  dataVehicles: Vehicle[] = [];
  listOfROUNDING : TravelCostDetail[];
  listOfOTHER : TravelCostDetail[];
  public dt: any;
  public cb: any;
  materialName: string;
  capacityTon: string;
  routeName: string;
  vehicleName: string;
  bbmOld: number;
  bbmNew: number;
  totalPercentage: number;
  diff: number;

  dtOptionsRoute: any = {};
  dtOptionsMaterial: any = {};
  dtOptionsCapacity: any = {};
  dtOptionsSimulate: any = {};
  dtOptionsVehicle : any = {};

  dtTriggerRoute = new Subject();
  dtTriggerMaterial = new Subject();
  dtTriggerCapacity = new Subject();
  dtTriggerSimulate = new Subject();
  dtTriggerVehicle = new Subject();

  @Input() modelExport = { listTable: null}
  @Input() modelUpdate = { vehicle_id: null, route_id: null, capacity_id: null, material_id: null, percentage: null};
  @Input() modelSave = { valid_to: null, listOfOldCost: null, listOfNewCost: null, listOfBBM: null, listOfROUNDING: null, listOfOTHER: null};

  constructor( public service: TransportService, private router: Router, private spinner: NgxSpinnerService, fb: FormBuilder, private changeDetectorRef: ChangeDetectorRef ) {
  }

    ngOnInit(): void {
      const menuUser = localStorage.getItem("menu");
      if (menuUser != "All"){
        const subMenu = localStorage.getItem("acces_menu");
        let arrayMenu =  subMenu.split(",");
        if (arrayMenu.includes("travelcostbbm") == false){
          this.router.navigate(["/home"]);
        }
      }
      this.dtOptionsSimulate = {
        retrieve: true,
        pageLength: 5,
        scrollX: true,
        scrollCollapse: true,
        //serverSide: true,
        searching : false,
        columnDefs: [
          { width: 100, targets: [0,1,2,3,4,5,6,7,8] },
        ],
      };



      this.dtOptionsRoute = {
        retrieve: true,
        pageLength: 5,
        scrollX: true,
        scrollCollapse: true,
        serverSide: true,
        processing: true,
        searching : false,
        ajax: (dataTablesParameters: any, callback) => {
         this.dt = dataTablesParameters; this.cb = callback;
         this.getDataRoute(dataTablesParameters, callback,$('#nameRute').val().toString(),  $('#typeRute').val().toString()
         ,  $('#unloadRute').val().toString(),  $('#loadRute').val().toString(), null,true);
        },
        columnDefs: [
          { width: 100, targets: [0,1,4,5,6,7,8] },
          { width: 200, targets: 2 },
          { width: 200, targets: 3 },
         ],
      };

      this.dtOptionsMaterial = {
        retrieve: true,
        pageLength: 5,
        scrollX: true,
        scrollCollapse: true,
        serverSide: true,
        processing: true,
        searching : false,
        ajax: (dataTablesParameters: any, callback) => {
          this.dt = dataTablesParameters; this.cb = callback;
          this.getDataMaterial(dataTablesParameters, callback, $('#codeMaterial').val().toString(), $('#nameMaterial').val().toString(),true);
        },
        columnDefs: [
          { width: 100, targets: 0 },
          { width: 200, targets: 2 },
        ]
      };

      this.dtOptionsCapacity = {
        retrieve: true,
        pageLength: 5,
        serverSide: true,
        processing: true,
        searching : false,
        scrollX: true,
        scrollCollapse: true,
        ajax: (dataTablesParameters: any, callback) => {
          this.dt = dataTablesParameters; this.cb = callback;
          this.getDataCapacity(dataTablesParameters, callback, "", "", "",true);
         },
         columnDefs: [
          { width: 100, targets: [0,1,2,3,4,5,6] }
        ]
      };

      
      this.getDataVehicle( "", "",true);

      // this.dtOptionsVehicle = {
      //   scrollX: true,
      //   scrollCollapse: true,
      //   serverSide: true,
      //   processing: true,
      //   searching : false,
      //   ajax: (dataTablesParameters: any, callback) => {
      //    this.dt = dataTablesParameters; this.cb = callback;
      //   },
      //   columnDefs: [
      //     { width: 50, targets: [0,1,2,3,4,5] }
      //   ],
        
      // };

      
    const that = this;

      $('.searchRute').on("keyup", function(e) {
        var key = e.which;
        if (key == 13) // the enter key ascii code
        {
          that.getDataRoute(that.dt, that.cb,  $('#nameRute').val().toString(),  $('#typeRute').val().toString()
          ,  $('#unloadRute').val().toString(),  $('#loadRute').val().toString(), null,false);
        }
      }); 

      $('.searchInputCapacity').on("keyup", function(e) {
        var key = e.which;
        if (key == 13) // the enter key ascii code
        {
          that.getDataCapacity(that.dt, that.cb, $('#codecapacity').val().toString(),$('#namecapacity').val().toString(),$('#vehiclesearch').val().toString(), false);
        }
      }); 

      $('.searchMaterial').on("keyup", function(e) {
        var key = e.which;
        if (key == 13) // the enter key ascii code
        {
          that.getDataMaterial(that.dt, that.cb, $('#codeMaterial').val().toString(), $('#nameMaterial').val().toString(),false);
        }
      }); 
    }   

   
    ngAfterViewInit(): void {
      
    }
  
    ngOnDestroy(): void {
    }
  
    
  
  onSelectCapacity(data: Capacity){
    this.modelUpdate.capacity_id = data.capacity_id;
    this.capacityTon = data.capacity_code;
  }

  onSelectMaterial(data : Material){
    this.modelUpdate.material_id = data.material_id;
    this.materialName = data.material_name;
  }
  
  onSelectRoute(data: Route){
    this.modelUpdate.route_id = data.route_id;
    this.routeName = data.route_name;
  } 

  onSelectVehicle(data: Vehicle){
    this.modelUpdate.vehicle_id = data.id;
    this.vehicleName = data.name;
  } 


  showRoute(){
    this.dtTriggerRoute.next(0);
  }

  showCapacity(){
    this.dtTriggerCapacity.next(0);
  }

  showMaterial(){
    this.dtTriggerMaterial.next(0);
  }

  showVehicle(){
    this.dtTriggerVehicle.next(0);
  }
 


    getDataRoute(dataTablesParameters, callback, route_name: string, route_type: string, unloadtext: string, loadtext: string, material_id: string,padging : boolean){ 
      var page = 1;
      if (padging == true){
        page = parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length) + 1;
      }
      this.service.getListRoute(dataTablesParameters, page, dataTablesParameters.length, route_name, route_type, unloadtext, loadtext, material_id)
      .subscribe(resp => { 
          this.dataRoute = resp.data;
          callback({
            recordsTotal: resp.totalCount,
            recordsFiltered: resp.totalCount,
             data:  []
          });
      });
    }

    getDataMaterial(dataTablesParameters, callback, code: string, name: string, padging : boolean){ 
      var page = 1;
      if (padging == true){
        page = parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length) + 1;
      }
      this.service.getDataMaterialTest(dataTablesParameters, page, dataTablesParameters.length, code, name)
      .subscribe(resp => { 
          this.dataMaterial = resp.data;
          callback({
            recordsTotal: resp.totalCount,
            recordsFiltered: resp.totalCount,
            data:  []
            //  data:  resp.data
          });
      });
    }

    getDataCapacity(dataTablesParameters, callback, code: string, name: string, vehicle: string, padging : boolean){ 
      var page = 1;
      if (padging == true){
        page = parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length) + 1;
      }
      this.service.getDataCapacity(dataTablesParameters, page, dataTablesParameters.length, code, name, vehicle)
      .subscribe(resp => { 
          this.dataCapacity = resp.data;
          callback({
            recordsTotal: resp.totalCount,
            recordsFiltered: resp.totalCount,
             data:  []
          });
      });
    }

    calculate(){
        if (this.bbmOld > 0 && this.bbmNew > 0)
        {
            this.diff =  this.bbmNew - this.bbmOld;
            this.totalPercentage = (this.diff / this.bbmOld) * 100;
            this.modelUpdate.percentage = this.totalPercentage;
        }
    }
    
    async simulate(){
      this.dtTriggerSimulate.next(0);
      console.log(JSON.stringify(this.modelUpdate));
      if (
        // this.modelUpdate.capacity_id == null || this.modelUpdate.material_id == null ||this.modelUpdate.route_id == null ||
        this.bbmNew == null || this.bbmOld == null){
          return Swal.fire({
            title: "Data Belum Lengkap!",
            type: 'warning',
            confirmButtonText: '      Ok!     '
          });
        }
      this.spinner.show();
      await this.service.simulateUpdateTravelCost(this.modelUpdate).subscribe( resp =>{
        // this.spinner.hide();
        if(resp.succes == false){
          Swal.fire({
            title: ""+resp.text,
            type: 'warning',
            confirmButtonText: '      Ok!     '
          });
        }
        else{
            this.listTable = resp.listTable;
            this.listOfOldCost = resp.listOfOldCost;
            this.listOfNewCost = resp.listOfNewCost;
            this.listOfBBM = resp.listOfBBM;
            this.listOfROUNDING = resp.listOfROUNDING;
            this.listOfOTHER = resp.listOfOTHER;
        }
        
      });
      this.spinner.show();
      setTimeout(() => {
        this.spinner.hide();        
        this.dtTriggerSimulate.next(0);
      }, 10000);
    }

    onClickSave(){
      if (this.listTable == null){
        return Swal.fire({
          title: "Tidak Ada Data!",
          type: 'warning',
          confirmButtonText: '      Ok!     '
        });
      }
      if (this.listTable.length == 0){
        return Swal.fire({
          title: "Tidak Ada Data!",
          type: 'warning',
          confirmButtonText: '      Ok!     '
        });
      }
      this.ModalSimpanShow.nativeElement.click();
    }

    saveData(){
      if (this.modelSave.valid_to == null){
        return Swal.fire({
          title: "Isi Tanggal Berlaku!",
          type: 'warning',
          confirmButtonText: '      Ok!     '
        });
      }
      this.modelSave.listOfOldCost = this.listOfOldCost;
      this.modelSave.listOfNewCost = this.listOfNewCost;
      this.modelSave.listOfBBM = this.listOfBBM;
      this.modelSave.listOfROUNDING = this.listOfROUNDING;
      this.modelSave.listOfOTHER = this.listOfOTHER;
      Swal.fire({
        title: 'Simpan Data?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: '      Yes     '
      }).then((result) => {
        if (result.value) {
            this.service.saveUpdateTravelCost(this.modelSave).subscribe( resp => {
              if(resp.succes == false){
                Swal.fire({
                  title: ""+resp.text,
                  type: 'warning',
                  confirmButtonText: '      Ok!     '
                });
              }
              else{
                Swal.fire({
                  title: ""+resp.text,
                  type: 'success',
                  confirmButtonText: '      Ok!     '
                }).then((result) => {
                  if (result.value) { 
                    this.setNull();
                    this.ModalSimpanClose.nativeElement.click();
                  }              
                }, (error) => {
                  ; 
                })
              }
            })
        }
      });
    }

    setNull(){
      this.listTable = [];
      this.listOfOldCost = [];
      this.listOfNewCost = [];
      this.listOfBBM = [];
      this.listOfROUNDING = [];
      this.listOfOTHER = [];
      this.modelUpdate = { vehicle_id: null, route_id: null, capacity_id: null, material_id: null, percentage: null};
      this.modelSave = { valid_to: null, listOfOldCost: null, listOfNewCost: null, listOfBBM: null, listOfROUNDING: null, listOfOTHER: null};
      this.routeName = null;
      this.capacityTon = null;
      this.materialName = null;
      this.bbmNew = null;
      this.bbmOld = null;
    }

    
exportSPK(){
  this.modelExport.listTable = this.listTable;
  if (this.listTable == null){
    return Swal.fire({
      title: "Tidak Ada Data!",
      type: 'warning',
      confirmButtonText: '      Ok!     '
    });
  }
  if (this.listTable.length == 0){
    return Swal.fire({
      title: "Tidak Ada Data!",
      type: 'warning',
      confirmButtonText: '      Ok!     '
    });
  }
  
  
  this.spinner.show();
    this.service.exportTravelCostMess(this.modelExport).subscribe(resp =>{
      this.spinner.hide();
      const blob = new Blob([resp.body],
        { type: 'application/vnd.ms-excel' });
    const file = new File([blob], 'UpdateBBM.xlsx',
        { type: 'application/vnd.ms-excel' });

      saveAs(file);
    })
}

getDataVehicle(code: string, name: string, padging : boolean){ 
  this.service.getDataVehicle(null, 1, 100, code, name)
  .subscribe(resp => { 
      this.dataVehicles = resp.data;
      // callback({
      //   recordsTotal: resp.totalCount,
      //   recordsFiltered: resp.totalCount,
      //    databe:  []
      // });
  });
}
  

      
}



