import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables'
import { TransportService} from '../../../services/api.service';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { DriverShrinkageRecapVm } from '@/model/driverShrinkageRecapVm';
import { saveAs } from 'file-saver';
import { ListDoDetail } from '@/model/listDoDetail';
import { Relation } from '@/model/relation';
import { Driver } from '@/model/driver';
import moment from 'moment';
import { Truck } from '@/model/truck';
import { ListTruck } from '@/model/listTruck';
import { CostOpslahModel, CostType } from '@/model/costType';

@Component({
  selector: 'app-trip-opslah',
  templateUrl: './trip-opslah.component.html',
  styleUrls: ['./trip-opslah.component.scss']
})
export class TripOpslahComponent implements OnInit, AfterViewInit {
  datatabdtOptionsnt: DataTableDirective;
  @ViewChild('modalFrom') modalFrom: ElementRef;
  @ViewChild('modalTo') modalTo: ElementRef;
  dtOptionsDetailDo: any = {};
  dtTriggerDetail = new Subject();
  dtOptionsDetailDo2: any = {};
  dtTriggerDetail2 = new Subject();
  dtOptionsEkspedisi: any = {};
  dtTriggerEkspedisi = new Subject();
  doList: string = "";
  public dt: any;
  public cb: any;
  public from : Date;
  public to : Date;
  public doFrom: string;
  public doTo: string;
  public save: Boolean;
  public mainDoCost: number;
  public listDoDetail: ListDoDetail[] = [];
  public listDoDetail2: ListDoDetail[] = [];
  dataEkspedisi: Relation[]=[];
  ekspedisiName: string = "";
  model: CostType;
  @Input() distModel = { depart_date: null, vendor: null, cost: null, charge_type: null, listOfDetailDo: new Array() }




  @Input() checkModel = { doNoFrom: null, doNoTo: null, truckId: null, dateDo: null, additionalCost: new Array() }
  @Input() addCostModel = { costId: null, costName: null, amount: null, remark: null, trip: null  }
  dataDriver: Driver[] = [];
  dataTruck: ListTruck[] = [];
  ListCostType: CostType[] = [];
  dtOptionsDriver: any = {};
  dtTriggerDriver = new Subject();
  dtOptionsTruck: any = {};
  dtTriggerTruck = new Subject();
  public dtDriver: any;
  public cbDriver: any;
  public dtTruck: any;
  public cbTruck: any;
  truckName: string = "";
  driverName: string = "";
  addCostList: CostOpslahModel[] = [];
  constructor( public service: TransportService, private spinner: NgxSpinnerService) {
  }

    ngOnInit(): void {

      this.dtOptionsDriver = {
        retrieve: true,
        pageLength: 5,
        scrollX: true,
        scrollCollapse: true,
        serverSide: true,
        processing: true,
        searching : false,
        ajax: (dataTablesParameters: any, callback) => {
         this.dtDriver = dataTablesParameters; this.cbDriver = callback;
         this.getDataDriver(dataTablesParameters, callback, "", "","","","", "","","","", "","","", "",  true);
         },
        columnDefs: [
          { width: 150, targets: [0,2,3,4,5,6,7,8,9,10,12,13,14,15] },
          { width: 200, targets: [1] },
          { width: 300, targets: [11] },
        ],
      };

      
      this.dtOptionsTruck = {
        retrieve: true,
        pageLength: 5,
        scrollX: true,
        scrollCollapse: true,
        serverSide: true,
        processing: true,
        searching : false,
        ajax: (dataTablesParameters: any, callback) => {
         this.dtTruck = dataTablesParameters; this.cbTruck = callback;
         this.getDataTruck(dataTablesParameters, callback, "","","",true);
        },
        columnDefs: [
          { width: 100, targets: 0 },
          { width: 150, targets: 1 },
          { width: 150, targets: 2 },
        ],
        
      };

      this.dtOptionsDetailDo = {
        retrieve: true,
        pageLength: 10,
        ordering : false,
        scrollX: true,
        scrollCollapse: true,
        serverSide: true,
        processing: true,
        searching : false,
        ajax: (dataTablesParameters: any, callback) => {
          this.dt = dataTablesParameters; this.cb = callback;
          this.getDetailDoNew(dataTablesParameters, callback, $('#doNo').val().toString(), $('#mainDo').val().toString(),true);
         },
      };


      this.dtOptionsDetailDo2 = {
        retrieve: true,
        pageLength: 10,
        ordering : false,
        scrollX: true,
        scrollCollapse: true,
        serverSide: true,
        processing: true,
        searching : false,
        ajax: (dataTablesParameters: any, callback) => {
          this.dt = dataTablesParameters; this.cb = callback;
          this.getDetailDoNew2(dataTablesParameters, callback, $('#doNo2').val().toString(), $('#mainDo').val().toString(), true);
         },
      };

      this.service.getListCostTypeAll("BAGI DO").subscribe(resp => {
        this.ListCostType = resp;
      });

      const that = this;

      $('.searchDo').on("keyup", function(e) {
        var key = e.which;
        if (key == 13) // the enter key ascii code
        {
          that.getDetailDoNew(that.dt, that.cb, $('#doNo').val().toString(), $('#mainDo').val().toString(), false);
        }
      });  

      $('.searchDo2').on("keyup", function(e) {
        var key = e.which;
        if (key == 13) // the enter key ascii code
        {
          that.getDetailDoNew2(that.dt, that.cb, $('#doNo2').val().toString(), $('#mainDo').val().toString(),false);
        }
      }); 

      $('.searchInputD').on("keyup", function(e) {
        var key = e.which;
        if (key == 13) // the enter key ascii code
        {
          that.getDataDriver(that.dtDriver, that.cbDriver, $('#driver_name').val().toString(), $('#driver_license').val().toString(), 
          $('#driver_license_type').val().toString(),$('#agama').val().toString(),$('#birthplace').val().toString(),
          $('#ktp_no').val().toString(),$('#tanggal_lahir').val().toString(),$('#sl_code').val().toString(),
          $('#sl_code2').val().toString(),$('#sl_code3').val().toString(),$('#address').val().toString(),
          $('#valid_to').val().toString(),$('#valid_to_contract').val().toString(), false);
        }
      });

    }   

    public expanded = false;

    showCheckboxes() {
     
     var checkboxes = document.getElementById("checkboxes");
     if (!this.expanded) {
       checkboxes.style.display = "block";
       this.expanded = true;
     } else {
       checkboxes.style.display = "none";
       this.expanded = false;
     }
   }
    
    ngAfterViewInit(): void {
    }
  
    ngOnDestroy(): void {
    }


  messageWarning(text: string){
    Swal.fire({
      title: ""+text,
      type: 'warning',
      confirmButtonText: '      Ok!     '
    });
    return;
  }

  getDataDriver(dataTablesParameters, callback,  driver_name: string, driver_license: string, driver_license_type: string, 
    agama: string, birthplace: string, ktp_no: string, tanggal_lahir: string, sl_code: string, sl_code2: string,
    sl_code3: string, address: string, valid_to: string, valid_to_contract: string, padging : boolean){ 
    var page = 1;
    if (padging == true){
      page = parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length) + 1;
    }
    this.service.getListDriver(dataTablesParameters, page, dataTablesParameters.length, driver_name, driver_license, driver_license_type, agama,
      birthplace,ktp_no,tanggal_lahir, sl_code, sl_code2, sl_code3, address, valid_to, valid_to_contract, "true", "false")
    .subscribe(resp => { 
        this.dataDriver = resp.data;
        callback({
          recordsTotal: resp.totalCount,
          recordsFiltered: resp.totalCount,
          data:  []
        });
    });
  }

  getDataTruck(dataTablesParameters, callback, platNumber: string, codeCapacity: string, location: string, padging : boolean){ 
    var page = 1;
    if (padging == true){
      page = parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length) + 1;
    }
    this.service.getListTruck(dataTablesParameters, page, dataTablesParameters.length, platNumber, codeCapacity, location)
    .subscribe(resp => { 
        this.dataTruck = resp.data;
        callback({
          recordsTotal: resp.totalCount,
          recordsFiltered: resp.totalCount,
           data:  []
        });
    });
  }

  getDetailDoNew(dataTablesParameters, callback, no_do: string, main_do: string, padging : boolean){ 
    var page = 1;
    if (padging == true){
      page = parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length) + 1;
    }
    this.spinner.show();
    this.service.getDetailDoNew(dataTablesParameters, page, dataTablesParameters.length, no_do, main_do)
      .subscribe(resp => { 
        this.spinner.hide();
        this.listDoDetail = resp.data;
        callback({
          recordsTotal: resp.totalCount,
          recordsFiltered: resp.totalCount,
           data:  []
        });
    });
  }

  getDetailDoNew2(dataTablesParameters, callback, no_do: string, main_do: string, padging : boolean){ 
    var page = 1;
    if (padging == true){
      page = parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length) + 1;
    }
    this.spinner.show();
    this.service.getDetailDoNew(dataTablesParameters, page, dataTablesParameters.length, no_do, main_do)
      .subscribe(resp => { 
        this.spinner.hide();
        this.listDoDetail2 = resp.data;
        callback({
          recordsTotal: resp.totalCount,
          recordsFiltered: resp.totalCount,
           data:  []
        });
    });
  }

SaveData(){
  if(this.distModel.cost == null || this.distModel.depart_date == null || this.distModel.vendor == null )
  {
    Swal.fire({
      title: "Data Belum Lengkap !",
      type: 'warning',
      confirmButtonText: '      Ok!     '
    });
    return;
  }
  if(this.distModel.cost > this.mainDoCost )
  {
    Swal.fire({
      title: "Harga ongkos angkut ke ekspedisi tidak boleh lebih besar dari harga ongkos angkut SPK!",
      type: 'warning',
      confirmButtonText: '      Ok!     '
    });
    return;
  }
  if (this.distModel.cost == 0){
    Swal.fire({
      title: "OA Tidak Boleh 0!",
      type: 'warning',
      confirmButtonText: '      Ok!     '
    });
    return;
  }
  Swal.fire({
    title: 'Simpan Data?',
    type: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: '      Yes     '
  }).then((result) => {
    if (result.value) {
      this.spinner.show();
       this.service.insertTransDistributed(this.distModel).subscribe(resp => { 
         this.spinner.hide();
       if(resp.succes == false){
         Swal.fire({
           title: ""+resp.text,
           type: 'warning',
           confirmButtonText: '      Ok!     '
         });
       }
       else{
         Swal.fire({
           title: ""+resp.text,
           type: 'success',
           confirmButtonText: '      Ok!     '
         }).then((result) => {
            this.clear();
           if (result.value) { 
             //
           }              
         }, (error) => {
           ; 
         })
       }
       });
 }

  });
}

  showDetail(){
    this.dtTriggerDetail.next(0);
  }
  
  showDetail2(){
    this.dtTriggerDetail2.next(0);
  }
  
  showEkspedisi(){
    this.dtTriggerEkspedisi.next(0);
  }

  onSelectFrom(data: ListDoDetail){
    this.doFrom = data.do_no;
    this.modalFrom.nativeElement.click();
  }

  onSelectTo(data: ListDoDetail){
    this.doTo = data.do_no;
    this.modalTo.nativeElement.click();
  }

  onSelectEkspedisi(data: Relation){
    this.ekspedisiName = data.relation_name;
    this.distModel.vendor = data.relation_id;
  } 

  clear(){
    this.distModel = { depart_date: null, vendor: null, cost: null, charge_type: null, listOfDetailDo: new Array() }
    this.doFrom = "";
    this.doTo = "";
    this.ekspedisiName = "";
    this.save = false;
  }

  showDriver(){
    this.dtTriggerDriver.next(0);
  }

  onSelectDriver(data: Driver){
    // if (moment(data.valid_to).isBefore(moment(new Date()))){
    //   Swal.fire({
    //     title: "Masa berlaku sim supir sudah habis!",
    //     type: 'warning',
    //     confirmButtonText: '      Ok!     '
    //   });
    // }
    // else if (moment(data.valid_to_contract).isBefore(moment(new Date()))){
    //   Swal.fire({
    //     title: "Masa berlaku kontrak supir "+data.driver_name+" sudah habis pada "+moment(data.valid_to_contract).format('DD-MM-YYYY'),
    //     type: 'warning',
    //     confirmButtonText: '      Ok!     '
    //   });
    // }
    // else if (moment(data.valid_to_contract).add(-30, 'days').isBefore(moment(new Date()))){
    //   this.tripModel.driver_id = data.driver_id;
    //   this.tripModel.driver_name = data.driver_name;
    //   Swal.fire({
    //     title: "Masa berlaku kontrak supir "+data.driver_name+" akan segera habis pada "+moment(data.valid_to_contract).format('DD-MM-YYYY'),
    //     type: 'warning',
    //     confirmButtonText: '      Ok!     '
    //   });
    // }
    // else if(moment(data.valid_to).add(-14, 'days').isBefore(moment(new Date()))){
    //   Swal.fire({
    //     title: "Masa berlaku sim supir akan segera habis pada "+moment(data.valid_to).format('DD-MM-YYYY')+" !",
    //     type: 'warning',
    //     confirmButtonText: '      Ok!     '
    //   });
    //   this.tripModel.driver_id = data.driver_id;
    //   this.tripModel.driver_name = data.driver_name;
    //   this.getDataDriver(this.dtDriver, this.cbDriver, "", "","","","", "","","","", "","","", "",  true);
    //   $('.searchInputD').val('');
    // }
    // else{
    //   this.tripModel.driver_id = data.driver_id;
    //   this.tripModel.driver_name = data.driver_name;
    //   this.getDataDriver(this.dtDriver, this.cbDriver, "", "","","","", "","","","", "","","", "",  true);
    //   $('.searchInputD').val('');
    // }
  } 

  showTruck(){
    this.dtTriggerTruck.next(0);
  }

  onSelectTruck(data: Truck){
    this.truckName = data.truck_plate_number;
    this.checkModel.truckId = data.truck_id;
    $('.searchInputTruck').val('');
    this.getDataTruck(this.dtTruck, this.cbTruck, "","","",true);
    
  } 

  addCost(){
    if(this.addCostModel.amount == null || this.addCostModel.remark == null) {
      return Swal.fire({
        title: "Data Belum Lengkap!",
        type: 'warning',
        confirmButtonText: '      Ok!     '
      });
    } 
    this.addCostList.push({costId: this.addCostModel.costId, costName: this.addCostModel.costName,
      amount: this.addCostModel.amount, remark: this.addCostModel.remark, trip: this.addCostModel.trip})
  }

  selectCost(data: CostType){
    this.addCostModel.costId = data.id;
    this.addCostModel.costName = data.name;
  }

  // checkTravelCost(){
  //   this.checkModel.additionalCost = [];
  //   for (let i = 0; i < this.addCostList.length; i++) {
  //     this.checkModel.additionalCost.push({
  //       id : this.addCostList[i].costId,
  //       amount : this.addCostList[i].amount,
  //       type: this.addCostList[i].type,
  //       total : this.addCostList[i].total,
  //       debt : this.addCostList[i].debt,
  //       cicilan : this.addCostList[i].cicilan,
  //     });
  //   }
  // }
  
}






