import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables'
import { TransportService} from '../../../services/api.service';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { DriverShrinkageRecapVm } from '@/model/driverShrinkageRecapVm';
import { saveAs } from 'file-saver';
import { ListDoDetail } from '@/model/listDoDetail';
import { Relation } from '@/model/relation';

@Component({
  selector: 'app-trip-distributed',
  templateUrl: './trip-distributed.component.html',
  styleUrls: ['./trip-distributed.component.scss']
})
export class TripDistributedComponent implements OnInit, AfterViewInit {
  datatabdtOptionsnt: DataTableDirective;
  @ViewChild('modalFrom') modalFrom: ElementRef;
  @ViewChild('modalTo') modalTo: ElementRef;
  dtOptionsDetailDo: any = {};
  dtTriggerDetail = new Subject();
  dtOptionsDetailDo2: any = {};
  dtTriggerDetail2 = new Subject();
  dtOptionsEkspedisi: any = {};
  dtTriggerEkspedisi = new Subject();
  doList: string = "";
  public dt: any;
  public cb: any;
  public from : Date;
  public to : Date;
  public doFrom: string;
  public doTo: string;
  public save: Boolean;
  public mainDoCost: number;
  public listDoDetail: ListDoDetail[] = [];
  public listDoDetail2: ListDoDetail[] = [];
  dataEkspedisi: Relation[]=[];
  ekspedisiName: string = "";
  @Input() checkModel = { do_from: null, do_to: null}
  @Input() distModel = { depart_date: null, vendor: null, cost: null, charge_type: null, listOfDetailDo: new Array() }
  constructor( public service: TransportService, private spinner: NgxSpinnerService) {
  }

    ngOnInit(): void {

      this.dtOptionsEkspedisi = {
        retrieve: true,
        pageLength: 10,
        ordering : false,
        scrollX: true,
        scrollCollapse: true,
        serverSide: true,
        processing: true,
        searching : false,
        ajax: (dataTablesParameters: any, callback) => {
         this.getDataEkspedisi(dataTablesParameters, callback, $('#codeEks').val().toString(), $('#nameEks').val().toString(), 
         $('#addressEks').val().toString(),"Ekspedisi",$('#slEks').val().toString(),true);
        },
        columnDefs: [
          { width: 100, targets: [0,1,4,5,6,7,8,9,10] },
          { width: 200, targets: 2 },
          { width: 300, targets: 3 },
        ],
      };
      
      this.dtOptionsDetailDo = {
        retrieve: true,
        pageLength: 10,
        ordering : false,
        scrollX: true,
        scrollCollapse: true,
        serverSide: true,
        processing: true,
        searching : false,
        ajax: (dataTablesParameters: any, callback) => {
          this.dt = dataTablesParameters; this.cb = callback;
          this.getDetailDoNew(dataTablesParameters, callback, $('#doNo').val().toString(), $('#mainDo').val().toString(),true);
         },
      };

      this.dtOptionsDetailDo2 = {
        retrieve: true,
        pageLength: 10,
        ordering : false,
        scrollX: true,
        scrollCollapse: true,
        serverSide: true,
        processing: true,
        searching : false,
        ajax: (dataTablesParameters: any, callback) => {
          this.dt = dataTablesParameters; this.cb = callback;
          this.getDetailDoNew2(dataTablesParameters, callback, $('#doNo2').val().toString(), $('#mainDo').val().toString(), true);
         },
      };

      const that = this;

      $('.searchDo').on("keyup", function(e) {
        var key = e.which;
        if (key == 13) // the enter key ascii code
        {
          that.getDetailDoNew(that.dt, that.cb, $('#doNo').val().toString(), $('#mainDo').val().toString(), false);
        }
      });  

      $('.searchDo2').on("keyup", function(e) {
        var key = e.which;
        if (key == 13) // the enter key ascii code
        {
          that.getDetailDoNew2(that.dt, that.cb, $('#doNo2').val().toString(), $('#mainDo').val().toString(),false);
        }
      }); 

      $('.searchEkspedisi').on("keyup", function(e) {
        var key = e.which;
        if (key == 13) // the enter key ascii code
        {
          that.getDataEkspedisi(that.dt, that.cb, $('#codeEks').val().toString(), $('#nameEks').val().toString(), 
          $('#addressEks').val().toString(), "EKSPEDISI",
          $('#slEks').val().toString(),false);
        }
      });  

    }   

    public expanded = false;

    showCheckboxes() {
     
     var checkboxes = document.getElementById("checkboxes");
     if (!this.expanded) {
       checkboxes.style.display = "block";
       this.expanded = true;
     } else {
       checkboxes.style.display = "none";
       this.expanded = false;
     }
   }
    
    ngAfterViewInit(): void {
    }
  
    ngOnDestroy(): void {
    }


  messageWarning(text: string){
    Swal.fire({
      title: ""+text,
      type: 'warning',
      confirmButtonText: '      Ok!     '
    });
    return;
  }

  getDataEkspedisi(dataTablesParameters, callback, relation_code: string, relation_name: string, relation_address: string, 
    relation_type: string, relation_sl_code: string, padging : boolean){ 
    var page = 1;
    if (padging == true){
      page = parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length) + 1;
    }
    this.service.getListRelation(dataTablesParameters, page, dataTablesParameters.length, relation_code,
      relation_name, relation_address, relation_type, relation_sl_code)
      .subscribe(resp => { 
        this.dataEkspedisi = resp.data;
        callback({
          recordsTotal: resp.totalCount,
          recordsFiltered: resp.totalCount,
           data:  []
        });
    });
  }

  getDetailDoNew(dataTablesParameters, callback, no_do: string, main_do: string, padging : boolean){ 
    var page = 1;
    if (padging == true){
      page = parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length) + 1;
    }
    this.spinner.show();
    this.service.getDetailDoNew(dataTablesParameters, page, dataTablesParameters.length, no_do, main_do)
      .subscribe(resp => { 
        this.spinner.hide();
        this.listDoDetail = resp.data;
        callback({
          recordsTotal: resp.totalCount,
          recordsFiltered: resp.totalCount,
           data:  []
        });
    });
  }

  getDetailDoNew2(dataTablesParameters, callback, no_do: string, main_do: string, padging : boolean){ 
    var page = 1;
    if (padging == true){
      page = parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length) + 1;
    }
    this.spinner.show();
    this.service.getDetailDoNew(dataTablesParameters, page, dataTablesParameters.length, no_do, main_do)
      .subscribe(resp => { 
        this.spinner.hide();
        this.listDoDetail2 = resp.data;
        callback({
          recordsTotal: resp.totalCount,
          recordsFiltered: resp.totalCount,
           data:  []
        });
    });
  }

SaveData(){
  if(this.distModel.cost == null || this.distModel.depart_date == null || this.distModel.vendor == null )
  {
    Swal.fire({
      title: "Data Belum Lengkap !",
      type: 'warning',
      confirmButtonText: '      Ok!     '
    });
    return;
  }
  if(this.distModel.cost > this.mainDoCost )
  {
    Swal.fire({
      title: "Harga ongkos angkut ke ekspedisi tidak boleh lebih besar dari harga ongkos angkut SPK!",
      type: 'warning',
      confirmButtonText: '      Ok!     '
    });
    return;
  }
  if (this.distModel.cost == 0){
    Swal.fire({
      title: "OA Tidak Boleh 0!",
      type: 'warning',
      confirmButtonText: '      Ok!     '
    });
    return;
  }
  Swal.fire({
    title: 'Simpan Data?',
    type: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: '      Yes     '
  }).then((result) => {
    if (result.value) {
      this.spinner.show();
       this.service.insertTransDistributed(this.distModel).subscribe(resp => { 
         this.spinner.hide();
       if(resp.succes == false){
         Swal.fire({
           title: ""+resp.text,
           type: 'warning',
           confirmButtonText: '      Ok!     '
         });
       }
       else{
         Swal.fire({
           title: ""+resp.text,
           type: 'success',
           confirmButtonText: '      Ok!     '
         }).then((result) => {
            this.clear();
           if (result.value) { 
             //
           }              
         }, (error) => {
           ; 
         })
       }
       });
 }

  });
}

  checkDo(){
    this.checkModel.do_from = this.doFrom;
    this.checkModel.do_to = this.doTo;
    this.service.checkDo(this.checkModel).subscribe( resp =>{
      if(resp.succes == false){
        Swal.fire({
          title: ""+resp.text,
          type: 'warning',
          confirmButtonText: '      Ok!     '
        });
      }
      else{
        this.save = true;
        this.distModel.charge_type = resp.chargetype;
        this.distModel.listOfDetailDo = resp.listDo;
        this.mainDoCost = resp.cost;
        this.doList = "";
        this.distModel.listOfDetailDo.forEach(item =>{
          this.doList += item.do_no+",";
        })
        Swal.fire({
          title: "DO : "+this.doList,
          type: 'success',
          confirmButtonText: '      Ok!     '
        });
      }
    })
  }


  showDetail(){
    this.dtTriggerDetail.next(0);
  }
  
  showDetail2(){
    this.dtTriggerDetail2.next(0);
  }
  
  showEkspedisi(){
    this.dtTriggerEkspedisi.next(0);
  }

  onSelectFrom(data: ListDoDetail){
    this.doFrom = data.do_no;
    this.modalFrom.nativeElement.click();
  }

  onSelectTo(data: ListDoDetail){
    this.doTo = data.do_no;
    this.modalTo.nativeElement.click();
  }

  onSelectEkspedisi(data: Relation){
    this.ekspedisiName = data.relation_name;
    this.distModel.vendor = data.relation_id;
  } 

  clear(){
    this.distModel = { depart_date: null, vendor: null, cost: null, charge_type: null, listOfDetailDo: new Array() }
    this.doFrom = "";
    this.doTo = "";
    this.ekspedisiName = "";
    this.save = false;
  }
  
}






