<section class="content">
    <div class="card">
      <div class="card-header">
      <strong>Update Masal BBM Uang Jalan </strong>
    </div>
      <div class="card-body">
        <div class="row">
            <div class="col-md-6">
              <div class="form-group row">
                    <!-- <label class="col-md-3 col-form-label" for="text-input">Tipe Kendaraan</label>
                    <input hidden disabled type="text" class="form-control">
                    <div class="col-md-7">
                      <input hidden disabled type="text" class="form-control">
                      <input [(ngModel)]="vehicleName" [ngModelOptions]="{standalone: true}" disabled type="text" class="form-control">
                    </div>
                    <div class="col-md-2">
                        <button class="btn btn-sm btn-primary" (click)="showVehicle();ModalVehicle.show()"  
                        data-toggle="modal"><i class="fa fa-plus"></i></button>
                    </div> -->
                    <label class="col-md-3 col-form-label" for="text-input">Tipe Kendaraan</label>
                    <div class="col-md-8">
                      <select [(ngModel)]="vehicleName" class="form-control">
                        <option value=""><b>All</b></option>
                        <option *ngFor="let row of dataVehicles" (click)="onSelectVehicle(row)"><b>{{row.name}}</b></option>
                      </select>    
                    </div> 
                    <!-- <div class="col-md-8">
                    <select [(ngModel)]="listMainDO" (ngModelChange)="onSelectDOMain($event)" class="form-control">
                       <option *ngFor="let row of listMainDoNo" [ngValue]="row" ><b>{{row.main_do}}</b></option>
                    </select>   
                   </div>  -->
                </div> 
                <div class="form-group row">
                    <label class="col-md-3 col-form-label" for="text-input">Kapasitas</label>
                    <input hidden disabled type="text" class="form-control">
                    <div class="col-md-7">
                      <input hidden disabled type="text" class="form-control">
                      <input [(ngModel)]="capacityTon" [ngModelOptions]="{standalone: true}" disabled type="text" class="form-control">
                    </div>
                    <div class="col-md-2">
                        <button class="btn btn-sm btn-primary" (click)="showCapacity();ModalCapacity.show()"  
                        data-toggle="modal"><i class="fa fa-plus"></i></button>
                    </div>
                </div> 
                <div class="form-group row">
                    <label class="col-md-3 col-form-label" for="text-input">Rute</label>
                    <input hidden disabled type="text" class="form-control">
                    <div class="col-md-7">
                        <input hidden [(ngModel)]="modelUpdate.route_id" disabled type="text" class="form-control">
                        <input [(ngModel)]="routeName" [ngModelOptions]="{standalone: true}" disabled type="text" class="form-control">
                    </div>
                    <div class="col-md-2">
                        <button class="btn btn-sm btn-primary" (click)="showRoute();ModalRoute.show()"
                        data-toggle="modal" ><i class="fa fa-plus"></i></button>
                    </div>
                </div> 
                <div class="form-group row">
                    <label class="col-md-3 col-form-label" for="text-input">Material</label>
                    <input hidden disabled type="text" class="form-control">
                    <div class="col-md-7">
                        <input hidden [(ngModel)]="modelUpdate.material_id" disabled type="text" class="form-control">
                        <input [(ngModel)]="materialName" [ngModelOptions]="{standalone: true}" disabled type="text" class="form-control">
                    </div>
                    <div class="col-md-2">
                        <button class="btn btn-sm btn-primary" (click)="showMaterial();ModalMaterial.show()" 
                        data-toggle="modal" ><i class="fa fa-plus"></i></button>
                    </div>
                </div> 
            </div>
            <div class="col-md-6">
                <div class="form-group row">
                    <label class="col-md-3 col-form-label" for="text-input">BBM Sebelumnya</label>
                    <div class="col-md-8">
                        <input [(ngModel)]="bbmOld" currencyMask [options]="{ prefix: '', thousands: '.', decimal: ',' ,precision: '0',align: 'left' }" (ngModelChange)="calculate()" type="text" class="form-control">
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-3 col-form-label" for="text-input">BBM Sekarang</label>
                    <div class="col-md-8">
                         <input [(ngModel)]="bbmNew" currencyMask [options]="{ prefix: '', thousands: '.', decimal: ',' ,precision: '0',align: 'left' }" (ngModelChange)="calculate()" type="text" class="form-control">
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-3 col-form-label" for="text-input">Presentase</label>
                    <div class="col-md-6">
                        <input disabled [(ngModel)]="modelUpdate.percentage" currencyMask [options]="{ prefix: '', thousands: '.', decimal: ',' ,precision: '0',align: 'left' }" type="text" class="form-control">
                    </div>
                    <div class="col-md-2">
                        <label class="col-md-3 col-form-label" for="text-input"><b>%</b></label>
                    </div>
                </div>
            </div>                
        </div> 
      </div>
  </div>
  <br>
  <div style="text-align: center">
    <button (click)="simulate()" type="button" class="btn btn-primary"><i class="fas fa-random"></i>&nbsp;<b>Simulasi</b>&nbsp;</button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <!-- <button (click)="clearGenerate()" type="button" class="btn btn-danger"><i class="fa fa-eraser"></i>&nbsp;<b>Bersih</b>&nbsp;</button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <button type="button" class="btn btn-warning"><i class="fas fa-info"></i>&nbsp;<b>Informasi</b>&nbsp;</button> -->
 </div>
 <br><br>
  <div class="card">
    <div class="card-body">
      <table datatable [dtOptions]="dtOptionsSimulate" [dtTrigger]="dtTriggerSimulate" class="table-striped">
        <thead>
          <tr>
            <th>Transit Port</th>
            <th>Nama Rute</th>
            <th>Rute Dari</th>
            <th>Rute Ke</th>
            <th>Kode Kapasitas</th>
            <th>Nama Material</th>
            <th>Uang Jalan Lama</th>
            <th>Uang Jalan Baru</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let row of listTable">    
            <td>{{row.transiT_PORT}}</td>
            <td>{{row.namA_RUTE}}</td>
            <td>{{row.rutE_DARI}}</td>
            <td>{{row.rutE_KE}}</td>
            <td>{{row.kodE_KAPASITAS}}</td>
            <td>{{row.namA_MATERIAL}}</td>
            <td>{{row.uanG_JALAN_LAMA | number}}</td>
            <td>{{row.uanG_JALAN_BARU | number}}</td> 
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <br>
  <div style="text-align: center">
    <button (click)="onClickSave()" type="button" class="btn btn-primary"><i class="fas fa-save"></i>&nbsp;<b>Simpan Data</b>&nbsp;</button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <button type="button" (click)="exportSPK()" class="btn btn-warning"><i class="fa fa-file-excel"></i>&nbsp;<b>Export Excel</b>&nbsp;</button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
 </div>


  </section>


  
<div  bsModal #ModalCapacity="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog" style="max-width: 35%;" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title"><strong>Pilih Kapasitas Mobil</strong></div>
          <button type="button" class="close" (click)="ModalCapacity.hide()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <table datatable  [dtOptions]="dtOptionsCapacity" [dtTrigger]="dtTriggerCapacity"  class="table-striped">
            <thead>
              <tr>
                <th>Action</th>
                <th>Kode</th>
                <th>Keterangan</th>
                <th>Tipe Kendaraan</th>
                <th>Tonnase</th>
                <th>Diubah Oleh</th>
                <th>Diubah Pada</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let row of dataCapacity">
                <td>
                  <button (click)="onSelectCapacity(row);ModalCapacity.hide()" class="btn btn-sm btn-primary"  data-toggle="modal" triggers="mouseenter:mouseleave"><i class="fa fa-check"></i></button>
                </td> 
                <td>{{row.capacity_code}}</td>
                <td>{{row.capacity_note}}</td>
                <td>{{row.vehicleType}}</td>
                <td>{{row.tonCode}}</td>
                <td>{{row.updatedby}}</td>  
                <td>{{row.updated_at | slice:0:10}}</td>    
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th><input hidden class="searchInputCapacity"/></th>
                <th><input id="codecapacity" type="text" class="searchInputCapacity" placeholder="Kode" /></th>
                <th><input id="namecapacity" type="text" class="searchInputCapacity" placeholder="Nama" /></th>
                <th><input id="vehiclesearch" type="text" class="searchInputCapacity" placeholder="Kendaraan"/></th>
                <th><input hidden class="searchInputCapacity"/></th>
                <th><input hidden class="searchInputCapacity"/></th>
                <th><input hidden class="searchInputCapacity"/></th>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
    </div>

    
<div bsModal #ModalRoute="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog  modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title">
            <div class="row">
              <strong>Pilih Rute</strong>
            </div>
          </div>
          <button type="button" class="close" (click)="ModalRoute.hide()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <!-- <app-master-relation></app-master-relation> -->
            <table datatable [dtOptions]="dtOptionsRoute" [dtTrigger]="dtTriggerRoute" class="table-striped">
              <thead>
                <tr>
                  <th>Action</th>
                  <th>Rute</th>
                  <th>Lokasi Muat</th>
                  <th>Lokasi Bongkar</th>
                  <th>Tipe Rute</th>
                  <th>Diubah Oleh</th>
                  <th>Dibuat Pada</th>
                  <th>Diubah Oleh</th>
                  <th>Diubah Pada</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let row of dataRoute">    
                  <td>
                    <button (click)="onSelectRoute(row);ModalRoute.hide()" class="btn btn-sm btn-primary"  data-toggle="modal" triggers="mouseenter:mouseleave"><i class="fa fa-check"></i></button>
                  </td> 
                  <td>{{row.route_name}}</td>
                  <td>{{row.loadtext}}</td>
                  <td>{{row.unloadtext}}</td>
                  <td>{{row.route_type}}</td>
                  <td>{{row.createdby}}</td>
                  <td>{{row.created_at | slice:0:10}}</td>  
                  <td>{{row.updatedby}}</td>  
                  <td>{{row.updated_at | slice:0:10}}</td>        
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th><input hidden class="searchRute"/></th>
                  <th><input id="nameRute" type="text" class="searchRute" placeholder="Cari Nama" /></th>
                  <th><input id="loadRute" type="text" class="searchRute" placeholder="Cari Muat" /></th>
                  <th><input id="unloadRute" type="text" class="searchRute" placeholder="Cari Bongkar" /></th>
                  <th><input id="typeRute" type="text" class="searchRute" placeholder="Cari Type" /></th>
                  <th><input hidden class="searchRute"/></th>
                  <th><input hidden class="searchRute"/></th>
                  <th><input hidden class="searchRute"/></th>
                  <th><input hidden class="searchRute"/></th>
                </tr>
              </tfoot>
            </table>
        </div>
      </div>
    </div>
  </div>

  
<div  bsModal #ModalMaterial="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog  modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title">
            <div class="row">
              <strong>Pilih Material</strong>
            </div>
          </div>
          <button type="button" class="close" (click)="ModalMaterial.hide()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <!-- <app-master-material></app-master-material> -->
            <table datatable [dtOptions]="dtOptionsMaterial" [dtTrigger]="dtTriggerMaterial" class="table-striped">
              <thead>
               <tr>
                 <th>Action</th>
                 <th>Kode Material</th>
                 <th>Nama Material</th>
                 <th>Harga</th>
                 <th>UOM</th>
                 <th>Tipe Cetakan DO</th>
                 <th>Dibuat Oleh</th>
                 <th>Dibuat Pada</th>
                 <th>Diubah Oleh</th>
                 <th>Diubah Pada</th>
               </tr>
             </thead> 
             <tbody>
               <tr *ngFor="let row of dataMaterial">
               <td>
                <button title="Pilih Data" (click)="onSelectMaterial(row);ModalMaterial.hide()" class="btn btn-sm btn-primary" triggers="mouseenter:mouseleave"><i class="fa fa-check"></i></button>
               </td>       
                 <td>{{row.material_code}}</td>
                 <td>{{row.material_name}}</td>
                 <td>{{row.material_price}}</td>
                 <td>{{row.uom}}</td>
                 <td>{{row.do_print_type}}</td>
                 <td>{{row.created}}</td>
                 <td>{{row.created_at | slice:0:10}}</td>  
                 <td>{{row.updated_by}}</td>  
                 <td>{{row.updated_at | slice:0:10}}</td>    
               </tr>
             </tbody>
             <tfoot>
               <tr>
                 <th><input hidden class="searchMaterial" /></th>
                 <th><input id="codeMaterial" type="text" class="searchMaterial" placeholder="Search Kode"/></th>
                 <th><input id="nameMaterial" type="text" class="searchMaterial" placeholder="Search Material"/></th>
                 <th><input hidden type="text" class="searchMaterial"/></th>
                 <th><input hidden type="text" class="searchMaterial"/></th>
                 <th><input hidden type="text" class="searchMaterial"/></th>
                 <th><input hidden type="text" class="searchMaterial"/></th>
                 <th><input hidden type="text" class="searchMaterial"/></th>
                 <th><input hidden type="text" class="searchMaterial"/></th>
                 <th><input hidden type="text" class="searchMaterial"/></th>
               </tr>
             </tfoot>
       
           </table>
        </div>
      </div>
    </div>
  </div>
  
  <div  bsModal #ModalSimpan="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog  modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" (click)="ModalSimpan.hide()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group row">
                        <label class="col-md-4 col-form-label" for="text-input">Berlaku Dari Tanggal</label>
                        <div class="col-md-7">
                            <input type="date" class="form-control" [ngModel]="modelSave.valid_to | date:'yyyy-MM-dd'" (ngModelChange)="modelSave.valid_to = $event">
                        </div>
                    </div> 
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" (click)="saveData()" class="btn btn-primary"><i class="fas fa-save"></i>&nbsp;<b>Simpan</b>&nbsp;</button>
            <button type="button" #ModalSimpanClose class="btn btn-secondary" (click)="ModalSimpan.hide()">&nbsp;<b>Cancel</b>&nbsp;</button> 
        </div>
        </div>
      </div>
    </div>
    

    <div  bsModal #ModalVehicle="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
      <div class="modal-dialog" style="max-width: 35%;" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <div class="modal-title"><strong>Pilih Kapasitas Mobil</strong></div>
            <button type="button" class="close" (click)="ModalVehicle.hide()" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <table datatable [dtOptions]="dtOptionsVehicle" [dtTrigger]="dtTriggerVehicle"  class="table-striped">
              <thead>
                <tr>
                  <th>Action</th>
                  <th>Kode</th>
                  <th>Kendaraan</th>
                  <th>Dibuat Oleh</th>
                  <th>Dibuat Pada</th>
                  <th>Diubah Oleh</th>
                  <th>Diubah Pada</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let row of dataVehicles">    
                  <td>
                    <button (click)="onSelectVehicle(row);ModalVehicle.hide()" class="btn btn-sm btn-primary"  data-toggle="modal"triggers="mouseenter:mouseleave"><i class="fa fa-pen"></i></button>
                  </td>       
                  <td>{{row.code}}</td>
                  <td>{{row.name}}</td>
                  <td>{{row.createdby}}</td>
                  <td>{{row.created_at | slice:0:10}}</td>  
                  <td>{{row.updatedby}}</td>  
                  <td>{{row.updated_at | slice:0:10}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      </div>
  

  <button #ModalSimpanShow hidden (click)="ModalSimpan.show()"></button>
    
  <ngx-spinner  bdColor = "rgba(248,248,248,0.95)" size = "large" color = "#262424" type = "ball-spin-fade" >
    <p style="font-size: 20px; color: white"></p>
  </ngx-spinner>


