<section class="content">    
    <div class="card">
        <div class="card-header">
          <strong>Daftar Pembayaran</strong>
          <div class="float-right">
          <a type="button" class="btn btn-sm btn-primary pull-right" data-toggle="modal" (click)="ModalSimpan.show();setSave();setNull()">&nbsp;&nbsp;<b>Tambah Pembayaran</b>&nbsp;&nbsp;&nbsp;<i class="fa fa-pen"></i>&nbsp;&nbsp;&nbsp;</a>
          </div>
        </div>
        <div class="card-body">
            <table datatable [dtOptions]="dtOptionsPayment" [dtTrigger]="dtTriggerPayment" class="table-striped">
                <thead>
                  <tr>
                    <th>Action</th>
                    <th>Jenis Pembayaran</th>
                    <th>Pembayar</th>
                    <th>No Pembayaran</th>
                    <th>Jumlah</th>
                    <th>Tanggal Bayar</th>
                    <th>Status</th>
                    <th>Nama Bank</th>
                    <th>Rekening Bank</th>
                    <th>Dibuat Oleh</th>
                    <th>Dibuat Tanggal</th>
                    <th>Diubah Oleh</th>
                    <th>Diubah Tanggal</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of dataListPayment">    
                    <td>
                      <button (click)="onSelectPayment(row);ModalSimpan.show();setUpdate()" title="Detail" class="btn btn-sm btn-primary" triggers="mouseenter:mouseleave"><i class="fa fa-list"></i></button>&nbsp;
                      <button (click)="deleteData(row)" class="btn btn-sm btn-danger" title="Delete" triggers="mouseenter:mouseleave"><i class="fa fa-trash"></i></button>&nbsp;
                    </td>       
                    <td>{{row.payment_type}}</td>
                    <td>{{row.relation_name}}</td>
                    <td>{{row.payment_no}}</td>
                    <td>{{row.pay_amount | number}}</td>
                    <td>{{row.payment_date | slice:0:10}}</td>
                    <td>{{row.status_name}}</td>
                    <td>{{row.bank_name}}</td>
                    <td>{{row.bank_account}}</td>
                    <td>{{row.created_bytext}}</td>
                    <td>{{row.created_at | slice:0:10}}</td>
                    <td>{{row.updated_bytext}}</td>
                    <td>{{row.updated_at | slice:0:10}}</td>
                </tr>
                </tbody>
                <tfoot>
                    <tr>
                      <th><input hidden class="searchInput"/></th>
                      <th><input id="payment_type" type="text" class="searchInput" placeholder="Tipe Pembayaran"/></th>
                      <th><input id="relation_name" type="text" class="searchInput" placeholder="Pembayar"/></th>
                      <th><input id="payment_no" type="text" class="searchInput" placeholder="No Pembayaran"/></th>
                      <th><input hidden class="searchInput"/></th>
                      <th><input hidden class="searchInput"/></th>
                      <th><input id="status_name" type="text" class="searchInput" placeholder="Status"/></th>
                      <th><input hidden class="searchInput"/></th>
                      <th><input hidden class="searchInput"/></th>
                      <th><input hidden class="searchInput"/></th>
                      <th><input hidden class="searchInput"/></th>
                      <th><input hidden class="searchInput"/></th>
                      <th><input hidden class="searchInput"/></th>
                    </tr>
                  </tfoot>
              </table>
        </div>
    </div>
  </section>

  <div  bsModal #ModalSimpan="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog" style="max-width: 70%;"  role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title">
            <div class="row">
              <strong>Input Data Hutang</strong>
            </div>
          </div>
          <button type="button" class="close" (click)="ModalSimpan.hide()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-6"> 
                  <div class="form-group row">
                    <label class="col-md-3 col-form-label" for="text-input">No Pembayaran</label>
                    <div class="col-md-8">
                        <input [(ngModel)]="paymentModel.payment_no" type="text" class="form-control" >
                    </div>
                  </div> 
                  <div class="form-group row group">
                    <label class="col-md-3 col-form-label" for="text-input">Pembayaran</label>
                    <div class="col-md-8">
                      <select [(ngModel)]="modelType" (ngModelChange)="onSelectType($event)" id="select" name="select" class="form-control">
                        <option selected *ngFor="let row of listCostType" [ngValue]="row" ><b>{{row.text}}</b></option>
                      </select>
                    </div>
                  </div>  
                </div>
                <div class="col-md-6">
                  <div class="form-group row">
                    <label class="col-md-3 col-form-label" for="text-input">Pembayar</label>
                    <div class="col-md-7">
                        <input [(ngModel)]="customer" type="text" class="form-control" >
                    </div>
                    <div class="col-md-2">
                      <button class="btn btn-sm btn-primary" (click)="showCustomer();"
                      data-toggle="modal"><i class="fa fa-plus"></i></button>
                    </div>
                  </div> 
                  <div class="form-group row">
                    <label class="col-md-3 col-form-label" for="text-input">No Invoice</label>
                    <div class="col-md-8">
                      <select id="select" name="select" [(ngModel)]="model" (ngModelChange)="onSelectInvoice($event)" class="form-control">
                        <option selected *ngFor="let row of dataInvoice" [ngValue]="row"><b>{{row.invoice_no}}</b></option>
                      </select>
                    </div>
                  </div> 
                </div>
            </div>
            </div>
          </div>
          <div style="text-align: center">
            <button [disabled]="payment" (click)="addDataTambahan()" type="button" class="btn btn-primary"><i class="fas fa-plus"></i>&nbsp;<b>Tambah</b>&nbsp;</button>
         </div>
         <br>
         <div class="card">
          <div class="card-body"> 
            <div class="scroll">
              <table class="table table-striped table-sm">
                  <thead>
                  <tr>
                      <th>No Invoice</th>
                      <th>Tipe</th>
                      <th>Total</th>
                      <th>Action</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let row of dataTambahan; let i=index">
                      <td>{{row.invoice_no}}</td>
                      <td>{{row.cost_type_text}}</td>
                      <td>Rp. {{row.amount | number}}</td>
                      <td>
                        <button [disabled]="payment" (click)="onSelectDataTambahan(row);ModalPotongan.show()" title="Tambah Potongan" class="btn btn-sm btn-primary" triggers="mouseenter:mouseleave"><i class="fa fa-plus"></i></button>&nbsp;
                        <button [disabled]="payment" class="btn btn-sm btn-danger" (click)="deleteDataTambahan(i);minTotal(row)" triggers="mouseenter:mouseleave"><i class="fa fa-trash"></i></button>
                      </td>
                      
                  </tr>
                  </tbody>
              </table>              
            </div>
          </div>    
      </div>
      <div class="col-lg-2 col-6">
        <div class="small-box">
            <div class="inner">
                <p>Total</p>
                <h2>Rp. {{total | number}}</h2>
            </div>
        </div>
      </div>
        </div>
        <div class="modal-footer">
          <button #modalSimpanClose type="button" class="btn btn-secondary" (click)="ModalSimpan.hide()">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>Close</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</button>
          <button [disabled]="payment"  type="button" class="btn btn-primary" (click)="saveData()">&nbsp;&nbsp;&nbsp;&nbsp;<i class="fa fa-save"></i>&nbsp;&nbsp;<b>Simpan</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</button>
          <button [disabled]="payment" type="button" class="btn btn-primary" (click)="ModalConfirm.show();"><i class="fas fa-hand-holding-usd"></i>&nbsp;&nbsp;<b>Simpan & Bayar</b>&nbsp;&nbsp;</button>
        </div>
      </div>
    </div>
  </div>

  <div  bsModal #ModalCustomer="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title">
            <div class="row">
              <strong>Pilih Customer</strong>
            </div>
          </div>
          <button type="button" class="close" (click)="ModalCustomer.hide()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <table datatable [dtOptions]="dtOptionsCustomer" [dtTrigger]="dtTriggerCustomer" class="table-striped">
                <thead>
                  <tr>
                    <th>Action</th>
                    <th>Kode</th>
                    <th>Nama</th>
                    <th>Alamat</th>
                    <th>Kode SL</th>
                    <th>Phone</th>
                    <th>Active</th>
                    <th>Dibuat Oleh</th>
                    <th>Dibuat Pada</th>
                    <th>Diubah Oleh</th>
                    <th>Diubah Pada</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of dataRelation">      
                    <td style="width: 200;">
                      <button (click)="onSelectCustomer(row);ModalCustomer.hide()" title="Edit"  class="btn btn-sm btn-primary" data-toggle="modal" triggers="mouseenter:mouseleave"><i class="fa fa-check"></i></button>
                    </td>  
                    <td>{{row.relation_code}}</td>
                    <td>{{row.relation_name}}</td>
                    <td>{{row.relation_address}}</td>
                    <td>{{row.relation_sl_code}}</td>
                    <td>{{row.relation_phone}}</td>
                    <td>{{row.active}}</td>cl
                    <td>{{row.createdby}}</td>
                    <td>{{row.created_at | slice:0:10}}</td>  
                    <td>{{row.updatedby}}</td>  
                    <td>{{row.updated_at | slice:0:10}}</td>   
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <th><input hidden class="searchCustomer"/></th>
                    <th><input id="codecustomer" type="text" class="searchCustomer" placeholder="Kode"/></th>
                    <th><input id="namecustomer" type="text" class="searchCustomer" placeholder="Nama"/></th>
                    <th><input id="addresscustomer" type="text" class="searchCustomer" placeholder="Alamat"/></th>
                    <th><input id="slcustomer" type="text" class="searchCustomer" placeholder="Kode SL"/></th>
                    <th><input hidden class="searchCustomer"/></th>
                    <th><input hidden class="searchCustomer"/></th>
                    <th><input hidden class="searchCustomer"/></th>
                    <th><input hidden class="searchCustomer"/></th>
                    <th><input hidden class="searchCustomer"/></th>
                    <th><input hidden class="searchCustomer"/></th>
                  </tr>
                </tfoot>
      
              </table>
        </div>
      </div>
    </div>
  </div>

  <div  bsModal #ModalConfirm="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog" style="max-width: 70%;"  role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title">
            <div class="row">
              <strong>Konfirmasi Pembayaran</strong>
            </div>
          </div>
          <button type="button" class="close" (click)="ModalConfirm.hide()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-6"> 
                  <div class="form-group row">
                    <label class="col-md-3 col-form-label" for="text-input">Jumlah Dibayarkan</label>
                    <div class="col-md-8">
                        <input disabled value="Rp. {{total | number}}" type="text" class="form-control" >
                    </div>
                  </div> 
                  <div class="form-group row">
                    <label class="col-md-3 col-form-label" for="text-input">Tanggal bayar</label>
                    <div class="col-md-8">
                        <input [ngModel]="paymentModel.payment_date | date:'yyyy-MM-dd'" (ngModelChange)="paymentModel.payment_date = $event" type="date"  class="form-control" >
                    </div>
                  </div> 
                </div>
                <div class="col-md-6">
                  <div class="form-group row group">
                    <label class="col-md-3 col-form-label" for="text-input">Nama Bank</label>
                    <div class="col-md-8">
                      <select class="form-control" [(ngModel)]="model" (ngModelChange)="onSelectBank($event)">
                        <option *ngFor="let row of dataBank" [ngValue]="row"><b>{{row.name}}</b></option>
                      </select>
                    </div>
                 </div> 
                  <div class="form-group row">
                    <label class="col-md-3 col-form-label" for="text-input">Rekening Bank</label>
                    <div class="col-md-8">
                        <input disabled [(ngModel)]="rekBank" type="text" class="form-control" >
                    </div>
                  </div> 
                </div>
            </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button #modalPaymentClose type="button" class="btn btn-secondary" (click)="ModalConfirm.hide()">&nbsp;&nbsp;&nbsp;&nbsp;<b>Close</b>&nbsp;&nbsp;&nbsp;&nbsp;</button>
          <button type="button" class="btn btn-primary" (click)="saveAndPayment()">&nbsp;&nbsp;&nbsp;&nbsp;<i class="fa fa-save"></i>&nbsp;&nbsp;<b>Konfirmasi</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</button>
        </div>
      </div>
    </div>
  </div>

  <div  bsModal #ModalPotongan="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog" style="max-width: 50%;" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title">
            <div class="row">
              <strong>Potongan Pembayaran</strong>
            </div>
          </div>
          <button type="button" class="close" (click)="ModalPotongan.hide()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-12"> 
                  <div class="form-group row">
                    <label class="col-md-3 col-form-label" for="text-input">No Invoice</label>
                    <div class="col-md-8">
                        <input disabled value="{{invoicePot}}" type="text" class="form-control" >
                    </div>
                  </div> 
                  <div class="form-group row group">
                    <label class="col-md-3 col-form-label" for="text-input">Jenis Potongan</label>
                    <div class="col-md-8">
                      <select [(ngModel)]="model" (ngModelChange)="onSelectTypePot($event)" class="form-control">
                        <option *ngFor="let row of listType" [ngValue]="row"><b>{{row.name}}</b></option>
                      </select>
                    </div>
                 </div>
                 <div class="form-group row">
                  <label class="col-md-3 col-form-label" for="text-input">Jumlah</label>
                  <div class="col-md-8">
                      <input [(ngModel)]="totalPot" type="text" currencyMask [options]="{ prefix: '', thousands: '.', decimal: ',' ,precision: '0',align: 'left' }" class="form-control" >
                  </div>
                </div> 
                </div>
            </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button #modalPotonganClose type="button" class="btn btn-secondary" (click)="ModalPotongan.hide()">&nbsp;&nbsp;&nbsp;&nbsp;<b>Close</b>&nbsp;&nbsp;&nbsp;&nbsp;</button>
          <button type="button" class="btn btn-primary" (click)="addDataPotongan()">&nbsp;&nbsp;&nbsp;&nbsp;<i class="fa fa-save"></i>&nbsp;&nbsp;<b>Konfirmasi</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</button>
        </div>
      </div>
    </div>
  </div>

  <button hidden #modalCustomerShow (click)="ModalCustomer.show()"></button>
<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>