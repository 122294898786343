<section class="content">    
    <div class="card">
        <div class="card-header">
          <strong>Premi Supir</strong>
          <div class="float-right">
          <a type="button" class="btn btn-sm btn-primary pull-right" data-toggle="modal" (click)="ModalPremi.show();setNull();">&nbsp;&nbsp;<b>Generate</b>&nbsp;&nbsp;&nbsp;<i class="fa-solid fa-shuffle"></i>&nbsp;&nbsp;&nbsp;</a>
          </div>
        </div>
        <div class="card-body">
            <table datatable [dtOptions]="dtOptionsPremi" [dtTrigger]="dtTriggerPremi" class="table-striped">
                <thead>
                  <tr>
                    <th>Nama Supir</th>
                    <th>Truck</th>
                    <th>Tipe Trip</th>
                    <th>Berat Kirim</th>
                    <th>Berat Terima</th>
                    <th>Total Trip</th>
                    <th>Total KM</th>
                    <th>Total Ritase</th>
                    <th>Premi Trip</th>
                    <th>Premi KM</th>
                    <th>Premi Ritase</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of dataListPremi">          
                    <td>{{row.driver_name}}</td>
                    <td>{{row.truck_name}}</td>
                    <td>{{row.trip_type_text}}</td>
                    <td>{{row.ton_send}}</td>
                    <td>{{row.ton_receive}}</td>
                    <td>{{row.total_trip}}</td>
                    <td>{{row.total_km}}</td>
                    <td>{{row.total_ritase}}</td>
                    <td>{{row.premi_trip}}</td>
                    <td>{{row.premi_km}}</td>
                    <td>{{row.premi_ritase}}</td>
                </tr>
                </tbody>
              </table>
        </div>
    </div>
  </section>

    
<div bsModal #ModalPremi="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog" style="max-width: 80%" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title">
            <strong>Generate Premi</strong>
          </div>
          <button type="button" class="close" (click)="ModalPremi.hide()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-4"> 
                  <div class="form-group row">
                    <label class="col-md-4 col-form-label" for="text-input">Nama Supir</label>
                    <div class="col-md-6">
                        <input [(ngModel)]="driver_name" type="text" class="form-control" >
                    </div>
                    <div class="col-md-2">
                      <button class="btn btn-sm btn-primary" (click)="showDriver();ModalDriver.show()"
                      data-toggle="modal"><i class="fa fa-plus"></i></button>
                    </div>
                  </div> 
                </div>
                <div class="col-md-4">
                  <div class="form-group row">
                    <label class="col-md-4 col-form-label" for="text-input">Dari Tanggal</label>
                    <div class="col-md-7">
                        <input  type="date" [ngModel]="from | date:'yyyy-MM-dd'" (ngModelChange)="from = $event"  class="form-control">
                    </div>
                  </div> 
                </div>
                <div class="col-md-4">
                  <div class="form-group row">
                    <label class="col-md-4 col-form-label" for="text-input">Sampai Tanggal</label>
                    <div class="col-md-7">
                        <input  type="date" [ngModel]="to | date:'yyyy-MM-dd'" (ngModelChange)="to = $event"  class="form-control">
                    </div>
                </div> 
                </div>
              </div>
            </div>
          </div>
          <div style="text-align: center">
            <button type="button" (click)="getGenerate();" class="btn btn-sm btn-primary"><i class="fa-solid fa-shuffle"></i>&nbsp;&nbsp;<b>Generate</b>&nbsp;&nbsp;</button>
          </div>
          <br>
          <div class="card">
            <div class="card-body">
                <table datatable [dtOptions]="dtOptionsGenerate" [dtTrigger]="dtTriggerGenerate" class="table-striped">
                    <thead>
                      <tr>
                        <th>Action</th>
                        <th>Truk</th>
                        <th>Supir</th>
                        <th>Jenis Trip</th>
                        <th>Berat Kirim</th>
                        <th>Berat Terima</th>
                        <th>Jumlah Trip</th>
                        <th>Jumlah Ritase</th>
                        <th>Jumlah KM</th>
                        <th>Jumlah Premi Trip</th>
                        <th>Jumlah Premi KM</th>
                        <th>Jumlah Premi Ritase</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let row of dataListPremiDetailDto">    
                        <td>
                          <!-- <button (click)="onSelectDebt(row);ModalPay.show();" class="btn btn-sm btn-primary" title="Bayar Hutang" triggers="mouseenter:mouseleave"><i class="fa fa-credit-card"></i></button>&nbsp; -->
                        </td>       
                        <td>{{row.truckName}}</td>
                        <td>{{row.driverName}}</td>
                        <td>{{row.tripTypeName}}</td>
                        <td>{{row.tonSend | number}}</td>
                        <td>{{row.tonReceive | number}}</td>
                        <td>{{row.totalTrip | number}}</td>
                        <td>{{row.totalRitase | number}}</td>
                        <td>{{row.totalKm | number}}</td>
                        <td>{{row.premiTrip | number}}</td>
                        <td>{{row.premiKm | number}}</td>
                        <td>{{row.premiRitase | number}}</td>
                        <td>Rp. {{row.total | number}}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" (click)="savePremi()" class="btn btn-primary"><i class="fa fa-print"></i>&nbsp;<b>Save</b>&nbsp;</button>
          <button type="button" #ModalSimpanHide class="btn btn-secondary" (click)="ModalPremi.hide()">&nbsp;<b>Cancel</b>&nbsp;</button> 
      </div>
      </div>
    </div>
  </div>

  <div  bsModal #ModalDriver="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog  modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title"><strong>Pilih Supir</strong></div>
          <button type="button" class="close" (click)="ModalDriver.hide()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <div class="card">
              <div class="card-body">
                <table datatable [dtOptions]="dtOptionsDriver" [dtTrigger]="dtTriggerDriver" class="table-striped">
                  <thead>
                    <tr>
                      <th>Action</th>
                      <th>Nama</th>
                      <th>No SIM</th>
                      <th>Jenis SIM</th>
                      <th>Agama</th>
                      <th>Tempat Lahir</th>
                      <th>No KTP</th>
                      <th>Tanggal Lahir</th>
                      <th>SL</th>
                      <th>SL Piutang</th>
                      <th>SL Uang Jalan</th>
                      <th>Alamat</th>
                      <th>Berlaku Sampai</th>
                      <th>Kontrak Sampai</th>
                      <th>Dibuat Pada</th>
                      <th>Diubah Pada</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let row of dataDriver">
                      <td>
                        <button (click)="onSelectDriver(row);ModalDriver.hide()" class="btn btn-sm btn-primary"  data-toggle="modal" triggers="mouseenter:mouseleave"><i class="fa fa-check"></i></button>
                      </td>
                      <td>{{row.driver_name}}</td>
                      <td>{{row.driver_license}}</td>
                      <td>{{row.driver_license_type}}</td>
                      <td>{{row.agama}}</td>
                      <td>{{row.birthplace}}</td>
                      <td>{{row.ktp_no}}</td>
                      <td>{{row.tanggal_lahir | slice:0:10}}</td>
                      <td>{{row.sl_code}}</td>
                      <td>{{row.sl_code2}}</td>
                      <td>{{row.sl_code3}}</td>
                      <td>{{row.address}}</td>
                      <td>{{row.valid_to | slice:0:10}}</td>
                      <td>{{row.valid_to_contract | slice:0:10}}</td>
                      <td>{{row.created_at | slice:0:10}}</td>  
                      <td>{{row.updated_at | slice:0:10}}</td>   
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <th><input hidden class="searchInputD"/></th>
                      <th><input id="driver_name" type="text" class="searchInputD" placeholder="Nama"/></th>
                      <th><input id="driver_license" type="text" class="searchInputD" placeholder="No SIM"/></th>
                      <th><input id="driver_license_type" type="text" class="searchInputD" placeholder="Tipe SIM"/></th>
                      <th><input id="agama" type="text" class="searchInputD" placeholder="Agama"/></th>
                      <th><input id="birthplace" type="text" class="searchInputD" placeholder="Tempat Lahir"/></th>
                      <th><input id="ktp_no" type="text" class="searchInputD" placeholder="No KTP"/></th>
                      <th><input id="tanggal_lahir" type="text" class="searchInputD" placeholder="Tanggal Lahir"/></th>
                      <th><input id="sl_code" type="text" class="searchInputD" placeholder="SL"/></th>
                      <th><input id="sl_code2" type="text" class="searchInputD" placeholder="SL Piutang"/></th>
                      <th><input id="sl_code3" type="text" class="searchInputD" placeholder="SL UJ"/></th>
                      <th><input id="address" type="text" class="searchInputD" placeholder="Alamat"/></th>
                      <th><input id="valid_to" type="text" class="searchInputD" placeholder="Berlaku Sampai"/></th>
                      <th><input id="valid_to_contract" type="text" class="searchInputD" placeholder="Kontrak Sampai"/></th>
                      <th><input hidden class="searchInputD"/></th>
                      <th><input hidden class="searchInputD"/></th>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
        </div>
      </div>
    </div>
    </div>

<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>