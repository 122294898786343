import { AfterViewInit, Component, OnInit, ViewChild,Input, ChangeDetectorRef, ElementRef  } from '@angular/core';
import { DataTableDirective } from 'angular-datatables'
import { TransportService} from '../../..//services/api.service';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { Guid } from 'guid-typescript'
import { MasterCode } from '@/model/masterCode';
import { Capacity } from '@/model/capacity';
import { Vehicle } from '@/model/vehicle';
import { Ton } from '@/model/tonnase';
import { ListTruck } from '@/model/listTruck';
import { MasterCapacityComponent } from '../master-capacity/master-capacity.component';
import { Truck } from '@/model/truck';
import { Relation } from '@/model/relation';
import { AssetTruck, TruckAsset } from '@/model/truckAsset';
import { MasterPortComponent } from '../master-port/master-port.component';
import { AppService } from '@services/app.service';
import { Router } from '@angular/router';
import { AuthMenu } from '@/model/masterSubMenu';
import { UserAuthNew } from '@/model/respDto';

@Component({
  selector: 'app-master-truck',
  templateUrl: './master-truck.component.html',
  styleUrls: ['./master-truck.component.scss'],
  providers: [MasterCapacityComponent],
})
export class MasterTruckComponent implements OnInit, AfterViewInit {
 @ViewChild('ModalTruckClose') ModalTruckClose: ElementRef;
 @ViewChild(DataTableDirective, {static: false})
  datatableElement: DataTableDirective;
  dtOptions: any = {};
  dtOptions2: any = {};
  dtOptions3: any = {};
  public capacity: Capacity;
  dataTruck: ListTruck[] = [];
  printDataTruck: ListTruck[] = [];
  dataLocation: Location[] = [];
  dataRelation: Relation[] = [];
  dataAssetTruck: TruckAsset[] = [];
  dataMasterCode: MasterCode[] = [];
  authAccessPlb: UserAuthNew[] = [];
  assetTruckList: AssetTruck[] = [];
  dtTrigger = new Subject();
  dtTrigger2 = new Subject();
  dtTrigger3 = new Subject();
  dataTambahan: Array<{assetName: string, validFrom: Date, validTo: Date, check: boolean }> = [];
  @Input() truckModel = { truck_id : null, truck_location_id : null, truck_plate_number: null, truck_capacity_id: null,
     truck_capacity_code: null, truck_vendor: null, truck_vendor_name: null, 
     truck_location: null, frame_no : null, machine_no : null, asset:new Array() };
  @Input() tambahanModel = { assetName: '', validFrom: new Date(), validTo: new Date(), check: false};
  @Input() assetTruck = { truckId : null, assetName : null, validTo: null};
  action: string;
  public dt: any;
  public cb: any;
  public capacitySelect: string;
  public ekspedisiSelect: string;
  dataVehicles: Vehicle[] = [];
  dataTonnase: Ton[] = [];
  dataCapacity: Capacity[] = [];
  dtOptionsAsset: any = {};
  dtTriggerAsset = new Subject();
  btnCrud : boolean = true;
  //dataCapacity : Vehicle[] = [];

  constructor( public service: TransportService, private router: Router,  private spinner: NgxSpinnerService, public componentCapacity: MasterCapacityComponent, private changeDetectorRef: ChangeDetectorRef, private appServ : AppService ) {
  }

  userAcces(){
    const menuUser = localStorage.getItem("menu");
    if (menuUser != "All"){
      const locationName = localStorage.getItem('locationname');
      if (locationName != "Palembang"){
        const subMenu = localStorage.getItem("acces_menu");
        let arrayMenu =  subMenu.split(",");
        if (arrayMenu.includes("truck") == false){
          this.router.navigate(["/home"]);
        }
      }
      else{
        this.authAccessPlb = JSON.parse(localStorage.getItem("authAccessPlb"));
        var a = this.authAccessPlb.filter(item => item.subMenu  == "truck").length;
        if (a == 0){
          return this.router.navigate(["/home"]);
        }
        this.btnCrud = false;
        this.authAccessPlb.filter(item => item.subMenu == "truck").forEach(i => {
          if(i.auth == "CRUD"){
            this.btnCrud = true;
          }
         });
      }

    }
  }

  ngOnInit(): void {
    this.userAcces();
      this.tambahanModel.validFrom = new Date();
      this.getDataLocation();
      this.dtOptions = {
        responsive:true,
        serverSide: true,
        processing: true,
        searching : false,
        ordering : false,
        ajax: (dataTablesParameters: any, callback) => {
         this.dt = dataTablesParameters; this.cb = callback;
         this.getDataTruck(dataTablesParameters, callback, $('#platsearch').val().toString(), $('#codesearch').val().toString(),
         $('#locationsearch').val().toString(),true);
        },
        columnDefs: [
          { width: 120, targets: [0,3,4,5,6,7,8] },
          { width: 200, targets: [1,2] }
        ],
        
      };

      this.dtOptions2 = {
        responsive:true,
        pagingType: 'full_numbers',
        pageLength: 7,
        serverSide: true,
        processing: true,
        searching : false,
        info: false,        
        lengthChange : false,
        ajax: (dataTablesParameters: any, callback) => {
         this.dt = dataTablesParameters; this.cb = callback;
         this.componentCapacity.getDataCapacity(dataTablesParameters, callback, $('#codesearchV').val().toString(),
         $('#namesearchV').val().toString(), $('#vehiclesearchV').val().toString(),true);
        }
      };

      this.dtOptions3 = {
        responsive:true,
        pagingType: 'full_numbers',
        pageLength: 7,
        serverSide: true,
        processing: true,
        searching : false,
        info: false,        
        lengthChange : false,
        autoWidth: true,
        ajax: (dataTablesParameters: any, callback) => {
         this.dt = dataTablesParameters; this.cb = callback;
         this.getDataRelation(dataTablesParameters, callback,  $('#codesearchT').val().toString(), $('#namesearchT').val().toString(),
         "","","",true);
        }
      };

      this.dtOptionsAsset = {
        pageLength: 100,
        ordering : false,
        searching : false,
        columnDefs: [
          { width: 200, targets: [1,2,3,4,5]},
          { width: 50, targets: [0] }
        ],
      };

      const that = this;
      $('.searchInput').on("keyup", function(e) {
        var key = e.which;
        if (key == 13) // the enter key ascii code
        {
          that.getDataTruck(that.dt, that.cb, $('#platsearch').val().toString(), $('#codesearch').val().toString(),
           $('#locationsearch').val().toString(),false);
        }
      });  
      $('.searchInputV').on("keyup", function(e) {
        var key = e.which;
        if (key == 13) // the enter key ascii code
        {
          that.componentCapacity.getDataCapacity(that.dt, that.cb, $('#codesearchV').val().toString(),
          $('#namesearchV').val().toString(), $('#vehiclesearchV').val().toString(),false);
        }
      });  
      $('.searchInputT').on("keyup", function(e) {
        var key = e.which;
        if (key == 13) // the enter key ascii code
        {
          that.getDataRelation(that.dt, that.cb, $('#codesearchT').val().toString(), $('#namesearchT').val().toString(),
           "","","",false);
        }
      });  
      
    }

    saveDataTruck(){
      if (this.truckModel.truck_plate_number == null || 
        this.truckModel.truck_capacity_code == null || this.truckModel.truck_location_id == null ){
          return Swal.fire({
            title: "Data Belum Lengkap!",
            type: 'warning',
            confirmButtonText: '      Ok!     '
          });
        }
      Swal.fire({
        title: 'Save Data Truck?',
        // text: "You won't be able to revert this!",
        type: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: '      Yes     '
      }).then((result) => {
        if (result.value) {
          this.spinner.show();
          this.truckModel.asset = [];
          for (let i = 0; i < this.dataTambahan.length; i++) {
            this.truckModel.asset.push({
              asset_name : this.dataTambahan[i].assetName,
              asset_valid_from : this.dataTambahan[i].validFrom,
              asset_valid_to: this.dataTambahan[i].validTo,
              checked: this.dataTambahan[i].check
          });
          }
          this.service.addTruck(this.truckModel).subscribe(resp => {
            this.spinner.hide();
          if(resp.succes == false){
            Swal.fire({
              title: ""+resp.text,
              type: 'warning',
              confirmButtonText: '      Ok!     '
            });
          }
          else{
            Swal.fire({
              title: ""+resp.text,
              type: 'success',
              confirmButtonText: '      Ok!     '
            }).then((result) => {
              if (result.value) { 
                this.ModalTruckClose.nativeElement.click();
                this.getDataTruck(this.dt, this.cb, "", "","",true);
              }              
            });
          }
            
          });
        }
      
      }, (error) => {
      });
    } 


    addAsset(){
      if (this.assetTruck.truckId == null || this.assetTruck.assetName == null || this.assetTruck.validTo == null ){
          return Swal.fire({
            title: "Data Belum Lengkap!",
            type: 'warning',
            confirmButtonText: '      Ok!     '
          });
        }
      Swal.fire({
        title: 'Save Data Asset?',
        // text: "You won't be able to revert this!",
        type: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: '      Yes     '
      }).then((result) => {
        if (result.value) {
          this.spinner.show();
          this.service.addTruckAsset(this.assetTruck).subscribe(resp => {
            this.spinner.hide();
          if(resp.succes == false){
            Swal.fire({
              title: ""+resp.text,
              type: 'warning',
              confirmButtonText: '      Ok!     '
            });
          }
          else{
            Swal.fire({
              title: ""+resp.text,
              type: 'success',
              confirmButtonText: '      Ok!     '
            }).then((result) => {
              if (result.value) { 
                this.service.GetTruckAssetById(this.assetTruck).subscribe(resp => {  
                  this.assetTruckList = resp;
              });
              }              
            });
          }
            
          });
        }
      
      }, (error) => {
      });
    } 

    getTruckAsset(id: string){
      this.dataTambahan = [];
      this.service.getListTruckAsset(id)
      .subscribe(resp => { 
          this.dataAssetTruck = resp;
          for (let i = 0; i < resp.length; i++) {
            this.dataTambahan.push({
              assetName : resp[i].asset_name,
              validFrom : resp[i].asset_valid_from,
              validTo: resp[i].asset_valid_to,
              check: resp[i].checked
          });
        }
      });
    }

    AddDataTambahan() {
      this.dataTambahan.push( { assetName: this.tambahanModel.assetName, validFrom: this.tambahanModel.validFrom,
         validTo: this.tambahanModel.validTo, check: this.tambahanModel.check} );
    }

    getDataTruck(dataTablesParameters, callback, platNumber: string, codeCapacity: string, location: string, padging : boolean){ 
      var page = 1;
      if (padging == true){
        page = parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length) + 1;
      }
      this.service.getListTruck(dataTablesParameters, page, dataTablesParameters.length, platNumber, codeCapacity, location)
      .subscribe(resp => { 
          this.dataTruck = resp.data;
          callback({
            recordsTotal: resp.totalCount,
            recordsFiltered: resp.totalCount,
             data:  []
          });
      });
    }

    getDataRelation(dataTablesParameters, callback, relation_code: string, relation_name: string, relation_address: string, 
      relation_type: string, relation_sl_code: string, padging : boolean){ 
      var page = 1;
      if (padging == true){
        page = parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length) + 1;
      }
      this.service.getListRelation(dataTablesParameters, page, dataTablesParameters.length, relation_code,
         relation_name, relation_address, relation_type, relation_sl_code)
      .subscribe(resp => { 
          this.dataRelation = resp.data;
          callback({
            recordsTotal: resp.totalCount,
            recordsFiltered: resp.totalCount,
             data:  []
          });
      });
    }

    ngAfterViewInit(): void {
      this.dtTrigger.next(this.dtOptions);
      this.dtTrigger2.next(this.dtOptions2);
      this.dtTrigger3.next(this.dtOptions3);
      this.dtTriggerAsset.next(this.dtOptionsAsset);
    }
  
    ngOnDestroy(): void {
      this.dtTrigger.unsubscribe();
      this.dtTrigger2.unsubscribe();
      this.dtTrigger3.unsubscribe();
      this.dtTriggerAsset.unsubscribe();
    }
  
    // rerender(): void {
    //   this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
    //     dtInstance.destroy();
    //     this.dtTrigger.next(0);
    //   });
    // }

    getDataLocation(){
      this.service.getLocation()
      .subscribe(resp => { 
        this.dataLocation = resp;
         
      });
    }

    onSelectAsset(truck: ListTruck) {
      this.assetTruck.truckId = truck.truck_id;
      this.service.GetTruckAssetById(this.assetTruck).subscribe(resp => {  
          this.assetTruckList = resp;
      });
    }

    onSelectTruck(truck: ListTruck) {
      this.getTruckAsset(truck.truck_id.toString());
      this.action = "Update";
      this.truckModel.truck_id = truck.truck_id;
      this.truckModel.truck_plate_number = truck.truck_plate_number;
      this.truckModel.truck_capacity_id = truck.truck_capacity_id;
      this.truckModel.truck_location_id = truck.truck_location_id;
      this.truckModel.truck_capacity_code = truck.truck_capacity_code;
      this.capacitySelect = truck.truck_capacity_code;
      this.truckModel.truck_location = truck.truck_location;
      this.truckModel.truck_vendor = truck.truck_vendor;
      this.truckModel.truck_vendor_name = truck.truck_vendor_name;
      this.ekspedisiSelect = truck.truck_vendor_name;
      this.truckModel.frame_no = truck.frameNo;
      this.truckModel.machine_no = truck.machineNo;
    }

    onSelectCapacity(capacity: Capacity){
      this.capacitySelect = capacity.capacity_code;
      this.truckModel.truck_capacity_id = capacity.capacity_id;
      this.truckModel.truck_capacity_code = capacity.capacity_code;
    }

    onSelectEkspedisi(relation: Relation){
      this.ekspedisiSelect = relation.relation_name;
      this.truckModel.truck_vendor = relation.relation_id;
      this.truckModel.truck_vendor_name =  relation.relation_name;
    }

  deleteTruckAsset(data: AssetTruck){
      Swal.fire({
        title: 'Delete Data?',
        type: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: '      Yes     '
        }).then((result) => {
          if (result.value) {
            this.spinner.show();
            setTimeout(() => {
              this.spinner.hide();
            }, 5000);
            this.service.deleteTruckAsset(data).subscribe(resp => {
              this.spinner.hide();
                if(resp.succes == false){
                  Swal.fire({
                    title: ""+resp.text,
                    type: 'warning',
                    confirmButtonText: '      Ok!     '
                  });
                }
                else{
                  Swal.fire({
                    title: ""+resp.text,
                    type: 'success',
                    confirmButtonText: '      Ok!     '
                  }).then((result) => {
                    if (result.value) { 
                      this.service.GetTruckAssetById(data).subscribe(resp => {
                        this.assetTruckList = resp;
                      });
                    }              
                  });
                }
            })
  
          }
      })
    }


  deleteTruck(truck: Truck){
    Swal.fire({
      title: 'Delete Data?',
      // text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '      Yes     '
    }).then((result) => {
      if (result.value) {
        this.spinner.show();
        this.service.DeleteTruck(truck.truck_id).subscribe(tonnase => { 
          this.spinner.hide();
          Swal.fire({
            type: 'success',
            confirmButtonText: '      Succes!     '
          }).then((result) => {
            if (result.value) { 
              this.getDataTruck(this.dt, this.cb, "", "","",true);
            }
          
          }, (error) => {
            //; 
          })
        });
      }
    
    }, (error) => {
      //; 
    });
  }

  clearFormTruck(){
    this.action = "Input";
    this.truckModel.truck_id = null;
    this.truckModel.truck_capacity_id = null;
    this.truckModel.truck_location_id = null;
    this.truckModel.truck_capacity_code = null;
    this.truckModel.truck_location = null;
    this.truckModel.truck_vendor = null;
    this.truckModel.truck_vendor_name = null;
    this.tambahanModel.assetName = null;
    this.tambahanModel.check = null;
    this.tambahanModel.validFrom = new Date();
    this.tambahanModel.validTo = null;
    this.truckModel.truck_plate_number = null;
    this.capacitySelect = null;
    this.ekspedisiSelect = null;
  }

  deleteAsset(id: number)
  { 
    this.dataTambahan.splice(id, 1); this.changeDetectorRef.detectChanges(); 
  } 


    
  getDataVehicle(dataTablesParameters, callback, code: string, name: string, padging : boolean){ 
    var page = 1;
    if (padging == true){
      page = parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length) + 1;
    }
    this.service.getDataVehicle(dataTablesParameters, page, dataTablesParameters.length, code, name)
    .subscribe(resp => { 
        this.dataVehicles = resp.data;
        callback({
          recordsTotal: resp.totalCount,
          recordsFiltered: resp.totalCount,
           data:  []
        });
    });
  }

  getDataTonnase(dataTablesParameters, callback, code: string, name: string, quantity: string, uoms: string, padging : boolean){ 
    var page = 1;
    if (padging == true){
      page = parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length) + 1;
    }
    this.service.getDataTonnase(dataTablesParameters, page, dataTablesParameters.length, code, name, quantity, uoms)
    .subscribe(resp => { 
        this.dataTonnase = resp.data;
        callback({
          recordsTotal: resp.totalCount,
          recordsFiltered: resp.totalCount,
           data:  []
        });
    });
  }

  exportAsXLSX():void {
    this.service.getListTruck(this.dt, 1, 1000000, $('#platsearch').val().toString(), $('#codesearch').val().toString(), $('#locationsearch').val().toString())
      .subscribe(resp => { 
          this.printDataTruck = resp.data;
          this.appServ.exportAsExcelFile(this.printDataTruck, 'Master_Truck');
      });
  }

      
}



