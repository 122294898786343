<section class="content">    
    <div class="card">
        <div class="card-header">
          <strong>Pengakuan Susut Supir</strong>
          <div class="float-right">
            <a type="button" class="btn btn-sm btn-primary pull-right" data-toggle="modal" (click)="ModalPrint.show()">&nbsp;<b>Export Data</b>&nbsp;&nbsp;<i class="fa fa-file-excel"></i>&nbsp;</a> &nbsp;&nbsp;&nbsp;
          </div>
        </div>
        <div class="card-body">
            <table datatable [dtOptions]="dtOptionsSusut" [dtTrigger]="dtTriggerSusut" class="table-striped">
                <thead>
                  <tr>
                    <th>Action</th>
                    <th>DO Kecil</th>
                    <th>Driver</th>
                    <th>Material</th>
                    <th>Rute</th>
                    <th>Diakui</th>
                    <th>Alasan Cancel</th>
                    <th>Tanggal Print</th>
                    <th>Tonase Kirim</th>
                    <th>PAK Kirim</th>
                    <th>Tonase Terima</th>
                    <th>PAK Terima</th>
                    <th>Harga</th>
                    <th>Dibayar Perusahaan</th>
                    <th>Total</th>
                    <th>Tanggal Hutang</th>
                    <th>Total Susut</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of dataListSusut">    
                    <td>
                      <button [disabled]="row.acknowledge" (click)="onSelectSusut(row);ModalSusut.show();" class="btn btn-sm btn-primary" title="Akui Susut" triggers="mouseenter:mouseleave"><i class="fa fa-check"></i></button>&nbsp;
                      <button (click)="onSelectCancel(row);ModalCancel.show();" class="btn btn-sm btn-danger" title="Cancel Susut" triggers="mouseenter:mouseleave"><i class="fa fa-times"></i></button>&nbsp;
                      <button [hidden]="!btnCancel" (click)="claimSusut(row);" class="btn btn-sm btn-warning" title="Rollback Cancel" triggers="mouseenter:mouseleave"><i class="fa fa-undo"></i></button>&nbsp;
                      <button (click)="onSelectPrint(row);" class="btn btn-sm btn-primary" title="Cetak Susut" triggers="mouseenter:mouseleave"><i class="fa fa-print"></i></button>&nbsp;
                      <!-- <button [hidden]="!btnClaim" [disabled]="row.acknowledge" (click)="onSelectSusut(row);ModalSusut.show();" class="btn btn-sm btn-primary" title="Akui Susut" triggers="mouseenter:mouseleave"><i class="fa fa-check"></i></button>&nbsp;
                      <button [hidden]="!btnCancel" (click)="onSelectCancel(row);ModalCancel.show();" class="btn btn-sm btn-danger" title="Cancel Susut" triggers="mouseenter:mouseleave"><i class="fa fa-times"></i></button>&nbsp;
                      <button [hidden]="!btnCancel" (click)="claimSusut(row);" class="btn btn-sm btn-warning" title="Rollback Cancel" triggers="mouseenter:mouseleave"><i class="fa fa-undo"></i></button>&nbsp;
                      <button [hidden]="!btnPrint" (click)="onSelectPrint(row);" class="btn btn-sm btn-primary" title="Cetak Susut" triggers="mouseenter:mouseleave"><i class="fa fa-print"></i></button>&nbsp; -->
                    </td>       
                    <td>{{row.wbreff}}</td>
                    <td>{{row.driver_name}}</td>
                    <td>{{row.material_name}}</td>
                    <td>{{row.route_name}}</td>
                    <td><input disabled type="checkbox" [checked]="row.acknowledge"/></td>
                    <td>{{row.cancelled_reason}}</td>
                    <td>{{row.printed_at | slice:0:10}}</td>
                    <td>{{row.tonnage_send}}</td>
                    <td>{{row.pack_send}}</td>
                    <td>{{row.tonnage_receive}}</td>
                    <td>{{row.pack_receive}}</td>
                    <td>{{row.unit_price}}</td>
                    <td>{{row.company_paid}}</td>
                    <td>{{row.total}}</td>
                    <td>{{row.debt_date | slice:0:10}}</td>
                    <td>{{row.total_shrinkage_qty}}</td>
                </tr>
                </tbody>
                <tfoot>
                    <tr>
                      
                        <th><input hidden class="searchInput"/></th>
                        <th><input id="reff" type="text" class="searchInput" placeholder="No DO"/></th>
                        <th><input id="driver_name" type="text" class="searchInput" placeholder="Nama Driver"/></th>
                        <th><input hidden class="searchInput"/></th>
                        <th><input hidden class="searchInput"/></th>
                        <th><input hidden class="searchInput"/></th>
                        <th>
                          <select (change)="selectActive()" id="cancel" class="searchInput">
                            <option value="true"><b>Termasuk Yang Cancel</b></option>
                            <option value="" selected><b>Tidak Termasuk Yang Cancel</b></option>
                         </select> 
                        </th>
                        <th>
                          <select (change)="selectActive()" id="print" class="searchInput">
                            <option value="true"><b>Termasuk Yang Sudah Print</b></option>
                            <option value="" selected><b>Tidak Termasuk Yang Sudah Print</b></option>
                         </select> 
                        </th>
                        <th><input hidden class="searchInput"/></th>
                        <th><input hidden class="searchInput"/></th>
                        <th><input hidden class="searchInput"/></th>
                        <th><input hidden class="searchInput"/></th>
                        <th><input hidden class="searchInput"/></th>
                        <th><input hidden class="searchInput"/></th>
                        <th><input hidden class="searchInput"/></th>
                        <th><input hidden class="searchInput"/></th>
                        <th><input hidden class="searchInput"/></th>
                    </tr>
                </tfoot>
              </table>
        </div>
    </div>
  </section>

    
<div bsModal #ModalSusut="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog" style="max-width: 60%" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title">
          </div>
          <button type="button" class="close" (click)="ModalSusut.hide()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-6"> 
                    <div class="form-group row">
                        <label class="col-md-4 col-form-label" for="text-input">DO Kecil</label>
                        <div class="col-md-7">
                            <input disabled type="text" [(ngModel)]="reff" class="form-control">
                        </div>
                    </div> 
                    <div class="form-group row">
                        <label class="col-md-4 col-form-label" for="text-input">Total Susut</label>
                        <div class="col-md-7">
                            <input disabled type="text" [(ngModel)]="totalSusut" class="form-control">
                        </div>
                    </div> 
                    <div class="form-group row">
                        <label class="col-md-4 col-form-label" for="text-input">Toleransi Diizinkan</label>
                        <div class="col-md-7">
                            <input disabled type="text" [(ngModel)]="tolerance" class="form-control">
                        </div>
                    </div> 
                    <div class="form-group row">
                        <label class="col-md-4 col-form-label" for="text-input">Harga Satuan</label>
                        <div class="col-md-7">
                            <input currencyMask [options]="{ prefix: '', thousands: '.', decimal: ',' ,precision: '0',align: 'left' }" type="text" [(ngModel)]="susutModel.unitPrice" (ngModelChange)="countTotal()" class="form-control">
                        </div>
                    </div> 
                    <div class="form-group row">
                        <label class="col-md-4 col-form-label" for="text-input">Total</label>
                        <div class="col-md-7">
                            <input disabled type="text" value="{{susutModel.totalSusut | number}}" class="form-control">
                        </div>
                    </div> 
                </div> 
                <div class="col-md-6"> 
                    <div class="form-group row">
                        <label class="col-md-4 col-form-label" for="text-input">Tanggung Perusahaan</label>
                        <div class="col-md-7">
                            <input currencyMask [options]="{ prefix: '', thousands: '.', decimal: ',' ,precision: '0',align: 'left' }" type="text" [(ngModel)]="susutModel.companyPaid" (ngModelChange)="countTotalDriverDebt()" class="form-control">
                        </div>
                    </div> 
                    <div class="form-group row">
                        <label class="col-md-4 col-form-label" for="text-input">Bayar Trip</label>
                        <div class="col-md-7">
                            <input disabled type="text" currencyMask [options]="{ prefix: '', thousands: '.', decimal: ',' ,precision: '0',align: 'left' }" [(ngModel)]="susutModel.bayarTrip" class="form-control">
                        </div>
                    </div> 
                    <div class="form-group row">
                        <label class="col-md-4 col-form-label" for="text-input"></label>
                        <div class="col-md-7">
                        <textarea [(ngModel)]="susutModel.potonganTripText" rows="3" type="text" class="form-control"></textarea>
                        </div>
                    </div> 
                    <div class="form-group row">
                        <label class="col-md-4 col-form-label" for="text-input">Tanggal Surat</label>
                        <div class="col-md-7">
                            <input  type="date" [ngModel]="susutModel.debDate| date:'yyyy-MM-dd'" (ngModelChange)="susutModel.debDate = $event"  class="form-control">
                        </div>
                    </div> 
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" (click)="addDataSusut()" class="btn btn-primary"><i class="fa fa-print"></i>&nbsp;<b>Save</b>&nbsp;</button>
          <button type="button" #ModalSimpanHide class="btn btn-secondary" (click)="ModalSusut.hide()">&nbsp;<b>Cancel</b>&nbsp;</button> 
      </div>
      </div>
    </div>
  </div>

  <div bsModal #ModalCancel="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog" style="max-width: 50%" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title">
          </div>
          <button #ModalCancelClose type="button" class="close" (click)="ModalCancel.hide()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-12"> 
                    <div class="form-group row">
                        <label class="col-md-4 col-form-label" for="text-input">Alasan Cancel</label>
                        <div class="col-md-7">
                            <input type="text" [(ngModel)]="reasonCancel" class="form-control">
                        </div>
                    </div> 
                </div> 
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" (click)="cancelSusut()" class="btn btn-primary"><i class="fas fa-save"></i>&nbsp;<b>Simpan</b>&nbsp;</button> 
      </div>
      </div>
    </div>
  </div>


  <div bsModal #ModalSignature="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog" style="max-width: 50%" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title">
          </div>
          <button type="button" class="close" (click)="ModalSignature.hide()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-12"> 
                    <div class="form-group row">
                        <label class="col-md-4 col-form-label" for="text-input">Disetujui</label>
                        <div class="col-md-8">
                          <select  [(ngModel)]="printModel.disetujui" class="form-control">
                            <option *ngFor="let row of dataSignature" [value]='row.name'><b>{{row.name}}</b></option>
                         </select>   
                        </div>
                    </div> 
                    <div class="form-group row">
                      <label class="col-md-4 col-form-label" for="text-input">Kepala Cabang</label>
                      <div class="col-md-8">
                        <select  [(ngModel)]="printModel.diketahui" class="form-control">
                          <option *ngFor="let row of dataSignature" [value]='row.name'><b>{{row.name}}</b></option>
                       </select>   
                      </div>
                  </div> 
                  
                      <div class="form-group row">
                            <label class="col-md-4 col-form-label" for="text-input">Operational</label>
                            <div class="col-md-5">
                              <select  [(ngModel)]="printModel.diketahui2" class="form-control">
                                <option *ngFor="let row of dataSignature" [value]='row.name'><b>{{row.name}}</b></option>
                             </select>   
                            </div>
                            <div class="col-sm-1">
                              <input [(ngModel)]="printModel.hide_diketahui2" style="width: 40%;" type="checkbox" class="form-control">
                            </div>
                            <label class="col-sm-2 col-form-label" for="text-input">Sembunyikan</label>
                      </div> 
                      <div class="form-group row">
                        <label class="col-md-4 col-form-label" for="text-input">Admin DO/Store</label>
                        <div class="col-md-5">
                          <select  [(ngModel)]="printModel.diketahui3" class="form-control">
                            <option *ngFor="let row of dataSignature" [value]='row.name'><b>{{row.name}}</b></option>
                         </select>   
                        </div>
                        <div class="col-sm-1">
                          <input [(ngModel)]="printModel.hide_diketahui3" style="width: 40%;" type="checkbox" class="form-control">
                        </div>
                        <label class="col-sm-2 col-form-label" for="text-input">Sembunyikan</label>
                     </div> 
                     <div class="form-group row">
                        <label class="col-md-4 col-form-label" for="text-input">HRGA</label>
                        <div class="col-md-5">
                          <select  [(ngModel)]="printModel.diketahui4" class="form-control">
                            <option *ngFor="let row of dataSignature" [value]='row.name'><b>{{row.name}}</b></option>
                         </select>   
                        </div>
                        <div class="col-sm-1">
                          <input [(ngModel)]="printModel.hide_diketahui4" style="width: 40%;" type="checkbox" class="form-control">
                        </div>
                        <label class="col-sm-2 col-form-label" for="text-input">Sembunyikan</label>
                     </div> 
                </div> 
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button (click)="Print()" type="button" class="btn btn-primary"><i class="fas fa-print"></i>&nbsp;&nbsp;&nbsp;<b>Cetak</b>&nbsp;&nbsp;&nbsp;</button> 
      </div>
      </div>
    </div>
  </div>


  <div bsModal #ModalPrint="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog" style="max-width: 50%;" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title">
          </div>
          <button type="button" class="close" (click)="ModalPrint.hide()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-12"> 
                    <div class="form-group row">
                        <label class="col-md-3 col-form-label" for="text-input">Dari Tanggal</label>
                        <div class="col-md-8">
                            <input type="date" [(ngModel)]="exportModel.from" class="form-control">
                        </div>
                    </div> 
                    <div class="form-group row">
                      <label class="col-md-3 col-form-label" for="text-input">Ke Tanggal</label>
                      <div class="col-md-8">
                          <input  type="date" [(ngModel)]="exportModel.to" class="form-control">
                      </div>
                    </div> 
                </div> 
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" (click)="exportDataSusut()" class="btn btn-primary"><i class="fa fa-file-excel"></i>&nbsp;<b>Export</b>&nbsp;</button>
          <button type="button" #modalPrint class="btn btn-secondary" (click)="ModalPrint.hide()">&nbsp;<b>Cancel</b>&nbsp;</button> 
      </div>
      </div>
    </div>
  </div>

<button #ModalSignatureShow hidden (click)="ModalSignature.show()"></button>

<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>