import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { DataTableDirective } from 'angular-datatables'
import { TransportService} from '../../../services/api.service';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { DriverShrinkageRecapVm } from '@/model/driverShrinkageRecapVm';
import { saveAs } from 'file-saver';
import { JournalInvoice, JournalInvoiceExport, JournalInvoiceOnly, JournalInvoiceOnlyExport } from '@/model/journalInvoice';
import { AppService } from '@services/app.service';
import {formatDate } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-journal-travelcost',
  templateUrl: './journal-travelcost.component.html',
  styleUrls: ['./journal-travelcost.component.scss']
})
export class JournalTravelcostComponent  implements OnInit, AfterViewInit {
  datatabdtOptionsnt: DataTableDirective;
  dtOptionsJournal: any = {};
  dtOptionsJournalInvOnly: any = {};
  dataListJournal: JournalInvoice[] = [];
  dataListJournalInvOnly: JournalInvoiceOnly[] = [];
  dataExportListJournal: JournalInvoiceExport[] = [];
  dataExportListJournalInvoiceOnly: JournalInvoiceOnlyExport[] = [];
  dtTriggerJournal = new Subject();  
  dtTriggerJournalInvOnly = new Subject();  
  @Input() inputModel = {  from: null, to: null, month: null, year: null, start_counter: null, tipe: null}
  @Input() inputSave = {  list: new Array()}
  public dt: any;
  public cb: any;
  public from : Date;
  public to : Date;
  amountDebt: number;
  invOnly: Boolean = false;
  constructor( public service: TransportService, private router: Router,  private spinner: NgxSpinnerService, private appServ: AppService) {
  }

    ngOnInit(): void {

      this.dtOptionsJournal = {  
        retrieve: true,
        pageLength: 10,
        ordering : false,
        searching : false,
        columnDefs: [
          { width: 100, targets: [0,1,2,4,5,6,7,8,9,10]},
          { width: 500, targets: [3]}
        ],
      };

      this.dtOptionsJournalInvOnly = {  
        retrieve: true,
        pageLength: 10,
        ordering : false,
        searching : false,
        columnDefs: [
          { width: 100, targets: [0,1,2,4,5,6,7,8,9,10,11,12]},
          { width: 500, targets: [3]}
        ],
      };

    }   

    setTableInv(){
      this.invOnly = true;
    }

    setTable(){
      this.invOnly = false;
    }

    generateInvoice(){
      if (this.inputModel.from == null || this.inputModel.to == null ||this.inputModel.month == null ||
        this.inputModel.year == null ||this.inputModel.start_counter == null || this.inputModel.tipe == ""
         || this.inputModel.tipe == null){
          return Swal.fire({
            title: "Data Belum Lengkap!",
            type: 'warning',
            confirmButtonText: '      Ok!     '
          });
        }
        if(this.inputModel.tipe == "InvoiceOnly"){
          this.dataListJournalInvOnly = [];
          this.spinner.show();
          this.service.generateJournalTravelCostInvOnly(this.inputModel)
          .subscribe(resp => { 
            this.spinner.hide();
            if(resp.succes == false){
              Swal.fire({
                title: ""+resp.text,
                type: 'warning',
                confirmButtonText: '      Ok!     '
              });
            }
              this.dataListJournalInvOnly = resp.list;
          });
        }
        else{
          
        this.dataListJournal = [];
        this.spinner.show();
        this.service.generateJournalTravelCost(this.inputModel)
        .subscribe(resp => { 
          this.spinner.hide();
          if(resp.succes == false){
            Swal.fire({
              title: ""+resp.text,
              type: 'warning',
              confirmButtonText: '      Ok!     '
            });
          }
            this.dataListJournal = resp.list;
        });
        }
     }


    ngAfterViewInit(): void {
      this.dtTriggerJournal.next(this.dtOptionsJournal);
      this.dtTriggerJournal.next(this.dtOptionsJournalInvOnly);
    }
  
    ngOnDestroy(): void {
    }


  messageWarning(text: string){
    Swal.fire({
      title: ""+text,
      type: 'warning',
      confirmButtonText: '      Ok!     '
    });
    return;
  }
  
  exportExcel(){
    if(this.inputModel.tipe == "InvoiceOnly"){
      if(this.dataListJournalInvOnly == null){
        return Swal.fire({
          title: "Tabel generate Journal Kosong!",
          type: 'warning',
          confirmButtonText: '      Ok!     '
        });
      }
      if(this.dataListJournalInvOnly.length == 0){
        return Swal.fire({
          title: "Tabel generate Journal Kosong!",
          type: 'warning',
          confirmButtonText: '      Ok!     '
        });
      }
      this.dataListJournalInvOnly.forEach(x => {
        this.dataExportListJournalInvoiceOnly.push({
          Journal_Invoice_No : x.journalInvoiceNo,
          PRJ : x.prj,
          Date : formatDate(x.date, 'yyyy-MM-dd', 'en-US'),
          Invoice_No : x.invoiceNo,
          Invoice_Date : formatDate(x.invoiceDate, 'yyyy-MM-dd', 'en-US'),
          Remark : x.remark,
          CCY : x.ccy,
          Rate : x.rate,
          Credit : x.credit,
          Debit : x.debit,
          GL_Code : x.glCode,
          Truck_No : x.truckNo,
          SL_Code : x.slCode,
        })
      });
      this.appServ.exportAsExcelFile(this.dataExportListJournalInvoiceOnly, 'Journal_Uang_Jalan');
    }
    else{
      if(this.dataListJournal == null){
        return Swal.fire({
          title: "Tabel generate Journal Kosong!",
          type: 'warning',
          confirmButtonText: '      Ok!     '
        });
      }
      if(this.dataListJournal.length == 0){
        return Swal.fire({
          title: "Tabel generate Journal Kosong!",
          type: 'warning',
          confirmButtonText: '      Ok!     '
        });
      }
      this.dataListJournal.forEach(x => {
        this.dataExportListJournal.push({
          Journal_Invoice_No : x.journalInvoiceNo,
          PRJ : x.prj,
          Date : formatDate(x.date, 'yyyy-MM-dd', 'en-US'),
          Remark : x.remark,
          CCY : x.ccy,
          Rate : x.rate,
          Credit : x.credit,
          Debit : x.debit,
          GL_Code : x.glCode,
          Truck_No : x.truckNo,
          SL_Code : x.slCode,
        })
      });
      this.appServ.exportAsExcelFile(this.dataExportListJournal, 'Journal_Uang_Jalan');
    }
   
  }


  saveInvoice(){
    if(this.dataListJournal == null){
      return Swal.fire({
        title: "Tabel generate Journal Kosong!",
        type: 'warning',
        confirmButtonText: '      Ok!     '
      });
    }
    if(this.dataListJournal.length == 0){
      return Swal.fire({
        title: "Tabel generate Journal Kosong!",
        type: 'warning',
        confirmButtonText: '      Ok!     '
      });
    }
    this.inputSave.list = [];
    Swal.fire({
      title: 'Simpan Data?',
      // text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '      Yes     '
    }).then((result) => {
      this.dataListJournal.forEach(x => {
        this.inputSave.list.push({
          type : x.type,
          transactionId : x.transactionId,
          transactionCostId : x.transactionCostId,
          journalInvoiceNo : x.journalInvoiceNo,
          prj : x.prj,
          date : x.date,
          remark : x.remark,
          ccy : x.ccy,
          rate : x.rate,
          credit : x.credit,
          debit : x.debit,
          glCode : x.glCode,
          truckNo : x.truckNo,
          slCode : x.slCode,
          etc : x.etc,
          refNo : x.refNo,
          total : x.total,
          percentage : x.percentage
        })
      });
        if (result.value) {
          this.spinner.show();
          this.service.saveJournalInvoice(this.inputSave).subscribe( resp => {
            this.spinner.hide();
            if(resp.succes == false){
              Swal.fire({
                title: ""+resp.text,
                type: 'warning',
                confirmButtonText: '      Ok!     '
              });
            }
            else{
              Swal.fire({
                title: ""+resp.text,
                type: 'success',
                confirmButtonText: '      Ok!     '
              }).then((result) => {
                this.dataListJournal = [];
                if (result.value) { 
                }              
              }, (error) => {
                ; 
              })
            }
            this.exportExcel();
          })
        }
    });
  }

  

  
}





