<section class="content">    
    <div class="card">
        <div class="card-header">
          <strong>List Closing Pembayaran Uang Jalan</strong>
          <div class="float-right">
          <a type="button" (click)="ModalSimpan.show();setNull();" class="btn btn-sm btn-primary pull-right" data-toggle="modal">&nbsp;<b>Tambah Account Closing</b>&nbsp;&nbsp;<i class="fa fa-pen"></i>&nbsp;</a>
          </div>
        </div>
        <div class="card-body">
            <table datatable [dtOptions]="dtOptionsAcc" [dtTrigger]="dtTriggerAcc" class="table-striped">
                <thead>
                  <tr>
                    <th>Action</th>
                    <th>Tipe Closing</th>
                    <th>Dari Tanggal</th>
                    <th>Sampai Tanggal</th>
                    <th>Dibuat Oleh</th>
                    <th>Dibuat Pada</th>
                    <th>Diubah Oleh</th>
                    <th>Diubah Pada</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of dataListAcc">    
                    <td>
                      <button (click)="onSelectDataAcc(row);ModalSimpan.show()" title="Detail" class="btn btn-sm btn-primary" triggers="mouseenter:mouseleave"><i class="fa fa-pen"></i></button>&nbsp;
                      <button (click)="deleteData(row)" class="btn btn-sm btn-danger" title="Delete" triggers="mouseenter:mouseleave"><i class="fa fa-trash"></i></button>&nbsp;
                    </td>       
                    <td>{{row.closing_type_text}}</td>
                    <td>{{row.date_from | slice:0:10}}</td>
                    <td>{{row.date_to | slice:0:10}}</td>
                    <td>{{row.created_by_text}}</td>
                    <td>{{row.created_at | slice:0:10}}</td>
                    <td>{{row.updated_by_text}}</td>
                    <td>{{row.updated_at | slice:0:10}}</td>
                    <td>{{row.status_text}}</td>
                </tr>
                </tbody>
                <tfoot>
                    <tr>
                      <th><input hidden class="searchInput"/></th>
                      <th><input hidden id="type" type="text" class="searchInput" placeholder="Tipe Closing"/></th>
                      <th><input hidden class="searchInput"/></th>
                      <th><input hidden class="searchInput"/></th>
                      <th><input hidden class="searchInput"/></th>
                      <th><input hidden class="searchInput"/></th>
                      <th><input hidden class="searchInput"/></th>
                      <th><input hidden class="searchInput"/></th>
                      <th><input hidden id="status" type="text" class="searchInput" placeholder="Status"/></th>
                    </tr>
                </tfoot>
              </table>
        </div>
    </div>
  </section>

  <div  bsModal #ModalSimpan="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog" style="max-width: 50%;" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title">
            <div class="row">
              <strong>Potongan Pembayaran</strong>
            </div>
          </div>
          <button type="button" class="close" (click)="ModalSimpan.hide()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-12"> 
                    <div class="form-group row group">
                        <label class="col-md-3 col-form-label" for="text-input">Tipe Closing</label>
                        <div class="col-md-8">
                            <select [(ngModel)]="accModel.closing_type" id="select" name="select" class="form-control">
                                <option selected *ngFor="let row of listType" [value]='row.id'><b>{{row.text}}</b></option>
                            </select>
                        </div>
                    </div> 
                </div>
                <div class="col-md-12"> 
                    <div class="form-group row">
                        <label class="col-md-3 col-form-label" for="text-input">Tanggal Ke</label>
                        <div class="col-md-8">
                            <input type="date" [ngModel]="accModel.date_from | date:'yyyy-MM-dd'" (ngModelChange)="accModel.date_from = $event" type="date"  class="form-control" >
                        </div>
                    </div> 
                </div>
                <div class="col-md-12"> 
                    <div class="form-group row">
                        <label class="col-md-3 col-form-label" for="text-input">Tanggal Dari</label>
                        <div class="col-md-8">
                            <input type="date" [ngModel]="accModel.date_to | date:'yyyy-MM-dd'" (ngModelChange)="accModel.date_to = $event" type="date"  class="form-control" >
                        </div>
                    </div> 
                </div>
                <div class="col-md-12"> 
                    <div class="form-group row group">
                        <label class="col-md-3 col-form-label" for="text-input">Tipe Status</label>
                        <div class="col-md-8">
                            <select [(ngModel)]="accModel.status" id="select" name="select" class="form-control">
                                <option selected *ngFor="let row of listStatus" [value]='row.id'><b>{{row.text}}</b></option>
                            </select>
                        </div>
                    </div> 
                </div>
            </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button #modalSimpanClose type="button" class="btn btn-secondary" (click)="ModalSimpan.hide()">&nbsp;&nbsp;&nbsp;&nbsp;<b>Close</b>&nbsp;&nbsp;&nbsp;&nbsp;</button>
          <button type="button" class="btn btn-primary" (click)="addDataClosing()">&nbsp;&nbsp;&nbsp;&nbsp;<i class="fa fa-save"></i>&nbsp;&nbsp;<b>Simpan</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</button>
        </div>
      </div>
    </div>
  </div>

  <ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>