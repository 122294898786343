
<section class="content">    
    <div class="card">
        <div class="card-body">
            <div class="card">
                <div class="card-header">
                  <div style="text-align: left">
                    <strong>Kriteria Pencarian</strong>
                    &nbsp;&nbsp;&nbsp;<button type="button" class="btn btn-sm btn-warning" (click)="clear()">&nbsp;<i class="fas fa-broom"></i>&nbsp;&nbsp;&nbsp;<b>Clear</b>&nbsp;&nbsp;&nbsp;</button>
                  </div>
                </div>
                  <div class="card-body">
                      <div class="row">
                          <div class="col-md-3">
                              <div class="form-group row">
                                  <label class="col-md-4 col-form-label" for="text-input">No DO Besar</label>
                                  <div class="col-md-7">
                                      <input type="text" [(ngModel)]="genModel.main_do_no" class="form-control">
                                  </div>
                              </div> 
                              <div class="form-group row">
                                  <label class="col-md-4 col-form-label" for="text-input">No SPK</label>
                                  <div class="col-md-7">
                                      <input type="text" [(ngModel)]="genModel.spk_no" class="form-control">
                                  </div>
                              </div> 
                              <div class="form-group row">
                                  <label class="col-md-4 col-form-label" for="text-input">Tipe DO</label>
                                  <div class="col-md-7">
                                    <select [(ngModel)]="genModel.main_do_type" class="form-control">
                                      <option *ngFor="let row of doType" [value]='row.id'><b>{{row.text}}</b></option>
                                    </select>
                                  </div>
                              </div> 
                              <div class="form-group row">
                                  <label class="col-md-4 col-form-label" for="text-input">Tipe Biaya</label>
                                  <div class="col-md-7">
                                    <select [(ngModel)]="genModel.charge_type" class="form-control">
                                      <option *ngFor="let row of chargeType" [value]='row.id'><b>{{row.text}}</b></option>
                                    </select>
                                  </div>
                              </div> 
                              <div class="form-group row">
                                  <label class="col-md-4 col-form-label" for="text-input">Status DO Kecil</label>
                                  <div class="col-md-7">
                                    <select [(ngModel)]="genModel.do_detail_status" class="form-control">
                                      <option *ngFor="let row of statusType" [value]='row.id'><b>{{row.text}}</b></option>
                                    </select>
                                  </div>
                              </div> 
                              <div class="form-group row">
                                  <div class="col-md-1">
                                    <input [(ngModel)]="genModel.driver_active"  type="checkbox" class="form-control">
                                  </div>
                                  <label class="col-md-4 col-form-label" for="text-input">Supir Aktif</label>
                                  <div class="col-md-1">
                                    <input [(ngModel)]="genModel.driver_inactive" type="checkbox" class="form-control">
                                  </div>
                                  <label class="col-md-4 col-form-label" for="text-input">Supir Tidak Aktif</label>
                              </div> 
                          </div>
                          
                          <div class="col-md-4">
                              <div class="form-group row">
                                  <label class="col-md-4 col-form-label" for="text-input">Rute</label>
                                  <div class="col-md-6">
                                      <input disabled type="text" [(ngModel)]="routeName" class="form-control" >
                                  </div>
                                  <div class="col-md-2">
                                    <button class="btn btn-sm btn-primary" (click)="ModalRoute.show();showRoute()"
                                    data-toggle="modal"><i class="fa fa-plus"></i></button>
                                  </div>
                              </div>  
                              <div class="form-group row">
                                  <label class="col-md-4 col-form-label" for="text-input">Pelanggan</label>
                                  <div class="col-md-6">
                                      <input disabled type="text" [(ngModel)]="customerName" class="form-control" >
                                  </div>
                                  <div class="col-md-2">
                                    <button class="btn btn-sm btn-primary" (click)="ModalCustomer.show();showCustomer()"
                                    data-toggle="modal"><i class="fa fa-plus"></i></button>
                                  </div>
                              </div>  
                              <div class="form-group row">
                                  <label class="col-md-4 col-form-label" for="text-input">Penjual</label>
                                  <div class="col-md-6">
                                      <input disabled type="text" [(ngModel)]="sellerName" class="form-control" >
                                  </div>
                                  <div class="col-md-2">
                                    <button class="btn btn-sm btn-primary" (click)="ModalSupplier.show();showSupplier()"
                                    data-toggle="modal"><i class="fa fa-plus"></i></button>
                                  </div>
                              </div>  
                              <div class="form-group row">
                                  <label class="col-md-4 col-form-label" for="text-input">Material</label>
                                  <div class="col-md-6">
                                      <input disabled type="text" [(ngModel)]="materialName" class="form-control" >
                                  </div>
                                  <div class="col-md-2">
                                    <button class="btn btn-sm btn-primary" (click)="ModalMaterial.show();showMaterial()"
                                    data-toggle="modal"><i class="fa fa-plus"></i></button>
                                  </div>
                              </div>  
                              <div class="form-group row">
                                  <label class="col-md-4 col-form-label" for="text-input">Supir</label>
                                  <div class="col-md-6">
                                      <input disabled type="text" [(ngModel)]="driverName" class="form-control" >
                                  </div>
                                  <div class="col-md-2">
                                    <button class="btn btn-sm btn-primary" (click)="ModalDriver.show();showDriver()"
                                    data-toggle="modal"><i class="fa fa-plus"></i></button>
                                  </div>
                              </div> 
                          </div>
                          <div class="col-md-5">
                              <div class="form-group row">
                                  <label class="col-md-3 col-form-label" for="text-input">Tanggal DO Besar</label>
                                  <div class="col-md-4">
                                       <input type="date" [ngModel]="genModel.main_do_date_from | date:'yyyy-MM-dd'" (ngModelChange)="genModel.main_do_date_from = $event" class="form-control" >
                                  </div>
                                  <label class="col-md-1 col-form-label" for="text-input">&nbsp;&nbsp;&nbsp;-</label>
                                  <div class="col-md-4">
                                    <input type="date" [ngModel]="genModel.main_do_date_to | date:'yyyy-MM-dd'" (ngModelChange)="genModel.main_do_date_to = $event" class="form-control" >
                                  </div>
                              </div> 
                              <div class="form-group row">
                                  <label class="col-md-3 col-form-label" for="text-input">Tanggal Muat</label>
                                  <div class="col-md-4">
                                       <input type="date" [ngModel]="genModel.muat_from | date:'yyyy-MM-dd'" (ngModelChange)="genModel.muat_from = $event" class="form-control" >
                                  </div>
                                  <label class="col-md-1 col-form-label" for="text-input">&nbsp;&nbsp;&nbsp;-</label>
                                  <div class="col-md-4">
                                    <input type="date" [ngModel]="genModel.muat_to | date:'yyyy-MM-dd'" (ngModelChange)="genModel.muat_to = $event" class="form-control" >
                                  </div>
                              </div> 
                              <div class="form-group row">
                                  <label class="col-md-3 col-form-label" for="text-input">Tanggal Bongkar</label>
                                  <div class="col-md-4">
                                       <input type="date" [ngModel]="genModel.bongkar_from | date:'yyyy-MM-dd'" (ngModelChange)="genModel.bongkar_from = $event" class="form-control" >
                                  </div>
                                  <label class="col-md-1 col-form-label" for="text-input">&nbsp;&nbsp;&nbsp;-</label>
                                  <div class="col-md-4">
                                    <input type="date" [ngModel]="genModel.bongkar_to | date:'yyyy-MM-dd'" (ngModelChange)="genModel.bongkar_to = $event" class="form-control" >
                                  </div>
                              </div> 
                              <div class="form-group row">
                                  <label class="col-md-3 col-form-label" for="text-input">Tanggal Realisasi</label>
                                  <div class="col-md-4">
                                      <input [ngModel]="genModel.realization_from | date:'yyyy-MM-dd'" type="date" class="form-control" (ngModelChange)="genModel.realization_from = $event" >
                                  </div>
                                  <label class="col-md-1 col-form-label" for="text-input">&nbsp;&nbsp;&nbsp;-</label>
                                  <div class="col-md-4">
                                    <input type="date" [ngModel]="genModel.realization_to | date:'yyyy-MM-dd'" (ngModelChange)="genModel.realization_to = $event" class="form-control" >
                                  </div>
                              </div> 
                              <div class="form-group row">
                                  <label class="col-md-3 col-form-label" for="text-input">Tanggal Bagi DO</label>
                                  <div class="col-md-4">
                                       <input type="date" [ngModel]="genModel.depart_from | date:'yyyy-MM-dd'" (ngModelChange)="genModel.depart_from = $event" class="form-control" >
                                  </div>
                                  <label class="col-md-1 col-form-label" for="text-input">&nbsp;&nbsp;&nbsp;-</label>
                                  <div class="col-md-4">
                                    <input type="date" [ngModel]="genModel.depart_to | date:'yyyy-MM-dd'" (ngModelChange)="genModel.depart_to = $event" class="form-control" >
                                  </div>
                              </div> 
                              <div class="form-group row">
                                  <label class="col-md-3 col-form-label" for="text-input">Tanggal Pembayaran</label>
                                  <div class="col-md-4">
                                       <input type="date" [ngModel]="genModel.payment_from | date:'yyyy-MM-dd'" (ngModelChange)="genModel.payment_from = $event" class="form-control" >
                                  </div>
                                  <label class="col-md-1 col-form-label" for="text-input">&nbsp;&nbsp;&nbsp;-</label>
                                  <div class="col-md-4">
                                    <input type="date" [ngModel]="genModel.payment_to | date:'yyyy-MM-dd'" (ngModelChange)="genModel.payment_to = $event" class="form-control" >
                                  </div>
                              </div> 
                              <div class="form-group row">
                                <label class="col-md-3 col-form-label" for="text-input">Tanggal Invoice</label>
                                <div class="col-md-4">
                                     <input type="date" [ngModel]="genModel.invoice_date_from | date:'yyyy-MM-dd'" (ngModelChange)="genModel.invoice_date_from = $event" class="form-control" >
                                </div>
                                <label class="col-md-1 col-form-label" for="text-input">&nbsp;&nbsp;&nbsp;-</label>
                                <div class="col-md-4">
                                  <input type="date" [ngModel]="genModel.invoice_date_to | date:'yyyy-MM-dd'" (ngModelChange)="genModel.invoice_date_to = $event" class="form-control" >
                                </div>
                            </div> 
                          </div>
                      </div> 
                  </div>
                  <div class="card-footer">
                    <div class="card-body">
                        <!-- <div style="text-align: center">
                              <button type="button" class="btn btn-sm btn-primary" >&nbsp;<i class="fa fa-trash"></i>&nbsp;&nbsp;&nbsp;&nbsp;<b>Clear</b>&nbsp;&nbsp;&nbsp;&nbsp;</button>
                        </div> -->
                        <div style="text-align: right">
                            <button (click)="printGeneralReport()" type="button" class="btn btn-lg btn-primary" >&nbsp;<i class="fas fa-file-excel"></i>&nbsp;&nbsp;&nbsp;&nbsp;<b>Export Data</b>&nbsp;&nbsp;&nbsp;&nbsp;</button>
                      </div>
                    </div>
                  </div>
                </div>
                      <br>
                      <div class="card">
                        <div class="card-header">
                            <div style="text-align: left">
                              <strong>Opsi Tampilan Kolom</strong>
                              &nbsp;&nbsp;&nbsp;<button (click)="checkAll()" type="button" class="btn btn-sm btn-warning" >&nbsp;<i class="fa fa-check"></i>&nbsp;<b>Check/Uncheck All</b>&nbsp;</button>
                              &nbsp;&nbsp;<button (click)="setDefault()" type="button" class="btn btn-sm btn-warning" >&nbsp;<i class="fa fa-check"></i>&nbsp;&nbsp;&nbsp;&nbsp;<b>Set Default</b>&nbsp;&nbsp;&nbsp;&nbsp;</button>
                            </div>
                        </div>
                        <div class="card-body">       
                            <div class="row">
                                <div class="col-md-2">
                                    <div class="form-group row">
                                        <div class="col-md-2">
                                          <input type="checkbox" [(ngModel)]="checkboxAll.MainDoNo"  (change)="onChange('No_DO', $event)" class="form-control">
                                        </div>
                                        <label class="col-md-8 col-form-label" for="text-input">No DO Besar</label>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-md-2">
                                          <input type="checkbox" [(ngModel)]="checkboxAll.SPKNo"  (change)="onChange('No_SPK', $event)"class="form-control">
                                        </div>
                                        <label class="col-md-8 col-form-label" for="text-input">No SPK</label>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-md-2">
                                          <input type="checkbox" [(ngModel)]="checkboxAll.ContractNo"  (change)="onChange('No_Contract', $event)"class="form-control">
                                        </div>
                                        <label class="col-md-8 col-form-label" for="text-input">No Contract</label>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-md-2">
                                          <input type="checkbox" [(ngModel)]="checkboxAll.MainDoDate"  (change)="onChange('Tanggal_DO', $event)"class="form-control">
                                        </div>
                                        <label class="col-md-8 col-form-label" for="text-input">Tanggal DO</label>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-md-2">
                                          <input type="checkbox" [(ngModel)]="checkboxAll.DoType"  (change)="onChange('Tipe_DO', $event)"class="form-control">
                                        </div>
                                        <label class="col-md-8 col-form-label" for="text-input">Tipe DO</label>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-md-2">
                                          <input type="checkbox" [(ngModel)]="checkboxAll.ChargeType"  (change)="onChange('Tipe_Biaya', $event)"class="form-control">
                                        </div>
                                        <label class="col-md-8 col-form-label" for="text-input">Tipe Biaya</label>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-md-2">
                                          <input type="checkbox" [(ngModel)]="checkboxAll.Route"  (change)="onChange('Route', $event)"class="form-control">
                                        </div>
                                        <label class="col-md-8 col-form-label" for="text-input">Rute</label>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-md-2">
                                          <input type="checkbox" [(ngModel)]="checkboxAll.Customer"  (change)="onChange('Pelanggan', $event)"class="form-control">
                                        </div>
                                        <label class="col-md-8 col-form-label" for="text-input">Pelanggan</label>
                                    </div>
                                    <div class="form-group row">
                                      <div class="col-md-2">
                                        <input type="checkbox" [(ngModel)]="checkboxAll.capacity"  (change)="onChange('Kapasitas', $event)"class="form-control">
                                      </div>
                                      <label class="col-md-8 col-form-label" for="text-input">Kapasitas</label>
                                  </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="form-group row">
                                        <div class="col-md-2">
                                          <input type="checkbox" [(ngModel)]="checkboxAll.Seller"  (change)="onChange('Penjual', $event)"class="form-control">
                                        </div>
                                        <label class="col-md-8 col-form-label" for="text-input">Penjual</label>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-md-2">
                                          <input type="checkbox" [(ngModel)]="checkboxAll.Material"  (change)="onChange('Material', $event)"class="form-control">
                                        </div>
                                        <label class="col-md-8 col-form-label" for="text-input">Material</label>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-md-2">
                                          <input type="checkbox" [(ngModel)]="checkboxAll.Ekspedisi"  (change)="onChange('Ekspedisi', $event)"class="form-control">
                                        </div>
                                        <label class="col-md-8 col-form-label" for="text-input">Ekspedisi</label>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-md-2">
                                          <input type="checkbox" [(ngModel)]="checkboxAll.RealDate"  (change)="onChange('Tanggal_Realisasi', $event)"class="form-control">
                                        </div>
                                        <label class="col-md-8 col-form-label" for="text-input">Tanggal Realisasi</label>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-md-2">
                                          <input type="checkbox" [(ngModel)]="checkboxAll.TruckNo"  (change)="onChange('No_Truk', $event)"class="form-control">
                                        </div>
                                        <label class="col-md-8 col-form-label" for="text-input">No Polisi</label>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-md-2">
                                          <input type="checkbox" [(ngModel)]="checkboxAll.PaymentNo"  (change)="onChange('No_Pembayaran', $event)"class="form-control">
                                        </div>
                                        <label class="col-md-8 col-form-label" for="text-input">No Pembayaran</label>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-md-2">
                                          <input type="checkbox" [(ngModel)]="checkboxAll.TravelCostBongkar"  (change)="onChange('Biaya_Bongkar', $event)"class="form-control">
                                        </div>
                                        <label class="col-md-8 col-form-label" for="text-input">Biaya Bongkar</label>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-md-2">
                                          <input type="checkbox" [(ngModel)]="checkboxAll.EstTon"  (change)="onChange('Est_Berat', $event)"class="form-control">
                                        </div>
                                        <label class="col-md-8 col-form-label" for="text-input">Est.Berat</label>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="form-group row">
                                        <div class="col-md-2">
                                          <input type="checkbox" [(ngModel)]="checkboxAll.DetailDoNo"  (change)="onChange('DO_Kecil', $event)"class="form-control">
                                        </div>
                                        <label class="col-md-10 col-form-label" for="text-input">No DO Kecil</label>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-md-2">
                                          <input type="checkbox" [(ngModel)]="checkboxAll.DetailDoYear"  (change)="onChange('Tahun_DO', $event)"class="form-control">
                                        </div>
                                        <label class="col-md-8 col-form-label" for="text-input">Tahun Do Kecil</label>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-md-2">
                                          <input type="checkbox" [(ngModel)]="checkboxAll.DetailDoStatus"  (change)="onChange('Status_DO', $event)"class="form-control">
                                        </div>
                                        <label class="col-md-8 col-form-label" for="text-input">Status DO Kecil</label>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-md-2">
                                          <input type="checkbox" [(ngModel)]="checkboxAll.DetailDoDeliverDate"  (change)="onChange('Tanggal_Selesai', $event)"class="form-control">
                                        </div>
                                        <label class="col-md-8 col-form-label" for="text-input">Tanggal Deliver</label>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-md-2">
                                          <input type="checkbox" [(ngModel)]="checkboxAll.ReffRealization"  (change)="onChange('Reff', $event)"class="form-control">
                                        </div>
                                        <label class="col-md-8 col-form-label" for="text-input">Reff Realisasi</label>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-md-2">
                                          <input type="checkbox" [(ngModel)]="checkboxAll.WBReff"  (change)="onChange('WB_Reff', $event)"class="form-control">
                                        </div>
                                        <label class="col-md-8 col-form-label" for="text-input">Reff WB</label>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-md-2">
                                          <input type="checkbox" [(ngModel)]="checkboxAll.SendTonnage"  (change)="onChange('Tonnase_Kirim', $event)"class="form-control">
                                        </div>
                                        <label class="col-md-8 col-form-label" for="text-input">Tonnase Kirim</label>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-md-2">
                                          <input type="checkbox" [(ngModel)]="checkboxAll.ReceivedTonnage"  (change)="onChange('Tonnase_Terima', $event)"class="form-control">
                                        </div>
                                        <label class="col-md-8 col-form-label" for="text-input">Tonnase Terima</label>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="form-group row">
                                        <div class="col-md-2">
                                          <input type="checkbox" [(ngModel)]="checkboxAll.Remark"  (change)="onChange('Keterangan', $event)"class="form-control">
                                        </div>
                                        <label class="col-md-10 col-form-label" for="text-input">Keterangan</label>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-md-2">
                                          <input type="checkbox" [(ngModel)]="checkboxAll.Driver"  (change)="onChange('Supir', $event)"class="form-control">
                                        </div>
                                        <label class="col-md-8 col-form-label" for="text-input">Supir</label>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-md-2">
                                          <input type="checkbox" [(ngModel)]="checkboxAll.TravelCostGaji"  (change)="onChange('Biaya_Gaji', $event)"class="form-control">
                                        </div>
                                        <label class="col-md-8 col-form-label" for="text-input">Uang Gaji</label>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-md-2">
                                          <input type="checkbox" [(ngModel)]="checkboxAll.TravelCostBBM"  (change)="onChange('Biaya_BBM', $event)"class="form-control">
                                        </div>
                                        <label class="col-md-8 col-form-label" for="text-input">Uang BBM</label>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-md-2">
                                          <input type="checkbox" [(ngModel)]="checkboxAll.MainSPONo"  (change)="onChange('No_SPO', $event)"class="form-control">
                                        </div>
                                        <label class="col-md-8 col-form-label" for="text-input">No SPO</label>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-md-2">
                                          <input type="checkbox" [(ngModel)]="checkboxAll.SPKKebun"  (change)="onChange('Kebun', $event)"class="form-control">
                                        </div>
                                        <label class="col-md-8 col-form-label" for="text-input">SPK Kebun</label>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-md-2">
                                          <input type="checkbox" [(ngModel)]="checkboxAll.EstPack"  (change)="onChange('Est_Kemasan', $event)"class="form-control">
                                        </div>
                                        <label class="col-md-8 col-form-label" for="text-input">Est. Kemasan</label>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-md-2">
                                          <input type="checkbox" [(ngModel)]="checkboxAll.Oa"  (change)="onChange('OA_Ekspedisi', $event)"class="form-control">
                                        </div>
                                        <label class="col-md-8 col-form-label" for="text-input">OA</label>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="form-group row">
                                        <div class="col-md-2">
                                          <input type="checkbox" [(ngModel)]="checkboxAll.DepartDate"  (change)="onChange('Tanggal_Bagi_DO', $event)"class="form-control">
                                        </div>
                                        <label class="col-md-8 col-form-label" for="text-input">Tanggal Bagi DO</label>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-md-2">
                                          <input type="checkbox" [(ngModel)]="checkboxAll.DriverLicense"  (change)="onChange('SIM', $event)"class="form-control">
                                        </div>
                                        <label class="col-md-8 col-form-label" for="text-input">SIM Supir</label>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-md-2">
                                          <input type="checkbox" [(ngModel)]="checkboxAll.AgamaSupir"  (change)="onChange('Agama', $event)"class="form-control">
                                        </div>
                                        <label class="col-md-8 col-form-label" for="text-input">Agama Supir</label>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-md-2">
                                          <input type="checkbox" [(ngModel)]="checkboxAll.PaymentDate"  (change)="onChange('Tanggal_Bayar', $event)"class="form-control">
                                        </div>
                                        <label class="col-md-8 col-form-label" for="text-input">Tanggal Pembayaran</label>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-md-2">
                                          <input type="checkbox" [(ngModel)]="checkboxAll.SendDate"  (change)="onChange('Tanggal_Muat', $event)"class="form-control">
                                        </div>
                                        <label class="col-md-8 col-form-label" for="text-input">Tanggal Muat</label>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-md-2">
                                          <input type="checkbox" [(ngModel)]="checkboxAll.ReceiveDate"  (change)="onChange('Tanggal_Bongkar', $event)"class="form-control">
                                        </div>
                                        <label class="col-md-8 col-form-label" for="text-input">Tanggal Bongkar</label>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-md-2">
                                          <input type="checkbox" [(ngModel)]="checkboxAll.SLCode"  (change)="onChange('SLCode', $event)"class="form-control">
                                        </div>
                                        <label class="col-md-8 col-form-label" for="text-input">SL Code Supir</label>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-md-2">
                                          <input type="checkbox" [(ngModel)]="checkboxAll.SLCode2"  (change)="onChange('SLCode2', $event)"class="form-control">
                                        </div>
                                        <label class="col-md-8 col-form-label" for="text-input">SL Code Supir 2</label>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="form-group row">
                                        <div class="col-md-2">
                                          <input type="checkbox" [(ngModel)]="checkboxAll.SLCode3"  (change)="onChange('SLCode3', $event)"class="form-control">
                                        </div>
                                        <label class="col-md-10 col-form-label" for="text-input">SL Code Supir 3</label>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-md-2">
                                          <input type="checkbox" [(ngModel)]="checkboxAll.TravelCostMakan"  (change)="onChange('Biaya_Makan', $event)"class="form-control">
                                        </div>
                                        <label class="col-md-8 col-form-label" for="text-input">Uang Makan</label>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-md-2">
                                          <input type="checkbox" [(ngModel)]="checkboxAll.TravelCostJarak"  (change)="onChange('Jarak', $event)"class="form-control">
                                        </div>
                                        <label class="col-md-8 col-form-label" for="text-input">Jarak</label>
                                    </div>
                                    <!-- <div class="form-group row">
                                        <div class="col-md-2">
                                          <input type="checkbox" [(ngModel)]="checkboxAll.StatusSupir"  (change)="onChange('StatusSupir', $event)"class="form-control">
                                        </div>
                                        <label class="col-md-8 col-form-label" for="text-input">Status Supir</label>
                                    </div> -->
                                    <!-- <div class="form-group row">
                                        <div class="col-md-2">
                                          <input type="checkbox" [(ngModel)]="checkboxAll.PaymentAmount"  (change)="onChange('Total_Bayar', $event)"class="form-control">
                                        </div>
                                        <label class="col-md-8 col-form-label" for="text-input">Total Pembayaran</label>
                                    </div> -->
                                    <div class="form-group row">
                                        <div class="col-md-2">
                                          <input type="checkbox" [(ngModel)]="checkboxAll.TravelCost"  (change)="onChange('Biaya_Perjalanan', $event)"class="form-control">
                                        </div>
                                        <label class="col-md-8 col-form-label" for="text-input">Uang Jalan</label>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-md-2">
                                          <input type="checkbox" [(ngModel)]="checkboxAll.SIMValid"  (change)="onChange('SIM_Expired', $event)"class="form-control">
                                        </div>
                                        <label class="col-md-8 col-form-label" for="text-input">Masa Aktif SIM</label>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-md-2">
                                          <input type="checkbox" [(ngModel)]="checkboxAll.joinDate"  (change)="onChange('Tanggal_Bergabung', $event)"class="form-control">
                                        </div>
                                        <label class="col-md-8 col-form-label" for="text-input">Join Date</label>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-md-2">
                                          <input type="checkbox" [(ngModel)]="checkboxAll.contractDate"  (change)="onChange('Kontrak_Dari', $event)"class="form-control">
                                        </div>
                                        <label class="col-md-8 col-form-label" for="text-input">Contract Date</label>
                                    </div>
                                    <div class="form-group row">
                                      <div class="col-md-2">
                                        <input type="checkbox" [(ngModel)]="checkboxAll.invoiceDate"  (change)="onChange('Tanggal_Invoice', $event)"class="form-control">
                                      </div>
                                      <label class="col-md-8 col-form-label" for="text-input">Invoice Date</label>
                                  </div>
                                </div>   
                            </div>
                        </div>
                      </div>
             
        </div>
    </div>
    
  </section>

  <div bsModal #ModalRoute="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog  modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title">
            <div class="row">
              <strong>Pilih Rute</strong>
            </div>
          </div>
          <button type="button" class="close" (click)="ModalRoute.hide()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <!-- <app-master-relation></app-master-relation> -->
            <table datatable [dtOptions]="dtOptionsRoute" [dtTrigger]="dtTriggerRoute" class="table-striped">
              <thead>
                <tr>
                  <th>Action</th>
                  <th>Rute</th>
                  <th>Lokasi Muat</th>
                  <th>Lokasi Bongkar</th>
                  <th>Tipe Rute</th>
                  <th>Diubah Oleh</th>
                  <th>Dibuat Pada</th>
                  <th>Diubah Oleh</th>
                  <th>Diubah Pada</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let row of dataRoute">    
                  <td>
                    <button (click)="onSelectRoute(row);ModalRoute.hide()" class="btn btn-sm btn-primary"  data-toggle="modal" triggers="mouseenter:mouseleave"><i class="fa fa-check"></i></button>
                  </td> 
                  <td>{{row.route_name}}</td>
                  <td>{{row.loadtext}}</td>
                  <td>{{row.unloadtext}}</td>
                  <td>{{row.route_type}}</td>
                  <td>{{row.createdby}}</td>
                  <td>{{row.created_at | slice:0:10}}</td>  
                  <td>{{row.updatedby}}</td>  
                  <td>{{row.updated_at | slice:0:10}}</td>        
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th><input hidden class="searchRute"/></th>
                  <th><input id="nameRute" type="text" class="searchRute" placeholder="Cari Nama" /></th>
                  <th><input id="loadRute" type="text" class="searchRute" placeholder="Cari Muat" /></th>
                  <th><input id="unloadRute" type="text" class="searchRute" placeholder="Cari Bongkar" /></th>
                  <th><input id="typeRute" type="text" class="searchRute" placeholder="Cari Type" /></th>
                  <th><input hidden class="searchRute"/></th>
                  <th><input hidden class="searchRute"/></th>
                  <th><input hidden class="searchRute"/></th>
                  <th><input hidden class="searchRute"/></th>
                </tr>
              </tfoot>
            </table>
        </div>
      </div>
    </div>
  </div>
  

  <div  bsModal #ModalDriver="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog  modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title"><strong>Pilih Supir</strong></div>
          <button type="button" class="close" (click)="ModalDriver.hide()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <table datatable [dtOptions]="dtOptionsDriver" [dtTrigger]="dtTriggerDriver" class="table-striped">
            <thead>
              <tr>
                <th>Action</th>
                <th>Nama</th>
                <th>No SIM</th>
                <th>Jenis SIM</th>
                <th>Agama</th>
                <th>Tempat Lahir</th>
                <th>No KTP</th>
                <th>Tanggal Lahir</th>
                <th>SL</th>
                <th>SL Piutang</th>
                <th>SL Uang Jalan</th>
                <th>Alamat</th>
                <th>Berlaku Sampai</th>
                <th>Kontrak Sampai</th>
                <th>Dibuat Pada</th>
                <th>Diubah Pada</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let row of dataDriver">
                <td>
                  <button (click)="onSelectDriver(row);ModalDriver.hide()" class="btn btn-sm btn-primary"  data-toggle="modal" triggers="mouseenter:mouseleave"><i class="fa fa-check"></i></button>
                </td>
                <td>{{row.driver_name}}</td>
                <td>{{row.driver_license}}</td>
                <td>{{row.driver_license_type}}</td>
                <td>{{row.agama}}</td>
                <td>{{row.birthplace}}</td>
                <td>{{row.ktp_no}}</td>
                <td>{{row.tanggal_lahir | slice:0:10}}</td>
                <td>{{row.sl_code}}</td>
                <td>{{row.sl_code2}}</td>
                <td>{{row.sl_code3}}</td>
                <td>{{row.address}}</td>
                <td>{{row.valid_to | slice:0:10}}</td>
                <td>{{row.valid_to_contract | slice:0:10}}</td>
                <td>{{row.created_at | slice:0:10}}</td>  
                <td>{{row.updated_at | slice:0:10}}</td>   
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th><input hidden class="searchInputD"/></th>
                <th><input id="driver_name" type="text" class="searchInputD" placeholder="Nama"/></th>
                <th><input id="driver_license" type="text" class="searchInputD" placeholder="No SIM"/></th>
                <th><input id="driver_license_type" type="text" class="searchInputD" placeholder="Tipe SIM"/></th>
                <th><input id="agama" type="text" class="searchInputD" placeholder="Agama"/></th>
                <th><input id="birthplace" type="text" class="searchInputD" placeholder="Tempat Lahir"/></th>
                <th><input id="ktp_no" type="text" class="searchInputD" placeholder="No KTP"/></th>
                <th><input id="tanggal_lahir" type="text" class="searchInputD" placeholder="Tanggal Lahir"/></th>
                <th><input id="sl_code" type="text" class="searchInputD" placeholder="SL"/></th>
                <th><input id="sl_code2" type="text" class="searchInputD" placeholder="SL Piutang"/></th>
                <th><input id="sl_code3" type="text" class="searchInputD" placeholder="SL UJ"/></th>
                <th><input id="address" type="text" class="searchInputD" placeholder="Alamat"/></th>
                <th><input id="valid_to" type="text" class="searchInputD" placeholder="Berlaku Sampai"/></th>
                <th><input id="valid_to_contract" type="text" class="searchInputD" placeholder="Kontrak Sampai"/></th>
                <th><input hidden class="searchInputD"/></th>
                <th><input hidden class="searchInputD"/></th>
              </tr>
            </tfoot>
      
          </table>
        </div>
      </div>
    </div>
    </div>

    
<div  bsModal #ModalCustomer="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog  modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title">
          <div class="row">
            <strong>Pilih Customer</strong>
          </div>
        </div>
        <button type="button" class="close" (click)="ModalCustomer.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
          <table datatable [dtOptions]="dtOptionsCustomer" [dtTrigger]="dtTriggerCustomer" class="table-striped">
              <thead>
                <tr>
                  <th>Action</th>
                  <th>Kode</th>
                  <th>Nama</th>
                  <th>Alamat</th>
                  <th>Kode SL</th>
                  <th>Phone</th>
                  <th>Active</th>
                  <th>Dibuat Oleh</th>
                  <th>Dibuat Pada</th>
                  <th>Diubah Oleh</th>
                  <th>Diubah Pada</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let row of dataRelation">      
                  <td style="width: 200;">
                    <button (click)="onSelectCustomer(row);ModalCustomer.hide()" title="Edit"  class="btn btn-sm btn-primary" data-toggle="modal" triggers="mouseenter:mouseleave"><i class="fa fa-check"></i></button>
                  </td>  
                  <td>{{row.relation_code}}</td>
                  <td>{{row.relation_name}}</td>
                  <td>{{row.relation_address}}</td>
                  <td>{{row.relation_sl_code}}</td>
                  <td>{{row.relation_phone}}</td>
                  <td>{{row.active}}</td>
                  <td>{{row.createdby}}</td>
                  <td>{{row.created_at | slice:0:10}}</td>  
                  <td>{{row.updatedby}}</td>  
                  <td>{{row.updated_at | slice:0:10}}</td>   
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th><input hidden class="searchCustomer"/></th>
                  <th><input id="codecustomer" type="text" class="searchCustomer" placeholder="Kode"/></th>
                  <th><input id="namecustomer" type="text" class="searchCustomer" placeholder="Nama"/></th>
                  <th><input id="addresscustomer" type="text" class="searchCustomer" placeholder="Alamat"/></th>
                  <th><input id="slcustomer" type="text" class="searchCustomer" placeholder="Kode SL"/></th>
                  <th><input hidden class="searchCustomer"/></th>
                  <th><input hidden class="searchCustomer"/></th>
                  <th><input hidden class="searchCustomer"/></th>
                  <th><input hidden class="searchCustomer"/></th>
                  <th><input hidden class="searchCustomer"/></th>
                  <th><input hidden class="searchCustomer"/></th>
                </tr>
              </tfoot>
    
            </table>
      </div>
    </div>
  </div>
</div>

<div bsModal #ModalSupplier="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog  modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title">
          <div class="row">
            <strong>Pilih Penjual</strong>
          </div>
        </div>
        <button type="button" class="close" (click)="ModalSupplier.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
          <!-- <app-master-relation></app-master-relation> -->
          <table datatable [dtOptions]="dtOptionsSupplier" [dtTrigger]="dtTriggerSupplier" class="table-striped">
              <thead>
                <tr>
                  <th>Action</th>
                  <th>Kode</th>
                  <th>Nama</th>
                  <th>Alamat</th>
                  <th>Kode SL</th>
                  <th>Phone</th>
                  <th>Active</th>
                  <th>Dibuat Oleh</th>
                  <th>Dibuat Pada</th>
                  <th>Diubah Oleh</th>
                  <th>Diubah Pada</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let row of dataSupplier">      
                  <td style="width: 200;">
                    <button (click)="onSelectSupplier(row);ModalSupplier.hide()" title="Edit"  class="btn btn-sm btn-primary" data-toggle="modal" triggers="mouseenter:mouseleave"><i class="fa fa-check"></i></button>
                  </td>  
                  <td>{{row.relation_code}}</td>
                  <td>{{row.relation_name}}</td>
                  <td>{{row.relation_address}}</td>
                  <td>{{row.relation_sl_code}}</td>
                  <td>{{row.relation_phone}}</td>
                  <td>{{row.active}}</td>
                  <td>{{row.createdby}}</td>
                  <td>{{row.created_at | slice:0:10}}</td>  
                  <td>{{row.updatedby}}</td>  
                  <td>{{row.updated_at | slice:0:10}}</td>   
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th><input hidden class="searchSupplier"/></th>
                  <th><input id="codeSup" type="text" class="searchSupplier" placeholder="Kode"/></th>
                  <th><input id="nameSup" type="text" class="searchSupplier" placeholder="Nama"/></th>
                  <th><input id="addressSup" type="text" class="searchSupplier" placeholder="Alamat"/></th>
                  <th><input id="slSup" type="text" class="searchSupplier" placeholder="Kode SL"/></th>
                  <th><input hidden class="searchSupplier"/></th>
                  <th><input hidden class="searchSupplier"/></th>
                  <th><input hidden class="searchSupplier"/></th>
                  <th><input hidden class="searchSupplier"/></th>
                  <th><input hidden class="searchSupplier"/></th>
                  <th><input hidden class="searchSupplier"/></th>
                </tr>
              </tfoot>
    
            </table>
      </div>
    </div>
  </div>
</div>
   

<div  bsModal #ModalMaterial="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog  modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title">
          <div class="row">
            <strong>Pilih Material</strong>
          </div>
        </div>
        <button type="button" class="close" (click)="ModalMaterial.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
          <!-- <app-master-material></app-master-material> -->
          <table datatable [dtOptions]="dtOptionsMaterial" [dtTrigger]="dtTriggerMaterial" class="table-striped">
            <thead>
             <tr>
               <th>Action</th>
               <th>Kode Material</th>
               <th>Nama Material</th>
               <th>Harga</th>
               <th>UOM</th>
               <th>Tipe Cetakan DO</th>
               <th>Dibuat Oleh</th>
               <th>Dibuat Pada</th>
               <th>Diubah Oleh</th>
               <th>Diubah Pada</th>
             </tr>
           </thead> 
           <tbody>
             <tr *ngFor="let row of dataMaterial">
             <td>
              <button title="Pilih Data" (click)="onSelectMaterial(row);ModalMaterial.hide()" class="btn btn-sm btn-primary" triggers="mouseenter:mouseleave"><i class="fa fa-check"></i></button>
             </td>       
               <td>{{row.material_code}}</td>
               <td>{{row.material_name}}</td>
               <td>{{row.material_price}}</td>
               <td>{{row.uom}}</td>
               <td>{{row.do_print_type}}</td>
               <td>{{row.created}}</td>
               <td>{{row.created_at | slice:0:10}}</td>  
               <td>{{row.updated_by}}</td>  
               <td>{{row.updated_at | slice:0:10}}</td>    
             </tr>
           </tbody>
           <tfoot>
             <tr>
               <th><input hidden class="searchMaterial" /></th>
               <th><input id="codeMaterial" type="text" class="searchMaterial" placeholder="Search Kode"/></th>
               <th><input id="nameMaterial" type="text" class="searchMaterial" placeholder="Search Material"/></th>
               <th><input hidden type="text" class="searchMaterial"/></th>
               <th><input hidden type="text" class="searchMaterial"/></th>
               <th><input hidden type="text" class="searchMaterial"/></th>
               <th><input hidden type="text" class="searchMaterial"/></th>
               <th><input hidden type="text" class="searchMaterial"/></th>
               <th><input hidden type="text" class="searchMaterial"/></th>
               <th><input hidden type="text" class="searchMaterial"/></th>
             </tr>
           </tfoot>
     
         </table>
      </div>
    </div>
  </div>
</div>
  
  <ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
  >
  <p style="font-size: 20px; color: white">Loading...</p>
  </ngx-spinner>