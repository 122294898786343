import { AfterViewInit, Component, OnInit, ViewChild,Input, ChangeDetectorRef} from '@angular/core';
import { DataTableDirective } from 'angular-datatables'
import { TransportService} from '../../..//services/api.service';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { Guid } from 'guid-typescript'
import { FormGroup, FormBuilder, Validators, FormArray, } from '@angular/forms';
import { MasterCode } from '@/model/masterCode';
import { TravelCost } from '@/model/travelCost';
import { ListTravelCost, ListTravelCostRaw } from '@/model/listTravelCost';
import { MasterRouteComponent } from '../master-route/master-route.component';
import { Route } from '@/model/route';
import { MasterPortComponent } from '../master-port/master-port.component';
import { Port } from '@/model/port';
import { MasterMaterialComponent } from '../master-material/master-material.component';
import { Material } from '@/model/material';
import { MasterCapacityComponent } from '../master-capacity/master-capacity.component';
import { Capacity } from '@/model/capacity';
import { MasterCosttypeComponent } from '../master-costtype/master-costtype.component';
import { CostType } from '@/model/costType';
import { DetailTravelCost } from '@/model/detailTravelCost';
import moment from 'moment';
import { Router } from '@angular/router';
import { AuthMenu } from '@/model/masterSubMenu';
import { UserAuthNew } from '@/model/respDto';
@Component({
  selector: 'app-master-travelcost',
  templateUrl: './master-travelcost.component.html',
  styleUrls: ['./master-travelcost.component.scss'],
  providers: [MasterRouteComponent, MasterPortComponent, MasterMaterialComponent, MasterCapacityComponent, MasterCosttypeComponent]
})
export class MasterTravelcostComponent implements OnInit, AfterViewInit {
  costForm: FormGroup;
  @ViewChild('modalSaveClose') modalClose;
  @ViewChild(DataTableDirective, {static: false})
  datatableElement: DataTableDirective;
  dtOptions: any = {};
  dtOptions2: any = {};
  dtOptions3: any = {};
  dtOptions4: any = {};
  dtOptions5: any = {};
  dtOptions6: any = {};
  dtTrigger2 = new Subject();
  dtTrigger3 = new Subject();
  dtTrigger4 = new Subject();
  dtTrigger5 = new Subject();
  dtOptionsHistory: any = {};
  dtOptionsCapacity: any = {};
  dtOptionsMaterial: any = {};
  dtOptionsRoute: any = {};
  dtOptionsPort: any = {};
  public travel: TravelCost;
  public dataType: MasterCode[] = [];
  dataTravel: ListTravelCost[] = [];
  dataHistory: ListTravelCost[] = [];
  dataDetailCost: DetailTravelCost[] = [];
  authAccessPlb: UserAuthNew[]= [];
  dataListRawDto : ListTravelCostRaw;
  dtTrigger = new Subject();
  dtTriggerCapacity = new Subject();
  dtTriggerMaterial = new Subject();
  dtTriggerRoute = new Subject();
  dtTriggerPort = new Subject();
  public portSelect: string;
  public ruteSelect: string;
  public materialSelect: string;
  public capacitySelect: string;
  public costTypeSelect: string;
  labelTotal : number = 0;
  @Input() historyModel = {port_id: null, material_id: null, route_id: null, capacity_id: null}
  dataTambahan: Array<{ costTypeId: Guid, costType: string, count: number, type_text: string}> = [];
  @Input() costModel = { id : null, location : null, transit_port : null,route_id : null,capacity_id : null,
                        material_id : null, distance: 0, ritase: 0, trip_premi: 0, trip_type: null, valid_from: null, detail:new Array()};
  @Input() tambahanModel = { id:Guid.create(), costTypeId: null, costType: '', count: 0, type_text: null}
  action: string;
  public dt: any;
  public cb: any;
  public dtTravelCost: any;
  public cbTravelcost: any;
  btnCrud : boolean = true;

  constructor( public service: TransportService, private router: Router,  private spinner: NgxSpinnerService, fb: FormBuilder, private changeDetectorRef: ChangeDetectorRef,
               public componentPort: MasterPortComponent, public componentRoute: MasterRouteComponent, public componentMaterial: MasterMaterialComponent,
               public componentCapacity: MasterCapacityComponent, public componentCostType: MasterCosttypeComponent, ) {

    this.costForm = fb.group({
      'location': [null, Validators.required],
      'transit_port': [null, Validators.required],
      'route_id': [null, Validators.required],
      'capacity_id': [null, Validators.required],
      'material_id': [null, Validators.required],
      'distance': [null, Validators.required],
      'ritase': [null, Validators.required],
      'trip_premi': [null, Validators.required],
      'trip_type': [null, Validators.required],
      'valid_from': [null, Validators.required],
      'terms': [false]
    });
  }

  userAcces(){
    const menuUser = localStorage.getItem("menu");
    if (menuUser != "All"){
      const locationName = localStorage.getItem('locationname');
      if (locationName != "Palembang"){
        const subMenu = localStorage.getItem("acces_menu");
        let arrayMenu =  subMenu.split(",");
        if (arrayMenu.includes("master-travelcost") == false){
          this.router.navigate(["/home"]);
        }
      }
      else{
        this.authAccessPlb = JSON.parse(localStorage.getItem("authAccessPlb"));
        var a = this.authAccessPlb.filter(item => item.subMenu  == "master-travelcost").length;
        if (a == 0){
          return this.router.navigate(["/home"]);
        }
        this.btnCrud = false;
        this.authAccessPlb.filter(item => item.subMenu == "master-travelcost").forEach(i => {
          if(i.auth == "CRUD"){
            this.btnCrud = true;
          }
         });
      }

    }
  }

    ngOnInit(): void {
      this.userAcces();
      this.dtOptions = {
        pageLength: 10,
        retrieve: true,
        scrollX: true,
        scrollCollapse: true,
        serverSide: true,
        processing: true,
        searching : false,
        info: false,     
        ordering: false,      
        ajax: (dataTablesParameters: any, callback, data: ListTravelCostRaw) => {
          this.dataListRawDto = data;
         this.dtTravelCost = dataTablesParameters; this.cbTravelcost = callback;
         this.getDataTravelCostRaw(dataTablesParameters, callback,  data, true );
        //  this.getDataTravelCost(dataTablesParameters, callback, $('#portsearch').val().toString(), $('#materialsearch').val().toString()
        //  , $('#routesearch').val().toString(), $('#capacitysearch').val().toString(),true);
        },
        columnDefs: [
          { width: 150, targets: [1,2,3,4,5,6,7,8,9,10,11,12,13,14],
          },
          { width: 200, targets: [0] }
        ],
      };
      
      this.dtOptions2 = {
        retrieve: true,
        pageLength: 5,
        serverSide: true,
        processing: true,
        searching : false,
        info: false,        
        lengthChange : false,
        ajax: (dataTablesParameters: any, callback) => {
          this.dt = dataTablesParameters; this.cb = callback;
          this.componentRoute.getDataRoute(dataTablesParameters, callback,$('#namesearch').val().toString(),  $('#typesearch').val().toString()
          ,  $('#unloadsearch').val().toString(),  $('#loadsearch').val().toString(), "",true);
         }
      };

      this.dtOptionsRoute = {
        retrieve: true,
        scrollX: true,
        scrollCollapse: true,
        pageLength: 5,
        serverSide: true,
        searching : false,
        info: false,        
        lengthChange : false,
        ajax: (dataTablesParameters: any, callback) => {
          this.dt = dataTablesParameters; this.cb = callback;
          this.componentRoute.getDataRoute(dataTablesParameters, callback,"","","","", "",true);
         }
      };

      this.dtOptionsPort = {
        retrieve: true,
        scrollX: true,
        scrollCollapse: true,
        pageLength: 5,
        serverSide: true,
        processing: true,
        searching : false,
        info: false,        
        lengthChange : false,
        ajax: (dataTablesParameters: any, callback) => {
         this.dt = dataTablesParameters; this.cb = callback;
         this.componentPort.getDataPort(dataTablesParameters, callback, $('#portsearchM2').val().toString(),true);
        }
      };

      this.dtOptions3 = {
        retrieve: true,
        pageLength: 5,
        serverSide: true,
        processing: true,
        searching : false,
        info: false,        
        lengthChange : false,
        ajax: (dataTablesParameters: any, callback) => {
         this.dt = dataTablesParameters; this.cb = callback;
         this.componentPort.getDataPort(dataTablesParameters, callback, $('#portsearchM').val().toString(),true);
        }
      };

      this.dtOptionsMaterial = {
        retrieve: true,
        scrollX: true,
        scrollCollapse: true,
        pageLength: 5,
        serverSide: true,
        processing: true,
        searching : false,
        info: false,        
        lengthChange : false,
        ajax: (dataTablesParameters: any, callback) => {
         this.dt = dataTablesParameters; this.cb = callback;
         this.componentMaterial.getDataMaterial(dataTablesParameters, callback,"","",true);
        }
      };

      this.dtOptions4 = {
        retrieve: true,
        pageLength: 5,
        serverSide: true,
        processing: true,
        searching : false,
        info: false,        
        lengthChange : false,
        ajax: (dataTablesParameters: any, callback) => {
         this.dt = dataTablesParameters; this.cb = callback;
         this.componentMaterial.getDataMaterial(dataTablesParameters, callback, "","",true);
        }
      };

      this.dtOptionsCapacity = {
        retrieve: true,
        scrollX: true,
        scrollCollapse: true,
        pageLength: 5,
        serverSide: true,
        processing: true,
        searching : false,
        info: false,        
        lengthChange : false,
        ajax: (dataTablesParameters: any, callback) => {
         this.dt = dataTablesParameters; this.cb = callback;
         this.componentCapacity.getDataCapacity(dataTablesParameters, callback, $('#codecapacity').val().toString(), $('#namecapacity').val().toString(),
         $('#vehiclesearch').val().toString(),true);
        }
      };
      
      this.dtOptions5 = {
        retrieve: true,
        pageLength: 5,
        serverSide: true,
        processing: true,
        searching : false,
        info: false,        
        lengthChange : false,
        ajax: (dataTablesParameters: any, callback) => {
         this.dt = dataTablesParameters; this.cb = callback;
         this.componentCapacity.getDataCapacity(dataTablesParameters, callback, $('#codecapacity').val().toString(), $('#namecapacity').val().toString(),
         $('#vehiclesearch').val().toString(),true);
        }
      };

      this.dtOptions6 = {
        retrieve: true,
        pageLength: 5,
        serverSide: true,
        processing: true,
        searching : false,
        info: false,        
        lengthChange : false,
        ajax: (dataTablesParameters: any, callback) => {
         this.dt = dataTablesParameters; this.cb = callback;
         this.componentCostType.getDataCost(dataTablesParameters, callback, $('#codecost').val().toString(), $('#namecost').val().toString(),true);
        }
      };

      this.dtOptionsHistory = {
        searching : false,
        info: false,        
        lengthChange : false,
      };
      


      const that = this;
      $('.searchInput').on("keyup", function(e) {
        var key = e.which;
        if (key == 13) // the enter key ascii code
        {
          that.getDataTravelCostRaw(that.dtTravelCost, that.cbTravelcost, that.dataListRawDto, false );
          // that.getDataTravelCost(that.dt, that.cb, $('#portsearch').val().toString(), $('#materialsearch').val().toString()
          // , $('#routesearch').val().toString(), $('#capacitysearch').val().toString(),false);
        }
      }); 

      $('.searchInputRoute').on("keyup", function(e) {
        var key = e.which;
        if (key == 13) // the enter key ascii code
        {
          that.componentRoute.getDataRoute(that.dt, that.cb,  $('#namesearch2').val().toString(),  $('#typesearch2').val().toString()
          ,  $('#unloadsearch2').val().toString(),  $('#loadsearch2').val().toString(), "",false);
        }
      }); 
      

      $('.searchInput2').on("keyup", function(e) {
        var key = e.which;
        if (key == 13) // the enter key ascii code
        {
          that.componentRoute.getDataRoute(that.dt, that.cb,  $('#namesearch').val().toString(),  $('#typesearch').val().toString()
          ,  $('#unloadsearch').val().toString(),  $('#loadsearch2').val().toString(), "",false);
        }
      });  

      $('.searchInputPort').on("keyup", function(e) {
        var key = e.which;
        if (key == 13) // the enter key ascii code
        {
          that.componentPort.getDataPort(that.dt, that.cb,  $('#portsearchM2').val().toString(),false);
        }
      }); 

      $('.searchInputMaterial').on("keyup", function(e) {
        var key = e.which;
        if (key == 13) // the enter key ascii code
        {
          that.componentMaterial.getDataMaterial(that.dt, that.cb,  $('#codematerial2').val().toString(), $('#namematerial2').val().toString(),false);
        }
      });  

      $('.searchInput4').on("keyup", function(e) {
        var key = e.which;
        if (key == 13) // the enter key ascii code
        {
          that.componentMaterial.getDataMaterial(that.dt, that.cb,  $('#codematerial').val().toString(), $('#namematerial').val().toString(),false);
        }
      });  

      $('.searchInput5').on("keyup", function(e) {
        var key = e.which;
        if (key == 13) // the enter key ascii code
        {
          that.componentCapacity.getDataCapacity(that.dt, that.cb, $('#codecapacity').val().toString(), $('#namecapacity').val().toString(),
           $('#vehiclesearch').val().toString(),false);
        }
      });     

      $('.searchInputCap').on("keyup", function(e) {
        var key = e.which;
        if (key == 13) // the enter key ascii code
        {
          that.componentCapacity.getDataCapacity(that.dt, that.cb, $('#codecapacity2').val().toString(), $('#namecapacity2').val().toString(),
           $('#vehiclesearch2').val().toString(),false);
        }
      });      
 

      $('.searchInput6').on("keyup", function(e) {
        var key = e.which;
        if (key == 13) // the enter key ascii code
        {
          that.componentCostType.getDataCost(that.dt, that.cb, $('#codecost').val().toString(), $('#namecost').val().toString(),
          false);
        }
      });   
      
      this.getDataType();
    }   

    // getDataTravelCost(dataTablesParameters, callback, port: string, material: string, route: string, capacity: string, padging : boolean){ 
    //   var page = 1;
    //   if (padging == true){
    //     page = parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length) + 1;
    //   }
    //   this.service.getTravelCost(dataTablesParameters, page, dataTablesParameters.length, port, material, route, capacity)
    //   .subscribe(resp => { 
    //       this.dataTravel = resp.data;
    //       callback({
    //         recordsTotal: resp.totalCount,
    //         recordsFiltered: resp.totalCount,
    //          data:  []
    //       });
    //   });
    // }

    getDataTravelCostRaw(dataTablesParameters, callback,data: ListTravelCostRaw, padging){
      var page = 1;
        if (padging == true){
          page = parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length) + 1;
        }
        data.page = page;
        data.perPage = dataTablesParameters.length;
        
        this.service.getListTravelCostRaw(data)
        .subscribe(resp => { 
            this.dataTravel = resp;
            callback({
              recordsTotal: dataTablesParameters.length,
              recordsFiltered: dataTablesParameters.length,
               data:  []
            });
        });
    }

    geHistoryTravelCost(port: Guid, material: Guid, route: Guid, capacity: Guid){ 
      this.dataHistory = null;
      this.historyModel.port_id = port;
      this.historyModel.material_id = material;
      this.historyModel.route_id = route;
      this.historyModel.capacity_id = capacity;
      this.spinner.show();
      this.service.getHistoryTravelCost(this.historyModel)
      .subscribe(resp => { 
          this.dataHistory = resp;
          this.spinner.hide();
      });
    }
    
    ngAfterViewInit(): void {
      this.dtTrigger.next(0);
    }
  
    ngOnDestroy(): void {
      this.dtTrigger.unsubscribe();
    }
  
    
    rerender(): void {
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtTrigger.next(0);
      });
    }

    onSelectCost(cost: ListTravelCost) {
      this.action = "Re-Assign";
      this.getDetailTravelCost(cost.id);
      this.costModel.id = cost.id;
      this.costModel.route_id = cost.route_id;
      this.costModel.capacity_id = cost.capacity_id;
      this.costModel.material_id = cost.material_id;
      this.costModel.transit_port = cost.transit_port_id;
      this.costModel.ritase = cost.ritase;
      this.portSelect = cost.transit_port_name;
      this.ruteSelect = cost.route_name;
      this.capacitySelect = cost.capacity_name;
      this.materialSelect = cost.material_name;
      this.costModel.distance = cost.distance;
      this.costModel.ritase = cost.ritase;
      this.costModel.trip_premi = cost.trip_premi;
      this.costModel.valid_from = cost.valid_from;
      this.costModel.trip_type = cost.trip_type;
      this.labelTotal = cost.total_amount;
    }

    onSelectRoute(route: Route){
      this.ruteSelect = route.route_name;
      this.costModel.route_id = route.route_id;
    }

    onSelectPort(port: Port){
      this.portSelect = port.port_name;
      this.costModel.transit_port = port.port_id;
    }

    onSelectMaterial(material: Material){
      this.materialSelect = material.material_name;
      this.costModel.material_id = material.material_id;
    }

    onSelectCapacity(capacity: Capacity){
      this.capacitySelect = capacity.capacity_note;
      this.costModel.capacity_id = capacity.capacity_id;
    }

    onSelectCostType(costType: CostType){
      this.tambahanModel.costType = costType.code;
      this.tambahanModel.id = costType.id;
      this.tambahanModel.type_text = costType.type_text;
    }

    onClickHistory(cost: ListTravelCost){
      this.geHistoryTravelCost(cost.transit_port_id,cost.material_id,cost.route_id,cost.capacity_id)
    }

    AddDataTambahan() {
      console.log(JSON.stringify(this.tambahanModel));
      if(this.tambahanModel.costType == null){
        return Swal.fire({
          title: "Data Belum Lengkap !",
          type: 'warning',
          confirmButtonText: '      Ok!     '
        });
      }
      this.dataTambahan.push( { costTypeId: this.tambahanModel.id, costType: this.tambahanModel.costType, 
        count: this.tambahanModel.count, type_text: this.tambahanModel.type_text} );
      // this.labelTotal = this.dataTambahan.reduce((sum, obj) => {
      //   return sum + obj.count;
      // }, 0); 
      if (this.tambahanModel.type_text == "PIUTANG"){
        this.labelTotal -= this.tambahanModel.count;
      }
      else{
        this.labelTotal += this.tambahanModel.count;
      }
    }

    deleteDetail(id: number)
    { 
      this.labelTotal = 0;
      this.dataTambahan.splice(id, 1); this.changeDetectorRef.detectChanges(); 
      this.dataTambahan.forEach(x => {
        if (x.costTypeId != null){
          console.log("not null : "+x.type_text)
          if (x.type_text == "PIUTANG"){
            this.labelTotal -= x.count;
          }
          else{
            this.labelTotal += x.count;
          }
        }
        else{
          console.log(" null : "+x.type_text)
          if (x.type_text == "PIUTANG"){
            this.labelTotal += this.tambahanModel.count;
          }
          else{
            this.labelTotal -= this.tambahanModel.count;
          }
        }
      })
      // this.labelTotal = this.dataTambahan.reduce((sum, obj) => {
      //   return sum + obj.count;
      // }, 0); 
      if (this.dataTambahan.length == 0){
        this.labelTotal = 0;
      }
    } 


    saveDataTravel(){
      if (this.costModel.material_id == null || this.costModel.distance == null || this.costModel.trip_premi == null || 
        this.costModel.valid_from == null || this.costModel.route_id == null || this.costModel.capacity_id == null){
        return Swal.fire({
          type: 'warning',
          text : 'Data Belum Lengkap!',
          confirmButtonText: 'Ok!'
        });
      }

      Swal.fire({
        title: 'Save Data Biaya Perjalanan?',
        // text: "You won't be able to revert this!",
        type: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: '      Yes     '
      }).then((result) => {
        if (result.value) {
          this.spinner.show();
          this.costModel.detail = [];
          for (let i = 0; i < this.dataTambahan.length; i++) {
            this.costModel.detail.push({
              amount : this.dataTambahan[i].count,
              cost_type_id : (this.dataTambahan[i].costTypeId),
          });
          }
          this.service.addTravelCost(this.costModel).subscribe(resp => {
            this.spinner.hide();
            if(resp.succes == false){
              Swal.fire({
                title: ""+resp.text,
                type: 'warning',
                confirmButtonText: '      Ok!     '
              });
            }
            else{
              Swal.fire({
                title: ""+resp.text,
                type: 'success',
                confirmButtonText: '      Ok!     '
              }).then((result) => {
                if (result.value) { 
                  this.getDataTravelCostRaw(this.dtTravelCost, this.cbTravelcost, this.dataListRawDto, true)
                  this.modalClose.nativeElement.click();;
                }              
              });
            }
          });
        }
      
      }, (error) => {
      });
    } 

  deleteCost(cost: TravelCost){
    Swal.fire({
      title: 'Delete Data?',
      // text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '      Yes     '
    }).then((result) => {
      if (result.value) {
        this.spinner.show();
        this.service.DeleteTravelCost(cost.id).subscribe(vehicle => { 
          this.spinner.hide();
          Swal.fire({
            type: 'success',
            confirmButtonText: '      Succes!     '
          }).then((result) => {
            if (result.value) { 
              this.getDataTravelCostRaw(this.dtTravelCost, this.cbTravelcost, this.dataListRawDto, true)
            }
          
          }, (error) => {
            //; 
          })
        });
      }
    
    }, (error) => {
      //; 
    });
  }

  
  clearFormCost(){
    var today = moment().format('YYYY-MM-DD');
    this.action = "Input";
    this.costModel.id = null;
    this.dataTambahan = [];
    this.costModel.id = null;
    this.portSelect = null;
    this.ruteSelect = null;
    this.capacitySelect = null;
    this.materialSelect = null;
    this.costModel.distance = 0;
    this.costModel.ritase = 0;
    this.costModel.trip_premi = 0;
    this.costModel.valid_from = today;
    this.labelTotal = 0;
    this.tambahanModel.costType = null;
    this.tambahanModel.count = 0;
  }

  getDataType(){
    this.service.getListMasterCode("TripType").subscribe(resp => {
      this.dataType = resp;
      this.costModel.trip_type = resp[4].id;
    })
  }
    
  getDetailTravelCost(id: Guid){
    this.dataTambahan = [];
    this.service.getDetailListTravelCost(id)
    .subscribe(resp => { 
        this.dataDetailCost = resp;
        for (let i = 0; i < resp.length; i++) {
          this.dataTambahan.push({
            costTypeId: resp[i].cost_type_id,
            costType : resp[i].cost_type_text,
            count: resp[i].amount,
            type_text: resp[i].type_text
        });
        
        // this.labelTotal = this.dataTambahan.reduce((sum, obj) => {
        //   return sum + obj.count;
        // }, 0); 
      }
    });
  }

  
showCapacity(){
  this.componentCapacity.getDataCapacity(this.dt, this.cb, "","","",true);
  this.dtTriggerCapacity.next(this.dtOptionsCapacity);
  this.dtTrigger5.next(this.dtOptions5);
}
showMaterial(){
  this.componentMaterial.getDataMaterial(this.dt, this.cb,"","",true);
  this.dtTriggerMaterial.next(this.dtOptionsMaterial)
  this.dtTrigger4.next(this.dtOptions4);
}
showPort(){
  this.componentPort.getDataPort(this.dt, this.cb, "",true);
  this.dtTriggerPort.next(this.dtOptionsPort)
  this.dtTrigger3.next(this.dtOptions3);
}
showRoute(){
  this.componentRoute.getDataRoute(this.dt, this.cb,"","","","", "",true);
  this.dtTriggerRoute.next(this.dtOptionsRoute)
  this.dtTrigger2.next(this.dtOptions2);
}

searchData(){
  this.dataListRawDto.capacityId = this.costModel.capacity_id;
  this.dataListRawDto.materialId = this.costModel.material_id;
  this.dataListRawDto.portId = this.costModel.transit_port;
  this.dataListRawDto.routeId = this.costModel.route_id;
  this.getDataTravelCostRaw(this.dtTravelCost, this.cbTravelcost, this.dataListRawDto, true);
}


setNullSearch(){
  this.costModel.transit_port = null;
  this.portSelect = null;
  this.costModel.material_id = null;
  this.materialSelect = null;
  this.costModel.route_id = null;
  this.ruteSelect = null;
  this.costModel.capacity_id = null;
  this.capacitySelect = null;

}
      
}