import { Guid } from "guid-typescript";

export class ReportDto {
    checkbox : string;
    fieldName : string;
    headerName : string;
    format : string;
    filter : string;
    total : string;
    showHeader : string;
    showSubHeader : string;
    query : string;
}

export class FinalResultDto {
    status : string;
    json : ReportDto[];
}

export class PrintReportDto {
    strHead : string;
    strJSON : string;
    strColWithAlignRight : string;
    strFoot : string;
    title : string;
}

export class ReportResp {
    succes : Boolean;
    text : string;
    report : PrintReportDto;
}


export class Report {
    id : Guid;
    modul : string;
    code : string;
    name : string;
    query : string;
    fields : string;
    headers : string;
    showAsHeaders : string;
    showAsSubHeaders : string;
    filters : string;
    formats : string;
    totals : string;
    primarySort : string;
    secondarySort : string;
}