<section class="content">    
    <div class="card">
      <div class="card-header">
        <strong>Laporan</strong>
      </div>
        <div class="card-body">
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group row">
                        <label class="col-md-3 col-form-label" for="text-input">Jenis Laporan</label>
                        <div class="col-md-8">
                          <select [(ngModel)]="tipe" (ngModelChange)="onSelectTipe($event)" class="form-control">
                            <option value='Rekap'><b>Laporan Rekapitulasi Realisasi Biaya Perjalanan</b></option>
                            <option value='TravelCost'><b>Laporan Pengeluaran Uang Jalan</b></option>
                          </select>   
                        </div>
                    </div> 
                    <div class="form-group row">
                        <label class="col-md-3 col-form-label" for="text-input">Dari Tanggal</label>
                        <div class="col-md-8">
                            <input [ngModel]="reportModel.from_date | date:'yyyy-MM-dd'" (ngModelChange)="reportModel.from_date = $event" type="date"  class="form-control">
                        </div>
                    </div> 
                    <div class="form-group row">
                        <label class="col-md-3 col-form-label" for="text-input">Sampai Tanggal</label>
                        <div class="col-md-8">
                            <input [ngModel]="reportModel.to_date | date:'yyyy-MM-dd'" (ngModelChange)="reportModel.to_date = $event" type="date"  class="form-control">
                        </div>
                    </div> 
                    <div [hidden]="!plb" class="form-group row">
                      <label class="col-md-3 col-form-label" for="text-input">Sub Lokasi</label>
                      <div class="col-md-8">
                      <select [(ngModel)]="reportModel.sub_location" class="form-control">
                          <option value=""><b>-- All --</b></option>
                          <option *ngFor="let row of locationSub" [value]='row.locationSub'><b>{{row.locationSub}}</b></option>
                      </select>
                      </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group row">
                        <label class="col-md-3 col-form-label" for="text-input">Material</label>
                        <div class="col-md-8">
                          <select [(ngModel)]="reportModel.material" class="form-control">
                            <option *ngFor="let row of dataListMaterial" [value]='row.material_id'><b>{{row.material_name}}</b></option>
                          </select>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-md-3 col-form-label" for="text-input">Exclude Material</label>
                        <div class="col-md-8">
                        <select [(ngModel)]="reportModel.exclude_material" class="form-control">
                            <option *ngFor="let row of dataListMaterial" [value]='row.material_id'><b>{{row.material_name}}</b></option>
                        </select>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-md-3 col-form-label" for="text-input">Tipe Perjalanan</label>
                        <div class="col-md-8">
                        <select [(ngModel)]="reportModel.trip_type" class="form-control">
                            <option *ngFor="let row of dataListMasterCode" [value]='row.id'><b>{{row.text}}</b></option>
                        </select>
                        </div>
                    </div>
                </div>                
            </div> 
            <br>
            <br>
            <div style="text-align: center">
                <button (click)="clean()" type="button" class="btn btn-warning" >&nbsp;<i class="fas fa-broom"></i>&nbsp;<b>Clear Form</b>&nbsp;</button>&nbsp;&nbsp;&nbsp;
                <button (click)="printTravelCostRekap()" type="button" class="btn btn-primary" >&nbsp;&nbsp;&nbsp;&nbsp;<i class="fa fa-print"></i>&nbsp;&nbsp;<b>Print</b>&nbsp;&nbsp;&nbsp;&nbsp;</button>&nbsp;&nbsp;&nbsp;
                <button (click)="exportExcel()" type="button" class="btn btn-primary" >&nbsp;&nbsp;&nbsp;&nbsp;<i class="fa fa-file-excel"></i>&nbsp;<b>Export Excel</b>&nbsp;</button>
            </div>
            <br>
        </div>
    </div>
  </section>

  <div bsModal #ModalSignature="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog" style="max-width: 50%" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title">
          </div>
          <button type="button" class="close" (click)="ModalSignature.hide()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-12"> 
                        <div class="form-group row">
                            <label class="col-md-4 col-form-label" for="text-input">Disetujui</label>
                            <div class="col-md-8">
                              <select  [(ngModel)]="reportModel.disetujui" class="form-control">
                                <option *ngFor="let row of dataSignature" [value]='row.name'><b>{{row.name}}</b></option>
                             </select>   
                            </div>
                        </div> 
                        <div class="form-group row">
                          <label class="col-md-4 col-form-label" for="text-input">Diketahui Oleh</label>
                          <div class="col-md-8">
                            <select  [(ngModel)]="reportModel.diketahui" class="form-control">
                              <option *ngFor="let row of dataSignature" [value]='row.name'><b>{{row.name}}</b></option>
                           </select>   
                          </div>
                      </div> 
                      <div class="form-group row">
                        <label class="col-md-4 col-form-label" for="text-input">Dibuat Oleh</label>
                        <div class="col-md-8">
                          <select  [(ngModel)]="reportModel.dibuat" class="form-control">
                            <option *ngFor="let row of dataSignature" [value]='row.name'><b>{{row.name}}</b></option>
                         </select>   
                        </div>
                     </div> 
                    </div> 
                  </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" (click)="printTravelCost()"><i class="fas fa-print"></i>&nbsp;&nbsp;&nbsp;<b>Cetak</b>&nbsp;&nbsp;&nbsp;</button>  
      </div>
      </div>
    </div>
  </div>
  
  <button #ModalSignatureShow hidden (click)="ModalSignature.show()"></button>
  
  <ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
  >
  <p style="font-size: 20px; color: white">Loading...</p>
  </ngx-spinner>