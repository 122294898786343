<!-- <section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>Blank Page</h1>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Home</a></li>
                    <li class="breadcrumb-item active">Blank Page</li>
                </ol>
            </div>
        </div>
    </div>
</section> -->

<!-- Main content -->
<section class="content">

    <div class="card">
      <div class="card-header">
      <strong>List FPKPS</strong>
      </div>
      <div class="card-body">
    <table datatable  [dtOptions]="dtOptions" class="table-striped">
      <thead>
        <tr>
          <th>ID</th>
          <th>First name</th>
          <th>Last name</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>1</td>
          <td>Foo</td>
          <td>Bar</td>
        </tr>
        <tr>
          <td>2</td>
          <td>Someone</td>
          <td>Youknow</td>
        </tr>
        <tr>
          <td>3</td>
          <td>Iamout</td>
          <td>Ofinspiration</td>
        </tr>
        <tr>
          <td>4</td>
          <td>Yoda</td>
          <td>Skywalker</td>
        </tr>
        <tr>
          <td>5</td>
          <td>Patrick</td>
          <td>Dupont</td>
        </tr>
        <tr>
          <td>6</td>
          <td>Barack</td>
          <td>Obama</td>
        </tr>
        <tr>
          <td>7</td>
          <td>François</td>
          <td>Holland</td>
        </tr>
        <tr>
          <td>8</td>
          <td>Michel</td>
          <td>Popo</td>
        </tr>
        <tr>
          <td>9</td>
          <td>Chuck</td>
          <td>Norris</td>
        </tr>
        <tr>
          <td>10</td>
          <td>Simon</td>
          <td>Robin</td>
        </tr>
        <tr>
          <td>11</td>
          <td>Louis</td>
          <td>Lin</td>
        </tr>
        <tr>
          <td>12</td>
          <td>Zelda</td>
          <td>Link</td>
        </tr>
      </tbody>
      <tfoot>
          <tr>
          <th><input type="text" placeholder="Search ID" name="search-id"/></th>
          <th><input type="text" placeholder="Search first name" name="search-first-name"/></th>
          <th><input type="text" placeholder="Search last name" name="search-last-name"/></th>
          </tr>
      </tfoot>

    </table>
  </div>
  </div>

      


      <div id="print-section">
        Put here your html stuff that you want to print...
    </div>
    
    <button printTitle="Print Title" printSectionId="print-section" ngxPrint>
      Print
    </button>







    <!-- /.card -->
</section>
<!-- /.content -->
