import { AfterViewInit, Component, OnInit, ViewChild,Input, ChangeDetectorRef, ElementRef  } from '@angular/core';
import { TransportService} from '../../../services/api.service';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { AccClosing } from '@/model/accClosing';
import { MasterCode } from '@/model/masterCode';
import moment from 'moment';
import { User } from '@/model/user';
import { Role } from '@/model/role';
import { MasterAuth, MasterMenu } from '@/model/masterMenu';
import { AuthMenu, MasterSubMenu } from '@/model/masterSubMenu';
import { Guid } from 'guid-typescript';
import { Approvals, BapbDetail, NpbDetail, SpbDetail, SpkService, SpkServiceDetail } from '@/model/spkService';
import { ListTruck } from '@/model/listTruck';
import { Driver } from '@/model/driver';
import { UserAuthNew } from '@/model/respDto';

@Component({
  selector: 'app-list-approval-service',
  templateUrl: './list-approval-service.component.html',
  styleUrls: ['./list-approval-service.component.scss']
})
export class ListApprovalServiceComponent implements OnInit, AfterViewInit {
  @ViewChild('modalSimpanClose') modalSimpanClose: ElementRef;
  @ViewChild('modalProcessClose') modalProcessClose: ElementRef;
  @ViewChild('modalApproveClose') modalApproveClose: ElementRef;
  @ViewChild('modalNpbShow') modalNpbShow: ElementRef;
  @ViewChild('modalBapbShow') modalBapbShow: ElementRef;
  @ViewChild('modalSpbShow') modalSpbShow: ElementRef;
  @ViewChild('modalSpkShow') modalSpkShow: ElementRef;
  dataListApproval: Approvals[] = [];
  listBapbDetail: BapbDetail[] = [];
  listNpbDetail: NpbDetail[] = [];
  dtOptionsAcc: any = {};
  dtOptionsDetail: any = {};
  dtTriggerDetail = new Subject();
  dtOptionsDetailSPB: any = {};
  dtTriggerDetailSPB = new Subject();
  dtOptionsDetailNPB: any = {};
  dtTriggerDetailNPB = new Subject();
  dtOptionsDetailBAPB: any = {};
  dtTriggerDetailBAPB = new Subject();
  dtTriggerAcc = new Subject();
  dataListSPK: SpkService[] = [];
  docNo: string;
  dtOptionsTruck: any = {};
  dtTriggerTruck = new Subject();
  dtOptionsDriver: any = {};
  dtTriggerDriver = new Subject();
  listRole: Role[] = [];
  listMenu: MasterMenu[] = [];
  listSubMenu: MasterSubMenu[] = [];
  listMasterAuth: MasterAuth[] = [];
  listSpkDetail: SpkServiceDetail[] = [];
  listSpkDetailDelete: SpkServiceDetail[] = [];
  dataTruck: ListTruck[] = [];
  dataDriver: Driver[] = [];
  listSpbDetail: SpbDetail[] = [];
  public dt: any;
  public cb: any;
  public dtTruck: any;
  public cbTruck: any;
  public dtDriver: any;
  public cbDriver: any;
  public update : boolean;
  public btnApproval : boolean = true;
  public btnProcess : boolean = true;
  locationName: string;
  authAccessPlb: UserAuthNew[] =[];
  menu: Guid;
  @Input() listApps = { roleName: null}
  @Input() spkNo = { startDate: null}
  @Input() spkDetailModel = {remark: null}
  @Input() approveModel = { ApprovalId: null, Approval: null, Remark: null}
  @Input() proccesModel = { ApprovalId: null, SpkId: null, SpkNo: null, TruckNo: null, DamageType: null, Workshop: null, RemarkProcess: null, Approval: null}
  @Input() spkModel = { SpkId: null, SpkNo: null, TruckId: null, DriverId: null, TruckNo: null, DriverName: null
                        , OilChange: null, StartDate: null, EndDate: null, spkDetail: new Array(), spkDetailDelete: new Array()}
  @Input() bapbModel = { BapbId: null, SpkId: null, BapbNo: null,  SpkNo: null, Date: null, Remark: null}
  @Input() npbModel = { NpbId: null, SpkId: null, NpbNo: null,  SpkNo: null, Date: null, Remark: null}
  @Input() spbModel = { SpbId: null, SpkId: null, SpbNo: null,  SpkNo: null, Date: null, Remark: null}
  constructor( public service: TransportService, private router: Router, private spinner: NgxSpinnerService, private changeDetectorRef: ChangeDetectorRef) {
  }

    ngOnInit(): void {
      this.getListApprovals();
      this.userAccess();

      this.dtOptionsAcc = {
        //retrieve: true,
        pageLength: 100,
        ordering : false,
        // scrollX: true,
        // scrollCollapse: true,
        searching : true,
        columnDefs: [
          { width: 300, targets: [0]},
          { width: 200, targets: [1,2,3,4,5,6,7,8]}
        ],
      };

      this.dtOptionsDetail = {
        retrieve: true,
        ordering : false,
        searching : false,
        columnDefs: [
          { width: 80, targets: [0,1,2,3,4] }
        ],
      };

      this.dtOptionsDetailNPB = {
        retrieve: true,
        ordering : false,
        searching : false,
        columnDefs: [
          { width: 80, targets: [0,1,2,3,4,5,6] }
        ],
      };

      this.dtOptionsDetailBAPB = {
        retrieve: true,
        ordering : false,
        searching : false,
        columnDefs: [
          { width: 80, targets: [0,1,2,3,4,5,6,7] }
        ],
      };
      
      this.dtOptionsDetailSPB = {
        retrieve: true,
        ordering : false,
        searching : false,
        columnDefs: [
          { width: 80, targets: [0,1,2,3,4,5,6,7] }
        ],
      };
    }   

    userAccess(){
      this.locationName = localStorage.getItem("locationname");
      const menuUser = localStorage.getItem("menu");
      if (menuUser != "All"){
          this.btnApproval = false;
          this.btnProcess = false;

            this.authAccessPlb = JSON.parse(localStorage.getItem("authAccessPlb"));
            var a = this.authAccessPlb.filter(item => item.subMenu  == "approval-service").length;
            if (a == 0){
              return this.router.navigate(["/home"]);
            }
            
            this.authAccessPlb.filter(item => item.subMenu == "approval-service").forEach(i => {
                if(i.auth == "PROCESS"){
                  this.btnProcess = true;
                }
                if(i.auth == "APPROVAL"){
                  this.btnApproval = true;
                }        
            });
          }
      }
      
    ngAfterViewInit(): void {
      this.dtTriggerAcc.next(this.dtOptionsAcc)
      this.dtTriggerDetail.next(this.dtOptionsDetail);
      this.dtTriggerDetailBAPB.next(this.dtOptionsDetailBAPB);
      this.dtTriggerDetailNPB.next(this.dtOptionsDetailNPB);
      this.dtTriggerDetailSPB.next(this.dtOptionsDetailSPB);
    }
  
    ngOnDestroy(): void {
      //this.dtTriggerAcc.unsubscribe();
     this.dtTriggerDetail.unsubscribe();
     this.dtTriggerDetailBAPB.unsubscribe();
     this.dtTriggerDetailNPB.unsubscribe();
     this.dtTriggerDetailSPB.unsubscribe();
    }


    getListApprovals(){

      this.listApps.roleName = localStorage.getItem("rolename");
      this.service.getListApprovals(this.listApps).subscribe(resp => {
        this.dataListApproval = resp;
       });
  }


  messageWarning(text: string){
    Swal.fire({
      title: ""+text,
      type: 'warning',
      confirmButtonText: '      Ok!     '
    });
    return;
  }

  processSPK(text: string){
    if (text == "APPROVED" && (this.proccesModel.DamageType == null || this.proccesModel.Workshop == null)){
      return Swal.fire({
        title: "Daya Belum Lengkap!",
        type: 'warning',
        confirmButtonText: '      Ok!     '
      });
    }
    this.proccesModel.Approval = text;
    Swal.fire({
      title: text+' SPK?',
      type: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '      Yes     '
      }).then((result) => {
        if (result.value) {
          this.service.processSpkService(this.proccesModel).subscribe( resp => {
            this.spinner.hide();
            if(resp.succes == false){
              Swal.fire({
                title: ""+resp.text,
                type: 'warning',
                confirmButtonText: '      Ok!     '
              });
            }
            else{
              Swal.fire({
                title: ""+resp.text,
                type: 'success',
                confirmButtonText: '      Ok!     '
              }).then((result) => {
                if (result.value) { 
                  this.getListApprovals();
                }              
              });
              this.modalProcessClose.nativeElement.click();
            }
          })
        }
    });
  }

  onselectAprove(data: any){
    this.approveModel.ApprovalId = data.id;
    this.docNo = data.documentNumber;
  }

  approve(text: string){
    this.approveModel.Approval = text;
    console.log("text : "+text)
    Swal.fire({
      title: text+' SPK?',
      type: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '      Yes     '
      }).then((result) => {
        if (result.value) {
          this.service.approveService(this.approveModel).subscribe( resp => {
            this.spinner.hide();
            if(resp.succes == false){
              Swal.fire({
                title: ""+resp.text,
                type: 'warning',
                confirmButtonText: '      Ok!     '
              });
            }
            else{
              Swal.fire({
                title: ""+resp.text,
                type: 'success',
                confirmButtonText: '      Ok!     '
              }).then((result) => {
                if (result.value) { 
                 this.getListApprovals();
                }              
              });
              this.modalApproveClose.nativeElement.click();
            }
          })
        }
    });
  }


  onSelectProcess(spk: any){
    this.docNo = spk.documentNumber;
    if (spk.type == "SPK"){
      this.proccesModel.ApprovalId = spk.id;
      this.service.getSPKServiceById(spk.documentId).subscribe(data => {        
      this.proccesModel.SpkId = data.spkId;
      this.proccesModel.SpkNo = data.spkNo;
      this.proccesModel.TruckNo = data.truckNo;
      });
    }
  }

  onSelectDetail(data: any){
    
    if (data.type == "SPK"){
      this.onSelectDetailSPK(data);
    }
    if (data.type == "NPB"){
      this.onSelectDetailNPB(data);
    }
    if (data.type == "BAPB"){
      this.onSelectDetailBapb(data);
    }
    if (data.type == "SPB"){
      this.onSelectDetailSPB(data);
    }
  }

  onSelectDetailBapb(data: any){
    this.service.getListBAPBDetail(data.documentId).subscribe(resp => {
      this.listBapbDetail = resp;
    });
    this.service.getBAPBId(data.documentId).subscribe(resp => {
      this.bapbModel.BapbId = resp.bapbId;
      this.bapbModel.BapbNo = resp.bapbNo;
      this.bapbModel.SpkId = resp.spkId;
      this.bapbModel.SpkNo = resp.spkNo;
      this.bapbModel.Date = resp.date;
      this.bapbModel.Remark = resp.remark;
    })
    this.modalBapbShow.nativeElement.click();
  }

  onSelectDetailNPB(data: any){
    this.service.getListNPBDetail(data.documentId).subscribe(resp => {
      this.listNpbDetail = resp;
    });
    this.service.getNPBId(data.documentId).subscribe(resp => {
      this.npbModel.NpbId = resp.npbId;
      this.npbModel.NpbNo = resp.npbNo;
      this.npbModel.SpkId = resp.spkId;
      this.npbModel.SpkNo = resp.spkNo;
      this.npbModel.Date = resp.date;
      this.npbModel.Remark = resp.remark;
    })
    this.modalNpbShow.nativeElement.click();
  }

  onSelectDetailSPB(data: any){
    this.service.getListSPBDetail(data.documentId).subscribe(resp => {
      this.listSpbDetail = resp;
    });
    this.service.getSPBId(data.documentId).subscribe(resp => {
      this.spbModel.SpbId = resp.spbId;
      this.spbModel.SpbNo = resp.spbNo;
      this.spbModel.SpkId = resp.spkId;
      this.spbModel.SpkNo = resp.spkNo;
      this.spbModel.Date = resp.date;
      this.spbModel.Remark = resp.remark;
    })
    this.modalSpbShow.nativeElement.click();
  }

  onSelectDetailSPK(spk: any){
      this.service.getSPKServiceById(spk.documentId).subscribe(data => {
        this.spkModel.SpkId = data.spkId;
        this.spkModel.SpkNo = data.spkNo;
        this.spkModel.TruckId = data.truckId;
        this.spkModel.DriverId = data.driverId;
        this.spkModel.TruckNo = data.truckNo;
        this.spkModel.DriverName = data.driverName;
        this.spkModel.OilChange = data.oilChange;
        this.spkModel.StartDate = data.startDate;
        this.spkModel.EndDate = data.endDate;
      });
      this.service.getSPKServiceDetailById(spk.documentId).subscribe(resp => {
        this.listSpkDetail = resp;
      });
      
    this.modalSpkShow.nativeElement.click();
  }
  

}







