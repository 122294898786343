<section class="content"> 

<div class="card" style="width: 40%;">
    <div class="card-header">
        <strong>Ubah Password</strong>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-12"> 
            <div class="form-group row">
                <label class="col-md-3 col-form-label" for="text-input">Username</label>
                <div class="col-md-8">
                    <input [(ngModel)]="user.user_name" disabled type="text" class="form-control">
                </div> 
            </div>
        </div>
        <div class="col-md-12"> 
            <div class="form-group row">
                <label class="col-md-3 col-form-label" for="text-input">Password Lama</label>
                <div class="col-md-8">
                    <input [(ngModel)]="userModel.oldPassword" type="text" class="form-control">
                </div> 
            </div>
        </div>
        <div class="col-md-12"> 
            <div class="form-group row">
                <label class="col-md-3 col-form-label" for="text-input">Password Baru</label>
                <div class="col-md-8">
                    <input [(ngModel)]="userModel.newPassword" type="text" class="form-control">
                </div> 
            </div>
        </div>
    </div>
    </div>
    <div class="card-footer">
        <button type="button" class="btn btn-primary" style="float: right;" (click)="UpdatePassword()"><i class="fa fa-save"></i>&nbsp;&nbsp;<b>Simpan</b>&nbsp;&nbsp;</button>
    </div>
  </div>


  </section>