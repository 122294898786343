import { AfterViewInit, Component, OnInit, ViewChild,Input  } from '@angular/core';
import { DataTableDirective } from 'angular-datatables'
import { TransportService} from '../../..//services/api.service';
import { Material } from '../../../model/material';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { Guid } from 'guid-typescript'
import moment from 'moment';
import { Router } from '@angular/router';
import { AppService } from '@services/app.service';
import { AuthMenu } from '@/model/masterSubMenu';

@Component({
  selector: 'app-master-material',
  templateUrl: './master-material.component.html',
  styleUrls: ['./master-material.component.scss']
})
export class MasterMaterialComponent implements OnInit, AfterViewInit {
  @ViewChild('modalSaveClose') modalClose;
  @ViewChild('ModalMaterialrShow') ModalMaterialrShow;
  @ViewChild('ModalMaterialrHide') ModalMaterialrHide;  
  @ViewChild(DataTableDirective, {static: false})
  datatableElement: DataTableDirective;
  dtOptions: any = {};
  public material: Material;
  dataMaterial: Material[] = [];
  printDataMaterial: Material[] = [];
  authAccessPlb: AuthMenu[] = [];
  dtTrigger = new Subject();
  @Input() materialModel = { id : null, code: null, name: null, price: null, uom: null, type: null};
  action: string;
  public dt: any;
  public cb: any;
  public modalTableMaterial: boolean=false;
  test: string;

  constructor( public service: TransportService,  private spinner: NgxSpinnerService, private router: Router,  private appServ : AppService) {
  }


  userAcces(){
    const menuUser = localStorage.getItem("menu");
    if (menuUser != "All"){
      const locationName = localStorage.getItem('locationname');
      if (locationName != "Palembang"){
        const subMenu = localStorage.getItem("acces_menu");
        let arrayMenu =  subMenu.split(",");
        if (arrayMenu.includes("material") == false){
          this.router.navigate(["/home"]);
        }
      }
      else{
        this.authAccessPlb = JSON.parse(localStorage.getItem("authAccessPlb"));
        var a = this.authAccessPlb.filter(item => item.subMenu  == "material").length;
        if (a == 0){
          return this.router.navigate(["/home"]);
        }
      }

    }
  }

    ngOnInit(): void {
      this.userAcces();
      if (this.router.url.includes("material")){
        
        this.modalTableMaterial = false;
        this.dtOptions = {
          responsive:true,
          pagingType: 'full_numbers',
          pageLength: 7,
          serverSide: true,
          processing: true,
          searching : false,
          ordering : false,
          ajax: (dataTablesParameters: any, callback) => {
            this.dt = dataTablesParameters; this.cb = callback;
            this.getDataMaterial(dataTablesParameters, callback, "", "",true);
          },
          columnDefs: [
            { width: 120, targets: [0,1,3,4,5,6,7,8,9] },
            { width: 200, targets: 2 },
          ]
        };
      } 
      else{
        this.modalTableMaterial = true;
        this.dtOptions = {
          pageLength: 5,
          scrollX: true,
          scrollCollapse: true,
          serverSide: true,
          processing: true,
          searching : false,
          ordering : false,
          ajax: (dataTablesParameters: any, callback) => {
            this.dt = dataTablesParameters; this.cb = callback;
            this.getDataMaterial(dataTablesParameters, callback, $('#codesearch').val().toString(), $('#namesearch').val().toString(),true);
          },
          columnDefs: [
            { width: 100, targets: 0 },
            { width: 200, targets: 2 },
          ]
        };
      }     
      
      const that = this;
      $('.searchInput').on("keyup", function(e) {
        var key = e.which;
        if (key == 13) // the enter key ascii code
        {
          that.getDataMaterial(that.dt, that.cb, $('#codesearch').val().toString(), $('#namesearch').val().toString(),false);
        }
      });  
      
    }


    getDataMaterial(dataTablesParameters, callback, code: string, name: string, padging : boolean){ 
      var page = 1;
      if (padging == true){
        page = parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length) + 1;
      }
      this.service.getDataMaterialTest(dataTablesParameters, page, dataTablesParameters.length, code, name)
      .subscribe(resp => { 
          this.dataMaterial = resp.data;
          callback({
            recordsTotal: resp.totalCount,
            recordsFiltered: resp.totalCount,
            data:  []
            //  data:  resp.data
          });
      });
    }

    

    ngAfterViewInit(): void {
      this.dtTrigger.next(this.dtOptions);
    }
  
    // ngOnDestroy(): void {
    //   this.dtTrigger.unsubscribe();
    // }
  
    // rerender(): void {
    //   this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
    //     dtInstance.destroy();
    //     this.dtTrigger.next(0);
    //   });
    // }

    onSelectMaterial(material: any) {
      this.action = "Update";
      this.materialModel.id = material.material_id;
      this.materialModel.code = material.material_code;
      this.materialModel.name= material.material_name;
      this.materialModel.type= material.do_print_type;
      this.materialModel.price= material.material_price;
      this.materialModel.uom= material.uom;
      this.service.onSelectMaterial(material);
    }
  
  saveDataMaterial(){
    if (this.materialModel.code == null || this.materialModel.name == null || this.materialModel.price == null ||
      this.materialModel.type == null || this.materialModel.uom == null){
        return Swal.fire({
          type: 'warning',
          text: 'Data Belum Lengkap!',
          confirmButtonText: 'ok!'
        });
      }
    this.spinner.show();
    this.service.addMaterial(this.materialModel).subscribe(material => {
      this.spinner.hide();
      Swal.fire({
        type: 'success',
        confirmButtonText: '      Succes!     '
      }).then((result) => {
        if (result.value) { 
          this.ModalMaterialrHide.nativeElement.click();
          this.getDataMaterial(this.dt, this.cb, "", "",true);
        }
      
      }, (error) => {
        //; 
      })
    });
    }

  deleteMaterial(material: any){
    Swal.fire({
      title: 'Delete Data?',
      // text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '      Yes     '
    }).then((result) => {
      if (result.value) {
        this.spinner.show();
        this.service.DeleteMaterial(material.material_id).subscribe(material => { 
          this.spinner.hide();
          Swal.fire({
            type: 'success',
            confirmButtonText: '      Succes!     '
          }).then((result) => {
            if (result.value) { 
              this.getDataMaterial(this.dt, this.cb, "", "",true);
            }
          
          }, (error) => {
            //; 
          })
        });
      }
    
    }, (error) => {
      //; 
    });
  }

  
  clearFormMaterial(){
    this.action = "Input";
    this.materialModel.id = null;
    this.materialModel.code = null;
    this.materialModel.name = null;
    this.materialModel.price = null;
    this.materialModel.type = null;
    this.materialModel.uom = null;
  }

  exportAsXLSX():void {
    this.service.getDataMaterialTest(this.dt, 1, 1000000,  $('#codesearch').val().toString(), $('#namesearch').val().toString())
    .subscribe(resp => { 
        this.printDataMaterial = resp.data;
        this.appServ.exportAsExcelFile(this.printDataMaterial, 'Master_Material');
    });
 }

    

      
}