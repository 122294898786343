import { Guid } from "guid-typescript";

export class DebitNote {
    debitNoteId : Guid;
    receiptNo : string;
    receiptDate : string;
    customer : string;
    address : string;
    description : string;
    route : string;
    sPK : Date;
    dO : number;
    amount : number;
    truckNumber : number;
    qty : number;
    oA : string;
    type : string;
    printDate : string;
    createdDate : string;
    createdName : string;
    updatedDate : string;
    updatedName : string;
}

export class UploadDebitNote {
    debitNoteId : Guid;
    receiptNo : string;
    receiptDate : string;
    customer : string;
    address : string;
    description : string;
    route : string;
    spk : Date;
    do : number;
    amount : number;
    truckNumber : number;
    qty : number;
    oa : number;
    type : string;
    printDate : string;
    createdDate : string;
    createdName : string;
    updatedDate : string;
    updatedName : string;
    error_message: string;
    error: Boolean;
    selected: Boolean;
}

export class DebitNoteDetail {
    detailId : Guid;
    debitNoteId : Guid;
    truckNumber : string;
    qty : string;
    oa : string;
    amount : string;
}



export class JournalDebitNote {
    journalNo : string;
    prj : string;
    date : Date;
    remark : string;
    doNo : string;
    ccy : string;
    rate : number;
    credit : number;
    debit : number;
    gl : string;
    io : string;
    custSup : string;
}

export class JournalDebitNoteExport {
    No_Bukti : string;
    PRJ : string;
    Tanggal : string;
    Keterangan : string;
    CCY : string;
    RATE : number;
    Credit : number;
    Debet : number;
    GL : string;
    IO : string;
    Cust_Supp : string;
}