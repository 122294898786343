import { AfterViewInit, Component, OnInit } from '@angular/core';
import { DataTableDirective } from 'angular-datatables'
import { TransportService} from '../../../services/api.service';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { DriverShrinkageRecapVm } from '@/model/driverShrinkageRecapVm';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-travel-cost-report',
  templateUrl: './travel-cost-report.component.html',
  styleUrls: ['./travel-cost-report.component.scss']
})

export class TravelCostReportComponent  implements OnInit, AfterViewInit {
  datatabdtOptionsnt: DataTableDirective;
  dtOptionsPay: any = {};
  dtTriggerPay = new Subject();  
  tolerance: number;
  public dt: any;
  public cb: any;
  public from : Date;
  public to : Date;
  amountDebt: number;
  constructor( public service: TransportService, private spinner: NgxSpinnerService) {
  }

    ngOnInit(): void {

    }   

    public expanded = false;

    showCheckboxes() {
     
     var checkboxes = document.getElementById("checkboxes");
     if (!this.expanded) {
       checkboxes.style.display = "block";
       this.expanded = true;
     } else {
       checkboxes.style.display = "none";
       this.expanded = false;
     }
   }
    
    ngAfterViewInit(): void {
      this.dtTriggerPay.next(0);
    }
  
    ngOnDestroy(): void {
    }


  messageWarning(text: string){
    Swal.fire({
      title: ""+text,
      type: 'warning',
      confirmButtonText: '      Ok!     '
    });
    return;
  }

  printTravelCostExcel(){
    this.spinner.show();
    let currentDate = new Date().toLocaleDateString();
    let name = "Report_Biaya_Perjalanan_"+currentDate+".xlsx";
    this.service.printReportTravelCostExcel(this.from, this.to).subscribe(resp =>{
      this.spinner.hide();
      const blob = new Blob([resp.body],
        { type: 'application/vnd.ms-excel' });
    const file = new File([blob], name,
        { type: 'application/vnd.ms-excel' });

      saveAs(file);
    })
  }

  printTravelCost(){
    this.spinner.show();
    let currentDate = new Date().toLocaleDateString();;
    let name = "ReportSusut_"+currentDate;
    this.service.printReportTravelCost(this.from, this.to).subscribe(resp =>{
      this.spinner.hide();
    //   const blob = new Blob([resp.body],
    //     { type: 'application/vnd.ms-excel' });
    // const file = new File([blob], name,
    //     { type: 'application/vnd.ms-excel' });

    //   saveAs(file);
    let blob:Blob=resp.body as Blob;
      let url = window.URL.createObjectURL(blob);
      window.open(url);
    })
  }

  
}





