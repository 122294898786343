import { AfterViewInit, Component, OnInit, ViewChild,Input, ChangeDetectorRef, ElementRef  } from '@angular/core';
import { DataTableDirective } from 'angular-datatables'
import { TransportService} from '../../../services/api.service';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Guid } from 'guid-typescript';
import { DebtListPay } from '@/model/debtListPay';
import { PremiDetailDto } from '@/model/premiDetailDto';
import { Driver } from '@/model/driver';
import { PremiDetail } from '@/model/premiDetail';

@Component({
  selector: 'app-driver-premi',
  templateUrl: './driver-premi.component.html',
  styleUrls: ['./driver-premi.component.scss']
})
export class DriverPremiComponent   implements OnInit, AfterViewInit {
  @ViewChild('ModalSimpanHide') ModalSimpanHide: ElementRef;  
  driver_id : Guid;
  driver_name : string;
  from: Date;
  to : Date;
  dataListDebtPay: DebtListPay[] = [];
  datatabdtOptionsnt: DataTableDirective;
  dataListPremi: PremiDetail[] = [];
  dataListPremiDetailDto: PremiDetailDto[] = [];
  dataDriver: Driver[] = [];
  dtOptionsDriver: any = {};
  dtTriggerDriver = new Subject();
  dtOptionsGenerate: any = {};
  dtTriggerGenerate = new Subject();  
  dtOptionsPremi: any = {};
  dtTriggerPremi = new Subject();  
  tolerance: number;
  public dt: any;
  public cb: any;
  amountDebt: number;
  @Input() listModel = {  id: null, trx_reff: null,type_name: null, driver_name: null, driver_license: null, amount: null , remaining: null}
  @Input() debtModel = {  driverdebt_id: null, amount: null}
  @Input() generateModel = {  driver_id: null, from: null, to: null}
  @Input() premiModel = {  periodFrom: null, periodTo: null, premiDetail: new Array()}
  constructor( public service: TransportService, private router: Router, private spinner: NgxSpinnerService, fb: FormBuilder, private changeDetectorRef: ChangeDetectorRef) {
  }

    ngOnInit(): void {
      this.dtOptionsGenerate = {  
        retrieve: true,
pageLength: 10,
        ordering : false,
        scrollX: true,
        scrollCollapse: true,
       // serverSide: true,
        processing: true,
        searching : false,
        // ajax: (dataTablesParameters: any, callback) => {
        //  this.dt = dataTablesParameters; this.cb = callback;
        //  this.getDebtPayList(dataTablesParameters, callback, "", "","","", true);
        // },
        columnDefs: [
          { width: 80, targets: [0,1,2,3,4,5,6,7,8,9,10,11,12]}
        ],
      };
      this.dtOptionsPremi = {  
        retrieve: true,
        pageLength: 10,
        ordering : false,
        scrollX: true,
        scrollCollapse: true,
        serverSide: true,
        processing: true,
        searching : false,
        ajax: (dataTablesParameters: any, callback) => {
         this.dt = dataTablesParameters; this.cb = callback;
         this.getPremiList(dataTablesParameters, callback, true);
        },
        columnDefs: [
          { width: 150, targets: [0,1,2,3,4,5,6,7,8,9,10]},
        ],
      };

      this.dtOptionsDriver = {
        retrieve: true,
        pageLength: 5,
        scrollX: true,
        scrollCollapse: true,
        serverSide: true,
        processing: true,
        searching : false,
        ajax: (dataTablesParameters: any, callback) => {
         this.dt = dataTablesParameters; this.cb = callback;
         this.getDataDriver(dataTablesParameters, callback, $('#driver_name').val().toString(), $('#driver_license').val().toString(), 
         $('#driver_license_type').val().toString(),$('#agama').val().toString(),$('#birthplace').val().toString(),
         $('#ktp_no').val().toString(),$('#tanggal_lahir').val().toString(),$('#sl_code').val().toString(),
         $('#sl_code2').val().toString(),$('#sl_code3').val().toString(),$('#address').val().toString(),
         $('#valid_to').val().toString(),$('#valid_to_contract').val().toString(),  true);
         },
        columnDefs: [
          { width: 150, targets: [0,2,3,4,5,6,7,8,9,10,12,13,14,15] },
          { width: 200, targets: [1] },
          { width: 300, targets: [11] },
        ],
      };

      const that = this;


       $('.searchInputD').on("keyup", function(e) {
        var key = e.which;
        if (key == 13) // the enter key ascii code
        {
          that.getDataDriver(that.dt, that.cb, $('#driver_name').val().toString(), $('#driver_license').val().toString(), 
          $('#driver_license_type').val().toString(),$('#agama').val().toString(),$('#birthplace').val().toString(),
          $('#ktp_no').val().toString(),$('#tanggal_lahir').val().toString(),$('#sl_code').val().toString(),
          $('#sl_code2').val().toString(),$('#sl_code3').val().toString(),$('#address').val().toString(),
          $('#valid_to').val().toString(),$('#valid_to_contract').val().toString(), false);
        }
      });
      
    }   
    
    ngAfterViewInit(): void {
      this.dtTriggerPremi.next(0);
    }
  
    ngOnDestroy(): void {
     this.dtTriggerPremi.unsubscribe();
    }

    showDriver(){
      this.dtTriggerDriver.next(0);
    }

    onSelectDriver(data: Driver){
      this.driver_id = data.driver_id;
      this.driver_name = data.driver_name;
    } 

    getDataDriver(dataTablesParameters, callback,  driver_name: string, driver_license: string, driver_license_type: string, 
      agama: string, birthplace: string, ktp_no: string, tanggal_lahir: string, sl_code: string, sl_code2: string,
      sl_code3: string, address: string, valid_to: string, valid_to_contract: string, padging : boolean){ 
      var page = 1;
      if (padging == true){
        page = parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length) + 1;
      }
      this.service.getListDriver(dataTablesParameters, page, dataTablesParameters.length, driver_name, driver_license, driver_license_type, agama,
        birthplace,ktp_no,tanggal_lahir, sl_code, sl_code2, sl_code3, address, valid_to, valid_to_contract, "true","false")
      .subscribe(resp => { 
          this.dataDriver = resp.data;
          callback({
            recordsTotal: resp.totalCount,
            recordsFiltered: resp.totalCount,
            data:  []
          });
      });
    }

    savePremi(){
      if(this.dataListPremiDetailDto.length < 1){
        Swal.fire({
          title: "Data Premi Kosong !",
          type: 'warning',
          confirmButtonText: '      Ok!     '
        });
        return;
      }
      this.premiModel.periodFrom = this.from;
      this.premiModel.periodTo = this.to;
      this.premiModel.premiDetail = this.dataListPremiDetailDto;
      Swal.fire({
        title: 'Simpan Data?',
        type: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: '      Yes     '
      }).then((result) => {
        if (result.value) {
             this.spinner.show();
             this.service.savePremi(this.premiModel).subscribe(resp => { 
                this.spinner.hide();
              if(resp.succes == false){
                Swal.fire({
                  title: ""+resp.text,
                  type: 'warning',
                  confirmButtonText: '      Ok!     '
                });
              }
              else{
                Swal.fire({
                  title: ""+resp.text,
                  type: 'success',
                  confirmButtonText: '      Ok!     '
                }).then((result) => {
                  if (result.value) { 
                    this.ModalSimpanHide.nativeElement.click();
                    this.getPremiList(this.dt, this.cb, true);
                  }              
                }, (error) => {
                  ; 
                })
              }
              });
        }
      });
    }

    setNull(){
      this.driver_name = null;
      this.from = null;
      this.to = null;
      this.dataListPremiDetailDto = [];
    }

    getGenerate(){
      if(this.driver_name == null || this.from == null || this.to == null){
        Swal.fire({
          title: "Data Belum Lengkap !",
          type: 'warning',
          confirmButtonText: '      Ok!     '
        });
        return;
      }
      this.spinner.show();
      this.generateModel.driver_id = this.driver_id;
      this.generateModel.from = this.from;
      this.generateModel.to = this.to;
      this.service.generatePremi(this.generateModel).subscribe(resp => {
        this.spinner.hide();
        if(resp.succes == false){
          Swal.fire({
            title: ""+resp.text,
            type: 'warning',
            confirmButtonText: '      Ok!     '
          });
        }
        else{
          this.dataListPremiDetailDto = [];
              for (let i = 0; i < resp.premiDetail.length; i++) {
                this.dataListPremiDetailDto.push({
                  driver : resp.premiDetail[i].driver,
                  driverName : resp.premiDetail[i].driverName,
                  truck : resp.premiDetail[i].truck,
                  truckName : resp.premiDetail[i].truckName,
                  tripType : resp.premiDetail[i].tripType,
                  tripTypeName : resp.premiDetail[i].tripTypeName,
                  tonSend : resp.premiDetail[i].tonSend,
                  tonReceive : resp.premiDetail[i].tonReceive,
                  totalTrip : resp.premiDetail[i].totalTrip,
                  totalKm : resp.premiDetail[i].totalKm,
                  totalRitase : resp.premiDetail[i].totalRitase,
                  premiTrip : resp.premiDetail[i].premiTrip,
                  premiKm : resp.premiDetail[i].premiKm,
                  premiRitase : resp.premiDetail[i].premiRitase,
                  total : resp.premiDetail[i].total
              });
            }
        }
      });
      this.dtTriggerGenerate.next(0);
    }

    getPremiList(dataTablesParameters, callback, padging: Boolean){
      var page = 1;
        if (padging == true){
          page = parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length) + 1;
        }

        this.service.getPremiList(dataTablesParameters, page, dataTablesParameters.length)
        .subscribe(resp => { 
            this.dataListPremi = resp.data;
            callback({
              recordsTotal: resp.totalCount,
              recordsFiltered: resp.totalCount,
              data:  []
            });
        });
   }

  messageWarning(text: string){
    Swal.fire({
      title: ""+text,
      type: 'warning',
      confirmButtonText: '      Ok!     '
    });
    return;
  }




  
}





