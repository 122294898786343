<section class="content">    
    <div class="card">
        <div class="card-header">
          <strong>List Report</strong>
          <div class="float-right">
          <a type="button" class="btn btn-sm btn-primary pull-right" (click)="clearInput();showCheck();ModalCreate.show()" data-toggle="modal">&nbsp;&nbsp;<b>Create Report</b>&nbsp;&nbsp;&nbsp;<i class="fa fa-pen"></i>&nbsp;&nbsp;&nbsp;</a>
          </div>
        </div>
        <div class="card-body">
            <table datatable [dtOptions]="dtOptionsListCustom" [dtTrigger]="dtTriggerListCustom" class="deep table-striped">
                <thead>
                  <tr>
                    <th>Action</th>
                    <th>Code</th>
                    <th>Name</th>
                    <th>Query</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of dataReport">    
                    <td>
                      <button (click)="editReport(row);ModalCreate.show()" title="Edit" class="btn btn-sm btn-primary"  data-toggle="modal"triggers="mouseenter:mouseleave"><i class="fa fa-pen"></i></button>&nbsp;
                      <button (click)="deleteData(row)" class="btn btn-sm btn-danger" title="Delete" triggers="mouseenter:mouseleave"><i class="fa fa-trash"></i></button>&nbsp;
                    </td>       
                    <td>{{row.code}}</td>
                    <td>{{row.name}}</td>
                    <td>{{row.query}}</td>
                </tr>
                </tbody>
                <tfoot>
                    <tr>
                      <th><input hidden class="searchInput"/></th>
                      <th><input hidden class="searchInput"/></th>
                      <th><input id="name" type="text" class="searchInput" placeholder="Name"/></th>
                      <th><input hidden class="searchInput"/></th>
                    </tr>
                  </tfoot>
              </table>
        </div>
    </div>

    <div bsModal #ModalCreate="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
        <div class="modal-dialog" style="max-width: 75%;" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <div class="modal-title">
              </div>
              <button type="button" class="close" (click)="ModalCreate.hide()" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-6"> 
                        <div class="form-group row">
                            <label class="col-md-3 col-form-label" for="text-input">Code</label>
                            <div class="col-md-8">
                                <input type="text" [(ngModel)]="reportModel.Code" class="form-control">
                            </div>
                        </div> 
                        <div class="form-group row">
                          <label class="col-md-3 col-form-label" for="text-input">Name</label>
                          <div class="col-md-8">
                              <input  type="text" [(ngModel)]="reportModel.Name" class="form-control">
                          </div>
                        </div> 
                        <div class="form-group row">
                          <label class="col-md-3 col-form-label" for="text-input">Primary Sort</label>
                          <div class="col-md-8">
                              <input  type="text" [(ngModel)]="reportModel.PrimarySort" class="form-control">
                          </div>
                        </div> 
                        <div class="form-group row">
                          <label class="col-md-3 col-form-label" for="text-input">Secondary Sort</label>
                          <div class="col-md-8">
                              <input  type="text" [(ngModel)]="reportModel.SecondarySort" class="form-control">
                          </div>
                        </div> 
                    </div> 
                    <div class="col-md-6">
                        <div class="form-group row">
                          <label class="col-md-2 col-form-label" for="text-input">Query</label>
                          <div class="col-md-8">
                              <textarea rows="8" type="text" [(ngModel)]="reportModel.Query" class="form-control"></textarea>
                          </div>
                          <div class="col-md-1">
                            <button class="btn btn-sm btn-primary" id="check"><i class="fa fa-recycle"></i></button>
                         </div>
                        </div> 
                    </div>
                  </div>
                </div>
              </div>
              <br>
              <div class="card">
                  <div class="card-body">
                    <div class="scroll">
                      <table datatable [dtOptions]="dtOptionsQuery" [dtTrigger]="dtTriggerQuery" class="table-striped" id="tableID">
                      </table>
                    </div>
                  </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" (click)="saveDataReport()" class="btn btn-primary"><i class="fa fa-print"></i>&nbsp;<b>Simpan</b>&nbsp;</button>
              <button type="button" #ModalCreateClose class="btn btn-secondary" (click)="ModalCreate.hide()">&nbsp;<b>Cancel</b>&nbsp;</button> 
          </div>
          </div>
        </div>
      </div>


</section>