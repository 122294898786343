
<!-- Main content -->
<section class="content">    
  <div class="card">
    <div class="card-header">
    <strong>List Tonnase</strong>
    <div class="float-right">
      <a type="button" class="btn btn-sm btn-warning pull-right" data-toggle="modal" (click)="exportAsXLSX()">&nbsp;&nbsp;&nbsp;<b>Export Data</b>&nbsp;&nbsp;&nbsp;&nbsp;<i class="fas fa-file-excel"></i>&nbsp;&nbsp;&nbsp;</a>&nbsp;&nbsp;&nbsp;&nbsp;
      <a type="button" class="btn btn-sm btn-primary pull-right" data-toggle="modal" (click)="ModalTonnase.show();clearFormTonnase()"><b>Tonnase&nbsp;&nbsp;&nbsp;&nbsp;</b><i class="fa fa-pen"></i>&nbsp;&nbsp;&nbsp;</a>
    </div>
  </div>
    <div class="card-body">
  <table datatable  [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"  class="table-striped">
    <thead>
      <tr>
        <th>Action</th>
        <th>Kode</th>
        <th>Tonnase</th>
        <th>Kuantitas</th>
        <th>UOM</th>
        <th>Dibuat Oleh</th>
        <th>Dibuat Pada</th>
        <th>Diubah Oleh</th>
        <th>Diubah Pada</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let row of dataTonnase">    
        <td><button class="btn btn-sm btn-danger" triggers="mouseenter:mouseleave" (click)="deleteTonnase(row);"><i class="fa fa-trash"></i></button>&nbsp;
          <button (click)="onSelectTonnase(row);ModalTonnase.show()" class="btn btn-sm btn-primary"  data-toggle="modal"triggers="mouseenter:mouseleave"><i class="fa fa-pen"></i></button>
        </td>
        <td>{{row.code}}</td>
        <td>{{row.name}}</td>
        <td>{{row.quantity}}</td>
        <td>{{row.uoms}}</td>
        <td>{{row.createdby}}</td>
        <td>{{row.created_at | slice:0:10}}</td>  
        <td>{{row.updatedby}}</td>  
        <td>{{row.updated_at | slice:0:10}}</td>       
      </tr>
    </tbody>
    <tfoot>
      <tr>
        <th><input hidden type="text" class="searchInput" placeholder="Action"/></th>
        <th><input id="codesearch" type="text" class="searchInput" placeholder="Search Kode" name="search-code"/></th>
        <th><input id="namesearch" type="text" class="searchInput" placeholder="Search Tonnase" name="search-tonnase"/></th>
        <th><input id="quantitysearch" type="text" class="searchInput" placeholder="Search Quantity" name="search-quantity"/></th>
        <th><input id="uomsearch" type="text" class="searchInput" placeholder="Search UOM" name="search-uom"/></th>
        <th><input hidden type="text" class="searchInput" placeholder="Search CreatedBy" name="search-createdby"/></th>
        <th><input hidden type="text" class="searchInput" placeholder="Search CreatedAt" name="search-createdat"/></th>
        <th><input hidden type="text" class="searchInput" placeholder="Search UpdateBy" name="search-updateby"/></th>
        <th><input hidden type="text" class="searchInput" placeholder="Search UpdateAt" name="search-updateat"/></th>
      </tr>
    </tfoot>

  </table>
</div>
</div>
</section>


<div  bsModal #ModalTonnase="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog  modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title"><strong>{{action}} Data Kendaraan</strong></div>
        <small><code style="color: black">&nbsp;&nbsp;*(semua kolom wajib di isi)</code></small>
        <button type="button" class="close" (click)="ModalTonnase.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="form-group row">
          <label class="col-md-3 col-form-label" for="text-input">Kode</label>
          <div class="col-md-9">
            <input [(ngModel)]="tonnaseModel.code" type="text" class="form-control">
        </div>
        </div> 
        <div class="form-group row">
          <label class="col-md-3 col-form-label" for="text-input">Nama</label>
          <div class="col-md-9">
            <input [(ngModel)]="tonnaseModel.name" type="text" class="form-control" >
        </div>
        </div> 
        <div class="form-group row">
          <label class="col-md-3 col-form-label" for="text-input">Kuantitas</label>
          <div class="col-md-9">
            <input [(ngModel)]="tonnaseModel.quantity" type="text" class="form-control" currencyMask [options]="{ prefix: '', thousands: '.', decimal: ',' ,precision: '0',align: 'left' }" >
        </div>
        </div> 
        <div class="form-group row">
          <label class="col-md-3 col-form-label" for="text-input">UOM</label>
          <div class="col-md-9">                      
            <select [(ngModel)]="tonnaseModel.uom" id="select" name="select" class="form-control">
              <option *ngFor="let row of dataMasterCode" [value]='row.id'><b>{{row.text}}</b></option>
            </select>
         </div>
        </div> 
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="ModalTonnase.hide()"><b>Close</b></button>
        <button type="button" class="btn btn-primary"  (click)="saveDataTonnase();ModalTonnase.hide();"><i class="fa fa-save"></i>&nbsp;&nbsp;<b>Save</b>&nbsp;&nbsp;</button>
      </div>
    </div>
  </div>
</div>

<ngx-spinner  bdColor = "rgba(248,248,248,0.95)" size = "large" color = "#262424" type = "ball-spin-fade" >
  <p style="font-size: 20px; color: white"></p>
</ngx-spinner>


