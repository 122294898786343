import { AfterViewInit, Component, OnInit, ViewChild,Input, ChangeDetectorRef, ElementRef  } from '@angular/core';
import { TransportService} from '../../../services/api.service';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { Guid } from 'guid-typescript'
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { Material } from '@/model/material';
import { MasterCode } from '@/model/masterCode';
import { LocationSettingSignature } from '@/model/locationSettingSignature';
import { saveAs } from 'file-saver';
import { SubLocation } from '@/model/masterMenu';


@Component({
  selector: 'app-report-form',
  templateUrl: './report-form.component.html',
  styleUrls: ['./report-form.component.scss']
})
export class ReportFormComponent implements OnInit, AfterViewInit {
  @ViewChild('ModalSignatureShow') ModalSignatureShow: ElementRef;
  dataListMaterial: Material[] = [];
  dataListMasterCode: MasterCode[] = [];
  dataSignature: LocationSettingSignature[] = []
  tipe: string;
  plb : boolean = true;
  locationSub: SubLocation[] = [];
  @Input() reportModel = {  from_date: null, to_date: null, material: null, exclude_material: null, trip_type: null, disetujui: null,
    diketahui: null,dibuat: null, location: null, sub_location : null}
  
  constructor( public service: TransportService, private router: Router, private spinner: NgxSpinnerService, fb: FormBuilder, private changeDetectorRef: ChangeDetectorRef) {
    
  }

    ngOnInit(): void {
      this.getDataMaterial();
      this.getListMasterCode();
      this.getSignature();
      this.tipe = "TravelCost";
      this.reportModel.location = localStorage.getItem('locationname');
      if(localStorage.getItem('locationname') != "Palembang"){
        this.plb = false;
      }
      this.service.getSubLocation().subscribe(resp => {
        this.locationSub = resp;
      }); 
    }   
    
    ngAfterViewInit(): void {
      
    }
  
    ngOnDestroy(): void {
      
    }

    getDataMaterial(){
      this.service.getListMaterial().subscribe(resp => {
        this.dataListMaterial = resp;
      })
    }

    getListMasterCode(){
      this.service.getListMasterCode("TripType").subscribe(resp => {
        this.dataListMasterCode = resp;
      })
    }

    clean(){
      this.reportModel = {  from_date: null, to_date: null, material: null, exclude_material: null, 
        trip_type: null, disetujui: null,diketahui: null,dibuat: null, location: null, sub_location: null}
    }

    printTravelCostRekap() {
      if(
        this.reportModel.from_date == null || this.reportModel.to_date == null
      )
      {
        Swal.fire({
          title: "Tanggal Belum Diisi !",
          type: 'warning',
          confirmButtonText: '      Ok!     '
        });
        return;
      }
      if(localStorage.getItem('locationname') != "Palembang"){
        this.reportModel.sub_location = "";
      }
        if(this.tipe == "Rekap"){
          this.spinner.show();
          this.reportModel.location = localStorage.getItem('locationname');
          this.service.printReportSpendTravelCostRekap(this.reportModel).subscribe(resp =>{
            this.spinner.hide();
            let blob:Blob=resp.body as Blob;
            let url = window.URL.createObjectURL(blob);
            window.open(url);
            //this.clean();
          });
        }
        else{
          this.ModalSignatureShow.nativeElement.click();
        }
        
    }

    printTravelCost() {
      this.spinner.show();
      if(localStorage.getItem('locationname') != "Palembang"){
        this.reportModel.sub_location = "";
      }
      this.reportModel.location = localStorage.getItem('locationname');
      this.service.printReportSpendTravelCost(this.reportModel).subscribe(resp =>{
        this.spinner.hide();
        let blob:Blob=resp.body as Blob;
        let url = window.URL.createObjectURL(blob);
        window.open(url);
        //this.clean();
      });
      this.reportModel.location = localStorage.getItem('locationname');
      this.service.printReportSpendTravelCostAdjusment(this.reportModel).subscribe(resp =>{
        let blob:Blob=resp.body as Blob;
        let url = window.URL.createObjectURL(blob);
        window.open(url);
        //this.clean();
      });
      this.reportModel.location = localStorage.getItem('locationname');
      this.service.printReportSpendTravelCostRetur(this.reportModel).subscribe(resp =>{
        let blob:Blob=resp.body as Blob;
        let url = window.URL.createObjectURL(blob);
        window.open(url);
        //this.clean();
      });
    }

    onSelectTipe(tipe: string){
      this.tipe = tipe;
    }

    getSignature(){
      this.service.getSignature().subscribe(resp => { 
              this.dataSignature = resp;
              this.reportModel.diketahui = resp[0].name;
              this.reportModel.dibuat = resp[0].name;
              this.reportModel.disetujui = resp[0].name;
          });
     }

     
  exportExcel(){
    if(this.reportModel.from_date == null || this.reportModel.to_date == null )
    {
      Swal.fire({
        title: "Tanggal Belum Diisi !",
        type: 'warning',
        confirmButtonText: '      Ok!     '
      });
      return;
    }
    if(this.tipe == "Rekap"){
      this.spinner.show();
      this.service.printAllTravelCostRekapExcel(this.reportModel).subscribe(resp =>{
        this.spinner.hide();
        
        let name = "Laporan_Rekap_Relalisai_Biaya_Perjalanan.xlsx";
        const blob = new Blob([resp.body],
          { type: 'application/vnd.ms-excel' });
      const file = new File([blob], name,
          { type: 'application/vnd.ms-excel' });
    
        saveAs(file);
      })
    }
    else{
      this.spinner.show();
      this.reportModel.location = localStorage.getItem('locationname');
      this.service.printAllTravelCostExcel(this.reportModel).subscribe(resp =>{
        this.spinner.hide();
        
        let name = "Laporan_Pengeluaran_Uang_Jalan.xlsx";
        const blob = new Blob([resp.body],
          { type: 'application/vnd.ms-excel' });
      const file = new File([blob], name,
          { type: 'application/vnd.ms-excel' });
    
        saveAs(file);
      })

    }

  }
   
}




