import {
  Component,
  OnInit,
  OnDestroy,
  Renderer2,
  HostBinding
} from '@angular/core';
import {UntypedFormGroup, UntypedFormControl, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {AppService} from '@services/app.service';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthenticatedResponse, loginModel } from '@/model/loginModel';
import { environment } from 'environments/environment.prod';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { DecodeToken } from '@modules/main/menu-sidebar/menu-sidebar.component';
import { JwtHelperService } from '@auth0/angular-jwt';
import { RespDtoToken } from '@/model/respDto';
import { TransportService } from '@services/api.service';
import { UserAuth } from '@/model/userAuth';
@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit, OnDestroy {
public user: any = null;
invalidLogin: boolean;
credentials: loginModel = {username:'', password:''};
private apiUrl = `${environment.apiUrl}/api`;
  public loginForm: UntypedFormGroup;
  public isAuthLoading = false;
  public isGoogleLoading = false;
  public isFacebookLoading = false;
  public location: string;
  tokenDecode : DecodeToken;
  constructor(
      private renderer: Renderer2,
      private toastr: ToastrService,
      private appService: AppService,
      private service: TransportService,
      private spinner: NgxSpinnerService, 
      private http: HttpClient,
      private router: Router,
      private jwtHelper: JwtHelperService
  ) {}

  doLogin(model: loginModel){
    const url = `${this.apiUrl}/auth/login`;
    return this.http.post<RespDtoToken>(url, model, {
                headers: new HttpHeaders({ "Content-Type": "application/json"})
            });    
    
  }

  getAuthAcces(username){
    const apiUrl = `${this.apiUrl}/auth/access?username=${username}`;
    this.http.post<UserAuth[]>(apiUrl, {
       headers: new HttpHeaders({ 'Content-Type': 'application/json' })
        }).subscribe(resp =>{
                localStorage.setItem("authAccess", JSON.stringify(resp));
    });
  }

  
  changeLocation(location: string) {
    const url = `${this.apiUrl}/auth/changelocation?location=${location}`;
    return this.http.post(url, {
        headers: new HttpHeaders({ "Content-Type": "application/json"})
    });
  }

  ngOnInit() {
      // this.renderer.addClass(
      //     document.querySelector('app-root'),
      //     'login-page'
      // );
      this.loginForm = new UntypedFormGroup({
          email: new UntypedFormControl(null, Validators.required),
          password: new UntypedFormControl(null, Validators.required)
      });
  }

  onSelectLocation(loc: string){
    this.location = loc;
  }

  loginByAuths({email, password}) {
    try {
        if(this.location == null || this.location == ""){
            Swal.fire({
                title: "Lokasi belum Dipilih!",
                type: 'warning',
                confirmButtonText: '      Ok!     '
                });
                return;
        }
        this.spinner.show();
        this.credentials.username = email;
        this.credentials.password = password;
        this.changeLocation(this.location).subscribe( resp =>{
            this.doLogin(this.credentials)
            .subscribe({
                next: (response: RespDtoToken) => {
                this.spinner.hide();
                if(response.succes == false){
                    Swal.fire({
                        title: response.text+"",
                        type: 'warning',
                        confirmButtonText: '      Ok!     '
                        });
                        return;
                }
                
                localStorage.setItem("authAccessPlb", JSON.stringify(response.listAuth));
                const token = response.token;
                localStorage.setItem("token", token); 
                this.tokenDecode = this.jwtHelper.decodeToken(localStorage.getItem("token"));
                localStorage.setItem("userid", this.tokenDecode.userid);
                localStorage.setItem("first_name", this.tokenDecode.firstname); 
                localStorage.setItem("username", this.tokenDecode.username); 
                localStorage.setItem("locationname", this.tokenDecode.locationname);
                localStorage.setItem("location", this.tokenDecode.location); 
                localStorage.setItem("menu", this.tokenDecode.menu); 
                localStorage.setItem("acces_menu", this.tokenDecode.acces_menu); 
                localStorage.setItem("rolename", this.tokenDecode.rolename); 
                localStorage.setItem("roleid", this.tokenDecode.roleid); 
                this.invalidLogin = false; 
                this.getAuthAcces(localStorage.getItem("username"));
                this.router.navigate(["/"]);
                
                
                },
                error: (err: HttpErrorResponse) => 
                Swal.fire({
                    title: "Error"+err.message,
                    type: 'warning',
                    confirmButtonText: '      Ok!     '
                    })
                // this.invalidLogin = true
            })
        })
        
        // this.getProfile();
        //await this.getProfile();
        this.router.navigate(['/']);
    } catch (error) {
        Swal.fire({
            title: "Username atau Password Salah!",
            type: 'warning',
            confirmButtonText: '      Ok!     '
          });
    }

    

}

  async loginByAuth() {
    
     this.spinner.show();
      if (this.loginForm.valid) {
          this.spinner.show();
          await this.loginByAuths(this.loginForm.value);
      } 
      else {
          this.toastr.error('Form is not valid!');
      }
      this.spinner.hide();
  }

  

  ngOnDestroy() {
      // this.renderer.removeClass(
      //     document.querySelector('app-root'),
      //     'login-page'
      // );
  }
}

