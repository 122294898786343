
<!-- <form>
    <div class="multiselect">
      <div class="selectBox" (click)="showCheckboxes()">
        <select>
          <option>Select an option</option>
        </select>
        <div class="overSelect"></div>
      </div>
      <div id="checkboxes">
        <label for="one">
          <input type="checkbox" id="one" />First checkbox</label>
        <label for="two">
          <input type="checkbox" id="two" />Second checkbox</label>
        <label for="three">
          <input type="checkbox" id="three" />Third checkbox</label>
      </div>
    </div>
</form> -->

<section class="content">    
    <div class="card">
      <div class="card-header">
        <strong>Cetak Performa Supir</strong>
      </div>
        <div class="card-body">
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group row">
                        <label class="col-md-3 col-form-label" for="text-input">Dari Tanggal</label>
                        <div class="col-md-8">
                            <input  type="date" [ngModel]="from | date:'yyyy-MM-dd'" (ngModelChange)="from = $event"  class="form-control">
                        </div>
                    </div> 
                    </div>
                    <div class="col-md-6">
                    <div class="form-group row">
                        <label class="col-md-3 col-form-label" for="text-input">Sampai Tanggal</label>
                        <div class="col-md-8">
                            <input  type="date" [ngModel]="to | date:'yyyy-MM-dd'" (ngModelChange)="to = $event"  class="form-control">
                        </div>
                    </div> 
                </div>
            </div> 
            <br>
            <br>
            <div style="text-align: center">
                <button type="button" class="btn btn-sm btn-primary" (click)="printDriverPerform()">&nbsp;<i class="fa fa-print"></i>&nbsp;&nbsp;&nbsp;&nbsp;<b>Export</b>&nbsp;&nbsp;&nbsp;&nbsp;</button>
            </div>
            <br>
        </div>
    </div>
  </section>
  
   
  
  <ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
  >
  <p style="font-size: 20px; color: white">Loading...</p>
  </ngx-spinner>