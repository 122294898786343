import { AfterViewInit, Component, OnInit } from '@angular/core';
import { TransportService} from '../../../services/api.service';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-driver-perform',
  templateUrl: './driver-perform.component.html',
  styleUrls: ['./driver-perform.component.scss']
})
export class DriverPerformComponent implements OnInit, AfterViewInit {
  public from : Date;
  public to : Date;
  amountDebt: number;
  location: string;
  constructor( public service: TransportService, private spinner: NgxSpinnerService) {
  }

    ngOnInit(): void {

    }   

    ngAfterViewInit(): void {
    }
  
    ngOnDestroy(): void {
    }


  messageWarning(text: string){
    Swal.fire({
      title: ""+text,
      type: 'warning',
      confirmButtonText: '      Ok!     '
    });
    return;
  }

  printDriverPerform(){
    this.spinner.show();
    this.location = localStorage.getItem('locationname');
    let currentDate = new Date().toLocaleDateString();;
    let name = "Report_Driver_Perform_"+currentDate+".xlsx";
    this.service.printReportPerformDriver(this.from, this.to, this.location).subscribe(resp =>{
      this.spinner.hide();
      const blob = new Blob([resp.body],
        { type: 'application/vnd.ms-excel' });
    const file = new File([blob], name,
        { type: 'application/vnd.ms-excel' });

      saveAs(file);
    })
  }


}



