
<section class="content">    
    <div class="card">
      <div class="card-header">
        <strong>Form Bagi DO Ekspedisi</strong>
      </div>
        <div class="card-body">
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group row">
                        <label class="col-md-3 col-form-label" for="text-input">No DO Kecil</label>
                        <input hidden disabled type="text" class="form-control">
                        <div class="col-md-3">
                            <input [(ngModel)]="doFrom" disabled type="text" class="form-control">
                        </div>
                        <div class="col-md-1">
                            <button class="btn btn-sm btn-primary" (click)="showDetail();ModalDetail.show()"  
                            data-toggle="modal" ><i class="fa fa-plus"></i></button>
                        </div>
                        <label class="col-md-1 col-form-label" for="text-input">s/d</label>
                        <div class="col-md-3">
                            <input [(ngModel)]="doTo" disabled type="text" class="form-control">
                        </div>
                        <div class="col-md-1">
                            <button class="btn btn-sm btn-primary" (click)="showDetail2();ModalDetail2.show()"  
                            data-toggle="modal" ><i class="fa fa-plus"></i></button>
                        </div>
                    </div> 
                    <div class="form-group row">
                        <label class="col-md-3 col-form-label" for="text-input">Ekspedisi</label>
                        <div class="col-md-8">
                            <input [(ngModel)]="ekspedisiName" disabled type="text" class="form-control">
                        </div>
                        <div class="col-md-1">
                            <button class="btn btn-sm btn-primary"  (click)="showEkspedisi();ModalEkspedisi.show()"
                            data-toggle="modal" ><i class="fa fa-plus"></i></button>
                        </div>
                    </div> 
                    <div class="form-group row">
                        <label class="col-md-3 col-form-label" for="text-input">Tanggal Bagi</label>
                        <div class="col-md-8">
                            <input [ngModel]="distModel.depart_date | date:'yyyy-MM-dd'" (ngModelChange)="distModel.depart_date = $event" type="date"  class="form-control">
                        </div>
                    </div> 
                </div>
                <div class="col-md-6">
                    <div class="form-group row">
                        <div class="col-md-1"></div>
                        <label class="col-md-3 col-form-label" for="text-input">OA Ekspedisi</label>
                        <div class="col-md-8">
                            <input [(ngModel)]="distModel.cost" type="text" currencyMask [options]="{ prefix: '', thousands: '.', decimal: ',' ,precision: '0',align: 'left' }" class="form-control">
                        </div>
                    </div> 
                    <div class="form-group row">
                        <div class="col-md-1"></div>
                        <label class="col-md-3 col-form-label" for="text-input">Tipe Pembayaran</label>
                        <div class="col-md-8">
                            <input [(ngModel)]="distModel.charge_type" disabled type="text" class="form-control">
                        </div>
                    </div> 
                </div>
            </div> 
            <br>
            <br>
            <div style="text-align: center">
                <button (click)="checkDo()" type="button" class="btn btn-warning">&nbsp;<i class="fa fa-recycle"></i>&nbsp;&nbsp;<b>Check</b>&nbsp;&nbsp;</button>&nbsp;&nbsp;&nbsp;&nbsp;
                <button [disabled]="!save" (click)="SaveData()" type="button" class="btn btn-primary">&nbsp;<i class="fa fa-save"></i>&nbsp;&nbsp;<b>Simpan</b>&nbsp;&nbsp;</button>
            </div>
            <br>
        </div>
    </div>
  </section>


  
  <div bsModal #ModalDetail="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title">
            <div class="row">
              <strong>Pilih DO</strong>
            </div>
          </div>
          <button #modalFrom type="button" class="close" (click)="ModalDetail.hide()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <table datatable [dtOptions]="dtOptionsDetailDo" [dtTrigger]="dtTriggerDetail" class="table-striped">
                <thead>
                  <tr>
                    <th>Action</th>
                    <th>No DO</th>
                    <th>No DO Besar</th>
                    <th>Tanggal Terbit</th>
                    <th>Status DO</th>
                    <th>Estimasi Berat</th>
                    <th>Estimasi Kemasan</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of listDoDetail">    
                    <td>
                      <button (click)="onSelectFrom(row)" title="Pilih DO" class="btn btn-sm btn-primary" triggers="mouseenter:mouseleave"><i class="fa fa-check"></i></button>
                    </td> 
                    <td>{{row.do_no}}</td>
                    <td>{{row.main_do_no}}</td>
                    <td>{{row.do_date | slice:0:10}}</td>
                    <td>{{row.status_text}}</td>
                    <td>{{row.est_ton}}</td>
                    <td>{{row.est_pack}}</td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <th><input hidden class="searchDo"/></th>
                    <th><input id="doNo" type="text" class="searchDo" placeholder="No DO" /></th>
                    <th><input id="mainDo" type="text" class="searchDo" placeholder="No DO Besar" /></th>
                    <th><input hidden class="searchDo"/></th>
                    <th><input hidden /></th>
                    <th><input hidden class="searchDo"/></th>
                    <th><input hidden class="searchDo"/></th>
                  </tr>
                </tfoot>
              </table>
        </div>
      </div>
    </div>
  </div>

  <div bsModal #ModalDetail2="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title">
            <div class="row">
              <strong>Pilih DO</strong>
            </div>
          </div>
          <button #modalTo type="button" class="close" (click)="ModalDetail2.hide()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <table datatable [dtOptions]="dtOptionsDetailDo2" [dtTrigger]="dtTriggerDetail2" class="table-striped">
                <thead>
                  <tr>
                    <th>Action</th>
                    <th>No DO</th>
                    <th>Tanggal Terbit</th>
                    <th>Status DO</th>
                    <th>Estimasi Berat</th>
                    <th>Estimasi Kemasan</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of listDoDetail2">    
                    <td>
                      <button (click)="onSelectTo(row)" title="Pilih DO" class="btn btn-sm btn-primary" triggers="mouseenter:mouseleave"><i class="fa fa-check"></i></button>
                    </td> 
                    <td>{{row.do_no}}</td>
                    <td>{{row.do_date | slice:0:10}}</td>
                    <td>{{row.status_text}}</td>
                    <td>{{row.est_ton}}</td>
                    <td>{{row.est_pack}}</td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <th><input hidden class="searchDo2"/></th>
                    <th><input id="doNo2" type="text" class="searchDo2" placeholder="No DO" /></th>
                    <th><input hidden class="searchDo2"/></th>
                    <th><input hidden /></th>
                    <th><input hidden class="searchDo2"/></th>
                    <th><input hidden class="searchDo2"/></th>
                  </tr>
                </tfoot>
              </table>
        </div>
      </div>
    </div>
  </div>

  <div bsModal #ModalEkspedisi="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog  modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title">
            <div class="row">
              <strong>Pilih Ekspedisi</strong>
            </div>
          </div>
          <button type="button" class="close" (click)="ModalEkspedisi.hide()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <!-- <app-master-relation></app-master-relation> -->
            <table datatable [dtOptions]="dtOptionsEkspedisi" [dtTrigger]="dtTriggerEkspedisi" class="table-striped">
                <thead>
                  <tr>
                    <th>Action</th>
                    <th>Kode</th>
                    <th>Nama</th>
                    <th>Alamat</th>
                    <th>Kode SL</th>
                    <th>Phone</th>
                    <th>Active</th>
                    <th>Dibuat Oleh</th>
                    <th>Dibuat Pada</th>
                    <th>Diubah Oleh</th>
                    <th>Diubah Pada</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of dataEkspedisi">      
                    <td style="width: 200;">
                      <button (click)="onSelectEkspedisi(row);ModalEkspedisi.hide()" title="Edit"  class="btn btn-sm btn-primary" data-toggle="modal" triggers="mouseenter:mouseleave"><i class="fa fa-check"></i></button>
                    </td>  
                    <td>{{row.relation_code}}</td>
                    <td>{{row.relation_name}}</td>
                    <td>{{row.relation_address}}</td>
                    <td>{{row.relation_sl_code}}</td>
                    <td>{{row.relation_phone}}</td>
                    <td>{{row.active}}</td>
                    <td>{{row.createdby}}</td>
                    <td>{{row.created_at | slice:0:10}}</td>  
                    <td>{{row.updatedby}}</td>  
                    <td>{{row.updated_at | slice:0:10}}</td>   
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <th><input hidden class="searchEkspedisi"/></th>
                    <th><input id="codeEks" type="text" class="searchEkspedisi" placeholder="Kode"/></th>
                    <th><input id="nameEks" type="text" class="searchEkspedisi" placeholder="Nama"/></th>
                    <th><input id="addressEks" type="text" class="searchEkspedisi" placeholder="Alamat"/></th>
                    <th><input id="slEks" type="text" class="searchEkspedisi" placeholder="Kode SL"/></th>
                    <th><input hidden class="searchEkspedisi"/></th>
                    <th><input hidden class="searchEkspedisi"/></th>
                    <th><input hidden class="searchEkspedisi"/></th>
                    <th><input hidden class="searchEkspedisi"/></th>
                    <th><input hidden class="searchEkspedisi"/></th>
                    <th><input hidden class="searchEkspedisi"/></th>
                  </tr>
                </tfoot>
      
              </table>
        </div>
      </div>
    </div>
  </div>


  <ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
  >
  <p style="font-size: 20px; color: white">Loading...</p>
  </ngx-spinner>