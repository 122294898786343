import { AfterViewInit, Component, OnInit, ViewChild,Input, ChangeDetectorRef, ElementRef  } from '@angular/core';
import { TransportService} from '../../services/api.service';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { Guid } from 'guid-typescript'
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { Material } from '@/model/material';
import { MasterCode } from '@/model/masterCode';
import { LocationSettingSignature } from '@/model/locationSettingSignature';
import { saveAs } from 'file-saver';
import { Logs } from '@/model/logs';


@Component({
  selector: 'app-sync-ais',
  templateUrl: './sync-ais.component.html',
  styleUrls: ['./sync-ais.component.scss']
})
export class SyncAisComponent implements OnInit, AfterViewInit {
  dtOptions: any = {};
  dtTrigger = new Subject();
  dataLogs: Logs[]=[];

  @ViewChild('modalPrintHide') modalPrintHide: ElementRef;
  dataListMaterial: Material[] = [];
  dataListMasterCode: MasterCode[] = [];
  dataSignature: LocationSettingSignature[] = []
  tipe: string;
  @Input() syncModel = {  from: null, to: null}
  
  constructor( public service: TransportService, private router: Router, private spinner: NgxSpinnerService, fb: FormBuilder, private changeDetectorRef: ChangeDetectorRef) {
    
  }

    ngOnInit(): void {
      this.userAccess();
      this.getLogs();
      this.dtOptions = {
        paging: false,
        ordering : false,
        bInfo : false,
        searching : false,
      };
    }   
    
    ngAfterViewInit(): void {
      this.dtTrigger.next(0);
    }
  
    ngOnDestroy(): void {
      
    }

    getLogs(){
      this.service.getLogsSync().subscribe(resp => {
        this.dataLogs = resp;
      })
    }

    syncAisUJ(){
      if (this.syncModel.from == null || this.syncModel.to == null){
        return  Swal.fire({
          title: "Data Belum Lengkap",
          type: 'warning',
          confirmButtonText: '      Ok!     '
        });
      }
      Swal.fire({
        title: 'Anda Yakin?',
        // text: "You won't be able to revert this!",
        type: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: '      Yes     '
      }).then((result) => {
        if (result.value) {
          this.spinner.show();
          this.service.syncAISUJ(this.syncModel).subscribe(resp => {
            this.spinner.hide();
            if(resp.succes == false){
              Swal.fire({
                title: ""+resp.text,
                type: 'warning',
                confirmButtonText: '      Ok!     '
              });
            }
            else{
              this.getLogs();
              this.modalPrintHide.nativeElement.click();
              Swal.fire({
                title: ""+resp.text,
                type: 'success',
                confirmButtonText: '      Ok!     '
              }).then((result) => {
                if (result.value) { 
                }              
              }, (error) => {
                ; 
              })
            }
          });
  
        }
      
      }, (error) => {
      });
     
    }

    userAccess(){
      const username = localStorage.getItem("username");
      if (!username.includes("admin")){
          return this.router.navigate(["/home"]);
      }
    }

   
}




