
<!-- Main content -->
<section class="content">    
    <div class="card">
      <div class="card-header">
      <strong>List Capacity</strong>
      <div class="float-right">
        <a type="button" class="btn btn-sm btn-warning pull-right" data-toggle="modal" (click)="exportAsXLSX()">&nbsp;&nbsp;&nbsp;<b>Export Data</b>&nbsp;&nbsp;&nbsp;&nbsp;<i class="fas fa-file-excel"></i>&nbsp;&nbsp;&nbsp;</a>&nbsp;&nbsp;&nbsp;&nbsp;
        <a type="button" class="btn btn-sm btn-primary pull-right" data-toggle="modal" (click)="ModalCapacity.show();clearFormCapacity()">&nbsp;&nbsp;&nbsp;<b>Input Kapasitas</b>&nbsp;&nbsp;&nbsp;&nbsp;<i class="fa fa-pen"></i>&nbsp;&nbsp;&nbsp;</a>
      </div>
    </div>
      <div class="card-body">
    <table datatable  [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"  class="table-striped">
      <thead>
        <tr>
          <th>Action</th>
          <th>Kode</th>
          <th>Keterangan</th>
          <th>Tipe Kendaraan</th>
          <th>Tonnase</th>
          <th>Dibuat Oleh</th>
          <th>Dibuat Pada</th>
          <th>Diubah Oleh</th>
          <th>Diubah Pada</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let row of dataCapacity">    
          <td>
            <button class="btn btn-sm btn-danger" title="Delete" triggers="mouseenter:mouseleave" (click)="deleteCapacity(row);"><i class="fa fa-trash"></i></button>&nbsp;
            <button (click)="onSelectCapacity(row);ModalCapacity.show()" title="Edit" class="btn btn-sm btn-primary"  data-toggle="modal"triggers="mouseenter:mouseleave"><i class="fa fa-pen"></i></button>&nbsp;
            <button (click)="getDataPercentage(row);ModalCapacityPercentage.show()" title="Persentase" class="btn btn-sm btn-primary"  data-toggle="modal"triggers="mouseenter:mouseleave"><i class="fa fa-percent"></i></button>
          </td>   
          <td>{{row.capacity_code}}</td>
          <td>{{row.capacity_note}}</td>
          <td>{{row.vehicleType}}</td>
          <td>{{row.tonCode}}</td>
          <td>{{row.createdby}}</td>
          <td>{{row.created_at | slice:0:10}}</td>  
          <td>{{row.updatedby}}</td>  
          <td>{{row.updated_at | slice:0:10}}</td>    
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <th><input hidden class="searchInput"/></th>
          <th><input id="codesearch" type="text" class="searchInput" placeholder="Search Kode" name="search-code"/></th>
          <th><input id="namesearch" type="text" class="searchInput" placeholder="Search Keterangan" name="search-keterangan"/></th>
          <th><input id="vehiclesearch" type="text" class="searchInput" placeholder="Search Kendaraan" name="search-quantity"/></th>
          <th><input hidden class="searchInput"/></th>
          <th><input hidden class="searchInput"/></th>
          <th><input hidden class="searchInput"/></th>
          <th><input hidden class="searchInput"/></th>
          <th><input hidden class="searchInput"/></th>
        </tr>
      </tfoot>
  
    </table>
  </div>
  </div>
  </section>
  
  
  <div  bsModal #ModalCapacity="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog  modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title"><strong>{{action}} Data Kapasitas</strong></div>
          <small><code style="color: black">&nbsp;&nbsp;*(semua kolom wajib di isi)</code></small>
          <button type="button" class="close" (click)="ModalCapacity.hide()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="form-group row">
            <label class="col-md-3 col-form-label" for="text-input">Kode Kapasitas</label>
            <div class="col-md-9">
              <input [(ngModel)]="capacityModel.capacity_code" type="text" class="form-control">
          </div>
          </div> 
          <div class="form-group row">
            <label class="col-md-3 col-form-label" for="text-input">Keterangan</label>
            <div class="col-md-9">
              <input [(ngModel)]="capacityModel.capacity_note" type="text" class="form-control" >
          </div>
          </div> 
          <div class="form-group row">
            <label class="col-md-3 col-form-label" for="text-input">Kendaraan</label>
            <input hidden [(ngModel)]="capacityModel.vehicle_type_id" disabled type="text" class="form-control" >
            <div class="col-md-7">
              <input [(ngModel)]="vehicleSelect" disabled type="text" class="form-control" >
            </div>
            <div class="col-md-2">
              <button style="position:absolute; right: 0; right: 75px; top: 4px;" class="btn btn-sm btn-primary" 
              data-toggle="modal" (click)="ModalTipe.show()"><i class="fa fa-plus"></i></button>
            </div>
          </div> 
          <div class="form-group row">
            <label class="col-md-3 col-form-label" for="text-input">Tonnase Kapasitas</label>
            <input hidden [(ngModel)]="capacityModel.ton_capacity_id" disabled type="text" class="form-control" >
            <div class="col-md-7">
              <input [(ngModel)]="tonnaseSelect" disabled type="text" class="form-control" >
            </div>
            <div class="col-md-2">
              <button style="position:absolute; right: 0; right: 75px; top: 4px;" class="btn btn-sm btn-primary" 
              data-toggle="modal" (click)="ModalTon.show()"><i class="fa fa-plus"></i></button>
            </div>
          </div> 
        </div>
        <div class="modal-footer">
          <button #modalSaveClose type="button" class="btn btn-secondary" (click)="ModalCapacity.hide()"><b>Close</b></button>
          <button type="button" class="btn btn-primary"  (click)="saveDataCapacity();"><i class="fa fa-save"></i>&nbsp;&nbsp;<b>Save</b>&nbsp;&nbsp;</button>
        </div>
      </div>
    </div>
  </div>

  <div  bsModal #ModalTipe="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog  modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title"><strong>Tipe Kendaraan</strong></div>
          <small><code style="color: black">&nbsp;&nbsp;*(semua kolom wajib di isi)</code></small>
          <button type="button" class="close" (click)="ModalTipe.hide()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body"> 
          <table style="width:100%" datatable  [dtOptions]="dtOptions2" [dtTrigger]="dtTrigger"  class="table-striped">
            <thead>
              <tr>
                <th>Kode</th>
                <th>Kendaraan</th>
                <th>Dibuat Oleh</th>
                <th>Dibuat Pada</th>
                <th>Diubah Oleh</th>
                <th>Diubah Pada</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let row of dataVehicles">
                <td>{{row.code}}</td>
                <td>{{row.name}}</td>
                <td>{{row.createdby}}</td>
                <td>{{row.created_at | slice:0:10}}</td>  
                <td>{{row.updatedby}}</td>  
                <td>{{row.updated_at | slice:0:10}}</td>    
                <td>
                  <button (click)="onSelectVehicle(row);ModalTipe.hide()" class="btn btn-sm btn-primary"  data-toggle="modal" triggers="mouseenter:mouseleave"><i class="fa fa-check"></i></button>
                </td>       
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th><input id="codesearchV" type="text" class="searchInputV" placeholder="Search Kode" name="search-code"/></th>
                <th><input id="namesearchV" type="text" class="searchInputV" placeholder="Search Vehicle" name="search-vehicle"/></th>
                <th><input hidden class="searchInputV" name="search-createdby"/></th>
                <th><input hidden class="searchInputV" name="search-createdat"/></th>
                <th><input hidden class="searchInputV" name="search-updateby"/></th>
                <th><input hidden class="searchInputV" name="search-updateat"/></th>
                <th><input hidden class="searchInputV"/></th>
              </tr>
            </tfoot>
      
          </table>
        </div>
      </div>
    </div>
  </div>

  <div  bsModal #ModalTon="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog  modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title"><strong>Tonnase Kapasitas</strong></div>
          <small><code style="color: black">&nbsp;&nbsp;*(semua kolom wajib di isi)</code></small>
          <button type="button" class="close" (click)="ModalTon.hide()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body"> 
          <table datatable  [dtOptions]="dtOptions3" [dtTrigger]="dtTrigger"  class="table-striped">
            <thead>
              <tr>
                <th>Kode</th>
                <th>Tonnase</th>
                <th>Kuantitas</th>
                <th>UOM</th>
                <!-- <th>Dibuat Oleh</th>
                <th>Dibuat Pada</th> -->
                <th>Diubah Oleh</th>
                <th>Diubah Pada</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let row of dataTonnase">
                <td>{{row.code}}</td>
                <td>{{row.name}}</td>
                <td>{{row.quantity}}</td>
                <td>{{row.uoms}}</td>
                <!-- <td>{{row.createdby}}</td>
                <td>{{row.created_at | slice:0:10}}</td>   -->
                <td>{{row.updatedby}}</td>  
                <td>{{row.updated_at | slice:0:10}}</td>    
                <td>
                  <button (click)="onSelectTonnase(row);ModalTon.hide()" class="btn btn-sm btn-primary"  data-toggle="modal" triggers="mouseenter:mouseleave"><i class="fa fa-check"></i></button>
                </td> 
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th><input id="codesearchT" type="text" class="searchInputT" placeholder="Search Kode" name="search-code"/></th>
                <th><input id="namesearchT" type="text" class="searchInputT" placeholder="Search Tonnase" name="search-tonnase"/></th>
                <th><input id="quantitysearch" type="text" class="searchInputT" placeholder="Search Quantity" name="search-quantity"/></th>
                <th><input id="uomsearch" type="text" class="searchInputT" placeholder="Search UOM" name="search-uom"/></th>
                <th><input hidden class="searchInputT" /></th>
                <!-- <th><input hidden class="searchInputT" /></th>
                <th><input hidden class="searchInputT" /></th> -->
                <th><input hidden class="searchInputT" /></th>
                <th><input hidden class="searchInputT" /></th>
              </tr>
            </tfoot>        
          </table>
        </div>
      </div>
    </div>
  </div>

  <div  bsModal #ModalCapacityPercentage="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog  modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title"><strong>Presentase Kapasitas {{capacityCode}}</strong></div>
          <small><code style="color: black">&nbsp;&nbsp;*(semua kolom wajib di isi)</code></small>
          <button type="button" class="close" (click)="ModalCapacityPercentage.hide()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          
          <div class="row">
            <div class="col-md-6"> 
                <div class="form-group row">
                    <label class="col-md-3 col-form-label" for="text-input">Cost Type</label>
                    <div class="col-md-8">
                      <select [(ngModel)]="model" [ngModelOptions]="{standalone: true}" (ngModelChange)="selectCost($event)" class="form-control" >
                        <option *ngFor="let row of ListCostType" [ngValue]="row"><b>{{row.code}}</b></option>
                      </select>
                    </div>
                </div> 
                <div class="form-group row">
                  <label class="col-md-3 col-form-label" for="text-input">Keterangan</label>
                  <div class="col-md-8">
                      <input disabled [(ngModel)]="percentageModel.remark" [ngModelOptions]="{standalone: true}" type="text" class="form-control" >
                  </div>
              </div> 
            </div>
            <div class="col-md-6"> 
                <div class="form-group row">
                    <label class="col-md-3 col-form-label" for="text-input">Presentase</label>
                    <div class="col-md-8">
                        <input [(ngModel)]="percentageModel.percentage" currencyMask [options]="{ prefix: '', thousands: '.', decimal: ',' ,precision: '0',align: 'left' }"  type="text" class="form-control" >
                    </div>
                </div> 
                <div class="form-group row">
                  <label class="col-md-3 col-form-label" for="text-input">GL Account</label>
                  <div class="col-md-8">
                      <input disabled [(ngModel)]="percentageModel.gl_account" [ngModelOptions]="{standalone: true}" type="text" class="form-control" >
                  </div>
              </div> 
            </div>
        </div>
        <br>
        <br>
        <div style="text-align: center">
            <button (click)="AddDataTambahan()" type="button" class="btn btn-sm btn-primary">&nbsp;Tambah&nbsp;</button>
        </div>
        <br>
        <br>
        <div class="card">
            <div class="card-body">
                <table class="table table-striped table-sm">
                    <thead>
                    <tr>
                        <th>Action</th>
                        <th>Keterangan</th>
                        <th>Presentase</th>
                        <th>GL Account</th>
                        <!-- <th>Dibuat Oleh</th>
                        <th>Dibuat Pada</th>
                        <th>Diubah Oleh</th>
                        <th>Diubah Pada</th> -->
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let row of dataTambahan; let i=index">
                        <td><button class="btn btn-sm btn-danger" (click)="deleteDataTambahan(i)" triggers="mouseenter:mouseleave"><i class="fa fa-trash"></i></button></td>
                        <td>{{row.remark}}</td>
                        <td>{{row.percentage}}</td>
                        <td>{{row.gl_account}}</td>
                        <td>{{row.amount}}</td>
                        <!-- <td>{{row.created_at}}</td>
                        <td>{{row.createdby}}</td>
                        <td>{{row.update_at}}</td>
                        <td>{{row.updatedby}}</td> -->
                    </tr>
                    </tbody>
                </table>
            </div>    
        </div>

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" #modalPercentage (click)="ModalCapacityPercentage.hide()"><b>Close</b></button>
          <button type="button" class="btn btn-primary" (click)="saveDataCapacityPercentage()">Save</button>
        </div>
      </div>
    </div>
  </div>
  
  <ngx-spinner  bdColor = "rgba(248,248,248,0.95)" size = "large" color = "#262424" type = "ball-spin-fade" >
    <p style="font-size: 20px; color: white"></p>
  </ngx-spinner>
  
  
  