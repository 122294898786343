import { Guid } from "guid-typescript";

export class ListTravelCost {
    id : Guid;
    transit_port_id : Guid;
    transit_port_name : string;
    route_id : Guid;
    route_name : string;
    capacity_id : Guid;
    capacity_name : string;
    material_id : Guid;
    material_name : string;
    trip_type: Guid;
    distance : number;
    ritase : number;
    trip_premi : number;
    valid_from : Date;
    valid_to : Date;
    location : Guid;
    total_hutang : number;
    total_piutang : number;
    total_amount_money : string;
    total_amount : number;
    created_at : Date;
    created_by_text : string;
    updated_at : Date;
    updated_by_text : string;
  }

  export class TravelCostDetail {
    id : Guid;
    location : Guid;
    travel_cost_id : Guid;
    cost_type_id : Guid;
    amount : number;
    created_at : Date;
    created_by : Guid;
    type_text : string;
    updated_at : Date;
    updated_by : string;
    deleted : boolean;
  }

  export class TravelCostBBM {
    traveL_COST_ID : Guid;
    transiT_PORT : string;
    namA_RUTE : string;
    rutE_DARI : string;
    rutE_KE : string;
    kodE_KAPASITAS : string;
    namA_MATERIAL : string;
    uanG_BBM : number;
    uanG_BBM_BARU : number;
    uanG_JALAN_LAMA_HUTANG : number;
    uanG_JALAN_LAMA_PIUTANG : number;
    uanG_JALAN_LAMA : number;
    uanG_JALAN_BARU : number;
    uanG_JALAN_TANPA_BBM_DAN_PEMBULATAN_HUTANG : number;
    uanG_JALAN_TANPA_BBM_DAN_PEMBULATAN_PIUTANG : number;
  }

  export class ListTravelCostRaw {
    dt: any;
    callback: any;
    page: number;
    perPage: number;
    portId : Guid;
    materialId : Guid;
    routeId : Guid;
    capacityId : Guid;
  }