import { AfterViewInit, Component, OnInit, ViewChild,Input, ChangeDetectorRef, ElementRef  } from '@angular/core';
import { TransportService} from '../../../services/api.service';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import moment from 'moment';
import { Guid } from 'guid-typescript';
import { Npb, NpbDetail, SpkService, SpkServiceDetail, ViewStock } from '@/model/spkService';
import { ListTruck } from '@/model/listTruck';
import { Driver } from '@/model/driver';

@Component({
  selector: 'app-list-npb',
  templateUrl: './list-npb.component.html',
  styleUrls: ['./list-npb.component.scss']
})
export class ListNpbComponent implements OnInit, AfterViewInit {
  @ViewChild('modalSimpanClose') modalSimpanClose: ElementRef;
  @ViewChild('ModalDetailClose') ModalDetailClose: ElementRef;
  @ViewChild('modalSPKClose') modalSPKClose: ElementRef;
  dtOptionsAcc: any = {};
  dtOptionsDetail: any = {};
  dtTriggerDetail = new Subject();
  dtTriggerAcc = new Subject();
  dataListNPB: Npb[] = [];
  dtOptionsStock: any = {};
  dtTriggerStock = new Subject();
  listNpbDetail: NpbDetail[] = [];
  listNpbDetailDelete: NpbDetail[] = [];
  listSPK: SpkService[] = [];
  dataStock: ViewStock[] = [];
  dtOptionsSpk: any = {};
  dtTriggerSpk = new Subject();
  public dt: any;
  public cb: any;

  public dtSpk: any;
  public cbSpk: any;
  public dtDriver: any;
  public cbDriver: any;
  public dtStock: any;
  public cbStock: any;
  public update : boolean;
  public show : boolean;
  locationName: string;
  itemQty: number;
  menu: Guid;
  @Input() listApps = { roleName: null}
  @Input() spkNo = { startDate: null, code: null}
  @Input() npbDetailModel = {itemId: null, stockCode: null,itemName: null,qty: null, uom: null}

  @Input() npbModel = { NpbId: null, SpkId: null, NpbNo: null,  SpkNo: null, Date: null, Remark: null,npbDetail: new Array(), npbDetailDelete: new Array()}
  constructor( public service: TransportService, private router: Router, private spinner: NgxSpinnerService, private changeDetectorRef: ChangeDetectorRef) {
  }

    ngOnInit(): void {
      this.listApps.roleName = localStorage.getItem("rolename");
      this.locationName = localStorage.getItem('locationname');
      this.service.getListApprovals(this.listApps).subscribe(resp => {
        console.log(JSON.stringify(resp))
       });
      //this.userAccess();
      this.dtOptionsAcc = {  
        scrollX: true,
        scrollCollapse: true,
        pageLength: 10,
        ordering : false,
        serverSide: true,
        processing: true,
        searching : false,
        ajax: (dataTablesParameters: any, callback) => {
         this.dt = dataTablesParameters; this.cb = callback;
         this.getNPBList(dataTablesParameters, callback,  true,"","","","");

        },
        columnDefs: [
          { width: 200, targets: [0]},
          { width: 200, targets: [1,2,3,4,5,6,7,8,9]}
        ],
      };

      this.dtOptionsSpk = {  
        scrollX: true,
        scrollCollapse: true,
        pageLength: 10,
        ordering : false,
        serverSide: true,
        processing: true,
        searching : false,
        ajax: (dataTablesParameters: any, callback) => {
         this.dtSpk = dataTablesParameters; this.cbSpk = callback;
         this.getSpkNpb(dataTablesParameters, callback, "","","", true);

        },
        columnDefs: [
          { width: 200, targets: [0]},
          { width: 200, targets: [1,2,3,4,5,6,7,8]}
        ],
      };

      this.dtOptionsDetail = {
        retrieve: true,
        //pageLength: 100,
        ordering : false,
        // scrollX: true,
        // scrollCollapse: true,
        searching : false,
        columnDefs: [
          { width: 400, targets: [0,1,2,3,4,5,6,7] }
        ],
      };

      this.dtOptionsStock = {
        retrieve: true,
        pageLength: 5,
        scrollX: true,
        scrollCollapse: true,
        serverSide: true,
        processing: true,
        searching : false,
        ajax: (dataTablesParameters: any, callback) => {
         this.dtStock = dataTablesParameters; this.cbStock = callback;
         this.getDataStock(dataTablesParameters, callback, "", "","","","",  true);
         },
        columnDefs: [
          { width: 150, targets: [0,1,2,3,4,5,6,7,8] }
        ],
      };

      const that = this;
      $('.searchInputD').on("keyup", function(e) {
        var key = e.which;
        if (key == 13) // the enter key ascii code
        {
          that.getSpkNpb(that.dtSpk, that.cbSpk, $('#spkNo').val().toString(), $('#driverName').val().toString(),$('#truckNo').val().toString(),false);
        }
      });

      $('.searchInputS').on("keyup", function(e) {
        var key = e.which;
        if (key == 13) // the enter key ascii code
        {
          that.getDataStock(that.dtStock, that.cbStock, $('#ItemCode').val().toString(), $('#ItemSubKategori').val().toString(),
          $('#ItemKategori').val().toString(),$('#GL_Account').val().toString(),$('#ItemName').val().toString(),false);
        }
      });

      $('.searchInputN').on("keyup", function(e) {
        var key = e.which;
        if (key == 13) // the enter key ascii code
        {
          that.getNPBList(that.dt, that.cb, false, $('#npbNo').val().toString(), $('#spkNo').val().toString(),
          $('#remark').val().toString(),$('#status').val().toString());
        }
      });
      
      
    }   

    userAccess(){
      const username = localStorage.getItem("username");
      if (!username.includes("admin")){
          return this.router.navigate(["/home"]);
      }
    }
    
    ngAfterViewInit(): void {
      this.dtTriggerAcc.next(this.dtOptionsAcc)
      this.dtTriggerDetail.next(this.dtOptionsDetail);
    }
  
    ngOnDestroy(): void {
      this.dtTriggerAcc.unsubscribe();
      this.dtTriggerDetail.unsubscribe();
    }


    showSpk(){
      this.dtTriggerSpk.next(this.dtOptionsSpk);
    }

    showStock(){
      this.dtTriggerStock.next(this.dtOptionsStock);
    }

    getSpkNpb(dataTablesParameters, callback, spkNo: string, driverName: string, truckNo: string,padging : boolean){ 
      var page = 1;
      if (padging == true){
        page = parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length) + 1;
      }
      this.service.getSpkListNpb(dataTablesParameters, page, dataTablesParameters.length, spkNo, driverName, truckNo)
      .subscribe(resp => {
          this.listSPK = resp.data;
          callback({
            recordsTotal: resp.totalCount,
            recordsFiltered: resp.totalCount,
            data:  []
          });
      });
    }

    getDataStock(dataTablesParameters, callback, ItemCode: string, ItemSubKategori: string, ItemKategori: string, 
      GL_Account: string, ItemName: string, padging : boolean){ 
      var page = 1;
      if (padging == true){
        page = parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length) + 1;
      }
      this.service.getListStock(dataTablesParameters, page, dataTablesParameters.length, ItemCode , ItemSubKategori,
        ItemKategori, GL_Account, ItemName)
      .subscribe(resp => { 
          this.dataStock = resp.data;
          callback({
            recordsTotal: resp.totalCount,
            recordsFiltered: resp.totalCount,
            data:  []
          });
      });
    }

    getNPBList(dataTablesParameters, callback, padging: Boolean, npbNo: string, spkNo: string, remark: string, status: string){
    var page = 1;
      if (padging == true){
        page = parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length) + 1;
      }
      this.service.getNpbList(dataTablesParameters, page, dataTablesParameters.length,npbNo,spkNo,remark, status)
      .subscribe(resp => { 
          this.dataListNPB = resp.data;
          callback({
            recordsTotal: resp.totalCount,
            recordsFiltered: resp.totalCount,
             data:  []
          });
      });
  }

  messageWarning(text: string){
    Swal.fire({
      title: ""+text,
      type: 'warning',
      confirmButtonText: '      Ok!     '
    });
    return;
  }

  addNPBDetail(){
    if (this.npbDetailModel.qty == "" || this.npbDetailModel.qty == null){
      return Swal.fire({
        title: "Data Belum Lengkap!",
        type: 'warning',
        confirmButtonText: '      Ok!     '
      });
    }
    else if (this.npbDetailModel.qty > this.itemQty){
      return Swal.fire({
        title: "Stok Tidak Cukup!",
        type: 'warning',
        confirmButtonText: '      Ok!     '
      });
    }
    else{
      this.listNpbDetail.push( { npbDetailId : null, npbId : null,
        itemId : this.npbDetailModel.itemId,  stockCode : this.npbDetailModel.stockCode, itemName : this.npbDetailModel.itemName, 
        qty : this.npbDetailModel.qty, uom : this.npbDetailModel.uom,
        createdAt : null, updatedAt : null, createdName : null,updatedName : null} );
        this.ModalDetailClose.nativeElement.click();
        this.npbDetailModel.qty = 0;
        this.npbDetailModel.stockCode = "";
        this.npbDetailModel.itemName = "";
        this.npbDetailModel.uom = "";
        console.log(JSON.stringify(this.listNpbDetail))
    }
  }

  createSPK(){
    // if (this.roleModel.role_name == null || this.roleModel.role_name == "" ){
    //   Swal.fire({
    //     title: "Data Masih Belum Lengkap !",
    //     type: 'warning',
    //     confirmButtonText: '      Ok!     '
    //   });
    //   return;
    // }
    Swal.fire({
      title: 'Simpan Data?',
      type: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '      Yes     '
      }).then((result) => {
        if (result.value) {
         // this.spinner.show();

          this.npbModel.npbDetail = [];
            for (let i = 0; i < this.listNpbDetail.length; i++) {
              this.npbModel.npbDetail.push({
                itemId : this.listNpbDetail[i].itemId,
                stockCode : this.listNpbDetail[i].stockCode,
                itemName : this.listNpbDetail[i].itemName,
                qty : this.listNpbDetail[i].qty,
                uom : this.listNpbDetail[i].uom
            });
          }
          this.service.addNPB(this.npbModel).subscribe( resp => {
            this.spinner.hide();
            if(resp.succes == false){
              Swal.fire({
                title: ""+resp.text,
                type: 'warning',
                confirmButtonText: '      Ok!     '
              });
            }
            else{
              Swal.fire({
                title: ""+resp.text,
                type: 'success',
                confirmButtonText: '      Ok!     '
              }).then((result) => {
                if (result.value) { 
                  this.getNPBList(this.dt, this.cb,  true,"","","","");
                }              
              });
              this.modalSimpanClose.nativeElement.click();
            }
          })
        }
    });
  }

  updateSPK(){
    // if (this.roleModel.role_name == null || this.roleModel.role_name == "" ){
    //   Swal.fire({
    //     title: "Data Masih Belum Lengkap !",
    //     type: 'warning',
    //     confirmButtonText: '      Ok!     '
    //   });
    //   return;
    // }
    Swal.fire({
      title: 'Update Data?',
      type: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '      Yes     '
      }).then((result) => {
        if (result.value) {
         // this.spinner.show();

          this.npbModel.npbDetail = [];
          for (let i = 0; i < this.listNpbDetail.length; i++) {
             if (this.listNpbDetail[i].npbDetailId == null){ 
              this.npbModel.npbDetail.push({
                npbDetailId : null,
                itemId : this.listNpbDetail[i].itemId,
                StockCode : this.listNpbDetail[i].stockCode,
                itemName : this.listNpbDetail[i].itemName,
                qty : this.listNpbDetail[i].qty,
                uom : this.listNpbDetail[i].uom
              });
             }
          }

          this.npbModel.npbDetailDelete = [];
          for (let i = 0; i < this.listNpbDetailDelete.length; i++) {
              this.npbModel.npbDetailDelete.push({
                npbDetailId : this.listNpbDetailDelete[i].npbDetailId,
                npbId : this.listNpbDetailDelete[i].npbId
            });
          }

          console.log(this.npbModel);
          this.service.updateNPB(this.npbModel).subscribe( resp => {
            this.spinner.hide();
            if(resp.succes == false){
              Swal.fire({
                title: ""+resp.text,
                type: 'warning',
                confirmButtonText: '      Ok!     '
              });
            }
            else{
              Swal.fire({
                title: ""+resp.text,
                type: 'success',
                confirmButtonText: '      Ok!     '
              }).then((result) => {
                if (result.value) { 
                  this.getNPBList(this.dt, this.cb,  true,"","","","");
                }              
              });
              this.modalSimpanClose.nativeElement.click();
            }
          })
        }
    });
  }

  onSelectDetailSPK(data: any){
    this.show = true;
    this.update = true;
    this.service.getListNPBDetail(data.npbId).subscribe(resp => {
      this.listNpbDetail = resp;
    });
    this.npbModel.NpbId = data.npbId;
    this.npbModel.NpbNo = data.npbNo;
    this.npbModel.SpkId = data.spkId;
    this.npbModel.SpkNo = data.spkNo;
    this.npbModel.Date = data.date;
    this.npbModel.Remark = data.remark;
  }

  onSelectUpdate(data: any){
    this.show = false;
    this.update = true;
    this.service.getListNPBDetail(data.npbId).subscribe(resp => {
      this.listNpbDetail = resp;
    });
    this.npbModel.NpbId = data.npbId;
    this.npbModel.NpbNo = data.npbNo;
    this.npbModel.SpkId = data.spkId;
    this.npbModel.Date = data.date;
    this.npbModel.Remark = data.remark;
  }

  onSelectSpk(data: SpkService){
    this.service.getExistSPK("NPB",data.spkId).subscribe(resp => {
      if (resp.docNo == null || resp.docNo == ""){
        this.npbModel.SpkId = data.spkId;
        this.npbModel.SpkNo = data.spkNo;
        this.modalSPKClose.nativeElement.click();
      }
      else{
        Swal.fire({
          title: "Sudah ada NPB ("+resp.docNo+ ")Terbuat Dalam SPK Tersebut!",
          type: 'warning',
          confirmButtonText: '      Ok!     '
        });
      }
    })
  }

  onSelectStock(data: ViewStock){
    this.npbDetailModel.itemId = data.itemID;
    this.npbDetailModel.itemName = data.itemName;
    this.npbDetailModel.stockCode = data.itemCode;
    this.npbDetailModel.uom = data.uom;
    this.itemQty = data.qty;
 }

  onSelectDriver(data: Driver){
    // this.npbModel.DriverId = data.driver_id;
    // this.npbModel.DriverName = data.driver_name;
  }

  setNull(){
    this.show = false;
    this.update = false;
    var today = moment().format('YYYY-MM-DD HH:mm');
    this.npbModel.Date = today;
    this.npbModel.SpkId = null;
    this.npbModel.Remark = null;
    this.listNpbDetail = [];
    this.spkNo.startDate = this.npbModel.Date;
    this.spkNo.code= "NPB";
    this.service.generateSPKNo(this.spkNo).subscribe(resp => {
      if(resp.succes == false){
          Swal.fire({
            title: ""+resp.text,
            type: 'warning',
            confirmButtonText: '      Ok!     '
          });
      }
      else{
        this.npbModel.NpbNo = resp.text;
      }
    });
  }


  deleteDataDetail(id: number, data: NpbDetail){
    Swal.fire({
      title: 'Delete Data?',
      type: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '      Yes     '
      }).then((result) => {
        if (result.value) {
        this.listNpbDetail.splice(id, 1); this.changeDetectorRef.detectChanges();
        this.listNpbDetailDelete.push({npbDetailId : data.npbDetailId, npbId : data.npbId, itemId : data.itemId,
          stockCode : data.stockCode, itemName : data.itemName, qty : data.qty, uom : data.uom,
          createdAt : data.createdAt, updatedAt : data.updatedAt, createdName : data.createdName,updatedName : data.updatedName});
        }
    })
  }

  printNpb(data: any){
    this.spinner.show();
    this.service.printNPB(data.npbId, this.locationName).subscribe(resp =>{
      this.spinner.hide();
      let blob:Blob=resp.body as Blob;
        let url = window.URL.createObjectURL(blob);
        window.open(url);
      })
  }


}













