
<section class="content"> 
    <div class="card"> 
        <div class="card-body">
            <div class="row">
                <div class="col-md-8"> 
                    <div class="form-group row">
                        <label class="col-md-3 col-form-label" for="text-input">Select Report</label>
                        <div class="col-md-8">
                        <select class="form-control" [(ngModel)]="code" [ngModelOptions]="{standalone: true}" (ngModelChange)="selectCode($event)">
                            <option *ngFor="let row of allReport" [ngValue]="row"><b>{{row.code}}</b></option>
                        </select>
                        </div>
                    </div> 
                </div>  
            </div>
        </div>
    </div>
    <div class="card"> 
        <div class="card-body">
            <div id="reportFilterLayoutDiv" style="display:none">
                <div id="reportLayout">
                </div>
                <hr />
                <div class="form-group row">
                    <label class="col-sm-2 col-form-label" for="sort1" name="label">Sort By</label>
                    <div class="col-sm-8">
                        <select name="sort1" class="form-control form-control-sm" id="sort1"></select>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-2 col-form-label" for="sort2" name="label">Then By</label>
                    <div class="col-sm-8">
                        <select name="sort2" class="form-control form-control-sm" id="sort2"></select>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-2 col-form-label" for="sort3" name="label">Then By</label>
                    <div class="col-sm-8">
                        <select name="sort3" class="form-control form-control-sm" id="sort3"></select>
                    </div>
                </div>
        
                <button type="button" id="btnShow" (click)="ModalReport.show()" class="btn btn-success float-right btn-approve">Show</button>
            </div>
            <div class="blueprint" style="display:none">
                <div class="form-group row">
                    <label class="col-sm-2 col-form-label" name="label"></label>
                    <div class="col-sm-4">
                        <input type="text" placeholder="From" class="form-control" name="from" />
                    </div>
                    <div class="col-sm-4">
                        <input type="text" placeholder="To" class="form-control" name="to" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>



<div bsModal #ModalReport="bs-modal"  [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog" style="max-width: 90%" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title">
          </div>
          <button type="button" class="close" (click)="resetTable();ModalReport.hide()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="card">
            <div class="card-body">
                <div class="scroll">
                    <input type="hidden" name="dtRow" id="dtRow" [(ngModel)]="strJson" />
                    <table id="jqueryDatatable" width="100%"  class="table table-bordered table-sm"></table>
                    <!-- <table *ngIf="dtRendered" datatable [dtOptions]="dtOptionsQuery" [dtTrigger]="dtTriggerQuery" width="100%"></table> -->
                </div>
            </div>
          </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" (click)="resetTable();ModalReport.hide()"><b>Close</b></button>
          </div>
        </div>
      </div>
    </div>

<!-- <button #ModalReportShow hidden (click)="ModalReport.show()"></button> -->


<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>