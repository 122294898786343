import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {HttpClientModule} from '@angular/common/http';

import {AppRoutingModule} from '@/app-routing.module';
import {AppComponent} from './app.component';
import {MainComponent} from '@modules/main/main.component';
import {LoginComponent} from '@modules/login/login.component';
import {HeaderComponent} from '@modules/main/header/header.component';
import {FooterComponent} from '@modules/main/footer/footer.component';
import {MenuSidebarComponent} from '@modules/main/menu-sidebar/menu-sidebar.component';
import {BlankComponent} from '@pages/blank/blank.component';
import {ReactiveFormsModule} from '@angular/forms';
import {ProfileComponent} from '@pages/profile/profile.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RegisterComponent} from '@modules/register/register.component';
import {DashboardComponent} from '@pages/dashboard/dashboard.component';
import {ToastrModule} from 'ngx-toastr';
import {MessagesComponent} from '@modules/main/header/messages/messages.component';
import {NotificationsComponent} from '@modules/main/header/notifications/notifications.component';

import {registerLocaleData} from '@angular/common';
import localeEn from '@angular/common/locales/en';
import {UserComponent} from '@modules/main/header/user/user.component';
import {ForgotPasswordComponent} from '@modules/forgot-password/forgot-password.component';
import {RecoverPasswordComponent} from '@modules/recover-password/recover-password.component';
import {LanguageComponent} from '@modules/main/header/language/language.component';
import {MainMenuComponent} from './pages/main-menu/main-menu.component';
import {SubMenuComponent} from './pages/main-menu/sub-menu/sub-menu.component';
import {MenuItemComponent} from './components/menu-item/menu-item.component';
import {ControlSidebarComponent} from './modules/main/control-sidebar/control-sidebar.component';
import {StoreModule} from '@ngrx/store';
import {authReducer} from './store/auth/reducer';
import {uiReducer} from './store/ui/reducer';
import {ProfabricComponentsModule} from '@profabric/angular-components';
import {defineCustomElements} from '@profabric/web-components/loader';
import { SidebarSearchComponent } from './components/sidebar-search/sidebar-search.component';
import { DataTablesModule } from "angular-datatables";
import {FormsModule} from '@angular/forms';
import { MasterMaterialComponent } from './pages/main-menu/master-material/master-material.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { CurrencyMaskModule } from "ng2-currency-mask";
import { NgxSpinnerModule } from 'ngx-spinner';
import { MasterTonnaseCapacityComponent } from './pages/main-menu/master-tonnase-capacity/master-tonnase-capacity.component';
import { MaterialModule } from './shared/material.module';
import { MasterVehiclesComponent } from './pages/main-menu/master-vehicles/master-vehicles.component';
import { MasterCapacityComponent } from './pages/main-menu/master-capacity/master-capacity.component';
import { MasterTruckComponent } from './pages/main-menu/master-truck/master-truck.component';
import { MasterPortComponent } from './pages/main-menu/master-port/master-port.component';
import { MasterRouteComponent } from './pages/main-menu/master-route/master-route.component';
import { MasterRelationComponent } from './pages/main-menu/master-relation/master-relation.component';
import { MasterCosttypeComponent } from './pages/main-menu/master-costtype/master-costtype.component';
import { MasterDebtpriorityComponent } from './pages/main-menu/master-debtpriority/master-debtpriority.component';
import { MasterTravelcostComponent } from './pages/main-menu/master-travelcost/master-travelcost.component';
import { MasterDriverComponent } from './pages/main-menu/master-driver/master-driver.component';
import { MasterToleranceComponent } from './pages/main-menu/master-tolerance/master-tolerance.component';
import { MasterKebunComponent } from './pages/main-menu/master-kebun/master-kebun.component';
import { SpkListComponent } from './pages/spk/spk-list/spk-list.component';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TripRecordManualComponent } from './pages/TripRecords/TripRecordManual/trip-record-manual/trip-record-manual.component';
import { InvoiceListComponent } from './pages/invoice/invoice-list/invoice-list/invoice-list.component';
import { PaymentListComponent } from './pages/payment/payment-list/payment-list.component';
import { DriverDebtComponent } from './pages/driver-calculation/driver-debt/driver-debt.component';
import { ReportFormComponent } from './pages/TripRecords/report-form/report-form.component';
import { AccClosingComponent } from './pages/payment/acc-closing/acc-closing.component';
import { JwtModule } from '@auth0/angular-jwt';
import { LoginPageComponent } from './modules/login-page/login-page.component';
import { DriverBpjsComponent } from './pages/driver-calculation/driver-bpjs/driver-bpjs.component';
import { ClaimSusutComponent } from './pages/driver-calculation/claim-susut/claim-susut.component';
import { DebtPayComponent } from './pages/driver-calculation/debt-pay/debt-pay.component';
import { DriverPremiComponent } from './pages/driver-calculation/driver-premi/driver-premi.component';
import { SpsSusutComponent } from './pages/general-report/sps-susut/sps-susut.component';
import { TravelCostReportComponent } from './pages/general-report/travel-cost-report/travel-cost-report.component';
import { MatTableModule } from '@angular/material/table';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { DriverPerformComponent } from './pages/general-report/driver-perform/driver-perform.component';
import { ReportGeneralComponent } from './pages/general-report/report-general/report-general.component';
import { TripDistributedComponent } from './pages/TripRecords/trip-distributed/trip-distributed.component';
import { JournalInvoiceComponent } from './pages/journal/journal-invoice/journal-invoice.component';
import { JournalTravelcostComponent } from './pages/journal/journal-travelcost/journal-travelcost.component';
import { ReportCustomComponent } from './pages/general-report/report-custom/report-custom.component';
import { ReportListComponent } from './pages/general-report/report-list/report-list.component';
import { TravelcostBbmComponent } from './pages/main-menu/travelcost-bbm/travelcost-bbm.component';
import { ListApprovalsDoComponent } from './pages/approval/list-approvals-do/list-approvals-do.component';
import { DetailApprovalsDoComponent } from './pages/approval/detail-approvals-do/detail-approvals-do.component';
import { SyncAisComponent } from './pages/sync-ais/sync-ais.component';
import { TripOpslahComponent } from './pages/TripRecords/trip-opslah/trip-opslah.component';
import { AccountComponent } from './pages/settings/account/account.component';
import { RePassComponent } from './pages/settings/re-pass/re-pass.component';
import { ListRoleComponent } from './pages/settings/list-role/list-role.component';
import { SpkServiceComponent } from './pages/services/spk-service/spk-service.component';
import { ListApprovalServiceComponent } from './pages/services/list-approval-service/list-approval-service.component';
import { ListNpbComponent } from './pages/services/list-npb/list-npb.component';
import { DebitNoteComponent } from './pages/invoice/debit-note/debit-note.component';
import { InvoiceManualComponent } from './pages/invoice/invoice-manual/invoice-manual.component';
import { ListSpbComponent } from './pages/services/list-spb/list-spb.component';
import { ListBapbComponent } from './pages/services/list-bapb/list-bapb.component';
import { DashboardServiceComponent } from '@pages/services/dashboard-service/dashboard-service.component';
defineCustomElements();
registerLocaleData(localeEn, 'en-EN');

export function tokenGetter() {
    return localStorage.getItem("token");
}

@NgModule({
    declarations: [
        AppComponent,
        MainComponent,
        LoginComponent,
        HeaderComponent,
        FooterComponent,
        MenuSidebarComponent,
        BlankComponent,
        ProfileComponent,
        RegisterComponent,
        DashboardComponent,
        MessagesComponent,
        NotificationsComponent,
        UserComponent,
        ForgotPasswordComponent,
        RecoverPasswordComponent,
        LanguageComponent,
        MainMenuComponent,
        SubMenuComponent,
        MenuItemComponent,
        ControlSidebarComponent,
        SidebarSearchComponent,
        MasterMaterialComponent,
        MasterTonnaseCapacityComponent,
        MasterVehiclesComponent,
        MasterCapacityComponent,
        MasterTruckComponent,
        MasterPortComponent,
        MasterRouteComponent,
        MasterRelationComponent,
        MasterCosttypeComponent,
        MasterDebtpriorityComponent,
        MasterTravelcostComponent,
        MasterDriverComponent,
        MasterToleranceComponent,
        MasterKebunComponent,
        SpkListComponent,
        TripRecordManualComponent,
        InvoiceListComponent,
        PaymentListComponent,
        DriverDebtComponent,
        ReportFormComponent,
        AccClosingComponent,
        LoginPageComponent,
        DriverBpjsComponent,
        ClaimSusutComponent,
        DebtPayComponent,
        DriverPremiComponent,
        SpsSusutComponent,
        TravelCostReportComponent,
        DriverPerformComponent,
        ReportGeneralComponent,
        TripDistributedComponent,
        JournalInvoiceComponent,
        JournalTravelcostComponent,
        ReportCustomComponent,
        ReportListComponent,
        TravelcostBbmComponent,
        ListApprovalsDoComponent,
        DetailApprovalsDoComponent,
        SyncAisComponent,
        TripOpslahComponent,
        AccountComponent,
        RePassComponent,
        ListRoleComponent,
        SpkServiceComponent,
        ListApprovalServiceComponent,
        ListNpbComponent,
        DebitNoteComponent,
        InvoiceManualComponent,
        ListSpbComponent,
        ListBapbComponent,
        DashboardServiceComponent,
    ],
    imports: [
        BrowserModule,
        ModalModule,
        TabsModule.forRoot(),
        NgxSpinnerModule,
        ModalModule.forRoot(),
        DataTablesModule,
        CurrencyMaskModule,
        HttpClientModule,
        FormsModule,
        StoreModule.forRoot({auth: authReducer, ui: uiReducer}),
        HttpClientModule,
        AppRoutingModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        MatButtonModule,
        MatInputModule,
        MatTableModule,
        MatSelectModule,
        MaterialModule,
        ToastrModule.forRoot({
            timeOut: 3000,
            positionClass: 'toast-top-right',
            preventDuplicates: true
        }),
        ProfabricComponentsModule,
        JwtModule.forRoot({
            config: {
              tokenGetter: tokenGetter,
              allowedDomains: ["localhost"],
            },
          }),
    ],
    providers: [MasterKebunComponent],
    bootstrap: [AppComponent]
})
export class AppModule {}
