import { AfterViewInit, Component, OnInit, ViewChild,Input ,ChangeDetectorRef, ElementRef  } from '@angular/core';
import { DataTableDirective } from 'angular-datatables'
import { TransportService} from '../../..//services/api.service';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { Guid } from 'guid-typescript'
import { MasterCode } from '@/model/masterCode';
import { Capacity } from '@/model/capacity';
import { Vehicle } from '@/model/vehicle';
import { Ton } from '@/model/tonnase';
import { CapacityPercentage } from '@/model/capacityPercentage';
import { CostType } from '@/model/costType';
import { AppService } from '@services/app.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-master-capacity',
  templateUrl: './master-capacity.component.html',
  styleUrls: ['./master-capacity.component.scss']
})
export class MasterCapacityComponent implements OnInit, AfterViewInit {
 @ViewChild('modalPercentage') modalPercentage: ElementRef;
 @ViewChild('modalSaveClose') modalClose: ElementRef;
 @ViewChild(DataTableDirective, {static: false})
  datatableElement: DataTableDirective;
  model : any;
  dtOptions: any = {};
  dtOptions2: any = {};
  dtOptions3: any = {};
  capacityId: Guid;
  public capacity: Capacity;
  public dataCapacity: Capacity[] = [];
  printDataCapacity: Capacity[] = [];
  ListCostType: CostType[] = [];
  dataPercentage: CapacityPercentage[] = [];
  dataMasterCode: MasterCode[] = [];
  dtTrigger = new Subject();
  dataTambahan: Array<{capacity_id: Guid, cost_type_id: Guid, remark:string, percentage: number, gl_account: string}> = [];
  @Input() capacityModel = { capacity_id : null, capacity_code: '', capacity_note: '', ton_capacity_id: null,vehicle_type_id: null};
  @Input() percentageModel = { capacity_id : null, cost_type_id: null, remark: '', percentage: 0, gl_account: '', cost_name: null};
  @Input() percentageList = { capacity_id : null, list:new Array()};
  action: string;
  capacityCode: string;
  public dt: any;
  public cb: any;
  public vehicleSelect: string;
  public tonnaseSelect: string;
  dataVehicles: Vehicle[] = [];
  dataTonnase: Ton[] = [];

  constructor( public service: TransportService, private router: Router,  private spinner: NgxSpinnerService, private changeDetectorRef: ChangeDetectorRef, private appServ : AppService) {
  }

    
    ngOnInit(): void {
     const menuUser = localStorage.getItem("menu");
      if (menuUser != "All"){
        const subMenu = localStorage.getItem("acces_menu");
        let arrayMenu =  subMenu.split(",");
        if (arrayMenu.includes("capacity") == false){
          this.router.navigate(["/home"]);
        }
      }
      this.dtOptions = {
        responsive:true,
        pagingType: 'full_numbers',
        pageLength: 7,
        serverSide: true,
        processing: true,
        searching : false,
        ordering : false,
        ajax: (dataTablesParameters: any, callback) => {
         this.dt = dataTablesParameters; this.cb = callback;
         this.getDataCapacity(dataTablesParameters, callback, $('#codesearch').val().toString(), $('#namesearch').val().toString(),
         $('#vehiclesearch').val().toString(),true);
        },
        columnDefs: [
          { width: 150, targets: 0 }
        ]
        
      };

      this.dtOptions2 = {
        responsive:true,
        pagingType: 'full_numbers',
        pageLength: 7,
        serverSide: true,
        processing: true,
        searching : false,
        info: false,        
        lengthChange : false,
        ajax: (dataTablesParameters: any, callback) => {
         this.dt = dataTablesParameters; this.cb = callback;
         this.getDataVehicle(dataTablesParameters, callback, $('#codesearchV').val().toString(), $('#namesearchV').val().toString(),true);
        }
      };

      this.dtOptions3 = {
        scrollX: true,
        scrollCollapse: true,
        serverSide: true,
        processing: true,
        searching : false,
        info: false,        
        lengthChange : false,
        autoWidth: true,
        ajax: (dataTablesParameters: any, callback) => {
         this.dt = dataTablesParameters; this.cb = callback;
         this.getDataTonnase(dataTablesParameters, callback, $('#codesearchT').val().toString(), $('#namesearchT').val().toString(),
         $('#quantitysearch').val().toString(), $('#uomsearch').val().toString(),true);
        },
        columnDefs: [
          { width: 100, targets: 0 }
        ]
      };

      const that = this;
      $('.searchInput').on("keyup", function(e) {
        var key = e.which;
        if (key == 13) // the enter key ascii code
        {
          that.getDataCapacity(that.dt, that.cb, $('#codesearch').val().toString(), $('#namesearch').val().toString(),
           $('#vehiclesearch').val().toString(),false);
        }
      });  
      $('.searchInputV').on("keyup", function(e) {
        var key = e.which;
        if (key == 13) // the enter key ascii code
        {
          that.getDataVehicle(that.dt, that.cb, $('#codesearchV').val().toString(), $('#namesearchV').val().toString(),false);
        }
      });  
      $('.searchInputT').on("keyup", function(e) {
        var key = e.which;
        if (key == 13) // the enter key ascii code
        {
          that.getDataTonnase(that.dt, that.cb, $('#codesearchT').val().toString(), $('#namesearchT').val().toString(),
           $('#quantitysearch').val().toString(), $('#uomsearch').val().toString(),false);
        }
      });  

      this.service.getListCostTypeAll("").subscribe(resp => {
        this.ListCostType = resp;
      });
      
    }


    getDataCapacity(dataTablesParameters, callback, code: string, name: string, vehicle: string, padging : boolean){ 
      var page = 1;
      if (padging == true){
        page = parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length) + 1;
      }
      this.service.getDataCapacity(dataTablesParameters, page, dataTablesParameters.length, code, name, vehicle)
      .subscribe(resp => { 
          this.dataCapacity = resp.data;
          callback({
            recordsTotal: resp.totalCount,
            recordsFiltered: resp.totalCount,
             data:  []
          });
      });
    }

    ngAfterViewInit(): void {
      this.dtTrigger.next(0);
    }
  
    ngOnDestroy(): void {
      this.dtTrigger.unsubscribe();
    }
  
    rerender(): void {
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtTrigger.next(0);
      });
    }

    selectCost(data: CostType){
      this. percentageModel.cost_name = data.name;
      this.percentageModel.cost_type_id = data.id;
      this.percentageModel.gl_account = data.gl_code;
      this.percentageModel.remark = data.name;
      this.capacityId = data.id;
    }

    AddDataTambahan() {
      this.dataTambahan.push({capacity_id: this.percentageModel.capacity_id, cost_type_id: this.percentageModel.cost_type_id,
         remark:this.percentageModel.remark, percentage: this.percentageModel.percentage, gl_account: this.percentageModel.gl_account});
    }

    deleteDataTambahan(id: number)
    { 
      this.dataTambahan.splice(id, 1); this.changeDetectorRef.detectChanges(); 
    } 

    saveDataCapacityPercentage(){
        var totalPercentage = this.dataTambahan.reduce((sum, obj) => {
          return sum + obj.percentage;
        }, 0);
        totalPercentage = totalPercentage;
        if (totalPercentage != 100){
          Swal.fire({
            title: "Total persentase masih kurang atau lebih dari 100% !",
            type: 'warning',
            confirmButtonText: '      Ok!     '
          });
        }
        else{
          Swal.fire({
            title: 'Simpan Data?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: '      Yes     '
          }).then((result) => {
            if (result.value) {

              this.percentageList.list = [];
              for (let i = 0; i < this.dataTambahan.length; i++) {
                this.percentageList.list.push({
                  cost_type_id : this.dataTambahan[i].cost_type_id,
                  gl_account : this.dataTambahan[i].gl_account,
                  percentage: this.dataTambahan[i].percentage,
                  remark: this.dataTambahan[i].remark
                });
              }
              this.spinner.show();
              this.service.addCapacityPercentage(this.percentageList).subscribe(resp => {
                this.spinner.hide();
                Swal.fire({
                  type: 'success',
                  confirmButtonText: '      Succes!     '
                }).then((result) => {
                  if (result.value) { 
                    this.modalPercentage.nativeElement.click();
                  }
                
                }, (error) => {
                  //; 
                })
              });
            }
          });
        }
        
    }

    onSelectCapacity(capacity: Capacity) {
      this.action = "Update";
      this.capacityModel.capacity_id = capacity.capacity_id;
      this.capacityModel.capacity_code = capacity.capacity_code;
      this.capacityModel.capacity_note= capacity.capacity_note;
      this.capacityModel.ton_capacity_id= capacity.ton_capacity_id;
      this.capacityModel.vehicle_type_id=  capacity.vehicle_type_id;
      this.tonnaseSelect = capacity.tonCode;
      this.vehicleSelect = capacity.vehicleType;
    }

    onSelectVehicle(vehicle: Vehicle){
      this.vehicleSelect = vehicle.name;
      this.capacityModel.vehicle_type_id = vehicle.id;
    }

    onSelectTonnase(ton: Ton){
      this.tonnaseSelect = ton.name;
      this.capacityModel.ton_capacity_id = ton.id;
    }
  
  saveDataCapacity(){
    console.log(JSON.stringify(this.capacityModel));
    if (this.capacityModel.capacity_code == null || this.capacityModel.capacity_note == null ||
      this.vehicleSelect == null || this.tonnaseSelect == null ){
        return Swal.fire({
          title: "Data Belum Lengkap!",
          type: 'warning',
          confirmButtonText: '      Ok!     '
        });
      }
    Swal.fire({
      title: 'Simpan Data?',
      type: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '      Yes     '
    }).then((result) => {
      if (result.value) {
        this.spinner.show();
        this.service.addCapacity(this.capacityModel).subscribe(resp => {
          this.spinner.hide();
          if(resp.succes == false){
            Swal.fire({
              title: ""+resp.text,
              type: 'warning',
              confirmButtonText: '      Ok!     '
            });
          }
          else{
            Swal.fire({
              title: ""+resp.text,
              type: 'success',
              confirmButtonText: '      Ok!     '
            }).then((result) => {
              if (result.value) { 
                 this.getDataCapacity(this.dt, this.cb, "", "","",true);
                 this.modalClose.nativeElement.click();
              }              
            });
          }
        });
      }
    })
    }

  getDataPercentage(data: Capacity){
    this.dataTambahan.length = 0;
    this.capacityCode = data.capacity_code;
    this.percentageList.capacity_id = data.capacity_id;
    this.service.getListCapacityPercentage(data.capacity_id).subscribe(resp => {
      this.dataPercentage = resp;
      this.dataPercentage.forEach((item) => {
        this.dataTambahan.push({capacity_id: item.capacity_id, cost_type_id: item.cost_type_id,
          remark: item.remark, percentage: item.percentage, gl_account: item.gl_account});
        });
      });
      
  }

  deleteCapacity(capacity: Capacity){
    Swal.fire({
      title: 'Delete Data?',
      // text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '      Yes     '
    }).then((result) => {
      if (result.value) {
        this.spinner.show();
        this.service.DeleteCapacity(capacity.capacity_id).subscribe(tonnase => { 
          this.spinner.hide();
          Swal.fire({
            type: 'success',
            confirmButtonText: '      Succes!     '
          }).then((result) => {
            if (result.value) { 
              this.getDataCapacity(this.dt, this.cb, "", "","",true);
            }
          
          }, (error) => {
            //; 
          })
        });
      }
    
    }, (error) => {
      //; 
    });
  }


  deleteCapacityPecentage(capacity: CapacityPercentage){
    Swal.fire({
      title: 'Delete Data?',
      // text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '      Yes     '
    }).then((result) => {
      if (result.value) {
        this.spinner.show();
        this.service.deleteCapacityPercentage(capacity.id).subscribe(resp => { 
          this.spinner.hide();
          Swal.fire({
            type: 'success',
            confirmButtonText: '      Succes!     '
          }).then((result) => {
            if (result.value) { 
              this.service.getListCapacityPercentage(capacity.capacity_id).subscribe(resp => {
                this.dataPercentage = resp;
              });
            }
          
          }, (error) => {
            //; 
          })
        });
      }
    
    }, (error) => {
      //; 
    });
  }

  
  clearFormCapacity(){
    this.action = "Input";
    this.capacityModel.capacity_id = null;
    this.capacityModel.capacity_code = null;
    this.capacityModel.capacity_note = null;
    this.capacityModel.ton_capacity_id = null;
    this.capacityModel.vehicle_type_id = null;
    this.tonnaseSelect = null;
    this.vehicleSelect = null;
  }
    
  getDataVehicle(dataTablesParameters, callback, code: string, name: string, padging : boolean){ 
    var page = 1;
    if (padging == true){
      page = parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length) + 1;
    }
    this.service.getDataVehicle(dataTablesParameters, page, dataTablesParameters.length, code, name)
    .subscribe(resp => { 
        this.dataVehicles = resp.data;
        callback({
          recordsTotal: resp.totalCount,
          recordsFiltered: resp.totalCount,
           data:  []
        });
    });
  }

  getDataTonnase(dataTablesParameters, callback, code: string, name: string, quantity: string, uoms: string, padging : boolean){ 
    var page = 1;
    if (padging == true){
      page = parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length) + 1;
    }
    this.service.getDataTonnase(dataTablesParameters, page, dataTablesParameters.length, code, name, quantity, uoms)
    .subscribe(resp => { 
        this.dataTonnase = resp.data;
        callback({
          recordsTotal: resp.totalCount,
          recordsFiltered: resp.totalCount,
           data:  []
        });
    });
  }

  exportAsXLSX():void {
    this.service.getDataCapacity(this.dt, 1, 1000000,$('#codesearch').val().toString(), $('#namesearch').val().toString(), $('#vehiclesearch').val().toString())
      .subscribe(resp => { 
          this.printDataCapacity = resp.data;
          this.appServ.exportAsExcelFile(this.printDataCapacity, 'Master_Kapasitas');
      });
  }

  
      
}



