import { AfterViewInit, Component, OnInit, ViewChild,Input, ChangeDetectorRef, ElementRef  } from '@angular/core';
import { DataTableDirective } from 'angular-datatables'
import { TransportService} from '../../../../services/api.service';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { Guid } from 'guid-typescript'
import { Vehicle } from '@/model/vehicle';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { DebtPriority } from '@/model/debtPriority';
import { MasterCode } from '@/model/masterCode';
import { TabsetComponent, TabDirective } from 'ngx-bootstrap/tabs';
import { Router } from '@angular/router';
import { Relation } from '@/model/relation';
import { Route } from '@/model/route';
import { CostType } from '@/model/costType';
import moment from 'moment';
import { Capacity } from '@/model/capacity';
import { ListTripRecord, ListTripRecordRaw } from '@/model/listTripRecord';
import { ListTruck } from '@/model/listTruck';
import { Driver } from '@/model/driver';
import { Port, PortTransit } from '@/model/port';
import { Truck } from '@/model/truck';
import { RespDetailDo } from '@/model/respDetailDo';
import { LabelTotal } from '@/model/labelTotal';
import { ListPaymentTrx } from '@/model/listPaymentTrx';
import { ListOfTrxDoDetail } from '@/model/listOfTrxDoDetail';
import { ListDriverDebt } from '@/model/listDriverDebt';
import { ListOfTrxCostDetail } from '@/model/listOfTrxCostDetail';
import { UploadFileRealizationDto } from '@/model/uploadFileRealizationDto';
import saveAs from 'file-saver';
import { UserAuth } from '@/model/userAuth';
import { filter } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { Action } from 'rxjs/internal/scheduler/Action';
import { listTrxDetailPaymentTrip } from '@/model/listTrxDetailPaymentTrip';
import { LocationSettingSignature } from '@/model/locationSettingSignature';
import {formatDate} from '@angular/common';
import { AuthMenu } from '@/model/masterSubMenu';
import { UserAuthNew } from '@/model/respDto';
Object.assign(Action.prototype, {  map, filter });


// import { DatepickerOptions } from 'ng2-datepicker';
// import { getYear } from 'date-fns';
// import locale from 'date-fns/locale/en-US';


@Component({
  selector: 'app-trip-record-manual',
  templateUrl: './trip-record-manual.component.html',
  styleUrls: ['./trip-record-manual.component.scss']
})
export class TripRecordManualComponent implements OnInit, AfterViewInit {
  @ViewChild(TabsetComponent) tabset: TabsetComponent;
  spkForm: FormGroup;
  @ViewChild('clickTab') clickTab;
  @ViewChild('ModalTripOpen') ModalTripOpen: ElementRef; 
  @ViewChild('modalCreateClose') modalCreateClose: ElementRef; 
  @ViewChild('modalFinishClose') modalFinishClose: ElementRef; 
  @ViewChild('ModalPaymentClose') ModalPaymentClose: ElementRef; 
  @ViewChild('debtClick') debtClick: ElementRef; 
  @ViewChild('paymentClick') paymentClick: ElementRef;
  @ViewChild('uploadFile') uploadFile: ElementRef;
  @ViewChild('ModalUpdateDoClose') ModalUpdateDoClose: ElementRef;
  @ViewChild('modalPrint') modalPrint: ElementRef;
  @ViewChild('ModalListPaymentShow') ModalListPaymentShow: ElementRef;
  @ViewChild('ModalAdAsDebtHide') ModalAdAsDebtHide: ElementRef;
  @ViewChild('ModalUpdateReasonClose') ModalUpdateReasonClose: ElementRef;   
  @ViewChild('ModalCancelHide') ModalCancelHide: ElementRef;   
  @ViewChild('ModalUpdateVendorHide') ModalUpdateVendorHide: ElementRef; 
  @ViewChild('ModalUpdatePaymentDateClose') ModalUpdatePaymentDateClose: ElementRef; 
  @ViewChild('ModalUpdateVendorShow') ModalUpdateVendorShow: ElementRef; 
  @ViewChild('ModalSusutShow') ModalSusutShow: ElementRef; 
  @ViewChild('ModalSusutClose') ModalSusutClose: ElementRef; 
  @ViewChild('ModalSignatureShow') ModalSignatureShow: ElementRef; 
  
  
  bol = true;
 //@ViewChild('currentTabId') currentTabId; 
  public currentTabId: number; 
  model : any;
  closeForm: FormGroup;
  public clean = false;
  @ViewChild(DataTableDirective, {static: false})
  datatabdtOptionsnt: DataTableDirective;
  detailIdDoSusut: Guid;
  idTrip: Guid;
  doNoSusut : string;
  dataSusut: any = {};
  dtOptions: any = {};
  dtOptionSusut: any = {};
  dtOptionsEkspedisi : any = {};
  dtTriggerSusut = new Subject();
  dtOptionsCancel: DataTables.Settings = {};
  dtOptionsTrip: any = {};
  dtOptionsDriver: any = {};
  dtOptionsRoute: any = {};
  dtOptionsTruck: any = {};
  dtOptionsPort: any = {};
  dtOptionsVehicle: any = {};
  dtOptionsPayment: any = {};
  dtOptionsPaymenDetail: any = {};
  dtTriggerPaymentDetail = new Subject();
  dtTriggerEkspedisi= new Subject(); 
  dtTriggerCancel = new Subject();
  dtTriggerRoute = new Subject();
  dtTriggerPort = new Subject();
  dtTriggerTrip = new Subject();
  dtTriggerTruck = new Subject();
  dtTriggerDriver = new Subject();
  dtTriggerVehicle = new Subject();
  dtTriggerPayment = new Subject();
  checkPaid: Boolean = false;
  dataRelation: Relation[]=[];
  dataVehicles: Vehicle[] = [];
  dataSignature: LocationSettingSignature[] = [];
  public debt: DebtPriority;
  dataListPayment: ListPaymentTrx[]=[];
  dataListCancelPayment: ListPaymentTrx[]=[]
  dataEkspedisi: Relation[]=[];
  dataSupplier: Relation[]=[];
  dataTruck: ListTruck[] = [];
  public dataType: MasterCode[] = [];
  dataDebt: DebtPriority[] = [];
  dataRoute: Route[] = [];
  dataDriver: Driver[] = [];
  dataPort: PortTransit[] = [];
  dataListDo:  Array<any>;
  dataListDo2: RespDetailDo[] = [];
  dataDriverDebt: ListDriverDebt[] = [];
  dataUpload: UploadFileRealizationDto[]=[];
  dataSave: UploadFileRealizationDto[]=[];
  public dataLabel: LabelTotal;
  totalTravelCost : number = 0;
  totalCost : number = 0;
  total : number = 0;
  travelCostPayment : number = 0;
  totalInvoice : number = 0;
  totalInvoiceVendor : number = 0;
  totalDebt : number = 0;
  profitLoss : number = 0;
  cicilan : number = 0;
  debtTotal : number = 0;
  debtPay : number = 0;
  name: string = '';
  dataTotalPayment: number = 0;
  qtySusut : number = 0;
  qtyPrice : number = 0;
  debtSusut : number = 0;
  debtTrip : number = 0;
  debtPremi : number = 0;
  dateType : string = "text";
  dataListTripRecord: ListTripRecord[] = [];
  dataCapacity: Capacity[] = [];
  ListCostType: CostType[] = [];
  action: string;
  // public dt: any;
  // public cb: any;
  public dtRoute: any;
  public cbRoute: any;
  public dtPort: any;
  public cbPort: any;
  public dtTruck: any;
  public cbTruck: any;
  public dtDriver: any;
  public cbDriver: any;
  public dtVehicle : any;
  public cbVehicle : any;
  public dtTrip: any;
  public cbTrip: any;
  public dtEkspedisi: any;
  public cbEkspedisi: any;
  public dataTripDto: any;
  public dataTripDtoRaw: ListTripRecordRaw;
  datePay: Date;
  dateNow: Date;
  sevenday: Date;
  hold: boolean;
  amounTable: number = 0;
  amountTotalCancel: number = 0;
  driverDebtCancel: number = 0;
  driverDebtCancelTemp: number = 0;
  companyCost: number = 0;
  dirverReturn: number = 0;
  costName: string;
  printDateFrom : Date;
  printDateTo : Date;
  transactionId: Guid;
  difDays: Boolean = false;
  detail: Boolean = false;
  ekspedisi: Boolean = false;
  paymentAdd: Boolean = false;
  realisasi: Boolean = false;
  invEdit: Boolean = false;
  fgs: Boolean = false;
  actionUpdate: Boolean = false;
  cost: Boolean = false;
  notSave: Boolean = false;
  totalHutangTrip = 0;
  actionCreate : string;
  fileName: string;
  idDoDetail: Guid;
  tipeUpload: string;
  authAcces: UserAuth[] =[];
  authAccessPlb: UserAuthNew[] =[];
  menuRealization: Boolean = false;
  realizationType: string;
  mainDO: string; 
  mainDoId: Guid;
  listMainDO: string;
  listMainDoNo: RespDetailDo[] = [];
  modelMainDoNo: RespDetailDo[] = [];
  listTrxDetailPaymentTrip: listTrxDetailPaymentTrip[] = [];
  routeId: string = "";
  capacityId: string = "";
  btnCreate: Boolean = true;
  btnUpload: Boolean = true;
  btnUpload2: Boolean = true;
  btnUpdate: Boolean = true;
  btnRealisasi: Boolean = true;
  btnClose: Boolean = true;
  btnfinish: Boolean = true;
  btnRollback: Boolean = true;
  btnRollbackCancel: Boolean = true;
  realisasiStatus: Boolean = true;
  basicCost: boolean = false;
  totalBasicCost: number = 0;
  totalBasicCostLoad: number = 0;
  select: number = 0;
  totalData: number = 0;
  getTotalUJ: Boolean = true;
  dateTotal = { transaction_id: null, action: null, do_detail_no: null, route_id: null, transit_port: null, depart_date: null, capacity_id: null };
  dataTambahanAdd: Array<{cost_type: Guid, category: string, type:string, amount: number, remark: string,  cost_name: string, approve: boolean, paid: boolean}> = [];
  dataTambahanDelete: Array<{id : Guid, cost_type: Guid, category: string, type:string, amount: number, remark: string,  cost_name: string, approve: boolean, paid: boolean}> = [];
  dataTambahan: Array<{id:Guid, cost_type: Guid, category: string, type:string, amount: number, remark: string,  cost_name: string, approve: boolean, paid: boolean, basic: boolean, 
    created_by: string, created_at: Date}> = [];
  dataTambahanDebt: Array<{driverdebt_id: Guid, reff: string, type: string, total: number, debt: number,  cicilan: number}> = [];
  dataDriverDebtTemp: Array<{ id : number, debt_id: Guid,reff: string, type: string, total: number,debt: number,  cicilan: number}> = [];
  listOfTrxDoDetail: ListOfTrxDoDetail[] = [];
  listOfTrxDoDetailDelete: ListOfTrxDoDetail[] = [];
  listOfTrxDoDetailAdd: ListOfTrxDoDetail[] = [];
  saveDataTambahan: Array<{id:Guid, cost_type: Guid, category: string, type:string, amount: number, remark: string,  cost_name: string, approve: boolean, paid: boolean, basic: boolean}> = [];
  listSusut: Array<{    id: Guid, location: Guid, transaction_id: Guid, transaction_detail_id: Guid, driver_id: Guid, type: Guid, truck_id: Guid, contract_no: string, 
    perihal: string, ba_no: string, sps_reason: string, sps_date: Date, amount: number, company_paid: number, cash_payment: number, trip_payment: number, 
    trip_payment_text: string, debt_remaining: number, paid: boolean, paid_on: Guid, debt_no: string, debt_date: Date, cancelled_at: Date, cancelled_by: Guid, created_at: Date, 
    created_by: Guid, updated_at: Date, updated_by: Guid, deleted: boolean, do_no: string, type_text: string, driver_name: string, driver_license: string, route_name: string, paid_on_text: string}> = [];
  ekspedisiName : string;
  @Input() vendorModel = {id: null, vendor: null, cost: null, driverName: null, truckName: null  };
  dataAdaptCost = { transit_port: null, route_id: null, capacity_id : null, depart_date: null, transaction_type_text: null, do_detail_no: null};
  @Input() selectModel = {id: null, doNo: '',doDate: null, materialName: '', trip_type: null, routeId: null, est_ton: null, est_pack: null  };
  @Input() tambahanModel = { cost_type: null, category: "", type:"", cost_name: null, amount: 0,remark: null  };
  @Input() transDetailModel = { do_detail_no: null, tonnage_send: 0, tonnage_received: 0 , pack_send: 0,
     pack_received: 0, invoice_no: null, remark: '', WB_reff: '' };
  @Input() tripModel = { id: null, transaction_type_text: null, vendor_id: null, cost: 0, driver_id: null, 
    truck_id: null, driver_name: '', truck_plate_number: '', vehicle_type_id: null, route_id: null, 
    seller_id: null, send_date: null, status: null, 
    delivered: false, delivery_date: null, reff: null, route_name:'', vehicle_type_name : '', transit_port_name : '',
    cost_type_external : null, cost_type_external_text: '', travel_cost_id: null, transit_port: null,
    system_reff: '', capacity_id: null, transporter_name: '', transactionCost: new Array(), transDetail: new Array(), do_detail_no: '',
     dataTravelCostId: '', debtAmount: 0, saveType: '',  totalHutang: 0, sisaCicilan: 0, paymentDate: null, 
     grandTotal: 0, dataDriverDebt: new Array(), skipPaymentStatus : false, 
    //update
    transDetailAdd: new Array(), transDetailDelete: new Array(), transactionCostAdd: new Array(), transactionCostDelete: new Array(),
    //realisasi
    depart_date: null, arrive_date: null,  receive_date: null, trip_type: null, realisasi: null, totalBasicCost: 0, pass: false
  }
  locationName: string;
  ponti: Boolean = false;
  totalSusut: number = 0;
  @Input() paymentModel = {transactionId: null};
  @Input() reffModel = {transaction_id: null, reff: null};
  @Input() wbreffModel = {transaction_detail_id: null, wbreff: null};
  @Input() setModel = {transaction_id: null};
  @Input() inputSave = {  list: new Array()}
  @Input() addCloseModel = {transaction_id: null, payDate: null, totalAmount: 0, totalUj: 0,companyCost: 0, sisa: 0, reason: '', baNo: ''};
  @Input() finishModel = {id: null, delivery: null};
  @Input() rollbackModel = {transaction_id: null, realizationType: null};
  @Input() cancelRemarkModel = {id: null, reason: null  };
  @Input() paymentDateModel = {id: null, payment_date: null};
  @Input() updateDOModel = {id: null, tonnage_send: null, tonnage_received: null, pack_send: null, pack_received: null, wbreff: null};
  @Input() susutModel = {transaction_id: null, receivedDate: null,susut_id: null, unitPrice: null,companyPaid: 0, 
    driverPaidTotal: 0, debtdate: null, totalSusut: 0, remarks: null};
  @Input() printModel = {  id: null,subscription_id: null, diketahui: null, diketahui2: null, diketahui3: null, diketahui4: null, disetujui: null,
    hide_diketahui2: false,hide_diketahui3: false,hide_diketahui4: false, susut: null, transaction_id: null, location: null}

  @Input() searchModel = { routeName: null, vendorName: null, reff: null, systemReff: null,driverLicense: null,truckPlatNumber: null,
    departDateFrom: null,departDateTo: null,arriveDateFrom: null,arriveDateTo: null,receiveDateFrom: null,receiveDateTo: null,
    doNo: null,mainDoNo: null,spkNo: null, realisasi: null, distributed: null, cancel: null, complete: null};
    @Input() disableBox: boolean = false;
  constructor(public service: TransportService, private router: Router, private spinner: NgxSpinnerService, fb: FormBuilder, private changeDetectorRef: ChangeDetectorRef) {
    this.closeForm = fb.group({
      'payDate': [null, Validators.required],
      'reason': [null, Validators.required],
      // 'totalAmount': [],
      // 'companyCost': [],
      // 'totalUangJalan': [],
      // 'sisa': [],
      'terms': [false]
    });
  }

  filterCategory(people: any[]): any[] {
    return people.filter(p => p.category != "Uang Jalan");
  }

    ngOnInit(): void { 
      this.dateNow = new Date();
      this.datePay = new Date();
      this.userAcces();
      if (this.router.url.includes("realization-list")){
        this.menuRealization = true;
        this.realizationType = "REALISASI";
      }
      else{
        this.menuRealization = false;
        this.realizationType = "BAGI DO";
      }
      this.dtOptionsTrip = {
        pageLength: 10,
        lengthMenu : [10,50,100,250,1000],
        ordering : false,
        scrollX: true,
        scrollCollapse: true,
        serverSide: true,
        processing: true,
        searching : false,
        // ajax: (dataTablesParameters: any, callback, dataDto: ListTripRecord) => {
          ajax: (dataTablesParameters: any, callback, dataDto: ListTripRecordRaw) => {
          if (this.menuRealization == false){
            dataDto.distributed  = true;
          }
          else{
            dataDto.realisasi  = true;
            dataDto.distributed  = true;
          }
         this.dataTripDto = dataDto
         this.dataTripDtoRaw = dataDto;
         this.dtTrip = dataTablesParameters; this.cbTrip = callback;
         this.getDataListTripRecord(dataTablesParameters, callback, dataDto, true );

        },
        columnDefs: [
          { width: 100, targets: [1,2,3,4,5,6,7,8,10,11,12,13,14,15,16,17,18,19,20],
          },
          { width: 250, targets: [0] },
        ],
      };

      this.dtOptionsRoute = {
        retrieve: true,
        pageLength: 5,
        scrollX: true,
        scrollCollapse: true,
        serverSide: true,
        processing: true,
        searching : false,
        ajax: (dataTablesParameters: any, callback) => {
         this.dtRoute = dataTablesParameters; this.cbRoute = callback;
         this.getDataRoute(dataTablesParameters, callback,"","","","", null,true);
        },
        columnDefs: [
          { width: 100, targets: [0,1,4,5,6,7,8] },
          { width: 200, targets: 2 },
          { width: 200, targets: 3 },
         ],
      };

      this.dtOptionsTruck = {
        retrieve: true,
        pageLength: 5,
        scrollX: true,
        scrollCollapse: true,
        serverSide: true,
        processing: true,
        searching : false,
        ajax: (dataTablesParameters: any, callback) => {
         this.dtTruck = dataTablesParameters; this.cbTruck = callback;
         this.getDataTruck(dataTablesParameters, callback, "","","",true);
        },
        columnDefs: [
          { width: 100, targets: 0 },
          { width: 150, targets: 1 },
          { width: 150, targets: 2 },
        ],
        
      };

      this.dtOptionsDriver = {
        retrieve: true,
        pageLength: 5,
        scrollX: true,
        scrollCollapse: true,
        serverSide: true,
        processing: true,
        searching : false,
        ajax: (dataTablesParameters: any, callback) => {
         this.dtDriver = dataTablesParameters; this.cbDriver = callback;
         this.getDataDriver(dataTablesParameters, callback, "", "","","","", "","","","", "","","", "",  true);
         },
        columnDefs: [
          { width: 150, targets: [0,2,3,4,5,6,7,8,9,10,12,13,14,15] },
          { width: 200, targets: [1] },
          { width: 300, targets: [11] },
        ],
      };

      this.dtOptionsVehicle = {
        retrieve: true,
        pageLength: 5,
        scrollX: true,
        scrollCollapse: true,
        serverSide: true,
        processing: true,
        searching : false,
        ajax: (dataTablesParameters: any, callback) => {
         this.dtVehicle = dataTablesParameters; this.cbVehicle = callback;
         this.getDataVehicle(dataTablesParameters, callback, "", "",true);
        },
        columnDefs: [
          { width: 100, targets: 0 },
          { width: 250, targets: 1 },
          { width: 250, targets: 2 },
        ],
      };

      this.dtOptionsPort = {
        retrieve: true,
        //paging: false,
         ordering : false,
        pageLength: 10,
        scrollX: true,
        scrollCollapse: true,
        serverSide: true,
        processing: true,
        searching : false,
        ajax: (dataTablesParameters: any, callback) => {
         this.dtPort = dataTablesParameters; this.cbPort = callback;
         this.getDataPort(dataTablesParameters, callback, this.routeId, this.capacityId, "",true);
        },
        columnDefs: [
          { width: 100, targets: 0 },
          { width: 400, targets: 1 },
          { width: 150, targets: 2 },
        ],
      };
      
      this.dtOptionsPayment = {
        retrieve: true,
        scrollX: true,
        scrollCollapse: true,
        searching : false,
        columnDefs: [
          { width: 150, targets: [0,2,3,4] },
          { width: 40, targets: [1] },
          { width: 40, targets: [5,6,7,8,9,10,11,12] },
        ],
      };

      this.dtOptionsPaymenDetail = {
        retrieve: true,
        searching : false,
        ordering : false,
        info : false,
        columnDefs: [
          { width: 200, targets: [0,1,2,3,4] },
        ],
      };

      // this.dtOptionsCancel = {
      //   retrieve: true,
      //   scrollX: true,
      //   scrollCollapse: true,
      //   searching : false
      // };

      this.dtOptionsCancel = {
        pagingType: 'full_numbers',
        pageLength: 2,
        searching : false
      };

      this.dtOptionsEkspedisi = {
        retrieve: true,
        pageLength: 10,
        ordering : false,
        scrollX: true,
        scrollCollapse: true,
        serverSide: true,
        processing: true,
        searching : false,
        ajax: (dataTablesParameters: any, callback) => {
          this.dtEkspedisi = dataTablesParameters; this.cbEkspedisi = callback;
         this.getDataEkspedisi(dataTablesParameters, callback, $('#codeEks').val().toString(), $('#nameEks').val().toString(), 
         $('#addressEks').val().toString(),"Ekspedisi",$('#slEks').val().toString(),true);
        },
        columnDefs: [
          { width: 100, targets: [0,1,4,5,6,7,8,9,10] },
          { width: 200, targets: 2 },
          { width: 300, targets: 3 },
        ],
      };


      this.getSignature();
     

      const that = this;

      $('.searchInput').on("keyup", function(e) {
            
        var key = e.which;
        if (key == 13) // the enter key ascii code
        {
          that.dataTripDto.transaction_type =  $('#transaction_type').val().toString();
          that.dataTripDto.reff =  $('#reff').val().toString();
          that.dataTripDto.system_reff =  $('#system_reff').val().toString();
          that.dataTripDto.do_detail =  $('#do_detail').val().toString();
          that.dataTripDto.route_name =  $('#route_name').val().toString();
          that.dataTripDto.route_to =  $('#route_to').val().toString();
          that.dataTripDto.relation_name =  $('#relation_name').val().toString();
          that.dataTripDto.cost =  $('#cost').val().toString();
          that.dataTripDto.driver_name =  $('#driver_name2').val().toString();
          that.dataTripDto.truck_plate_number =  $('#truck_plate_number').val().toString();
          that.dataTripDto.vehicle_type_name =  $('#vehicle_type_name').val().toString();
          that.dataTripDto.depart_date =  $('#depart_date').val().toString();
          that.dataTripDto.arrive_date =  $('#arrive_date').val().toString();
          if (that.menuRealization == false){
          that.dataTripDto.status_text = "DO Distributed";
          }
          else{
          that.dataTripDto.status_text =  $('#status_text').val().toString();
          }
          that.dataTripDto.delivery_date =  $('#delivery_date').val().toString();
          that.dataTripDto.transit_port_name =  $('#transit_port_name').val().toString();
          that.dataTripDto.canceled_remark =  $('#canceled_remark').val().toString();
          that.dataTripDto.created_by_text =  $('#created_by_text').val().toString();
          that.dataTripDto.created_at_text =  $('#created_at_text').val().toString();
          that.dataTripDto.updated_by_text =  $('#updated_by_text').val().toString();
          that.dataTripDto.updated_at_text =  $('#updated_at_text').val().toString();
          that.getDataListTripRecord(that.dtTrip, that.cbTrip, that.dataTripDto, false );
        }
      });
    
      $('.searchInputTruck').on("keyup", function(e) {
        var key = e.which;
        if (key == 13) // the enter key ascii code
        {
          that.getDataTruck(that.dtTruck, that.cbTruck, $('#platsearch').val().toString(), $('#codesearch').val().toString(),
           $('#locationsearch').val().toString(),false);
        }
      });  

      $('.searchInputPort').on("keyup", function(e) {
        var key = e.which;
        if (key == 13) // the enter key ascii code
        {
          that.getDataPort(that.dtPort, that.cbPort, that.routeId,that.capacityId,$('#namesearchport').val().toString(),false);
        }
      });  

      $('.searchInputV').on("keyup", function(e) {
        var key = e.which;
        if (key == 13) // the enter key ascii code
        {
          that.getDataVehicle(that.dtVehicle, that.cbVehicle, $('#codesearchV').val().toString(), $('#namesearchV').val().toString(),false);
        }
      }); 

      $('.searchInputD').on("keyup", function(e) {
        var key = e.which;
        if (key == 13) // the enter key ascii code
        {
          that.getDataDriver(that.dtDriver, that.cbDriver, $('#driver_name').val().toString(), $('#driver_license').val().toString(), 
          $('#driver_license_type').val().toString(),$('#agama').val().toString(),$('#birthplace').val().toString(),
          $('#ktp_no').val().toString(),$('#tanggal_lahir').val().toString(),$('#sl_code').val().toString(),
          $('#sl_code2').val().toString(),$('#sl_code3').val().toString(),$('#address').val().toString(),
          $('#valid_to').val().toString(),$('#valid_to_contract').val().toString(), false);
        }
      });

      $('.searchRute').on("keyup", function(e) {
        var key = e.which;
        if (key == 13) // the enter key ascii code
        {
          that.getDataRoute(that.dtRoute, that.cbRoute,  $('#nameRute').val().toString(),  $('#typeRute').val().toString()
          ,  $('#unloadRute').val().toString(),  $('#loadRute').val().toString(), null,false);
        }
      }); 

      $('.searchEkspedisi').on("keyup", function(e) {
        var key = e.which;
        if (key == 13) // the enter key ascii code
        {
          that.getDataEkspedisi(that.dtEkspedisi, that.cbEkspedisi, $('#codeEks').val().toString(), $('#nameEks').val().toString(), 
          $('#addressEks').val().toString(), "EKSPEDISI",
          $('#slEks').val().toString(),false);
        }
      });  

      this.service.getListCostTypeAll(this.realizationType).subscribe(resp => {
      this.ListCostType = resp;
    });
    
      this.getDataType();
      this.getDoMain();
      this.locationName = localStorage.getItem('locationname');
      if (this.locationName != "Pontianak"){
        this.ponti = false;
      }
      else{
        this.ponti = true;
      }
    }   

    getDoMain(){
      this.dataListDo2 = [];
      this.modelMainDoNo = [];
      this.listMainDO = null;
      this.listMainDoNo = [];
      this.service.getDoDetailByRoute("").subscribe(resp => {
        this.dataListDo = resp;
        this.listMainDoNo = Object.values(
          resp.reduce((a, b) => {
            if (!a[b.main_do]) a[b.main_do] = b 
              return a
           }, {})
        )
      });
    }
    ngAfterViewInit(): void {
      this.dtTriggerTrip.next(this.dtOptionsTrip);
      //this.dtTriggerSpk.next(0);
      // this.dtTriggerCustomer.next(0);
      // this.dtTriggerSupplier.next(0);
    }
  
    ngOnDestroy(): void {
      this.dtTriggerTrip.unsubscribe();
      //this.dtTriggerTrip.next(this.dtOptionsTrip);
      // this.dtTriggerCustomer.unsubscribe();
      // this.dtTriggerSupplier.unsubscribe();
    }

    setDate(){
      var today = moment().format('YYYY-MM-DD');
      $('.setDate').val(today);
    }

    getDataRoute(dataTablesParameters, callback, route_name: string, route_type: string, unloadtext: string, loadtext: string, material_id: string,padging : boolean){ 
      
      var page = 1;
      if (padging == true){
        page = parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length) + 1;
      }
      this.service.getListRoute(dataTablesParameters, page, dataTablesParameters.length, route_name, route_type, unloadtext, loadtext, material_id)
      .subscribe(resp => { 
          this.dataRoute = resp.data;
          callback({
            recordsTotal: resp.totalCount,
            recordsFiltered: resp.totalCount,
             data:  []
          });
      });
    }

    getDataVehicle(dataTablesParameters, callback, code: string, name: string, padging : boolean){ 
      var page = 1;
      if (padging == true){
        page = parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length) + 1;
      }
      this.service.getDataVehicle(dataTablesParameters, page, dataTablesParameters.length, code, name)
      .subscribe(resp => { 
          this.dataVehicles = resp.data;
          callback({
            recordsTotal: resp.totalCount,
            recordsFiltered: resp.totalCount,
             data:  []
          });
      });
    }

  // getDataListTripRecord(dataTablesParameters, callback,data: ListTripRecord, padging){
  //   var page = 1;
  //     if (padging == true){
  //       page = parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length) + 1;
  //     }
  //     data.page = page;
  //     data.perPage = dataTablesParameters.length;
  //     this.service.getListTripRecord(data)
  //     .subscribe(resp => { 
  //         this.dataListTripRecord = resp.data;
  //         callback({
  //           recordsTotal: resp.totalCount,
  //           recordsFiltered: resp.totalCount,
  //            data:  []
  //         });
  //     });
  // }

  
  getDataListTripRecord(dataTablesParameters, callback,data: ListTripRecordRaw, padging){
    if (this.menuRealization == false){
      data.distributed = true;
      data.cancel = false;
      data.cancel = false;
      data.complete = false;
      data.realisasi = false;
    }
    var page = 1;
      if (padging == true){
        page = parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length) + 1;
      }
      data.page = page;
      data.perPage = dataTablesParameters.length;
      this.service.getListTriprecordRaw(data)
      .subscribe(resp => { 
          this.dataListTripRecord = resp;
          callback({
            recordsTotal: dataTablesParameters.length,
            recordsFiltered: dataTablesParameters.length,
             data:  []
          });
      });
  }

  getDataCapacity(dataTablesParameters, callback, code: string, name: string, vehicle: string, padging : boolean){ 
    var page = 1;
    if (padging == true){
      page = parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length) + 1;
    }
    this.service.getDataCapacity(dataTablesParameters, page, dataTablesParameters.length, code, name, vehicle)
    .subscribe(resp => { 
        this.dataCapacity = resp.data;
        callback({
          recordsTotal: resp.totalCount,
          recordsFiltered: resp.totalCount,
           data:  []
        });
    });
  }

  getDataTruck(dataTablesParameters, callback, platNumber: string, codeCapacity: string, location: string, padging : boolean){ 
    var page = 1;
    if (padging == true){
      page = parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length) + 1;
    }
    this.service.getListTruck(dataTablesParameters, page, dataTablesParameters.length, platNumber, codeCapacity, location)
    .subscribe(resp => { 
        this.dataTruck = resp.data;
        callback({
          recordsTotal: resp.totalCount,
          recordsFiltered: resp.totalCount,
           data:  []
        });
    });
  }

  getDataDriver(dataTablesParameters, callback,  driver_name: string, driver_license: string, driver_license_type: string, 
    agama: string, birthplace: string, ktp_no: string, tanggal_lahir: string, sl_code: string, sl_code2: string,
    sl_code3: string, address: string, valid_to: string, valid_to_contract: string, padging : boolean){ 
    var page = 1;
    if (padging == true){
      page = parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length) + 1;
    }
    this.service.getListDriver(dataTablesParameters, page, dataTablesParameters.length, driver_name, driver_license, driver_license_type, agama,
      birthplace,ktp_no,tanggal_lahir, sl_code, sl_code2, sl_code3, address, valid_to, valid_to_contract, "true", "false")
    .subscribe(resp => { 
        this.dataDriver = resp.data;
        callback({
          recordsTotal: resp.totalCount,
          recordsFiltered: resp.totalCount,
          data:  []
        });
    });
  }

  selectTransport(){
    if (this.tripModel.transaction_type_text == "EKSPEDISI"){
      this.ekspedisi = true;
      this.fgs = false;
      this.cost = true;
    }
    else{
      this.ekspedisi = false;
      this.fgs = true;
      this.cost = false;
    }
  }

  getPaymentTrx(id: Guid){
    this.paymentModel.transactionId = id;
    this.service.getListPaymentTrx(this.paymentModel).subscribe(resp => {
      this.dataListPayment = resp;
      if (resp.filter(x => x.deleted != true).length > 0){
        this.getTotalUJ = true;
      }
      else{
        this.getTotalUJ = false;
      }
    });
    
    // this.dtTriggerPayment.next(0);
  }
  

  setPayment(){
    var cValue = formatDate(this.datePay, 'yyyy-MM-dd', 'en-US');
    this.service.getAccClosing(cValue).subscribe(resp => { 
      if (resp.succes == false){
        this.messageWarning("Pembayaran uang jalan untuk tanggal tersebut sudah ditutup!");
        return;
      }
      if(this.cicilan < 0){
        this.messageWarning("Sisa cicilan tidak boleh kurang dari 0 !");
        return;
      }
    });
  }

  selectCost(data: CostType){
    this.basicCost = data.basic_cost;
    this.tambahanModel.cost_name = data.name;
    this.tambahanModel.cost_type = data.id; 
    if (this.menuRealization == true){
      this.tambahanModel.category = "Adjustment";
    }
    else{
      this.tambahanModel.category = "Uang Jalan Bagi DO";
    }
    this.tambahanModel.type = data.type_text;
  }

  getLabelCalculate(){
    if (this.selectModel.doNo == null || this.tripModel.route_id == null || this.tripModel.depart_date == null || this.listOfTrxDoDetail.length == 0){
      Swal.fire({
        title: "Pencocokan Uang Jalan Gagal!",
        type: 'warning',
        confirmButtonText: '      Ok!     '
      });
      this.tabset.tabs[0].active = true;
    }
    else{
      this.dateTotal.capacity_id = this.tripModel.capacity_id;
      if (this.listOfTrxDoDetail.length > 0){ 
        this.dateTotal.do_detail_no =  this.listOfTrxDoDetail[0].do_detail_id;
      }
      this.dateTotal.route_id = this.tripModel.route_id;
      this.dateTotal.transit_port = this.tripModel.transit_port;
      this.dateTotal.depart_date = this.tripModel.depart_date;

      this.service.getLabeltotal(this.dateTotal).subscribe(resp => {

        this.amounTable = 0;
        if (this.dateTotal.action == "Add"){     
          this.dataTambahan.forEach(item => {
            if(item.id == null){
              if (item.type == "PIUTANG" && item.category != "Uang Jalan"){
                this.amounTable-= item.amount;
              }
              if (item.type == "HUTANG" && item.category != "Uang Jalan"){
                this.amounTable+= item.amount;
              }
            }
          }) 
          // this.amounTable = this.dataTambahan.filter(x => x.category != "Uang Jalan").reduce((sum, obj) => {
          //   return sum + obj.amount;
          // }, 0);   
          
          this.totalBasicCostLoad = 0; 
          this.totalTravelCost = resp.totalTravelCost;
          this.totalCost = resp.totalCost + this.amounTable;
          console.log("resp totalcost "+resp.totalCost+ " : akhir : "+this.totalCost )
          this.totalInvoice = resp.totalInvoice;
          this.totalInvoiceVendor = resp.totalInvoiceVendor;
          this.profitLoss = resp.profitLoss - this.amounTable;
          this.totalBasicCostLoad = resp.basicCost;
          let minus ="";
          let plus ="";
          if(this.dataTambahan.length == 0){
            resp.listCost.filter(x => x.cost_type_id == Guid.parse('fa54c9ab-744a-4973-9787-3856aea50bc0') ||  x.cost_type_id == Guid.parse('8ded1d15-ba21-46f7-95f1-ea0f2073ab57')).forEach( x =>{
            //resp.listCost.filter(x => x.type_text == "PIUTANG").forEach( x =>{
              minus += ","+-1*x.amount
              this.dataTambahan.push({id : null, cost_type: x.cost_type_id, category: "Uang Jalan", type:null,
                amount: -1*x.amount, remark: "",  cost_name: null, approve : true, paid : false, basic: true, created_at: null, created_by: null});
            })
            resp.listCost.filter(x => x.cost_type_id != Guid.parse('fa54c9ab-744a-4973-9787-3856aea50bc0') &&  x.cost_type_id != Guid.parse('8ded1d15-ba21-46f7-95f1-ea0f2073ab57')).forEach( x =>{
            //resp.listCost.filter(x => x.type_text == "HUTANG").forEach( x =>{
              plus += ","+x.amount
              this.dataTambahan.push({id : null, cost_type: x.cost_type_id, category: "Uang Jalan", type:null,
                amount: x.amount, remark: "",  cost_name: null, approve : true, paid : false, basic: true, created_at: null, created_by: null});
            })
            
            console.log("minus :"+minus)
            
            console.log("plus :"+plus)
          }
        }
        if (this.dateTotal.action == "Update"){
          let tempAmount = 0;
          this.dataTambahan.forEach(item => {
            if(item.id == null){
              if (item.type == "PIUTANG"){
                 tempAmount-= item.amount;
              }
              else{
                tempAmount+= item.amount;
              }
            }
          })
          this.amounTable = this.dataTambahan.reduce((sum, obj) => {
            return sum + obj.amount;
          }, 0);
          this.totalTravelCost = resp.totalTravelCost;
          this.totalCost = resp.totalCost+tempAmount;
          console.log("temp amount "+tempAmount)
          this.totalInvoice = resp.totalInvoice;
          this.totalInvoiceVendor = resp.totalInvoiceVendor;
          this.profitLoss = resp.profitLoss-tempAmount;
          this.paymentClear();
        }
      });
    }

  }

  getDataPort(dataTablesParameters, callback, route: string, capacity: string, name: string,padging : boolean){ 
    var date = "";
    if (this.tripModel.depart_date != null){
      date = this.tripModel.depart_date.toString();
    }
    var page = 1;
    if (padging == true){
      page = parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length) + 1;
    }
    this.service.getListPortTransit(dataTablesParameters, page, dataTablesParameters.length, route, capacity, name, date)
    .subscribe(resp => { 
        this.dataPort = resp.data;
        callback({
          recordsTotal: resp.totalCount,
          recordsFiltered: resp.totalCount,
           data:  []
        });
    });
  }

  setNull(){
    this.invEdit = false;
    this.difDays = false;
    this.notSave = false;
    this.getTotalUJ = false;
    this.realisasiStatus = false;
    this.dateType = "date";
    this.actionCreate = "create";
    this.paymentClear();
    this.ekspedisi = false;
    this.fgs = false;
    this.actionUpdate = false;
    this.dataListDo2 = null;
    this.listOfTrxDoDetail .length = 0;
    this.totalTravelCost = 0;
    this.totalCost = 0;
    this.totalInvoice = 0;
    this.totalInvoiceVendor = 0;
    this.profitLoss = 0;
    this.dataListPayment.length = 0;
    var today = moment().format('YYYY-MM-DD');
    this.tripModel = { id: null, transaction_type_text: null, vendor_id: null, cost: 0, driver_id: null, 
      truck_id: null, driver_name: null, truck_plate_number: null, vehicle_type_id: null, route_id: null, trip_type: null, 
      depart_date: today, arrive_date: today, seller_id: null, send_date: null, receive_date: null, status: null, 
      delivered: false, delivery_date: today, reff: '', route_name:'', vehicle_type_name : '', transit_port_name : '',
      cost_type_external : null, cost_type_external_text: '', travel_cost_id: null, transit_port: null,
      system_reff: '', capacity_id: null, transporter_name: '', transactionCost: new Array(), transDetail: new Array(), do_detail_no: '',
       dataTravelCostId: '' , debtAmount: null, saveType: '',  totalHutang: 0, sisaCicilan: 0, paymentDate: today, 
       grandTotal: 0, dataDriverDebt: new Array(), skipPaymentStatus : false, transDetailAdd: new Array(),
      transDetailDelete: new Array(), transactionCostAdd: new Array(), transactionCostDelete: new Array(), realisasi: null, totalBasicCost :0, pass: false}
    this.ekspedisi = false;
    this.fgs = true;
    this.cost = false;
    this.tripModel.transaction_type_text = "FGS";
    this.dataTambahan = [];
    this.dateTotal = { transaction_id: null, action: null, do_detail_no: null, route_id: null, transit_port: null, depart_date: null, capacity_id: null };
    this.dateTotal.action = "Add";
    this.detail = false;
    this.listOfTrxDoDetail = [];
    this.dataListPayment = [];
    this.datePay = new Date();
    this.listMainDO = null;    
    this.model = null;
    this.tambahanModel.amount = 0;
    this.tambahanModel.remark = "";
    this.clearPayment();
    this.dataListDo2 = [];
    this.getDoMain();
  }

 

  onSelectTripRealization(data: any){
    this.invEdit = false;
    this.notSave = false;
    this.service.disableEditTrip(data.id).subscribe(resp => {
    // if (resp > 0){
    //   return Swal.fire({
    //     title: "Trip tersebut sudah punya invoice!",
    //     type: 'warning',
    //     confirmButtonText: '      Ok!     '
    //   });
    // }
    // else{
      if (resp > 0){
        this.invEdit =true
      }
      this.realisasi = false;
      if (data.status_text == "Realisasi"){
        Swal.fire({
          title: "Data Sudah Realisasi",
          type: 'warning',
          confirmButtonText: '      Ok!     '
        });
        return;
      }else{
       // this.ModalTripOpen.nativeElement.click();
       this.ModalTripOpen.nativeElement.click();
       this.getDoMain();
       this.dataTambahan = [];
       this.listOfTrxDoDetail = [];
       this.dataListPayment = [];
       this.dataTambahanDebt = [];
       this.listOfTrxDoDetailAdd = [];
       this.listOfTrxDoDetailDelete = [];
       this.dataTambahanAdd = [];
       this.dataTambahanDelete = [];
       if (data.status_text == "Canceled"){
         this.detail = true;
       }
       else{
         this.detail = false;
       }
       if (data.status_text == 'Realisasi'){
         this.realisasiStatus = true;
       }
       else{
         this.realisasiStatus = false;
       }
       this.model = null;
       this.tambahanModel.amount = 0;
       this.tambahanModel.remark = "";
       this.idTrip = data.id;
       this.dateType = "text";
       this.actionCreate = "update";
       this.paymentClear();
       if (data.vendor_id != null){
         this.ekspedisi = true;
       }
       else{
         this.ekspedisi = false;
       }
       this.fgs = true;
       this.actionUpdate = true;
       this.dateTotal.action = "Update";
       this.dateTotal.transaction_id = data.id;
       this.getPaymentTrx(data.id);
       this.tripModel = data;
       this.tripModel.transaction_type_text= data.transaction_type;
       this.service.getListTransactionCost(data.id).subscribe(resp => {
         if (resp != null){
           this.dataTambahan = [];
           for (let i = 0; i < resp.length; i++) {
             this.dataTambahan.push({
               id: resp[i].id,
               cost_type: resp[i].cost_type_id,
               category: resp[i].category_text,
               type: resp[i].type,
               amount : resp[i].amount,
               remark: resp[i].remark,
               cost_name: resp[i].cost_type,
               approve: resp[i].approved,
               paid: resp[i].paid,
               basic: resp[i].basic_cost,
               created_at: resp[i].created_at,
               created_by: resp[i].created_by_text
           });
         
       }
         }
       });
       this.getDoDetailTrans(data.id);
       this.idTrip = data.id;
       if (this.tripModel.transaction_type_text == "EKSPEDISI"){
         this.cost = true;
       }
       else{
         this.cost = false;
       }
        this.realisasi = true;
        var today = moment().format('YYYY-MM-DD');
        // this.tripModel.send_date = today;
        // this.tripModel.receive_date = today;
        this.tripModel.arrive_date = today;
      }
      this.getDoMain();
    //}
    
  });
  }
  
  showDataTrip(data: any){
    this.notSave = true;
    this.ModalTripOpen.nativeElement.click();
    this.getDoMain();
    this.dataTambahan = [];
    this.listOfTrxDoDetail = [];
    this.dataListPayment = [];
    this.dataTambahanDebt = [];
    this.listOfTrxDoDetailAdd = [];
    this.listOfTrxDoDetailDelete = [];
    this.dataTambahanAdd = [];
    this.dataTambahanDelete = [];
    if (data.status_text == "Canceled"){
      this.detail = true;
    }
    else{
      this.detail = false;
    }
    if (data.status_text == 'Realisasi'){
      this.realisasiStatus = true;
    }
    else{
      this.realisasiStatus = false;
    }
    // if (this.menuRealization == true){
    //   this.realisasi = true;
    // }
    // else{
    //  this.realisasi = false;
    // }
    this.realisasi = false;
    this.model = null;
    this.tambahanModel.amount = 0;
    this.tambahanModel.remark = "";
    this.idTrip = data.id;
    this.dateType = "text";
    this.actionCreate = "update";
    this.paymentClear();
    if (data.vendor_id != null){
      this.ekspedisi = true;
    }
    else{
      this.ekspedisi = false;
    }
    this.fgs = true;
    this.actionUpdate = true;
    this.dateTotal.action = "Update";
    this.dateTotal.transaction_id = data.id;
    this.getPaymentTrx(data.id);
    this.tripModel = data;
    this.tripModel.transaction_type_text= data.transaction_type;
    this.service.getListTransactionCost(data.id).subscribe(resp => {
      if (resp != null){
        this.dataTambahan = [];
        for (let i = 0; i < resp.length; i++) {
          this.dataTambahan.push({
            id: resp[i].id,
            cost_type: resp[i].cost_type_id,
            category: resp[i].category_text,
            type: resp[i].type,
            amount : resp[i].amount,
            remark: resp[i].remark,
            cost_name: resp[i].cost_type,
            approve: resp[i].approved,
            paid: resp[i].paid,
            basic: resp[i].basic_cost,
            created_at: resp[i].created_at,
            created_by: resp[i].created_by_text
        });
      
    }
      }
    });
    this.getDoDetailTrans(data.id);
    this.idTrip = data.id;
    if (this.tripModel.transaction_type_text == "EKSPEDISI"){
      this.cost = true;
    }
    else{
      this.cost = false;
    }
  }

  onSelectTrip(data: any){
    this.invEdit = false;
    this.difDays = false;
    this.notSave = false;
    this.service.disableEditTrip(data.id).subscribe(resp => {
      if (resp > 0){
        this.invEdit = true;
      }
    //   if (resp > 0){
    //     return Swal.fire({
    //       title: "Trip tersebut sudah punya invoice!",
    //       type: 'warning',
    //       confirmButtonText: '      Ok!     '
    //     });
    //   }
    //   else{
        this.ModalTripOpen.nativeElement.click();
        this.getDoMain();
        this.dataTambahan = [];
        this.listOfTrxDoDetail = [];
        this.dataListPayment = [];
        this.dataTambahanDebt = [];
        this.listOfTrxDoDetailAdd = [];
        this.listOfTrxDoDetailDelete = [];
        this.dataTambahanAdd = [];
        this.dataTambahanDelete = [];
        if (data.status_text == "Canceled"){
          this.detail = true;
        }
        else{
          this.detail = false;
        }
        if (data.status_text == 'Realisasi'){
          this.realisasiStatus = true;
          var start = moment(data.receive_date, "YYYY-MM-DD");
          var end = moment().format('YYYY-MM-DD');
          var difDay = Math.abs(moment.duration(start.diff(end)).asDays())
          // if(difDay > 14){
          //   this.difDays = true;
          // }
          // else{
          //   this.difDays = false;
          // }
        }
        else{
          this.realisasiStatus = false;
        }
        // if (this.menuRealization == true){
        //   this.realisasi = true;
        // }
        // else{
        //  this.realisasi = false;
        // }
        this.realisasi = false;
        this.model = null;
        this.tambahanModel.amount = 0;
        this.tambahanModel.remark = "";
        this.idTrip = data.id;
        this.dateType = "text";
        this.actionCreate = "update";
        this.paymentClear();
        if (data.vendor_id != null){
          this.ekspedisi = true;
        }
        else{
          this.ekspedisi = false;
        }
        this.fgs = true;
        this.actionUpdate = true;
        this.dateTotal.action = "Update";
        this.dateTotal.transaction_id = data.id;
        this.getPaymentTrx(data.id);
        this.tripModel = data;
        this.tripModel.transaction_type_text= data.transaction_type;
        this.service.getListTransactionCost(data.id).subscribe(resp => {
          if (resp != null){
            this.dataTambahan = [];
            for (let i = 0; i < resp.length; i++) {
              this.dataTambahan.push({
                id: resp[i].id,
                cost_type: resp[i].cost_type_id,
                category: resp[i].category_text,
                type: resp[i].type,
                amount : resp[i].amount,
                remark: resp[i].remark,
                cost_name: resp[i].cost_type,
                approve: resp[i].approved,
                paid: resp[i].paid,
                basic: resp[i].basic_cost,
                created_at: resp[i].created_at,
                created_by: resp[i].created_by_text,
            });
          
        }
          }
        });
        this.getDoDetailTrans(data.id);
        this.idTrip = data.id;
        if (this.tripModel.transaction_type_text == "EKSPEDISI"){
          this.cost = true;
        }
        else{
          this.cost = false;
        }

     // }
   
    })
  } 

  
  onSelectFinish(data: any){
    var today = moment().format('YYYY-MM-DD');
    this.finishModel.id =  data.id;
    this.finishModel.delivery = today;
    this.service.getDoDetailTrans(data.id).subscribe(resp => {
      this.listOfTrxDoDetail = resp;
    });
  }

  finishTrip(){
    if(this.finishModel.id == null || this.finishModel.delivery == null){
      Swal.fire({
        title: "Data Belum Lengkap",
        type: 'warning',
        confirmButtonText: '      Ok!     '
      });
      return;
    }
    Swal.fire({
      title: 'Anda Yakin?',
      type: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '      Yes     '
    }).then((result) => {
      if (result.value) {
        this.spinner.show();
        this.service.finishTrip(this.finishModel).subscribe(resp => {
          this.spinner.hide();
          if (resp.succes == true){
            Swal.fire({
              type: 'success',
              title: ""+resp.text,
              confirmButtonText: '      Ok!     '
            }).then((result) => {
              if (result.value) { 
                this.getDataListTripRecord(this.dtTrip, this.cbTrip, this.dataTripDto, true );
                this.modalFinishClose.nativeElement.click();
              }
            
            });
          }
          else{
            Swal.fire({
              title: ""+resp.text,
              type: 'warning',
              confirmButtonText: '      Ok!     '
            });
          }
        });
      }
    });
  }

  claimSusut(){
    this.dataSusut.transaction_id = this.dateTotal.transaction_id;
    this.dataSusut.qty_susut = this.qtySusut;
    this.dataSusut.debt_trip = this.debtTrip;
    this.dataSusut.debt_premi = this.debtPremi;
    this.dataSusut.truck_id = this.tripModel.truck_id;
    this.dataSusut.driver_id = this.tripModel.driver_id;
    if (this.detailIdDoSusut == null){
      Swal.fire({
        title: "Silahkan Pilih DO Kecil!",
        type: 'warning',
        confirmButtonText: '      Ok!     '
      });
      return;
    }
    if (this.debtSusut > (this.debtTrip+this.debtPremi)){
      Swal.fire({
        title: "Pembagian total hutang belum pas!",
        type: 'warning',
        confirmButtonText: '      Ok!     '
      });
      return;
    }
    if (this.debtSusut < (this.debtTrip+this.debtPremi)){
      Swal.fire({
        title: "Pembagian total hutang belum pas!",
        type: 'warning',
        confirmButtonText: '      Ok!     '
      });
      return;
    }
    if (this.listSusut.length > 0){
      Swal.fire({
        title: "Data sudah ada pada Database",
        type: 'warning',
        confirmButtonText: '      Ok!     '
      });
      return;
    }
    Swal.fire({
      title: 'Anda Yakin?',
      // text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '      Yes     '
    }).then((result) => {
        if (result.value) {
          this.spinner.show();
          this.service.claimSusut(this.dataSusut)
             .subscribe(resp => { 
              this.spinner.hide();

            if(resp.succes == false){
              Swal.fire({
                title: ""+resp.text,
                type: 'warning',
                confirmButtonText: '      Ok!     '
              });
            }
            else{
              if(resp.list_premi != null){
                this.listSusut.push({
                  id: resp.list_premi.id, 
                  location: resp.list_premi.location, 
                  transaction_id: resp.list_premi.transaction_id, 
                  transaction_detail_id: resp.list_premi.transaction_detail_id, 
                  driver_id: resp.list_premi.driver_id, 
                  type: resp.list_premi.type, 
                  truck_id: resp.list_premi.truck_id, 
                  contract_no: resp.list_premi.contract_no, 
                  perihal: resp.list_premi.perihal, 
                  ba_no: resp.list_premi.ba_no, 
                  sps_reason: resp.list_premi.sps_reason, 
                  sps_date: resp.list_premi.sps_date, 
                  amount: resp.list_premi.amount, 
                  company_paid: resp.list_premi.company_paid, 
                  cash_payment: resp.list_premi.cash_payment, 
                  trip_payment: resp.list_premi.trip_payment, 
                  trip_payment_text: resp.list_premi.trip_payment_text, 
                  debt_remaining: resp.list_premi.debt_remaining, 
                  paid: resp.list_premi.paid, 
                  paid_on: resp.list_premi.paid_on, 
                  debt_no: resp.list_premi.debt_no, 
                  debt_date: resp.list_premi.debt_date, 
                  cancelled_at: resp.list_premi.cancelled_at, 
                  cancelled_by: resp.list_premi.cancelled_by, 
                  created_at: resp.list_premi.created_at, 
                  created_by: resp.list_premi.created_by, 
                  updated_at: resp.list_premi.updated_at, 
                  updated_by: resp.list_premi.updated_by, 
                  deleted: resp.list_premi.deleted, 
                  do_no : this.doNoSusut,
                  type_text: resp.list_premi.type_text,
                  driver_name: this.tripModel.driver_name,
                  driver_license : resp.list_premi.driver_license,
                  route_name: this.tripModel.route_name,
                  paid_on_text : resp.list_premi.paid_on_text,
                });
              }

              if(resp.list_trip != null){
                this.listSusut.push({
                  id: resp.list_trip.id, 
                  location: resp.list_trip.location, 
                  transaction_id: resp.list_trip.transaction_id, 
                  transaction_detail_id: resp.list_trip.transaction_detail_id, 
                  driver_id: resp.list_trip.driver_id, 
                  type: resp.list_trip.type, 
                  truck_id: resp.list_trip.truck_id, 
                  contract_no: resp.list_trip.contract_no, 
                  perihal: resp.list_trip.perihal, 
                  ba_no: resp.list_trip.ba_no, 
                  sps_reason: resp.list_trip.sps_reason, 
                  sps_date: resp.list_trip.sps_date, 
                  amount: resp.list_trip.amount, 
                  company_paid: resp.list_trip.company_paid, 
                  cash_payment: resp.list_trip.cash_payment, 
                  trip_payment: resp.list_trip.trip_payment, 
                  trip_payment_text: resp.list_trip.trip_payment_text, 
                  debt_remaining: resp.list_trip.debt_remaining, 
                  paid: resp.list_trip.paid, 
                  paid_on: resp.list_trip.paid_on, 
                  debt_no: resp.list_trip.debt_no, 
                  debt_date: resp.list_trip.debt_date, 
                  cancelled_at: resp.list_trip.cancelled_at, 
                  cancelled_by: resp.list_trip.cancelled_by, 
                  created_at: resp.list_trip.created_at, 
                  created_by: resp.list_trip.created_by, 
                  updated_at: resp.list_trip.updated_at, 
                  updated_by: resp.list_trip.updated_by, 
                  deleted: resp.list_trip.deleted, 
                  do_no : this.doNoSusut,
                  type_text: resp.list_trip.type_text,
                  driver_name: this.tripModel.driver_name,
                  driver_license : resp.list_trip.driver_license,
                  route_name: this.tripModel.route_name,
                  paid_on_text : resp.list_trip.paid_on_text,
                });
              }
                
              }
            });
        }        
    
    }, (error) => {
      //; 
    });
  }

  onChangeSusut(){
    this.debtSusut = 0;

    this.debtSusut = (this.qtyPrice) * (this.qtySusut);
  }

  onSelectSusut(data: ListOfTrxDoDetail){
    this.detailIdDoSusut = data.do_detail_id;
    this.doNoSusut = data.do_detail_no;
    this.qtySusut = data.tonnage_send - data.tonnage_received;
  }
  

  onSelectRoute(data: Route){
    this.tripModel.route_id = data.route_id;
    this.tripModel.route_name = data.route_name;
    this.routeId = data.route_id.toString();
    this.getDataPort(this.dtPort, this.cbPort,  this.routeId, this.capacityId,"",true);
    this.getDataRoute(this.dtRoute, this.cbRoute,"","","","", null,true);
    $('.searchRute').val('');
  } 

  onTruck(data: ListTruck){
    this.service.getVehicleSelect(data.truck_capacity_id).subscribe(resp => {
      this.tripModel.vehicle_type_id = resp.id;
      this.tripModel.vehicle_type_name = resp.code;
    })
    this.capacityId = data.truck_capacity_id.toString();
    this.tripModel.truck_id = data.truck_id;
    this.tripModel.truck_plate_number = data.truck_plate_number;
    this.tripModel.capacity_id = data.truck_capacity_id;
    this.getLabelCalculate();
    this.getDataPort(this.dtPort, this.cbPort,  this.routeId, this.capacityId,"",true);
    $('.searchInputTruck').val('');
    this.getDataTruck(this.dtTruck, this.cbTruck, "","","",true);  
  }

  onSelectTruck(data: ListTruck){
    const loc = localStorage.getItem('locationname');
    if (loc == "Palembang"){
      if (moment(data.validTo).add(-4, 'days').isBefore(moment(new Date()))){
        Swal.fire({
          title: "Masa berlaku STNK mobil sudah habis! Mobil tidak dapat digunakan",
          type: 'warning',
          confirmButtonText: '      Ok!     '
        });
      }
      else if (moment(data.validTo).add(-30, 'days').isBefore(moment(new Date()))){
        Swal.fire({
          title: "Masa berlaku STNK mobil "+data.truck_plate_number+" akan segera habis pada "+moment(data.validTo).format('DD-MM-YYYY'),
          type: 'warning',
          confirmButtonText: '      Ok!     '
        });
        this.onTruck(data);
      }
      else{
        this.onTruck(data);
      }
    }
    else{
      this.onTruck(data);
    }
    
  } 

  onSelectDataRow(data: RespDetailDo){
    this.transDetailModel.do_detail_no = data.id;
    this.selectModel.id = data.id;
    this.selectModel.doNo = data.doNo;
    this.selectModel.doDate = data.doDate;
    this.selectModel.materialName = data.materialName;
    this.selectModel.trip_type = data.trip_type;
    this.selectModel.routeId = data.route_id;
    this.selectModel.est_ton = data.est_ton;
    this.selectModel.est_pack = data.est_pack;
    this.tripModel.trip_type = data.trip_type;
    this.mainDO = data.main_do;
    this.mainDoId = data.main_do_id;
    console.log(data);
  } 

  onSelectDriver(data: Driver){
    if (moment(data.valid_to).isBefore(moment(new Date()))){
      Swal.fire({
        title: "Masa berlaku sim supir sudah habis!",
        type: 'warning',
        confirmButtonText: '      Ok!     '
      });
    }
    else if (moment(data.valid_to_contract).isBefore(moment(new Date()))){
      Swal.fire({
        title: "Masa berlaku kontrak supir "+data.driver_name+" sudah habis pada "+moment(data.valid_to_contract).format('DD-MM-YYYY'),
        type: 'warning',
        confirmButtonText: '      Ok!     '
      });
    }
    else if (moment(data.valid_to_contract).add(-30, 'days').isBefore(moment(new Date()))){
      this.tripModel.driver_id = data.driver_id;
      this.tripModel.driver_name = data.driver_name;
      Swal.fire({
        title: "Masa berlaku kontrak supir "+data.driver_name+" akan segera habis pada "+moment(data.valid_to_contract).format('DD-MM-YYYY'),
        type: 'warning',
        confirmButtonText: '      Ok!     '
      });
    }
    else if(moment(data.valid_to).add(-14, 'days').isBefore(moment(new Date()))){
      Swal.fire({
        title: "Masa berlaku sim supir akan segera habis pada "+moment(data.valid_to).format('DD-MM-YYYY')+" !",
        type: 'warning',
        confirmButtonText: '      Ok!     '
      });
      this.tripModel.driver_id = data.driver_id;
      this.tripModel.driver_name = data.driver_name;
      this.getDataDriver(this.dtDriver, this.cbDriver, "", "","","","", "","","","", "","","", "",  true);
      $('.searchInputD').val('');
    }
    else{
      this.tripModel.driver_id = data.driver_id;
      this.tripModel.driver_name = data.driver_name;
      this.getDataDriver(this.dtDriver, this.cbDriver, "", "","","","", "","","","", "","","", "",  true);
      $('.searchInputD').val('');
    }
    this.loadPayment(data.driver_id);
  } 

  messageWarning(text: string){
    Swal.fire({
      title: ""+text,
      type: 'warning',
      confirmButtonText: '      Ok!     '
    });
    return;
  }

  transform(value: any, args?: any): any {
    if(args === 'Checked'){
        return value.filter(x=>x.checked)
    }else if(args === 'NotChecked'){
         return value.filter(x=>!x.checked)
    }else{
      return value
    }
  }

  loadPayment(id: Guid){
    this.service.getDriverdebtById(id).subscribe(resp => {
      this.dataDriverDebt = resp;
    });
  }

  onTypeDebtPay(){
    this.cicilan = this.debtTotal - this.debtPay;
  }

  amountDebtCheck(isSelected, data, id){
    if (isSelected == true){
      this.debtTotal += data.remaining;
      this.dataDriverDebtTemp.push({ id: id, debt_id: data.id, reff: data.trx_reff, type: data.type_name, total: data.remaining, debt: 0,  cicilan: 0 })
    }
    else{
      console.log(JSON.stringify(this.dataDriverDebtTemp))
      this.debtTotal -= data.remaining;
      this.dataDriverDebtTemp.forEach((value,index)=>{
        if(value.id==id) this.dataDriverDebtTemp.splice(index,1);
    });
      console.log(JSON.stringify(this.dataDriverDebtTemp))
    }
  }


  onSelectCancel(data: ListTripRecord){
    this.service.disableEditTrip(data.id).subscribe(resp => {
      if (resp > 0){
        return Swal.fire({
          title: "Trip tersebut sudah punya invoice!",
          type: 'warning',
          confirmButtonText: '      Ok!     '
        });
      }
    else{
      this.addCloseModel.payDate = null;
      this.addCloseModel.reason = null;
      this.companyCost = 0;
      this.dirverReturn = 0;
      this.transactionId = data.id;
      this.getListCancelPayment(data.id);
      var today = moment().format('YYYY-MM-DD');
      this.addCloseModel.payDate = today;
    }
    })
  }

  addAsDebt(){
    Swal.fire({
      title: 'Anda Yakin?',
      type: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '      Yes     '
    }).then((result) => {
      if (result.value) {
        this.tripModel.debtAmount = this.totalCost;
      }
    });
  }

  directPayment(){
    Swal.fire({
      title: 'Anda Yakin?',
      type: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '      Yes     '
    }).then((result) => {
      if (result.value) {
        this.tripModel.debtAmount = null;
        this.ModalAdAsDebtHide.nativeElement.click();
        // this.loadPayment(this.tripModel.driver_id);
        this.tripModel.saveType == "payment";
        this.paymentClick.nativeElement.click();
      }
    });
  }

  save(){
    if (this.realisasi == true){
      if (this.tripModel.send_date == null || this.tripModel.receive_date == null 
        || this.tripModel.trip_type == null)
      if (this.tripModel.trip_type == null)
      {
        Swal.fire({
          title: "Data Belum Lengkap !",
          type: 'warning',
          confirmButtonText: '      Ok!     '
        });
        return;
      }
    }
    else{
      if (this.tripModel.transaction_type_text == "FGS"){
        if (this.tripModel.transaction_type_text == null || this.tripModel.route_name == null 
          || this.tripModel.truck_plate_number == null || this.tripModel.driver_name == null
          || this.tripModel.vehicle_type_name == null || this.tripModel.depart_date == null ){
            Swal.fire({
              title: "Data Belum Lengkap !",
              type: 'warning',
              confirmButtonText: '      Ok!     '
            });
            return;
          }
      }
      else{
        if (this.tripModel.transaction_type_text == null || this.tripModel.cost == null 
          || this.tripModel.cost_type_external == null || this.tripModel.depart_date == null){
            Swal.fire({
              title: "Data Belum Lengkap !",
              type: 'warning',
              confirmButtonText: '      Ok!     '
            });
            return;
          }
      }
    }
    if (this.actionCreate == "create" && this.totalTravelCost == 0){
      return Swal.fire({
        title: "Pencocokan Uang Jalan Gagal!",
        type: 'warning',
        confirmButtonText: '      Ok!     '
      });
      this.tabset.tabs[0].active = true;
    }
    if (this.actionCreate == "create"){
      if( this.tripModel.reff != null && this.tripModel.reff != ""){
          this.reffModel.transaction_id = null;
          this.reffModel.reff = this.tripModel.reff;
          this.service.checkReff(this.reffModel).subscribe( x => {
            if(x.succes == false){
              return Swal.fire({
                title: ""+x.text,
                type: 'warning',
                confirmButtonText: '      Ok!     '
              }); 
            }
          });
      }
      this.service.checkSusut("", this.tripModel.driver_id).subscribe( resp => {
        if (resp.succes == false){
          return Swal.fire({
            title: ""+resp.text,
            type: 'warning',
            confirmButtonText: '      Ok!     '
          });
        }
      });
      }
      else{
        if( this.tripModel.reff != null && this.tripModel.reff != ""){
          this.reffModel.transaction_id = this.tripModel.id;
          this.reffModel.reff = this.tripModel.reff;
          this.service.checkReff(this.reffModel).subscribe( x => {
            if(x.succes == false){
              return Swal.fire({
                title: ""+x.text,
                type: 'warning',
                confirmButtonText: '      Ok!     '
              }); 
            }
          });
      }
        this.service.checkSusut(this.tripModel.id, this.tripModel.driver_id).subscribe( resp => {
          if (resp.succes == false){
            return Swal.fire({
              title: ""+resp.text,
              type: 'warning',
              confirmButtonText: '      Ok!     '
            });
          }
        });
      } 
    // if (this.actionCreate == "create"){
    //   this.createTrip();
    // }
    // else{
    //     this.updateTrip();
    // }
    if (this.realisasi == true && this.tripModel.transaction_type_text != "EKSPEDISI") {
      if(this.dataListPayment.length == 0 ){
       return Swal.fire({
          title: "Mohon pastikan sudah ada payment yang di approve untuk melanjutkan ke realisasi!",
          type: 'warning',
          confirmButtonText: '      Ok!     '
        });
        
      }
    }
    this.tripModel.saveType = "save";
    this.saveTrip();
  }


  savePayment(){
    console.log("dataTambahan save : "+JSON.stringify(this.dataTambahan))
    this.totalBasicCost = 0;
    if (this.actionCreate == "create"){
      this.totalBasicCost += this.totalBasicCostLoad;
    }
    
    console.log("totalBasicCostLoad : "+this.totalBasicCostLoad)
    this.dataTambahan.forEach(item => {
      if(item.basic == true && item.paid == false && item.category != "Uang Jalan"){
        this.totalBasicCost += item.amount;
      }
    })
    
    console.log("totalBasicCost save : "+this.totalBasicCost)
    if (this.menuRealization == true){
      // if (this.tripModel.send_date == null || this.tripModel.receive_date == null 
      //   || this.tripModel.trip_type == null )
      if (this.tripModel.trip_type == null )
      {
        Swal.fire({
          title: "Data Belum Lengkap !",
          type: 'warning',
          confirmButtonText: '      Ok!     '
        });
        return;
      }
    }
    else{
      if (this.tripModel.transaction_type_text == "FGS"){
        if (this.tripModel.transaction_type_text == null || this.tripModel.route_name == null 
          || this.tripModel.truck_plate_number == null || this.tripModel.driver_name == null
          || this.tripModel.vehicle_type_name == null || this.tripModel.depart_date == null ){
            Swal.fire({
              title: "Data Belum Lengkap !",
              type: 'warning',
              confirmButtonText: '      Ok!     '
            });
            return;
          }
      }
      else{
        if (this.tripModel.transaction_type_text == null || this.tripModel.cost == null 
          || this.tripModel.cost_type_external == null || this.tripModel.depart_date == null){
            Swal.fire({
              title: "Data Belum Lengkap !",
              type: 'warning',
              confirmButtonText: '      Ok!     '
            });
            return;
          }
      }
    }
    
    if (this.realisasi == true  && this.tripModel.transaction_type_text != "EKSPEDISI") {
      if(this.dataListPayment.length == 0 ){
       return Swal.fire({
          title: "Mohon pastikan sudah ada payment yang di approve untuk melanjutkan ke realisasi!",
          type: 'warning',
          confirmButtonText: '      Ok!     '
        });
        
      }
    }

    if (this.actionCreate == "create" && this.totalTravelCost == 0){
      return Swal.fire({
        title: "Pencocokan Uang Jalan Gagal!",
        type: 'warning',
        confirmButtonText: '      Ok!     '
      });
      this.tabset.tabs[0].active = true;
    }

    if (this.actionCreate == "create"){
          if( this.tripModel.reff != null && this.tripModel.reff != ""){
            this.reffModel.transaction_id = null;
            this.reffModel.reff = this.tripModel.reff;
            this.service.checkReff(this.reffModel).subscribe( x => {
              if(x.succes == false){
                return Swal.fire({
                  title: ""+x.text,
                  type: 'warning',
                  confirmButtonText: '      Ok!     '
                }); 
              }
            });
        }
      this.service.checkSusut("", this.tripModel.driver_id).subscribe( resp => {
        if (resp.succes == false){
          return Swal.fire({
            title: ""+resp.text,
            type: 'warning',
            confirmButtonText: '      Ok!     '
          });
        }
      });
      }
      else{
        if( this.tripModel.reff != null && this.tripModel.reff != ""){
          this.reffModel.transaction_id = this.tripModel.id;
          this.reffModel.reff = this.tripModel.reff;
          this.service.checkReff(this.reffModel).subscribe( x => {
            if(x.succes == false){
              return Swal.fire({
                title: ""+x.text,
                type: 'warning',
                confirmButtonText: '      Ok!     '
              }); 
            }
          });
      }
        this.service.checkSusut(this.tripModel.id.toString(), this.tripModel.driver_id).subscribe( resp => {
          if (resp.succes == false){
            return Swal.fire({
              title: ""+resp.text,
              type: 'warning',
              confirmButtonText: '      Ok!     '
            });
          }
        });
      } 
    
    this.tripModel.saveType = "payment";
    this.paymentClear();
    this.saveTrip();
  }

  createPayment(){
    if(this.tripModel.skipPaymentStatus != true && this.dataTambahanDebt.length == 0 && this.dataDriverDebt.length > 0){
      this.messageWarning("Harus Membayar Cicilan Hutang Atau Tangguhkan Pembayaran !");
      return;
    }
    this.setPayment();
    //off dulu
    var totalListDebt = this.dataDriverDebt.reduce((sum, obj) => {
      return sum + obj.remaining;
    }, 0);

    if(this.tripModel.grandTotal == Math.abs(this.tripModel.grandTotal)){
      if (totalListDebt > 0 &&  this.debtPay<= 0 && this.tripModel.skipPaymentStatus != true){
        return Swal.fire({
          title: "Belum Ada Pembayaran Hutang!",
          type: 'warning',
          confirmButtonText: '      Ok!     '
        });
      }
    }
    if(totalListDebt < (0.1*this.totalBasicCost)){
      this.tripModel.pass = true;
    }


    // if (this.getTotalUJ == true){
    //   this.tripModel.totalBasicCost = this.tripModel.grandTotal + this.debtPay ;
    //   if(totalListDebt < (0.1*this.tripModel.totalBasicCost)){
    //     this.tripModel.pass = true;
    //   }
    // }
    // else{
    //   this.tripModel.totalBasicCost = this.totalTravelCost;
    //   if(totalListDebt < (0.1*this.tripModel.totalBasicCost)){
    //     this.tripModel.pass = true;
    //   }
    // }
    

    if(this.datePay == null ){
      return Swal.fire({
         title: "Tanggal bayar Belum Diisi!",
         type: 'warning',
         confirmButtonText: '      Ok!     '
       });
    }
    Swal.fire({
      title: 'Anda Yakin?',
      type: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '      Yes     '
    }).then((result) => {
      if (result.value) {
        if(this.actionCreate == "create"){
          this.createTrip();
        }
        else{
          this.updateTrip();
        }
      }
    });
  }

  saveTrip(){

    this.count();
    console.log(this.listOfTrxDoDetail[0]);
    const item = this.listOfTrxDoDetail.filter((el) => el.material_name != this.listOfTrxDoDetail[0].material_name);
    if (item.length > 0){
      return Swal.fire({
        title: "Ada maaterial yang tidak sama dalam list!",
        type: 'warning',
        confirmButtonText: '      Ok!     '
      });
    }
    if (this.listOfTrxDoDetail.length !== null){
      this.tripModel.do_detail_no = this.listOfTrxDoDetail[0].do_detail_no;
    }
    this.tripModel.transactionCost = [];
    //   for (let i = 0; i < this.dataTambahan.filter(x => x.category != "Uang Jalan").length; i++) {
    //     this.tripModel.transactionCost.push({
    //       amount : this.dataTambahan[i].amount,
    //       cost_type_id : this.dataTambahan[i].cost_type,
    //       remark: this.dataTambahan[i].remark
    //   });
    // }

    this.saveDataTambahan = this.dataTambahan.filter(x => x.category != "Uang Jalan");
    for (let i = 0; i < this.saveDataTambahan.filter(x => x.category != "Uang Jalan").length; i++) {
      this.tripModel.transactionCost.push({
        amount : this.saveDataTambahan[i].amount,
        cost_type_id : this.saveDataTambahan[i].cost_type,
        remark: this.saveDataTambahan[i].remark
    });
  }
    this.tripModel.transDetail = [];
      for (let i = 0; i < this.listOfTrxDoDetail.length; i++) {
        this.tripModel.transDetail.push({
          pack_send : this.listOfTrxDoDetail[i].pack_send,
          pack_received : this.listOfTrxDoDetail[i].pack_received,
          tonnage_received: this.listOfTrxDoDetail[i].tonnage_received,
          tonnage_send : this.listOfTrxDoDetail[i].tonnage_send,
          do_detail_no : this.listOfTrxDoDetail[i].do_detail_id
      });
    }
  if (this.mainDoId == null){
    this.mainDoId = this.listOfTrxDoDetail[0].id;
  }
    Swal.fire({
      title: 'Simpan Trip?',
      type: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '      Yes     '
    }).then((result) => {
      if (result.value) {
       this.spinner.show();
       this.dataAdaptCost.transit_port = this.tripModel.transit_port;
       this.dataAdaptCost.route_id = this.tripModel.route_id;
       this.dataAdaptCost.depart_date = this.tripModel.depart_date;
       this.dataAdaptCost.transaction_type_text = this.tripModel.transaction_type_text;
       this.dataAdaptCost.do_detail_no = this.tripModel.do_detail_no;
       this.dataAdaptCost.capacity_id = this.tripModel.capacity_id;
       this.service.adaptCost(this.dataAdaptCost).subscribe(resp => {
          if (resp.succes = true){
            
            this.tripModel.dataTravelCostId = resp.text;
            this.service.checkDriverDebt(this.tripModel.route_id,).subscribe(resp => {
              this.spinner.hide();
              if (resp.succes = true){
                if (this.tripModel.saveType == "save")
                {
                  if(this.total < 0){
                    this.debtClick.nativeElement.click();
                  }
                  else{
                    if (this.actionCreate == "create"){
                      this.createTrip();
                    }
                    else{
                        this.updateTrip();
                    }
                  }
                }
                else{
                  if(this.total < 0){
                    this.debtClick.nativeElement.click();
                  }
                  else{
                    // if(this.dataDriverDebt.length = 0){
                       this.loadPayment(this.tripModel.driver_id);
                    //}
                    this.tripModel.saveType == "payment";
                    if (this.dataTotalPayment > 0){
                      this.paymentClick.nativeElement.click();
                    }
                    else{
                    this.service.checkPaymentTrans(this.tripModel.id).subscribe(resp => {
                      if (resp == false){
                        this.paymentClick.nativeElement.click();
                      }
                      else{
                        this.createPayment();
                      }
                    });
                    }
                    //show modal payment
                    //call createTrip
                  }
                }

              }
              else{
                this.messageWarning(resp.text);
                return;
              }
            })
            this.spinner.hide();
          }
          else{
            this.spinner.hide();
            this.messageWarning(resp.text);
            return;
          }
       });        
      }
    
    }, (error) => {
    });
  }

  count(){
    this.total = 0;
    this.dataTambahan.forEach( data =>{
      if (data.category == "Invoice"){
        return;
      }
      if(data.approve == true){
        if (data.paid == null){
          if (data.type == "PIUTANG")
          {
            this.total -= data.amount;
          }
          else
          {
            this.total += data.amount;
          }
        }
        else if (data.paid == false)
        {
          if (data.type == "PIUTANG")
            {
              this.total -= data.amount;
            }
            else
            {
              this.total += data.amount;
            }
        }
      }
    })
  }
  // setPayment(){
  //   this.service.getAccClosing()
  // }

  updateTrip(){
    this.spinner.show();
    this.tripModel.dataDriverDebt = [];
    this.tripModel.transDetailAdd = [];
    this.tripModel.transDetailDelete = [];
    this.tripModel.transactionCostAdd = [];
    this.tripModel.transactionCostDelete = [];
    
    console.log("transaction delete : "+JSON.stringify(this.tripModel.transactionCostDelete) + 
    "\n transaction delete : "+JSON.stringify(this.tripModel.transactionCostAdd))
    for (let i = 0; i < this.dataTambahanDebt.length; i++) {
      this.tripModel.dataDriverDebt.push({
        driverdebt_id : this.dataTambahanDebt[i].driverdebt_id,
        reff : this.dataTambahanDebt[i].reff,
        type: this.dataTambahanDebt[i].type,
        total : this.dataTambahanDebt[i].total,
        debt : this.dataTambahanDebt[i].debt,
        cicilan : this.dataTambahanDebt[i].cicilan,
      });
    }

    for (let i = 0; i < this.listOfTrxDoDetailAdd.length; i++) {
      this.tripModel.transDetailAdd.push({
        do_detail_no : this.listOfTrxDoDetailAdd[i].do_detail_id,
        pack_send : this.listOfTrxDoDetailAdd[i].pack_send,
        pack_received: this.listOfTrxDoDetailAdd[i].pack_received,
        tonnage_received : this.listOfTrxDoDetailAdd[i].tonnage_received,
        tonnage_send : this.listOfTrxDoDetailAdd[i].tonnage_send
      });
    }

    for (let i = 0; i < this.listOfTrxDoDetailDelete.length; i++) {
      if (this.listOfTrxDoDetailDelete[i].id != null){
        this.tripModel.transDetailDelete.push({
          id : this.listOfTrxDoDetailDelete[i].id,
          do_detail_no : this.listOfTrxDoDetailDelete[i].do_detail_id,
          pack_send : this.listOfTrxDoDetailDelete[i].pack_send,
          pack_received: this.listOfTrxDoDetailDelete[i].pack_received,
          tonnage_received : this.listOfTrxDoDetailDelete[i].tonnage_received,
          tonnage_send : this.listOfTrxDoDetailDelete[i].tonnage_send
        });
      }
      else{
        
      this.tripModel.transDetailDelete.push({
        do_detail_no : this.listOfTrxDoDetailDelete[i].do_detail_id,
        pack_send : this.listOfTrxDoDetailDelete[i].pack_send,
        pack_received: this.listOfTrxDoDetailDelete[i].pack_received,
        tonnage_received : this.listOfTrxDoDetailDelete[i].tonnage_received,
        tonnage_send : this.listOfTrxDoDetailDelete[i].tonnage_send
      });
      }
    }

    if (this.tripModel.arrive_date == null && this.locationName == "Palembang"){
      this.hold = true;
    }
    else{
      this.sevenday = this.addDays(this.tripModel.arrive_date, 7)
    }
    for (let i = 0; i < this.dataTambahanAdd.length; i++) {
      if(this.dateNow > this.sevenday && this.locationName == "Palembang"){
        if (this.dataTambahanAdd[i].type == "HUTANG" && this.dataTambahanAdd[i].paid ==false){
          this.hold = true;
           console.log("hold true")
        }
      }
      this.tripModel.transactionCostAdd.push({
        amount : this.dataTambahanAdd[i].amount,
        cost_type_id : this.dataTambahanAdd[i].cost_type,
        remark: this.dataTambahanAdd[i].remark,
        trxType : this.dataTambahanAdd[i].type
      });
    }

    for (let i = 0; i < this.dataTambahanDelete.length; i++) {
      this.tripModel.transactionCostDelete.push({
        id: this.dataTambahanDelete[i].id,
        amount : this.dataTambahanDelete[i].amount,
        cost_type_id : this.dataTambahanDelete[i].cost_type,
        remark: this.dataTambahanDelete[i].remark
      });
    }
    this.tripModel.totalHutang = this.debtTotal;
    this.tripModel.sisaCicilan = this.cicilan;
    this.tripModel.paymentDate = this.datePay;
    this.tripModel.realisasi = this.realisasi;
    this.tripModel.totalBasicCost = this.totalBasicCost;

    if(this.hold == true){
      this.spinner.hide();
        Swal.fire({
          title: 'Pembayaran UJ sudah lebih dari 7 hari dari tanggal kembali atau trip belum di realisasi, pembayaran harus di approve terlebih dahulu, Tetap Lanjutkan?',
          type: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: '      Yes     '
        }).then((result) => {
          if (result.value) {
            this.service.updateTrip(this.tripModel).subscribe(resp => {
              if (resp.succes == true){
                  this.getDataListTripRecord(this.dtTrip, this.cbTrip, this.dataTripDto, true );
                  this.modalCreateClose.nativeElement.click();
                  this.ModalPaymentClose.nativeElement.click();
                  this.spinner.hide();
                  this.getDoMain();
                  Swal.fire({
                    type: 'success',
                    title: ""+resp.text,
                    confirmButtonText: '      Ok!     '
                  }).then((result) => {
                    if (result.value) { 
                    }
                  }, (error) => {
                    //; 
                  })
                
                  
                if (resp.status == "print"){
                  this.service.printTripPayment(Guid.parse(resp.text2), this.locationName).subscribe(resp =>{
                    let blob:Blob=resp.body as Blob;
                    let url = window.URL.createObjectURL(blob);
                    window.open(url);
                  })
                  
                  if (this.locationName != "Pontianak"){
                    this.service.printDOTrip(Guid.parse(resp.text3), this.locationName).subscribe(resp =>{
                      let blob:Blob=resp.body as Blob;
                        let url = window.URL.createObjectURL(blob);
                        window.open(url);
                    })
                  }
                }
                if (this.locationName != "Pontianak"){
                  if (resp.status == "printDo"){
                    this.service.printDOTrip(Guid.parse(resp.text3), this.locationName).subscribe(resp =>{
                      let blob:Blob=resp.body as Blob;
                        let url = window.URL.createObjectURL(blob);
                        window.open(url);
                    })
                  }
                }
              }
              else{
                this.spinner.hide();
                this.messageWarning(resp.text);
                this.getDoMain();
                return;
              }
            });
          }
      
      }, (error) => {
      });
  
    }
    else{
      this.service.updateTrip(this.tripModel).subscribe(resp => {
        if (resp.succes == true){
            this.getDataListTripRecord(this.dtTrip, this.cbTrip, this.dataTripDto, true );
            this.modalCreateClose.nativeElement.click();
            this.ModalPaymentClose.nativeElement.click();
            this.spinner.hide();
            this.getDoMain();
            Swal.fire({
              type: 'success',
              title: ""+resp.text,
              confirmButtonText: '      Ok!     '
            }).then((result) => {
              if (result.value) { 
              }
            }, (error) => {
              //; 
            })
          
            
          if (resp.status == "print"){
            this.service.printTripPayment(Guid.parse(resp.text2), this.locationName).subscribe(resp =>{
              let blob:Blob=resp.body as Blob;
              let url = window.URL.createObjectURL(blob);
              window.open(url);
            })
            
            if (this.locationName != "Pontianak"){
              this.service.printDOTrip(Guid.parse(resp.text3), this.locationName).subscribe(resp =>{
                let blob:Blob=resp.body as Blob;
                  let url = window.URL.createObjectURL(blob);
                  window.open(url);
              })
            }
          }
          if (this.locationName != "Pontianak"){
            if (resp.status == "printDo"){
              this.service.printDOTrip(Guid.parse(resp.text3), this.locationName).subscribe(resp =>{
                let blob:Blob=resp.body as Blob;
                  let url = window.URL.createObjectURL(blob);
                  window.open(url);
              })
            }
          }
        }
        else{
          this.spinner.hide();
          this.messageWarning(resp.text);
          this.getDoMain();
          return;
        }
      });
      
    }

    
  }

  createTrip(){
    this.spinner.show();
    this.tripModel.dataDriverDebt = [];
    for (let i = 0; i < this.dataTambahanDebt.length; i++) {
      this.tripModel.dataDriverDebt.push({
        driverdebt_id : this.dataTambahanDebt[i].driverdebt_id,
        reff : this.dataTambahanDebt[i].reff,
        type: this.dataTambahanDebt[i].type,
        total : this.dataTambahanDebt[i].total,
        debt : this.dataTambahanDebt[i].debt,
        cicilan : this.dataTambahanDebt[i].cicilan,
      });
    }
    this.tripModel.totalHutang = this.debtTotal;
    this.tripModel.sisaCicilan = this.cicilan;
    this.tripModel.paymentDate = this.datePay;
    this.tripModel.totalBasicCost = this.totalBasicCost;
    this.service.createTrip(this.tripModel).subscribe(resp => {
      this.spinner.hide();
      if (resp.succes == true){
        this.getDataListTripRecord(this.dtTrip, this.cbTrip, this.dataTripDto, true );
        this.modalCreateClose.nativeElement.click();
        this.ModalPaymentClose.nativeElement.click();
        this.getDoMain();
        Swal.fire({
          type: 'success',
          title: ""+resp.text,
          confirmButtonText: '      Ok!     '
        }).then((rest) => {
          if (rest.value) { 
          }
        }, (error) => {
          //; 
        })
        if (resp.status == "print"){
          this.spinner.hide();
          this.service.printTripPayment(Guid.parse(resp.text2), this.locationName).subscribe(resp =>{
            let blob:Blob=resp.body as Blob;
            let url = window.URL.createObjectURL(blob);
            window.open(url);
          })
          if (this.locationName != "Pontianak"){
            this.service.printDOTrip(Guid.parse(resp.text3), this.locationName).subscribe(resp =>{
              let blob:Blob=resp.body as Blob;
                let url = window.URL.createObjectURL(blob);
                window.open(url);
              })
          }
        }
        if (this.locationName != "Pontianak"){
          if (resp.status == "printDo"){
            this.service.printDOTrip(Guid.parse(resp.text3), this.locationName).subscribe(resp =>{
              let blob:Blob=resp.body as Blob;
                let url = window.URL.createObjectURL(blob);
                window.open(url);
            })
          }
        }
      }
      else{
        this.spinner.hide();
        this.messageWarning(resp.text);
        this.getDoMain();
        return;
      }
    });
  }

  getListCancelPayment(id: Guid){
    this.spinner.show();
    this.paymentModel.transactionId = id;
    this,this.dataListCancelPayment.length = 0;
    this.service.getPaymentCancelById(this.paymentModel).subscribe( resp => {
      this.dataListCancelPayment = resp;
      this.amountTotalCancel = this.dataListCancelPayment.reduce((sum, obj) => {
        return sum + obj.total;
      }, 0);
      this.driverDebtCancel = this.dataListCancelPayment.reduce((sum, obj) => {
        return sum + obj.total;
      }, 0);
      this.driverDebtCancelTemp = this.driverDebtCancel;
      this.spinner.hide();
    })
  }

  onTypeAmount(){
    this.driverDebtCancel = this.driverDebtCancelTemp - (this.companyCost + this.dirverReturn);
  }

  onSelectVehicle(data: Vehicle){
    this.tripModel.vehicle_type_id = data.id;
    this.tripModel.vehicle_type_name = data.name;
    this.getDataVehicle(this.dtVehicle, this.cbVehicle, "", "",true);
    $('.searchInputV').val('');
  } 

  onSelectPort(data: Port){
    this.tripModel.transit_port = data.port_id;
    this.tripModel.transit_port_name = data.port_name;
    this.getLabelCalculate();
    this.getDataPort(this.dtPort, this.cbPort,  this.routeId, this.capacityId,"",true);
    $('.searchInputPort').val('');
  } 


  showRoute(){
    this.dtTriggerRoute.next(0);
  }

  showTruck(){
    this.dtTriggerTruck.next(0);
  }

  showDriver(){
    this.dtTriggerDriver.next(0);
  }

  showVehicle(){
    this.dtTriggerVehicle.next(0);
  }

  showPort(){
    this.dtTriggerPort.next(this.dtOptionsPort);
  }

  getDoDetailTrans(id: Guid){
    this.service.getDoDetailTrans(id).subscribe(resp => {
      this.listOfTrxDoDetail = resp;
      if(resp != null){
        this.getLabelCalculate();
      }
      // resp.forEach((item) => {
      //   this.listOfTrxDoDetail.push( { do_detail_no : item.do_detail_no, do_detail: item.do_detail, tonnage_send: item.tonnage_send, 
      //     tonnage_received: item.tonnage_received, pack_send: item.pack_send, pack_received: item.pack_received,
      //      invoice_no: item.invoice_no, remark: item.remark, WB_reff: item.WB_reff, do_date: item.do_date, material_name : item.material_name } );
      //   });
    });
    
  }

  AddListDetailModel(data: RespDetailDo[]){
//     let groupIds = [1,2,4];
// let groups = [{id:1,name:'A'}, {id:2,name:'B}, {id:2222, name'C'}];
// let groups= groups.filter(({id}) => groupIds.includes(id));

    
    this.listOfTrxDoDetail = [];
      data.forEach(data => {
        this.transDetailModel.do_detail_no = data.id;
        this.selectModel.id = data.id;
        this.selectModel.doNo = data.doNo;
        this.selectModel.doDate = data.doDate;
        this.selectModel.materialName = data.materialName;
        this.selectModel.trip_type = data.trip_type;
        this.tripModel.trip_type = data.trip_type;
        this.listOfTrxDoDetail.push( { id : null, do_detail_no : this.selectModel.doNo, main_do : this.mainDO, do_detail_id: this.transDetailModel.do_detail_no, tonnage_send: this.transDetailModel.tonnage_send, 
          tonnage_received: this.transDetailModel.tonnage_received, pack_send: this.transDetailModel.pack_send, pack_received: this.transDetailModel.pack_received,
           invoice_no: this.transDetailModel.invoice_no, remark: this.transDetailModel.remark, wb_reff: this.transDetailModel.WB_reff, do_date: this.selectModel.doDate, 
           material_name : this.selectModel.materialName, trip_type : this.selectModel.trip_type, est_ton: data.est_ton, est_pack: data.est_pack, route_id : data.route_id } );      
    });


  }

  AddDatDetailModel() {
    if (this.transDetailModel.do_detail_no == "" || this.transDetailModel.do_detail_no == null){
      Swal.fire({
        title: "Data Belum Lengkap !",
        type: 'warning',
        confirmButtonText: '      Ok!     '
      });
      return;
    }
    if(this.listOfTrxDoDetail.length > 0){
      if (this.selectModel.materialName != this.listOfTrxDoDetail[0].material_name){
        Swal.fire({
          title: "Material tidak sama dengan yang sudah ada di list",
          type: 'warning',
          confirmButtonText: '      Ok!     '
        });
        return;
      }
      if (this.selectModel.routeId != this.listOfTrxDoDetail[0].route_id && this.locationName != "Pontianak"){
        Swal.fire({
          title: "Rute tidak sama dengan yang sudah ada di list",
          type: 'warning',
          confirmButtonText: '      Ok!     '
        });
        return;
      }
    }

    if(this.listOfTrxDoDetail.filter((x) => x.do_detail_no.includes(this.selectModel.doNo)).length > 0){
      Swal.fire({
        title: "Data DO sudah ada dalam List",
        type: 'warning',
        confirmButtonText: '      Ok!     '
      });
    }
    else{
      if (this.transDetailModel.WB_reff != null && this.transDetailModel.WB_reff != ""){
        this.wbreffModel.transaction_detail_id = null;
        this.wbreffModel.wbreff = this.transDetailModel.WB_reff;
        this.service.checkWbReff(this.wbreffModel).subscribe( x => {
          if(x.succes == false){
            Swal.fire({
              title: ""+x.text,
              type: 'warning',
              confirmButtonText: '      Ok!     '
            }); 
          }
          else{
            if (this.actionCreate == "create"){
              this.listOfTrxDoDetail.push( { id : null, do_detail_no : this.selectModel.doNo, main_do : this.mainDO, do_detail_id: this.transDetailModel.do_detail_no, tonnage_send: this.transDetailModel.tonnage_send, 
                tonnage_received: this.transDetailModel.tonnage_received, pack_send: this.transDetailModel.pack_send, pack_received: this.transDetailModel.pack_received,
                 invoice_no: this.transDetailModel.invoice_no, remark: this.transDetailModel.remark, wb_reff: this.transDetailModel.WB_reff, do_date: this.selectModel.doDate, 
                 material_name : this.selectModel.materialName, trip_type : this.selectModel.trip_type, est_ton: this.selectModel.est_ton, est_pack: this.selectModel.est_pack, route_id: this.selectModel.routeId } );
            }
            else{
              this.listOfTrxDoDetail.push( { id : null, do_detail_no : this.selectModel.doNo, main_do : this.mainDO, do_detail_id: this.transDetailModel.do_detail_no, tonnage_send: this.transDetailModel.tonnage_send, 
              tonnage_received: this.transDetailModel.tonnage_received, pack_send: this.transDetailModel.pack_send, pack_received: this.transDetailModel.pack_received,
                invoice_no: this.transDetailModel.invoice_no, remark: this.transDetailModel.remark, wb_reff: this.transDetailModel.WB_reff, do_date: this.selectModel.doDate, 
                material_name : this.selectModel.materialName, trip_type : this.selectModel.trip_type, est_ton: this.selectModel.est_ton, est_pack: this.selectModel.est_pack, route_id: this.selectModel.routeId } );
              this.listOfTrxDoDetailAdd.push( { id : null, do_detail_no : this.selectModel.doNo, main_do : this.mainDO, do_detail_id: this.transDetailModel.do_detail_no, tonnage_send: this.transDetailModel.tonnage_send, 
              tonnage_received: this.transDetailModel.tonnage_received, pack_send: this.transDetailModel.pack_send, pack_received: this.transDetailModel.pack_received,
                invoice_no: this.transDetailModel.invoice_no, remark: this.transDetailModel.remark, wb_reff: this.transDetailModel.WB_reff, do_date: this.selectModel.doDate, 
                material_name : this.selectModel.materialName, trip_type : this.selectModel.trip_type, est_ton: this.selectModel.est_ton, est_pack: this.selectModel.est_pack, route_id: this.selectModel.routeId } );
            }
          }
        })
      }
      else{
        if (this.actionCreate == "create"){
          this.listOfTrxDoDetail.push( { id : null, do_detail_no : this.selectModel.doNo, main_do : this.mainDO, do_detail_id: this.transDetailModel.do_detail_no, tonnage_send: this.transDetailModel.tonnage_send, 
            tonnage_received: this.transDetailModel.tonnage_received, pack_send: this.transDetailModel.pack_send, pack_received: this.transDetailModel.pack_received,
             invoice_no: this.transDetailModel.invoice_no, remark: this.transDetailModel.remark, wb_reff: this.transDetailModel.WB_reff, do_date: this.selectModel.doDate, 
             material_name : this.selectModel.materialName, trip_type : this.selectModel.trip_type, est_ton: this.selectModel.est_ton, est_pack: this.selectModel.est_pack, route_id: this.selectModel.routeId } );
        }
        else{
          this.listOfTrxDoDetail.push( { id : null, do_detail_no : this.selectModel.doNo, main_do : this.mainDO, do_detail_id: this.transDetailModel.do_detail_no, tonnage_send: this.transDetailModel.tonnage_send, 
          tonnage_received: this.transDetailModel.tonnage_received, pack_send: this.transDetailModel.pack_send, pack_received: this.transDetailModel.pack_received,
            invoice_no: this.transDetailModel.invoice_no, remark: this.transDetailModel.remark, wb_reff: this.transDetailModel.WB_reff, do_date: this.selectModel.doDate, 
            material_name : this.selectModel.materialName, trip_type : this.selectModel.trip_type, est_ton: this.selectModel.est_ton, est_pack: this.selectModel.est_pack, route_id: this.selectModel.routeId } );
          this.listOfTrxDoDetailAdd.push( { id : null, do_detail_no : this.selectModel.doNo, main_do : this.mainDO, do_detail_id: this.transDetailModel.do_detail_no, tonnage_send: this.transDetailModel.tonnage_send, 
          tonnage_received: this.transDetailModel.tonnage_received, pack_send: this.transDetailModel.pack_send, pack_received: this.transDetailModel.pack_received,
            invoice_no: this.transDetailModel.invoice_no, remark: this.transDetailModel.remark, wb_reff: this.transDetailModel.WB_reff, do_date: this.selectModel.doDate, 
            material_name : this.selectModel.materialName, trip_type : this.selectModel.trip_type, est_ton: this.selectModel.est_ton, est_pack: this.selectModel.est_pack, route_id: this.selectModel.routeId} );
        }
      }
      
      
      }
   }

  AddDataTambahan() {
    if(this.tambahanModel.cost_name == null || this.tambahanModel.cost_name == "" || 
    this.tambahanModel.amount == 0 || this.tambahanModel.amount == null || 
    this.tambahanModel.remark == null || this.tambahanModel.remark == "" ){
      Swal.fire({
        title: "Data Belum Lengkap !",
        type: 'warning',
        confirmButtonText: '      Ok!     '
      });
    }
    else{
      if(this.actionCreate == "create"){
        this.dataTambahan.push({id : null, cost_type: this.tambahanModel.cost_type, category: this.tambahanModel.category, type:this.tambahanModel.type,
        amount: this.tambahanModel.amount, remark: this.tambahanModel.remark,  cost_name: this.tambahanModel.cost_name, approve : true, paid : false, basic: this.basicCost, 
        created_at: null, created_by: null});
        this.getLabelCalculate();
      }
      else{
        this.dataTambahan.push({id : null, cost_type: this.tambahanModel.cost_type, category: this.tambahanModel.category, type:this.tambahanModel.type,
          amount: this.tambahanModel.amount, remark: this.tambahanModel.remark,  cost_name: this.tambahanModel.cost_name, approve : true, paid : false, basic: this.basicCost,
          created_at: null, created_by: null});
          this.getLabelCalculate();
        this.dataTambahanAdd.push({cost_type: this.tambahanModel.cost_type, category: this.tambahanModel.category, type:this.tambahanModel.type,
          amount: this.tambahanModel.amount, remark: this.tambahanModel.remark,  cost_name: this.tambahanModel.cost_name, approve : true, paid : false});
          this.getLabelCalculate();
      }
    }
  }

    AddDataTambahanDebt() {
      this.dataTambahanDebt = [];
        var totalPaidAmount = this.debtPay;
        var debtTrip = 0;
        var cicilanDebt = 0;
        console.log( " 10% uj : "+ 0.1 * this.totalBasicCost)
        if( (0.1 * this.totalBasicCost) > totalPaidAmount){
          Swal.fire({
            title: 'Pembayaran kurang dari 10% Uang Jalan akan membutuhkan approval!\n Klik Yes Untuk melanjutkan',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: '      Yes     '
          }).then((result) => { 
            if(result.value){
              this.dataDriverDebtTemp.forEach(i => {
                if(totalPaidAmount >= 0){
                  if (totalPaidAmount > i.total){
                    debtTrip = i.total
                  }
                  else{
                    debtTrip = totalPaidAmount;
                  }
                  if(this.debtPay > this.debtTotal){
                    Swal.fire({
                      title: "Jumlah Dibayarkan Lebih besar daripada Hutang",
                      type: 'warning',
                      confirmButtonText: '      Ok!     '
                    });
                    return;
                  }
                  cicilanDebt = i.total - debtTrip;
                  this.dataTambahanDebt.push({driverdebt_id: i.debt_id,reff: i.reff, type: i.type, total: i.total, debt: debtTrip,  cicilan: cicilanDebt});
                  totalPaidAmount -= i.total;
                  // this.dataTambahanDebt.forEach(i => {
                  //   this.totalHutangTrip += i.debt;
                  // });
                  this.totalHutangTrip = this.dataTambahanDebt.reduce((sum, obj) => {
                    return sum + obj.debt;
                  }, 0);
                  
                  //this.tripModel.grandTotal = Math.abs(this.travelCostPayment) - this.totalHutangTrip;
                  
                }
              });
              
              //this.tripModel.grandTotal = Math.abs(this.travelCostPayment) - this.totalHutangTrip;
              this.tripModel.grandTotal = this.tripModel.grandTotal - this.totalHutangTrip;
              this.paymentAdd = true;
            }
          })
        }
        else{
          this.dataDriverDebtTemp.forEach(i => {
            if(totalPaidAmount >= 0){
              if (totalPaidAmount > i.total){
                debtTrip = i.total
              }
              else{
                debtTrip = totalPaidAmount;
              }
              if(this.debtPay > this.debtTotal){
                Swal.fire({
                  title: "Jumlah Dibayarkan Lebih besar daripada Hutang",
                  type: 'warning',
                  confirmButtonText: '      Ok!     '
                });
                return;
              }
              cicilanDebt = i.total - debtTrip;
              this.dataTambahanDebt.push({driverdebt_id: i.debt_id,reff: i.reff, type: i.type, total: i.total, debt: debtTrip,  cicilan: cicilanDebt});
              totalPaidAmount -= i.total;
              this.dataTambahanDebt.forEach(i => {
                this.totalHutangTrip += i.debt;
              });
              this.totalHutangTrip = this.dataTambahanDebt.reduce((sum, obj) => {
                return sum + obj.debt;
              }, 0);
              
              //this.tripModel.grandTotal = Math.abs(this.travelCostPayment) - this.totalHutangTrip;
            }
            
          });
          
          this.tripModel.grandTotal = this.tripModel.grandTotal - this.totalHutangTrip;
          this.paymentAdd = true;
        }
       
        
        
    }

  deleteDataDoDetail(id: number, data: ListOfTrxDoDetail)
  { 
    Swal.fire({
      title: 'Delete Data?',
      // text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '      Yes     '
    }).then((result) => {
        if (result.value) {
          this.listOfTrxDoDetail.splice(id, 1); this.changeDetectorRef.detectChanges(); 
          this.listOfTrxDoDetailDelete.push( { id : data.id, do_detail_no : this.selectModel.doNo, main_do : this.mainDO, do_detail_id: data.do_detail_id, tonnage_send: this.transDetailModel.tonnage_send, 
            tonnage_received: this.transDetailModel.tonnage_received, pack_send: this.transDetailModel.pack_send, pack_received: this.transDetailModel.pack_received,
            invoice_no: this.transDetailModel.invoice_no, remark: this.transDetailModel.remark, wb_reff: this.transDetailModel.WB_reff, do_date: this.selectModel.doDate, 
            material_name : this.selectModel.materialName, trip_type : this.selectModel.trip_type, est_ton: this.selectModel.est_ton, est_pack: this.selectModel.est_pack, route_id: this.selectModel.routeId } );
        }
   })
  } 

  deleteDataTambahan(id: number, data: ListOfTrxCostDetail)
  { 
    this.getLabelCalculate();
    this.dataTambahan.splice(id, 1); this.changeDetectorRef.detectChanges();
    this.dataTambahanDelete.push({id: data.id, cost_type: this.tambahanModel.cost_type, category: this.tambahanModel.category, type:this.tambahanModel.type,
      amount: this.tambahanModel.amount, remark: this.tambahanModel.remark,  cost_name: this.tambahanModel.cost_name, approve : true, paid : false});
  }

  skipPayment(){
    this.dataTambahanDebt = [];
    this.paymentAdd = true;
    this.tripModel.skipPaymentStatus = true;
    this.debtPay = 0;
    this.debtTotal = 0;
    this.cicilan = 0;
  }

  paymentClear(){
    this.dataTotalPayment = 0;
    this.dataTambahanDebt = [];
    this.totalDebt = 0;
    this.paymentAdd = false;
    if (this.dataTambahan.length < 1){
      this.dataTotalPayment = this.totalTravelCost;
      console.log("KOSONG "+this.totalTravelCost)
    }
    this.tripModel.skipPaymentStatus = false;
    console.log("data tambahan :"+JSON.stringify(this.dataTambahan))
    this.dataTambahan.forEach((item) => {
      if (item.paid != true){
        this.checkPaid = true;
        console.log("masuk paid not")
        if (item.type == "PIUTANG")
        {
          this.dataTotalPayment -= item.amount;
        }
        else
        {
          this.dataTotalPayment += item.amount;
        }
      }
      // else{
      //   if (item.type == "PIUTANG")
      //   {
      //     this.dataTotalPayment -= item.amount;
      //   }
      //   else
      //   {
      //     this.dataTotalPayment += item.amount;
      //   }
      // }
    })
    this.dataTambahan.forEach((item) => {
      // if (item.type == "PIUTANG" && item.paid == false){
      if (item.type == "PIUTANG"){
        this.totalDebt += item.amount;
      }
    })
    if (this.checkPaid == true){
      this.tripModel.grandTotal = this.dataTotalPayment;
      this.travelCostPayment = this.dataTotalPayment;
      console.log("Total A "+this.tripModel.grandTotal)
    }
    else{
      console.log("Total Cost B "+this.totalCost)
      this.travelCostPayment = this.totalCost;
      this.tripModel.grandTotal = this.travelCostPayment;
      console.log("Total B "+this.tripModel.grandTotal)
    }
    // this.tripModel.grandTotal = this.tripModel.grandTotal - this.totalDebt;
  }


  saveCancel(){
    this.addCloseModel.companyCost = this.companyCost;
    this.addCloseModel.sisa = this.driverDebtCancel;
    this.addCloseModel.totalAmount = this.dirverReturn;
    this.addCloseModel.totalUj = this.amountTotalCancel;
    this.addCloseModel.transaction_id = this.transactionId;
      Swal.fire({
        title: 'Apakah anda yakin untuk menutup transaksi ini?',
        type: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: '      Yes     '
      }).then((result) => {
        if (result.value) {
          this.spinner.show();
          this.service.closeTrip(this.addCloseModel).subscribe(resp => {
            this.spinner.hide();
            if (resp.succes == true){
              Swal.fire({
                type: 'success',
                title: ""+resp.text,
                confirmButtonText: '      Ok!     '
              }).then((result) => {
                if (result.value) { 
                  this.ModalCancelHide.nativeElement.click();
                  this.getDataListTripRecord(this.dtTrip, this.cbTrip, this.dataTripDtoRaw,true);
                }
              
              });
            }
            else{
              Swal.fire({
                title: ""+resp.text,
                type: 'warning',
                confirmButtonText: '      Ok!     '
              });
            }
          });
        }
      
      }, (error) => {
      });

  }
     
  getDataType(){
    this.service.getListMasterCode("TripType").subscribe(resp => {
      this.dataType = resp;
    })
  }

  rollback(data: ListTripRecord){
    this.service.disableEditTrip(data.id).subscribe(resp => {
      if (resp > 0){
        return Swal.fire({
          title: "Trip tersebut sudah punya invoice!",
          type: 'warning',
          confirmButtonText: '      Ok!     '
        });
      }
      else{
        let quest = "Apakah anda yakin untuk me-rollback transaksi ini kembali menjadi Bagi DO? \n Data hutang akan dihapuskan pembayarannya!";
        Swal.fire({
          title: ''+quest,
          type: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: '      Yes     '
        }).then((result) => {
          if (result.value) {
            this.spinner.show();
            this.rollbackModel.transaction_id = data.id;
            this.rollbackModel.realizationType = data.status_text;
            this.service.doRollBack(this.rollbackModel).subscribe(resp => {
              this.spinner.hide();
              if (resp.succes == true){
                Swal.fire({
                  type: 'success',
                  title: ""+resp.text,
                  confirmButtonText: '      Ok!     '
                }).then((result) => {
                  if (result.value) { 
                    this.getDataListTripRecord(this.dtTrip, this.cbTrip, this.dataTripDto, true );
                  }
                
                });
              }
              else{
                Swal.fire({
                  title: ""+resp.text,
                  type: 'warning',
                  confirmButtonText: '      Ok!     '
                });
              }
            })
          }
        });
      }
   
  })
  }

  rollbackCancel(data: ListTripRecord){
    if(data.status_text != "Canceled"){
      Swal.fire({
        title: "Data yang terpilih bukan status cancel!",
        type: 'warning',
        confirmButtonText: '      Ok!     '
      });
    }
    else{
      let quest = "Apakah anda yakin untuk me-rollback transaksi ini kembali menjadi Bagi DO?";
        Swal.fire({
          title: ''+quest,
          type: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: '      Yes     '
        }).then((result) => {
          if (result.value) {
            this.spinner.show()
            this.service.doRollBackCancel(data.id).subscribe(resp => {
            this.spinner.hide();
              if (resp.succes == true){
                Swal.fire({
                  title: ""+resp.text,
                  type: 'success',
                  confirmButtonText: '      Ok!     ',
                }).then((result) => {
                  if (result.value) { 
                    this.getDataListTripRecord(this.dtTrip, this.cbTrip, this.dataTripDto, true );
                  }
                
                });
              }
              else{
                Swal.fire({
                  title: ""+resp.text,
                  type: 'warning',
                  confirmButtonText: '      Ok!     '
                });
              }
            })
          }
        });
    }
  }

  deletePaymentTrip(data : ListPaymentTrx){
    Swal.fire({
      title: 'Hapus Pembayaran?',
      type: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '      Yes     '
    }).then((result) => {
      if (result.value) {
        this.spinner.show();
        this.service.deleteTripPayment(data.id).subscribe(resp => {
          this.spinner.hide();
          if (resp.succes == true){
            Swal.fire({
              title: ""+resp.text,
              type: 'success',
              confirmButtonText: '      Ok!     ',
            }).then((result) => {
              if (result.value) { 
                this.getPaymentTrx(this.idTrip);
              }
            });
          }
          else{
            Swal.fire({
              title: ""+resp.text,
              type: 'warning',
              confirmButtonText: '      Ok!     '
            });
          }
      })

      }
    });
   
  }

  browseFile(){
    this.uploadFile.nativeElement.click();
    (<HTMLInputElement> document.getElementById("upload")).disabled = false;
  }

  setTipeFGS(){
    this.tipeUpload = "FGS";
  }

  setTipeCustomer(){
    this.tipeUpload = "Customer";
  }

  
  uploadFGS(){
    const fi = this.uploadFile.nativeElement;
    if (fi.files && fi.files[0]) {
      const fileToUpload = fi.files[0];
    this.spinner.show();
    if (this.tipeUpload == "FGS"){
      this.service.UploadFGS(fileToUpload,this.locationName).subscribe( resp => { 
        this.spinner.hide();
        if(resp.succes == true){
          this.dataUpload = resp.list;
          this.select = resp.list.filter(x => x.selected == true).length;
          this.totalData = resp.list.length;
          (<HTMLInputElement> document.getElementById("upload")).disabled = true;
        }
        else{
          Swal.fire({
            title: ""+resp.text,
            type: 'warning',
            confirmButtonText: '      Ok!     '
          });
        }
      })
    }
    else{
      this.service.UploadCustomer(fileToUpload, this.locationName).subscribe( resp => { 
        this.spinner.hide();
        if(resp.succes == true){
          this.dataUpload = resp.list;
          this.select = resp.list.filter(x => x.selected == true).length;
          this.totalData = resp.list.length;
          (<HTMLInputElement> document.getElementById("upload")).disabled = true;
        }
        else{
          Swal.fire({
            title: ""+resp.text,
            type: 'warning',
            confirmButtonText: '      Ok!     '
          });
        }
      })

    }
  }
}

saveUploadJournal(){
  this.dataSave = this.dataUpload.filter(item => item.selected == true);
  if(this.dataSave == null){
    return Swal.fire({
      title: "Belum Ada Data yang dipilih!",
      type: 'warning',
      confirmButtonText: '      Ok!     '
    });
  }
  if(this.dataSave.length == 0){
    return Swal.fire({
      title: "Belum Ada Data yang dipilih!",
      type: 'warning',
      confirmButtonText: '      Ok!     '
    });
  }
  this.inputSave.list = [];
  Swal.fire({
    title: 'Simpan Data?',
    // text: "You won't be able to revert this!",
    type: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: '      Yes     '
  }).then((result) => {
    this.dataSave.forEach(x => {
      this.inputSave.list.push({
        type: x.type,
        spk_no: x.spk_no,
        main_do_no: x.main_do_no,
        referensi: x.referensi,
        sim: x.sim,
        truck_no: x.truck_no,
        ekspedisi: x.ekspedisi,
        oa: x.oa,
        depart_date: x.depart_date,
        arrive_date: x.arrive_date,
        send_date: x.send_date,
        receive_date: x.receive_date,
        do_no: x.do_no,
        tonnase_send: x.tonnase_send,
        tonnase_received: x.tonnase_received,
        wb_reff: x.wb_reff,
        port: x.port,
        main_do_id: x.main_do_id,
        route_id: x.route_id,
        driver_id: x.driver_id,
        truck_id: x.truck_id,
        vendor_id: x.vendor_id,
        detail_do_id: x.detail_do_id,
        transaction_detail_id: x.transaction_detail_id,
        travel_cost_id: x.travel_cost_id,
        error_message: x.error_message,
        error: x.error,
        selected: x.selected,
      })
    });
      if (result.value) {
        this.spinner.show();
        this.service.saveUploadRealization(this.inputSave).subscribe( resp => {
          this.spinner.hide();
          if(resp.succes == false){
            Swal.fire({
              title: ""+resp.text,
              type: 'warning',
              confirmButtonText: '      Ok!     '
            });
          }
          else{
            Swal.fire({
              title: ""+resp.text,
              type: 'success',
              confirmButtonText: '      Ok!     '
            }).then((result) => {
              this.dataUpload = [];
              this.dataSave = [];
              if (result.value) { 
              }              
            }, (error) => {
            ; 
            })
          }
        })
      }
  });
}

  onFileSelected(event: Event) {
    const target = event.target as HTMLInputElement;
    if (target.files && target.files.length > 0) {
        this.fileName = target.files[0].name;
    }
}

saveTest() {
  this.dataSave = this.dataUpload.filter(item => item.selected == true);
}

  selectAll(){
    if (this.clean == false){
      this.dataUpload.filter(item => item.error != true).forEach(i => {
        i.selected = true;
      });
      this.clean = true;
  }
  else{
    this.dataUpload.filter(item => item.error != true).forEach(i => {
      i.selected = false;
    });
      this.clean = false;
  }
}

  uploadClear(){
    (<HTMLInputElement> document.getElementById("upload")).disabled = true;
    this.fileName = "";
    this.dataUpload = [];
  }

  onSelectUpdateDo(data: ListOfTrxDoDetail){
    this.updateDOModel.id = data.id;
    this.updateDOModel.tonnage_received = data.tonnage_received;
    this.updateDOModel.tonnage_send = data.tonnage_send;
    this.updateDOModel.pack_received = data.pack_received;
    this.updateDOModel.pack_send = data.pack_send;
    this.updateDOModel.wbreff = data.wb_reff;
  }

  updateDo(){
    if (this.updateDOModel.wbreff != null && this.updateDOModel.wbreff != ""){
      this.wbreffModel.transaction_detail_id = this.updateDOModel.id;
      this.wbreffModel.wbreff = this.updateDOModel.wbreff;
      this.service.checkWbReff(this.wbreffModel).subscribe( x => {
          if(x.succes == false){
            Swal.fire({
              title: ""+x.text,
              type: 'warning',
              confirmButtonText: '      Ok!     '
            }); 
          }
          else{
          this.service.updateDO(this.updateDOModel).subscribe( resp =>{
            if (resp.succes == true){
              this.service.getDoDetailTrans(this.idTrip).subscribe(resp => {
                this.listOfTrxDoDetail = resp;
              });
              this.ModalUpdateDoClose.nativeElement.click();
            }
            else{
              Swal.fire({
                title: ""+resp.text,
                type: 'warning',
                confirmButtonText: '      Ok!     '
              }); 
            }
          });
        }
      });
    }
    else{
      this.service.updateDO(this.updateDOModel).subscribe( resp =>{

        if (resp.succes == true){
          this.service.getDoDetailTrans(this.idTrip).subscribe(resp => {
            this.listOfTrxDoDetail = resp;
          });
          this.ModalUpdateDoClose.nativeElement.click();
        }
        else{
          Swal.fire({
            title: ""+resp.text,
            type: 'warning',
            confirmButtonText: '      Ok!     '
          }); 
        }
      })
    }
        
  }

  
exportSPK(){
  this.spinner.show();
  if (this.printDateFrom != null && this.printDateTo != null){
    this.service.printTripList(this.printDateFrom, this.printDateTo, localStorage.getItem('locationname')).subscribe(resp =>{
      this.spinner.hide();
      // let blob:Blob=resp.body as Blob;
      // let url = window.URL.createObjectURL(blob);
      // window.open(url);
      const blob = new Blob([resp.body],
        { type: 'application/vnd.ms-excel' });
    const file = new File([blob], 'Data_Trip.xlsx',
        { type: 'application/vnd.ms-excel' });

      saveAs(file);
      this.modalPrint.nativeElement.click();
    })
  }
  else{
  this.spinner.hide();
   return Swal.fire({
      title: "Data Belum Lengkap!",
      type: 'warning',
      confirmButtonText: '      Ok!     '
    });
  }
}

userAcces(){
const menuUser = localStorage.getItem("menu");
 if (menuUser != "All"){
    console.log("Masuk");
    this.btnCreate = false;
    this.btnUpload = false;
    this.btnUpload2 = false;
    this.btnUpdate = false;
    this.btnRealisasi = false;
    this.btnRollback = false;
    this.btnRollbackCancel = false;
    this.btnClose = false;
    this.btnfinish = false;
    const locationName = localStorage.getItem('locationname');

    if (locationName != "Palembang"){
          let arrayMenu =  menuUser.split(",");
          if (arrayMenu.includes("do") == false){
          return this.router.navigate(["/home"]);
          }
        
        this.authAcces = JSON.parse(localStorage.getItem("authAccess"));
        this.authAcces.filter(item => item.menu_name == "TripRecordForm").forEach(i => {
            if(i.name == "INSERT"){
              this.btnCreate = true;
            }
            if(i.name == "UPLOAD_TRIP"){
              this.btnUpload = true;
              this.btnUpload2 = true;
            }
            if(i.name == "UPDATE"){
              this.btnUpdate = true;
              this.btnCreate = true;
            }
            if(i.name == "REALIZATION_TRIP"){
              this.btnRealisasi = true;
            }
            if(i.name == "ROLLBACK_TRIP"){
              this.btnRollback = true;
              this.btnRollbackCancel = true; 
            }
            if(i.name == "CLOSE_TRIP"){
              this.btnClose = true;
            }
            if(i.name == "FINISH_TRIP"){
              this.btnfinish = true;
            }
            
        });
    }
    else{
      this.authAccessPlb = JSON.parse(localStorage.getItem("authAccessPlb"));
      
      var a = this.authAccessPlb.filter(item => item.subMenu  == "tripmanual").length;
      if (a == 0){
        return this.router.navigate(["/home"]);
      }
      
      this.authAccessPlb.filter(item => item.subMenu == "tripmanual").forEach(i => {
          if(i.auth == "CRUD"){
            this.btnCreate = true;
            this.btnUpdate = true;
          }
          if(i.auth == "ROLLBACK_TRIP"){
            this.btnRollback = true;
            this.btnRollbackCancel = true; 
          }
          if(i.auth == "CLOSE_TRIP"){
            this.btnClose = true;
          }
          if(i.auth == "FINISH_TRIP"){
            this.btnfinish = true;
          }
          
      });

      this.authAccessPlb.filter(item => item.subMenu == "realizationlist").forEach(i => {
        if(i.auth == "CRUD"){
          this.btnCreate = true;
          this.btnUpdate = true;
        }
        if(i.auth == "UPLOAD_TRIP"){
          this.btnUpload = true;
          this.btnUpload2 = true;
        }
        if(i.auth == "REALIZATION_TRIP"){
          this.btnRealisasi = true;
        }
        if(i.auth == "ROLLBACK_TRIP"){
          this.btnRollback = true;
          this.btnRollbackCancel = true; 
        }
        if(i.auth == "CLOSE_TRIP"){
          this.btnClose = true;
        }
        if(i.auth == "FINISH_TRIP"){
          this.btnfinish = true;
        }
        
      });
    }
  }
}

onSelectDOMain(main_do: string){
  this.dataListDo2 =  this.dataListDo.filter( x => x.main_do == main_do).sort((a, b) => {
    if (a.doNo < b.doNo) return -1;
    else if (a.doNo > b.doNo) return 1;
    else return 0;
  });

  this.tripModel.route_id =  this.listMainDoNo.filter( x => x.main_do == main_do)[0].route_id;
  this.tripModel.route_name =  this.listMainDoNo.filter( x => x.main_do == main_do)[0].route_name;
  this.routeId = this.tripModel.route_id;
  this.dtTriggerPort.next(this.dtOptionsPort);
  this.getDataPort(this.dtPort, this.cbPort,  this.routeId, this.capacityId,"",true);
}

getDetailPaymentTrip(data: ListPaymentTrx){
  this.spinner.show();
  this.service.getDetailPaymentTrip(data.id, data.type_name).subscribe(resp => {
    this.listTrxDetailPaymentTrip = resp;
    this.spinner.hide();
    setTimeout(() => {
      this.dtTriggerPaymentDetail.next(0);
    }, 500);
    this.ModalListPaymentShow.nativeElement.click();
  })
}

  printPayment(data : ListPaymentTrx){
    this.spinner.show();
    this.service.printTripPayment(data.id, this.locationName).subscribe(resp =>{
      this.spinner.hide();
      let blob:Blob=resp.body as Blob;
      let url = window.URL.createObjectURL(blob);
      window.open(url);
    })
  }

  onSelectCancelremark(data: ListTripRecord){
    this.cancelRemarkModel.id = data.id;
    this.cancelRemarkModel.reason = data.canceled_remark;
  }

  updateCancelreason(){
    Swal.fire({
      title: 'Update Data?',
      // text: "You won't be able to revert this!",
      type: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '      Yes     '
    }).then((result) => {
      if (result.value) {
        this.spinner.show();
        this.service.updateCancelRemark(this.cancelRemarkModel).subscribe(resp => {
          this.spinner.hide();
          if(resp.succes == false){
            Swal.fire({
              title: ""+resp.text,
              type: 'warning',
              confirmButtonText: '      Ok!     '
            });
          }
          else{
            Swal.fire({
              title: ""+resp.text,
              type: 'success',
              confirmButtonText: '      Ok!     '
            }).then((result) => {
              if (result.value) { 
                this.getDataListTripRecord(this.dtTrip, this.cbTrip, this.dataTripDtoRaw,true);
                this.ModalUpdateReasonClose.nativeElement.click();
              }              
            });
          }
        });
      }
    });
   
  }

  clearPayment(){
    this.travelCostPayment = 0;
    this.debtPay = 0;
    this.dataTambahanDebt = [];
    this.dataDriverDebt = [];
    this.dataDriverDebtTemp = [];
    this.debtTotal = 0;
    this.cicilan = 0;
    this.tripModel.grandTotal = 0;
    this.totalHutangTrip = 0;
  }

  getDataEkspedisi(dataTablesParameters, callback, relation_code: string, relation_name: string, relation_address: string, 
    relation_type: string, relation_sl_code: string, padging : boolean){ 
    var page = 1;
    if (padging == true){
      page = parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length) + 1;
    }
    this.service.getListRelation(dataTablesParameters, page, dataTablesParameters.length, relation_code,
      relation_name, relation_address, relation_type, relation_sl_code)
      .subscribe(resp => { 
        this.dataEkspedisi = resp.data;
        callback({
          recordsTotal: resp.totalCount,
          recordsFiltered: resp.totalCount,
           data:  []
        });
    });
  }

  showEkspedisi(){
    this.dtTriggerEkspedisi.next(0);
  }
  onSelectEkspedisi(data: Relation){
    this.ekspedisiName = data.relation_name;
    this.vendorModel.vendor = data.relation_id;
  } 

  onSelectChangeVendor(data: ListTripRecord){
    this.spinner.show();
    this.service.checkUpdateVendor(data.id).subscribe( resp =>{
      this.spinner.hide();
      if (resp == true){
        return  Swal.fire({
          title: "Sudah terdapat pembayaran ekspedisi terhadap transaksi ini !",
          type: 'warning',
          confirmButtonText: '      Ok!     '
        });
      }
      else{
        this.ekspedisiName = data.relation_name;
        this.vendorModel.id = data.id;
        this.vendorModel.vendor = data.vendor_id;
        this.vendorModel.cost = data.cost;
        this.vendorModel.driverName = data.driver_name;
        this.vendorModel.truckName = data.truck_plate_number;
        this.ModalUpdateVendorShow.nativeElement.click();
      }
    })
  }

  UpdateVendorTrip(){
    if(
      this.vendorModel.vendor == null || this.vendorModel.truckName == null || this.vendorModel.driverName == null ||
      this.vendorModel.cost == null
    )
    {
      return Swal.fire({
        title: "Data Belum Lengkap !",
        type: 'warning',
        confirmButtonText: '      Ok!     '
      });
    }
    else{
        
    Swal.fire({
      title: 'Simpan Data?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '      Yes     '
    }).then((result) => {
      if (result.value) {
           this.spinner.show();
            this.service.updateVendorTrip(this.vendorModel).subscribe(resp => { 
              this.spinner.hide();
            if(resp.succes == false){
              Swal.fire({
                title: ""+resp.text,
                type: 'warning',
                confirmButtonText: '      Ok!     '
              });
            }
            else{
              this.ModalUpdateVendorHide.nativeElement.click();
              Swal.fire({
                title: ""+resp.text,
                type: 'success',
                confirmButtonText: '      Ok!     '
              }).then((result) => {
                this.getDataListTripRecord(this.dtTrip, this.cbTrip, this.dataTripDtoRaw,true); 
              }, (error) => {
              ; 
              })
            }
            });
      }
    });
    }
   }


   tripDtoValue(){
    this.dataTripDto.transaction_type =  $('#transaction_type').val().toString();
    this.dataTripDto.reff =  $('#reff').val().toString();
    this.dataTripDto.system_reff =  $('#system_reff').val().toString();
    this.dataTripDto.do_detail =  $('#do_detail').val().toString();
    this.dataTripDto.route_name =  $('#route_name').val().toString();
    this.dataTripDto.route_to =  $('#route_to').val().toString();
    this.dataTripDto.relation_name =  $('#relation_name').val().toString();
    this.dataTripDto.cost =  $('#cost').val().toString();
    this.dataTripDto.driver_name =  $('#driver_name2').val().toString();
    this.dataTripDto.truck_plate_number =  $('#truck_plate_number').val().toString();
    this.dataTripDto.vehicle_type_name =  $('#vehicle_type_name').val().toString();
    this.dataTripDto.depart_date =  $('#depart_date').val().toString();
    this.dataTripDto.arrive_date =  $('#arrive_date').val().toString();
    if (this.menuRealization == false){
      this.dataTripDto.status_text = "DO Distributed";
    }
    else{
      this.dataTripDto.status_text =  $('#status_text').val().toString();
    }
    this.dataTripDto.delivery_date =  $('#delivery_date').val().toString();
    this.dataTripDto.transit_port_name =  $('#transit_port_name').val().toString();
    this.dataTripDto.canceled_remark =  $('#canceled_remark').val().toString();
    this.dataTripDto.created_by_text =  $('#created_by_text').val().toString();
    this.dataTripDto.created_at_text =  $('#created_at_text').val().toString();
    this.dataTripDto.updated_by_text =  $('#updated_by_text').val().toString();
    this.dataTripDto.updated_at_text =  $('#updated_at_text').val().toString();
   }

   onSelectUpdatePaymentDate(data: ListPaymentTrx){
    this.paymentDateModel.id = data.id;
    this.paymentDateModel.payment_date = data.payment_date;
   }

   updatePaymentDate(){
      Swal.fire({
        title: 'Update Data?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: '      Yes     '
      }).then((result) => {
        if (result.value) {
            this.spinner.show();
              this.service.updatePaymentDate(this.paymentDateModel).subscribe(resp => { 
                this.spinner.hide();
              if(resp.succes == false){
                Swal.fire({
                  title: ""+resp.text,
                  type: 'warning',
                  confirmButtonText: '      Ok!     '
                });
              }
              else{
                this.ModalUpdatePaymentDateClose.nativeElement.click();
                Swal.fire({
                  title: ""+resp.text,
                  type: 'success',
                  confirmButtonText: '      Ok!     '
                }).then((result) => {
                  this.getPaymentTrx(this.tripModel.id);
                }, (error) => {
                ; 
                })
              }
              });
        }
      });
   }

   onSelectSusutTrip(data: ListTripRecord){
    // this.service.disableEditTrip(data.id).subscribe(resp => {
    //   if (resp > 0){
    //     return Swal.fire({
    //       title: "Trip tersebut sudah punya invoice!",
    //       type: 'warning',
    //       confirmButtonText: '      Ok!     '
    //     });
    //   }
    //   else{
          this.susutModel = {transaction_id: null, receivedDate: null,susut_id: null, unitPrice: null,companyPaid: 0, 
            driverPaidTotal: 0, debtdate: null, totalSusut: 0, remarks: null};
          this.setModel.transaction_id = data.id;
          if (data.status_text != "Realisasi"){
            return Swal.fire({
              title: "Status DO Belum Realisasi!",
              type: 'warning',
              confirmButtonText: '      Ok!     '
            });
          }
          Swal.fire({
            title: 'Apakah Anda Yakin Transaksi Ini Memiliki Hutang Susut?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: '      Yes     '
          }).then((result) => {
            if (result.value) {
              this.spinner.show();
              this.service.setSusutTrip(this.setModel).subscribe( resp =>{
                this.spinner.hide();
                if(resp.succes == false){
                  Swal.fire({
                    title: ""+resp.text,
                    type: 'warning',
                    confirmButtonText: '      Ok!     '
                  });
                }
                else{
                  var today = moment().format('YYYY-MM-DD');
                  this.susutModel.debtdate = today;
                  this.susutModel.transaction_id = data.id;
                  this.susutModel.susut_id = resp.susutId;
                  this.susutModel.receivedDate = resp.receiveDate;
                  this.susutModel.totalSusut = resp.totalSusut;
                  this.printModel.transaction_id = data.id;
                  this.ModalSusutShow.nativeElement.click();
                }
              })
            }
          })
  //   }
  // })

   }

   testSelectSusut(data: ListTripRecord){
    this.susutModel.totalSusut = 10;
    this.ModalSusutShow.nativeElement.click();
   }

   countTotal(){
    this.totalSusut = this.susutModel.totalSusut * this.susutModel.unitPrice;
    if (this.susutModel.companyPaid == 0){
      this.susutModel.driverPaidTotal = this.totalSusut;
    }
   }

   countTotalDriverDebt(){
    this.susutModel.driverPaidTotal = this.totalSusut - this.susutModel.companyPaid;
   }

   addSusutTrip(){
    Swal.fire({
      title: 'Simpan Data?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '      Yes     '
    }).then((result) => {
      if (result.value) {
        this.service.addSusutTrip(this.susutModel).subscribe( resp =>{
          if(resp.succes == false){
            Swal.fire({
              title: ""+resp.text,
              type: 'warning',
              confirmButtonText: '      Ok!     '
            });
          }
          else{
            Swal.fire({
              title: ""+resp.text,
              type: 'success',
              confirmButtonText: '      Ok!     '
            });
            this.ModalSusutClose.nativeElement.click();
            this.ModalSignatureShow.nativeElement.click();
            this.getDataListTripRecord(this.dtTrip, this.cbTrip, this.dataTripDtoRaw,true);       
          
          }
      });
    }

    })
   }

   getSignature(){
    this.service.getSignature().subscribe(resp => { 
            this.dataSignature = resp;
            this.printModel.disetujui = resp[1].name;
            this.printModel.diketahui = resp[2].name;
            this.printModel.diketahui2 = resp[4].name;
            this.printModel.diketahui3 = resp[5].name;
            this.printModel.diketahui4 = resp[3].name;
        });
   }

   print(){
    if( this.printModel.diketahui == null || this.printModel.diketahui2 == null || this.printModel.diketahui3 == null
      || this.printModel.diketahui4 == null || this.printModel.disetujui == null){
      
      return  Swal.fire({
        title: "Data Belum Lengkap!",
        type: 'warning',
        confirmButtonText: '      Ok!     '
      });
    }
    else{
      this.spinner.show();
      this.printModel.susut = "susut";
      this.printModel.location = localStorage.getItem('locationname');
      this.service.printDriverDebt(this.printModel).subscribe(resp =>{
        this.spinner.hide();
      let blob:Blob=resp.body as Blob;
        let url = window.URL.createObjectURL(blob);
        window.open(url);
      })
    }
  }

  selectComplete(data){
    if(data == "true"){
      this.disableBox = true;
      this.searchModel.distributed = false;
      this.searchModel.realisasi = false;
      this.searchModel.cancel = false;
      this.searchModel.complete = true;
    }
    if(data == "false"){
      this.disableBox = false;
      this.searchModel.complete = false;
    }
  }

  searchData(){
    this.dataTripDtoRaw.routeName = this.searchModel.routeName;
    this.dataTripDtoRaw.vendorName = this.searchModel.vendorName;
    this.dataTripDtoRaw.reff = this.searchModel.reff;
    this.dataTripDtoRaw.systemReff = this.searchModel.systemReff;
    this.dataTripDtoRaw.driverLicense = this.searchModel.driverLicense;
    this.dataTripDtoRaw.truckPlatNumber = this.searchModel.truckPlatNumber;
    this.dataTripDtoRaw.departDateFrom = this.searchModel.departDateFrom;
    this.dataTripDtoRaw.departDateTo = this.searchModel.departDateTo;
    this.dataTripDtoRaw.arriveDateFrom = this.searchModel.arriveDateFrom;
    this.dataTripDtoRaw.arriveDateTo = this.searchModel.arriveDateTo;
    this.dataTripDtoRaw.receiveDateFrom = this.searchModel.receiveDateFrom;
    this.dataTripDtoRaw.receiveDateTo = this.searchModel.receiveDateTo;
    this.dataTripDtoRaw.doNo = this.searchModel.doNo;
    this.dataTripDtoRaw.mainDoNo = this.searchModel.mainDoNo;
    this.dataTripDtoRaw.spkNo = this.searchModel.spkNo;
    this.dataTripDtoRaw.realisasi= this.searchModel.realisasi;
    this.dataTripDtoRaw.distributed= this.searchModel.distributed;
    this.dataTripDtoRaw.cancel= this.searchModel. cancel;
    this.dataTripDtoRaw.complete= this.searchModel.complete;
    this.getDataListTripRecord(this.dtTrip, this.cbTrip, this.dataTripDtoRaw,true);
  }

  clearSearch(){
    this.searchModel = { routeName: null, vendorName: null, reff: null, systemReff: null,driverLicense: null,truckPlatNumber: null,
      departDateFrom: null,departDateTo: null,arriveDateFrom: null,arriveDateTo: null,receiveDateFrom: null,receiveDateTo: null,
      doNo: null,mainDoNo: null,spkNo: null, realisasi: true, distributed: true, cancel: null, complete: null};
  }

  
  releasePrint(id: Guid){
    this.spinner.show();
    this.service.CheckDOPrint(id).subscribe( resp =>{
      this.spinner.hide();
      if(resp.succes == false){
        Swal.fire({
          title: ""+resp.text,
          type: 'warning',
          confirmButtonText: '      Ok!     '
        });
      }
      else{
        Swal.fire({
          title: 'Release Print DO?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: '      Yes     '
         }).then((result) => {
          if (result.value) {
            this.service.ReleasePrintDO(id).subscribe( resp =>{
              if(resp.succes == false){
                Swal.fire({
                  title: ""+resp.text,
                  type: 'warning',
                  confirmButtonText: '      Ok!     '
                });
              }
              else{
                Swal.fire({
                  title: ""+resp.text,
                  type: 'success',
                  confirmButtonText: '      Ok!     '
                });
                this.getDataListTripRecord(this.dtTrip, this.cbTrip, this.dataTripDtoRaw,true);  
              }
            })
          }
        });

      }
    })
  }

  printDO(data: ListTripRecord){
    this.spinner.show();
    if (data.transaction_type == "EKSPEDISI"){
      this.spinner.hide();
      Swal.fire({
        title: 'Print DO?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: '      Yes     '
       }).then((result) => {
        if (result.value) {
          this.spinner.show();
          this.service.printDOTrip(data.id, this.locationName).subscribe(resp =>{
            this.spinner.hide();
            let blob:Blob=resp.body as Blob;
              let url = window.URL.createObjectURL(blob);
              window.open(url);
              this.getDataListTripRecord(this.dtTrip, this.cbTrip, this.dataTripDtoRaw,true);  
            })
        }
      });
    }
    else{
      this.service.CheckDOPrint(data.id).subscribe( resp =>{
        this.spinner.hide();
        if(resp.succes == true){
          Swal.fire({
            title: ""+resp.text,
            type: 'warning',
            confirmButtonText: '      Ok!     '
          });
        }
        else{
          Swal.fire({
            title: 'Print DO?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: '      Yes     '
           }).then((result) => {
            if (result.value) {
              this.spinner.show();
              this.service.printDOTrip(data.id, this.locationName).subscribe(resp =>{
                this.spinner.hide();
                let blob:Blob=resp.body as Blob;
                  let url = window.URL.createObjectURL(blob);
                  window.open(url);
                  this.getDataListTripRecord(this.dtTrip, this.cbTrip, this.dataTripDtoRaw,true);  
                })
            }
          });
  
        }
      })
    }

    

  }

  sort(column){
    if(column == "do"){
      // this.dataTripDtoRaw.sortBackdesc= null;
      // this.dataTripDtoRaw.sortEnddesc= null;
      // this.dataTripDtoRaw.sortUpdateDesc= null;
      // this.dataTripDtoRaw.sortCreateDesc= null;
      // this.dataTripDtoRaw.sortDOdesc= this.bol;
      // this.getDataListTripRecordSort(this.dtTrip, this.cbTrip, this.dataTripDtoRaw,true);
      if (this.bol == false){
        this.dataListTripRecord.sort((a, b) => moment(b.depart_date).unix() - moment(a.depart_date).unix());
        this.bol = true;
      }
      else{
        this.dataListTripRecord.sort((a, b) => moment(a.depart_date).unix() - moment(b.depart_date).unix());
        this.bol = false;
      }
    }
    if(column == "back"){
      if (this.bol == false){
        this.dataListTripRecord.sort((a, b) => moment(b.arrive_date).unix() - moment(a.arrive_date).unix());
        this.bol = true;
      }
      else{
        this.dataListTripRecord.sort((a, b) => moment(a.arrive_date).unix() - moment(b.arrive_date).unix());
        this.bol = false;
      }
    }
    if(column == "end"){
      if (this.bol == false){
        this.dataListTripRecord.sort((a, b) => moment(b.delivery_date).unix() - moment(a.delivery_date).unix());
        this.bol = true;
      }
      else{
        this.dataListTripRecord.sort((a, b) => moment(a.delivery_date).unix() - moment(b.delivery_date).unix());
        this.bol = false;
      }
    }
    if(column == "create"){
      if (this.bol == false){
        this.dataListTripRecord.sort((a, b) => moment(b.created_at_text).unix() - moment(a.created_at_text).unix());
        this.bol = true;
      }
      else{
        this.dataListTripRecord.sort((a, b) => moment(a.created_at_text).unix() - moment(b.created_at_text).unix());
        this.bol = false;
      }
    }
    if(column == "update"){
      if (this.bol == false){
        this.dataListTripRecord.sort((a, b) => moment(b.updated_at_text).unix() - moment(a.updated_at_text).unix());
        this.bol = true;
      }
      else{
        this.dataListTripRecord.sort((a, b) => moment(a.updated_at_text).unix() - moment(b.updated_at_text).unix());
        this.bol = false;
      }
    }
  }


  checkSelected(){
    this.select = this.dataUpload.filter(x => x.selected == true).length;
  }


addDays(date: Date, days: number): any {
  let startDate = new Date(date);
  return moment(startDate).add(days, "days");
}



}
