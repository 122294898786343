<section class="content">    
    <div class="card">
        <div class="card-header">
          <strong>Pembayaran Hutang</strong>
        </div>
        <div class="card-body">
            <table datatable [dtOptions]="dtOptionsPay" [dtTrigger]="dtTriggerPay" class="table-striped">
                <thead>
                  <!-- <tr>
                    <th> 
                      <select class="form-control">
                            <option *ngFor="let row of dataListDebtPay"><b>{{row.driver_name}}</b></option>
                      </select>   
                    </th>
                    <th><input id="driver_name" type="text" class="searchInput" placeholder="No DO"/></th>
                    <th><input id="driver_license" type="text" class="searchInput" placeholder="Nama Driver"/></th>
                    <th><input id="trx_reff" type="text" class="searchInput" placeholder="No DO"/></th>
                    <th><input id="type_name" type="text" class="searchInput" placeholder="Nama Driver"/></th>
                    <th><input hidden class="searchInput"/></th>
                    <th><input hidden class="searchInput"/></th>
                    <th><input hidden class="searchInput"/></th>
                  </tr> -->
                  <tr>
                    <th>Action</th>
                    <th>Nama Supir</th>
                    <th>SIM Supir</th>
                    <th>Sisa Hutang</th>
                    <th>Trx Reff</th>
                    <th>Jenis Hutang</th>
                    <th>Dibuat Oleh</th>
                    <th>Dibuat Pada</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of dataListDebtPay">    
                    <td>
                      <button (click)="onSelectDebt(row);ModalPay.show();" class="btn btn-sm btn-primary" title="Bayar Hutang" triggers="mouseenter:mouseleave"><i class="fa fa-credit-card"></i></button>&nbsp;
                    </td>       
                    <td>{{row.driver_name}}</td>
                    <td>{{row.driver_license}}</td>
                    <td>{{row.remaining}}</td>
                    <td>{{row.trx_reff}}</td>
                    <td>{{row.type_name}}</td>
                    <td>{{row.created_by_name}}</td>
                    <td>{{row.created_at | slice:0:10}}</td>
                </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <th><input hidden class="searchInput"/></th>
                        <th><input id="driver_name" type="text" class="searchInput" placeholder="Nama Driver"/></th>
                        <th><input id="driver_license" type="text" class="searchInput" placeholder="No SIM"/></th>
                        <th><input id="trx_reff" type="text" class="searchInput" placeholder="No DO"/></th>
                        <th><input id="type_name" type="text" class="searchInput" placeholder="Type"/></th>
                        <th><input hidden class="searchInput"/></th>
                        <th><input hidden class="searchInput"/></th>
                        <th><input hidden class="searchInput"/></th>
                    </tr>
                </tfoot>
              </table>
        </div>
    </div>
  </section>

    
<div bsModal #ModalPay="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog" style="max-width: 60%" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title">
            <strong>Bayar Hutang</strong>
          </div>
          <button type="button" class="close" (click)="ModalPay.hide()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-6"> 
                    <div class="form-group row">
                        <label class="col-md-4 col-form-label" for="text-input">Tipe Hutang</label>
                        <div class="col-md-7">
                            <input disabled type="text" [(ngModel)]="listModel.type_name" class="form-control">
                        </div>
                    </div> 
                    <div class="form-group row">
                        <label class="col-md-4 col-form-label" for="text-input">Nama Supir</label>
                        <div class="col-md-7">
                            <input disabled type="text" [(ngModel)]="listModel.driver_name" class="form-control">
                        </div>
                    </div> 
                    <div class="form-group row">
                        <label class="col-md-4 col-form-label" for="text-input">SIM</label>
                        <div class="col-md-7">
                            <input disabled type="text" [(ngModel)]="listModel.driver_license" class="form-control">
                        </div>
                    </div> 
                    <div class="form-group row">
                        <label class="col-md-4 col-form-label" for="text-input">Reff</label>
                        <div class="col-md-7">
                            <input disabled type="text" [(ngModel)]="listModel.trx_reff" class="form-control">
                        </div>
                    </div> 
                    <div class="form-group row">
                        <label class="col-md-4 col-form-label" for="text-input">Total Hutang</label>
                        <div class="col-md-7">
                            <input disabled type="text" value="{{listModel.amount | number}}" class="form-control">
                        </div>
                    </div> 
                    <div class="form-group row">
                        <label class="col-md-4 col-form-label" for="text-input">Sisa Hutang</label>
                        <div class="col-md-7">
                            <input disabled type="text" value="{{listModel.remaining | number}}" class="form-control">
                        </div>
                    </div> 
                </div> 
                <div class="col-md-6"> 
                    <div class="form-group row">
                        <label class="col-md-4 col-form-label" for="text-input">Tanggal Bayar</label>
                        <div class="col-md-7">
                            <input type="date" class="form-control" [ngModel]="debtModel.payment_date | date:'yyyy-MM-dd'" (ngModelChange)="debtModel.payment_date = $event">
                        </div>
                    </div> 
                    <div class="form-group row">
                        <label class="col-md-4 col-form-label" for="text-input">Jumlah Bayar</label>
                        <div class="col-md-7">
                            <input currencyMask [options]="{ prefix: '', thousands: '.', decimal: ',' ,precision: '0',align: 'left' }" type="text" [(ngModel)]="amountDebt" class="form-control">
                        </div>
                        <!-- <div class="col-sm-1">
                          <input type="checkbox" class="form-control">
                        </div>
                        <label class="col-sm-3 col-form-label" for="text-input">Ditanggung Perusahaan</label> -->
                    </div> 
                    <div class="form-group row">
                        <label class="col-md-4 col-form-label" for="text-input">Tanggal Adjusment</label>
                        <div class="col-md-7">
                            <input type="date" class="form-control" [ngModel]="debtModel.adjusment_date | date:'yyyy-MM-dd'" (ngModelChange)="debtModel.adjusment_date = $event">
                        </div>
                    </div> 
                    <div class="form-group row">
                      <label class="col-md-4 col-form-label" for="text-input">Keterangan</label>
                      <div class="col-md-7">
                          <textarea [(ngModel)]="debtModel.remarks" rows="5" type="text" class="form-control"></textarea>
                      </div>
                    </div> 
                    <div class="form-group row">
                      <label class="col-md-4 col-form-label" for="text-input"></label>
                      <div class="col-sm-1">
                        <input [(ngModel)]="debtModel.company_paid" type="checkbox" class="form-control">
                      </div>
                      <label class="col-md-6 col-form-label" for="text-input">Ditanggung Perusahaan</label>
                    </div> 
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" (click)="AddDebtPay()" class="btn btn-primary"><i class="fa fa-print"></i>&nbsp;<b>Save</b>&nbsp;</button>
          <button type="button" #ModalSimpanHide class="btn btn-secondary" (click)="ModalPay.hide()">&nbsp;<b>Cancel</b>&nbsp;</button> 
      </div>
      </div>
    </div>
  </div>

<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>