<div style="background-color:#f0f3f4 ;font-family: Verdana, Geneva, Tahoma, sans-serif;">
<section class="content">

    <div class="input-group mb-3">
      <div class="input-group-prepend">
          <a class="input-group-text">&nbsp;&nbsp;<i class="fa fa-search" style="font-size:18px">&nbsp;&nbsp;</i></a>
      </div>
      <input type="text" class="searchInput form-control" id="truckNo" placeholder="Search.." aria-describedby="basic-addon1">
    </div>
    <!-- <img style="display: inline;" src="assets/img/ringan.png" width="23px" height="23px"> -->
    <div class="row">
        <div class="col-md-12">
            <div class="form-group row">
                <div class="col-md-3"> 
                    <div class="card ringan">
                        <div class="card-body" style="text-align: center;font-weight: bold;">
                            <a style="cursor: pointer;" (click)="showSPK('Ringan')">
                                <p style="font-size: 18px;">RINGAN</p>
                                <p style="font-size: 40px;">{{countRingan}}</p>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-md-3"> 
                    <div class="card sedang">
                        <div class="card-body" style="text-align: center;font-weight: bold;">
                            <a style="cursor: pointer;" (click)="showSPK('Sedang')">
                                <p style="font-size: 18px;">SEDANG</p>
                                <p style="font-size: 40px;">{{countSedang}}</p>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-md-3"> 
                    <div class="card berat">
                        <div class="card-body" style="text-align: center;font-weight: bold;">
                            <a style="cursor: pointer;" (click)="showSPK('Berat')">
                                <p style="font-size: 18px;">BERAT</p>
                                <p style="font-size: 40px;">{{countBerat}}</p>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-md-3"> 
                    <div class="card sangatberat">
                        <div class="card-body" style="text-align: center;font-weight: bold;">
                            <a style="cursor: pointer;" (click)="showSPK('Sangat Berat')">
                                <p style="font-size: 18px;">SANGAT BERAT</p>
                                <p style="font-size: 40px;">{{countSangatBerat}}</p>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-md-4"> 
                    <div class="card">
                        <div class="card-header">
                            <b>DALAM ANTRIAN</b>&nbsp;&nbsp;<i class="fa fa-hourglass"></i>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div *ngFor="let row of dataNew" class="col-md-6"> 
                                    <div class="card" style="border-radius: 20%">
                                        <div class="card-body" style="text-align: center;font-weight: bold;">
                                            <a style="cursor: pointer;" (click)="onSelectDetailSPK(row);ModalSimpan.show()">
                                                <p style="font-size: 20px;">{{row.truckNo}}</p>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4"> 
                    <div class="card">
                        <div class="card-header">
                            <b >SEDANG DIKERJAKAN</b>&nbsp;&nbsp;<i class="fa fa-wrench"></i>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div *ngFor="let row of dataProcess" class="col-md-6"> 
                                    <div [hidden]="row.damageType !== 'Ringan'" class="card" style="border-radius: 20%">
                                        <div class="card-header bg-gradient-success">
                                        </div>
                                        <div class="card-body" style="text-align: center;font-weight: bold;">
                                            <a style="cursor: pointer;" (click)="onSelectDetailSPK(row);ModalSimpan.show()">
                                                <p style="font-size: 20px;">{{row.truckNo}}</p>
                                            </a>
                                        </div>
                                    </div>
                                    <div [hidden]="row.damageType !== 'Sedang'" class="card" style="border-radius: 20%">
                                        <div class="card-header bg-gradient-primary">
                                        </div>
                                        <div class="card-body" style="text-align: center;font-weight: bold;">
                                            <a style="cursor: pointer;" (click)="onSelectDetailSPK(row);ModalSimpan.show()">
                                                <p style="font-size: 20px;">{{row.truckNo}}</p>
                                            </a>
                                        </div>
                                    </div>
                                    <div [hidden]="row.damageType !== 'Berat'" class="card" style="border-radius: 20%">
                                        <div class="card-header bg-gradient-warning">
                                        </div>
                                        <div class="card-body" style="text-align: center;font-weight: bold;">
                                            <a style="cursor: pointer;" (click)="onSelectDetailSPK(row);ModalSimpan.show()">
                                                <p style="font-size: 20px;">{{row.truckNo}}</p>
                                            </a>
                                        </div>
                                    </div>
                                    <div [hidden]="row.damageType !== 'Sangat Berat'" class="card" style="border-radius: 20%">
                                        <div class="card-header bg-gradient-danger">
                                        </div>
                                        <div class="card-body" style="text-align: center;font-weight: bold;">
                                            <a style="cursor: pointer;" (click)="onSelectDetailSPK(row);ModalSimpan.show()">
                                                <p style="font-size: 20px;">{{row.truckNo}}</p>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4"> 
                    <div class="card">
                        <div class="card-header">
                            <b >SELESAI</b>&nbsp;&nbsp;<i class="fa fa-flag-checkered"></i>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div *ngFor="let row of dataFinish" class="col-md-6"> 
                                    <div [hidden]="row.damageType !== 'Ringan'" class="card" style="border-radius: 20%">
                                        <div class="card-header bg-gradient-success">
                                        </div>
                                        <div class="card-body" style="text-align: center;font-weight: bold;">
                                             <a style="cursor: pointer;" (click)="onSelectDetailSPK(row);ModalSimpan.show()">
                                                <p style="font-size: 20px;">{{row.truckNo}}</p>
                                            </a>
                                        </div>
                                    </div>
                                    <div [hidden]="row.damageType !== 'Sedang'" class="card" style="border-radius: 20%">
                                        <div class="card-header bg-gradient-primary">
                                        </div>
                                        <div class="card-body" style="text-align: center;font-weight: bold;">
                                             <a style="cursor: pointer;" (click)="onSelectDetailSPK(row);ModalSimpan.show()">
                                                <p style="font-size: 20px;">{{row.truckNo}}</p>
                                            </a>
                                        </div>
                                    </div>
                                    <div [hidden]="row.damageType !== 'Berat'" class="card" style="border-radius: 20%">
                                        <div class="card-header bg-gradient-warning">
                                        </div>
                                        <div class="card-body" style="text-align: center;font-weight: bold;">
                                             <a style="cursor: pointer;" (click)="onSelectDetailSPK(row);ModalSimpan.show()">
                                                <p style="font-size: 20px;">{{row.truckNo}}</p>
                                            </a>
                                        </div>
                                    </div>
                                    <div [hidden]="row.damageType !== 'Sangat Berat'" class="card" style="border-radius: 20%">
                                        <div class="card-header bg-gradient-danger">
                                        </div>
                                        <div class="card-body" style="text-align: center;font-weight: bold;">
                                             <a style="cursor: pointer;" (click)="onSelectDetailSPK(row);ModalSimpan.show()">
                                                <p style="font-size: 20px;">{{row.truckNo}}</p>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


</section>

</div>

<button #openModalSPK hidden (click)="ModalSPK.show()"></button>

<div  bsModal #ModalSPK="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog" style="max-width: 50%;" role="document">
    <div class="modal-content">
        <div class="modal-header">
        <div class="modal-title">
            <div class="row">
                <strong>SPK</strong>
            </div>
        </div>
        <button type="button" class="close" (click)="ModalSPK.hide()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
        </div>
        <div class="modal-body">
            <div class="scroll">
            <table datatable [dtOptions]="dtOptionsAcc" [dtTrigger]="dtTriggerAcc" class="deeps table-striped">
                <thead>
                  <tr>
                    <th>No SPK</th>
                    <th>Truk</th>
                    <th>Supir</th>
                    <th>Status</th>
                    <th>Dibuat Oleh</th>
                    <th>Dibuat Pada</th>
                    <th>Diubah Oleh</th>
                    <th>Diubah Pada</th>
                  </tr>
                </thead>
                 <tbody>
                    <tr *ngFor="let row of dataListSPK">      
                      <td>{{row.spkNo}}</td>       
                      <td>{{row.truckNo}}</td>       
                      <td>{{row.driverName}}</td>         
                      <td>{{row.status}}</td>    
                      <td>{{row.createdName}}</td>
                      <td>{{row.createdAt | slice:0:10}}</td>
                      <td>{{row.updatedName}}</td>
                      <td>{{row.createdAt | slice:0:10}}</td>
                   </tr>
                  </tbody>
              </table>
              </div>
        </div>
    </div>
    </div>
</div> 

<div  bsModal #ModalSimpan="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog" style="max-width: 80%;" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title">
            <div class="row">
              <strong>SPK Service</strong>
            </div>
          </div>
          <button type="button" class="close" (click)="ModalSimpan.hide()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group row">
                          <label class="col-md-3 col-form-label" for="text-input">NO SPK</label>
                          <div class="col-md-8">
                              <input disabled type="text" [(ngModel)]="spkModel.SpkNo" value="Test1234" class="form-control">
                          </div>
                        </div>
                        <div class="form-group row">
                          <label class="col-md-3 col-form-label" for="text-input">Truck</label>
                          <input hidden type="text" class="form-control">
                          <div class="col-md-8">
                            <input [(ngModel)]="spkModel.TruckNo" disabled type="text" class="form-control">
                          </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-3 col-form-label" for="text-input">Supir</label>
                            <input hidden type="text" class="form-control">
                            <div class="col-md-8">
                              <input [(ngModel)]="spkModel.DriverName" disabled type="text" class="form-control">
                            </div>
                          </div>
                    </div>
  
                    <div class="col-md-6">
                        <div class="form-group row group">
                            <label class="col-md-3 col-form-label" for="text-input">Include Ganti Oli</label>
                            <div class="col-md-8">
                                <select disabled [(ngModel)]="spkModel.OilChange" class="form-control">
                                <option value=true><b>Ya</b></option>
                                <option value=false selected><b>Tidak</b></option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-3 col-form-label" for="text-input">Waktu Mulai</label>
                            <div class="col-md-8">
                                <input disabled [(ngModel)]="spkModel.StartDate" type="datetime-local" class="form-control">
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-3 col-form-label" for="text-input">Waktu Selesai</label>
                            <div class="col-md-8">
                                <input disabled [(ngModel)]="spkModel.EndDate" type="datetime-local" class="form-control">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
            <br>
            <div class="card">
              <div class="card-body">
                <div class="card">
                    <div class="card-header">
                    </div>
                    <div class="card-body">
                        <table datatable [dtOptions]="dtOptionsDetail" [dtTrigger]="dtTriggerDetail" class="table-striped">
                            <thead>
                            <tr>
                                <th>Perbaikan</th>
                                <th>Dibuat Pada</th>
                                <th>Dibuat Oleh</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let row of listSpkDetail;">    
                                <td>{{row.remark}}</td>
                                <td>{{row.createdAt | slice:0:10}}</td>
                                <td>{{row.createdName}}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
              </div>
            </div>

        </div>
      </div>
    </div>
  </div> 