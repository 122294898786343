<section class="content">
  <div class="card">
      <div class="card-header">
        <strong>Rekam Perjalanan</strong>
        <div [hidden]="menuRealization" class="float-right">
          <a type="button" class="btn btn-sm btn-warning pull-right" data-toggle="modal" (click)="clearSearch();ModalSearch.show()">&nbsp;<b>Cari Data</b>&nbsp;&nbsp;<i class="fa fa-search"></i>&nbsp;</a> &nbsp;&nbsp;&nbsp;
          <a type="button" class="btn btn-sm btn-primary pull-right" data-toggle="modal" (click)="ModalPrint.show()">&nbsp;<b>Export Data</b>&nbsp;&nbsp;<i class="fa fa-print"></i>&nbsp;</a> &nbsp;&nbsp;&nbsp;
          <a type="button"  [hidden]="!btnCreate" class="btn btn-sm btn-primary pull-right" data-toggle="modal" (click)="setNull();ModalTrip.show()">&nbsp;&nbsp;&nbsp;<b>Trip Baru</b>&nbsp;&nbsp;&nbsp;&nbsp;<i class="fa fa-pen"></i>&nbsp;&nbsp;&nbsp;</a>
        </div>
        <div [hidden]="!menuRealization" class="float-right">
          <a type="button" class="btn btn-sm btn-warning pull-right" data-toggle="modal" (click)="clearSearch();ModalSearch.show()">&nbsp;<b>Cari Data</b>&nbsp;&nbsp;<i class="fa fa-search"></i>&nbsp;</a> &nbsp;&nbsp;&nbsp;
          <a [hidden]="!btnUpload" type="button" class="btn btn-sm btn-primary pull-right" data-toggle="modal" (click)="setTipeFGS();uploadClear();ModalUploadFGS.show()">&nbsp;<b>Upload Realisasi FGS</b>&nbsp;&nbsp;<i class="fa fa-upload"></i>&nbsp;&nbsp;</a> &nbsp;&nbsp;&nbsp;
          <a [hidden]="!btnUpload2" type="button" class="btn btn-sm btn-primary pull-right" data-toggle="modal" (click)="setTipeCustomer();uploadClear();ModalUploadFGS.show()">&nbsp;<b>Upload Realisasi Customer</b>&nbsp;&nbsp;<i class="fa fa-upload"></i>&nbsp;&nbsp;</a> &nbsp;&nbsp;&nbsp;
          <a type="button" class="btn btn-sm btn-primary pull-right" data-toggle="modal" (click)="ModalPrint.show()">&nbsp;<b>Export Data</b>&nbsp;&nbsp;<i class="fa fa-print"></i>&nbsp;</a>
        </div>
      </div>
      <div class="card-body">
          <table datatable [dtOptions]="dtOptionsTrip" [dtTrigger]="dtTriggerTrip" class="deeps table-striped">
              <thead>
                <tr>
                  <th class="first">Action</th>
                  <th>Jenis Perjalanan</th>
                  <th>Refrensi</th>
                  <th>System Reff</th>
                  <th>Status</th>
                  <!-- <th>Do Kecil</th> -->
                  <th>Rute</th>
                  <th>Dari</th>
                  <th>Ke</th>
                  <th>Ekspedisi</th>
                  <th>OA Ekspedisi</th>
                  <th>Nama Supir</th>
                  <th>Nomor Polisi Kendaraan</th>
                  <th>Fungsi Kendaraan</th>
                  <th>Tanggal Bagi DO  <a type="button" class="primary" (click)="sort('do')"><i class="fa fa-sort"></i></a></th>
                  <th>Tanggal Kembali  <a type="button" class="primary" (click)="sort('back')"><i class="fa fa-sort"></i></a></th>
                  <th>Tanggal Selesai  <a type="button" class="primary" (click)="sort('end')"><i class="fa fa-sort"></i></a></th>
                  <th>Transit</th>
                  <th>Alasan Cancel</th>
                  <th>Dibuat Oleh</th>
                  <th>Dibuat Pada <a type="button" class="primary" (click)="sort('create')"><i class="fa fa-sort"></i></a></th>
                  <th>Diubah Oleh</th>
                  <th>Diubah Pada <a type="button" class="primary" (click)="sort('update')"><i class="fa fa-sort"></i></a></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let row of dataListTripRecord">
                  <td>
                    <div [hidden]="menuRealization">
                      <button (click)="showDataTrip(row)" title="Lihat Data" class="btn btn-sm btn-primary" triggers="mouseenter:mouseleave"><i class="fa fa-eye"></i></button>&nbsp;
                      <button (click)="onSelectTrip(row);ModalTrip.show()" title="Edit" class="btnUpdate btn btn-sm btn-primary" triggers="mouseenter:mouseleave"><i class="fa fa-pen"></i></button>&nbsp;
                      <button [hidden]="!btnRollback" [disabled]="(row.status_text!='Realisasi' && menuRealization == true) || (row.status_text=='Realisasi' && menuRealization != true)" (click)="rollback(row)" title="Rollback" class="btn btn-sm btn-primary" triggers="mouseenter:mouseleave"><i class="fa fa-undo"></i></button>&nbsp;
                      <button [hidden]="!btnClose" [disabled]="row.status_text!='DO Distributed'" (click)="onSelectCancel(row);ModalCancel.show()" title="Tutup Trip" class="btn btn-sm btn-danger" triggers="mouseenter:mouseleave"><i class="fa fa-times"></i></button>&nbsp;
                      <button [hidden]="row.status_text!='DO Distributed' || ponti" [disabled]="(row.status_text!='DO Distributed' && row.status_text!='New')" (click)="printDO(row);" title="Cetak DO" class="btn btn-sm btn-primary"><i class="fa fa-print"></i></button>&nbsp;
                      <button [hidden]="row.status_text!='DO Distributed' || row.transaction_type == 'EKSPEDISI' || ponti" [disabled]="(row.status_text!='DO Distributed' && row.status_text!='New')" (click)="releasePrint(row.id);" title="Release Print DO" class="btn btn-sm btn-primary"><i class="fa fa-recycle"></i></button>&nbsp;
                    </div>
                    <div [hidden]="!menuRealization">
                      <button (click)="showDataTrip(row)" title="Lihat Data" class="btn btn-sm btn-primary" triggers="mouseenter:mouseleave"><i class="fa fa-eye"></i></button>&nbsp;
                      <button (click)="onSelectTrip(row)" title="Edit" class="btnUpdate btn btn-sm btn-primary" triggers="mouseenter:mouseleave"><i class="fa fa-pen"></i></button>&nbsp;
                      <button [hidden]="!menuRealization || !btnRealisasi" [disabled]="(row.status_text=='Canceled')"  (click)="onSelectTripRealization(row);" title="Realisasi" class="btn btn-sm btn-primary" triggers="mouseenter:mouseleave"><i class="fa fa-road"></i></button>&nbsp;
                      <button [hidden]="!btnRollback" [disabled]="(row.status_text!='Realisasi' && menuRealization == true) || (row.status_text=='Realisasi' && menuRealization != true)" (click)="rollback(row)" title="Rollback" class="btn btn-sm btn-primary" triggers="mouseenter:mouseleave"><i class="fa fa-undo"></i></button>&nbsp;
                      <!-- <button [hidden]="!btnfinish" [disabled]="((row.status_text!='Realisasi') || (row.delivered==true))" (click)="onSelectFinish(row);ModalFinish.show()" title="Selesai" class="btn btn-sm btn-danger" triggers="mouseenter:mouseleave"><i class="fa fa-flag-checkered"></i></button>&nbsp; -->
                      <button [hidden]="!btnfinish" [disabled]="row.status_text!='Realisasi'" (click)="onSelectFinish(row);ModalFinish.show()" title="Selesai" class="btn btn-sm btn-danger" triggers="mouseenter:mouseleave"><i class="fa fa-flag-checkered"></i></button>&nbsp;
                      <button [hidden]="!menuRealization || !btnRealisasi" (click)="onSelectSusutTrip(row);" title="Transaksi Susut" class="btn btn-sm btn-primary"><i class="fa fa-compress"></i></button>&nbsp;
                      <button [hidden]="(row.transaction_type != 'EKSPEDISI')" (click)="onSelectChangeVendor(row);" title="Ubah Ekspedisi" class="btn btn-sm btn-primary"><i class="fa fa-truck"></i></button>&nbsp;
                      <button [hidden]="(row.status_text != 'Canceled')" (click)="onSelectCancelremark(row);ModalUpdateReason.show()" title="Edit Alasan Cancel" class="btn btn-sm btn-warning"><i class="fa fa-pen"></i></button>&nbsp;
                    </div>
                    <!-- <button [hidden]="!btnRollbackCancel" (click)="rollbackCancel(row)" title="Rollback Cancel" class="btn btn-sm btn-danger" triggers="mouseenter:mouseleave"><i class="fa fa-undo"></i></button>&nbsp; -->
                  </td>
                  <td>{{row.transaction_type}}</td>
                  <td>{{row.reff}}</td>
                  <td>{{row.system_reff}}</td>
                  <td>{{row.status_text}}</td>
                  <!-- <td>{{row.do_detail}}</td>                     -->
                  <td>{{row.route_name}}</td>
                  <td>{{row.route_from}}</td>
                  <td>{{row.route_to}}</td>
                  <td>{{row.relation_name}}</td>
                  <td>{{row.cost}}</td>
                  <td>{{row.driver_name}}</td>
                  <td>{{row.truck_plate_number}}</td>
                  <td>{{row.vehicle_type_name}}</td>
                  <td>{{row.depart_date | slice:0:10}}</td>
                  <td>{{row.arrive_date | slice:0:10}}</td>
                  <td>{{row.delivery_date | slice:0:10}}</td>
                  <td>{{row.transit_port_name}}</td>
                  <td>{{row.canceled_remark}}</td>
                  <td>{{row.created_by_text}}</td>
                  <td>{{row.created_at_text | slice:0:10}}</td>
                  <td>{{row.updated_by_text}}</td>
                  <td>{{row.updated_at_text | slice:0:10}}</td>
              </tr>
              </tbody>
              <!-- <tfoot>
                  <tr>
                    <th><input id="do_detail" type="text" class="searchInput" placeholder="DO Kecil"/></th>
                    <th><input id="transaction_type" type="text" class="searchInput" placeholder="Jenis Perjalanan"/></th>
                    <th><input id="reff" type="text" class="searchInput" placeholder="Refrensi"/></th>
                    <th><input id="system_reff" type="text" class="searchInput" placeholder="System Reff"/></th>
                    <th><input id="status_text" type="text" class="searchInput" placeholder="Status"/></th>
                    <th><input id="route_name" type="text" class="searchInput" placeholder="Rute"/></th>
                    <th><input id="route_from" type="text" class="searchInput" placeholder="Dari"/></th>
                    <th><input id="route_to" type="text" class="searchInput" placeholder="Ke"/></th>
                    <th><input id="relation_name" type="text" class="searchInput" placeholder="Ekspedisi"/></th>
                    <th><input id="cost" type="text" class="searchInput" placeholder="OA Ekspedisi"/></th>
                    <th><input id="driver_name2" type="text" class="searchInput" placeholder="Nama Supir"/></th>
                    <th><input id="truck_plate_number" type="text" class="searchInput" placeholder="Nomor Polisi Kendaraan"/></th>
                    <th><input id="vehicle_type_name" type="text" class="searchInput" placeholder="Fungsi Kendaraan"/></th>
                    <th><input id="depart_date" type="text" class="searchInput" placeholder="Tanggal Bagi DO"/></th>
                    <th><input id="arrive_date" type="text" class="searchInput" placeholder="Tanggal Kembali"/></th>
                    <th><input id="delivery_date" type="text" class="searchInput" placeholder="Tanggal Selesai"/></th>
                    <th><input id="transit_port_name" type="text" class="searchInput" placeholder="Transit"/></th>
                    <th><input id="canceled_remark" type="text" class="searchInput" placeholder="Alasan Cancel"/></th>
                    <th><input id="created_by_text" type="text" class="searchInput" placeholder="Dibuat Oleh"/></th>
                    <th><input id="created_at_text" type="text" class="searchInput" placeholder="Dibuat Pada"/></th>
                    <th><input id="updated_by_text" type="text" class="searchInput" placeholder="Diubah Oleh"/></th>
                    <th><input id="updated_at_text" type="text" class="searchInput" placeholder="Diubah Pada"/></th>
                  </tr>
                </tfoot> -->
            </table>
      </div>
  </div>
</section>


<div bsModal #ModalTrip="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog" style="max-width: 90%;" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <!-- <div class="modal-title"><strong>{{action}} Data SPK</strong></div> -->
        <div class="modal-title"><strong>Input Trip baru</strong></div>
        <button type="button" class="close" #modalSaveClose (click)="ModalTrip.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
      <div class="card">
          <div class="card-body">
              <div class="row">
                  <div class="col-md-4">
                      <div class="form-group row group">
                          <label class="col-md-3 col-form-label" for="text-input">Perjalanan Dilakukan Oleh</label>
                          <div class="col-md-8">
                            <select [disabled]="actionUpdate" [(ngModel)]="tripModel.transaction_type_text" (change)="selectTransport()" class="form-control">
                              <option value="FGS" selected><b>FGS</b></option>
                              <option value="EKSPEDISI"><b>EKSPEDISI</b></option>
                            </select>
                          </div>
                      </div>
                      <div class="form-group row">
                          <label class="col-md-3 col-form-label" for="text-input">System Reff</label>
                          <div class="col-md-8">
                              <input disabled [(ngModel)]="tripModel.system_reff" type="text" class="form-control" id="contract_no">
                          </div>
                      </div>
                      <div class="form-group row">
                          <label class="col-md-3 col-form-label" for="text-input">No Refrensi</label>
                          <div class="col-md-8">
                              <input [disabled]="realisasiStatus" [(ngModel)]="tripModel.reff" type="text" class="form-control" id="spk_no">
                          </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-md-3 col-form-label" for="text-input">Rute</label>
                        <input hidden [(ngModel)]="tripModel.route_id"  type="text" class="form-control">
                        <div class="col-md-7">
                          <input [(ngModel)]="tripModel.route_name" disabled type="text" class="form-control">
                        </div>
                        <div class="col-md-2">
                            <button [disabled]="actionUpdate" class="btn btn-sm btn-primary"  (click)="showRoute();ModalRoute.show()"
                            data-toggle="modal"><i class="fa fa-plus"></i></button>
                        </div>
                      </div>
                      <div [hidden]="!menuRealization" class="form-group row">
                        <label class="col-md-3 col-form-label" for="text-input">Tanggal Muat</label>
                        <div class="col-md-8">
                            <input [disabled]="realisasiStatus" [ngModel]="tripModel.send_date | date:'yyyy-MM-dd'" (ngModelChange)="tripModel.send_date = $event" type="date" class="form-control" id="finish_date">
                        </div>
                      </div>
                  </div>

                  <div class="col-md-4">
                      <div class="form-group row">
                        <label class="col-md-3 col-form-label" for="text-input">Truck</label>
                        <div class="col-md-7">
                          <input [(ngModel)]="tripModel.truck_id" hidden disabled type="text" class="form-control">
                          <input [(ngModel)]="tripModel.truck_plate_number" [disabled]="!ekspedisi" type="text" class="form-control">
                        </div>
                        <div [hidden]="realisasiStatus" class="col-md-2">
                            <button [disabled]="ekspedisi" class="btn btn-sm btn-primary" (click)="showTruck();ModalTruck.show()"
                            data-toggle="modal"><i class="fa fa-plus"></i></button>
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-md-3 col-form-label" for="text-input">Supir</label>
                        <div class="col-md-7">
                          <input [(ngModel)]="tripModel.driver_id" hidden disabled type="text" class="form-control">
                          <input [(ngModel)]="tripModel.driver_name" [disabled]="!ekspedisi" type="text" class="form-control">
                        </div>
                        <div [hidden]="realisasiStatus" class="col-md-2">
                            <button [disabled]="ekspedisi" class="btn btn-sm btn-primary" (click)="showDriver();ModalDriver.show()"
                            data-toggle="modal"><i class="fa fa-plus"></i></button>
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-md-3 col-form-label" for="text-input">Jenis Kendaraan</label>
                        <div class="col-md-7">
                          <input [(ngModel)]="tripModel.vehicle_type_id" hidden disabled type="text" class="form-control">
                          <input [(ngModel)]="tripModel.vehicle_type_name" disabled type="text" class="form-control">
                        </div>
                        <div [hidden]="realisasiStatus" class="col-md-2">
                            <button [disabled]="ekspedisi" class="btn btn-sm btn-primary" (click)="showVehicle();ModalVehicle.show()"
                            data-toggle="modal"><i class="fa fa-plus"></i></button>
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-md-3 col-form-label" for="text-input">Transit</label>
                        <div class="col-md-7">
                          <input [disabled]="ekspedisi" [(ngModel)]="tripModel.transit_port" hidden disabled type="text" class="form-control">
                          <input [(ngModel)]="tripModel.transit_port_name"  disabled type="text" class="form-control">
                        </div>
                        <div class="col-md-2">
                            <button [disabled]="actionUpdate" class="btn btn-sm btn-primary" (click)="showPort();ModalPort.show()"
                            data-toggle="modal"><i class="fa fa-plus"></i></button>
                        </div>
                      </div>
                      <div [hidden]="!menuRealization" class="form-group row">
                        <label class="col-md-3 col-form-label" for="text-input">Tanggal Bongkar</label>
                        <div class="col-md-8">
                            <input [disabled]="realisasiStatus" [disabled]="difDays" [ngModel]="tripModel.receive_date | date:'yyyy-MM-dd'" type="date" class="form-control" (ngModelChange)="tripModel.receive_date = $event"   id="finish_date">
                        </div>
                      </div>
                  </div>

                  <div class="col-md-4">
                      <div class="form-group row">
                          <label class="col-md-3 col-form-label" for="text-input">Biaya OA</label>
                          <div class="col-md-8">
                              <input [disabled]="fgs" currencyMask [options]="{ prefix: '', thousands: '.', decimal: ',' ,precision: '0',align: 'left' }" [(ngModel)]="tripModel.cost" type="text" class="form-control" >
                          </div>
                      </div>
                      <div class="form-group row group">
                        <label class="col-md-3 col-form-label" for="text-input">Tipe Biaya</label>
                        <div class="col-md-8">
                          <select [disabled]="fgs" [(ngModel)]="tripModel.cost_type_external" class="form-control">
                            <option value="D7EDAB9B-15A7-4346-8609-A778366DDF4C"><b>Per Kuantiti</b></option>
                            <option value="81CE5A8B-7D0F-4E44-A458-C5E3D50479F9"><b>Per Trip</b></option>
                          </select>
                        </div>
                      </div>
                    <div class="form-group row">
                      <label class="col-md-3 col-form-label" for="text-input">Tanggal Bagi DO</label>
                      <div class="col-md-8">
                         <input [disabled]="realisasiStatus" [ngModel]="tripModel.depart_date | date:'yyyy-MM-dd'" (ngModelChange)="tripModel.depart_date = $event" type="date"   class="form-control">
                      </div>
                  </div>
                  <div [hidden]="!menuRealization" class="form-group row">
                    <label class="col-md-3 col-form-label" for="text-input">Tanggal Kembali</label>
                    <div class="col-md-8">
                        <input [disabled]="realisasiStatus" [ngModel]="tripModel.arrive_date | date:'yyyy-MM-dd'" (ngModelChange)="tripModel.arrive_date = $event" type="date" class="form-control" id="finish_date">
                    </div>
                  </div>
                  <div [hidden]="!menuRealization" class="form-group row group">
                    <label class="col-md-3 col-form-label" for="text-input">Fungsi Kendaraan</label>
                    <div class="col-md-8">
                      <select [disabled]="realisasiStatus" [(ngModel)]="tripModel.trip_type" class="form-control">
                        <option *ngFor="let row of dataType" [value]='row.id'><b>{{row.text}}</b></option>
                     </select>
                    </div>
                </div>
                </div>
              </div>
            </div>
      </div>
      <div class="card">
          <div class="card-body">
            <div>
              <!-- <button class="btn" (click)="updatePrev()"> Previous </button>
              <button class="btn" #clickTab (click)="getLabelCalculate()"> Next </button> -->
              </div>
              <tabset #tabset>
                <tab  id="0" heading="Delivery Order">
                  <div class="card">
                      <!-- <div class="card-header">
                      <strong>Data Tambahan</strong>
                     </div> -->
                      <div class="card-body">
                          <div class="row">
                              <div class="col-md-4">
                                <div class="form-group row">
                                      <label class="col-md-3 col-form-label" for="text-input">No DO Besar</label>
                                      <div class="col-md-8">
                                         <!-- <select [(ngModel)]="listMainDO" (ngModelChange)="onSelectDOMain($event)" class="form-control">
                                          <option *ngFor="let row of listMainDoNo" [ngValue]="row" ><b>{{row.main_do}}</b></option>
                                       </select>    -->
                                    <input  [(ngModel)]="listMainDO" (click)="onSelectDOMain(listMainDO)" (change)="onSelectDOMain(listMainDO)" list="menace" name="menace" class="form-control">
                                    <datalist id="menace">
                                    <option *ngFor="let row of listMainDoNo" [ngValue]="row">{{row.main_do}} </option>
                                    </datalist>
                                      </div>
                                  </div>
                                  <div class="form-group row">
                                      <label class="col-md-3 col-form-label" for="text-input">No DO Kecil</label>
                                      <div class="col-md-8">
                                         <!-- <select [(ngModel)]="modelMainDoNo" (ngModelChange)="onSelectDataRow($event)" class="form-control"> -->
                                          <select [(ngModel)]="modelMainDoNo" (ngModelChange)="onSelectDataRow($event)"  class="form-control">
                                          <!-- <option *ngFor="let row of dataListDo2" [ngValue]="row" (click)="onSelectDataRow(row)" ><b>{{row.doNo}}</b></option> -->
                                          <option><b></b></option>
                                          <option *ngFor="let row of dataListDo2" [ngValue]="row" ><b>{{row.doNo}}</b></option>
                                       </select>
                                      </div>
                                  </div>
                                  <div class="form-group row">
                                    <label class="col-md-3 col-form-label" for="text-input">WB Reff</label>
                                    <div class="col-md-8">
                                      <input [(ngModel)]="transDetailModel.WB_reff" [ngModelOptions]="{standalone: true}" type="text" class="form-control" >
                                    </div>
                                  </div>
                              </div>
                              <div class="col-md-4">
                                  <div class="form-group row">
                                    <label class="col-md-3 col-form-label" for="text-input">Tonnase Kirim</label>
                                    <div class="col-md-8">
                                        <input type="text" [(ngModel)]="transDetailModel.tonnage_send" [ngModelOptions]="{standalone: true}" class="form-control" currencyMask [options]="{ prefix: '', thousands: '.', decimal: ',' ,precision: '0',align: 'left' }">
                                    </div>
                                  </div>
                                  <div class="form-group row">
                                    <label class="col-md-3 col-form-label" for="text-input">Tonnase Terima</label>
                                    <div class="col-md-8">
                                      <input [(ngModel)]="transDetailModel.tonnage_received" [ngModelOptions]="{standalone: true}" type="text" class="form-control" currencyMask [options]="{ prefix: '', thousands: '.', decimal: ',' ,precision: '0',align: 'left' }">
                                    </div>
                                  </div>
                              </div>
                              <div class="col-md-4">
                                  <div class="form-group row">
                                    <label class="col-md-3 col-form-label" for="text-input">ZAK Kirim</label>
                                    <div class="col-md-8">
                                        <input [(ngModel)]="transDetailModel.pack_send" [ngModelOptions]="{standalone: true}" type="text" class="form-control" currencyMask [options]="{ prefix: '', thousands: '.', decimal: ',' ,precision: '0',align: 'left' }">
                                    </div>
                                  </div>
                                  <div class="form-group row">
                                    <label class="col-md-3 col-form-label" for="text-input">ZAK Terima</label>
                                    <div class="col-md-8">
                                      <input [(ngModel)]="transDetailModel.pack_received" [ngModelOptions]="{standalone: true}" type="text" class="form-control" currencyMask [options]="{ prefix: '', thousands: '.', decimal: ',' ,precision: '0',align: 'left' }">
                                   </div>
                                </div>
                              </div>
                          </div>
                          <div style="text-align: center">
                              <!-- <button [disabled]="realisasiStatus" (click)="AddDatDetailModel()" type="button" class="btn btn-sm btn-primary">&nbsp;<b>Tambah</b>&nbsp;</button> -->
                              <button [hidden]="notSave" (click)="AddDatDetailModel()" type="button" class="btn btn-sm btn-primary">&nbsp;<b>Tambah</b>&nbsp;</button>
                          </div>
                          <br>
                          <br>
                          <div class="card">
                              <div class="card-body">
                                  <table class="table table-striped table-sm">
                                      <thead>
                                      <tr>
                                          <th>Action</th>
                                          <th>No DO</th>
                                          <th>DO Besar</th>
                                          <th>Tanggal DO</th>
                                          <th>Nama Material</th>
                                          <th>Berat Dikirim</th>
                                          <th>Berat Diterima</th>
                                          <th>ZAK Kirim</th>
                                          <th>ZAK Terima</th>
                                          <th>Est Berat</th>
                                          <th>Est Kemasan</th>
                                          <th>Slip Timbangan</th>
                                      </tr>
                                      </thead>
                                      <tbody>
                                      <tr *ngFor="let row of listOfTrxDoDetail; let i=index">
                                          <td>
                                            <button [hidden]="notSave" [disabled]="row.id==null || invEdit==true" (click)="ModalUpdateDo.show();onSelectUpdateDo(row)" title="Edit" class="btn btn-sm btn-primary" triggers="mouseenter:mouseleave"><i class="fa fa-pen"></i></button>&nbsp;
                                            <button [hidden]="notSave" [disabled]="invEdit==true" (click)="deleteDataDoDetail(i,row)" title="Delete" class="btn btn-sm btn-danger" triggers="mouseenter:mouseleave"><i class="fa fa-trash"></i></button>&nbsp;
                                          </td>
                                          <td>{{row.do_detail_no}}</td>
                                          <td>{{row.main_do}}</td>
                                          <td>{{row.do_date | slice:0:10}}</td>
                                          <td>{{row.material_name}}</td>
                                          <td>{{row.tonnage_send}}</td>
                                          <td>{{row.tonnage_received}}</td>
                                          <td>{{row.pack_send}}</td>
                                          <td>{{row.pack_received}}</td>
                                          <td>{{row.est_ton}}</td>
                                          <td>{{row.est_pack}}</td>
                                          <td>{{row.wb_reff}}</td>
                                          <td></td>
                                      </tr>
                                      </tbody>
                                  </table>
                              </div>
                          </div>
                      </div>
                  </div>
              </tab>
                  <tab  id="1" [disabled]="cost" heading="Biaya" (select)="getLabelCalculate()">
                      <div class="card">
                          <!-- <div class="card-header">
                          <strong>Data Tambahan</strong>
                         </div> -->
                          <div class="card-body">
                              <div class="row">
                                  <div class="col-md-4">
                                      <div class="form-group row">
                                          <label class="col-md-3 col-form-label" for="text-input">Jenis Biaya</label>
                                          <div class="col-md-8">
                                            <select [(ngModel)]="model" (ngModelChange)="selectCost($event)" [ngModelOptions]="{standalone: true}" class="form-control" >
                                              <option *ngFor="let row of ListCostType" [ngValue]="row"><b>{{row.name}}</b></option>
                                            </select>
                                          </div>
                                      </div>
                                  </div>
                                  <div class="col-md-4">
                                      <div class="form-group row">
                                          <label class="col-md-3 col-form-label" for="text-input">Jumlah</label>
                                          <div class="col-md-8">
                                              <input [(ngModel)]="tambahanModel.amount" currencyMask [options]="{ prefix: '', thousands: '.', decimal: ',' ,precision: '0',align: 'left' }"  type="text" class="form-control" >
                                          </div>
                                      </div>
                                  </div>
                                  <div class="col-md-4">
                                      <div class="form-group row">
                                          <label class="col-md-3 col-form-label" for="text-input">Keterangan</label>
                                          <div class="col-md-8">
                                              <input [(ngModel)]="tambahanModel.remark" [ngModelOptions]="{standalone: true}" type="text" class="form-control" >
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div [hidden]="difDays" style="text-align: center">
                                  <button [hidden]="notSave"  [disabled]="cost" (click)="AddDataTambahan()" type="button" class="btn btn-sm btn-primary">&nbsp;Tambah&nbsp;</button>
                              </div>
                              <br>
                              <br>
                              <div class="card">
                                  <div class="card-body">
                                    <div class="scroll">
                                      <table class="table table-striped table-sm">
                                          <thead>
                                          <tr>
                                              <th>Kategori</th>
                                              <th>Jenis Biaya</th>
                                              <th>Tipe</th>
                                              <th>Jumlah</th>
                                              <th>Keterangan</th>
                                              <th>Disetujui</th>
                                              <th>Dibayar</th>
                                              <th>Dibuat Oleh</th>
                                              <th>Dibuat Pada</th>
                                              <!-- 
                                              <th>Diubah Oleh</th>
                                              <th>Diubah Pada</th> -->
                                              <th>Action</th>
                                          </tr>
                                          </thead>
                                          <tbody>
                                          <tr *ngFor="let row of filterCategory(dataTambahan); let i=index">
                                            <!-- <tr *ngFor="let row of dataTambahan; let i=index" [hidden]="row.category == 'Uang Jalan'">  -->
                                            <!-- <tr *ngFor="let row of dataTambahan; let i=index"> -->
                                              <td>{{row.category}}</td>
                                              <td>{{row.cost_name}}</td>
                                              <td>{{row.type}}</td>
                                              <td>{{row.amount | number}}</td>
                                              <td>{{row.remark}}</td>
                                              <td><input disabled [checked]="row.approve" type="checkbox"/></td>
                                              <td><input disabled [checked]="row.paid" type="checkbox"/></td>
                                              <td>{{row.created_by}}</td>
                                              <td>{{row.created_at | slice:0:10}} {{row.created_at | slice:11:16}}</td>
                                              <td><button [hidden]="notSave" class="btn btn-sm btn-danger" (click)="deleteDataTambahan(i, row)" triggers="mouseenter:mouseleave"><i class="fa fa-trash"></i></button></td>
                                          </tr>
                                          </tbody>
                                      </table>
                                    </div>
                                  </div>
                              </div>
                              <br>
                              <div class="row">
                                <div class="col-lg-1 col-6">
                                </div>
                                <div class="col-lg-2 col-6">
                                    <div class="small-box">
                                        <div class="inner">
                                            <h3>Rp. {{ totalTravelCost | number }}</h3>
                                            <p>Total Uang Jalan</p>
                                        </div>
                                    </div>
                                </div>
                                  <div class="col-lg-2 col-6">
                                      <div class="small-box">
                                          <div class="inner">
                                              <h3>Rp. {{ totalCost | number }}</h3>
                                              <p>Grand Total</p>
                                          </div>
                                      </div>
                                  </div>
                                  <div class="col-lg-2 col-6">
                                      <!-- <div class="small-box">
                                          <div class="inner">
                                              <h3>Rp. {{ totalInvoice | number }}</h3>
                                              <p>Invoice Ekspedisi</p>
                                          </div>
                                      </div> -->
                                  </div>
                                  <div class="col-lg-2 col-6">
                                      <!-- <div class="small-box">
                                          <div class="inner">
                                              <h3>Rp. {{ totalInvoiceVendor | number }}</h3>
                                              <p>Total Invoice</p>
                                          </div>
                                      </div> -->
                                  </div>
                                  <div class="col-lg-2 col-6">
                                    <!-- <div class="small-box">
                                        <div class="inner">
                                            <h3>Rp. {{ profitLoss | number }}</h3>
                                            <p>Profit/Loss</p>
                                        </div>
                                    </div> -->
                                  </div>
                                  <div class="col-lg-1 col-6">
                                  </div>

                              </div>
                          </div>
                      </div>
                  </tab>
                  <!-- <tab id="2" [hidden]="!menuRealization" heading="Klaim Susut">
                    <div class="card">
                        <div class="card-body">
                          <div class="row">
                            <div class="col-md-4">
                                <div class="form-group row">
                                    <label class="col-md-3 col-form-label" for="text-input">No DO Kecil</label>
                                    <div class="col-md-8">
                                      <select [(ngModel)]="model" (ngModelChange)="onSelectSusut($event)" class="form-control">
                                        <option *ngFor="let row of listOfTrxDoDetail" [ngValue]="row"><b>{{row.do_detail_no}}</b></option>
                                     </select>
                                    </div>
                                </div>
                                <div class="form-group row">
                                  <label class="col-md-3 col-form-label" for="text-input">QTY Susut</label>
                                  <div class="col-md-8">
                                    <input disabled [(ngModel)]="qtySusut" type="text" class="form-control" >
                                  </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group row">
                                  <label class="col-md-4 col-form-label" for="text-input">Harga Perkuantitas</label>
                                  <div class="col-md-7">
                                      <input type="text" [(ngModel)]="qtyPrice" class="form-control" (ngModelChange)="onChangeSusut()" currencyMask [options]="{ prefix: '', thousands: '.', decimal: ',' ,precision: '0',align: 'left' }">
                                  </div>
                                </div>
                                <div class="form-group row">
                                  <label class="col-md-4 col-form-label" for="text-input">Total Hutang Susut</label>
                                  <div class="col-md-7">
                                    <input disabled type="text" [(ngModel)]="debtSusut" class="form-control" currencyMask [options]="{ prefix: '', thousands: '.', decimal: ',' ,precision: '0',align: 'left' }">
                                  </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group row">
                                  <label class="col-md-3 col-form-label" for="text-input">Hutang Trip</label>
                                  <div class="col-md-8">
                                      <input type="text" [(ngModel)]="debtTrip" class="form-control" currencyMask [options]="{ prefix: '', thousands: '.', decimal: ',' ,precision: '0',align: 'left' }">
                                  </div>
                                </div>
                                <div class="form-group row">
                                  <label class="col-md-3 col-form-label" for="text-input">Hutang Premi</label>
                                  <div class="col-md-8">
                                    <input type="text" [(ngModel)]="debtPremi" class="form-control" currencyMask [options]="{ prefix: '', thousands: '.', decimal: ',' ,precision: '0',align: 'left' }">
                                 </div>
                              </div>
                            </div>
                        </div>
                        <div style="text-align: center">
                            <button (click)="claimSusut()" type="button" class="btn btn-sm btn-primary">&nbsp;<b>Tambah</b>&nbsp;</button>
                        </div>
                        <br>
                        <br>
                          <div class="card">
                            <div class="card-body">
                              <table class="table table-striped table-sm">
                                <thead>
                                  <tr>
                                    <th>Reff Transaksi</th>
                                    <th>Rute</th>
                                    <th>No DO Kecil</th>
                                    <th>Jenis Hutang</th>
                                    <th>Dibayar Pada</th>
                                    <th>Nama Supir</th>
                                    <th>SIM Supir</th>
                                    <th>Total Hutang</th>
                                    <th>Sisa Hutang</th>
                                    <th>Debt No</th>
                                    <th>Dibuat Hutang Pada</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr *ngFor="let row of listSusut">
                                    <td>{{row.do_no}}</td>
                                    <td>{{row.route_name}}</td>
                                    <td>{{row.do_no}}</td>
                                    <td>{{row.type_text}}</td>
                                    <td>{{row.paid_on_text}}</td>
                                    <td>{{row.driver_name}}</td>
                                    <td>{{row.driver_license}}</td>
                                    <td>{{row.amount}}</td>
                                    <td>{{row.debt_remaining}}</td>
                                    <td>{{row.debt_no}}</td>
                                    <td>{{row.updated_at | slice:0:10}}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                    </div>
                </tab> -->
                  <tab  id="2" heading="Payment History">
                      <div class="card">
                          <div class="card-body">
                              <div class="col-md-12">
                                <table datatable [dtOptions]="dtOptionsPayment" [dtTrigger]="dtTriggerPayment" class="table table-striped table-sm">
                                  <thead>
                                  <tr>
                                      <th>Action</th>
                                      <!-- <th>Reff</th> -->
                                      <th>Tipe</th>
                                      <th>No Kwitansi</th>
                                      <th>Tanggal Bayar</th>
                                      <th>Dibuat Pada</th>
                                      <th>Dihapus</th>
                                      <th>Status Approval</th>
                                      <th>Approve/Reject Pada</th>
                                      <th>Approve/Reject Oleh</th>
                                      <th>Dibuat Oleh</th>
                                      <th>Bayar Hutang</th>
                                      <th>Total Uang Jalan</th>
                                      <th>Total Dibayarkan</th>
                                  </tr>
                                  </thead>
                                  <tbody>
                                  <tr *ngFor="let row of dataListPayment">
                                      <td>
                                        <button [disabled]="row.document_status != 'Approved'" (click)="printPayment(row)" class="btn btn-sm btn-primary" title="Cetak"><i class="fa fa-print"></i></button>&nbsp;
                                        <button (click)="getDetailPaymentTrip(row)" class="btn btn-sm btn-primary" title="Detail"><i class="fa fa-list"></i></button>&nbsp;
                                        <button [hidden]="notSave" (click)="onSelectUpdatePaymentDate(row);ModalUpdatePaymentDate.show()" class="btn btn-sm btn-warning" title="Ubah Tanggal Pembayaran"><i class="fa fa-pen"></i></button>&nbsp;
                                        <button [hidden]="notSave" [disabled]="row.deleted" (click)="deletePaymentTrip(row)" class="btn btn-sm btn-danger" title="Delete" triggers="mouseenter:mouseleave"><i class="fa fa-trash"></i></button>&nbsp;
                                      </td>
                                      <!-- <td>{{row.reff}}</td> -->
                                      <td>{{row.type_name}}</td>
                                      <td>{{row.trx_payment_no}}</td>
                                      <td>{{row.payment_date | slice:0:10}} {{row.payment_date | slice:11:16}}</td>
                                      <td>{{row.created_at | slice:0:10}} {{row.created_at | slice:11:16}}</td>
                                      <td><input disabled type="checkbox" [checked]="row.deleted"/></td>
                                      <td>{{row.document_status}}</td>
                                      <td>{{row.approved_at}}</td>
                                      <td>{{row.approved_by}}</td>
                                      <td>{{row.created_by_name}}</td>
                                      <td>{{row.debt | number}}</td>
                                      <td>{{row.total | number}}</td>
                                      <td>{{row.total_paid | number}}</td>
                                  </tr>
                                  </tbody>
                              </table>
                              </div>
                          </div>
                      </div>
                  </tab>
              </tabset>
          </div>
      </div>
      </div>
      <div class="modal-footer">
          <button #modalCreateClose type="button" class="btn btn-secondary" (click)="ModalTrip.hide()">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>Close</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</button>
          <button [hidden]="notSave" [disabled]="detail" type="button" (click)="save()" class="btn btn-primary"><i class="fa fa-save"></i>&nbsp;&nbsp;&nbsp;&nbsp;<b>Simpan</b>&nbsp;&nbsp;&nbsp;&nbsp;</button>
          <button [hidden]="notSave" [disabled]="detail" type="button" (click)="savePayment()" class="btn btn-primary"><i class="fas fa-hand-holding-usd"></i><b>&nbsp;Simpan & Bayar</b></button>
          </div>
    </div>
  </div>
</div>


<div bsModal #ModalRoute="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title">
          <div class="row">
            <strong>Pilih Rute</strong>
          </div>
        </div>
        <button type="button" class="close" (click)="ModalRoute.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
          <!-- <app-master-relation></app-master-relation> -->
          <table datatable [dtOptions]="dtOptionsRoute" [dtTrigger]="dtTriggerRoute" class="deeps table-striped">
            <thead>
              <tr>
                <th>Action</th>
                <th>Rute</th>
                <th>Lokasi Muat</th>
                <th>Lokasi Bongkar</th>
                <th>Tipe Rute</th>
                <th>Diubah Oleh</th>
                <th>Dibuat Pada</th>
                <th>Diubah Oleh</th>
                <th>Diubah Pada</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let row of dataRoute">
                <td>
                  <button (click)="onSelectRoute(row);ModalRoute.hide()" class="btn btn-sm btn-primary"  data-toggle="modal" triggers="mouseenter:mouseleave"><i class="fa fa-check"></i></button>
                </td>
                <td>{{row.route_name}}</td>
                <td>{{row.loadtext}}</td>
                <td>{{row.unloadtext}}</td>
                <td>{{row.route_type}}</td>
                <td>{{row.createdby}}</td>
                <td>{{row.created_at | slice:0:10}}</td>
                <td>{{row.updatedby}}</td>
                <td>{{row.updated_at | slice:0:10}}</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th><input hidden class="searchRute"/></th>
                <th><input id="nameRute" type="text" class="searchRute" placeholder="Cari Nama" /></th>
                <th><input id="loadRute" type="text" class="searchRute" placeholder="Cari Muat" /></th>
                <th><input id="unloadRute" type="text" class="searchRute" placeholder="Cari Bongkar" /></th>
                <th><input id="typeRute" type="text" class="searchRute" placeholder="Cari Type" /></th>
                <th><input hidden class="searchRute"/></th>
                <th><input hidden class="searchRute"/></th>
                <th><input hidden class="searchRute"/></th>
                <th><input hidden class="searchRute"/></th>
              </tr>
            </tfoot>
          </table>
      </div>
    </div>
  </div>
</div>


<div  bsModal #ModalTruck="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog  modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title"><strong>Pilih Truk</strong></div>
        <button type="button" class="close" (click)="ModalTruck.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <table datatable [dtOptions]="dtOptionsTruck" [dtTrigger]="dtTriggerTruck"  class="deeps table-striped">
          <thead>
            <tr>
              <th>Action</th>
              <th>Nomor Polisi</th>
              <th>Kode Kapasitas</th>
              <th>Lokasi Truk</th>
              <th>Nama Ekspedisi</th>
              <th>Dibuat Oleh</th>
              <th>Dibuat Pada</th>
              <th>Diubah Oleh</th>
              <th>Diubah Pada</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let row of dataTruck">
              <td>
                <button (click)="onSelectTruck(row);ModalTruck.hide()" class="btn btn-sm btn-primary"  data-toggle="modal" triggers="mouseenter:mouseleave"><i class="fa fa-check"></i></button>
              </td>
              <td>{{row.truck_plate_number}}</td>
              <td>{{row.truck_capacity_code}}</td>
              <td>{{row.truck_location}}</td>
              <td>{{row.truck_vendor_name}}</td>
              <td>{{row.created_by_text}}</td>
              <td>{{row.created_at_text | slice:0:10}}</td>
              <td>{{row.updated_by_text}}</td>
              <td>{{row.updated_at_text | slice:0:10}}</td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <th><input hidden class="searchInputTruck"/></th>
              <th><input id="platsearch" type="text" class="searchInputTruck" placeholder="Nomor Polisi" name="search-code"/></th>
              <th><input id="codesearch" type="text" class="searchInputTruck" placeholder="Kode Kapasitas" name="search-capacity"/></th>
              <th><input id="locationsearch" type="text" class="searchInputTruck" placeholder="Lokasi Truk" name="search-location"/></th>
              <th><input hidden class="searchInputTruck"/></th>
              <th><input hidden class="searchInputTruck"/></th>
              <th><input hidden class="searchInputTruck"/></th>
              <th><input hidden class="searchInputTruck"/></th>
              <th><input hidden class="searchInputTruck"/></th>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </div>
  </div>

  <div  bsModal #ModalDriver="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog  modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title"><strong>Pilih Supir</strong></div>
          <button type="button" class="close" (click)="ModalDriver.hide()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <table datatable [dtOptions]="dtOptionsDriver" [dtTrigger]="dtTriggerDriver" class="deeps table-striped">
            <thead>
              <tr>
                <th>Action</th>
                <th>Nama</th>
                <th>No SIM</th>
                <th>Jenis SIM</th>
                <th>Agama</th>
                <th>Tempat Lahir</th>
                <th>No KTP</th>
                <th>Tanggal Lahir</th>
                <th>SL</th>
                <th>SL Piutang</th>
                <th>SL Uang Jalan</th>
                <th>Alamat</th>
                <th>Berlaku Sampai</th>
                <th>Kontrak Sampai</th>
                <th>Dibuat Pada</th>
                <th>Diubah Pada</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let row of dataDriver">
                <td>
                  <button (click)="onSelectDriver(row);ModalDriver.hide()" class="btn btn-sm btn-primary"  data-toggle="modal" triggers="mouseenter:mouseleave"><i class="fa fa-check"></i></button>
                </td>
                <td>{{row.driver_name}}</td>
                <td>{{row.driver_license}}</td>
                <td>{{row.driver_license_type}}</td>
                <td>{{row.agama}}</td>
                <td>{{row.birthplace}}</td>
                <td>{{row.ktp_no}}</td>
                <td>{{row.tanggal_lahir | slice:0:10}}</td>
                <td>{{row.sl_code}}</td>
                <td>{{row.sl_code2}}</td>
                <td>{{row.sl_code3}}</td>
                <td>{{row.address}}</td>
                <td>{{row.valid_to | slice:0:10}}</td>
                <td>{{row.valid_to_contract | slice:0:10}}</td>
                <td>{{row.created_at | slice:0:10}}</td>
                <td>{{row.updated_at | slice:0:10}}</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th><input hidden class="searchInputD"/></th>
                <th><input id="driver_name" type="text" class="searchInputD" placeholder="Nama"/></th>
                <th><input id="driver_license" type="text" class="searchInputD" placeholder="No SIM"/></th>
                <th><input id="driver_license_type" type="text" class="searchInputD" placeholder="Tipe SIM"/></th>
                <th><input id="agama" type="text" class="searchInputD" placeholder="Agama"/></th>
                <th><input id="birthplace" type="text" class="searchInputD" placeholder="Tempat Lahir"/></th>
                <th><input id="ktp_no" type="text" class="searchInputD" placeholder="No KTP"/></th>
                <th><input id="tanggal_lahir" type="text" class="searchInputD" placeholder="Tanggal Lahir"/></th>
                <th><input id="sl_code" type="text" class="searchInputD" placeholder="SL"/></th>
                <th><input id="sl_code2" type="text" class="searchInputD" placeholder="SL Piutang"/></th>
                <th><input id="sl_code3" type="text" class="searchInputD" placeholder="SL UJ"/></th>
                <th><input id="address" type="text" class="searchInputD" placeholder="Alamat"/></th>
                <th><input id="valid_to" type="text" class="searchInputD" placeholder="Berlaku Sampai"/></th>
                <th><input id="valid_to_contract" type="text" class="searchInputD" placeholder="Kontrak Sampai"/></th>
                <th><input hidden class="searchInputD"/></th>
                <th><input hidden class="searchInputD"/></th>
              </tr>
            </tfoot>

          </table>
        </div>
      </div>
    </div>
    </div>

    <div  bsModal #ModalVehicle="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
      <div class="modal-dialog  modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <div class="modal-title"><strong>Pilih Jenis Kendaraan</strong></div>
            <button type="button" class="close" (click)="ModalVehicle.hide()" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <table datatable  [dtOptions]="dtOptionsVehicle" [dtTrigger]="dtTriggerVehicle"  class="table-striped">
              <thead>
                <tr>
                  <th>Action</th>
                  <th>Kode</th>
                  <th>Kendaraan</th>
                  <th>Dibuat Oleh</th>
                  <th>Dibuat Pada</th>
                  <th>Diubah Oleh</th>
                  <th>Diubah Pada</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let row of dataVehicles">
                  <td>
                    <button (click)="onSelectVehicle(row);ModalVehicle.hide()" class="btn btn-sm btn-primary"  data-toggle="modal" triggers="mouseenter:mouseleave"><i class="fa fa-check"></i></button>
                  </td>
                  <td>{{row.code}}</td>
                  <td>{{row.name}}</td>
                  <td>{{row.createdby}}</td>
                  <td>{{row.created_at | slice:0:10}}</td>
                  <td>{{row.updatedby}}</td>
                  <td>{{row.updated_at | slice:0:10}}</td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th><input hidden class="searchInputV"/></th>
                  <th><input id="codesearchV" type="text" class="searchInputV" placeholder="Search Kode"/></th>
                  <th><input id="namesearchV" type="text" class="searchInputV" placeholder="Search Vehicle"/></th>
                  <th><input hidden class="searchInputV"/></th>
                  <th><input hidden class="searchInputV"/></th>
                  <th><input hidden class="searchInputV"/></th>
                  <th><input hidden class="searchInputV"/></th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
      </div>

      <div  bsModal #ModalPort="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
        <div class="modal-dialog  modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <div class="modal-title"><strong>Pilih Transit</strong></div>
              <button type="button" class="close" (click)="ModalPort.hide()" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <table datatable  [dtOptions]="dtOptionsPort" [dtTrigger]="dtTriggerPort"  class="deeps table-striped">
                <thead>
                  <tr>
                    <th>Action</th>
                    <th>Nama Port</th>
                    <th>Biaya Bongkar</th>
                    <th>Dibuat Oleh</th>
                    <th>Dibuat Pada</th>
                    <th>Diubah Oleh</th>
                    <th>Diubah Pada</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of dataPort">
                    <td>
                      <button (click)="onSelectPort(row);ModalPort.hide()" class="btn btn-sm btn-primary"  data-toggle="modal" triggers="mouseenter:mouseleave"><i class="fa fa-check"></i></button>
                    </td>
                    <td>{{row.port_name}}</td>
                    <td>{{row.biaya_bongkar}}</td>
                    <td>{{row.created_by_text}}</td>
                    <td>{{row.created_at | slice:0:10}}</td>
                    <td>{{row.updated_by_text}}</td>
                    <td>{{row.updated_at | slice:0:10}}</td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <th><input hidden class="searchInputPort"/></th>
                    <th><input id="namesearchport" type="text" class="searchInputPort" placeholder="Cari Nama" name="search-name"/></th>
                    <th><input hidden class="searchInputPort"/></th>
                    <th><input hidden class="searchInputPort"/></th>
                    <th><input hidden class="searchInputPort"/></th>
                    <th><input hidden class="searchInputPort"/></th>
                    <th><input hidden class="searchInputPort"/></th>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
        </div>



<div  bsModal #ModalCancel="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
<div class="modal-dialog" style="max-width: 75%;" role="document">
  <div class="modal-content">
    <div class="modal-header">
      <div class="modal-title"><strong>Alasan Cancel</strong></div>
      <button #ModalCancelHide type="button" class="close" (click)="ModalCancel.hide()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">

      <div class="card">
        <div class="card-body">
          <table datatable [dtOptions]="dtOptionsCancel" class="deeps table-striped">
            <thead>
              <tr>
                <th>Payment No</th>
                <th>Payment Date</th>
                <th>Jumlah</th>
                <th>Potongan</th>
                <th>Diterima</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let row of dataListCancelPayment">
                <td>{{row.trx_payment_no}}</td>
                <td>{{row.payment_date | slice:0:10}}</td>
                <td>Rp. {{row.total | number}}</td>
                <td>Rp. {{row.debt | number}}</td>
                <td>Rp. {{row.total_paid | number}}</td>
              </tr>
            </tbody>

          </table>
        </div>
      </div>

        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <!-- <div class="form-group row">
                  <label class="col-md-4 col-form-label" for="text-input">Total Yang Telah Dibayarkan</label>
                  <div class="col-md-8">
                    <input value="{{amountTotalCancel | number}}" disabled type="text" class="form-control">
                  </div>
                </div> -->
                <div class="form-group row">
                  <label class="col-md-4 col-form-label" for="text-input">Tanggungan Perusahaan</label>
                  <div class="col-md-8">
                    <input [(ngModel)]="companyCost" [ngModelOptions]="{standalone: true}" (ngModelChange)="onTypeAmount()" currencyMask [options]="{ prefix: '', thousands: '.', decimal: ',' ,precision: '0',align: 'left' }" type="text" class="form-control">
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-md-4 col-form-label" for="text-input">Dikembalikan Supir</label>
                  <div class="col-md-8">
                    <input [(ngModel)]="dirverReturn" [ngModelOptions]="{standalone: true}" (ngModelChange)="onTypeAmount()" currencyMask [options]="{ prefix: '', thousands: '.', decimal: ',' ,precision: '0',align: 'left' }" type="text" class="form-control">
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-md-4 col-form-label" for="text-input">Hutang Supir</label>
                  <div class="col-md-8">
                    <input value="{{driverDebtCancel | number}}" disabled type="text" class="form-control">
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group row">
                  <label class="col-md-3 col-form-label" for="text-input">No Pembayaran</label>
                  <div class="col-md-9">
                    <input disabled type="text" class="form-control">
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-md-3 col-form-label" for="text-input">Tanggal bayar</label>
                  <div class="col-md-9">
                    <input [ngModel]="addCloseModel.payDate | date:'yyyy-MM-dd'" (ngModelChange)="addCloseModel.payDate = $event"  type="date" class="form-control">
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-md-3 col-form-label" for="text-input">No BA</label>
                  <div class="col-md-9">
                    <input [(ngModel)]="addCloseModel.baNo"  type="text" class="form-control">
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-md-3 col-form-label" for="text-input">Alasan</label>
                  <div class="col-md-9">
                    <textarea [(ngModel)]="addCloseModel.reason" rows="4" type="text" class="form-control"></textarea>
                  </div>
                </div>
              </div>
              </div>
          </div>
        </div>

    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="ModalCancel.hide()"><b>Close</b></button>
      <button type="button" class="btn btn-primary" (click)="saveCancel()"><i class="fa fa-save"></i>&nbsp;&nbsp;<b>Save</b>&nbsp;&nbsp;</button>
    </div>
  </div>
</div>
</div>


<div  bsModal #ModalAdAsDebt="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
<div class="modal-dialog" style="max-width: 40%;" role="document">
  <div class="modal-content">
    <div class="modal-body">
      <div class="form-group row">
        <label class="col-md-4 col-form-label" for="text-input">Nama Supir</label>
        <div class="col-md-8">
          <input value="{{tripModel.driver_name}}" disabled type="text" class="form-control">
        </div>
      </div>
      <div class="form-group row">
        <label class="col-md-4 col-form-label" for="text-input">Jumlah Hutang</label>
        <div class="col-md-8">
          <input value="{{total | number}}" disabled type="text" class="form-control">
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button #ModalAdAsDebtHide type="button" class="btn btn-secondary" (click)="ModalAdAsDebt.hide()">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Close&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</button>
      <!-- <button type="button" class="btn btn-primary">Akui Sebagai Hutang</button> -->
      <button type="button" class="btn btn-primary" (click)="directPayment()">&nbsp;&nbsp;Bayar Langsung&nbsp;&nbsp;</button>
    </div>
  </div>
</div>
</div>

<button hidden #debtClick type="button" class="btn btn-primary" (click)="ModalAdAsDebt.show()">Show Modal Debt</button>
<button hidden #paymentClick type="button" class="btn btn-primary" (click)="ModalPayment.show();paymentClear()">Show Modal Payment</button>

<div  bsModal #ModalPayment="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
<div class="modal-dialog" style="max-width: 70%;" role="document">
  <div class="modal-content">
    <div class="modal-body">
      <div class="card">
        <div class="card-body">
          <div class="scroll">
            <table class="table table-striped table-sm">
                <thead>
                <tr>
                    <th>Select</th>
                    <th>Nama Supir</th>
                    <th>SIM Supir</th>
                    <th>Sisa Hutang</th>
                    <th>Trx Reff</th>
                    <th>Jenis Hutang</th>
                    <th>Remark</th>
                    <th>Dibuat Oleh</th>
                    <th>Dibuat Pada</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let row of dataDriverDebt; let i = index">
                    <td><input [disabled]="tripModel.skipPaymentStatus" type="checkbox"  [(ngModel)]="row.isSelected" (change)="amountDebtCheck(row.isSelected, row, i)"/></td>
                    <td>{{row.driver_name}}</td>
                    <td>{{row.driver_license}}</td>
                    <td>{{row.remaining}}</td>
                    <td>{{row.trx_reff}}</td>
                    <td>{{row.type_name}}</td>
                    <td>{{row.remark}}</td>
                    <td>{{row.created_by_name}}</td>
                    <td>{{row.created_at | slice:0:10}}</td>
                  </tr>
                </tbody>
            </table>
          </div>
        </div>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group row">
              <label class="col-md-4 col-form-label" for="text-input">Total Uang Jalan</label>
              <div class="col-md-8">
                <input value="{{totalTravelCost | number}}" disabled type="text" class="form-control">
              </div>
            </div>
            <div class="form-group row">
              <label class="col-md-4 col-form-label" for="text-input">Jumlah Dibayarkan</label>
              <div class="col-md-8">
                <input [disabled]="tripModel.skipPaymentStatus" [(ngModel)]="debtPay" [ngModelOptions]="{standalone: true}" (ngModelChange)="onTypeDebtPay()" currencyMask [options]="{ prefix: '', thousands: '.', decimal: ',' ,precision: '0',align: 'left' }" type="text" class="form-control">
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group row">
              <label class="col-md-4 col-form-label" for="text-input">Total Hutang</label>
              <div class="col-md-8">
                <input value="{{debtTotal | number}}" disabled type="text" class="form-control">
              </div>
            </div>
            <div class="form-group row">
              <label class="col-md-4 col-form-label" for="text-input">Sisa Cicilan</label>
              <div class="col-md-8">
                <input value="{{cicilan | number}}" disabled type="text" class="form-control">
              </div>
            </div>
          </div>
        </div>
        <br>
        <div style="text-align: center">
          <button [disabled]="paymentAdd" (click)="AddDataTambahanDebt()" type="button" class="btn btn-sm btn-primary"><i class="fa fa-plus"></i>&nbsp;Tambahkan Pembayaran Hutang</button>&nbsp;&nbsp;
          <button type="button" class="btn btn-sm btn-primary" (click)="skipPayment()"><i class="fa fa-stop-circle"></i>&nbsp;Tangguhkan Pembayaran</button>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="scroll">
          <table class="table table-striped table-sm">
              <thead>
              <tr>
                  <th>Reff</th>
                  <th>Jenis Hutang<th>
                  <th>Total Hutang</th>
                  <th>Hutang Trip</th>
                  <th>Sisa Cicilan</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let row of dataTambahanDebt; let i = index">
                  <td>{{row.reff}}</td>
                  <td>{{row.type}}</td>
                  <td>{{row.total}}</td>
                  <td>{{row.debt}}</td>
                  <td>{{row.cicilan}}</td>
                </tr>
              </tbody>
          </table>
        </div>
      </div>
  </div>
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-md-6">
          <div class="form-group row">
            <label class="col-md-4 col-form-label" for="text-input">No Pembayaran</label>
            <div class="col-md-8">
              <input disabled type="text" class="form-control">
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-4 col-form-label" for="text-input">Tanggal Bayar</label>
            <div class="col-md-8">
              <input [ngModel]="datePay | date:'yyyy-MM-dd'" (ngModelChange)="datePay = $event"  [ngModelOptions]="{standalone: true}"  type="date" class="form-control">
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group row">
            <label class="col-md-4 col-form-label" for="text-input">Yang Dibayarkan</label>
            <div class="col-md-8">
              <input value="{{tripModel.grandTotal | number}}" disabled type="text" class="form-control">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
    </div>
    <div class="modal-footer">
      <button #ModalPaymentClose type="button" class="btn btn-secondary" (click)="clearPayment();ModalPayment.hide()"><b>Close</b></button>
      <button type="button" (click)="createPayment();" class="btn btn-primary">&nbsp;&nbsp;&nbsp;<b>Bayar</b>&nbsp;&nbsp;&nbsp;</button>
    </div>
  </div>
</div>
</div>

<div  bsModal #ModalReport="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
<div class="modal-dialog  modal-lg" role="document">
  <div class="modal-content">
    <div class="modal-header">
      <div class="modal-title"><strong>{{action}} Data Material</strong></div>
      <small><code style="color: black">&nbsp;&nbsp;*(semua kolom wajib di isi)</code></small>
      <button type="button" class="close" (click)="ModalReport.hide()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="form-group row">
        <label class="col-md-3 col-form-label" for="text-input">Dari Tanggal</label>
        <div class="col-md-9">
          <input type="date" class="form-control" class="form-control">
      </div>
      </div>
      <div class="form-group row">
        <label class="col-md-3 col-form-label" for="text-input">Ke Tanggal</label>
        <div class="col-md-9">
          <input type="date" class="form-control"  class="form-control" >
      </div>
      </div>
      <div class="form-group row">
        <label class="col-md-3 col-form-label" for="text-input">Material</label>
        <div class="col-md-9">
          <select id="select" name="select" class="form-control">
            <option value="DO_NORMAL">Normal</option>
            <option value="DO_PUPUK">Pupuk</option>
          </select>
       </div>
      </div>
      <div class="form-group row">
        <label class="col-md-3 col-form-label" for="text-input">Exclude Material</label>
        <div class="col-md-9">
          <select id="select" name="select" class="form-control">
            <option value="DO_NORMAL">Normal</option>
            <option value="DO_PUPUK">Pupuk</option>
          </select>
       </div>
      </div>
      <div class="form-group row">
        <label class="col-md-3 col-form-label" for="text-input">Tipe Perjalanan</label>
        <div class="col-md-9">
          <select id="select" name="select" class="form-control">
            <option value="DO_NORMAL">Normal</option>
            <option value="DO_PUPUK">Pupuk</option>
          </select>
       </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="ModalReport.hide()"><b>Close</b></button>
      <button type="button" class="btn btn-primary"><i class="fa fa-save"></i>&nbsp;&nbsp;<b>Save</b>&nbsp;&nbsp;</button>
    </div>
  </div>
</div>
</div>

<div  bsModal #ModalFinish="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
<div class="modal-dialog" style="max-width: 40%;" role="document">
  <div class="modal-content">
    <div class="modal-body">
      <div class="card">
        <div class="card-body">
            <table class="table table-striped">
                <thead>
                <tr>
                    <th>No DO</th>
                    <th>Tanggal DO</th>
                    <th>Nama Material</th>
                    <th>Berat Dikirim</th>
                    <th>Berat Diterima</th>
                    <th>ZAK Kirim</th>
                    <th>ZAK Terima</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let row of listOfTrxDoDetail; let i=index">
                    <td>{{row.do_detail_no}}</td>
                    <td>{{row.do_date | slice:0:10}}</td>
                    <td>{{row.material_name}}</td>
                    <td>{{row.tonnage_send}}</td>
                    <td>{{row.tonnage_received}}</td>
                    <td>{{row.pack_send}}</td>
                    <td>{{row.pack_received}}</td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="form-group row">
     <div class="col-md-12">
      <label class="col-md-3 col-form-label" for="text-input">Tanggal Deliver</label>
      <div class="col-md-4">
          <input [(ngModel)]="finishModel.delivery" type="date" class="form-control" id="finish_date">
      </div>
     </div>
   </div>
    </div>
    <div class="modal-footer">
      <button #modalFinishClose type="button" class="btn btn-secondary" (click)="ModalFinish.hide()"><b>Close</b></button>
      <button type="button" class="btn btn-primary" (click)="finishTrip()"><b>Selesai</b></button>
    </div>
  </div>
</div>
</div>

<button hidden #ModalTripOpen (click)="ModalTrip.show()"></button>

<!-- <ngx-spinner bdColor = "rgba(248,248,248,0.95)" size = "large" color = "#262424" type = "ball-spin-fade" >
<p style="font-size: 20px; color: white"></p>
</ngx-spinner> -->

<div  bsModal #ModalUploadFGS="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
<div class="modal-dialog" style="max-width: 80%;" role="document">
  <div class="modal-content">
    <div class="modal-header">
      <div class="modal-title"><strong>Upload Realisasi</strong></div>
      <small><code style="color: black">&nbsp;&nbsp;*(semua kolom wajib di isi)</code></small>
      <button type="button" class="close" (click)="ModalUploadFGS.hide()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="card">
        <div class="card-body">
                <input #uploadFile type="file" (change)="onFileSelected($event)" name="img[]" class="file">
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                      <button (click)="browseFile()" class="browse input-group-text btn btn-warning">&nbsp;&nbsp;<i class="fa fa-folder-open" style="font-size:18px">&nbsp;&nbsp;</i></button>
                  </div>
                  <input type="text" [(ngModel)]="fileName" class="form-control" disabled aria-label="Upload File" aria-describedby="basic-addon1">
                  <div class="input-group-append">
                      <button (click)="uploadFGS()" id="upload" disabled class="btn btn-warning">&nbsp;&nbsp;<i class="fa fa-upload" style="font-size:18px">&nbsp;&nbsp;</i></button>
                  </div>
                </div>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <div class="scroll">
          <div class="card-header">
            <button (click)="selectAll();checkSelected();" class="btn btn-sm btn-warning" style="font-size:14px"><b>&nbsp;&nbsp;Check All&nbsp;&nbsp;</b><i class="fa fa-check">&nbsp;&nbsp;</i></button>&nbsp;&nbsp;
            <button (click)="uploadClear()" class="btn btn-sm btn-danger" style="font-size:14px"><b>&nbsp;&nbsp;Clear Data&nbsp;&nbsp;</b><i class="fa fa-trash">&nbsp;&nbsp;</i></button>
          </div>
            <table class="table">
                <thead>
                <tr>
                    <th></th>
                    <th>Error Message</th>
                    <th>Tipe</th>
                    <th>No SPK<th>
                    <th>No DO Besar</th>
                    <th>SIM Supir</th>
                    <th>No Truck</th>
                    <th>Ekspedisi</th>
                    <th>Ongkos Angkut</th>
                    <th>Tanggal Bagi DO</th>
                    <th>Tanggal Kembali</th>
                    <th>Tanggal Muat</th>
                    <th>Tanggal Bongkar</th>
                    <th>No DO Kecil</th>
                    <th>Tonasse Kirim</th>
                    <th>Tonnase Terima</th>
                    <th>WB Reff</th>
                    <th>Transit Port</th>
                </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of dataUpload; let i = index">
                    <td><input (change)="checkSelected()" [disabled]="row.error" type="checkbox" [(ngModel)]="row.selected"></td>
                    <td>
                      <span *ngIf="row.error_message"><b style="color: red;" > {{row.error_message}} </b></span>
                      <span *ngIf="!row.error_message">{{row.error_message}}</span>
                    </td>
                    <td>{{row.type}}</td>
                    <td>{{row.spk_no}}</td>
                    <td>{{row.main_do_no}}</td>
                    <td>{{row.referensi}}</td>
                    <td>{{row.sim}}</td>
                    <td>{{row.truck_no}}</td>
                    <td>{{row.ekspedisi}}</td>
                    <td>{{row.oa}}</td>
                    <td>{{row.depart_date}}</td>
                    <td>{{row.arrive_date}}</td>
                    <td>{{row.send_date}}</td>
                    <td>{{row.receive_date}}</td>
                    <td>{{row.do_no}}</td>
                    <td>{{row.tonnase_send}}</td>
                    <td>{{row.tonnase_received}}</td>
                  </tr>
                </tbody>
            </table>
          </div>
          <div class="row">
            <div class="col-md-4"> 
                <div class="form-group row group">
                    <label class="col-md-4 col-form-label" for="text-input">Data Terpilih</label>
                    <label class="col-md-3 col-form-label" for="text-input">{{select}}/{{totalData}}</label>
                </div> 
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" (click)="ModalUploadFGS.hide()"><b>Close</b></button>
          <button type="button" class="btn btn-primary" (click)="saveUploadJournal()"><i class="fa fa-save"></i>&nbsp;<b>Save</b>&nbsp;</button>
        </div>
      </div>
    </div>
  </div>
</div>
</div>


<div  bsModal #ModalUpdateDo="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
<div class="modal-dialog" style="max-width: 35%;" role="document">
  <div class="modal-content">
    <div class="modal-header">
      <div class="modal-title"><strong>Ubah Perkiraan Pengiriman</strong></div>
      <button #ModalUpdateDoClose type="button" class="close" (click)="ModalUpdateDo.hide()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <div class="form-group row">
          <label class="col-md-3 col-form-label" for="text-input">Tonnase Kirim</label>
          <div class="col-md-9">
            <input type="text" class="form-control" [(ngModel)]="updateDOModel.tonnage_send" currencyMask [options]="{ prefix: '', thousands: '.', decimal: ',' ,precision: '0',align: 'left' }">
        </div>
        </div>
        <div class="form-group row">
          <label class="col-md-3 col-form-label" for="text-input">Tonnase Terima</label>
          <div class="col-md-9">
            <input type="text" class="form-control" [(ngModel)]="updateDOModel.tonnage_received" currencyMask [options]="{ prefix: '', thousands: '.', decimal: ',' ,precision: '0',align: 'left' }">
        </div>
        </div>
        <div class="form-group row">
          <label class="col-md-3 col-form-label" for="text-input">Zak Kirim</label>
          <div class="col-md-9">
            <input type="text" class="form-control" [(ngModel)]="updateDOModel.pack_send" currencyMask [options]="{ prefix: '', thousands: '.', decimal: ',' ,precision: '0',align: 'left' }">
        </div>
        </div>
        <div class="form-group row">
          <label class="col-md-3 col-form-label" for="text-input">Zak Terima</label>
          <div class="col-md-9">
            <input type="text" class="form-control" [(ngModel)]="updateDOModel.pack_received" currencyMask [options]="{ prefix: '', thousands: '.', decimal: ',' ,precision: '0',align: 'left' }">
        </div>
        </div>
        <div class="form-group row">
          <label class="col-md-3 col-form-label" for="text-input">WB Reff</label>
          <div class="col-md-9">
            <input type="text" class="form-control" [(ngModel)]="updateDOModel.wbreff" >
        </div>
        </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="ModalUpdateDo.hide()"><b>Close</b></button>
      <button type="button" class="btn btn-primary" (click)="updateDo()"><i class="fa fa-save"></i>&nbsp;&nbsp;<b>Save</b>&nbsp;&nbsp;</button>
    </div>
  </div>
</div>
</div>


<div bsModal #ModalPrint="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
<div class="modal-dialog" style="max-width: 50%;" role="document">
  <div class="modal-content">
    <div class="modal-header">
      <div class="modal-title">
      </div>
      <button type="button" class="close" (click)="ModalPrint.hide()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-md-12">
                <div class="form-group row">
                    <label class="col-md-3 col-form-label" for="text-input">Dari Tanggal</label>
                    <div class="col-md-8">
                        <input type="date" [(ngModel)]="printDateFrom" class="form-control">
                    </div>
                </div>
                <div class="form-group row">
                  <label class="col-md-3 col-form-label" for="text-input">Ke Tanggal</label>
                  <div class="col-md-8">
                      <input  type="date" [(ngModel)]="printDateTo" class="form-control">
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" (click)="exportSPK()" class="btn btn-primary"><i class="fa fa-print"></i>&nbsp;<b>Print</b>&nbsp;</button>
      <button type="button" #modalPrint class="btn btn-secondary" (click)="ModalPrint.hide()">&nbsp;<b>Cancel</b>&nbsp;</button>
  </div>
  </div>
</div>
</div>



<div bsModal #ModalListPayment="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
<div class="modal-dialog  modal-lg" role="document">
  <div class="modal-content">
    <div class="modal-header">
      <div class="modal-title">
        <div class="row">
          <strong>Detail List Payment</strong>
        </div>
      </div>
      <button type="button" class="close" (click)="ModalListPayment.hide()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <table datatable [dtOptions]="dtOptionsPaymenDetail" [dtTrigger]="dtTriggerPaymentDetail" class="table table-bordered">
          <thead>
            <tr>
              <th>Nama Biaya</th>
              <th>Reff Hutang</th>
              <th>Jumlah Biaya</th>
              <th>Jumlah Hutang</th>
              <th>Keterangan</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let row of listTrxDetailPaymentTrip">
              <td>{{row.transact_cost_text}}</td>
              <td>{{row.debt_reff}}</td>
              <td>{{row.cost_amount | number}}</td>
              <td>{{row.debt_amount | number}}</td>
              <td>{{row.remark}}</td>
            </tr>
          </tbody>
        </table>
    </div>
  </div>
</div>
</div>

<div  bsModal #ModalUpdateReason="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
<div class="modal-dialog" style="max-width: 35%;" role="document">
  <div class="modal-content">
    <div class="modal-header">
      <div class="modal-title"><strong>Ubah Alasan Cancel</strong></div>
      <button #ModalUpdateReasonClose type="button" class="close" (click)="ModalUpdateReason.hide()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <div class="form-group row">
          <label class="col-md-3 col-form-label" for="text-input">Alasan Cancel</label>
          <div class="col-md-9">
            <textarea [(ngModel)]="cancelRemarkModel.reason" rows="4" type="text" class="form-control"></textarea>
        </div>
        </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="ModalUpdateReason.hide()"><b>Close</b></button>
      <button type="button" class="btn btn-primary" (click)="updateCancelreason()"><i class="fa fa-save"></i>&nbsp;&nbsp;<b>Save</b>&nbsp;&nbsp;</button>
    </div>
  </div>
</div>
</div>

<div bsModal #ModalUpdateVendor="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog" style="max-width: 50%;" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title">
        </div>
        <button #ModalUpdateVendorHide type="button" class="close" (click)="ModalUpdateVendor.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                  <div class="form-group row">
                      <label class="col-md-3 col-form-label" for="text-input">Vendor</label>
                      <div class="col-md-8">
                          <input [(ngModel)]="ekspedisiName" disabled type="text" class="form-control">
                      </div>
                      <div class="col-md-1">
                          <button class="btn btn-sm btn-primary"  (click)="showEkspedisi();ModalEkspedisi.show()"
                          data-toggle="modal" ><i class="fa fa-plus"></i></button>
                      </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-md-3 col-form-label" for="text-input">Truck</label>
                    <div class="col-md-8">
                        <input  type="text" [(ngModel)]="vendorModel.truckName" class="form-control">
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-md-3 col-form-label" for="text-input">Biaya OA</label>
                    <div class="col-md-8">
                        <input disabled type="text" [(ngModel)]="vendorModel.cost" class="form-control">
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-md-3 col-form-label" for="text-input">Supir</label>
                    <div class="col-md-8">
                        <input  type="text" [(ngModel)]="vendorModel.driverName" class="form-control">
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" (click)="UpdateVendorTrip()" class="btn btn-primary"><i class="fa fa-save"></i>&nbsp;<b>Simpan</b>&nbsp;</button>
        <button type="button" #modalPrint class="btn btn-secondary" (click)="ModalUpdateVendor.hide()">&nbsp;<b>Cancel</b>&nbsp;</button>
    </div>
    </div>
  </div>
</div>

<div bsModal #ModalEkspedisi="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog  modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title">
          <div class="row">
            <strong>Pilih Ekspedisi</strong>
          </div>
        </div>
        <button type="button" class="close" (click)="ModalEkspedisi.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
          <!-- <app-master-relation></app-master-relation> -->
          <table datatable [dtOptions]="dtOptionsEkspedisi" [dtTrigger]="dtTriggerEkspedisi" class="table-striped">
              <thead>
                <tr>
                  <th>Action</th>
                  <th>Kode</th>
                  <th>Nama</th>
                  <th>Alamat</th>
                  <th>Kode SL</th>
                  <th>Phone</th>
                  <th>Active</th>
                  <th>Dibuat Oleh</th>
                  <th>Dibuat Pada</th>
                  <th>Diubah Oleh</th>
                  <th>Diubah Pada</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let row of dataEkspedisi">
                  <td style="width: 200;">
                    <button (click)="onSelectEkspedisi(row);ModalEkspedisi.hide()" title="Edit"  class="btn btn-sm btn-primary" data-toggle="modal" triggers="mouseenter:mouseleave"><i class="fa fa-check"></i></button>
                  </td>
                  <td>{{row.relation_code}}</td>
                  <td>{{row.relation_name}}</td>
                  <td>{{row.relation_address}}</td>
                  <td>{{row.relation_sl_code}}</td>
                  <td>{{row.relation_phone}}</td>
                  <td>{{row.active}}</td>
                  <td>{{row.createdby}}</td>
                  <td>{{row.created_at | slice:0:10}}</td>
                  <td>{{row.updatedby}}</td>
                  <td>{{row.updated_at | slice:0:10}}</td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th><input hidden class="searchEkspedisi"/></th>
                  <th><input id="codeEks" type="text" class="searchEkspedisi" placeholder="Kode"/></th>
                  <th><input id="nameEks" type="text" class="searchEkspedisi" placeholder="Nama"/></th>
                  <th><input id="addressEks" type="text" class="searchEkspedisi" placeholder="Alamat"/></th>
                  <th><input id="slEks" type="text" class="searchEkspedisi" placeholder="Kode SL"/></th>
                  <th><input hidden class="searchEkspedisi"/></th>
                  <th><input hidden class="searchEkspedisi"/></th>
                  <th><input hidden class="searchEkspedisi"/></th>
                  <th><input hidden class="searchEkspedisi"/></th>
                  <th><input hidden class="searchEkspedisi"/></th>
                  <th><input hidden class="searchEkspedisi"/></th>
                </tr>
              </tfoot>

            </table>
      </div>
    </div>
  </div>
</div>

<div  bsModal #ModalUpdatePaymentDate="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog" style="max-width: 35%;" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title"><strong>Ubah Tanggal Pembayaran</strong></div>
        <button #ModalUpdatePaymentDateClose type="button" class="close" (click)="ModalUpdatePaymentDate.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
          <div class="form-group row">
            <label class="col-md-3 col-form-label" for="text-input">Tanggal Pembayaran</label>
            <div class="col-md-8">
              <input [ngModel]="paymentDateModel.payment_date | date:'yyyy-MM-dd'" (ngModelChange)="paymentDateModel.payment_date = $event" type="date" class="form-control">
          </div>
          </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="ModalUpdatePaymentDate.hide()"><b>Close</b></button>
        <button type="button" class="btn btn-primary" (click)="updatePaymentDate()"><i class="fa fa-save"></i>&nbsp;&nbsp;<b>Save</b>&nbsp;&nbsp;</button>
      </div>
    </div>
  </div>
  </div>


  <div bsModal #ModalSusut="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog" style="max-width: 60%" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title">
          </div>
          <button #ModalSusutClose type="button" class="close" (click)="ModalSusut.hide()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-6"> 
                    <div class="form-group row">
                        <label class="col-md-4 col-form-label" for="text-input">Total Susut</label>
                        <div class="col-md-7">
                            <input currencyMask [options]="{ prefix: '', thousands: '.', decimal: ',' ,precision: '0',align: 'left' }" type="text" [(ngModel)]="susutModel.totalSusut" class="form-control">
                        </div>
                    </div> 
                    <div class="form-group row">
                        <label class="col-md-4 col-form-label" for="text-input">Harga Satuan</label>
                        <div class="col-md-7">
                            <input currencyMask [options]="{ prefix: '', thousands: '.', decimal: ',' ,precision: '0',align: 'left' }" type="text" [(ngModel)]="susutModel.unitPrice" (ngModelChange)="countTotal()" class="form-control">
                        </div>
                    </div> 
                    <div class="form-group row">
                        <label class="col-md-4 col-form-label" for="text-input">Total Hutang Susut</label>
                        <div class="col-md-7">
                            <input disabled type="text" value="{{totalSusut | number}}" class="form-control">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-md-4 col-form-label" for="text-input">Tanggal Bongkar</label>
                        <div class="col-md-7">
                            <input  type="date" [ngModel]="susutModel.receivedDate| date:'yyyy-MM-dd'" (ngModelChange)="susutModel.receivedDate = $event"  class="form-control">
                        </div>
                    </div>  
                </div> 
                <div class="col-md-6"> 
                    <div class="form-group row">
                        <label class="col-md-4 col-form-label" for="text-input">Ditanggung Perusahaan</label>
                        <div class="col-md-7">
                            <input currencyMask [options]="{ prefix: '', thousands: '.', decimal: ',' ,precision: '0',align: 'left' }" type="text" [(ngModel)]="susutModel.companyPaid" (ngModelChange)="countTotalDriverDebt()" class="form-control">
                        </div>
                    </div> 
                    <div class="form-group row">
                        <label class="col-md-4 col-form-label" for="text-input">Ditanggung Supir</label>
                        <div class="col-md-7">
                            <input disabled type="text" value="{{susutModel.driverPaidTotal | number}}" class="form-control">
                        </div>
                    </div> 
                    <div class="form-group row">
                        <label class="col-md-4 col-form-label" for="text-input"></label>
                        <div class="col-md-7">
                        <textarea [(ngModel)]="susutModel.remarks" rows="1" type="text" class="form-control"></textarea>
                        </div>
                    </div> 
                    <div class="form-group row">
                        <label class="col-md-4 col-form-label" for="text-input">Tanggal Surat</label>
                        <div class="col-md-7">
                            <input  type="date" [ngModel]="susutModel.debtdate| date:'yyyy-MM-dd'" (ngModelChange)="susutModel.debtdate = $event"  class="form-control">
                        </div>
                    </div> 
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" (click)="addSusutTrip()" class="btn btn-primary"><i class="fa fa-print"></i>&nbsp;<b>Save</b>&nbsp;</button>
          <button type="button" #ModalSimpanHide class="btn btn-secondary" (click)="ModalSusut.hide()">&nbsp;<b>Cancel</b>&nbsp;</button> 
      </div>
      </div>
    </div>
  </div>

  <div bsModal #ModalSignature="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog" style="max-width: 50%" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title">
          </div>
          <button type="button" class="close" (click)="ModalSignature.hide()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-12"> 
                    <div class="form-group row">
                        <label class="col-md-4 col-form-label" for="text-input">Disetujui</label>
                        <div class="col-md-8">
                          <select  [(ngModel)]="printModel.disetujui" class="form-control">
                            <option *ngFor="let row of dataSignature" [value]='row.name'><b>{{row.name}}</b></option>
                         </select>   
                        </div>
                    </div> 
                    <div class="form-group row">
                      <label class="col-md-4 col-form-label" for="text-input">Kepala Cabang</label>
                      <div class="col-md-8">
                        <select  [(ngModel)]="printModel.diketahui" class="form-control">
                          <option *ngFor="let row of dataSignature" [value]='row.name'><b>{{row.name}}</b></option>
                       </select>   
                      </div>
                  </div> 
                  <div class="form-group row">
                        <label class="col-md-4 col-form-label" for="text-input">Operational</label>
                        <div class="col-md-5">
                          <select  [(ngModel)]="printModel.diketahui2" class="form-control">
                            <option *ngFor="let row of dataSignature" [value]='row.name'><b>{{row.name}}</b></option>
                         </select>   
                        </div>
                        <div class="col-sm-1">
                          <input [(ngModel)]="printModel.hide_diketahui2" style="width: 40%;" type="checkbox" class="form-control">
                        </div>
                        <label class="col-sm-2 col-form-label" for="text-input">Sembunyikan</label>
                  </div> 
                  <div class="form-group row">
                    <label class="col-md-4 col-form-label" for="text-input">Admin DO/Store</label>
                    <div class="col-md-5">
                      <select  [(ngModel)]="printModel.diketahui3" class="form-control">
                        <option *ngFor="let row of dataSignature" [value]='row.name'><b>{{row.name}}</b></option>
                     </select>   
                    </div>
                    <div class="col-sm-1">
                      <input [(ngModel)]="printModel.hide_diketahui3" style="width: 40%;" type="checkbox" class="form-control">
                    </div>
                    <label class="col-sm-2 col-form-label" for="text-input">Sembunyikan</label>
                 </div> 
                 <div class="form-group row">
                    <label class="col-md-4 col-form-label" for="text-input">HRGA</label>
                    <div class="col-md-5">
                      <select  [(ngModel)]="printModel.diketahui4" class="form-control">
                        <option *ngFor="let row of dataSignature" [value]='row.name'><b>{{row.name}}</b></option>
                     </select>   
                    </div>
                    <div class="col-sm-1">
                      <input [(ngModel)]="printModel.hide_diketahui4" style="width: 40%;" type="checkbox" class="form-control">
                    </div>
                    <label class="col-sm-2 col-form-label" for="text-input">Sembunyikan</label>
                 </div> 
                </div> 
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button (click)="print()" type="button" class="btn btn-primary"><i class="fas fa-print"></i>&nbsp;&nbsp;&nbsp;<b>Cetak</b>&nbsp;&nbsp;&nbsp;</button> 
      </div>
      </div>
    </div>
  </div>

  <div bsModal #ModalSearch="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog" style="max-width: 60%;" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title">
           <b> Pencarian </b>
          </div>
          <button type="button" class="close" (click)="ModalSearch.hide()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">

          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-6"> 
                  <div class="form-group row">
                    <label class="col-md-4 col-form-label" for="text-input">Reff</label>
                    <div class="col-md-7">
                        <input type="text" [(ngModel)]="searchModel.reff"  class="form-control">
                    </div>
                  </div> 
                  <div class="form-group row">
                    <label class="col-md-4 col-form-label" for="text-input">Nama Rute</label>
                    <div class="col-md-7">
                        <input type="text" [(ngModel)]="searchModel.routeName"  class="form-control">
                    </div>
                  </div> 
                  <div class="form-group row">
                    <label class="col-md-4 col-form-label" for="text-input">No Plat Kendaraan</label>
                    <div class="col-md-7">
                        <input type="text" [(ngModel)]="searchModel.truckPlatNumber"  class="form-control">
                    </div>
                  </div> 
                  <div class="form-group row">
                    <label class="col-md-4 col-form-label" for="text-input">Tanggal Bagi DO</label>
                    <div class="col-md-7">
                        <input type="date" [(ngModel)]="searchModel.departDateFrom"  class="form-control">
                    </div>
                  </div> 
                  <div class="form-group row">
                    <label class="col-md-4 col-form-label" for="text-input">Tanggal Kembali</label>
                    <div class="col-md-7">
                        <input type="date" [(ngModel)]="searchModel.arriveDateFrom"  class="form-control">
                    </div>
                  </div> 
                  <div class="form-group row">
                    <label class="col-md-4 col-form-label" for="text-input">Tanggal Bongkar</label>
                    <div class="col-md-7">
                        <input type="date" [(ngModel)]="searchModel.receiveDateFrom"  class="form-control">
                    </div>
                  </div> 
                  <div class="form-group row">
                    <label class="col-md-4 col-form-label" for="text-input">No SPK</label>
                    <div class="col-md-7">
                        <input type="text" [(ngModel)]="searchModel.spkNo"  class="form-control">
                    </div>
                  </div> 
                  <div class="form-group row">
                    <label class="col-md-4 col-form-label" for="text-input">No Do Besar</label>
                    <div class="col-md-7">
                        <input type="text" [(ngModel)]="searchModel.mainDoNo"  class="form-control">
                    </div>
                  </div> 
                </div>
                <div class="col-md-6"> 
                  <div class="form-group row">
                    <label class="col-md-4 col-form-label" for="text-input">System Reff</label>
                    <div class="col-md-8">
                        <input type="text" [(ngModel)]="searchModel.systemReff"  class="form-control">
                    </div>
                  </div> 
                  <div class="form-group row">
                    <label class="col-md-4 col-form-label" for="text-input">SIM Supir</label>
                    <div class="col-md-8">
                        <input type="text" [(ngModel)]="searchModel.driverLicense"  class="form-control">
                    </div>
                  </div> 
                  <div class="form-group row">
                    <label class="col-md-4 col-form-label" for="text-input">Ekspedisi</label>
                    <div class="col-md-8">
                        <input type="text" [(ngModel)]="searchModel.vendorName"  class="form-control">
                    </div>
                  </div> 
                  <div class="form-group row">
                    <label class="col-md-4 col-form-label" for="text-input">S/D</label>
                    <div class="col-md-8">
                        <input type="date" [(ngModel)]="searchModel.departDateTo"  class="form-control">
                    </div>
                  </div> 
                  <div class="form-group row">
                    <label class="col-md-4 col-form-label" for="text-input">S/D</label>
                    <div class="col-md-8">
                        <input type="date" [(ngModel)]="searchModel.arriveDateTo"  class="form-control">
                    </div>
                  </div> 
                  <div class="form-group row">
                    <label class="col-md-4 col-form-label" for="text-input">S/D</label>
                    <div class="col-md-8">
                        <input type="date" [(ngModel)]="searchModel.receiveDateTo"  class="form-control">
                    </div>
                  </div> 
                  <div class="form-group row">
                    <label class="col-md-4 col-form-label" for="text-input">No Do Kecil</label>
                    <div class="col-md-8">
                        <input type="text" [(ngModel)]="searchModel.doNo"  class="form-control">
                    </div>
                  </div> 
                </div>
              </div>
              <br>
              <div [hidden]="!menuRealization" class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-4"> 
                      <div class="form-group row">
                        <div class="col-sm-1">
                          <input [disabled]="disableBox" [(ngModel)]="searchModel.distributed" style="width: 100%;" type="checkbox" class="form-control">
                        </div>
                        <label class="col-sm-4 col-form-label" for="text-input">Do Distributed</label>
                        <div class="col-sm-6">
                        </div>
                     </div> 
                     <div class="form-group row">
                        <div class="col-sm-1">
                          <input [disabled]="disableBox" [(ngModel)]="searchModel.realisasi" style="width: 100%;" type="checkbox" class="form-control">
                        </div>
                        <label class="col-sm-4 col-form-label" for="text-input">Realisasi</label>
                        <div class="col-sm-6">
                        </div>
                     </div> 
                    </div>
                    <div class="col-md-6"> 
                        <div class="form-group row">
                          <label class="col-md-4 col-form-label" for="text-input">Dokumen Lengkap</label>
                          <div class="col-md-8">
                            <select [(ngModel)]="searchModel.complete" (ngModelChange)="selectComplete($event)" class="form-control">
                              <option selected><b></b></option>
                              <option value=true><b>Ya</b></option>
                              <option value=false><b>Tidak</b></option>
                            </select>
                          </div>
                        </div> 
                      <div class="form-group row">
                        <div class="col-sm-1">
                          <input [disabled]="disableBox" [(ngModel)]="searchModel.cancel" style="width: 50%;" type="checkbox" class="form-control">
                        </div>
                        <label class="col-sm-4 col-form-label" for="text-input">Cancel</label>
                        <div class="col-sm-6">
                        </div>
                      </div> 
                    </div>
                    <div class="col-md-2"> 

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
  
        </div>
        <div class="modal-footer">
          <button type="button" (click)="searchData();ModalSearch.hide()" class="btn btn-primary"><i class="fa fa-search"></i>&nbsp;<b>Cari</b>&nbsp;</button>
          <button type="button" class="btn btn-secondary" (click)="ModalSearch.hide()">&nbsp;<b>Close</b>&nbsp;</button> 
      </div>
      </div>
    </div>
  </div>
<button hidden #ModalListPaymentShow (click)="ModalListPayment.show()"></button>
<button hidden #ModalUpdateVendorShow (click)="ModalUpdateVendor.show()"></button>
<button hidden #ModalSusutShow (click)="ModalSusut.show()"></button>
<button hidden #ModalSignatureShow (click)="ModalSignature.show()"></button>

<ngx-spinner
bdColor="rgba(51,51,51,0.8)"
size="medium"
color="#fff"
type="ball-scale-multiple"
>
<p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>