import { Guid } from "guid-typescript";

export class ManualInvoice {
    invoiceMLId : Guid;
    invoiceMLNo : string;
    date : string;
    customer : string;
    address : string;
    description : string;
    route : string;
    spk : string;
    senderSPK : string;
    amount : number;
    taxNo : string;
    printDate : string;
    createdDate : string;
    createdName : string;
    updatedDate : string;
    updatedName : string;
}

export class UploadManualInvoice {
    invoiceMLId : Guid;
    invoiceMLNo : string;
    date : string;
    customer : string;
    address : string;
    description : string;
    route : string;
    spk : string;
    senderSPK : string;
    do : number;
    amount : number;
    truckNumber : number;
    qty : number;
    oa : number;
    ppn : number;
    dpp : number;
    taxNo : string;
    printDate : string;
    createdDate : string;
    createdName : string;
    updatedDate : string;
    updatedName : string;
    error_message: string;
    error: Boolean;
    selected: Boolean;
}

export class ManualInvoiceDetail {
    detailId : Guid;
    debitNoteId : Guid;
    truckNumber : string;
    do : string;
    qty : string;
    oa : string;
    ppn : string;
    dpp : string;
    amount : string;
}


export class JournalManualInvoice {
    journalNo : string;
    prj : string;
    date : Date;
    remark : string;
    doNo : string;
    ccy : string;
    rate : number;
    credit : number;
    debit : number;
    gl : string;
    io : string;
}

export class JournalManualInvoiceExport {
    No_Bukti : string;
    PRJ : string;
    Tanggal : string;
    Keterangan : string;
    No_DO_Kecil : string;
    CCY : string;
    RATE : number;
    Credit : number;
    Debet : number;
    GL : string;
    IO : string;
}

export class RevenueType {
    id : Guid;
    name : string;
    debit : string;
    credit : string;
    creditEx : string;
}